import {
    OBTENER_LISTADO_HISTORIAL_COMISION,
    OBTENER_FRANQUICIA_SELECT,
    SELECCIONAR_PAGINA_HISTORIAL,
    HISTORIAL_TOTALES,
} from '../../../../types';

export const initialState = {
    listaHistorialComisiones: [],
    franquicias: [],
    paginaActualHistorial: 1,
    totalHistorial: 0,
};

const ReporteComisionesReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_LISTADO_HISTORIAL_COMISION:
            return {
                ...state,
                listaHistorialComisiones: action.payload,
            };
        case OBTENER_FRANQUICIA_SELECT:
            return {
                ...state,
                franquicias: action.payload,
            };
        case SELECCIONAR_PAGINA_HISTORIAL:
            return {
                ...state,
                paginaActualHistorial: action.payload,
            };
        case HISTORIAL_TOTALES:
            return {
                ...state,
                totalHistorial: action.payload,
            };
        default:
            return state;
    }
};

export default ReporteComisionesReducer;
