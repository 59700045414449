import React, { useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import TiempoPedido from '../../../../atoms/support/TiempoPedido/TiempoPedido';
import Local from '../../../../atoms/support/Local';
import DatosCliente from '../../../../atoms/support/DatosCliente';
import Acciones from '../../../../atoms/support/acciones/Acciones';
import CargarMasButton from '../../../../atoms/support/CargarMasButton/CargarMasButton';
import classes from './busquedas.module.css';
import PropTypes from 'prop-types';
import Integraciones from '../../../../atoms/support/Integraciones';
import tablas from '../tablas.module.css';
import useSupport from '../../../../../hooks/support/useSupport';
import { useVerificarPermiso } from '../../../../hooks/useVerificarPermiso';
import FinalPrice from '../../../../atoms/support/FinalPrice';

const Busqueda = ({
    filter,
    triggerSearch,
    setTriggerSearch,
    activeTab,
    filterStatus,
    bancard,
}) => {
    const {
        cancelarOrden,
        verOrdenModal,
        imprimirOrden,
        cambiarEstado,
        obtenerOrdenesBusqueda,
        cargarMasBusqueda,
        vaciarTablaBusqueda,
        loading,
        listaOrdenesBusqueda,
        permisoVerIntegraciones,
        deshabilitarBotonIntegracion,
        revertirCancelacion,
        revertirPago,
        solicitarDriver,
        paginaActualBusqueda,
        totalPaginasBusqueda,
    } = useSupport();
    useEffect(() => {
        // Si estás en la tab 7 y cambias de tab, vaciar el campo de búsqueda
        if (activeTab !== '7') {
            setPalabraClave(''); // Vaciar el campo de búsqueda
        }
    }, [activeTab]);

    useEffect(() => {
        setPalabraClave(filter);
    }, [filter]);

    // instancia del formulario
    // Search params
    const [selectEstadoValue, setSelectEstadoValue] = useState('');
    const [palabraClave, setPalabraClave] = useState(filter);
    //Hook de mixpanel
    const mixpanel = useMixpanel();

    //Se trackea el tiempo en esta pantalla antes de solicitar driver
    useEffect(() => {
        mixpanel.time_event('request_driver');
        mixpanel.track('click_on_search_tab');
    }, []);

    useEffect(() => {
        vaciarTablaBusqueda();
    }, []);

    useEffect(() => {
        if (triggerSearch && activeTab === '7') {
            searchHandler({ OrderStatusSelect: filterStatus });
            setTriggerSearch(false); // reset the triggerSearch prop
        }
    }, [triggerSearch, activeTab]);

    const searchHandler = () => {
        const selectEstado = filterStatus || 'todos'; // Usa el estado del filtro directamente
        const page = 1;
        obtenerOrdenesBusqueda(selectEstado, palabraClave, bancard, page);
        mixpanel.track('search', {
            keyword: palabraClave,
            stateSelected: selectEstado,
            bancard: bancard,
        });
    };

    const cargarMasButtonHandler = () => {
        cargarMasBusqueda(selectEstadoValue, palabraClave);
    };

    const columnasParte1 = useMemo(() => [
        {
            dataIndex: 'local',
            key: 'local',
            render: (_text, record) => (
                <>
                    <Local
                        nombreLocal={record?.nombreLocal}
                        tipoEntrega={record?.tipoEntrega}
                        codigoOnline={record?.codigoPagoOnline}
                        telefonoLocal={record?.telefonoLocal}
                        tienePermiso={record?.canSeeOnlinePaymentType}
                    />
                    <div>
                        <strong> Orden ID: </strong>
                        {record?.ordenOriginal?.id}
                    </div>
                    <DatosCliente
                        nombre={record?.nombreCliente}
                        id={record?.idCliente}
                        razonSocial={record?.razonSocial}
                        RUC={record?.ruc}
                    />
                </>
            ),
        },
        {
            dataIndex: 'precioFinal',
            key: 'precioFinal',
            render: (_text, record) => (
                <>
                    <div className={classes.precioFinalBusqueda}>
                        <Local
                            tipoPago={record?.tipoPago}
                            codigoOnline={record?.codigoPagoOnline}
                        />
                        <strong>
                            <FinalPrice ordenOriginal={record?.ordenOriginal} />
                        </strong>
                    </div>
                </>
            ),
        },
        {
            dataIndex: 'tiempoPedido',
            key: 'tiempoPedido',
            render: (_text, record) => (
                <>
                    <TiempoPedido
                        fechaPedido={record?.fechaPedido}
                        tiempoEstimado={record?.tiempoEstimado}
                        ordenOriginal={record?.ordenOriginal}
                    />
                </>
            ),
        },
    ]);

    const columnasParte2 = useMemo(() => [
        {
            dataIndex: 'acciones',
            key: 'acciones',
            render: (_text, record) => (
                <Acciones
                    ordenSeleccionada={record?.ordenOriginal}
                    tieneMonchisDriver={record?.tieneMonchisDriver}
                    permitirSolicitarDriver={record?.permitirSolicitarDriver}
                    solicitarDriver={solicitarDriver}
                    tipoPago={record?.tipoPago}
                    permitirRevertirPagoOnline={
                        record?.permitirRevertirPagoOnline
                    }
                    revertirPago={revertirPago}
                    permitirCancelarOrden={record?.permitirCancelarOrden}
                    cancelarOrden={cancelarOrden}
                    permitirCambiarEstado={record?.permitirCambiarEstado}
                    cambiarEstado={cambiarEstado}
                    permitirRevertirCancelacion={
                        record?.permitirRevertirCancelacion
                    }
                    revertirCancelacion={revertirCancelacion}
                    permitirImprimirOrden={record?.permitirImprimirOrden}
                    imprimirOrden={imprimirOrden}
                    verOrden={verOrdenModal}
                    permitirVerOrden={record?.permitirVerOrden}
                    tipoPagoSoloCupon={record?.tipoPagoSoloCupon}
                />
            ),
        },
    ]);

    const columnaIntegraciones = useMemo(
        () =>
            permisoVerIntegraciones
                ? {
                      dataIndex: 'integraciones',
                      key: 'integraciones',
                      render: (_text, record) => {
                          return (
                              <Integraciones
                                  esIntegracion={
                                      record?.ordenOriginal?.actions
                                          ?.has_integration
                                  }
                                  esJedi={
                                      record?.ordenOriginal?.branch?.franchise
                                          ?.has_jedi
                                  }
                                  ordenSeleccionada={record?.ordenOriginal}
                                  jediId={record?.ordenOriginal?.jedi_id}
                                  fechaJedi={
                                      record?.ordenOriginal?.sent_to_jedi_date
                                  }
                                  idMcDonalds={
                                      record?.ordenOriginal
                                          ?.mcdonals_external_invoice_id
                                  }
                                  estadoOrden={
                                      record?.ordenOriginal?.order_state_id
                                  }
                                  idIntegracion={
                                      record?.ordenOriginal?.external_order_id
                                  }
                                  envioIntegracion={
                                      record?.ordenOriginal?.actions
                                          ?.sent_integration
                                  }
                                  deshabilitarBotonIntegracion={
                                      deshabilitarBotonIntegracion
                                  }
                              />
                          );
                      },
                  }
                : undefined,
        [permisoVerIntegraciones, deshabilitarBotonIntegracion],
    );
    const columns = useMemo(
        () =>
            columnaIntegraciones
                ? [...columnasParte1, columnaIntegraciones, ...columnasParte2]
                : [...columnasParte1, ...columnasParte2],
        [
            listaOrdenesBusqueda,
            solicitarDriver,
            revertirPago,
            cancelarOrden,
            cambiarEstado,
            revertirCancelacion,
            imprimirOrden,
            verOrdenModal,
            permisoVerIntegraciones,
            deshabilitarBotonIntegracion,
        ],
    );
    const combinedRowClassName = (record, index) => {
        return `${tablas.tableRow} ${classNameRow(record, index)}`;
    };
    const classNameRow = (record) => {
        let clase;
        const ordenAtrasada = TiempoPedido({
            fechaPedido: record.fechaPedido,
            tiempoEstimado: record.tiempoEstimado,
            ordenOriginal: record.ordenOriginal,
            obtenerAtrasado: true,
        });
        if (ordenAtrasada) {
            clase = classes.rowClass;
        } else {
            clase = 'table-row-dark';
        }
        return clase;
    };

    return (
        <div className={classes.space}>
            <Table
                className={tablas.bordeNegro}
                columns={columns}
                showHeader={false}
                dataSource={listaOrdenesBusqueda}
                loading={loading}
                pagination={false}
                rowClassName={combinedRowClassName}
            />
            <CargarMasButton
                onCargarMas={cargarMasButtonHandler}
                loading={loading}
                paginaActual={paginaActualBusqueda}
                totalPaginas={totalPaginasBusqueda}
            />
        </div>
    );
};

export default Busqueda;
Busqueda.propTypes = {
    filter: PropTypes.any,
    triggerSearch: PropTypes.func,
    setTriggerSearch: PropTypes.func,
    activeTab: PropTypes.string,
    filterStatus: PropTypes.string,
    bancard: PropTypes.bool,
};
