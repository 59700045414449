import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, message, Table, Tag, Tooltip, Row } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { formatoGuaranies } from '../../../../../utils-components/Number';
import DocumentosComercioContext from '../../../../../../context/facturacion/documentosComercio/DocumentosComercioContext';
import { obtenerIntervaloInicioFinDocumentosFacturacion } from '../../../../../utils-components/obtenerIntervaloInicioFinDocumentosFacturacion';
import FormItemSelectMes from '../../../../../atoms/Form/Select/Mes';
import FormItemSelectAnho from '../../../../../atoms/Form/Select/Anho';
import { dateDMYyHora } from '../../../../../utils-components/Date';
import classes from './listaFacturasComercio.module.css';
import externalLinkImg from '../../../../../../assets/images/externalLink.png';

const itemsPorPagina = 20;

const ListaFacturasComercio = () => {
    const [parametrosBusqueda, setParametrosBusqueda] = useState({
        date_start: '',
        date_end: '',
        state_ids: 1,
        page: 1,
    });

    const documentosComercioContext = useContext(DocumentosComercioContext);

    const {
        facturasObtenerLista,
        facturasLista,
        facturasListaLoading,
        facturasTotalPaginas,
    } = documentosComercioContext;

    const [misFacturasForm] = Form.useForm();

    const handleCopyInfo = (info) => {
        copy(info);
        message.success('Copiado!');
    };

    const copyButton = (data) => {
        return (
            <>
                <Tooltip title="Copiar">
                    {data}
                    <Button type="link" onClick={() => handleCopyInfo(data)}>
                        <CopyOutlined />
                    </Button>
                </Tooltip>
            </>
        );
    };

    const columns = [
        {
            title: 'Emisión',
            dataIndex: 'emision',
            key: 'emision',
            render: (text) => dateDMYyHora(text),
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },

        {
            title: 'Razón Social',
            dataIndex: 'razonSocial',
            key: 'razonSocial',
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },
        {
            title: 'Impresiones',
            dataIndex: 'impresiones',
            key: 'impresiones',
            align: 'center',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (text) => (
                <div className={classes.stateColumnDiv}>
                    <Tag
                        className={classes.stateColumnTag}
                        color={
                            text === 'pendiente'
                                ? 'orange'
                                : text === 'facturado'
                                ? 'cyan'
                                : text === 'pagado'
                                ? 'green'
                                : 'volcano'
                        }>
                        {text}
                    </Tag>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text) => formatoGuaranies(+text),
        },
        {
            title: 'Timbrado',
            dataIndex: 'timbrado',
            key: 'timbrado',
            fixed: 'right',
            render: (record) => copyButton(record),
        },
        {
            title: 'Nro. comprobante',
            dataIndex: 'numero',
            key: 'numero',
            fixed: 'right',
            width: 180,
            render: (record) => copyButton(record),
        },
        {
            title: 'Acción',
            key: 'acciones',
            fixed: 'right',
            render: (record) => (
                <Link
                    to={{
                        pathname:
                            'https://monchis.kigafe.com/consultacomprobante',
                    }}
                    target="_blank"
                    disabled={
                        !record?.factura?.invoice?.id ||
                        !record?.factura?.invoice?.secret
                    }>
                    Descargar
                    <img
                        className={classes.externalLinkImg}
                        src={externalLinkImg}
                    />
                </Link>
            ),
        },
    ];

    useEffect(() => {
        // Obtener las fechas para la busqueda inicial

        // Obtener fecha del mes anterior al actual
        const fechaConsultar = new Date();
        fechaConsultar.setMonth(fechaConsultar.getMonth() - 1);

        const { fechasBusqueda, mesStart, anhoStart } =
            obtenerIntervaloInicioFinDocumentosFacturacion(
                fechaConsultar.getMonth(),
                fechaConsultar.getFullYear(),
            );

        // Establecer parametros de busqueda en el form
        misFacturasForm.setFieldsValue({
            mes: mesStart,
            anho: anhoStart,
            franquiciaId: '',
        });

        // Guardar parametros de busqueda en el state local
        setParametrosBusqueda((prevState) => ({
            ...prevState,
            ...fechasBusqueda,
        }));
    }, []);

    useEffect(() => {
        if (parametrosBusqueda.date_start && parametrosBusqueda.date_end) {
            facturasObtenerLista(parametrosBusqueda);
        }
    }, [parametrosBusqueda]);

    const buscarFacturasHandler = (values) => {
        const { fechasBusqueda } =
            obtenerIntervaloInicioFinDocumentosFacturacion(
                +values?.mes - 1,
                +values?.anho,
            );

        setParametrosBusqueda((prevState) => ({
            ...prevState,
            ...fechasBusqueda,
            page: 1,
        }));
    };

    const changePageHandler = (page) => {
        setParametrosBusqueda((prevState) => ({
            ...prevState,
            page,
        }));
    };

    return (
        <>
            <Row gutter={12}>
                <Col span={16}>
                    <Form
                        form={misFacturasForm}
                        onFinish={buscarFacturasHandler}
                        name="filtros"
                        layout="vertical">
                        <Row gutter={12}>
                            <Col span={10}>
                                <FormItemSelectMes
                                    rules={[{ required: true }]}
                                    disabled={facturasListaLoading}
                                />
                            </Col>
                            <Col span={10}>
                                <FormItemSelectAnho
                                    rules={[{ required: true }]}
                                    disabled={facturasListaLoading}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item style={{ marginTop: 26 }}>
                                    <Button
                                        disabled={facturasListaLoading}
                                        type="primary"
                                        htmlType="submit">
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={24}>
                    <Table
                        size="small"
                        loading={facturasListaLoading}
                        columns={columns}
                        dataSource={facturasLista}
                        scroll={{
                            x: 1400,
                        }}
                        pagination={{
                            pageSize: itemsPorPagina,
                            total: facturasTotalPaginas,
                            current: parametrosBusqueda.page,
                            showSizeChanger: false,
                            onChange: (page) => changePageHandler(page),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ListaFacturasComercio;
