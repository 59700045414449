import { Card } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import NivelUsuarioContext from '../../../../context/programaRecompensa/nivelUsuario/NivelUsuarioContext.js';
import ABMLayout from '../../../../layouts/ABMLayout';
import UserLevelForm from './UserLevelForm';

const EditarNivel = () => {
    const history = useHistory();

    const {
        selectedUserLevel,
        loading,
        updateUserLevel,
        cancelEditUserLevel,
        rewardsList,
    } = useContext(NivelUsuarioContext);

    useEffect(() => {
        if (!selectedUserLevel) {
            cancelButtonHandler();
        }
    }, [selectedUserLevel]);

    const saveButtonHandler = (values) => {
        const userLevel = {
            id: Number(selectedUserLevel?.id) || undefined,
            name: values?.name,
            amount: values?.amount,
            points: values?.points,
            price: values?.price,
            from: values?.from,
            to: values?.to,
            colours: values?.colours,
            rewards: values?.rewards?.map((item) => +item.reward) || [],
            quantity: values?.rewards?.map((item) => +Number(item?.quantity)),
        };
        updateUserLevel(userLevel);
    };

    const cancelButtonHandler = () => {
        cancelEditUserLevel();
        history.push('/programa-recompensa/nivel-usuario/lista');
    };

    return (
        <ABMLayout titulo={'Editar nivel'}>
            <Card>
                <UserLevelForm
                    onSave={saveButtonHandler}
                    onCancel={cancelButtonHandler}
                    loading={loading}
                    initialValues={selectedUserLevel}
                    rewardsList={rewardsList}
                />
            </Card>
        </ABMLayout>
    );
};

export default EditarNivel;
