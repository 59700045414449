import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import AMBLayout from '../../../../../../layouts/ABMLayout';
import moment from 'moment';
import { Form, Row, Col, Divider, Select, message } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classes from './ListaDetalleTransaccion.module.css';
import axios from '../../../../../../config/axios';
import { tipoPagoOpciones } from '../../../../../../constants';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../../../utils-components/Number';

import CustomSVG from '../../../../../../assets/svg/support/CustomSVG';
import Input from '../../../../../atoms/Input';
import Card from '../../../../../atoms/Card/Card';
import Button from '../../../../../atoms/Button';
import DatePicker from '../../../../../atoms/DatePicker';
import TablaResumen from '../../../../../molecules/TablaResumen';
import Avatar from 'antd/lib/avatar/avatar';
import { useTransactionsDetails } from '../../../../../hooks/useTransactionsDetails';
import ProtectedComponent from '../../../../../../hoc/ProtectedComponent';
import { useVerificarPermiso } from '../../../../../hooks/useVerificarPermiso';
import { Permisos } from '../../../../../../constants/Permisos';
import FormItemSelectTipoPago from '../../../../../atoms/Form/Select/TipoPago';
import UtilidadesContext from '../../../../../../context/utilidades/UtilidadesContext';
import useRouterQuery from 'hooks/useRouterQueries';

const totalRegistrosPorPaginaDetalleTransaccion = 15;

// Formulario -- Configuracion -- configuracion del input fecha inicio fecha fin
const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Por favor seleccione un periodo',
        },
    ],
};

// Formulario -- Configuracion -- formato para input input fecha inicio fecha fin
const dateFormat = 'DD/MM/YYYY';

const ListaDetalleTransaccion = () => {
    const { createQueryParams } = useRouterQuery();
    //custom hook
    const {
        transaccionDetailDescarga,
        transaccionCancelados,
        transaccionNoProcesados,
        obtenerSucursales,
        datosCsv,
        sucursales,
        datosCanceladoCsv,
        datosNoProcesadosCsv,
    } = useTransactionsDetails();

    const [obtenerValores, setObtenerValores] = useState({});
    const [sucursalDisabled, setSucursalDisabled] = useState(true);
    const [seleccionFranquicia, setSeleccionFranquicia] = useState([]);
    const [dataEndpoint, setDataEndpoint] = useState();
    const [initialFormValues, setInitialFormValues] = useState({});

    const [transaccionForm] = Form.useForm();

    const { listaTiposPago } = useContext(UtilidadesContext);

    const history = useHistory();

    useEffect(() => {
        if (dataEndpoint) {
            obtenerDatosEndpoint();
        }
        const sessionStorageFilters = sessionStorage.getItem('searchFilters');
        const storageParsed = JSON.parse(sessionStorageFilters);

        if (storageParsed) {
            const { franchiseId, payment_type, dateStart, dateEnd, branchId } =
                storageParsed;
            setInitialFormValues({
                franquiciaId: dataEndpoint
                    ? dataEndpoint.franchiseId
                    : franchiseId?.split(','),
                nroTransaccion: undefined,
                payment_type: dataEndpoint
                    ? dataEndpoint.payment_type
                    : payment_type?.split(','),
                periodo: [
                    moment(moment(dateStart, 'YYYY/MM/DD'), dateFormat),
                    moment(moment(dateEnd, 'YYYY/MM/DD'), dateFormat),
                ],
                sucursales: branchId?.split(','),
            });
        }
    }, [dataEndpoint, sessionStorage]);

    const columns = [
        {
            title: 'Nro de orden',
            dataIndex: 'order_id',
            key: 'order_id',
            align: 'center',
        },
        {
            title: 'Fecha y Hora',
            dataIndex: 'date_time',
            key: 'date_time',
            align: 'center',
        },
        {
            title: 'Tipo de Pago',
            dataIndex: 'payment_type',
            key: 'payment_type',
            align: 'center',
        },
        {
            title: 'Sucursal',
            dataIndex: 'branch',
            key: 'branch',
            align: 'center',
        },
        {
            title: 'Driver Monchis',
            dataIndex: 'has_monchis_drivers',
            key: 'has_monchis_drivers',
            align: 'center',
        },
        {
            title: 'Importe de la orden',
            dataIndex: 'total_order',
            key: 'total_order',
            align: 'center',
        },
        {
            title: 'Importe Total',
            dataIndex: 'total_with_delivery',
            key: 'total_with_delivery',
            align: 'center',
        },
        {
            title: 'SubTotal',
            dataIndex: 'subtotal_comission',
            key: 'subtotal_comission',
            align: 'center',
        },
        {
            title: 'Comisión a cobrar',
            dataIndex: 'comission',
            key: 'comission',
            align: 'center',
        },
        {
            key: 'discount',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Cant. Descuento',
                    dataIndex: 'cantidadDescuento',
                    key: 'discount',
                    align: 'center',
                },
            }),
        },
        {
            key: 'discount_type',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Tipo descuento',
                    dataIndex: 'tipoDescuento',
                    key: 'discount_type',
                    align: 'center',
                },
            }),
        },
        {
            key: 'promotion_name',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Nombre de promoción',
                    dataIndex: 'nombrePromocion',
                    key: 'promotion_name',
                    align: 'center',
                },
            }),
        },
        {
            key: 'total_with_discount',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: ' Total con descuento',
                    dataIndex: 'totalDescuento',
                    key: 'total_with_discount',
                    align: 'center',
                },
            }),
        },
        {
            key: 'saldo',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Saldo a Cobrar',
                    dataIndex: 'saldoCobrar',
                    key: 'saldo',
                    align: 'center',
                },
            }),
        },
        {
            key: 'discount_limit',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Monto límite descuento',
                    dataIndex: 'montoLimiteDescuento',
                    key: 'discount_limit',
                    align: 'center',
                },
            }),
        },
        {
            key: 'entity',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Entidad Financiera',
                    dataIndex: 'entidadFinanciera',
                    key: 'entity',
                    align: 'center',
                },
            }),
        },
        {
            key: 'card_used',
            ...ProtectedComponent({
                esObjeto: true,
                permiso:
                    Permisos.reportes.ventas.detalleTransaccion
                        .columnasDetalleDescuento,
                objeto: {
                    title: 'Tarjeta Usada',
                    dataIndex: 'tarjetaUsada',
                    key: 'card_used',
                    align: 'center',
                },
            }),
        },
        {
            title: 'Ver Orden',
            dataIndex: 'verorden',
            key: 'verorden',
            align: 'center',
            render: (orderId) => (
                <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => verOrdenBotonHandler(orderId)}
                />
            ),
        },
    ];

    // Formulario -- Configuracion
    const [detalleTransaccionForm, setDetalleTransaccionForm] = useState({});
    const [obteniendoDatosForm, setObteniendoDatosForm] = useState(false);

    // Formulario - Franquicias
    const [franquicias, setFranquicias] = useState();

    // Franquicias - Obtener datos para elselect
    const obtenerFranquicias = async () => {
        let arrFranquicias = [{ franchise_id: '', name: 'Todas' }];

        try {
            setObteniendoDatosForm(true);

            const respuesta = await axios.get('/franchises');

            respuesta.data.data.franchises.map((item) =>
                arrFranquicias.push(item),
            );
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las franquicias',
            );
        }

        setObteniendoDatosForm(false);

        setFranquicias(arrFranquicias);
    };

    // Franquicias - Obtener datos para el select al cargar la pantalla
    useEffect(() => {
        obtenerFranquicias();
    }, []);

    // Formulario - onChange
    const formSelectChangeHandler = (e) => {
        setDetalleTransaccionForm({
            ...detalleTransaccionForm,
            [e.target.name]: e.target.value,
        });
    };

    // Total ordenes
    const [
        cantidadRegistrosDetalleTransaccion,
        setCantidadRegistrosDetalleTransaccion,
    ] = useState();

    // Formulario - Boton Ver Resumen
    const verResumenButtonHandler = (values) => {
        setObteniendoDatos(true);

        let dataToQuery = {
            dateStart: values?.periodo[0].format('YYYY/MM/DD'),
            dateEnd: values?.periodo[1].format('YYYY/MM/DD'),
            page: 1,
        };

        if (values?.payment_type)
            dataToQuery['payment_type'] = values?.payment_type;
        if (values?.nroTransaccion)
            dataToQuery['transactionNumber'] = values?.nroTransaccion;
        if (values?.franquiciaId)
            dataToQuery['franchiseId'] =
                values?.franquiciaId[0] === ''
                    ? undefined
                    : values?.franquiciaId.toString();

        if (values?.sucursales?.length >= 1)
            dataToQuery['branchId'] = values?.sucursales.toString();

        //generar el link para descargar transacciones
        handleDescargarTotal({ ...dataToQuery, page: undefined });

        // generar el link para descargar transacciones canceladas
        handleDescargarCancelados({ ...dataToQuery, page: undefined });

        // generar el link para descargar transacciones no procesadas
        handleDescargarNoProcesados({ ...dataToQuery, page: undefined });
        setDataEndpoint(dataToQuery);

        // se guarda los filtros de búsqueda en el sessionStorage
        sessionStorage.setItem('searchFilters', JSON.stringify(dataToQuery));
    };

    // Tabla de datos
    const [detalleTransaccion, setDetalleTransaccion] = useState(0);

    const [dataTarjetas, setDataTarjetas] = useState();

    // Tabla de datos -- Detalle Transaccion -- Datos formateados
    const [tablaResumenData, setTablaResumenData] = useState();

    const [obteniendoDatos, setObteniendoDatos] = useState(false);

    // Detalle Transaccion - Obtener datos
    const obtenerDatosEndpoint = async () => {
        setObteniendoDatos(true);
        try {
            const respuesta = await axios.get('/transaction_details', {
                params: dataEndpoint,
            });

            const totalOrden = respuesta.data.data.total_orders;

            setCantidadRegistrosDetalleTransaccion(totalOrden);

            const detalleArr = respuesta.data.data.transaction_details;

            const datosCards = respuesta.data.data;

            setDetalleTransaccion(detalleArr);

            setDataTarjetas(datosCards);
        } catch (error) {
            message.error(error.response.data.message);
        }
        setObteniendoDatos(false);
    };

    // Detalle Transaccion - Refrescar tabla y tarjetas cuando existan datos
    useEffect(() => {
        if (detalleTransaccion && dataTarjetas && listaTiposPago?.length) {
            refrescarDatosTarjetaCabecera();
            refrescarDatosTabla();
        }
    }, [detalleTransaccion, dataTarjetas, listaTiposPago]);

    // Detalle Transaccion - Refrescar datos de la tabla
    const refrescarDatosTabla = () => {
        // agreagar formato para mostrar en la tabla
        const tablaConFormato = detalleTransaccion.map((item) => {
            const dotIndex = item.date_time.indexOf('.');
            const fechaHora =
                item.date_time.substring(0, dotIndex) + moment().format('Z');
            const tipoPago =
                listaTiposPago.find((tipo) => tipo?.name === item?.payment_type)
                    ?.slug || item?.payment_type;
            return {
                key: item.order_id,
                order_id: `#${formatoNumeros(item.order_id)}`,
                payment_type: (
                    <>
                        <strong>{tipoPago}</strong>
                        {item.payment_type === tipoPagoOpciones[3].valor ? (
                            <>
                                <br />
                                {item.transaction_number}
                            </>
                        ) : null}
                    </>
                ),
                branch: item.branch,
                has_monchis_drivers: (
                    <>
                        <strong>{item.has_monchis_drivers}</strong>
                        <br />
                        {formatoGuaranies(item.delivery_price)}
                    </>
                ),
                date_time: moment(fechaHora).format('DD-MM-YYYY HH:mm:ss'),
                total_order: formatoGuaranies(item.total_order),
                total_with_delivery: formatoGuaranies(item.total_with_delivery),
                subtotal_comission: (
                    <>
                        <strong>{Number(item.commssion_percent)}%</strong>
                        <br />
                        {formatoGuaranies(item.subtotal_comission)}
                    </>
                ),
                comission: formatoGuaranies(item.comission),
                montoLimiteDescuento: item.discount_limit,
                tipoDescuento: item.discount_type,
                nombrePromocion: item.promotion_name,
                totalDescuento: item.total_with_discount,
                entidadFinanciera: item.entity,
                tarjetaUsada: item.card_used,
                saldoCobrar: item.saldo,
                cantidadDescuento: item.discount,
                verorden: item.order_id,
            };
        });

        setTablaResumenData(tablaConFormato);
    };

    // Detalle Transaccion - Boton Ver Orden
    const verOrdenBotonHandler = (orderId) => {
        const queryParams = createQueryParams({ order_id: orderId });
        history.push(
            `/reportes/ventas/detalle-transaccion/ver-orden${queryParams}`,
        );
    };

    // Tarjetas
    // Tarjetas -- datos para las tarjetas en la cabecera
    const [tarjetasCabecera, setTarjetasCabecera] = useState({
        cantidadVentas: 0,
        totalVentas: formatoGuaranies(0),
        cantidadDrivers: 0,
        totalDrivers: formatoGuaranies(0),
        totalPagoOnline: formatoGuaranies(0),
        totalEfectivo: formatoGuaranies(0),
        totalPorcentajeMonchis: formatoGuaranies(0),
    });

    // Detalle Transaccion - Refrescar datos de las tarjetas
    const refrescarDatosTarjetaCabecera = () => {
        let cantidadVentas = dataTarjetas.total_orders;
        let totalVentas = dataTarjetas.amount_total_orders;

        let cantidadDrivers = dataTarjetas.quantity_delivery_with_monchis;
        let totalDrivers = dataTarjetas.amount_total_orders_with_delivery;

        let totalPagoOnline = dataTarjetas.amount_online_amount;
        let totalEfectivo = dataTarjetas.amount_cash_amount;
        let totalPorcentajeMonchis = dataTarjetas.amount_total_commissions;

        setTarjetasCabecera({
            cantidadVentas: formatoNumeros(cantidadVentas),
            totalVentas: formatoGuaranies(totalVentas),
            cantidadDrivers: formatoNumeros(cantidadDrivers),
            totalDrivers: formatoGuaranies(totalDrivers),
            totalPagoOnline: formatoGuaranies(totalPagoOnline),
            totalEfectivo: formatoGuaranies(totalEfectivo),
            totalPorcentajeMonchis: formatoGuaranies(totalPorcentajeMonchis),
        });
    };

    const cambioDePaginaHandler = (page) => {
        setDataEndpoint((state) => ({
            ...state,
            page: page,
        }));
    };

    const handleDescargarTotal = (data) => {
        transaccionDetailDescarga(data);
    };

    const handleDescargarCancelados = (data) => {
        transaccionCancelados(data);
    };

    const handleDescargarNoProcesados = (data) => {
        transaccionNoProcesados(data);
    };

    const seleccionarFranquicia = (valor) => {
        setSeleccionFranquicia(valor);
    };

    useEffect(() => {
        let { franquiciaId } = obtenerValores;
        let deshabilitarSucursal = true;
        deshabilitarSucursal &&
            transaccionForm.setFieldsValue({ sucursales: undefined });
        if (franquiciaId) {
            // Permitir seleccionar 'TODAS' luego de marcar franquicias
            if (franquiciaId[franquiciaId.length - 1] === '') {
                transaccionForm.setFieldsValue({ franquiciaId: [''] });
            } else {
                // Cuando ya esta seleccionado 'TODAS' pero se marca otra franquicia, remover la opcion de 'TODAS'
                franquiciaId = franquiciaId?.filter((id) => id !== '');
                if (franquiciaId.length > 0) {
                    // Actualizar datos del form
                    transaccionForm.setFieldsValue({
                        franquiciaId: franquiciaId,
                    });
                    // Obtener sucursales
                    obtenerSucursales(franquiciaId);
                    // Habilitar select de sucursales
                    deshabilitarSucursal = false;
                } else {
                    transaccionForm.setFieldsValue({ franquiciaId: [''] });
                }
            }
        }
        setSucursalDisabled(deshabilitarSucursal);
    }, [seleccionFranquicia]);

    const { tienePermiso, esUsuarioComercio } = useVerificarPermiso();
    const isACommercialUser = esUsuarioComercio();
    const hasPermission = tienePermiso(
        Permisos.reportes.ventas.detalleTransaccion.verOpcionesPagoOnline,
    );

    return (
        <AMBLayout titulo="Detalle de transacción">
            <Form
                name="basic"
                form={transaccionForm}
                initialValues={initialFormValues}
                layout="vertical"
                onFinish={(event) => verResumenButtonHandler(event)}
                onValuesChange={(valores) => setObtenerValores(valores)}>
                <div className={classes.cardForm}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        name="franquiciaId"
                                        label="Seleccionar Franquicia">
                                        <Select
                                            disabled={obteniendoDatos}
                                            name="franquiciaId"
                                            mode="multiple"
                                            onChange={seleccionarFranquicia}
                                            showSearch
                                            loading={obteniendoDatosForm}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {franquicias &&
                                                franquicias.map((item) => (
                                                    <Select.Option
                                                        key={item.franchise_id}
                                                        value={
                                                            item.franchise_id
                                                        }>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={8} xl={8}>
                                    <Form.Item
                                        name="periodo"
                                        label="Periodo"
                                        {...rangeConfig}
                                        className={classes.filters}>
                                        <DatePicker
                                            disabled={obteniendoDatos}
                                            name="periodo"
                                            format={dateFormat}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24} align="bottom">
                                <Col md={8} xl={8}>
                                    <Form.Item
                                        name="sucursales"
                                        label="Sucursales">
                                        <Select
                                            name="sucursales"
                                            mode="multiple"
                                            disabled={sucursalDisabled}
                                            showSearch
                                            loading={obteniendoDatosForm}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {sucursales &&
                                                sucursales.map((item) => (
                                                    <Select.Option
                                                        key={item.branch_id}
                                                        value={item.branch_id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={8} xl={8}>
                                    <FormItemSelectTipoPago
                                        formInstance={transaccionForm}
                                        disabled={obteniendoDatos}
                                        esUsuarioComercio={isACommercialUser}
                                        tienePermisoVerPagoOnline={
                                            hasPermission
                                        }
                                    />
                                </Col>
                                <Col md={8} xl={8}>
                                    <Form.Item
                                        name="nroTransaccion"
                                        label="Nro. de transacción">
                                        <Input
                                            disabled={obteniendoDatos}
                                            name="nroTransaccion"
                                            onChange={(evt) =>
                                                formSelectChangeHandler(evt)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={4} style={{ marginTop: 'auto' }}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    className={classes.PrimaryCustom}
                                    block={true}
                                    disabled={!franquicias && true}
                                    htmlType="submit"
                                    loading={obteniendoDatos}>
                                    Ver Resumen
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div className={classes.conjuntoBotones}>
                        <div className={classes.btnSeparacion}>
                            <Button
                                type="primary"
                                href={datosCsv}
                                disabled={!tablaResumenData && true}
                                loading={obteniendoDatos}
                                className={classes.btnDescargar}>
                                Descargar
                            </Button>
                        </div>
                        <ProtectedComponent
                            permiso={
                                Permisos.reportes.ventas.detalleTransaccion
                                    .botonDescargarCancelados
                            }>
                            <div className={classes.btnSeparacion}>
                                <Button
                                    type="primary"
                                    href={datosCanceladoCsv}
                                    className={classes.btnDescargaCancelado}
                                    loading={obteniendoDatos}
                                    disabled={!tablaResumenData && true}>
                                    Descargar Cancelados
                                </Button>
                            </div>
                        </ProtectedComponent>
                        <ProtectedComponent
                            permiso={
                                Permisos.reportes.ventas.detalleTransaccion
                                    .botonDescargarNoProcesadas
                            }>
                            <div className={classes.btnSeparacion}>
                                <Button
                                    type="primary"
                                    href={datosNoProcesadosCsv}
                                    className={classes.btnDescargaTransaccion}
                                    loading={obteniendoDatos}
                                    disabled={!tablaResumenData && true}>
                                    Descargar transacciones no procesadas
                                </Button>
                            </div>
                        </ProtectedComponent>
                    </div>
                </div>
            </Form>

            <Divider style={{ border: 'none', margin: '0.5rem 0' }} />

            {/* Tarjetas */}

            <Row gutter={24} justify="start" style={{ margin: '30px 0' }}>
                <Col span={8}>
                    <Card
                        loading={obteniendoDatos}
                        cargando={obteniendoDatos}
                        className={classes.cardPrincipales}>
                        <div className={classes.cardTransaccion}>
                            <Avatar
                                style={{ backgroundColor: '#3B82F6' }}
                                size={64}
                                icon={<CustomSVG name="total" />}
                            />
                            <div className={classes.iconoNumeroTotal}>
                                <div className={classes.cantidadTotal}>
                                    <strong>
                                        {tarjetasCabecera.cantidadVentas}
                                    </strong>
                                </div>
                                <div className={classes.iconoSub}>
                                    <small>Total</small>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        loading={obteniendoDatos}
                        cargando={obteniendoDatos}
                        className={classes.cardPrincipales}>
                        <div className={classes.cardTransaccion}>
                            <Avatar
                                style={{ backgroundColor: '#8B5CF6' }}
                                size={64}
                                icon={<CustomSVG name="moto" />}
                            />
                            <div className={classes.iconoNumeroTotal}>
                                <div className={classes.cantidadTotal}>
                                    <strong>
                                        {tarjetasCabecera.totalDrivers}
                                    </strong>
                                </div>
                                <div className={classes.iconoSub}>
                                    <small>Total con delivery</small>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>

                <Col span={8}>
                    <Card
                        loading={obteniendoDatos}
                        cargando={obteniendoDatos}
                        className={classes.cardPrincipales}>
                        <div className={classes.cardTransaccion}>
                            <Avatar
                                style={{ backgroundColor: '#6EE7B7' }}
                                size={64}
                                icon={<CustomSVG name="porcentaje" />}
                            />
                            <div className={classes.iconoNumeroTotal}>
                                <div className={classes.cantidadTotal}>
                                    <strong>
                                        {
                                            tarjetasCabecera.totalPorcentajeMonchis
                                        }
                                    </strong>
                                </div>
                                <div className={classes.iconoSub}>
                                    <small>Total Comisión</small>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Divider style={{ border: 'none', margin: '1.2rem 0' }} />

            {/* Tabla resumen */}
            <TablaResumen
                loading={obteniendoDatos}
                columns={columns.filter(
                    (column) => column.dataIndex !== undefined,
                )}
                data={tablaResumenData}
                scroll={{ x: 2100 }}
                pagination={{
                    pageSize: totalRegistrosPorPaginaDetalleTransaccion,
                    total: cantidadRegistrosDetalleTransaccion,
                    current: dataEndpoint?.page ? dataEndpoint.page : 1,
                    showSizeChanger: false,
                    onChange: (page) => cambioDePaginaHandler(page),
                }}
            />
        </AMBLayout>
    );
};

export default ListaDetalleTransaccion;
