import React from 'react';

function Xsvg() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none">
            <g clipPath="url(#clip0_7421_43368)">
                <path
                    d="M13.2348 3.76004L9.63212 0.157366C9.53167 0.0569197 9.39607 0 9.25377 0H2.14216C1.84584 0 1.60645 0.239397 1.60645 0.535714V14.4643C1.60645 14.7606 1.84584 15 2.14216 15H12.8564C13.1528 15 13.3922 14.7606 13.3922 14.4643V4.14007C13.3922 3.99777 13.3352 3.86049 13.2348 3.76004ZM12.1567 4.38616H9.006V1.23549L12.1567 4.38616ZM12.1868 13.7946H2.8118V1.20536H7.86761V4.82143C7.86761 5.00791 7.94169 5.18675 8.07355 5.31861C8.20541 5.45047 8.38425 5.52455 8.57073 5.52455H12.1868V13.7946ZM7.53446 8.64007L6.49986 6.92578C6.46303 6.86551 6.39774 6.82868 6.32743 6.82868H5.68457C5.64607 6.82868 5.60924 6.83873 5.57743 6.86049C5.48368 6.91908 5.45522 7.04297 5.51549 7.13839L6.89328 9.32143L5.49707 11.5446C5.4781 11.5751 5.46762 11.6101 5.46672 11.646C5.46581 11.6819 5.47452 11.7174 5.49193 11.7488C5.50934 11.7802 5.53483 11.8064 5.56575 11.8247C5.59668 11.8429 5.63192 11.8526 5.66783 11.8527H6.2454C6.31571 11.8527 6.37932 11.8158 6.41616 11.7573L7.46582 10.058L8.50879 11.7556C8.54562 11.8158 8.61091 11.851 8.67955 11.851H9.30734C9.34584 11.851 9.38267 11.8393 9.41616 11.8192C9.50991 11.7589 9.53669 11.635 9.47642 11.5413L8.07017 9.35826L9.49819 7.14007C9.51752 7.10966 9.52835 7.07464 9.52955 7.03863C9.53075 7.00262 9.52227 6.96695 9.50501 6.93533C9.48775 6.90371 9.46232 6.87729 9.43139 6.85883C9.40045 6.84037 9.36513 6.83054 9.3291 6.83036H8.73145C8.66113 6.83036 8.59584 6.86719 8.55901 6.92745L7.53446 8.64007Z"
                    fill="black"
                    fillOpacity="0.85"
                />
            </g>
            <defs>
                <clipPath id="clip0_7421_43368">
                    <rect width="15" height="15" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
}

export default Xsvg;
