import React, { useReducer } from 'react';
import PromotionsReducer, { initialState } from './PromotionsReducer';
import { useHistory } from 'react-router-dom';
import PromotionsContext from './PromotionsContext';
import { urls } from '../../../constants';
import clienteAxios from 'config/axios';
import PropTypes from 'prop-types';
import { message } from 'antd';
import getErrorMessage from 'components/utils-components/getErrorMessage';

import {
    PROMOTIONS_LIST,
    PROMOTIONS_NEW_OR_EDIT,
    PROMOTIONS_ID,
    PROMOTIONS_ENABLE,
    PROMOTIONS_SHOW_DEACTIVATE_MODAL,
    PROMOTIONS_SHOW_ACTIVATE_MODAL,
    PROMOTIONS_CURRENT_PAGE,
    PROMOTIONS_FILTERS_TO_LIST,
    PROMOTIONS_SHOW_ADD_PRODUCT_MODAL,
    PROMOTIONS_SELECTED_PRODUCTS,
    PROMOTIONS_FRANCHISES_SELECTED,
    PROMOTIONS_BRANCHES_SELECTED,
    PROMOTIONS_PRODUCT_LIST,
    PROMOTIONS_PRODUCT_LIST_PAGE,
    PROMOTIONS_AUDIENCE_LIST,
    PROMOTION_CHARACTERISTICS,
    PROMOTIONS_LOADING_PROMOTIONS_LIST,
    PROMOTIONS_DATA_SOURCE,
    PROMOTIONS_PAGE_SIZE,
    PROMOTIONS_TOTAL,
} from './Types';

const PromotionsState = ({ children }) => {
    const [state, dispatch] = useReducer(PromotionsReducer, initialState);

    const history = useHistory();

    const setDataSource = (dataSource) => {
        dispatch({
            type: PROMOTIONS_DATA_SOURCE,
            payload: {
                dataSource,
            },
        });
    };

    const setPromotionsList = (promotions) => {
        dispatch({
            type: PROMOTIONS_LIST,
            payload: {
                promotionsList: promotions.dataSource,
                pageSize: promotions.pageSize,
                total: promotions.total,
                loadingPromotionList: false,
            },
        });
    };

    const setLoadingPromotionList = (loading) => {
        dispatch({
            type: PROMOTIONS_LOADING_PROMOTIONS_LIST,
            payload: {
                loadingPromotionList: loading,
            },
        });
    };

    /**
     * getPromotionsList
     * @param {String} name
     * @param {Boolean} enabled
     * @param {Number} page
     * @param {Number} franchise
     * @param {Number} branch
     * @returns {Object} promotionsList
     */
    const getPromotionsList = async (
        name = undefined,
        enabled = undefined,
        franchise = undefined,
        branch = undefined,
        page = 1,
    ) => {
        try {
            const params = {
                name,
                page,
                enabled,
                franchise_id: franchise,
                branch_id: branch,

                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            };
            const promotionsList = await clienteAxios.get('promotion_list', {
                params,
            });

            if (!promotionsList?.data?.success)
                throw new Error(
                    promotionsList?.message ||
                        'No hemos podido obtener la lista de promociones.',
                );

            return promotionsList?.data?.data;
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            message.error(errorMessage);
            return null;
        }
    };

    /**
     * activatePromotion
     * @param {Boolean} enabled
     * @param {Number} id
     */

    const activatePromotion = async (enabled, id, callback) => {
        const data = {
            enabled,
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.patch(
                `/promotion_activation/${id}`,
                data,
            );

            if (response?.data?.success) {
                message.success(
                    `La promoción fue ${
                        enabled ? 'activada' : 'desactivada'
                    } correctamente`,
                );
                callback();
            } else {
                throw new Error(response?.data?.message || 'Error');
            }
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                `No se pudo ${enabled ? 'activar' : 'desactivar'} la promoción`,
            );
            message.error(errorMessage);
        }
    };

    const getProductsList = async (
        productName,
        franchiseIds,
        branchIds = [],
        page = 1,
        enabled = true,
        disabled = true,
        deleted = false,
        orderBy = 'updated_at',
        order = 'DESC',
    ) => {
        const params = {
            product_name: productName,
            franchise_ids: franchiseIds,
            branch_ids: branchIds,
            page,
            enabled,
            disabled,
            deleted,
            order_by: orderBy,
            order,

            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };
        try {
            const response = await clienteAxios.get('products', { params });
            const productList = response?.data?.data?.products_updated;
            const pageSize = response?.data?.data?.per_page;
            const total = response?.data?.data?.count;
            if (!response?.data?.success)
                throw new Error(
                    'No hemos podido obtener la lista de productos',
                );

            dispatch({
                type: PROMOTIONS_PRODUCT_LIST,
                payload: {
                    productList: productList,
                    productListTotal: total,
                    productListPageSize: pageSize,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            message.error(errorMessage);
        }
    };

    const setProductList = (productList) => {
        dispatch({
            type: PROMOTIONS_PRODUCT_LIST,
            payload: {
                productList,
            },
        });
    };

    const setProductListPage = (page) => {
        dispatch({
            type: PROMOTIONS_PRODUCT_LIST_PAGE,
            payload: {
                productListPage: page,
            },
        });
    };

    const getAudience = async () => {
        const params = {
            domain: 'BANNERS',
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.get('get_audiencies', {
                params,
            });

            if (!response?.data?.success)
                throw new Error(
                    'No hemos podido obtener el listado de audiencias',
                );

            const audience = response?.data?.data?.audiencies;

            dispatch({
                type: PROMOTIONS_AUDIENCE_LIST,
                payload: {
                    audienceList: audience,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'No hemos podido obtener el listado de audiencias',
            );
            message.error(errorMessage);
        }
    };

    const setNewOrEditProm = (newOrEditProm) => {
        dispatch({
            type: PROMOTIONS_NEW_OR_EDIT,
            payload: {
                newOrEditProm,
            },
        });
    };

    const setId = (id) => {
        dispatch({
            type: PROMOTIONS_ID,
            payload: {
                id,
            },
        });
    };

    const setEnable = (enable) => {
        dispatch({
            type: PROMOTIONS_ENABLE,
            payload: {
                enable,
            },
        });
    };

    const setShowDeactivatePromotionModal = (show) => {
        dispatch({
            type: PROMOTIONS_SHOW_DEACTIVATE_MODAL,
            payload: {
                showDeactivatePromotionModal: show,
            },
        });
    };

    const setShowActivatePromotionModal = (show) => {
        dispatch({
            type: PROMOTIONS_SHOW_ACTIVATE_MODAL,
            payload: {
                showActivatePromotionModal: show,
            },
        });
    };

    const setCurrentPage = (currentPage) => {
        dispatch({
            type: PROMOTIONS_CURRENT_PAGE,
            payload: {
                currentPage,
            },
        });
    };

    // CREATE AND EDIT PROMOTIONS

    const createOrEditPromo = async (data, bannerId) => {
        const body = {
            ...data,
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_SV_RECEPTION,
        };
        try {
            if (bannerId !== undefined) {
                await clienteAxios.put(`/promotion/${bannerId}`, body);
                message.success(
                    `La promoción ${data?.name} fue editada correctamente`,
                );
                history.push('/programa-recompensa/promociones/banners-promo/');
            } else {
                await clienteAxios.post('/promotion', body);
                message.success(
                    `Se creó correctamente la promoción ${data?.name}`,
                );
                history.push('/programa-recompensa/promociones/banners-promo/');
            }
        } catch (error) {
            const parsedError =
                error?.response?.data?.message ||
                error?.message ||
                error ||
                'Hubo un problema al crear la promoción';
            message.error(parsedError);
            throw new Error(parsedError);
        }
    };

    const setFilters = (filters) => {
        dispatch({
            type: PROMOTIONS_FILTERS_TO_LIST,
            payload: {
                filters,
            },
        });
    };

    const setShowAddProductModal = (show) => {
        dispatch({
            type: PROMOTIONS_SHOW_ADD_PRODUCT_MODAL,
            payload: {
                showAddProductModal: show,
            },
        });
    };

    const setSelectedProducts = (selectedProducts) => {
        dispatch({
            type: PROMOTIONS_SELECTED_PRODUCTS,
            payload: {
                selectedProducts,
            },
        });
    };

    const setSelectedFranchises = (franchises) => {
        dispatch({
            type: PROMOTIONS_FRANCHISES_SELECTED,
            payload: {
                selectedFranchises: franchises,
            },
        });
    };

    const setSelectedBranches = (branches) => {
        dispatch({
            type: PROMOTIONS_BRANCHES_SELECTED,
            payload: {
                selectedBranches: branches,
            },
        });
    };

    const setCharacteristics = (characteristics) => {
        dispatch({
            type: PROMOTION_CHARACTERISTICS,
            payload: {
                characteristics,
            },
        });
    };

    const setPageSize = (pageSize) => {
        dispatch({
            type: PROMOTIONS_PAGE_SIZE,
            payload: {
                pageSize,
            },
        });
    };

    const setTotal = (total) => {
        dispatch({
            type: PROMOTIONS_TOTAL,
            payload: {
                total,
            },
        });
    };

    return (
        <PromotionsContext.Provider
            value={{
                promotionsList: state.promotionsList,
                newOrEditProm: state.newOrEditProm,
                bannerSelectedData: state.bannerSelectedData,
                id: state.id,
                enable: state.enable,
                showDeactivatePromotionModal:
                    state.showDeactivatePromotionModal,
                showActivatePromotionModal: state.showActivatePromotionModal,
                currentPage: state.currentPage,
                filters: state.filters,
                showAddProductModal: state.showAddProductModal,
                selectedProducts: state.selectedProducts,
                selectedFranchises: state.selectedFranchises,
                selectedBranches: state.selectedBranches,
                productList: state.productList,
                productListTotal: state.productListTotal,
                productListPageSize: state.productListPageSize,
                audienceList: state.audienceList,
                characteristics: state.characteristics,
                loadingPromotionList: state.loadingPromotionList,
                dataSource: state.dataSource,
                pageSize: state.pageSize,
                total: state.total,

                getPromotionsList,
                createOrEditPromo,
                setNewOrEditProm,
                activatePromotion,
                setId,
                setEnable,
                setShowDeactivatePromotionModal,
                setShowActivatePromotionModal,
                setCurrentPage,
                setFilters,
                setShowAddProductModal,
                setSelectedProducts,
                setSelectedFranchises,
                setSelectedBranches,
                getProductsList,
                getAudience,
                setCharacteristics,
                setPromotionsList,
                setLoadingPromotionList,
                setDataSource,
                setPageSize,
                setTotal,
                setProductList,
                setProductListPage,
            }}>
            {children}
        </PromotionsContext.Provider>
    );
};

PromotionsState.propTypes = {
    children: PropTypes.node,
};

export default PromotionsState;
