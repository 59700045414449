import React, { useReducer, useState } from 'react';
import clienteAxios from '../../../config/axios';
import EmisionDocumentosReducer, {
    initialState,
} from './EmisionDocumentosReducer';
import EmisionDocumentosContext from './EmisionDocumentosContext';
import PropTypes from 'prop-types';
import {
    FACTURAS_TOTALES,
    LOADING_NOTIFICACION,
    OBTENER_LISTADO_EMISION_FACTURAS,
    SELECCIONAR_PAGINA_FACTURA,
    RECIBOS_OBTENER_LISTA,
    RECIBOS_LISTA_LOADING,
    RECIBOS_MODAL_LOADING,
} from './index';
import { message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import descargarArchivoRaw from '../../../components/utils-components/descargarArchivoRaw';

const EmisionDocumentosState = ({ children }) => {
    const [loadingTablaFactura, setLoadingTablaFactura] = useState(false);
    const [loadingModalOk, setLoadingModalOk] = useState(false);
    const [fechas, setFechas] = useState({});

    const [state, dispatch] = useReducer(
        EmisionDocumentosReducer,
        initialState,
    );

    const obtenerListaDocumentos = async (data) => {
        let respuesta;
        try {
            respuesta = await clienteAxios.post('/get_invoices', data);
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }

        return respuesta?.data?.data;
    };

    const obtenerListadoFacturas = async (datos, page = 1) => {
        setLoadingTablaFactura(true);

        // Parametros
        const {
            date_start: dateStart,
            date_end: dateEnd,
            franchise_ids: franchiseIds,
            state,
            invoice_number,
        } = datos;
        const voucherTypeId = 1;

        let franchiseIdArray = [];
        if (franchiseIds !== '' && franchiseIds !== null) {
            franchiseIdArray.push(franchiseIds);
        }
        setFechas({ date_start: dateStart, date_end: dateEnd });

        let params = {
            date_start: dateStart ? dateStart : undefined,
            date_end: dateEnd ? dateEnd : undefined,
            franchise_ids: franchiseIdArray.filter(
                (el) => el !== '' && el !== null && el !== undefined,
            ),
            state: state ? state : undefined,
            invoice_number: invoice_number,
            voucher_type_id: voucherTypeId,
            page,
        };

        const listaDocumentos = await obtenerListaDocumentos(params);

        if (listaDocumentos) {
            dispatch({
                type: OBTENER_LISTADO_EMISION_FACTURAS,
                payload: listaDocumentos?.invoices,
            });
            dispatch({
                type: FACTURAS_TOTALES,
                payload: listaDocumentos?.total,
            });
        }

        setLoadingTablaFactura(false);
    };

    const recibosObtenerListado = async (parametros) => {
        dispatch({
            type: RECIBOS_LISTA_LOADING,
        });

        const listaDocumentos = await obtenerListaDocumentos(parametros);

        // Extraer datos relevantes
        const recibos = listaDocumentos?.invoices.map((recibo) => ({
            key: recibo.invoice.id,
            idFactura: recibo.invoice.id,
            nroRecibo: recibo.invoice.invoice_number,
            nombreFranquicia: recibo.franchise_society.name,
            nombreLegal: recibo.franchise_society.legal_name,
            ruc: recibo.franchise_society.ruc,
            estado: recibo.invoice_state.name,
            total: recibo.invoice.amount,
            idFranquicia: recibo.franchise_society.id,
            secret: recibo.invoice.secret,
        }));

        dispatch({
            type: RECIBOS_OBTENER_LISTA,
            payload: {
                recibosLista: recibos,
                recibosTotalPaginas: listaDocumentos?.total || 1,
            },
        });

        dispatch({
            type: RECIBOS_LISTA_LOADING,
        });
    };

    const cambiarEstadoDocumento = async (data) => {
        try {
            const resp = await clienteAxios.post('/change_invoice_state', data);
            message.success(resp?.data.data.message);
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }
    };

    const cambiarEstadoFactura = async (
        idFactura,
        estado,
        seleccionarTodos,
        facturasExcluidas,
        enviarNotificacion,
    ) => {
        setLoadingModalOk(true);
        let data = {
            invoices: seleccionarTodos ? [] : idFactura,
            state_id: estado,
            select_all: seleccionarTodos,
            exclude_invoices: facturasExcluidas,
            send_email: enviarNotificacion,
        };
        await cambiarEstadoDocumento(data);
        setLoadingModalOk(false);
        // TODO: Ver como refrescar la tabla y quitar los rows seleccionados por antd
        estado === 2 && location.reload();
        // renderizar de nuevo al terminar la ejecucion
        obtenerListadoFacturas(fechas, state.paginaActualFactura);
    };

    const recibosAnular = async (idFactura) => {
        dispatch({
            type: RECIBOS_MODAL_LOADING,
        });

        let params = {
            invoices: [idFactura],
            state_id: 4, // 4 = anulado
        };

        await cambiarEstadoDocumento(params);

        dispatch({
            type: RECIBOS_MODAL_LOADING,
        });
    };

    const seleccionarPaginadoTabla = async (page) => {
        dispatch({
            type: SELECCIONAR_PAGINA_FACTURA,
            payload: page,
        });
    };

    /**
     *
     * @param {array} arrIdFactura
     * @param {boolean} enviarEmail
     * @param {boolean} enviarSMS
     * @param {boolean} todoLosIdsSeleccionados
     * @param {string} facturasExcluidas
     *
     */
    const enviarNotificacion = async (
        arrIdFactura,
        enviarEmail,
        enviarSMS,
        todoLosIdsSeleccionados,
        facturasExcluidas,
    ) => {
        const data = {
            arrayId: arrIdFactura,
            select_all: todoLosIdsSeleccionados,
            exclude_invoices: facturasExcluidas,
        };

        dispatch({
            type: LOADING_NOTIFICACION,
        });

        if (enviarEmail) {
            try {
                const resp = await clienteAxios.post(
                    '/send_invoice_email',
                    data,
                );
                message.success(resp?.data.message);
            } catch (error) {
                message.error(error?.response?.data?.message || error);
            }
        }

        if (enviarSMS) {
            try {
                const resp = await clienteAxios.post('/send_invoice_sms', data);
                message.success(resp?.data.message);
            } catch (error) {
                message.error(error?.response?.data?.message || error);
            }
        }

        dispatch({
            type: LOADING_NOTIFICACION,
        });
    };

    const descargarPdf = async (voucherTypeId, id, secret) => {
        message.info({
            content: 'Generando archivo...',
            key: 'descargar',
            duration: 0,
            icon: <LoadingOutlined />,
        });

        const params = {
            invoice_id: id,
            voucher_type_id: voucherTypeId,
            secret,
            quantity: 3,
        };

        try {
            const respuesta = await clienteAxios.get(
                `/get_invoice_without_token`,
                { params: params },
            );

            // eslint-disable-next-line no-undef
            const rawFile = Buffer.from(respuesta.data.data.body, 'base64');
            const fileName = `${
                voucherTypeId === 1 ? 'factura' : 'recibo'
            }-${id}`;
            const fileType = 'pdf';

            descargarArchivoRaw(rawFile, fileName, fileType);
        } catch (error) {
            message.destroy('descargar');
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al generar su archivo',
            );
        }
        message.destroy('descargar');
    };

    // TODO: Mover logica de exportar PDF aqui para facturas y recibos
    // const exportarPdf = () => {};

    return (
        <EmisionDocumentosContext.Provider
            value={{
                listadoFacturas: state.listadoFacturas,
                paginaActualFactura: state.paginaActualFactura,
                totalFacturas: state.totalFacturas,
                facturaExito: state.facturaExito,
                loadingNotificacion: state.loadingNotificacion,

                recibosLista: state.recibosLista,
                recibosTotalPaginas: state.recibosTotalPaginas,
                recibosListaLoading: state.recibosListaLoading,
                recibosModalLoading: state.recibosModalLoading,

                obtenerListadoFacturas,
                cambiarEstadoFactura,
                seleccionarPaginadoTabla,
                enviarNotificacion,
                recibosObtenerListado,
                recibosAnular,
                loadingTablaFactura,
                loadingModalOk,
                descargarPdf,
            }}>
            {children}
        </EmisionDocumentosContext.Provider>
    );
};

export default EmisionDocumentosState;

EmisionDocumentosState.propTypes = {
    children: PropTypes.object,
};
