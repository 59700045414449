import React from 'react';
import { Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import classes from './AprobacionPedidoAgendado.module.css';
import PropTypes from 'prop-types';
import useSupport from '../../../../hooks/support/useSupport';

const AprobacionPedidoAgendado = ({ ordenOriginal }) => {
    const { abrirAceptarAgendado, abrirRechazarAgendado } = useSupport();
    const orderId = ordenOriginal.order_state_id;
    let disable;

    if (orderId === 10 || orderId === 12) {
        disable = true;
    }
    return (
        <div className={classes.containerButtons}>
            <Button
                disabled={disable}
                icon={<CheckOutlined color="white" />}
                className={classes.buttonAceptar}
                onClick={() => abrirAceptarAgendado(ordenOriginal)}>
                Aceptar
            </Button>
            <Button
                disabled={disable}
                icon={<CloseOutlined />}
                className={classes.buttonRechazar}
                onClick={() => abrirRechazarAgendado(ordenOriginal)}>
                Rechazar
            </Button>
        </div>
    );
};

AprobacionPedidoAgendado.propTypes = {
    ordenOriginal: PropTypes.any,
};

export default AprobacionPedidoAgendado;
