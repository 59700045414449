/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, Form, message, Space } from 'antd';
import useListPromsBanners from 'components/hooks/useListPromsBanners';

import styles from './NewPromBanner.module.css';
import ABMLayout from 'layouts/ABMLayout';
import GeneralInfo from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/GeneralInfo';
import CombineCharacteristics from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/CombineCharacteristics';
import SelectProducts from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/SelectProducts';
import SelectedAudience from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/SelectedAudience';
import ProgramPromotions from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/ProgramPromotions';
import useNewPromBanner from 'components/hooks/useNewPromBanner';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import moment from 'moment';

const NewOrEditPromBanner = () => {
    const { setCharacteristics, setSelectedProducts } =
        useContext(PromotionsContext);
    const { getBannerData } = useListPromsBanners();
    const { handleCreateOrEditBanner } = useNewPromBanner();
    const [bannersForm] = Form.useForm();
    const { id } = useParams();
    const history = useHistory();

    const [loader, setLoader] = useState();
    const [imageUrl, setImageUrl] = useState('');
    const [initialSchedule, setInitialSchedule] = useState([]);

    const [cantidadUsuariosSeleccionados, setCantidadUsuariosSeleccionados] =
        useState(); // Total de usuarios en la BD
    const [tipoZona, setTipoZona] = useState('1');
    const [restablecerDeeplink, setRestablecerDeeplink] = useState(false);
    const [alcanceLoading, setAlcanceLoading] = useState(false);
    const [coverageZone, setCoverageZone] = useState([]);
    const [coordenates, setCoordenates] = useState([]);
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (id) {
            setLoader(true);
            let formData;
            getBannerData(id).then((data) => {
                setIsValid(true);
                setImageUrl(data?.general?.banner_img);

                const transformedData = data?.features?.detail?.map((item) => ({
                    family:
                        item?.franchise_family_id && item?.familia
                            ? `${item.franchise_family_id} | ${item.familia}`
                            : 'all',
                    franchisesCategory:
                        item?.franchise_category_id && item?.franchise_category
                            ? `${item.franchise_category_id} | ${item.franchise_category}`
                            : 'all',
                    franchises:
                        item?.franchise_id && item?.franchise
                            ? `${item.franchise_id} | ${item.franchise}`
                            : 'all',
                    branches:
                        item?.branch_id && item?.branch
                            ? `${item.branch_id} | ${item.branch}`
                            : 'all',
                }));

                setCharacteristics(transformedData);

                const transformedProducts = data?.products?.map((item) => ({
                    product_id: item?.product_id,
                    product_name: item?.product_name,
                    franchise_name: item?.franchise_name,
                }));
                setSelectedProducts(transformedProducts);

                const transformDate = (dateString) => {
                    return dateString ? moment(new Date(dateString)) : null;
                };

                const spanishToEnglishDays = {
                    Lunes: 'monday',
                    Martes: 'tuesday',
                    Miercoles: 'wednesday',
                    Miércoles: 'wednesday',
                    Jueves: 'thursday',
                    Viernes: 'friday',
                    Sábado: 'saturday',
                    Sabado: 'saturday',
                    Domingo: 'sunday',
                };

                const formatTime = (timeString) => {
                    const [hours, minutes, seconds] = timeString.split(':');
                    const today = new Date();
                    today.setHours(hours);
                    today.setMinutes(minutes);
                    today.setSeconds(seconds);
                    today.setMilliseconds(0);
                    return today.toISOString();
                };

                const transformedProgramation = data?.programation?.week
                    ? data?.programation?.week?.map((item) => ({
                          promotionDay: spanishToEnglishDays[item.day_name],
                          promotionHourFrom: item.time_from
                              ? formatTime(item.time_from)
                              : null,
                          promotionHourTo: item.time_to
                              ? formatTime(item.time_to)
                              : null,
                      }))
                    : [];
                setInitialSchedule(transformedProgramation);

                const transformedCoverage = data?.coverage?.coverage_zone?.map(
                    (item) => ({
                        franquicia: item?.franchise,
                        franquiciaId: item?.franchise_id,
                        sucursal: item?.branch,
                        sucursalId: item?.branch_id,
                        zona: item?.zone_name,
                        zonaId: item?.zone_id,
                    }),
                );

                setCoverageZone(transformedCoverage);

                const transformedCustomZone = data?.coverage?.custom_zone?.map(
                    (item) => ({
                        ciudad: item && item.city ? `${item.city}` : null,
                        barrio:
                            item && item.neighborhood
                                ? `${item.neighborhood}`
                                : null,
                    }),
                );

                const transformedCoordenates =
                    data?.coverage?.geographical_zone[0]?.geo_zone?.coordinates
                        ?.map((item) =>
                            item.map((coord) => ({
                                lat: coord[1],
                                lng: coord[0],
                            })),
                        )
                        .flat();

                setCoordenates(transformedCoordenates);

                formData = {
                    promotionName: data?.general?.name,
                    showAs: data?.general?.show,
                    showHome: data?.general?.show_in_home,
                    onlinePay: data?.features?.has_bancard,
                    monchisDriver: data?.features?.has_monchis_driver,
                    exclusiveLocal: data?.features?.exclusive,
                    promotionDateFrom: transformDate(data?.programation?.from),
                    promotionDateTo: transformDate(data?.programation?.to),
                    audience: data?.coverage?.audiencia,
                    ciudad:
                        transformedCustomZone &&
                        transformedCustomZone.length > 0
                            ? transformedCustomZone[0]?.ciudad
                            : null,
                    barrio:
                        transformedCustomZone &&
                        transformedCustomZone.length > 0
                            ? transformedCustomZone[0]?.barrio
                            : null,
                };
                bannersForm.setFieldsValue(formData);
                setLoader(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bannersForm, getBannerData, id]);

    const onCancel = () => {
        bannersForm.resetFields();
        setCharacteristics([]);
        setSelectedProducts([]);
        history.push('/programa-recompensa/promociones/banners-promo/');
    };

    const onSave = () => {
        bannersForm
            .validateFields()
            .then(async (fields) => {
                try {
                    if (!isValid) {
                        message.error(
                            'Debe seleccionar al menos una zona de cobertura o audiencia',
                        );
                    } else {
                        setLoader(true);
                        await handleCreateOrEditBanner(fields, id);
                        bannersForm.resetFields();
                        setCharacteristics([]);
                        setSelectedProducts([]);
                    }
                } catch (error) {
                    setLoader(false);
                    message.error(error.message);
                }
            })
            .catch((error) => {
                message.error(
                    'Error al guardar: Verifique los campos requerido',
                    [3],
                );
            });
    };

    const actionButtons = (
        <div className={styles.actionsButtons}>
            <Button type="link" onClick={onCancel}>
                Cancelar
            </Button>
            <Button type="primary" onClick={onSave}>
                Guardar
            </Button>
        </div>
    );

    const title = id ? 'Editar banner promocional' : 'Crear banner promocional';

    const isDisabled = !!id;

    const handleZoneValidation = (valid) => {
        setIsValid(valid);
    };

    return (
        <ABMLayout titulo={title} acciones={actionButtons} loading={loader}>
            <Card>
                <Form layout="vertical" form={bannersForm}>
                    <GeneralInfo
                        imageUrl={imageUrl}
                        formInstance={bannersForm}
                        disabled={isDisabled}
                    />
                    <Space className={styles.space}>
                        <CombineCharacteristics
                            formInstance={bannersForm}
                            disabled={isDisabled}
                        />
                        <SelectProducts
                            formInstance={bannersForm}
                            disabled={isDisabled}
                        />
                    </Space>
                    <ProgramPromotions
                        formInstance={bannersForm}
                        initialSchedule={initialSchedule}
                        disabled={isDisabled}
                    />
                    <SelectedAudience
                        form={bannersForm}
                        tipoZona={tipoZona}
                        setCantidadUsuariosSeleccionados={
                            setCantidadUsuariosSeleccionados
                        }
                        setTipoZona={setTipoZona}
                        setRestablecerDeeplink={setRestablecerDeeplink}
                        setAlcanceLoading={setAlcanceLoading}
                        coverageZone={coverageZone}
                        coordenates={coordenates}
                        disabled={isDisabled}
                        handleZoneValidation={handleZoneValidation}
                    />
                </Form>
            </Card>
        </ABMLayout>
    );
};

export default NewOrEditPromBanner;
