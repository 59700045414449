import React from 'react';
const Lupa = (props) => {
    return (
        <svg
            {...props}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="7">
                <g id="Group">
                    <path
                        id="Vector"
                        d="M10.5907 17.871C14.6116 17.871 17.8713 14.6114 17.8713 10.5904C17.8713 6.56945 14.6116 3.30981 10.5907 3.30981C6.5697 3.30981 3.31006 6.56945 3.31006 10.5904C3.31006 14.6114 6.5697 17.871 10.5907 17.871Z"
                        stroke="#85878E"
                        strokeWidth="1.3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        id="Vector_2"
                        d="M15.7427 15.7422L20.0004 20"
                        stroke="#85878E"
                        strokeWidth="1.3"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Lupa;
