import React, { useContext, useEffect, useState } from 'react';
import ClientesContext from '../../../../context/usuario/Clientes/ClientesContext';
import { Input, Table, Form, Button, Row, Col, Modal, Spin, Card } from 'antd';
import { ExclamationCircleOutlined, SearchOutlined } from '@ant-design/icons';
import ABMLayout from '../../../../layouts/ABMLayout';

export const ListaClientes = () => {
    const {
        obtenerClientes,
        listaClientes,
        totalClientes,
        seleccionarPaginadoTabla,
        paginaActualClientes,
        loadingTabla,
        revalidarNumero,
        confirmarNumero,
    } = useContext(ClientesContext);
    const [obtenerValoresForm, setObtenerValoresForm] = useState({});
    const [clientesForm] = Form.useForm();

    useEffect(() => {
        let sgtePagina = {
            ...obtenerValoresForm,
            page: paginaActualClientes,
        };
        obtenerClientes(sgtePagina);
    }, [paginaActualClientes]);

    const listaClientesTabla = listaClientes.map((cliente) => {
        const {
            first_name: firstName,
            last_name: lastName,
            email,
            id,
            phone,
            phone_confirmed_at: phoneConfirmedAt,
        } = cliente;
        return {
            id,
            nombre: `${firstName} ${lastName}`,
            correo: email,
            telefono: phone,
            telefonoConfirmado: phoneConfirmedAt ? 'Si' : 'No',
        };
    });

    const confirmarButtonHandler = async (tipo, datos) => {
        if (tipo === 'restablecer') {
            await revalidarNumero(datos?.id);
        } else {
            await confirmarNumero(datos?.id);
        }
        obtenerClientes();
    };

    const confirmar = (datos, tipo) => {
        Modal.confirm({
            title:
                tipo === 'confirmar'
                    ? 'Estás seguro de confirmar número de teléfono'
                    : 'Estás seguro de restablecer intentos?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Aceptar',
            cancelText: 'Cancelar',
            onOk: () => confirmarButtonHandler(tipo, datos),
        });
    };

    const handleSubmit = (valores) => {
        let datos = {
            q: valores?.busqueda,
            page: 1,
        };
        obtenerClientes(datos);
        setObtenerValoresForm(datos);
        seleccionarPaginadoTabla(1);
    };

    const changePage = (page) => {
        seleccionarPaginadoTabla(page);
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
        },
        {
            title: 'Correo',
            dataIndex: 'correo',
            key: 'correo',
        },
        {
            title: 'Teléfono',
            dataIndex: 'telefono',
            key: 'telefono',
            align: 'right',
        },
        {
            title: 'Teléfono confirmado',
            dataIndex: 'telefonoConfirmado',
            key: 'telefonoConfirmado',
            align: 'right',
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            key: 'acciones',
            align: 'center',
            render: (_, elm) => (
                <div style={{ display: 'inline-grid' }}>
                    <Button
                        shape="round"
                        type="primary"
                        style={{ marginBottom: '8px' }}
                        onClick={() => confirmar(elm, 'confirmar')}>
                        Confirmar teléfono
                    </Button>
                    <Button
                        type="link"
                        onClick={() => confirmar(elm, 'restablecer')}>
                        Restablecer intentos
                    </Button>
                </div>
            ),
        },
    ];

    return (
        <ABMLayout titulo="Usuarios pendientes con teléfono sin confirmar">
            <Card>
                <Spin spinning={loadingTabla}>
                    <Row gutter={12}>
                        <Col span={16}>
                            <Form
                                layout="vertical"
                                form={clientesForm}
                                onFinish={handleSubmit}
                                initialValues={{ filtro: 'email' }}>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="busqueda"
                                            rules={[{ required: true }]}>
                                            <Input
                                                prefix={<SearchOutlined />}
                                                placeholder="Buscar por nombre, correo o teléfono"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={4}>
                                        <Form.Item>
                                            <Button
                                                type="primary"
                                                htmlType="submit">
                                                Buscar
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row gutter={12}>
                        <Col span={24}>
                            <Table
                                columns={columns}
                                dataSource={listaClientesTabla}
                                size="middle"
                                rowKey="id"
                                pagination={{
                                    pageSize: 15,
                                    total: totalClientes,
                                    showSizeChanger: false,
                                    current: paginaActualClientes,
                                    onChange: (page) => changePage(page),
                                }}
                            />
                        </Col>
                    </Row>
                </Spin>
            </Card>
        </ABMLayout>
    );
};
