import {
    LISTADO_REPORTE_CUPONES,
    PAGINADO_REPORTE_CUPONES,
    REPORTE_CUPONES_LOADING,
    REPORTE_CUPONES_TOTALES,
    REPORTE_CUPONES_RESUMEN,
    REPORTE_CUPON_SELECCIONADO,
} from './types';

export const initialState = {
    listaReporteCupones: [],
    reporteSeleccionado: [],
    resumenReporte: [],
    paginaActualReporte: 1,
    totalReporte: 0,
    loading: false,
};

const ReporteComisionesReducer = (state = initialState, action) => {
    switch (action.type) {
        case LISTADO_REPORTE_CUPONES:
            return {
                ...state,
                listaReporteCupones: action.payload.listaReporteCupones,
            };
        case REPORTE_CUPON_SELECCIONADO:
            return {
                ...state,
                reporteSeleccionado: action.payload.reporteSeleccionado,
            };
        case REPORTE_CUPONES_LOADING: {
            return {
                ...state,
                loading: !state.loading,
            };
        }
        case PAGINADO_REPORTE_CUPONES:
            return {
                ...state,
                paginaActualReporte: action.payload.paginaActualReporte,
            };
        case REPORTE_CUPONES_TOTALES:
            return {
                ...state,
                totalReporte: action.payload.totalReporte,
            };
        case REPORTE_CUPONES_RESUMEN:
            return {
                ...state,
                resumenReporte: action.payload.resumenReporte,
            };
        default:
            return state;
    }
};

export default ReporteComisionesReducer;
