import { message } from 'antd';
import React, { useReducer, useState } from 'react';
import clienteAxios from '../../../../config/axios';
import DetalleComisionesContext from './DetalleComisionesContext';
import DetalleComisionesReducer, {
    initialState,
} from './DetalleComisionesReducer';
import {
    OBTENER_LISTADO_DETALLE_COMISIONES,
    SELECCIONAR_PAGINA_DETALLE_COMISION,
    TOTAL_DETALLE_COMISION,
} from './index';

const DetalleComisionesState = ({ children }) => {
    const [state, dispatch] = useReducer(
        DetalleComisionesReducer,
        initialState,
    );

    const [loadingTablaObtenerDetalle, setLoadingTablaObtenerDetalle] =
        useState();
    const [totalComision, setTotalComision] = useState(0);

    const obtenerDetalleComisiones = async (datos) => {
        setLoadingTablaObtenerDetalle(true);
        try {
            const { data } = await clienteAxios.get('commission_details', {
                params: datos,
            });
            dispatch({
                type: OBTENER_LISTADO_DETALLE_COMISIONES,
                payload: data?.data?.commission_details,
            });
            dispatch({
                type: TOTAL_DETALLE_COMISION,
                payload: data?.data?.total_rows,
            });
            setTotalComision(data?.data?.total_commission);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener datos, vuelva a intentarlo',
            );
        }
        setLoadingTablaObtenerDetalle(false);
    };

    const seleccionarPaginadoTabla = async (page) => {
        dispatch({
            type: SELECCIONAR_PAGINA_DETALLE_COMISION,
            payload: page,
        });
    };

    return (
        <DetalleComisionesContext.Provider
            value={{
                listadoDetalleComsiones: state.listadoDetalleComisiones,
                paginaActualDetalleComisiones:
                    state.paginaActualDetalleComisiones,
                totalDetalleComisiones: state.totalDetalleComisiones,
                totalComision,

                obtenerDetalleComisiones,
                seleccionarPaginadoTabla,
                loadingTablaObtenerDetalle,
            }}>
            {children}
        </DetalleComisionesContext.Provider>
    );
};

export default DetalleComisionesState;
