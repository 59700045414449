import React, { useEffect } from 'react';
import ABMLayout from 'layouts/ABMLayout';
import styles from './ListPromsBanners.module.css';
import { Button, Form, Input, Row, Col, Card, Select, Table, Spin } from 'antd';
import DeactivatePromotionModal from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/DeactivatePromotionModal';
import ActivatePromotionModal from 'components/organisms/ProgramaRecompensas/Promociones/BannersPromo/ActivatePromotionModal';
import ProtectedComponent from 'hoc/ProtectedComponent';
import { Permisos } from 'constants/Permisos';
import useListPromsBanners from 'components/hooks/useListPromsBanners';
import filterOption from 'components/utils-components/filterOption';
import { useGetFilters } from '../../../hooks/ProgramaRecompensa/Promociones/useGetFilters';
import { useGetPromotions } from '../../../hooks/ProgramaRecompensa/Promociones/useGetPromotions';

const ListPromsBanners = () => {
    const {
        loading,
        columns,
        dataSource,
        pageSize,
        total,
        currentPage,

        goToNewPromView,
    } = useListPromsBanners();

    const { franchises, branches, form, setFranchiseSelected } =
        useGetFilters();
    const { onFinish, onPageChange } = useGetPromotions(form);

    useEffect(() => {
        onFinish(form.getFieldsValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { Option } = Select;

    const NewPromButton = () => (
        <ProtectedComponent
            permiso={Permisos.programaRecompensa.promociones.crearEditarBanner}>
            <Button type="primary" onClick={goToNewPromView}>
                Nueva promo
            </Button>
        </ProtectedComponent>
    );

    return (
        <ABMLayout
            titulo="Banners promocionales"
            loading={false}
            acciones={<NewPromButton />}>
            <Card>
                <Row>
                    <Form
                        layout="vertical"
                        form={form}
                        disabled={loading}
                        onFinish={onFinish}
                        initialValues={{
                            state: 'actives-and-inactives',
                            franchise: 'all',
                            branch: 'all',
                        }}>
                        <Row gutter={15} align="middle">
                            <Col span={1 / 7}>
                                <Form.Item
                                    label="Nombre de la promoción"
                                    name="promotion"
                                    className={styles.itemSize}>
                                    <Input className={styles.inputSize} />
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Form.Item
                                    label="Estado de la promoción"
                                    name="state"
                                    className={styles.itemSize}>
                                    <Select className={styles.inputSize}>
                                        <Option value="actives-and-inactives">
                                            Activos e inactivos
                                        </Option>
                                        <Option value="actives">
                                            Solo activos
                                        </Option>
                                        <Option value="inactives">
                                            Solo inactivos
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Form.Item label="Franquicia" name="franchise">
                                    <Select
                                        showSearch
                                        maxTagCount="responsive"
                                        onChange={setFranchiseSelected}
                                        className={styles.selectWith}
                                        optionFilterProp="children"
                                        filterOption={filterOption}>
                                        <Option value="all">Todas</Option>
                                        {franchises?.map((franchise) => (
                                            <Option
                                                value={franchise.id}
                                                key={franchise.id}>
                                                {franchise.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Form.Item label="Sucursal" name="branch">
                                    <Select
                                        showSearch
                                        maxTagCount="responsive"
                                        className={styles.selectWith}
                                        optionFilterProp="children"
                                        filterOption={filterOption}>
                                        <Option value="all">Todas</Option>
                                        {branches?.map((branch) => (
                                            <Option
                                                value={branch.id}
                                                key={branch.id}>
                                                {branch.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={1 / 7}>
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className={styles.buttonSize}>
                                    Buscar
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Row>

                <Row>
                    <Col span={24}>
                        <Spin spinning={loading}>
                            <Table
                                columns={columns}
                                dataSource={dataSource}
                                pagination={{
                                    current: currentPage,
                                    onChange: (page) => onPageChange(page),
                                    pageSize: pageSize,
                                    showSizeChanger: false,
                                    defaultCurrent: currentPage,
                                    total: total,
                                }}
                            />
                        </Spin>
                    </Col>
                </Row>
            </Card>

            <ActivatePromotionModal />
            <DeactivatePromotionModal />
        </ABMLayout>
    );
};

export default ListPromsBanners;
