import React, { useContext } from 'react';
import {
    Form,
    Button,
    Card,
    Row,
    Col,
    Select,
    // Checkbox,
    Typography,
    message,
} from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import styles from './CombineCharacteristics.module.css';
import useNewPromBanner from 'components/hooks/useNewPromBanner';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';
import PropTypes from 'prop-types';
import useFranchisesLoader from 'components/hooks/ProgramaRecompensa/Promociones/useFranchisesLoader';
import filterOption from 'components/utils-components/filterOption';

const CombineCharacteristics = ({ formInstance, disabled }) => {
    const { Option } = Select;
    const { Text } = Typography;
    const {
        characteristics,
        selectedFranchises,

        setSelectedFranchises,
        setSelectedBranches,
    } = useContext(PromotionsContext);
    const { franchiseCategoriesByFamily } = useContext(UtilidadesContext);

    const {
        franchises,
        branches,
        obtainingBranches,
        obtainingCategories,
        obtainingFranchises,
        selectedFamily,
        selectedFranchiseCategory,

        handleFamilyChange,
        handleFranchiseChange,
        handleBranchChange,
        handleRemove,
        handleAddCharacteristic,
        getTextOption,
        handleCategoryChange,
        setSelectedFamily,
        setSelectedFranchiseCategory,
    } = useNewPromBanner();

    const { families } = useFranchisesLoader();

    const onAddCharacteristics = () => {
        const values = formInstance.getFieldsValue();
        const newCharacteristic = {
            family: values?.family,
            franchisesCategory: values?.franchisesCategory,
            franchises: values?.franchises,
            branches: values?.branches,
        };

        // Verificar si la sucursal ya fue agregada
        const branchAlreadyAdded = characteristics.some(
            (characteristic) =>
                characteristic.branches === newCharacteristic.branches,
        );

        if (branchAlreadyAdded) {
            message.warning('Esta sucursal ya ha sido agregada.');
            return;
        }

        handleAddCharacteristic(newCharacteristic);
        const addedCharacteristics = [...characteristics, newCharacteristic];
        formInstance.resetFields([
            'family',
            'franchisesCategory',
            'franchises',
            'branches',
        ]);

        formInstance.setFieldsValue({ details: addedCharacteristics });

        setSelectedFamily(null);
        setSelectedFranchiseCategory(null);
        setSelectedFranchises(null);
        setSelectedBranches(null);
    };

    const isSelected = () => {
        const family =
            formInstance.getFieldValue('family') !== undefined &&
            formInstance.getFieldValue('family') !== null;
        const franchisesCategory =
            formInstance.getFieldValue('franchisesCategory') !== undefined &&
            formInstance.getFieldValue('franchisesCategory') !== null;
        const franchises =
            formInstance.getFieldValue('franchises') !== undefined &&
            formInstance.getFieldValue('franchises') !== null;
        const branches =
            formInstance.getFieldValue('branches') !== undefined &&
            formInstance.getFieldValue('branches') !== null;

        return family && franchisesCategory && franchises && branches;
    };

    return (
        <>
            <Card
                type="inner"
                title="Combinar características"
                className={styles.card}>
                {/* <Row>
                    <Form.Item name="onlinePay" valuePropName="checked">
                        <Checkbox
                            disabled={disabled}
                            className={styles.checkboxOption}
                            key="onlinePay">
                            Pago online
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="monchisDriver" valuePropName="checked">
                        <Checkbox
                            disabled={disabled}
                            className={styles.checkboxOption}
                            key="monchisDriver">
                            Driver Monchis
                        </Checkbox>
                    </Form.Item>

                    <Form.Item name="exclusiveLocal" valuePropName="checked">
                        <Checkbox
                            disabled={disabled}
                            className={styles.checkboxOption}
                            key="exclusiveLocal">
                            Local exclusivo
                        </Checkbox>
                    </Form.Item>
                </Row> */}

                <Form.Item name="details">
                    <Row className={styles.characteristicsRow}>
                        <Form.Item
                            label="Familia"
                            name="family"
                            className={styles.characteristicsFilter}>
                            <Select
                                disabled={disabled}
                                placeholder="Seleccionar"
                                loading={!families?.length}
                                onChange={handleFamilyChange}>
                                {families?.map((family) => (
                                    <Option
                                        key={family?.id}
                                        value={`${family?.id}| ${family?.name}`}>
                                        {family?.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Categoría de franquicias"
                            name="franchisesCategory"
                            className={styles.characteristicsFilter}>
                            <Select
                                placeholder="Seleccionar"
                                loading={obtainingCategories}
                                onChange={handleCategoryChange}
                                disabled={
                                    obtainingCategories || !selectedFamily
                                }>
                                {franchiseCategoriesByFamily?.map(
                                    (category) => (
                                        <Option
                                            key={category?.id}
                                            value={`${category?.id}|${category?.name}`}>
                                            {category?.name}
                                        </Option>
                                    ),
                                )}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Franquicia"
                            name="franchises"
                            className={styles.characteristicsFilter}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder="Seleccionar"
                                onChange={handleFranchiseChange}
                                loading={obtainingFranchises}
                                disabled={
                                    obtainingFranchises ||
                                    !selectedFranchiseCategory
                                }>
                                {franchises?.map((franchise) => (
                                    <Option
                                        key={franchise?.id}
                                        value={`${franchise?.id}|${franchise?.name}`}>
                                        {franchise?.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label="Sucursal"
                            name="branches"
                            className={styles.characteristicsFilter}>
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder="Seleccionar"
                                loading={obtainingBranches}
                                disabled={
                                    obtainingBranches ||
                                    !selectedFranchises ||
                                    branches?.length === 0
                                }
                                onChange={handleBranchChange}>
                                {branches.map((branch) => (
                                    <Option
                                        key={branch?.id}
                                        value={`${branch?.id}|${branch?.name}`}>
                                        {branch?.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Row>

                    <Row className={styles.marginTop30px}>
                        <Col span={24}>
                            <Button
                                disabled={disabled || !isSelected()}
                                type="dashed"
                                block
                                onClick={onAddCharacteristics}
                                icon={<PlusOutlined />}>
                                Agregar características
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            {characteristics?.map((characteristic, index) => (
                                <div
                                    key={`${index} ${characteristic}`}
                                    className={styles.characteristicsWrapper}>
                                    <div
                                        className={
                                            styles.characteristicsTextWrapper
                                        }>
                                        <Text>Familia: </Text>
                                        <Text strong>
                                            {!characteristic?.family
                                                ? 'Todas'
                                                : getTextOption(
                                                      characteristic?.family,
                                                  )}
                                        </Text>

                                        <span>|</span>

                                        <Text>Categoría: </Text>
                                        <Text strong>
                                            {!characteristic?.franchisesCategory
                                                ? 'Todas'
                                                : getTextOption(
                                                      characteristic?.franchisesCategory,
                                                  )}
                                        </Text>

                                        <span>|</span>

                                        <Text>Franquicia: </Text>
                                        <Text strong>
                                            {!characteristic?.franchises
                                                ? 'Todas'
                                                : getTextOption(
                                                      characteristic?.franchises,
                                                  )}
                                        </Text>

                                        <span>|</span>

                                        <Text>Sucursal: </Text>
                                        <Text strong>
                                            {!characteristic?.branches
                                                ? 'Todas'
                                                : getTextOption(
                                                      characteristic?.branches,
                                                  )}
                                        </Text>
                                    </div>

                                    <MinusCircleOutlined
                                        hidden={disabled}
                                        onClick={() => handleRemove(index)}
                                        className={styles.removeButton}
                                    />
                                </div>
                            ))}
                        </Col>
                    </Row>
                </Form.Item>
            </Card>
        </>
    );
};

export default CombineCharacteristics;

CombineCharacteristics.propTypes = {
    formInstance: PropTypes.object,
    disabled: PropTypes.bool,
};
