/**
 * getBase64
 * @param {Object} file Debe ser un objeto de tipo File
 */
const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        if (typeof file !== 'object')
            return reject('Lo sentimos, no hemos podido procesar la imagen');

        let baseURL = '';
        let reader = new FileReader();

        reader.readAsDataURL(file);

        reader.onload = () => {
            if (reader.error) {
                reject('Lo sentimos, no hemos podido procesar la imagen');
            } else {
                baseURL = reader.result;
                resolve(baseURL);
            }
        };
    });
};

export default getBase64;
