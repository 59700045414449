import React from 'react';
import {
    Checkbox,
    Col,
    Form,
    Input,
    InputNumber,
    Modal,
    Row,
    Select,
} from 'antd';
import PropTypes from 'prop-types';
import style from './newProductModalStyle.module.css';

// import AdministrarContext from '../../../context/administrar/AdministrarContext';
// const {Option} = Select;

const { TextArea } = Input;

const NewProductModal = ({ isVisible, onConfirm, onCancel }) => {
    const onChange = (checkedValues) => {
        console.log('checked = ', checkedValues);
    };

    const checkFilters = [
        {
            label: 'Combo',
            value: 'Combo',
        },
        {
            label: 'Promoción',
            value: 'Promoción',
        },
        {
            label: 'Producto para cupones',
            value: 'Producto para cupones',
        },
        {
            label: 'Producto destacado',
            value: 'Producto destacado',
        },
    ];

    const modalContent = (
        <>
            <Row className={style.flexBetween}>
                <Col className={style.colsStyle}>
                    <Form.Item
                        name="category"
                        label="Categorías"
                        rules={[{ required: true }]}>
                        <Select>
                            {/* TO-DO: to connect when endpoints available */}
                            {/* {categoryList.map((category) => (
                                <Option value={category.id} key={category.id}>
                                    {category.name.toUpperCase()}
                                </Option>
                            ))} */}
                        </Select>
                    </Form.Item>
                </Col>
                <Col className={style.colsStyle}>
                    <Form.Item
                        name="name"
                        label="Nombre"
                        rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col className={style.width100}>
                    <Form.Item
                        name="description"
                        label="Descripción"
                        rules={[{ required: true }]}>
                        <TextArea rows={2} maxLength={200} />
                    </Form.Item>
                </Col>
            </Row>
            <Row className={style.flexBetween}>
                <Col>
                    <Form.Item
                        name="price"
                        label="Precio"
                        rules={[{ required: true }]}>
                        <InputNumber
                            min={1}
                            max={10}
                            className={style.width100}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="barcode"
                        label="Código de barras"
                        rules={[{ required: true }]}>
                        <InputNumber
                            min={1}
                            max={10}
                            defaultValue={'1234 5678 0123'}
                            className={style.width100}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col className={style.width100}>
                    <Checkbox.Group
                        options={checkFilters}
                        defaultValue={['Apple']}
                        onChange={onChange}
                    />
                </Col>
            </Row>
        </>
    );

    return (
        <Modal
            destroyOnClose
            open={isVisible}
            closable={null}
            title={'Nuevo producto'}
            okText="Guardar"
            cancelText="Cancelar"
            width={'45vw'}
            onOk={onConfirm}
            onCancel={() => {
                onCancel();
            }}
            isLoading={!isVisible}>
            {modalContent}
        </Modal>
    );
};

NewProductModal.propTypes = {
    isVisible: PropTypes.any,
    onConfirm: PropTypes.any,
    onCancel: PropTypes.any,
};

export default NewProductModal;
