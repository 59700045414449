import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row, Table, Typography, Card } from 'antd';
import classes from './reporteCupones.module.css';
import { useHistory } from 'react-router-dom';
import ReporteCuponesContext from '../../../../../context/reportes/reporteCupones/ReporteCuponesContext';
import ABMLayout from '../../../../../layouts/ABMLayout';

const { Text } = Typography;

const column = [
    {
        title: 'DATOS DEL CUPÓN',
        dataIndex: 'datosCupon',
        key: 'datosCupon',
        render: (text) => <strong>{text}</strong>,
    },
    {
        title: 'DETALLE',
        dataIndex: 'detalle',
        key: 'detalle',
    },
];

const ReporteVerCupones = () => {
    const [loading, setLoading] = useState(true);
    const { reporteSeleccionado } = useContext(ReporteCuponesContext);
    const {
        fecha,
        usuarioId,
        usuario,
        codigoDescuento,
        montoDescuento,
        montoTotalPedido,
        estado,
        franquicia,
        motivo,
        gastadoAntes,
        comisionAntes,
        totalGastadoDespues,
        totalComisionDespues,
        comisionOrden,
    } = reporteSeleccionado;
    const history = useHistory();
    const volver = () => {
        history.goBack();
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            setLoading(false);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    //TODO: refactorizar para que sea dinamico
    const data = [
        {
            key: 1,
            datosCupon: 'Fecha del pedido',
            detalle: fecha || 'No disponible',
        },
        {
            key: 2,
            datosCupon: 'ID usuario',
            detalle: usuarioId || 'No disponible',
        },
        {
            key: 3,
            datosCupon: 'Usuario',
            detalle: usuario || 'No disponible',
        },
        {
            key: 4,
            datosCupon: 'Franquicia',
            detalle: franquicia || 'No disponible',
        },
        {
            key: 5,
            datosCupon: 'Estado del pedido',
            detalle: estado || 'No disponible',
        },
        {
            key: 6,
            datosCupon: 'Total del pedido',
            detalle: montoTotalPedido || 'No disponible',
        },
        {
            key: 7,
            datosCupon: 'Comisión del pedido',
            detalle: comisionOrden || 'No disponible',
        },
        {
            key: 8,
            datosCupon: 'Monto gastado antes',
            detalle: gastadoAntes || 'No disponible',
        },
        {
            key: 9,
            datosCupon: 'Comisión antes',
            detalle: comisionAntes || 'No disponible',
        },
        {
            key: 10,
            datosCupon: 'Monto gastado después',
            detalle: totalGastadoDespues || 'No disponible',
        },
        {
            key: 11,
            datosCupon: 'Comisión después',
            detalle: totalComisionDespues || 'No disponible',
        },
    ];

    return (
        <ABMLayout
            titulo="Detalle del cupón"
            acciones={<Button onClick={volver}>Volver</Button>}
            loading={loading}>
            <Card className={classes.content}>
                <Row className={classes.cuadradoHeader}>
                    <Col span={7} className={classes.marginCodigo}>
                        <Row>
                            <Text strong>CÓDIGO DE DESCUENTO:</Text>
                        </Row>
                        <Row>
                            <Text>{codigoDescuento || 'No disponible'}</Text>
                        </Row>
                    </Col>

                    <Col span={7}>
                        <Row>
                            <Text strong>MONTO DE DESCUENTO:</Text>
                        </Row>
                        <Row>
                            <Text>{montoDescuento || 'No disponible'}</Text>
                        </Row>
                    </Col>

                    <Col span={7}>
                        <Row>
                            <Text strong>MOTIVO DEL DESCUENTO:</Text>
                        </Row>
                        <Row>
                            <Text>{motivo || 'No disponible'}</Text>
                        </Row>
                    </Col>
                </Row>

                <Row className={classes.tabla}>
                    <Col span={24}>
                        <Table
                            columns={column}
                            dataSource={data}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ReporteVerCupones;
