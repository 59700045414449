import React, { useCallback, useEffect, useMemo } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import UltimaActualizacion from '../../../../atoms/support/UltimaActualizacion/UltimaActualizacion';
import { Space, Table } from 'antd';
import Acciones from '../../../../atoms/support/acciones/Acciones';
import TiempoPedido from '../../../../atoms/support/TiempoPedido/TiempoPedido';
import Local from '../../../../atoms/support/Local';
import DatosCliente from '../../../../atoms/support/DatosCliente';
import classes from './Finalizados1.module.css';
import CargarMasButton from '../../../../atoms/support/CargarMasButton/CargarMasButton';
import Integraciones from '../../../../atoms/support/Integraciones';
import PropTypes from 'prop-types';
import tablas from '../tablas.module.css';
import FinalPrice from '../../../../atoms/support/FinalPrice';
import useSupport from '../../../../../hooks/support/useSupport';

const Finalizados = ({ finalizados, online, conectarSocket, loading }) => {
    const {
        solicitarDriver,
        paginaActualFinalizados,
        totalPaginasFinalizados,
        permisoVerIntegraciones,
        obtenerTodasOrdenes,
        cancelarOrden,
        cambiarEstado,
        cargarMas,
        verOrdenModal,
        imprimirOrden,
        revertirPago,
        revertirCancelacion,

        deshabilitarBotonIntegracion,
    } = useSupport();

    const mixpanel = useMixpanel();

    //Se trackea el tiempo en esta pantalla antes de solicitar driver
    useEffect(() => {
        mixpanel.time_event('request_driver');
        mixpanel.track('click_on_finished_tab');
    }, [mixpanel]);

    const obtenerOrdenesHandler = () => {
        obtenerTodasOrdenes();
        conectarSocket();
    };

    const cargarMasButtonHandler = useCallback(() => {
        cargarMas(6, paginaActualFinalizados, totalPaginasFinalizados);
    }, [cargarMas, paginaActualFinalizados, totalPaginasFinalizados]);

    const columnaIntegraciones = useMemo(
        () =>
            permisoVerIntegraciones
                ? {
                      dataIndex: 'integraciones',
                      key: 'integraciones',
                      render: (_text, record) => (
                          <Integraciones
                              jediId={record?.jediId}
                              fechaJedi={record?.fechaJEdi}
                              idMcDonalds={record?.idMcDonalds}
                              ordenSeleccionada={record?.ordenOriginal}
                              esIntegracion={record?.hasIntegration}
                              esJedi={record?.hasJedi}
                              estadoOrden={record?.estadoOrden}
                              idIntegracion={record?.idIntegracion}
                              envioIntegracion={record?.envioIntegracion}
                              deshabilitarBotonIntegracion={
                                  deshabilitarBotonIntegracion
                              }
                          />
                      ),
                  }
                : null,
        [permisoVerIntegraciones, deshabilitarBotonIntegracion],
    );

    const columnasParte1 = useMemo(
        () => [
            {
                dataIndex: 'nombreCliente',
                key: 'nombreCliente',
                render: (_text, record) => (
                    <>
                        <Local
                            nombreLocal={record?.nombreLocal}
                            tipoEntrega={record?.tipoEntrega}
                            codigoOnline={record?.codigoPagoOnline}
                            telefonoLocal={record?.telefonoLocal}
                            tienePermiso={record?.canSeeOnlinePaymentType}
                        />
                        <div>
                            <strong> Orden ID: </strong>
                            {record?.ordenOriginal?.id}
                        </div>
                        <DatosCliente
                            nombre={record?.nombreCliente}
                            id={record?.idCliente}
                            level={record?.level}
                            razonSocial={record?.razonSocial}
                            RUC={record?.ruc}
                        />
                    </>
                ),
            },
            {
                dataIndex: 'precioFinal',
                key: 'precioFinal',
                render: (_text, record) => (
                    <>
                        <Local
                            tipoPago={record?.tipoPago}
                            codigoOnline={record?.codigoPagoOnline}
                        />
                        <strong>
                            <FinalPrice ordenOriginal={record?.ordenOriginal} />
                        </strong>
                    </>
                ),
            },
            {
                dataIndex: 'tiempoPedido',
                key: 'tiempoPedido',
                render: (_text, record) => (
                    <TiempoPedido
                        fechaPedido={record?.fechaPedido}
                        tiempoEstimado={record?.tiempoEstimado}
                        ordenOriginal={record?.ordenOriginal}
                    />
                ),
            },
        ],
        [],
    );

    const columnasParte2 = useMemo(
        () => [
            {
                dataIndex: 'acciones',
                key: 'acciones',
                render: (_text, record) => (
                    <Acciones
                        ordenSeleccionada={record?.ordenOriginal}
                        tieneMonchisDriver={record?.tieneMonchisDriver}
                        permitirSolicitarDriver={
                            record?.permitirSolicitarDriver
                        }
                        solicitarDriver={solicitarDriver}
                        tipoPago={record?.tipoPago}
                        permitirRevertirPagoOnline={
                            record?.permitirRevertirPagoOnline
                        }
                        revertirPago={revertirPago}
                        permitirCancelarOrden={record?.permitirCancelarOrden}
                        cancelarOrden={cancelarOrden}
                        permitirCambiarEstado={record?.permitirCambiarEstado}
                        cambiarEstado={cambiarEstado}
                        permitirRevertirCancelacion={
                            record?.permitirRevertirCancelacion
                        }
                        revertirCancelacion={revertirCancelacion}
                        permitirImprimirOrden={record?.permitirImprimirOrden}
                        imprimirOrden={imprimirOrden}
                        verOrden={verOrdenModal}
                        permitirVerOrden={record?.permitirVerOrden}
                        tipoPagoSoloCupon={record?.tipoPagoSoloCupon}
                    />
                ),
            },
        ],
        [
            solicitarDriver,
            revertirPago,
            cancelarOrden,
            cambiarEstado,
            revertirCancelacion,
            imprimirOrden,
            verOrdenModal,
        ],
    );

    const columns = useMemo(
        () =>
            columnaIntegraciones
                ? [...columnasParte1, columnaIntegraciones, ...columnasParte2]
                : [...columnasParte1, ...columnasParte2],
        [columnaIntegraciones, columnasParte1, columnasParte2],
    );

    return (
        <Space direction="vertical" size="small" className={classes.space}>
            <UltimaActualizacion
                onClick={obtenerOrdenesHandler}
                online={online}
            />
            <Table
                className={tablas.bordeNegro}
                columns={columns}
                pagination={false}
                dataSource={finalizados}
                loading={loading}
                showHeader={false}
            />
            <CargarMasButton
                onCargarMas={cargarMasButtonHandler}
                loading={loading}
                paginaActual={paginaActualFinalizados}
                totalPaginas={totalPaginasFinalizados}
            />
        </Space>
    );
};

export default Finalizados;

Finalizados.propTypes = {
    finalizados: PropTypes.array,
    online: PropTypes.bool,
    conectarSocket: PropTypes.func,
    loading: PropTypes.bool,
};
