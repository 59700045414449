import React, { useEffect, useState } from 'react';
import {
    Table,
    Button,
    Divider,
    Tag,
    message,
    Tooltip,
    Modal,
    Card,
    Typography,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
    PlusCircleOutlined,
    ClockCircleOutlined,
    CloseOutlined,
    InfoCircleTwoTone,
    CheckOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import clienteAxios from '../../../config/axios';
import { urls } from '../../../constants';
import { formatoFechaLocal } from '../../utils-components/Date';
import ABMLayout from '../../../layouts/ABMLayout';
import classes from './Push.module.css';

const { Text } = Typography;

const ListaPush = () => {
    const history = useHistory();
    const [listaPush, setlistaPush] = useState();
    const [paginationData, setPaginationData] = useState();
    const [loadingPushList, setLoadingPushList] = useState(false);
    const [openModalCancelarPush, setOpenModalCancelarPush] = useState();

    useEffect(() => {
        getPushList();
    }, []);

    const cancelarPushHandler = async (dataCancelarPush) => {
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            machine_arn: dataCancelarPush,
        };
        try {
            const llamada = await clienteAxios.get('/cancel_scheduled_push?', {
                params,
            });
            message.success(llamada.data.data.message);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un error al enviar los datos',
            );
        }
        setOpenModalCancelarPush({
            ...openModalCancelarPush,
            openModal: false,
        });
    };

    const closeModalCancelarPush = () => {
        setOpenModalCancelarPush({
            ...openModalCancelarPush,
            openModal: false,
        });
    };

    const ModalCancelarPush = () => (
        <Modal open={openModalCancelarPush.openModal} footer={false}>
            <>
                <Text strong>
                    <ExclamationCircleOutlined
                        style={{
                            color: 'orange',
                            fontSize: '25px',
                            margin: '10px',
                        }}
                    />
                    ¿Estás seguro de cancelar este push?
                </Text>
                <Divider></Divider>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '15px',
                    }}>
                    <Button
                        style={{ marginRight: '10px' }}
                        type="primary"
                        onClick={() =>
                            cancelarPushHandler(openModalCancelarPush.data)
                        }>
                        Aceptar
                    </Button>
                    <Button onClick={closeModalCancelarPush}>Cancelar</Button>
                </div>
            </>
        </Modal>
    );

    const openModal = (dataCancelarPush) => {
        const datosCancelarPush = {
            data: dataCancelarPush,
            openModal: true,
        };
        setOpenModalCancelarPush(datosCancelarPush);
    };

    const columns = [
        {
            title: 'Fecha de envío',
            dataIndex: 'fechaPush',
            key: 'fechaPush',
        },

        {
            title: 'Programado',
            dataIndex: 'programado',
            key: 'programado',
            render: (text, record) => (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    {record.esProgramado ? (
                        <Tag color="blue">SI</Tag>
                    ) : (
                        <Tag color="red">No</Tag>
                    )}
                    {record.esProgramado &&
                        !record.isenviado &&
                        !record.iscancelado && <ClockCircleOutlined />}
                    {record.isenviado && (
                        <CheckOutlined
                            style={{ marginRight: '5px', color: '' }}
                        />
                    )}
                    {record.iscancelado ? <CloseOutlined /> : null}
                </div>
            ),
        },
        {
            title: 'Público objetivo',
            dataIndex: 'publicoObjetivo',
            key: 'publicoObjetivo',
        },
        {
            title: 'Alcance real',
            dataIndex: 'alcanceReal',
            key: 'alcanceReal',
        },
        {
            title: 'Alcance estimado',
            dataIndex: 'alcanceEstimado',
            key: 'alcanceEstimado',
        },
        {
            title: 'Título',
            dataIndex: 'titulo',
            key: 'titulo',
            render: (record) => (
                <>
                    {record.extra && (
                        <Tooltip placement="top" title={'Enviado desde cupón'}>
                            <InfoCircleTwoTone
                                style={{ marginRight: '5px', color: 'red' }}
                            />
                        </Tooltip>
                    )}
                    <span>{record.titulo}</span>
                </>
            ),
        },

        {
            title: 'Enviado por',
            dataIndex: 'enviadoPor',
            key: 'enviadoPor',
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <>
                    <Button
                        disabled={record.isenviado || record.iscancelado}
                        type="dashed"
                        danger
                        onClick={() => openModal(record.cancelarPush)}
                        icon={<CloseOutlined />}>
                        Cancelar Push
                    </Button>
                </>
            ),
        },
    ];

    const nuevoPushButton = () => {
        history.push('/push/enviar-push');
    };

    const getPushList = async (currentPage = 1) => {
        setLoadingPushList(true);
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            page: currentPage,
        };
        try {
            const response = await clienteAxios.get('/get_scheduled_push', {
                params,
            });
            const listaPush = response?.data?.data?.data;
            const listaPushArray = new Array();
            for (let i = 0; i < listaPush.length; i++) {
                const newListaPush = {
                    key: listaPush[i]?.id,
                    fechaPush: formatoFechaLocal(listaPush[i]?.date_to_execute),
                    publicoObjetivo: listaPush[i]?.request?.total_users,
                    alcanceReal: listaPush[i]?.success_count || '-',
                    alcanceEstimado:
                        listaPush[i]?.request?.selected_users || '-',
                    titulo: {
                        titulo: listaPush[i]?.request?.title,
                        extra: listaPush[i]?.request?.isCoupon === 'is_coupon',
                    },
                    enviadoPor: `${listaPush[i]?.user?.first_name} ${listaPush[i]?.user?.last_name}`,
                    cancelarPush: listaPush[i]?.machine_arn,
                    pushCancelado: listaPush[i]?.cancelled,
                    esProgramado:
                        listaPush[i]?.sending_type === 'scheduled' && true,
                    isenviado: listaPush[i]?.push_sent,
                    iscancelado: listaPush[i]?.cancelled,
                    iscupon: listaPush[i]?.request?.isCoupon === 'is_coupon',
                };
                listaPushArray.push(newListaPush);
            }
            setPaginationData({
                currentPage: response?.data?.data?.page,
                pageSize: response?.data?.data?.items_per_page,
            });
            setlistaPush(listaPushArray);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un error al obtener los datos',
            );
        }
        setLoadingPushList(false);
    };

    const openModalCancelar =
        openModalCancelarPush?.openModal && ModalCancelarPush();

    const changePageHandler = (page) => {
        getPushList(page);
    };

    return (
        <ABMLayout
            titulo="Lista de mensajes push"
            acciones={
                <Button
                    type="primary"
                    onClick={nuevoPushButton}
                    icon={<PlusCircleOutlined />}>
                    Nuevo Push
                </Button>
            }
            className={classes.pushListActionsPadding}>
            <Card>
                <Table
                    columns={columns}
                    dataSource={listaPush}
                    loading={loadingPushList}
                    pagination={{
                        total: 200,
                        current: listaPush?.currentPage,
                        pageSize: paginationData?.pageSize,
                        showSizeChanger: false,
                        onChange: changePageHandler,
                    }}
                />
            </Card>
            {openModalCancelar}
        </ABMLayout>
    );
};

export default ListaPush;
