import React from 'react';
import PropTypes from 'prop-types';
import { Tabs, Typography, Layout } from 'antd';
import { useHistory } from 'react-router-dom';

import navegacionConfig from 'config/NavegacionConfig';

import classes from './ProductosTabLayout.module.css';

const ProductosTabLayout = ({ title, currentTab, children }) => {
    const history = useHistory();

    // Build Tabs from the sidebar menus
    const administrarMenuItems = navegacionConfig.find(
        (item) => item.path === '/administrar',
    );
    const productosMenuItems = administrarMenuItems.submenu.find(
        (item) => item.path === '/administrar/productos',
    );
    const filteredProductosMenuItems = productosMenuItems.submenu.filter(
        (item) => item.path !== '/administrar/productos/actualizar',
    );
    const tabItemsList = filteredProductosMenuItems.map((item) => ({
        label: item.title,
        key: item.path,
        children: item.path === currentTab && children,
    }));

    const onTabChange = (key) => {
        history.push(key);
    };

    return (
        <>
            <Layout>
                <Typography.Title>{title}</Typography.Title>
                <Layout.Content className={classes.siteLayoutBackground}>
                    <Tabs
                        defaultActiveKey={currentTab?.toString()}
                        onChange={onTabChange}
                        animated={false}
                        destroyInactiveTabPane
                        items={tabItemsList}
                    />
                </Layout.Content>
            </Layout>
        </>
    );
};

export default ProductosTabLayout;

ProductosTabLayout.propTypes = {
    title: PropTypes.string,
    currentTab: PropTypes.string,
    children: PropTypes.node,
};
