export const obtenerIntervaloInicioFinDocumentosFacturacion = (mes, anho) => {
    // Creacion de intervalos y correccion de mes inicial
    const fechaInicio = new Date(anho, mes, 1);
    let fechaFin = new Date(anho, mes, 1);
    fechaFin.setMonth(fechaFin.getMonth() + 1);

    // Obtener mes en formato string
    const mesStart =
        fechaInicio.getMonth() < 9
            ? `0${fechaInicio.getMonth() + 1}`
            : `${fechaInicio.getMonth() + 1}`;
    const mesEnd =
        fechaFin.getMonth() < 9
            ? `0${fechaFin.getMonth() + 1}`
            : `${fechaFin.getMonth() + 1}`;

    // Construir parametros
    const fechasBusqueda = {
        date_start: `${fechaInicio.getFullYear()}-${mesStart}-01`,
        date_end: `${fechaFin.getFullYear()}-${mesEnd}-01`,
    };

    return {
        fechasBusqueda,
        mesStart,
        anhoStart: fechaInicio.getFullYear().toString(),
    };
};
