import React from 'react';
import { ProgramPromotionsCard } from 'components/atoms/ProgramaRecompensas/Promociones/BannersPromo/ProgramPromotionsCard';
import PropTypes from 'prop-types';

export const PromotionCardsList = ({
    dayOptions,
    cardPropsReduced,
    removeHandler,
    disabled,
}) => {
    return (
        <>
            {dayOptions.map((dayOption, index) => (
                <ProgramPromotionsCard
                    disabled={disabled}
                    key={index}
                    day={dayOption.label}
                    hourRange={cardPropsReduced[dayOption.value]}
                    removeHandler={() =>
                        removeHandler(cardPropsReduced[dayOption.value][0].id)
                    }
                />
            ))}
        </>
    );
};

PromotionCardsList.propTypes = {
    dayOptions: PropTypes.array.isRequired,
    cardPropsReduced: PropTypes.any.isRequired,
    removeHandler: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
