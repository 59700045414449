import React, { useContext, useEffect } from 'react';
import {
    Card,
    Row,
    Col,
    Table,
    Button,
    Form,
    Dropdown,
    Menu,
    Badge,
} from 'antd';
import { PlusCircleOutlined, MoreOutlined } from '@ant-design/icons';
import ABMLayout from '../../../../../layouts/ABMLayout';
import { useHistory } from 'react-router-dom';
import TimbradoContext from '../../../../../context/facturacion/timbrado/TimbradoContext';
import { Divider } from 'antd';

const ListaTimbrado = () => {
    // extraemos los datos que provee el context
    const timbradoContext = useContext(TimbradoContext);
    const {
        obtenerListadoTimbrados,
        listadoTimbradosTabla,
        seleccionarPageTabla,
        loadingTablaTimbrados,
        paginaActual,
        totalTimbrados,
    } = timbradoContext;

    // const [valorBusqueda, setValorBusqueda] = useState('');
    //hook history para redireccionar a otra pagina etc.
    let history = useHistory();

    const addTimbrado = () => {
        history.push(`/finanzas/facturacion/timbrado/nuevo`);
    };

    const editTimbrado = (datos) => {
        history.push(`/finanzas/facturacion/timbrado/editar/${datos.id}`);
    };

    useEffect(() => {
        obtenerListadoTimbrados();
    }, []);
    useEffect(() => {
        obtenerListadoTimbrados(paginaActual);
    }, [paginaActual]);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <span>#{text}</span>,
        },
        {
            title: 'Timbrado',
            dataIndex: 'timbrado',
            key: 'timbrado',
        },
        {
            title: 'Fecha Inicio - Fin',
            dataIndex: 'fechaInicioFin',
            key: 'fechaInicioFin',
        },
        {
            title: 'Rango de nro. de factura',
            dataIndex: 'nroFactura',
            key: 'nroFactura',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (value) => (
                <div style={{ textAlign: 'center' }}>
                    {value ? (
                        <Badge status="success" text="Activo" />
                    ) : (
                        <Badge status="error" text="Inactivo" />
                    )}
                </div>
            ),
        },
        {
            title: '',
            key: 'action',
            render: (text, record) => (
                <Dropdown overlay={() => menu(record)}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const menu = (datos) => (
        <Menu>
            <Menu.Item onClick={() => editTimbrado(datos)}>Editar</Menu.Item>
        </Menu>
    );

    const changePage = (page) => {
        seleccionarPageTabla(page);
    };

    // useEffect(() => {
    //     // llama a obtener usuarios despues de 1 segundo
    //     const timeout = setTimeout(() => {
    //         if (valorBusqueda) {
    //             busquedaTimbrado(valorBusqueda);
    //         } else {
    //             obtenerListadoTimbrados(paginaActual);
    //         }
    //     }, 500);
    //     return () => {
    //         clearTimeout(timeout);
    //     };
    // }, [valorBusqueda]);

    // const handleChange = async (valor) => {
    //     setValorBusqueda(valor);
    // };

    return (
        <ABMLayout titulo={'Timbrado'}>
            <Card>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form name="filtros" layout="vertical">
                            {/* <Row gutter={12}>
                                <Col span={8}>
                                    <Form.Item label="Buscar" name="buscar">
                                        <Input onChange={(evt) => handleChange(evt.currentTarget.value)} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item style={{marginTop: 26}}>
                                        <Button type="primary">Buscar</Button>
                                    </Form.Item>
                                </Col>
                            </Row> */}
                        </Form>
                    </Col>
                    <Col span={8} style={styles.acciones}>
                        <Button
                            type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={addTimbrado}>
                            Nuevo
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            loading={loadingTablaTimbrados}
                            columns={columns}
                            dataSource={listadoTimbradosTabla}
                            rowKey="id"
                            pagination={{
                                pageSize: 20,
                                total: Number(totalTimbrados),
                                current: paginaActual,
                                showSizeChanger: false,
                                onChange: (page) => changePage(page),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ListaTimbrado;

const styles = {
    acciones: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
};
