import React from 'react';
import 'antd/dist/antd.css';
import { Card, Col, Row, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import classes from './Card.module.css';

const StatisticWidget = (props) => {
    return (
        <Card
            className={[classes.Card, props.className].join(' ')}
            style={{ backgroundColor: props.backgroundcolor }}>
            <Row>
                <Col className={classes.Icon}>{props.icon}</Col>
                <Col
                    className={classes.Content}
                    style={{ display: !props.cargando ? 'block' : 'none' }}>
                    {props.children}
                </Col>
                <Col
                    className={classes.Content}
                    span={16}
                    style={{ display: props.cargando ? 'block' : 'none' }}>
                    <Skeleton
                        title=""
                        paragraph={{ rows: 2 }}
                        active="true"
                        size="small"
                        shape="round"
                        loading="true"
                    />
                </Col>
            </Row>
        </Card>
    );
};

StatisticWidget.propTypes = {
    className: PropTypes.any,
    backgroundcolor: PropTypes.string,
    icon: PropTypes.element,
    cargando: PropTypes.bool,
    children: PropTypes.any,
};

export default StatisticWidget;
