import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Card, Button, Typography } from 'antd';
import {
    CheckCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import CuponesContext from '../../../../context/programaRecompensa/cupones/CuponesContext';
import { ListarUsuarios } from './ListarUsuarios';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { useMixpanel } from 'react-mixpanel-browser';

const { Text } = Typography;

const FormItemAsignarUsuarios = () => {
    const cuponesContext = useContext(CuponesContext);
    const {
        obtenerUsuarios,
        guardarUsuariosSelecionados,
        seleccionarUsuarioId,
        restablecerUsuarios,
        usuariosSeleccionados,
    } = cuponesContext;
    const mixpanel = useMixpanel();

    const [errorArchivo, setErrorArchivo] = useState(null);
    const [isSubirArchivo, setIsSubirArchivo] = useState(false);
    const [cantidadUsuariosSeleccionados, setCantidadUsuariosSeleccionados] =
        useState(0);

    useEffect(() => {
        obtenerUsuarios();
    }, []);

    // Callback para obtener archivos
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            // Archivo en memoria
            const reader = new FileReader();
            reader.onload = () => {
                extraerUsuariosArchivo(reader);
            };
            reader.readAsText(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop }); // State del componente de DROP FILES

    const extraerUsuariosArchivo = (reader) => {
        // Datos dentro del archivo
        const binaryStr = reader.result;
        // Se separa cada linea del archivo
        const linea = binaryStr.split('\n');
        // Cantidad de columnas que tiene el archivo
        // const headers = lines[0].split(",");
        // Extraer los datos y ponerlos en un objeto
        let arrLineas = [];
        for (let i = 0; i < linea.length; i++) {
            // let temp = {}; // Si se necesita alguna vez soportar mas datos se puede usar un objeto
            const currentLine = linea[i].split(',');
            // Aqui se podria usar el encabezado de KEY para el objecto
            // Recorrer cada columna del archivo
            // for (let j = 0; j < headers.length; j++) {
            //     // temp[headers[j].replaceAll(`"`, "")] = currentLine[j].replaceAll(`"`, "");
            // }
            const columna1 = currentLine[0].replaceAll(`"`, '');
            arrLineas.push(columna1);
        }
        // Extraer y validar que los datos sean correctos
        let arrIdUsuarios = [];
        let tieneError = false;
        arrLineas.forEach((element) => {
            if (!isNaN(element)) {
                const id = Number(element);
                if (id) {
                    arrIdUsuarios.push(id);
                }
            } else {
                tieneError = true;
            }
        });
        // Remover duplicados
        arrIdUsuarios = [...new Set(arrIdUsuarios)];
        // Extraer datos relevantes de la lista de usuarios detectados
        let cantidadUsuarios = arrIdUsuarios.length;

        // Puede suceder que hayan lineas con datos incorrectos, si existieron pueden ser ignorados
        tieneError = cantidadUsuarios < 21;
        // Actualizar datos en el stat3
        seleccionarUsuarioId(arrIdUsuarios);
        guardarUsuariosSelecionados(arrIdUsuarios);

        setErrorArchivo(tieneError);
        setCantidadUsuariosSeleccionados(cantidadUsuarios);
    };

    const restablecerSeleccionUsuariosCard = () => {
        restablecerUsuarios();
        setCantidadUsuariosSeleccionados(0);
    };

    const contenedorSubirArchivo = (
        <>
            <div {...getRootProps()} style={styles.contenedorSubirArchivo}>
                <input {...getInputProps()} style={styles.inputSubirArchivo} />

                {errorArchivo ? (
                    <Text style={{ padding: '10px 20px' }}>
                        <WarningOutlined style={styles.warningIcon} /> El
                        archivo contiene errores
                    </Text>
                ) : (
                    <>
                        {cantidadUsuariosSeleccionados ? (
                            <Text style={{ padding: '10px 20px' }}>
                                <CheckCircleOutlined style={styles.checkIcon} />{' '}
                                Encontramos{' '}
                                <Text style={{ fontWeight: 'bold' }}>
                                    {cantidadUsuariosSeleccionados} posibles
                                </Text>{' '}
                                usuarios
                            </Text>
                        ) : null}
                    </>
                )}
                <Text style={{ padding: '10px 20px', fontSize: 11 }}>
                    <InfoCircleOutlined style={styles.infoIcon} /> Cambie de
                    archivo haciendo clic en &quot;Seleccionar archivos&quot;
                </Text>
            </div>
            <Button
                style={{ marginTop: 10 }}
                onClick={restablecerSeleccionUsuariosCard}
                disabled={!usuariosSeleccionados?.length}>
                Remover usuarios
            </Button>
        </>
    );

    return (
        <Card
            title="Asignar usuarios"
            extra={
                <Button
                    size="small"
                    style={{ fontSize: '12px' }}
                    onClick={() => {
                        setIsSubirArchivo(!isSubirArchivo);
                        restablecerSeleccionUsuariosCard();
                        mixpanel.track(
                            isSubirArchivo
                                ? 'click_on_coupon_user_list_button'
                                : 'click_on_coupon_upload_file_button',
                        );
                    }}
                    disabled={usuariosSeleccionados?.length}>
                    {isSubirArchivo ? 'Lista de usuarios' : 'Subir archivo'}
                </Button>
            }>
            {isSubirArchivo ? (
                contenedorSubirArchivo
            ) : (
                <ListarUsuarios isSubirArchivo={isSubirArchivo} />
            )}
        </Card>
    );
};

export default FormItemAsignarUsuarios;

const styles = {
    contenedorSubirArchivo: { border: '2px dashed gray', height: '10rem' },
    contenedorBuscadorPersonas: { marginBottom: 12 },
    fullWidth: { width: '100%' },
    inputSubirArchivo: {
        display: 'flex',
        width: '100%',
        padding: '20px 20px 10px 20px',
    },
    warningIcon: { color: '#faad14' },
    checkIcon: { color: '#52c41a' },
    infoIcon: { color: '#1890ff' },
};

FormItemAsignarUsuarios.propTypes = {
    mode: PropTypes.string,
    cuponForm: PropTypes.any,
};
