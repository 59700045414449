import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListaNiveles from './ListaNiveles.js';
import EditarNivel from './EditarNivel';
import { Permisos } from '../../../../constants/Permisos';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import PropTypes from 'prop-types';

const NivelUsuario = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.programaRecompensa.nivelUsuario
                                .listaNiveles
                        }
                        componente={ListaNiveles}
                    />
                )}
            />
            <Route
                exact
                path={`${match.url}/editar`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.programaRecompensa.nivelUsuario
                                .listaNiveles
                        }
                        componente={EditarNivel}
                    />
                )}
            />
        </Switch>
    );
};

export default NivelUsuario;

NivelUsuario.propTypes = {
    match: PropTypes.object,
};
