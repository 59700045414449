import React from 'react';

const IconoBan = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g id="ArrowCounterClockwise">
            <g id="ArrowCounterClockwise_2">
                <path
                    id="Vector"
                    d="M6.23695 7.79028H2.48695V4.04028"
                    stroke="#0140EF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    id="Vector_2"
                    d="M5.13864 14.8614C6.10013 15.8229 7.32514 16.4776 8.65875 16.7429C9.99237 17.0082 11.3747 16.872 12.6309 16.3517C13.8872 15.8313 14.9609 14.9501 15.7164 13.8196C16.4718 12.689 16.875 11.3598 16.875 10C16.875 8.64026 16.4718 7.31105 15.7164 6.18046C14.9609 5.04987 13.8872 4.16868 12.6309 3.64833C11.3747 3.12798 9.99237 2.99183 8.65875 3.2571C7.32514 3.52238 6.10013 4.17716 5.13864 5.13864L2.48699 7.79029"
                    stroke="#0140EF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </g>
    </svg>
);

export default IconoBan;
