import React, { useState, useEffect, useContext } from 'react';
import {
    Input,
    Table,
    Menu,
    Dropdown,
    Button,
    Divider,
    Row,
    Col,
    Modal,
    Switch,
    Form,
    message,
    Tag,
    InputNumber,
} from 'antd';
import { PlusCircleOutlined, MoreOutlined } from '@ant-design/icons';
import clienteAxios from '../../../../../config/axios';
import { urls } from '../../../../../constants';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import ModalContent from '../../../../atoms/ModalContent';
import { dateDMYyHora } from '../../../../utils-components/Date';

const DatosContribuyentesUsuario = () => {
    const baseURL = urls.REACT_APP_BACKEND_URL_E;
    const [editarForm] = Form.useForm();
    const [crearForm] = Form.useForm();
    const { usuarioSeleccionado } = useContext(UsuariosContext);

    //states para controlar los modales
    const [openModalCrear, setOpenModalCrear] = useState(false);
    const [openModalEditar, setOpenModalEditar] = useState(false);
    const [openModalEliminar, setOpenModalEliminar] = useState(false);
    const [invoicesList, setInvoicesList] = useState(false);
    const [loadingTable, setLoadingTable] = useState(false);
    const [dataFormEditar, setDataFormEditar] = useState();
    const [dataFormCrear, setDataFormCrear] = useState();
    const [invoiceId, setIdInvoice] = useState();
    const [loaderModal, setLoaderModal] = useState(false);
    const [inhabilitarSwitch, setInhabilitarSwitch] = useState(false);

    useEffect(() => {
        getInvoicesData();
    }, []);

    useEffect(() => {
        if (dataFormEditar) {
            sendNewInvoiceData();
        }
    }, [dataFormEditar]);

    useEffect(() => {
        if (dataFormCrear) {
            crearNuevoRuc();
        }
    }, [dataFormCrear]);

    const openModalFacturasCrear = () => {
        setOpenModalCrear(true);
    };

    // td del nombre del dato principal con un check
    const rucPrincipal = (elm) => {
        return (
            <>
                <span style={{ marginRight: '5px' }}> {elm.razonSocial}</span>
                <Tag color="green">Principal</Tag>
            </>
        );
    };

    const openModalFacturasEditar = (row) => {
        setOpenModalEditar(true);
        editarForm.setFieldsValue({
            razonSocial: row.razonSocial,
            ruc: +row.ruc.split('-')[0],
            dv: row.ruc.split('-').length > 1 ? +row.ruc.split('-')[1] : '',
            principal: row.principal,
        });
        setIdInvoice(row.key);
        row.principal === true
            ? setInhabilitarSwitch(true)
            : setInhabilitarSwitch(false);
    };

    const closeModalEditar = () => {
        setOpenModalEditar(false);
    };

    const closeModalEliminar = () => {
        setOpenModalEliminar(false);
    };

    const openModalFacturasEliminar = (row) => {
        setOpenModalEliminar(true);
        setIdInvoice(row.key);
    };

    const dropdownMenu = (row) => (
        <Menu>
            <Menu.Item onClick={() => openModalFacturasEditar(row)}>
                Editar
            </Menu.Item>
            {!row.principal && (
                <Menu.Item onClick={() => openModalFacturasEliminar(row)}>
                    Eliminar
                </Menu.Item>
            )}
        </Menu>
    );

    const getInvoicesData = async () => {
        setLoadingTable(true);
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_E,
        };
        try {
            const llamada = await clienteAxios.get(
                `/get_user_taxpayers/${usuarioSeleccionado.id}`,
                { params },
            );
            const invoices = llamada.data.data.response.user_taxpayers;
            const invoicesArray = new Array();
            for (let i = 0; i < invoices.length; i++) {
                const newInvoicesArray = {
                    key: invoices[i].id,
                    principal: invoices[i].is_default,
                    razonSocial: invoices[i].name,
                    ruc: invoices[i].taxpayer_record,
                    fechaActualizacion: dateDMYyHora(invoices[i].updated_at),
                };
                invoicesArray.push(newInvoicesArray);
            }
            setInvoicesList(invoicesArray);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener los datos',
            );
        }
        setLoadingTable(false);
    };

    const columns = [
        {
            title: 'Razón social',
            dataIndex: 'razonSocial',
            key: 'razonSocial',
            render: (_, elm) =>
                elm.principal ? rucPrincipal(elm) : elm.razonSocial,
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },

        {
            title: 'Fecha de actualización',
            dataIndex: 'fechaActualizacion',
            key: 'fechaActualizacion',
        },
        {
            title: '',
            dataIndex: 'acciones',
            key: 'acciones',
            render: (_, elm) => (
                <Dropdown overlay={() => dropdownMenu(elm)}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    //primero se guardan los datos del form
    const saveDataForm = () => {
        editarForm
            .validateFields()
            .then(async () => {
                try {
                    let formData = editarForm.getFieldsValue();
                    const razonSocial = formData.razonSocial;
                    const ruc = `${formData.ruc}${
                        formData?.dv || +formData?.dv > 0
                            ? '-' + formData.dv
                            : ''
                    }`;

                    const isDefault = formData.principal ? true : false;

                    let data = {
                        name: razonSocial,
                        taxpayer_record: ruc,
                        is_default: isDefault,
                    };
                    setDataFormEditar(data);
                } catch (error) {
                    message.error(
                        error.response?.data.message ||
                            'Hubo un problema al obtener los datos',
                    );
                }
            })
            .catch(() => {});
    };

    const saveDataFormCrear = () => {
        crearForm
            .validateFields()
            .then((formData) => {
                try {
                    const name = formData.razonSocial;

                    const taxpayerRecord = `${formData.ruc}${
                        formData?.dv || +formData?.dv > 0
                            ? '-' + formData.dv
                            : ''
                    }`;

                    const isDefault = Boolean(formData?.principal);

                    let data = {
                        name: name,
                        taxpayer_record: taxpayerRecord,
                        is_default: isDefault,
                    };
                    setDataFormCrear(data);
                    crearForm.resetFields();
                } catch (error) {
                    message.error(
                        error.response?.data.message ||
                            'Hubo un problema al obtener los datos',
                    );
                }
            })
            .catch((error) => {
                message.error(
                    error.response?.data.message || 'Ocurrio un Error',
                );
            });
    };

    // despues se mandan los nuevos datos al back
    const crearNuevoRuc = async () => {
        const data = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_E,
            user_id: usuarioSeleccionado.id,
            ...dataFormCrear,
        };
        try {
            setLoaderModal(true);
            const llamada = await clienteAxios.post(
                '/create_user_taxpayer',
                data,
            );
            message.success(llamada.data.data.data);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener los datos',
            );
        }
        setLoaderModal(false);
        setOpenModalCrear(false);
        getInvoicesData();
    };

    //ENVIARLE ES IS_DEFAULT TRUE O FALSE
    const sendNewInvoiceData = async () => {
        const data = {
            user_id: usuarioSeleccionado.id,
            id: invoiceId,
            ...dataFormEditar,
        };
        try {
            setLoaderModal(true);
            const llamada = await clienteAxios.put(
                '/update_user_taxpayer',
                data,
                { baseURL },
            );
            message.success(llamada.data.data.data);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al editar los datos',
            );
        }
        setLoaderModal(false);
        setOpenModalEditar(false);
        getInvoicesData();
    };

    const deleteInvoice = async () => {
        const data = {
            id: invoiceId,
        };

        try {
            setLoaderModal(true);
            const llamada = await clienteAxios.delete(`/delete_user_taxpayer`, {
                params: { id: data.id },
                baseURL,
            });
            message.success(llamada.data.data.message);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al eliminar los datos',
            );
        }
        setLoaderModal(false);
        setOpenModalEliminar(false);
        getInvoicesData();
    };

    const ModalFacturasCrear = () => (
        <>
            <Modal
                open={openModalCrear}
                title="Crear nuevo contribuyente"
                footer={null}
                closable={null}>
                <ModalContent
                    isLoading={loaderModal}
                    onOk={saveDataFormCrear}
                    onCancel={() => setOpenModalCrear(false)}
                    cancelText="Cancelar"
                    okText="Guardar">
                    <Form form={crearForm} name="formCrear" layout="vertical">
                        <Row gutter={24}>
                            <Col style={{ width: '100%' }}>
                                <Form.Item
                                    name="razonSocial"
                                    label="Razón Social"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={18}>
                                <Row gutter={24}>
                                    <Col span={18}>
                                        <Form.Item
                                            name="ruc"
                                            label="RUC"
                                            rules={[{ required: true }]}>
                                            <InputNumber
                                                min={1}
                                                max={99999999}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="dv" label="DV">
                                            <InputNumber
                                                min={0}
                                                max={9}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Form.Item name="principal" label="Principal">
                                    <Switch></Switch>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </ModalContent>
            </Modal>
        </>
    );

    const ModalFacturaEditar = () => (
        <>
            <Modal
                open={openModalEditar}
                title="Editar datos del contribuyente"
                footer={null}
                closable={null}>
                <ModalContent
                    isLoading={loaderModal}
                    onCancel={closeModalEditar}
                    onOk={saveDataForm}
                    cancelText="Cancelar"
                    okText="Guardar">
                    <Form form={editarForm} name="formEditar" layout="vertical">
                        <Row gutter={24}>
                            <Col style={{ width: '100%' }}>
                                <Form.Item
                                    name="razonSocial"
                                    label="Razón Social">
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={18}>
                                <Row gutter={24}>
                                    <Col span={18}>
                                        <Form.Item
                                            name="ruc"
                                            label="RUC"
                                            rules={[{ required: true }]}>
                                            <InputNumber
                                                min={1}
                                                max={99999999}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item name="dv" label="DV">
                                            <InputNumber
                                                min={0}
                                                max={9}
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    valuePropName="checked"
                                    name="principal"
                                    label="Principal">
                                    <Switch
                                        disabled={inhabilitarSwitch}></Switch>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </ModalContent>
            </Modal>
        </>
    );

    const ModalFacturaEliminar = () => (
        <Modal open={openModalEliminar} footer={null} closable={null}>
            <ModalContent
                title="Está seguro de que quiere eliminar este contribuyente?"
                isLoading={loaderModal}
                onOk={deleteInvoice}
                onCancel={closeModalEliminar}
                okText="Eliminar"
                cancelText="Cancelar"
                okType="danger"
                okButtonProps={{ danger: true }}
                showWarnIcon
                isDelete
                invertPrimary></ModalContent>
        </Modal>
    );

    const headerTable = () => {
        return (
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: '60px',
                }}>
                <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={openModalFacturasCrear}>
                    Nuevo
                </Button>
                <Divider></Divider>
            </Row>
        );
    };

    return (
        <>
            {openModalCrear && ModalFacturasCrear()}
            {openModalEditar && ModalFacturaEditar()}
            {openModalEliminar && ModalFacturaEliminar()}
            <Table
                loading={loadingTable}
                columns={columns}
                pagination={false}
                title={headerTable}
                dataSource={invoicesList}
            />
        </>
    );
};

export default DatosContribuyentesUsuario;
