import React from 'react';
import 'antd/dist/antd.css';
import { Col, Button } from 'antd';
import PropTypes from 'prop-types';
import classes from './Acciones1.module.css';

function EstadoOrden({ idEstadoOrden }) {
    switch (idEstadoOrden) {
        case 3:
            return <div>A PREPARAR</div>;
        case 4:
            return <div>PASAR A DELIVERY</div>;
        case 5:
            return <div>FINALIZAR</div>;
        case 9:
            return <div>FINALIZAR</div>; //SE validad ambos números de estado porque en entorno Prod toma 5 y 9 en dev.
        default:
            return null;
    }
}
const CambiarEstadoButton = ({
    permitirCambiarEstado,
    cambiarEstadoButtonHandler,
    idEstadoOrden,
}) => (
    <>
        {idEstadoOrden !== 2 &&
            idEstadoOrden !== 0 &&
            permitirCambiarEstado && (
                <Col>
                    <Button
                        type="link"
                        onClick={cambiarEstadoButtonHandler}
                        className={classes.ESTADOButton}>
                        <span>
                            <EstadoOrden idEstadoOrden={idEstadoOrden} />
                        </span>
                        <span
                            role="img"
                            aria-label="right"
                            className="anticon anticon-right">
                            <svg
                                viewBox="64 64 896 896"
                                focusable="false"
                                data-icon="right"
                                width="1em"
                                height="1em"
                                fill="currentColor"
                                aria-hidden="true">
                                <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                            </svg>
                        </span>
                    </Button>
                </Col>
            )}
    </>
);

CambiarEstadoButton.propTypes = {
    permitirCambiarEstado: PropTypes.bool,
    cambiarEstadoButtonHandler: PropTypes.func,
    idEstadoOrden: PropTypes.number,
};

export default CambiarEstadoButton;
