import { useContext, useCallback } from 'react';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import { formatoFechaSinHora } from 'components/utils-components/Date/index';

export const useGetPromotions = (form) => {
    const {
        currentPage,
        filters,

        setCurrentPage,
        setLoadingPromotionList,
        setPromotionsList,
        setFilters,
        getPromotionsList,
    } = useContext(PromotionsContext);

    const formatPromotionListData = (dataSource) => {
        const promotions = dataSource?.promotions;
        const itemsPerPage = dataSource?.per_page;
        const totalItems = dataSource?.count || 0;
        const data = [];

        promotions?.forEach((promotion) => {
            const dateFrom = formatoFechaSinHora(promotion?.from);
            const dateTo = formatoFechaSinHora(promotion?.to);

            data.push({
                key: promotion?.id,
                id: promotion?.id,
                number: promotion?.id,
                promotion: promotion?.name,
                dates: `${dateFrom} - ${dateTo}`,
                checked: promotion?.active,
            });
        });

        return {
            dataSource: data,
            pageSize: itemsPerPage,
            total: totalItems,
        };
    };

    const getPromotions = useCallback(
        async (filters) => {
            setLoadingPromotionList(true);

            const promotionListApi = await getPromotionsList(
                filters.promotion,
                filters.state,
                filters.franchise,
                filters.branch,
                filters.page,
            );
            const promotionListFormatted =
                formatPromotionListData(promotionListApi);
            setPromotionsList(promotionListFormatted);
        },
        [getPromotionsList, setLoadingPromotionList, setPromotionsList],
    );

    const formatState = (state) => {
        if (state === 'actives') {
            return true;
        } else if (state === 'inactives') {
            return false;
        }
        return undefined;
    };

    const formatFilters = (filters) => {
        let { promotion, state, franchise, branch } = filters;
        const stateFormatted = formatState(state);
        promotion === null && (promotion = undefined);
        franchise === 'all' && (franchise = undefined);
        branch === 'all' && (branch = undefined);
        return {
            promotion,
            state: stateFormatted,
            franchise,
            branch,
            page: currentPage,
        };
    };

    const onFinish = (data) => {
        setPromotionsList({ dataSource: [], pageSize: 15, total: 0 });
        const filters = formatFilters(data);
        if (currentPage !== 1) {
            setCurrentPage(1);
        }
        setFilters(filters);
        getPromotions({ ...filters, page: 1 });
    };

    const onPageChange = (page) => {
        setCurrentPage(page);

        const filters = {
            ...formatFilters(form.getFieldsValue()),
            page: page,
        };
        setFilters(filters);
        getPromotions(filters);
    };

    const onRefreshList = () => {
        getPromotions(filters);
    };

    return {
        getPromotions,
        onFinish,
        onPageChange,
        onRefreshList,
    };
};
