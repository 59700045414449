import React from 'react';
const Filter = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <g id="Funnel">
                <g id="Funnel_2">
                    <path
                        id="Vector"
                        d="M3.6167 4.125H18.3834C18.5166 4.125 18.647 4.16369 18.7586 4.23636C18.8702 4.30904 18.9583 4.41257 19.0122 4.53437C19.0661 4.65617 19.0834 4.79101 19.0621 4.92248C19.0408 5.05396 18.9817 5.17641 18.8922 5.27496L13.2414 11.4908C13.1263 11.6174 13.0626 11.7823 13.0626 11.9533V16.8196C13.0626 16.9327 13.0346 17.0442 12.9812 17.144C12.9278 17.2438 12.8506 17.3288 12.7564 17.3916L10.0064 19.2249C9.90289 19.294 9.78256 19.3336 9.65827 19.3396C9.53397 19.3456 9.41038 19.3178 9.30067 19.259C9.19096 19.2003 9.09924 19.1129 9.03529 19.0062C8.97134 18.8994 8.93757 18.7773 8.93757 18.6529V11.9533C8.93757 11.7823 8.87382 11.6174 8.75878 11.4908L3.10799 5.27496C3.0184 5.17641 2.95936 5.05396 2.93805 4.92248C2.91675 4.79101 2.93409 4.65617 2.98797 4.53437C3.04185 4.41257 3.12996 4.30904 3.24157 4.23636C3.35319 4.16369 3.48351 4.125 3.6167 4.125V4.125Z"
                        stroke="#0140EF"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
            </g>
        </svg>
    );
};

export default Filter;
