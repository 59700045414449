import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListaDetalleTransaccion from './ListaDetalleTransaccion';
import VerOrden from './VerOrden';
import { Permisos } from '../../../../../constants/Permisos';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';

const DetalleTransaccion = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.reportes.ventas.detalleTransaccion
                                .verPagina
                        }
                        componente={ListaDetalleTransaccion}
                    />
                )}
            />
            <Route
                path={`${match.url}/ver-orden`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.reportes.ventas.detalleTransaccion
                                .verPagina
                        }
                        componente={VerOrden}
                    />
                )}
            />
        </Switch>
    );
};

export default DetalleTransaccion;

DetalleTransaccion.propTypes = {
    match: PropTypes.object,
};
