import React from 'react';
import { Card } from 'antd';
import { Pie, measureTextWidth } from '@ant-design/plots';
import clases from './analiticas.module.css';
import PropTypes from 'prop-types';

const CardGraficoCircular = ({ datosComercios }) => {
    const GraficoCircular = () => {
        function renderStatistic(containerWidth, text, style) {
            const { width: textWidth, height: textHeight } = measureTextWidth(
                text,
                style,
            );
            const R = containerWidth / 2;

            let scale = 1;

            if (containerWidth < textWidth) {
                scale = Math.min(
                    Math.sqrt(
                        Math.abs(
                            Math.pow(R, 2) /
                                (Math.pow(textWidth / 2, 2) +
                                    Math.pow(textHeight, 2)),
                        ),
                    ),
                    1,
                );
            }

            const textStyleStr = `width:${containerWidth}px;`;
            return `<div style="${textStyleStr};font-size:${
                scale - 0.2
            }em;line-height:${scale < 1 ? 1 : 'inherit'};">${
                text.length <= 13 ? text : text.slice(0, 13) + '...'
            }</div>`;
        }
        // Datos para el gráfico:
        let data = [];
        datosComercios?.length &&
            datosComercios.map(
                (item, index) =>
                    index <= 14 &&
                    data.push({
                        type: item?.franquicia,
                        value: Number(item?.total),
                        key: index,
                    }),
            );

        const config = {
            appendPadding: 10,
            data,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,
            label: {
                type: 'inner',
                offset: '-50%',
                content: '{value}',
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                },
            },
            interactions: [
                {
                    type: 'element-selected',
                },
                {
                    type: 'element-active',
                },
                {
                    type: 'pie-statistic-active',
                },
            ],
            statistic: {
                title: {
                    offsetY: -4,
                    customHtml: (container, view, datum) => {
                        const { width, height } =
                            container.getBoundingClientRect();
                        const d = Math.sqrt(
                            Math.pow(width / 2, 2) + Math.pow(height / 2, 2),
                        );
                        const text = datum ? datum.type : 'Total pedidos';
                        return renderStatistic(d, text, {
                            fontSize: 10,
                        });
                    },
                },
            },
        };
        return <Pie {...config} />;
    };
    return (
        <Card className={clases.cardGraficoDona}>
            <h4 className={clases.titleMayorCompra}>
                Comercios con mayor cantidad de compras
            </h4>
            <GraficoCircular />
        </Card>
    );
};

export default CardGraficoCircular;

CardGraficoCircular.propTypes = {
    datosComercios: PropTypes.array,
};
