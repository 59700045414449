import { useState, useContext } from 'react';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';
import getBase64 from 'components/utils-components/getBase64';

const useNewPromBanner = () => {
    const {
        obtenerSucursales,
        getFranchiseCategoriesByFamily,
        obtenerFranquicias,
    } = useContext(UtilidadesContext);

    const {
        createOrEditPromo,
        characteristics,
        setCharacteristics,
        selectedProducts,
        setSelectedFranchises,
        setSelectedBranches,
    } = useContext(PromotionsContext);

    const [branches, setBranches] = useState([]);
    const [obtainingBranches, setObtainingBranches] = useState(false);
    const [obtainingCategories, setObtainingCategories] = useState(false);
    const [obtainingFranchises, setObtainingFranchises] = useState(false);
    const [selectedFamily, setSelectedFamily] = useState(null);
    const [selectedFranchiseCategory, setSelectedFranchiseCategory] =
        useState(null);
    const [franchises, setFranchises] = useState(null);

    const initialValues = { franchisesCategory: [], branches: [] };

    const handleCreateOrEditBanner = async (data, id) => {
        let imageToBase64;

        if (data.image[0].originFileObj) {
            imageToBase64 = await getBase64(data.image[0].originFileObj);
        } else {
            imageToBase64 = null;
        }

        const formatDate = (date) =>
            date ? date.toISOString(true).slice(0, 10) : null;

        const formatTime = (time) => {
            if (!time) return null;
            const date = new Date(time);
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${hours}:${minutes}:${seconds}`;
        };

        const dayOfWeek = {
            monday: 1,
            tuesday: 2,
            wednesday: 3,
            thursday: 4,
            friday: 5,
            saturday: 6,
            sunday: 0,
        };

        const programation = Array.isArray(data?.scheduled)
            ? data.scheduled.map((day) => ({
                  time_from: formatTime(day.promotionHourFrom),
                  time_to: formatTime(day.promotionHourTo),
                  week_day: dayOfWeek[day.promotionDay],
              }))
            : [];

        const details = {
            branch_id: data?.details?.map((detail) => {
                const branches = detail.branches || '';
                const branchId = branches
                    ? parseInt(branches.split('|')[0])
                    : null;
                return !isNaN(branchId) ? branchId : null;
            }),
            franchise_id: data?.details?.map((detail) => {
                const franchises = detail.franchises || '';
                const franchiseId = franchises
                    ? parseInt(franchises.split('|')[0])
                    : null;
                return !isNaN(franchiseId) ? franchiseId : null;
            }),
            franchise_family_id: data?.details?.map((detail) => {
                const families = detail.family || '';
                const familyId = families
                    ? parseInt(families.split('|')[0])
                    : null;
                return !isNaN(familyId) ? familyId : null;
            }),
            franchise_category_id: data?.details?.map((detail) => {
                const categories = detail.franchisesCategory || '';
                const categoryId = categories
                    ? parseInt(categories.split('|')[0])
                    : null;
                return !isNaN(categoryId) ? categoryId : null;
            }),
            audiencie_id: data?.audience ? parseInt(data?.audience) : null,
            zone_ids: data?.zonasCobertura?.length
                ? data?.zonasCobertura[0].zone_ids
                : null,
            city_id: data?.ciudad ? parseInt(data?.ciudad) : null,
            neighborhood_id: data?.barrio ? parseInt(data?.barrio) : null,
            points: data?.poligonozonageografica
                ? data?.poligonozonageografica
                : null,
        };

        if (details.points && details.points.length > 0) {
            const firstPoint = details.points[0];
            details.points.push(firstPoint);
        }

        const selectedProductsIds =
            selectedProducts?.map((product) => product.product_id) || [];

        if (data?.showAs === 'franchises') {
            const selectedDetailFranchises = (data.details || [])
                .map((detail) => parseInt(detail.franchises.split('|')[0], 10))
                .filter((franchise) => !isNaN(franchise));

            const selectedProductFranchisesId = (selectedProducts || []).map(
                (product) => product.franchise_id,
            );

            const isEqual = selectedDetailFranchises.every((id) =>
                selectedProductFranchisesId.includes(id),
            );

            if (!isEqual) {
                throw new Error(
                    'Debe seleccionar al menos un producto de cada franquicia.',
                );
            }
        }

        const products = {
            ids: selectedProductsIds,
        };

        const dataBody = {
            name: data?.promotionName,
            show: data?.showAs,
            show_in_home: data?.showHome,

            // has_bancard: data?.onlinePay,
            // has_monchis_driver: data?.monchisDriver,
            // exclusive: data?.exclusiveLocal,

            valid_from: formatDate(data?.promotionDateFrom),
            valid_to: formatDate(data?.promotionDateTo),

            programation: programation,
            products: products,
            details: details,

            banner_img: imageToBase64,
            promotion_image: imageToBase64,
        };

        return await createOrEditPromo(dataBody, id);
    };

    const handleFamilyChange = (value) => {
        setObtainingCategories(true);
        setSelectedFamily(value);
        const categoriesIds = '1,2,3,4,5,6'; // TO-DO: opción de Id para todas las familias
        const id = value === 'all' ? categoriesIds : value.split('|')[0];
        getFranchiseCategoriesByFamily([id]).then(() =>
            setObtainingCategories(false),
        );
    };

    const handleCategoryChange = (value) => {
        setObtainingFranchises(true);
        setSelectedFranchiseCategory(value);
        const id = value.split('|')[0];
        obtenerFranquicias(id).then((response) => {
            const filteredFranchises = response.filter(
                (franchise) => !franchise.name.includes('CERRADO'),
            );
            setFranchises(filteredFranchises);
            setObtainingFranchises(false);
        });
    };

    const handleFranchiseChange = (value, option) => {
        const id = value.split('|')[0];
        const selectedFranchise = { id, name: option.children };
        getBranches(id);
        setSelectedFranchises(selectedFranchise);
    };

    const handleBranchChange = (value, option) => {
        const id = value.split('|')[0];
        const selectedBranch = { id, name: option.children };
        setSelectedBranches(selectedBranch);
    };

    const getBranches = async (id) => {
        setObtainingBranches(true);
        const branches = await obtenerSucursales(id);
        setBranches(branches);
        setObtainingBranches(false);
    };

    const handleRemove = (index) => {
        const copyOfTheCharacteristics = structuredClone(characteristics);
        delete copyOfTheCharacteristics[index];
        const characteristicsWithoutUndefinedValue =
            copyOfTheCharacteristics.filter(
                (characteristic) => characteristic !== undefined,
            );
        setCharacteristics([...characteristicsWithoutUndefinedValue]);
    };

    const handleAddCharacteristic = (values) => {
        setCharacteristics([...characteristics, values]);
    };

    const getTextOption = (text) => {
        if (Array.isArray(text) || text === 'all') return 'Todas';

        return text.split('|')[1];
    };

    return {
        franchises,
        branches,
        obtainingBranches,
        obtainingCategories,
        obtainingFranchises,
        characteristics,
        initialValues,
        selectedFamily,
        selectedFranchiseCategory,

        handleCreateOrEditBanner,
        getBranches,
        setObtainingBranches,
        setObtainingCategories,
        setBranches,
        handleFranchiseChange,
        handleFamilyChange,
        handleBranchChange,
        handleRemove,
        handleAddCharacteristic,
        getTextOption,
        handleCategoryChange,
        setSelectedFranchiseCategory,
        setSelectedFamily,
    };
};

export default useNewPromBanner;
