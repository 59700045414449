import React from 'react';
import { Modal, Radio, Space, Form, message } from 'antd';

import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';

const CancelarOrdenModal = ({
    idOrden,
    listaMotivosCancelacion,
    isCancelarOrdenModalVisible,
    cancelarOrdenLoading,
    cerrarCancelarOrden,
    confirmarCancelarOrden,
}) => {
    const [formCancelarOrden] = Form.useForm();

    const confirmarCancelarOrdenButtonHandler = () => {
        formCancelarOrden
            .validateFields()
            .then((values) => {
                const idRazonCancelacion = values.idRazonCancelacion;
                const resetForm = formCancelarOrden;
                confirmarCancelarOrden(idOrden, idRazonCancelacion, resetForm);
            })
            .catch((error) => {
                message.error(
                    error?.message ||
                        'Hubo un problema al confirmar la cancelación de esta orden.',
                );
            });
    };

    return (
        <Modal open={isCancelarOrdenModalVisible} footer={null} closable={null}>
            <ModalContent
                title={`Está por cancelar la orden #${idOrden || '...'}`}
                okText="CANCELAR ORDEN"
                cancelText="VOLVER"
                onOk={confirmarCancelarOrdenButtonHandler}
                onCancel={cerrarCancelarOrden}
                isLoading={cancelarOrdenLoading}
                showWarnIcon
                isDelete
                invertPrimary>
                <Form
                    form={formCancelarOrden}
                    validateMessages={{ required: 'Seleccione un motivo.' }}>
                    <Form.Item
                        name="idRazonCancelacion"
                        rules={[{ required: true }]}>
                        <Radio.Group optionType="button">
                            <Space direction="vertical">
                                {listaMotivosCancelacion?.length ? (
                                    listaMotivosCancelacion.map((item) => (
                                        <Radio key={item.id} value={item.id}>
                                            {item.name}
                                        </Radio>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </Space>
                        </Radio.Group>
                    </Form.Item>
                </Form>
            </ModalContent>
        </Modal>
    );
};

CancelarOrdenModal.propTypes = {
    idOrden: PropTypes.any,
    listaMotivosCancelacion: PropTypes.array,
    isCancelarOrdenModalVisible: PropTypes.bool,
    cancelarOrdenLoading: PropTypes.bool,
    cerrarCancelarOrden: PropTypes.func,
    confirmarCancelarOrden: PropTypes.func,
};

export default CancelarOrdenModal;
