import { Col, Form, Input, Row, DatePicker, Select } from 'antd';
import React, { useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

const SeasonsForm = ({
    formInstance,
    setIsEditForm,
    initialValues,
    userLevelList,
}) => {
    const hasInitialValues = Boolean(initialValues?.id);
    const userLevelListHasData = Boolean(userLevelList?.length);

    let needSetInitialValues = false;

    // (New or Copy) and (Edit) season
    if (!setIsEditForm) {
        // Copy season case
        if (hasInitialValues) {
            needSetInitialValues = true;
        }
    } else {
        // Edit case
        if (hasInitialValues) {
            needSetInitialValues = true;
        } else {
            throw 'Hubo un problema al cargar la temporada seleccionada.';
        }
    }

    const dateFormItemDisabledDate = (date) => date < moment().startOf('day');

    useEffect(() => {
        if (needSetInitialValues) {
            formInstance.setFieldsValue({
                ...initialValues,
                date: initialValues?.start_date && [
                    moment(initialValues?.start_date, 'YYYY-MM-DD'),
                    moment(initialValues?.end_date, 'YYYY-MM-DD'),
                ],
                user_levels: initialValues?.user_levels?.map((userLevel) =>
                    String(userLevel?.id),
                ),
            });
        } else {
            formInstance.resetFields();
        }
    }, []);

    return (
        <Form layout="vertical" form={formInstance}>
            <Form.Item style={{ display: 'none' }} name="id">
                <Input type="hidden" />
            </Form.Item>
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item
                        name="name"
                        label="Nombre de la temporada"
                        rules={[{ required: true }]}>
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name="date"
                        label="Duración de la temporada"
                        rules={[{ required: true }]}>
                        <RangePicker
                            placeholder={['Desde', 'Hasta']}
                            disabledDate={dateFormItemDisabledDate}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name="user_levels"
                        label="Niveles asignados a temporada:"
                        rules={[{ required: true }]}>
                        <Select
                            mode="multiple"
                            disabled={!userLevelListHasData}>
                            {userLevelList?.map((userLevel) => (
                                <Option
                                    key={userLevel?.id}
                                    value={userLevel?.id}>
                                    {userLevel?.name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={24}>
                    <Form.Item
                        name="description"
                        label="Descripción"
                        rules={[{ required: true }]}>
                        <TextArea autoSize={{ minRows: 2, maxRows: 6 }} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default SeasonsForm;

SeasonsForm.propTypes = {
    formInstance: PropTypes.any,
    setIsEditForm: PropTypes.func,
    initialValues: PropTypes.object,
    userLevelList: PropTypes.array,
};
