import React, { Component } from 'react';
import PropTypes from 'prop-types';
export class Icon extends Component {
    render() {
        const { type, className } = this.props;
        return <>{React.createElement(type, { className: className })}</>;
    }
}

Icon.propTypes = {
    type: PropTypes.any,
    className: PropTypes.string,
};

export default Icon;
