import React, { useContext } from 'react';
import listaReciboContext from '../../../../../../context/facturacion/ListaRecibos/ListaReciboContext';
import { dateDMYyHora } from '../../../../../utils-components/Date';
import { formatoGuaranies } from '../../../../../utils-components/Number';
import { Typography, Row, Col, Divider, Card, Button } from 'antd';
import { useHistory } from 'react-router-dom';
import ABMLayout from '../../../../../../layouts/ABMLayout';

const { Title } = Typography;

const DetalleRecibos = () => {
    const history = useHistory();
    const recibosContext = useContext(listaReciboContext);
    const { reciboSeleccionado } = recibosContext;
    const { franchise_society } = reciboSeleccionado;
    const btnVolverHandler = () => {
        history.goBack();
    };
    const tipoDeTransaccion = (tipo) => {
        switch (tipo) {
            case 'cash':
                return 'Efectivo';
            case 'transfer':
                return 'Transferencia';
            case 'check':
                return 'Cheque';
            default:
                return 'No definido';
        }
    };

    return (
        <ABMLayout
            titulo="Detalles del recibo"
            acciones={<Button onClick={btnVolverHandler}>Volver atrás</Button>}>
            <Card style={{ padding: '3rem' }}>
                <Title
                    level={
                        2
                    }>{`#${reciboSeleccionado.id} - ${franchise_society.name}`}</Title>
                <Row>
                    <Col span={12}>
                        <Row>{`Razón social:  ${franchise_society.legal_name}`}</Row>
                        <Row>{`RUC:  ${franchise_society.ruc}`}</Row>
                        <Row>{`Dirección:  ${franchise_society.address}`}</Row>
                        <Row>{`Teléfono:  ${franchise_society.phone}`}</Row>
                    </Col>
                    <Col
                        span={12}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                        }}>
                        <Row>{`Nº de factura: ${
                            !reciboSeleccionado.invoice_number
                                ? 'Sin número'
                                : reciboSeleccionado.invoice_number
                        }`}</Row>
                        <Row
                            style={{
                                fontSize: '30px',
                                fontWeight: '500',
                            }}>{`${formatoGuaranies(
                            reciboSeleccionado.amount,
                        )}.`}</Row>
                    </Col>
                </Row>
                <Divider />
                <Row style={{ marginTop: '5rem' }}>
                    <Col span={12}>Tipo de recibo:</Col>
                    <Col span={12} style={{ textAlign: 'end' }}>
                        {reciboSeleccionado.concept}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>Número de recibo:</Col>
                    <Col span={12} style={{ textAlign: 'end' }}>
                        {reciboSeleccionado.receipt_number}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>Concepto:</Col>
                    <Col span={12} style={{ textAlign: 'end' }}>
                        {reciboSeleccionado.reference}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>Fecha de creación:</Col>
                    <Col span={12} style={{ textAlign: 'end' }}>
                        {dateDMYyHora(reciboSeleccionado.created_at)}
                    </Col>
                </Row>
                <Divider />
                <Row>
                    <Col span={12}>Tipo de transacción:</Col>{' '}
                    {/*Los tipos de transacción pueden ser: efectivo, cheque o transferencia*/}
                    <Col span={12} style={{ textAlign: 'end' }}>
                        {tipoDeTransaccion(reciboSeleccionado.type)}
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default DetalleRecibos;
