import React from 'react';
import { Modal } from 'antd';
import ModalContent from '../../../atoms/ModalContent';
import PropTypes from 'prop-types';

const MatchearModal = ({ modalMatchear, handleCancelMatchear }) => {
    return (
        <div>
            <Modal
                centered
                visible={modalMatchear}
                footer={null}
                closable={false}
                onCancel={handleCancelMatchear}>
                <ModalContent
                    title="¿Confirmás el matcheo?"
                    okText="Sí, confirmar"
                    cancelText="No, cancelar"
                    isLoading={false}
                    onCancel={handleCancelMatchear}
                    invertPrimary
                    showWarnIcon
                    isDelete
                />
            </Modal>
        </div>
    );
};

MatchearModal.propTypes = {
    modalMatchear: PropTypes.bool,
    handleCancelMatchear: PropTypes.func.isRequired,
};

export default MatchearModal;
