import React from 'react';
import { Card, Col, Progress, Row, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import clases from './analiticas.module.css';
import PropTypes from 'prop-types';
import { formatoGuaranies } from '../../../utils-components/Number';

const CardTotalVsPromedio = ({ datosComercios }) => {
    const promedioFormateado = (dato) =>
        formatoGuaranies(
            Math.trunc(dato?.promedio_gastado?.toString().replaceAll(',', '')),
        );
    return (
        <Card className={clases.cardComercios}>
            <h4 className={clases.titleComercios}>
                Monto total por comercio VS. ticket promedio por comercio
            </h4>
            <div className={(clases.topDirecciones, clases.topComercios)}>
                {datosComercios &&
                    datosComercios.map((item, index) => (
                        <>
                            <Row
                                key={item?.key}
                                className={clases.flexRowBetween}>
                                <Col>
                                    <p style={{ marginBottom: '0px' }}>{`${
                                        index + 1
                                    }. ${item?.franquicia} (${item?.total} ${
                                        item?.total > 1 ? 'pedidos' : 'pedido'
                                    }) - ${formatoGuaranies(
                                        item?.gastado,
                                    )}`}</p>
                                </Col>
                                <Col>
                                    <Text strong>
                                        {promedioFormateado(item)}
                                    </Text>
                                </Col>
                            </Row>
                            <Tooltip
                                title={`${item?.porcentaje} %`}
                                placement="topLeft">
                                <Progress
                                    percent={item?.porcentaje}
                                    showInfo={false}
                                    strokeColor={'#1779BA'}
                                    style={{ marginBottom: '1.5rem' }}
                                />
                            </Tooltip>
                        </>
                    ))}
            </div>
        </Card>
    );
};

export default CardTotalVsPromedio;

CardTotalVsPromedio.propTypes = {
    datosComercios: PropTypes.array,
};
