import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Card,
    Divider,
    Table,
    Tag,
    message,
    Spin,
    Button,
    Typography,
} from 'antd';
import {
    FieldTimeOutlined,
    CheckCircleOutlined,
    ReloadOutlined,
    SendOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons';

import useRouterQuery from 'hooks/useRouterQueries';
import useSupport from '../../../../../../hooks/support/useSupport';

import axios from '../../../../../../config/axios';
import { urls } from '../../../../../../constants';
import { Permisos } from '../../../../../../constants/Permisos';
import { formatoGuaranies } from '../../../../../utils-components/Number';

import Cards from '../../../../../atoms/Card/Card';
import CancelarOrdenModal from '../../../../../molecules/Support/Modal/CancelarOrdenModal';
import ProtectedComponent from '../../../../../../hoc/ProtectedComponent';

import classes from './verOrden.module.css';

const { Text } = Typography;

const VerOrden = () => {
    const {
        confirmarCancelarOrden,
        cancelarOrdenLoading,
        cerrarCancelarOrden,
        isCancelarOrdenModalVisible,
        listaMotivosCancelacion,
        cancelarOrden,
    } = useSupport();
    const { query } = useRouterQuery();
    const history = useHistory();

    const [saveDataOrder, setSaveDataOrder] = useState(1); // datos del backend
    const [orderDetails, setOrderDetails] = useState(); // detalles de la orden
    const [paymentState, setPaymentState] = useState(); //para saber si la orden tiene descuento.
    const [orderHistoriesCard, setHistoriesCards] = useState();

    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);

    const getOrder = useCallback(async (orderId) => {
        try {
            let params = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_B,
                order_id: orderId,
            };
            const response = await axios.get('/order_show', { params: params });
            const dataOrderDetail = response?.data?.data.order?.order_detail;
            const orderDetailArray = new Array();
            for (let i = 0; i < dataOrderDetail.length; i++) {
                const newDetails = {
                    product_name: dataOrderDetail[i]?.product_name,
                    quantity_product: dataOrderDetail[i]?.quantity_product,
                    price_product: dataOrderDetail[i]?.price_product,
                    comments: dataOrderDetail[i]?.comments,
                };
                orderDetailArray.push(newDetails);
            }

            //datos para las cards de estado.
            const histories = response?.data?.data?.order?.state_history;
            if (histories) {
                const newHistories = [];
                histories.forEach((item) => {
                    const estado = item.name;
                    const fecha = item.started_at;
                    const user = item.first_name;
                    let icono = null;
                    switch (estado) {
                        case 'Pendiente':
                            icono = (
                                <FieldTimeOutlined
                                    className={classes.fieldTime}
                                />
                            );
                            break;
                        case 'Confirmada':
                            icono = (
                                <CheckCircleOutlined
                                    className={classes.checkCircle}
                                />
                            );
                            break;
                        case 'Cocina':
                            icono = (
                                <ReloadOutlined
                                    className={classes.reloadIcon}
                                />
                            );
                            break;
                        case 'Delivery':
                            icono = (
                                <SendOutlined className={classes.sendIcon} />
                            );
                            break;
                        case 'Entregada':
                            icono = (
                                <CheckCircleOutlined
                                    className={classes.checkCircle}
                                />
                            );
                            break;
                        case 'Cancelada':
                            icono = (
                                <CloseCircleOutlined
                                    className={classes.checkCircle}
                                />
                            );
                            break;
                    }
                    newHistories.push(
                        <Col span={6}>
                            <Cards icon={icono}>
                                <h2 style={{ color: 'black' }}>{estado}</h2>
                                <h4 style={{ color: 'black' }}>
                                    {moment(fecha).format(
                                        'DD/MM/YYYY HH:mm:ss',
                                    )}
                                </h4>
                                <h2 style={{ color: 'black' }}>{user}</h2>
                            </Cards>
                        </Col>,
                    );
                });

                setHistoriesCards([...newHistories]);
            }

            setOrderDetails(orderDetailArray);
            setSaveDataOrder(response);
            setPaymentState(response?.data?.data?.order?.payment_state[0]);
        } catch (error) {
            message.error('No encotramos datos');
            console.error('PROBLEM', error);
        }
    }, []);

    const cancelarOrdenButtonHandler = () => {
        cancelarOrden(saveDataOrder?.data?.data, true);
    };

    const CancelOrderButton = () => {
        return (
            <Button
                size="small"
                danger
                className={classes.cancelButton}
                onClick={cancelarOrdenButtonHandler}>
                Cancelar orden
            </Button>
        );
    };

    useEffect(() => {
        const orderId = query?.get('order_id');
        if (orderId) {
            getOrder(orderId);
        } else {
            goBack();
        }
    }, [getOrder, goBack, history, query]);

    const columns = [
        {
            title: 'Producto',
            dataIndex: 'producto',
            key: 'producto',
        },
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
        },
        {
            title: 'Precio',
            dataIndex: 'precio',
            key: 'precio',
        },
        {
            title: 'Comentarios',
            key: 'comentarios',
            dataIndex: 'comentarios',
        },
    ];

    const userId = saveDataOrder?.data?.data?.order?.user?.user_id || '';

    const orderHasCupon = paymentState?.payment_type === 'DISCOUNT';

    return (
        <Spin spinning={!saveDataOrder?.data?.data?.order?.user?.first_name}>
            <div className={classes.containerHeader}>
                <Button type="default" onClick={goBack}>
                    Volver
                </Button>
            </div>
            <Row gutter={24} align="top" justify="space-between">
                <Col span={12}>
                    <Card title="Datos de cliente">
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>ID de usuario:</Text>
                            </Col>
                            <Col>
                                <Text>{userId}</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>Nombre:</Text>
                            </Col>
                            <Col>
                                <Text>
                                    {
                                        saveDataOrder?.data?.data?.order?.user
                                            ?.first_name
                                    }{' '}
                                    {
                                        saveDataOrder?.data?.data?.order?.user
                                            ?.last_name
                                    }
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>RUC:&nbsp;</Text>
                            </Col>
                            <Col>
                                <Text>
                                    {saveDataOrder?.data?.data?.order
                                        ?.invoice_ruc || '-'}
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>Razón social: </Text>
                            </Col>
                            <Col>
                                <Text>
                                    {saveDataOrder?.data?.data?.order
                                        ?.invoice_name || '-'}
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>Dirección: </Text>
                            </Col>
                            <Col>
                                <Text>
                                    {
                                        saveDataOrder?.data?.data?.order?.user
                                            ?.street1
                                    }{' '}
                                    {
                                        saveDataOrder?.data?.data?.order?.user
                                            ?.street2
                                    }
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>Teléfono: </Text>
                            </Col>
                            <Col>
                                <Text>
                                    {saveDataOrder?.data?.data?.order?.user
                                        ?.phone || '-'}
                                </Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>Email: </Text>
                            </Col>
                            <Col>
                                <Text>
                                    {saveDataOrder?.data?.data?.order?.user
                                        ?.email || '-'}
                                </Text>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={12}>
                    <Card
                        title={saveDataOrder?.data?.data?.order?.branch?.name}>
                        <Row>
                            <Col className={classes.textSpace}>
                                <Text strong>Número de orden: </Text>
                            </Col>
                            <Col>
                                <Text>{`Orden #${saveDataOrder?.data?.data?.order.id}`}</Text>
                            </Col>
                        </Row>
                        <Row className={classes.orderStateRow}>
                            <Col className={classes.textSpace}>
                                <Text strong>Estado de la orden: </Text>
                            </Col>
                            <Col>
                                <Text>
                                    {
                                        saveDataOrder?.data?.data?.order
                                            ?.order_state
                                    }
                                </Text>
                            </Col>
                            <ProtectedComponent
                                permiso={Permisos.reportes.ordenes.cancelOrder}
                                componente={CancelOrderButton}
                            />

                            <CancelarOrdenModal
                                cancelarOrdenLoading={cancelarOrdenLoading}
                                cerrarCancelarOrden={cerrarCancelarOrden}
                                confirmarCancelarOrden={confirmarCancelarOrden}
                                idOrden={saveDataOrder?.data?.data?.order?.id}
                                isCancelarOrdenModalVisible={
                                    isCancelarOrdenModalVisible
                                }
                                listaMotivosCancelacion={
                                    listaMotivosCancelacion
                                }
                            />
                        </Row>
                        {/* total con delivery */}
                        <Divider />
                        <Text strong>
                            TOTAL:{' '}
                            {formatoGuaranies(
                                saveDataOrder?.data?.data?.order?.total,
                            )}
                        </Text>
                    </Card>
                </Col>
            </Row>

            <Divider />

            <Table
                pagination={false}
                columns={columns}
                dataSource={orderDetails?.map((item) => {
                    let producto = item.product_name;
                    let cantidad = item.quantity_product;
                    let precio = formatoGuaranies(item.price_product);
                    let comentarios = item.comments;

                    return {
                        producto: producto,
                        cantidad: cantidad,
                        precio: precio,
                        comentarios: comentarios,
                    };
                })}
                bordered></Table>

            <Divider />

            <Row gutter={24} align="top" justify="space-between">
                <Col span={12}>
                    {/* OBSERVACIONES DE PAGO */}
                    <Card title="Observaciones de pago">
                        <div className={classes.paymentObs}>
                            {saveDataOrder?.data?.data?.order?.payment_type ? (
                                <Tag color="blue">
                                    {
                                        saveDataOrder?.data?.data?.order
                                            ?.payment_type
                                    }
                                </Tag>
                            ) : null}
                            {saveDataOrder?.data?.data?.order?.payment_state
                                ?.nro_transaction_bancard ? (
                                <Tag color="blue">
                                    {
                                        saveDataOrder?.data?.data?.order
                                            ?.payment_state
                                            ?.nro_transaction_bancard
                                    }
                                </Tag>
                            ) : null}
                            {saveDataOrder?.data?.data?.order?.payment_state
                                ?.name ? (
                                <Tag color="blue">
                                    {
                                        saveDataOrder?.data?.data?.order
                                            ?.payment_state?.name
                                    }
                                </Tag>
                            ) : null}
                        </div>

                        <Divider />

                        <>
                            {orderHasCupon ?? <h3>Orden con descuento</h3>}
                            <p>
                                {orderHasCupon
                                    ? 'Código del cupón: '
                                    : 'ID de transacción: '}
                                <Tag color="blue">{`${paymentState?.nro_transaction_bancard}`}</Tag>
                            </p>
                        </>
                        {orderHasCupon ? (
                            <p>
                                Monto del cupón:{' '}
                                <Tag color="blue">{`${formatoGuaranies(
                                    paymentState.amount,
                                )}`}</Tag>
                            </p>
                        ) : (
                            <p>Sin observaciones adicionales</p>
                        )}
                    </Card>
                </Col>

                <Col span={12}>
                    <Card>
                        <Row>
                            <Text strong>
                                Sub-total:
                                <Text>
                                    {formatoGuaranies(
                                        saveDataOrder?.data?.data?.order
                                            ?.sub_total,
                                    )}
                                </Text>
                            </Text>
                        </Row>
                        <Row>
                            <Text strong>
                                Delivery:
                                <Text>
                                    {formatoGuaranies(
                                        saveDataOrder?.data?.data?.order
                                            ?.delivery_price,
                                    )}
                                </Text>
                            </Text>
                        </Row>
                        <Divider />

                        <h1>
                            {' '}
                            Total:{' '}
                            {formatoGuaranies(
                                saveDataOrder?.data?.data?.order?.total,
                            )}{' '}
                        </h1>
                    </Card>
                </Col>
            </Row>

            <Divider />

            {/* cards de cambios de estado, se setean las fechas con moment */}
            <Row gutter={20}>{orderHistoriesCard}</Row>
        </Spin>
    );
};

export default VerOrden;

VerOrden.propTypes = {
    location: PropTypes.object,
};
