import {
    PROMOTIONS_LIST,
    PROMOTIONS_NEW_OR_EDIT,
    PROMOTIONS_ID,
    PROMOTIONS_ENABLE,
    PROMOTIONS_SHOW_DEACTIVATE_MODAL,
    PROMOTIONS_SHOW_ACTIVATE_MODAL,
    PROMOTIONS_CURRENT_PAGE,
    PROMOTIONS_FILTERS_TO_LIST,
    PROMOTIONS_SHOW_ADD_PRODUCT_MODAL,
    PROMOTIONS_SELECTED_PRODUCTS,
    PROMOTIONS_FRANCHISES_SELECTED,
    PROMOTIONS_BRANCHES_SELECTED,
    PROMOTIONS_PRODUCT_LIST,
    PROMOTIONS_PRODUCT_LIST_PAGE,
    PROMOTIONS_AUDIENCE_LIST,
    PROMOTION_CHARACTERISTICS,
    PROMOTIONS_LOADING_PROMOTIONS_LIST,
    PROMOTIONS_DATA_SOURCE,
    PROMOTIONS_PAGE_SIZE,
    PROMOTIONS_TOTAL,
} from './Types';

export const initialState = {
    promotionsList: [],
    newOrEditProm: '',
    id: null,
    enable: null,
    showDeactivatePromotionModal: false,
    showActivatePromotionModal: false,
    filters: null,
    showAddProductModal: false,
    selectedProducts: [],
    selectedFranchises: [],
    selectedBranches: [],
    productList: [],
    audienceList: [],
    characteristics: [],
    selectedBannerById: [],
    pageSize: 0,
    total: 0,
    currentPage: 1,
    loadingPromotionList: false,
    dataSource: [],
    bannerSelectedData: [],
    promoData: {},
    loading: false,
    productListPage: 1,
    productListTotal: 0,
    productListPageSize: 15,
};

const PromotionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case PROMOTIONS_LIST:
            return {
                ...state,
                promotionsList: action.payload.promotionsList,
                pageSize: action.payload.pageSize,
                total: action.payload.total,
                loadingPromotionList: action.payload.loadingPromotionList,
            };

        case PROMOTIONS_NEW_OR_EDIT:
            return {
                ...state,
                newOrEditProm: action.payload.newOrEditProm,
            };

        case PROMOTIONS_ID:
            return {
                ...state,
                id: action.payload.id,
            };

        case PROMOTIONS_ENABLE:
            return {
                ...state,
                enable: action.payload.enable,
            };

        case PROMOTIONS_SHOW_DEACTIVATE_MODAL:
            return {
                ...state,
                showDeactivatePromotionModal:
                    action.payload.showDeactivatePromotionModal,
            };

        case PROMOTIONS_SHOW_ACTIVATE_MODAL:
            return {
                ...state,
                showActivatePromotionModal:
                    action.payload.showActivatePromotionModal,
            };

        case PROMOTIONS_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.payload.currentPage,
            };

        case PROMOTIONS_FILTERS_TO_LIST:
            return {
                ...state,
                filters: action.payload.filters,
            };

        case PROMOTIONS_SHOW_ADD_PRODUCT_MODAL:
            return {
                ...state,
                showAddProductModal: action.payload.showAddProductModal,
            };

        case PROMOTIONS_SELECTED_PRODUCTS:
            return {
                ...state,
                selectedProducts: action.payload.selectedProducts,
            };

        case PROMOTIONS_FRANCHISES_SELECTED:
            return {
                ...state,
                selectedFranchises: action.payload.selectedFranchises,
            };

        case PROMOTIONS_BRANCHES_SELECTED:
            return {
                ...state,
                selectedBranches: action.payload.selectedBranches,
            };

        case PROMOTIONS_PRODUCT_LIST:
            return {
                ...state,
                productList: action.payload.productList,
                productListTotal: action.payload.productListTotal,
                productListPageSize: action.payload.productListPageSize,
            };

        case PROMOTIONS_PRODUCT_LIST_PAGE:
            return {
                ...state,
                productListPage: action.payload.productListPage,
            };

        case PROMOTIONS_AUDIENCE_LIST:
            return {
                ...state,
                audienceList: action.payload.audienceList,
            };

        case PROMOTION_CHARACTERISTICS:
            return {
                ...state,
                characteristics: action.payload.characteristics,
            };

        case PROMOTIONS_LOADING_PROMOTIONS_LIST:
            return {
                ...state,
                loadingPromotionList: action.payload.loadingPromotionList,
            };

        case PROMOTIONS_DATA_SOURCE:
            return {
                ...state,
                dataSource: action.payload.dataSource,
            };

        case PROMOTIONS_PAGE_SIZE:
            return {
                ...state,
                pageSize: action.payload.pageSize,
            };

        case PROMOTIONS_TOTAL:
            return {
                ...state,
                total: action.payload.total,
            };

        default:
            return state;
    }
};

export default PromotionsReducer;
