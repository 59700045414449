import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import SupportContext from '../../../context/support/SupportContext';
import classes from './Integraciones.module.css';
import { franquiciasIntegracionesSupport } from '../../../constants/franquiciasIntegracionesSupport';
const { Text } = Typography;
import BotonPasarSucursalComponent from './BotonPasarSucursalComponent';
import useSupport from '../../../hooks/support/useSupport';

const Integraciones = ({
    esIntegracion,
    esJedi,
    ordenSeleccionada,
    jediId,
    fechaJedi,
    idMcDonalds,
    estadoOrden,
    idIntegracion,
    envioIntegracion,
    esVerDetalle,
}) => {
    // si es jedi abrir modal de jedi, sino abrir modal normal
    const { abrirModalVerEstadoJedi } = useContext(SupportContext);
    const { abrirModalEnviarIntegracion, abrirModalEnviarJedi } = useSupport();
    const idLocal = ordenSeleccionada?.branch?.franchise_id;

    let IntegracionComponent = null;

    const datosIntegracion = franquiciasIntegracionesSupport.find(
        (item) => item.idFranquicia === idLocal && item.tipo === 'INTEGRACION',
    );
    const idExternoIntegracion = idMcDonalds || idIntegracion;

    const fechaJediFormato = fechaJedi
        ? fechaJedi.toString().replace('.000Z', '').replace('T', ' ')
        : '';
    const fechaJediFormateada =
        fechaJedi &&
        `${new Date(fechaJediFormato).toLocaleDateString('es-PY')} - ${new Date(
            fechaJediFormato,
        ).toLocaleTimeString('es-PY', {
            hour12: false,
            timeStyle: 'short',
        })} hs.`;

    if (estadoOrden === 3 || esVerDetalle) {
        IntegracionComponent = esIntegracion && (
            <BotonPasarSucursalComponent
                envioIntegracion={envioIntegracion}
                esJedi={esJedi}
                abrirModalEnviarJedi={abrirModalEnviarJedi}
                abrirModalEnviarIntegracion={abrirModalEnviarIntegracion}
                ordenSeleccionada={ordenSeleccionada}
                esVerDetalle={esVerDetalle}
            />
        );
    } else if (
        (estadoOrden === 4 ||
            estadoOrden === 5 ||
            estadoOrden === 9 ||
            estadoOrden === 6) &&
        !esVerDetalle
    ) {
        if (esIntegracion && envioIntegracion) {
            IntegracionComponent =
                esJedi && jediId ? (
                    <div>
                        <div>
                            <Text level={5} strong={true}>
                                JEDI ID:
                            </Text>
                            <Text> {jediId}</Text>
                        </div>
                        <div>
                            <Text level={5} strong={true}>
                                Fecha:
                            </Text>
                            <Text> {fechaJediFormateada}</Text>
                        </div>
                        <div
                            onClick={() =>
                                abrirModalVerEstadoJedi(ordenSeleccionada)
                            }>
                            <Text className={classes.verEstadoJedi}>
                                VER ESTADO
                            </Text>
                        </div>
                    </div>
                ) : datosIntegracion && idExternoIntegracion ? (
                    <div>
                        <div>
                            <Text level={5} strong={true}>
                                Orden enviada a {datosIntegracion.nombre}
                            </Text>
                        </div>
                        <div>
                            <Text>ID Externo: {idExternoIntegracion}</Text>
                        </div>
                    </div>
                ) : null;
        } else if (esIntegracion && !envioIntegracion) {
            IntegracionComponent = esIntegracion && (
                <BotonPasarSucursalComponent
                    envioIntegracion={envioIntegracion}
                    esJedi={esJedi}
                    abrirModalEnviarJedi={abrirModalEnviarJedi}
                    abrirModalEnviarIntegracion={abrirModalEnviarIntegracion}
                    ordenSeleccionada={ordenSeleccionada}
                    esVerDetalle={esVerDetalle}
                />
            );
        } else {
            IntegracionComponent = null;
        }
    }

    return IntegracionComponent;
};

Integraciones.propTypes = {
    esIntegracion: PropTypes.any,
    esJedi: PropTypes.any,
    estadoOrden: PropTypes.any,
    ordenSeleccionada: PropTypes.any,
    esVerDetalle: PropTypes.bool,
};

export default React.memo(Integraciones);
