import React, { useContext } from 'react';
import { Modal, Spin } from 'antd';
import ModalContent from '../../../../atoms/ModalContent';
import HeaderVerOrden from '../../HeaderVerOrden/HeaderVerOrden';
import TableDetalleVerOrden from '../../../../atoms/support/TablaDetalleVerOrden/TableDetalleVerOrden';
import PropTypes from 'prop-types';
import { formatoGuaranies } from '../../../../utils-components/Number';
import NotaVerOrden from '../../../../atoms/support/NotaVerOrden/NotaVerOrden';
import AccionesDetalleVerOrden from '../../../../atoms/support/AccionesDetalleVerOrden/AccionesDetalleVerOrden';
import SupportContext from '../../../../../context/support/SupportContext';
import UtilidadesContext from '../../../../../context/utilidades/UtilidadesContext';
import useSupport from '../../../../../hooks/support/useSupport';

const VerOrdenModal = ({
    ordenSeleccionada,
    isVerOrdenModalVisible,
    cerrarVerOrden,
    cambiarEstado,
    cancelarOrden,
    obtenerSiguienteEstado,
    verificarDireccion,
    confirmarEnviarJedi,
}) => {
    const siguienteEstadoObj = obtenerSiguienteEstado(
        ordenSeleccionada?.order_state_id,
        ordenSeleccionada?.delivery_type,
    );

    const siguienteEstadoTexto = siguienteEstadoObj?.nombre || '';

    const cambiarEstadoOrdenButtonHandler = () =>
        cambiarEstado(ordenSeleccionada, true);

    const cancelarOrdenButtonHandler = () =>
        cancelarOrden(ordenSeleccionada, true);

    const { hasPermissionToChangeState } = useContext(SupportContext);

    const { imprimirOrdenLoading } = useSupport();

    const { ordersStates } = useContext(UtilidadesContext);
    const orderState = ordersStates.find(
        (state) => state?.id === ordenSeleccionada?.order_state_id,
    )?.name;

    const accionesFooterModal = (
        <AccionesDetalleVerOrden
            ordenSeleccionada={ordenSeleccionada}
            esJedi={ordenSeleccionada?.has_jedi}
            siguienteEstadoTexto={siguienteEstadoTexto}
            cambiarEstadoOrdenButtonHandler={cambiarEstadoOrdenButtonHandler}
            cancelarOrdenButtonHandler={cancelarOrdenButtonHandler}
            enviarJediHandler={confirmarEnviarJedi}
            hasPermissionToChangeState={hasPermissionToChangeState}
        />
    );

    const getOrderState = () => {
        let state = '';

        orderState === 'Confirmada' && (state = 'Pendiente');
        orderState === 'Retirar' && (state = 'Pick up');
        orderState === 'Entregada' && (state = 'Finalizado');
        orderState === 'Cancelada' && (state = 'Cancelado');

        return !state.length ? orderState : state;
    };

    const clientCashAmount = Number(ordenSeleccionada?.client_cash_amount);

    return (
        <Modal
            centered
            closable={true}
            footer={false}
            open={isVerOrdenModalVisible}
            width={'87%'}
            onCancel={cerrarVerOrden}>
            <Spin spinning={imprimirOrdenLoading}>
                <ModalContent
                    style={{ paddingLeft: 0 }}
                    isLoading={false}
                    customFooter={accionesFooterModal}>
                    {ordenSeleccionada && (
                        <>
                            <HeaderVerOrden
                                idEstadoOrden={
                                    ordenSeleccionada?.order_state_id
                                }
                                orderState={getOrderState()}
                                ordenOriginal={ordenSeleccionada}
                                orderId={ordenSeleccionada?.id}
                                localName={ordenSeleccionada?.branch?.name}
                                dateOrder={ordenSeleccionada?.confirmed_at}
                                clientName={`${ordenSeleccionada?.user?.first_name} ${ordenSeleccionada?.user?.last_name}`}
                                razonSocial={ordenSeleccionada?.invoice_name}
                                ruc={ordenSeleccionada?.invoice_ruc}
                                phoneNumber={ordenSeleccionada?.user?.phone}
                                address={`${ordenSeleccionada?.address?.street1}, ${ordenSeleccionada?.address?.number}, ${ordenSeleccionada?.address?.street2}`}
                                referencia={
                                    ordenSeleccionada?.address?.reference
                                }
                                latitudDireccionCliente={
                                    ordenSeleccionada?.address?.latitude
                                }
                                longitudDireccionCliente={
                                    ordenSeleccionada?.address?.longitude
                                }
                                tipoPago={ordenSeleccionada?.payment_type}
                                subtotalOrder={formatoGuaranies(
                                    ordenSeleccionada?.total,
                                )}
                                costoDelivery={formatoGuaranies(
                                    ordenSeleccionada?.delivery_price,
                                )}
                                costAssumedByFranchise={
                                    ordenSeleccionada?.discount
                                        ?.franchise_assume_cost
                                }
                                discountAmount={formatoGuaranies(
                                    ordenSeleccionada?.discount
                                        ?.discount_amount,
                                )}
                                codigoOnline={ordenSeleccionada?.payments?.map(
                                    (item) => item?.reference,
                                )}
                                idCliente={ordenSeleccionada?.user_id}
                                deliveryType={ordenSeleccionada?.delivery_type}
                                addressArr={ordenSeleccionada?.address}
                                verificarDireccion={verificarDireccion}
                                clientCashAmount={
                                    clientCashAmount
                                        ? formatoGuaranies(clientCashAmount)
                                        : null
                                }
                                telefonoLocal={ordenSeleccionada?.branch?.phone}
                                permitirRevertirPagoOnline={
                                    ordenSeleccionada?.actions
                                        ?.reverse_bancard ||
                                    ordenSeleccionada?.payments.some(
                                        (payment) =>
                                            payment.pre_authorization === true,
                                    )
                                }
                                tipoPagoSoloCupon={
                                    ordenSeleccionada?.payments?.find(
                                        (item) =>
                                            item?.payment_type?.name ===
                                            'DISCOUNT',
                                    ) &&
                                    ordenSeleccionada?.payments?.length === 1
                                }
                                pagoRevertido={
                                    ordenSeleccionada?.actions?.is_reversed
                                }
                            />

                            <NotaVerOrden
                                contenido={ordenSeleccionada?.comments}
                            />

                            <TableDetalleVerOrden
                                listaProductos={
                                    ordenSeleccionada?.order_products
                                }
                            />
                        </>
                    )}
                </ModalContent>
            </Spin>
        </Modal>
    );
};

VerOrdenModal.propTypes = {
    ordenSeleccionada: PropTypes.object,
    isVerOrdenModalVisible: PropTypes.bool,
    cerrarVerOrden: PropTypes.func,
    cambiarEstado: PropTypes.func,
    cancelarOrden: PropTypes.func,
    obtenerSiguienteEstado: PropTypes.func,
    verificarDireccion: PropTypes.func,
    confirmarEnviarJedi: PropTypes.func,
};

export default VerOrdenModal;
