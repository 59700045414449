/**
 * filterOption
 * Función para agregar a los select del Ant Design para buscar al escribir.
 *
 * Ejemplo de uso:
 * <Select
 *      showSearch
 *      optionFilterProp="children"
 *      filterOption={filterOption}
 *  >
 *          <Option
 *              value="value"
 *          >
 *              Example Text
 *          </Option>
 *  </Select>
 */

const filterOption = (input, option) => {
    return (option?.children ?? '').toLowerCase().includes(input.toLowerCase());
};

export default filterOption;
