import React, { useEffect, useState } from 'react';
import ModalContent from '../../../atoms/ModalContent';
import { Col, Input, Row, Form, Modal, Checkbox } from 'antd';
import Mapa from '../../../mapa/Mapa';
import PropTypes from 'prop-types';

const VerificarDireccionModal = ({
    ordenSeleccionada,
    isVerificarDireccionModalVisible,
    verificarDireccionLoading,
    cerrarVerificarDireccion,
    confirmarVerificarDireccion,
}) => {
    const [formModalVerificarDireccion] = Form.useForm();

    const [latitudSeleccionada, setLatitudSeleccionada] = useState(undefined);
    const [longitudSeleccionado, setLongitudSeleccionado] = useState(undefined);
    const direccion = ordenSeleccionada?.address;

    useEffect(() => {
        if (direccion) {
            const initialValuesForm = {
                nombre: direccion.name,
                callePrincipal: direccion.street1,
                calleSecundaria: direccion.street2,
                nroCasa: direccion.number,
                referencia: direccion.reference,
            };
            formModalVerificarDireccion.setFieldsValue(initialValuesForm);
        }
    }, [direccion]);

    const sendDatosBackend = () => {
        formModalVerificarDireccion.validateFields().then((values) => {
            const data = {
                id: direccion.id,
                name: values.nombre,
                street1: values.callePrincipal,
                street2: values.calleSecundaria,
                number: values.nroCasa,
                reference: values.referencia,
                latitude: latitudSeleccionada,
                longitude: longitudSeleccionado,
                checked: values.direccionComprobada,
            };
            formModalVerificarDireccion.resetFields();
            confirmarVerificarDireccion(data);
        });
    };

    return (
        <Modal
            title="Verificar dirección"
            open={isVerificarDireccionModalVisible}
            footer={null}
            closable={null}
            width={800}
            style={{ top: 20 }}>
            <ModalContent
                cancelText="Cancelar"
                okText="Guardar"
                isLoading={verificarDireccionLoading}
                onOk={sendDatosBackend}
                onCancel={cerrarVerificarDireccion}>
                {direccion && (
                    <Form
                        form={formModalVerificarDireccion}
                        layout="vertical"
                        validateMessages={{
                            required: 'Este campo es obligatorio.',
                        }}>
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Calle principal"
                                    name="callePrincipal"
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Calle secundaria"
                                    name="calleSecundaria"
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Nro. de casa"
                                    name="nroCasa"
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Referencia"
                                    name="referencia"
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Dirección Comprobada"
                                    name="direccionComprobada"
                                    valuePropName="checked"
                                    rules={[{ required: true }]}>
                                    <Checkbox />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Mapa
                                        latitud={direccion.latitude}
                                        longitud={direccion.longitude}
                                        obtenerLatitud={setLatitudSeleccionada}
                                        obtenerLongitud={
                                            setLongitudSeleccionado
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                )}
            </ModalContent>
        </Modal>
    );
};

export default VerificarDireccionModal;

VerificarDireccionModal.propTypes = {
    ordenSeleccionada: PropTypes.object,
    isVerificarDireccionModalVisible: PropTypes.bool,
    verificarDireccionLoading: PropTypes.bool,
    cerrarVerificarDireccion: PropTypes.func,
    confirmarVerificarDireccion: PropTypes.func,
};
