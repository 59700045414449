import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent/ModalContentSupport';

const CambiarEstadoModal = ({
    idOrden,
    siguienteEstado,
    isCambiarEstadoModalVisible,
    cambiarEstadoLoading,
    cerrarCambiarEstado,
    confirmarCambiarEstado,
}) => {
    let cambiarEstadoModal;

    if (siguienteEstado) {
        const nombre = siguienteEstado?.nombre;
        let titulo = isNaN(idOrden)
            ? `¿Pasar las órdenes seleccionadas a ${nombre}?`
            : `¿Pasar la órden #${idOrden} a ${nombre}?`;

        cambiarEstadoModal = (
            <Modal
                open={isCambiarEstadoModalVisible}
                footer={null}
                closable={null}>
                <ModalContent
                    title={titulo}
                    okText="CONFIRMAR"
                    cancelText="CANCELAR"
                    onOk={confirmarCambiarEstado}
                    onCancel={cerrarCambiarEstado}
                    isLoading={cambiarEstadoLoading}
                    showWarnIcon
                />
            </Modal>
        );
    }

    return <>{cambiarEstadoModal}</>;
};

CambiarEstadoModal.propTypes = {
    idOrden: PropTypes.any,
    siguienteEstado: PropTypes.object,
    isCambiarEstadoModalVisible: PropTypes.bool,
    cambiarEstadoLoading: PropTypes.bool,
    cerrarCambiarEstado: PropTypes.func,
    confirmarCambiarEstado: PropTypes.func,
};

export default CambiarEstadoModal;
