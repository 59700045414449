import React, { useReducer } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import axios from '../../../config/axios';
import { urls } from '../../../constants';
import CuponesContext from './CuponesContext';
import CuponesReducer, { initialState } from './CuponesReducer';

import { message } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import { formatoFecha } from '../../../components/utils-components/Date';
import { formatoGuaranies } from '../../../components/utils-components/Number';
import clienteAxios from '../../../config/axios';
import {
    BUSCA_USUARIO_CUPON,
    GUARDAR_LISTA_USUARIOS_SELECCIONADOS,
    OBTENER_ID_USUARIO_CUPON,
    OBTENER_MOTIVOS,
    OBTENER_USUARIOS,
    RESTABLECER_ID_USUARIO_CUPON,
    USUARIOS_LISTA_ELIMINAR,
} from '../../../types';
import {
    CUPONES_ESTABLECER_SELECCIONADO,
    CUPONES_LISTA,
    CUPONES_LISTA_LOADING,
    CUPONES_LOADING_FORM,
    CUPONES_TARJETAS_LISTA,
    CUPONES_TARJETAS_LOADING,
    CUPONES_TIPOS_PAGO_LISTA,
    CUPONES_TIPOS_PAGO_LOADING,
    CUPONS_CUSTOMIZED_NOTIFICATIONS,
} from './Types';

const CuponesState = ({ children }) => {
    const [state, dispatch] = useReducer(CuponesReducer, initialState);

    const mixpanel = useMixpanel();

    const obtenerListaCupones = async (params) => {
        dispatch({
            type: CUPONES_LISTA_LOADING,
        });
        try {
            // Posibles params: franchise_id=2&search=codigo&branch_id=1&product_id=1
            const respuesta = await axios.get('/get_discount_codes', {
                params,
            });
            let listaCuponesArr = respuesta?.data?.data?.discounts;
            const cantidadTotalListaCupones = respuesta?.data?.data?.total || 0;

            if (!listaCuponesArr?.length)
                throw 'La lista de cupones esta vacía';

            listaCuponesArr = listaCuponesArr.map((cupon) => {
                let fechaFrom = cupon?.valid_from;
                let fechaTo = cupon?.valid_to;
                let fechaNormalizadaFrom = fechaFrom.substring(
                    0,
                    fechaFrom.indexOf('T'),
                );
                let fechaNormalizadaTo = fechaTo.substring(
                    0,
                    fechaTo.indexOf('T'),
                );
                let fechaDesde =
                    moment(fechaNormalizadaFrom).format('DD/MM/YYYY');
                let fechaHasta =
                    moment(fechaNormalizadaTo).format('DD/MM/YYYY');
                let fechaUnida = `${fechaDesde} - ${fechaHasta}`;

                const tipo = cupon?.type;
                let monto = Number(cupon?.amount);
                monto =
                    tipo === 'percent' ? `${monto}%` : formatoGuaranies(monto);

                let creadoel = formatoFecha(cupon?.created_at);

                const listaFranquicias =
                    cupon?.discount_code_details?.filter(
                        (item) => item?.franchise_name,
                    ) || undefined;
                let usarEn = '';

                if (listaFranquicias?.length) {
                    listaFranquicias.forEach((item, index) => {
                        const isLastItem =
                            index === listaFranquicias?.length - 1;
                        usarEn += `${item?.franchise_name}${
                            !isLastItem ? ', ' : ''
                        }`;
                    });
                } else {
                    usarEn = 'Todas las franquicias';
                }

                return {
                    key: cupon?.id,
                    id: cupon?.id,
                    codigo: cupon?.code,
                    monto,
                    usaren: usarEn,
                    limites: {
                        limiteUso: cupon?.usage_limit,
                        limitePorUser: cupon?.max_per_user,
                    },
                    validez: fechaUnida,
                    estado: cupon?.enabled,
                    creado: creadoel,
                    cuponOriginal: cupon,
                };
            });

            dispatch({
                type: CUPONES_LISTA,
                payload: {
                    listaCupones: listaCuponesArr,
                    cantidadTotalListaCupones,
                },
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    error?.message ||
                    error ||
                    'Hubo un error al obtener los datos',
            );
            dispatch({
                type: CUPONES_LISTA_LOADING,
            });
        }
    };

    const establecerCuponSeleccionado = (cuponSeleccionado) => {
        dispatch({
            type: CUPONES_ESTABLECER_SELECCIONADO,
            payload: { cuponSeleccionado },
        });
    };

    const nuevoCupon = async (cupon) => {
        dispatch({
            type: CUPONES_LOADING_FORM,
        });

        const data = { ...cupon };

        try {
            await axios.post('create_discount', data);
            mixpanel.track('coupon_add_success', { ...data });
            message.success(`Se creo correctamente el cupon ${cupon?.code}`);
        } catch (error) {
            const parsedError =
                error?.response?.data?.message ||
                error?.message ||
                error ||
                'Hubo un problema al crear el cupón';
            mixpanel.track('coupon_add_error', {
                error: JSON.stringify(parsedError),
            });
            message.error(parsedError);
        }

        dispatch({
            type: RESTABLECER_ID_USUARIO_CUPON,
        });

        dispatch({
            type: CUPONES_LOADING_FORM,
        });
    };

    const editarCupon = async (cupon, paramsCupones) => {
        dispatch({
            type: CUPONES_LOADING_FORM,
        });

        const esEliminar = cupon?.deleted || undefined;

        let data = { ...cupon };

        if (!esEliminar) {
            const condicionesUsarEnCupon = cupon?.discount_code_details;
            const condicionesOriginalesCupon =
                state?.cuponSeleccionado?.cuponOriginal?.discount_code_details;
            const listaTiposPagosTarjetasOriginalArr =
                condicionesOriginalesCupon?.filter(
                    (item) => item?.payment_type_id,
                ) || [];

            // Construir array de condiciones de tipo de pago
            let listaTiposPagosActualArr = cupon?.payment_type_id;
            const listaTarjetasActualArr = cupon?.card_id;

            // Validar opcion de 'BANCARD' seleccionada en el formulario
            const idBancard = 3;
            const listaTipoDePagoIncluyeBancard =
                listaTiposPagosActualArr.includes(idBancard);

            if (listaTipoDePagoIncluyeBancard) {
                const listaTiposPagoSinBancard =
                    listaTiposPagosActualArr.filter((item) => item !== 3);
                let listaTiposPagoConBancard = listaTarjetasActualArr.map(
                    () => idBancard,
                );
                if (!listaTiposPagoConBancard.length)
                    listaTiposPagoConBancard = [3];
                listaTiposPagosActualArr = [
                    ...listaTiposPagoConBancard,
                    ...listaTiposPagoSinBancard,
                ];
            }

            // Generar array de objectos de tipo 'discount_code_details' para la comparacion
            listaTiposPagosActualArr = listaTiposPagosActualArr.map(
                (item, index) => {
                    let detalle = {
                        payment_type_id: item,
                    };
                    if (item === idBancard) {
                        detalle = {
                            ...detalle,
                            card_id: listaTarjetasActualArr[index],
                        };
                    }
                    return detalle;
                },
            );

            // Tipos de pago y tarjetas nuevos
            // 'condiciones' que existen en la lista actual y no existen en la lista original
            let tipoPagosTarjetasNuevos = [];
            listaTiposPagosActualArr.forEach((detalleActual) => {
                const tipoPagoActualId = detalleActual?.payment_type_id;
                const tarjetaActualId = detalleActual?.card_id;

                let existeEnListaOriginal = false;
                if (tipoPagoActualId && tarjetaActualId) {
                    existeEnListaOriginal =
                        listaTiposPagosTarjetasOriginalArr?.find(
                            (detalleOriginal) => {
                                let tieneTipoPagoTarjetaIguales =
                                    detalleOriginal?.payment_type_id ===
                                        tipoPagoActualId &&
                                    detalleOriginal?.card_id ===
                                        tarjetaActualId;
                                return tieneTipoPagoTarjetaIguales;
                            },
                        );
                } else if (tipoPagoActualId && !tarjetaActualId) {
                    existeEnListaOriginal =
                        listaTiposPagosTarjetasOriginalArr?.find(
                            (detalleOriginal) => {
                                let tieneTipoPagoIguales =
                                    detalleOriginal?.payment_type_id ===
                                    tipoPagoActualId;
                                return tieneTipoPagoIguales;
                            },
                        );
                }

                if (!existeEnListaOriginal)
                    tipoPagosTarjetasNuevos.push({ ...detalleActual });
            });

            // Tipos de pago y tarjetas a eliminar
            // 'condiciones' que no existen en la lista actual y si existen en la lista original
            let tipoPagosTarjetasEliminar = [];
            listaTiposPagosTarjetasOriginalArr.forEach((detalleOriginal) => {
                const tipoPagoOriginalId = detalleOriginal?.payment_type_id;
                const tarjetaOriginalId = detalleOriginal?.card_id;

                let existeEnListaActual = true;
                if (listaTiposPagosActualArr?.length) {
                    if (tipoPagoOriginalId && tarjetaOriginalId) {
                        existeEnListaActual = Boolean(
                            listaTiposPagosActualArr.find((detalleActual) => {
                                const tieneTipoPagoTarjetaIguales =
                                    detalleActual?.payment_type_id ===
                                        tipoPagoOriginalId &&
                                    detalleActual?.card_id ===
                                        tarjetaOriginalId;
                                return tieneTipoPagoTarjetaIguales;
                            }),
                        );
                    } else if (tipoPagoOriginalId && !tarjetaOriginalId) {
                        // Buscar coincidencias de otros tipos de pago
                        existeEnListaActual = Boolean(
                            listaTiposPagosActualArr.find((detalleActual) => {
                                const tieneTipoPagoIguales =
                                    detalleActual?.payment_type_id ===
                                    tipoPagoOriginalId;
                                return tieneTipoPagoIguales;
                            }),
                        );
                        // Buscar coincidencias de Bancard sin tarjetas asociadas
                        if (
                            listaTipoDePagoIncluyeBancard &&
                            tipoPagoOriginalId === idBancard &&
                            !listaTarjetasActualArr?.length
                        ) {
                            existeEnListaActual = true;
                        }
                    }
                }

                // Si no existe en la lista actual, eliminar
                if (!existeEnListaActual)
                    tipoPagosTarjetasEliminar.push({
                        ...detalleOriginal,
                        deleted: true,
                    });
            });

            // Remover de las condiciones originales tipos de pagos y tarjetas a eliminar
            const existenCondicionesParaEliminar = Boolean(
                tipoPagosTarjetasEliminar.length,
            );
            let condicionesOriginalesCuponFiltradas =
                listaTiposPagosTarjetasOriginalArr;
            if (existenCondicionesParaEliminar) {
                condicionesOriginalesCuponFiltradas =
                    listaTiposPagosTarjetasOriginalArr.filter(
                        (condicionOriginal) => {
                            const condicionARemover =
                                tipoPagosTarjetasEliminar.find(
                                    (condicionEliminar) =>
                                        condicionOriginal.id ===
                                        condicionEliminar.id,
                                );
                            return Boolean(condicionARemover);
                        },
                    );
            }

            const discountCodeDetails = [
                ...condicionesUsarEnCupon,
                ...condicionesOriginalesCuponFiltradas,
                ...tipoPagosTarjetasNuevos,
                ...tipoPagosTarjetasEliminar,
            ];

            data = {
                ...data,
                discount_code_details: discountCodeDetails,
                payment_type_id: undefined,
                card_id: undefined,
            };
        }

        try {
            await axios.put('update_discount', data);
            if (!esEliminar) {
                await asignarRemoverUsuariosCupon();
            }
            mixpanel.track(
                esEliminar ? 'coupon_delete_success' : 'coupon_edit_success',
                { ...data },
            );
            message.success(
                `Cupón ${esEliminar ? 'eliminado' : 'editado'} correctamente`,
            );
        } catch (error) {
            const parsedError =
                error?.response?.data?.message ||
                error?.message ||
                error ||
                `Hubo un problema al ${
                    esEliminar ? 'eliminar' : 'editar'
                } el cupón`;
            mixpanel.track(
                esEliminar ? 'coupon_delete_error' : 'coupon_edit_error',
                { error: JSON.stringify(parsedError) },
            );
            message.error(parsedError);
        }

        dispatch({
            type: RESTABLECER_ID_USUARIO_CUPON,
        });

        dispatch({
            type: CUPONES_LOADING_FORM,
        });

        if (esEliminar) {
            obtenerListaCupones(paramsCupones);
        }
    };

    const asignarRemoverUsuariosCupon = async () => {
        const idCupon = state?.cuponSeleccionado?.id;
        if (idCupon) {
            if (state?.obtenerIdUsuarioCupon?.length) {
                const data = {
                    user_id: state?.obtenerIdUsuarioCupon,
                    discount_code_id: state?.cuponSeleccionado?.id,
                    axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                };
                try {
                    await axios.post('add_discount_to_user', data);
                } catch (error) {
                    throw (
                        'Hubo un problema al asignar usuarios a este cupón: ' +
                            error?.response?.data?.message ||
                        error?.message ||
                        error
                    );
                }
            }
            if (state?.usuariosRemover?.length) {
                const data = {
                    discount_code_id: state?.cuponSeleccionado?.id,
                    user_ids: state?.usuariosRemover,
                    deleted: true,
                    enabled: false,
                };
                try {
                    await axios.post('update_discount_code_user', data);
                } catch (error) {
                    throw (
                        'Hubo un problema al remover usuarios de este cupón: ' +
                            error?.response?.data?.message ||
                        error?.message ||
                        error
                    );
                }
            }
        }
    };

    // 'Lista de usuarios' Form Item
    // TODO: Revisar flujo completo
    const obtenerUsuarios = async (tipo, value) => {
        // Obtener el valor a ser buscado
        let valorBusqueda = value;

        // Para tipo "ID" verificar que sea numero
        if (tipo === 'id' && isNaN(value)) {
            valorBusqueda = undefined;
        }
        // Se acepta la opcion de ("" vacio), por lo que se pregunta explicitamente por undefinded
        if (valorBusqueda !== undefined) {
            // Preparar parametros
            let data = {};
            data['user_id'] = tipo === 'id' ? valorBusqueda : undefined;
            data['full_name'] = tipo === 'nombre' ? valorBusqueda : undefined;
            data['email'] = tipo === 'correo' ? valorBusqueda : undefined;

            try {
                // obtener datos
                const response = await axios.get('/users_list', {
                    params: data,
                });
                // extraer lista de usuarios
                const users = response?.data?.data?.users;

                let userDataArray = [];
                users.forEach((user) => {
                    const userActualizado = {
                        idUser: user?.user_id,
                        nombreCompleto: user?.user_name,
                        correo: user?.email,
                    };
                    userDataArray.push(userActualizado);
                });

                dispatch(
                    { type: OBTENER_USUARIOS, payload: [...userDataArray] },
                    { type: BUSCA_USUARIO_CUPON, payload: [...userDataArray] },
                );
            } catch (error) {
                message.error(
                    'Hubo un problema al obtener los datos de los usuarios',
                );
            }
        }
    };

    // TODO: Revisar flujo completo
    const guardarUsuariosSelecionados = (listaUsuarios) => {
        dispatch({
            type: GUARDAR_LISTA_USUARIOS_SELECCIONADOS,
            payload: listaUsuarios,
        });
    };

    // TODO: Revisar flujo completo
    const seleccionarUsuarioId = (id) => {
        let arrIdUsuario = [];
        const data = id;

        // Si es que es un numero normal
        if (!isNaN(data)) {
            // Poner dentro de un array
            arrIdUsuario = [data];
        } else {
            // Guardar el array como tal
            arrIdUsuario = data;
        }

        // Actualizar el state
        dispatch({
            type: OBTENER_ID_USUARIO_CUPON,
            payload: arrIdUsuario,
        });
    };

    // TODO: Revisar flujo completo
    const restablecerUsuarios = () => {
        dispatch({
            type: RESTABLECER_ID_USUARIO_CUPON,
        });
    };

    // TODO: Revisar flujo completo
    const obtenerUsuariosEliminar = (usuarios) => {
        dispatch({
            type: USUARIOS_LISTA_ELIMINAR,
            payload: usuarios,
        });
    };

    // TODO: Revisar flujo completo
    const obtenerMotivos = async () => {
        const baseURL = urls.REACT_APP_BACKEND_URL_C;
        try {
            const resp = await clienteAxios.get('reasons_list', { baseURL });
            dispatch({
                type: OBTENER_MOTIVOS,
                payload: resp?.data?.data?.reasons_list,
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de motivos',
            );
        }
    };

    const obtenerTiposPago = async () => {
        const axiosBaseURL = urls.REACT_APP_BACKEND_URL_B;
        dispatch({
            type: CUPONES_TIPOS_PAGO_LOADING,
        });
        try {
            const respuesta = await clienteAxios.get('get_payment_types', {
                params: { axiosBaseURL },
            });
            const dataset = respuesta?.data?.data
                .filter((tipo) => tipo?.online)
                .map((tipo) => ({ ...tipo, discount_code_details_id: 0 }));
            dispatch({
                type: CUPONES_TIPOS_PAGO_LISTA,
                payload: {
                    listaTiposPagos: dataset,
                },
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de tipos de pago',
            );
            dispatch({
                type: CUPONES_TIPOS_PAGO_LOADING,
            });
        }
    };

    const obtenerTarjetas = async () => {
        const axiosBaseURL = urls.REACT_APP_BACKEND_URL_C;
        dispatch({
            type: CUPONES_TARJETAS_LOADING,
        });
        try {
            const respuesta = await clienteAxios.get('get_cards', {
                params: { axiosBaseURL },
            });
            let dataset = respuesta?.data?.data?.card_list;
            dataset = dataset.map((item) => ({ ...item, id: +item?.id }));
            dispatch({
                type: CUPONES_TARJETAS_LISTA,
                payload: {
                    listaTarjetas: dataset,
                },
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    error?.message ||
                    error ||
                    'Hubo un problema al obtener la lista de tipos de pago',
            );
            dispatch({
                type: CUPONES_TARJETAS_LOADING,
            });
        }
    };

    // 'Usar en' Form Item

    const obtenerCiudades = async () => {
        let dataset = [];
        try {
            const respuesta = await clienteAxios.get('/city_names');
            dataset = respuesta?.data?.data?.cities.map((item) => ({
                id: item.city_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las ciudades',
            );
        }
        return dataset;
    };

    const obtenerBarrios = async () => {
        let dataset = [];
        try {
            const respuesta = await axios.get('/neighborhoods');
            // Remover duplicados
            const barrios = [
                ...new Map(
                    respuesta?.data?.data?.neighborhoods.map((v) => [
                        v.name,
                        v,
                    ]),
                ).values(),
            ];
            dataset = barrios.map((item) => ({
                id: item.city_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de los barrios',
            );
        }
        return dataset;
    };

    const obtenerFamilias = async () => {
        let dataset = [];
        try {
            const respuesta = await clienteAxios.get('/families');
            dataset = respuesta?.data?.data?.families;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las familias',
            );
        }
        return dataset;
    };

    const obtenerFranquicias = async () => {
        let dataset = [];
        // TODO: Obtener del state de Utilidades (listaTiposPago) y filtrar por ONLINE luego de incorporar los cambios de 'Finanzas'
        const params = {
            payment_type: '3,5,7',
        };
        try {
            const respuesta = await axios.get('/franchises_list', { params });
            dataset = respuesta?.data?.data?.franchises;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las franquicias',
            );
        }
        return dataset;
    };

    const obtenerSucursales = async (franchiseId) => {
        let dataset = [];
        try {
            const respuesta = await axios.get('/branches_list', {
                params: { franchise_id: franchiseId },
            });
            dataset = respuesta.data.data.branches.map((item) => ({
                id: item.branch_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las sucursales',
            );
        }
        return dataset;
    };

    const obtenerProductos = async (franchiseId) => {
        let params = {};
        params['axiosBaseURL'] = urls.REACT_APP_BACKEND_URL_C;
        params['franchise_id'] = franchiseId ? Number(franchiseId) : undefined;
        let arrSeccion = [];
        try {
            const respuesta = await axios.get('get_products', {
                params: params,
            });
            const arrProductos = respuesta.data.data.products;
            arrSeccion = arrProductos.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de los productos',
            );
        }

        return arrSeccion;
    };

    const setCustomizedNotification = (customized) => {
        dispatch({
            type: CUPONS_CUSTOMIZED_NOTIFICATIONS,
            payload: {
                customizedNotification: customized,
            },
        });
    };

    const verifySMSRules = (sms) => {
        if (!sms?.length) return true;

        const regex = /^[a-zA-Z0-9¡!$%^&*()_+|~=`{}[\]:";'<>?,./_\- ]{0,161}$/;
        const smsMatched = regex.test(sms);
        return smsMatched;
    };

    return (
        <CuponesContext.Provider
            value={{
                listaCupones: state.listaCupones,
                loadingListaCupones: state.loadingListaCupones,
                cantidadTotalListaCupones: state.cantidadTotalListaCupones,
                cuponSeleccionado: state.cuponSeleccionado,
                loadingFormCupones: state.loadingFormCupones,

                obtenerMotivosSelect: state.obtenerMotivosSelect,

                nuevoCupon,
                editarCupon,
                obtenerListaCupones,
                establecerCuponSeleccionado,

                obtenerMotivos,

                // 'Asignar usuarios' Form item
                usuarios: state.usuarios,
                obtenerIdUsuarioCupon: state.obtenerIdUsuarioCupon,
                obtenerIdCupon: state.obtenerIdCupon,
                usuariosSeleccionados: state.usuariosSeleccionados,
                usuariosRemover: state.usuariosRemover,

                obtenerUsuarios,
                seleccionarUsuarioId,
                guardarUsuariosSelecionados,
                obtenerUsuariosEliminar,
                restablecerUsuarios,

                obtenerTiposPago,
                loadingTiposPago: state.loadingTiposPago,
                listaTiposPagos: state.listaTiposPagos,

                listaTarjetas: state.listaTarjetas,
                tarjetasLoading: state.tarjetasLoading,
                obtenerTarjetas,

                // 'Usar en' Form Item
                franquicias: state.franquicias,
                ciudades: state.ciudades,
                familias: state.familias,

                obtenerCiudades,
                obtenerFamilias,
                obtenerBarrios,
                obtenerFranquicias,
                obtenerSucursales,
                obtenerProductos,

                setCustomizedNotification,
                customizedNotification: state.customizedNotification,

                verifySMSRules,
            }}>
            {children}
        </CuponesContext.Provider>
    );
};

export default CuponesState;

CuponesState.propTypes = {
    children: PropTypes.node,
};
