import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Permisos } from '../../../../constants/Permisos';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import PropTypes from 'prop-types';
import CuponesState from '../../../../context/programaRecompensa/cupones/CuponesState';
import ListaCupones from './ListaCupones';
import NuevoCupon from './NuevoCupon';
import EditarCupon from './EditarCupon';

const Cupones = ({ match }) => {
    return (
        <CuponesState>
            <Switch>
                <Route
                    exact
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.programaRecompensa.cupones.listaCupones
                            }
                            componente={ListaCupones}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.url}/nuevo`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.programaRecompensa.cupones.listaCupones
                            }
                            componente={NuevoCupon}
                        />
                    )}
                />
                <Route
                    exact
                    path={`${match.url}/editar`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.programaRecompensa.cupones.listaCupones
                            }
                            componente={EditarCupon}
                        />
                    )}
                />
            </Switch>
        </CuponesState>
    );
};

export default Cupones;

Cupones.propTypes = {
    match: PropTypes.object,
};
