import React from 'react';
import { Route, Switch } from 'react-router-dom';
import DocumentosComercioLista from './DocumentosComercioLista';
import PropTypes from 'prop-types';
import { Permisos } from '../../../../../constants/Permisos';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import DocumentosComercioState from '../../../../../context/facturacion/documentosComercio/DocumentosComercioState';

const ListaDocumentosComercio = ({ match }) => {
    return (
        <DocumentosComercioState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.finanzas.facturacion.misDocumentos
                            }
                            componente={DocumentosComercioLista}
                        />
                    )}
                />
            </Switch>
        </DocumentosComercioState>
    );
};

export default ListaDocumentosComercio;

ListaDocumentosComercio.propTypes = {
    match: PropTypes.object,
};
