import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import useFranchiseAndBranchStatus from '../../../hooks/useFranchiseAndBranchStatus';
import { PlusOutlined } from '@ant-design/icons';
import ABMLayout from '../../../../layouts/ABMLayout';
import { Divider, Space } from '../../../../../node_modules/antd/lib/index';
import Menos from './SVG/Menos';
import MatchearModal from './MatchearModal';

const MatchearProducto = () => {
    const brandsHook = useFranchiseAndBranchStatus();
    const history = useHistory();
    const [modalMatchear, setmodalMatchear] = useState(false);
    const [form] = Form.useForm();

    const handleCancelMatchear = () => {
        setmodalMatchear(false);
    };

    const volver = () => {
        history.push('/administrar/integraciones/productos');
    };

    return (
        <ABMLayout
            titulo="Matchear productos"
            acciones={
                <Space>
                    <Button
                        type="link"
                        style={{ color: '#3F81F7' }}
                        onClick={() => volver()}>
                        Volver al listado
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setmodalMatchear(true)}>
                        Matchear
                    </Button>
                </Space>
            }
            loading={brandsHook.loadingBrands}>
            <Card>
                <strong>
                    <h3>Franquicia:</h3>
                </strong>
                <Divider></Divider>
                <div className="mt36">
                    <Form form={form} layout="vertical">
                        <Row gutter={12} align="middle">
                            <Col
                                span={8}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <span style={{ marginRight: '5px' }}>
                                    <strong>Producto franquicia: </strong>
                                </span>
                                <label>Mcpizza</label>
                            </Col>
                            <Col
                                span={8}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <span style={{ marginRight: '5px' }}>
                                    <strong>Tipo de producto: </strong>
                                </span>
                                <label>Individual</label>
                            </Col>
                        </Row>
                    </Form>
                </div>
                <Card style={{ marginTop: '20px' }}>
                    <Form form={form} layout="vertical">
                        <Row gutter={12} align="middle">
                            <Col span={7}>
                                <Form.Item
                                    label="Producto Monchis"
                                    name="branch">
                                    <Select
                                        allowClear
                                        optionFilterProp="children"
                                        placeholder="McPizza muzzarella"></Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label="Grupo de atributos"
                                    name="franchise">
                                    <Select
                                        allowClear
                                        optionFilterProp="children"
                                        placeholder="Seleccioná un grupo"></Select>
                                </Form.Item>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label="Atributos"
                                    name="refineSearch">
                                    <Select
                                        allowClear
                                        optionFilterProp="children"
                                        placeholder="Seleccioná un atributo"></Select>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Menos />
                            </Col>
                        </Row>
                    </Form>
                    <Form.Item>
                        <Button block type="dashed" icon={<PlusOutlined />}>
                            Agregar producto
                        </Button>
                    </Form.Item>
                </Card>
            </Card>
            <MatchearModal
                modalMatchear={modalMatchear}
                handleCancelMatchear={handleCancelMatchear}
            />
        </ABMLayout>
    );
};

export default MatchearProducto;
