import {
    TagBancard,
    TagTiendaNaranja,
    TagTigoMoney,
    TagQR,
    TagPos,
    TagEfectivo,
    TagBepsa,
} from 'components/atoms/tags/tags';

export const PAYMENT_TAGS = {
    BANCARD: TagBancard,
    UPAY: TagBancard,
    ITAU_PAGO: TagTiendaNaranja,
    TIGO_MONEY: TagTigoMoney,
    POS: TagPos,
    CASH: TagEfectivo,
    BEPSA: TagBepsa,
    QR: TagQR,
};
