import React, { useState, useContext, useEffect } from 'react';
import clases from './pagoOnline.module.css';
import { Col, Input, Row, Select, Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import PagoOnlineContext from '../../../context/pagoOnline/PagoOnlineContext';

const TablaUsuarios = () => {
    // const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [nombre, setNombre] = useState('');
    // const [valorId, setValorId] = useState('');
    const [tipoBusqueda, setTipoBusqueda] = useState('id');

    // pago online context
    const {
        totalUsuarios,
        seleccionarIdTabla,
        obtenerUsuarios,
        seleccionarPageTabla,
        usuarios,
        paginaActual,
        loadingTablaUsuarios,
    } = useContext(PagoOnlineContext);

    const columns = [
        {
            title: '#',
            dataIndex: 'idUser',
            key: 'idUser',
        },
        {
            title: 'Nombre',
            dataIndex: 'nombreCompleto',
            key: 'nombre',
        },
        {
            title: 'Correo',
            dataIndex: 'correo',
            key: 'correo',
        },
    ];

    const rowSelection = {
        onChange: (key) => {
            // setSelectedRows(rows);
            setSelectedRowKeys(key);
            seleccionarIdTabla(key);
        },
    };

    const changePage = (page) => {
        seleccionarPageTabla(page);
    };

    const handleChange = async (valor) => {
        setNombre(valor);
    };

    const tipoBusquedaHandler = (tipo) => {
        setTipoBusqueda(tipo);
    };

    useEffect(() => {
        seleccionarPageTabla(1);
    }, [nombre]);

    useEffect(() => {
        // llama a obtener usuarios despues de 1 segundo
        const timeout = setTimeout(() => {
            if (nombre) {
                obtenerUsuarios(paginaActual, nombre, tipoBusqueda);
            } else {
                obtenerUsuarios(paginaActual, nombre);
            }
            setSelectedRowKeys([]);
        }, 500);
        return () => {
            clearTimeout(timeout);
        };
    }, [paginaActual, nombre, tipoBusqueda]);

    return (
        <>
            <Row gutter={24} className={clases.cajaBuscar}>
                <Col>
                    <Input
                        placeholder="Buscar"
                        prefix={<SearchOutlined />}
                        onChange={(evt) =>
                            handleChange(evt.currentTarget.value)
                        }
                    />
                </Col>
                <Col>
                    <Select
                        defaultValue="id"
                        style={{ width: '7rem' }}
                        onChange={tipoBusquedaHandler}>
                        <Select.Option value="id">ID</Select.Option>
                        <Select.Option value="nombre">
                            Nombre y apellido
                        </Select.Option>
                        <Select.Option value="correo">Correo</Select.Option>
                    </Select>
                </Col>
            </Row>

            <Row>
                <Table
                    columns={columns}
                    pagination={{
                        current: paginaActual,
                        pageSize: 15,
                        total: totalUsuarios,
                        showSizeChanger: false,
                        onChange: (page) => changePage(page),
                    }}
                    dataSource={usuarios}
                    rowKey="idUser"
                    loading={loadingTablaUsuarios}
                    rowSelection={{
                        selectedRowKeys: selectedRowKeys,
                        type: 'radio',
                        ...rowSelection,
                    }}
                />
            </Row>
        </>
    );
};

export default TablaUsuarios;
