import React, { useState, useEffect, useContext } from 'react';
import {
    Input,
    Table,
    Form,
    Button,
    Select,
    message,
    Card,
    Row,
    Col,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import clienteAxios from '../../../../config/axios';
import { dateDDMMYYYY } from '../../../utils-components/Date';
import UsuariosContext from '../../../../context/usuario/UsuariosContext';
import ABMLayout from '../../../../layouts/ABMLayout';

const ListaUsuarios = () => {
    const [filtrosBusquedaForm] = Form.useForm();
    let history = useHistory();
    const { guardarUsuarioSeleccionado, setPathname } =
        useContext(UsuariosContext);
    const [listaUsuarios, setListaUsuarios] = useState();
    const [loading, setLoading] = useState(false);
    const [paramsListaUsuarios, setParamsListaUsuarios] = useState({
        page: 1,
        items_per_page: 15,
    });
    const [cantidadRegistros, setCantidadRegistros] = useState();
    const PATH_NAME = window.location.pathname;

    useEffect(() => {
        setPathname(PATH_NAME);
    }, [PATH_NAME]);

    useEffect(() => {
        obtenerUsuariosFiltrados();
    }, []);

    // se obtienen los datos de la sessionStorage y se mantienen los datos en el formulario de filtros
    const obtenerUsuariosFiltrados = () => {
        if (sessionStorage.getItem('usuariosFiltrados')) {
            const storage = JSON.parse(
                sessionStorage.getItem('usuariosFiltrados'),
            );

            const formData = {
                filtro: storage.filtro,
                busqueda: storage.busqueda,
            };

            filtrosBusquedaForm.setFieldsValue(formData);

            const data = parseSearchParams(formData);
            setParamsListaUsuarios((state) => {
                const newState = { ...state, ...data };
                getUsers(newState);
                return newState;
            });
        }
    };

    const verDetalles = (row) => {
        guardarUsuarioSeleccionado(row);
        history.push(`/usuarios/detalles`);
    };

    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
        },
        {
            title: 'Correo',
            dataIndex: 'correo',
            key: 'correo',
        },
        {
            title: 'Teléfono',
            dataIndex: 'telefono',
            key: 'telefono',
            align: 'left',
        },
        {
            title: 'Fecha de registro',
            dataIndex: 'fecharegistro',
            key: 'fecharegistro',
            align: 'right',
        },
        {
            title: '',
            dataIndex: 'acciones',
            key: 'acciones',
            render: (_, elm) => (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        shape="circle"
                        icon={<SearchOutlined />}
                        onClick={() => verDetalles(elm)}
                    />
                </div>
            ),
        },
    ];

    const parseSearchParams = (formvalues) => {
        let data = {};
        data['user_id'] =
            formvalues.busqueda && formvalues.filtro === 'user_id'
                ? formvalues.busqueda
                : undefined;
        data['full_name'] =
            formvalues.busqueda && formvalues.filtro === 'full_name'
                ? formvalues.busqueda
                : undefined;
        data['email'] =
            formvalues.busqueda && formvalues.filtro === 'email'
                ? formvalues.busqueda
                : undefined;
        data['phone'] =
            formvalues.busqueda && formvalues.filtro === 'phone'
                ? formvalues.busqueda
                : undefined;
        return data;
    };

    const dataFiltrosBusqueda = () => {
        filtrosBusquedaForm
            .validateFields()
            .then(async () => {
                try {
                    let formData = filtrosBusquedaForm.getFieldValue();
                    const data = parseSearchParams(formData);
                    setParamsListaUsuarios((state) => {
                        const newState = { ...state, ...data };
                        getUsers(newState);
                        return newState;
                    });
                    // se guarda los filtros de búsqueda en el sessionStorage
                    sessionStorage.setItem(
                        'usuariosFiltrados',
                        JSON.stringify(formData),
                    );
                } catch (err) {
                    message.error(
                        err.response?.data.message ||
                            'Hubo un problema al obtener los datos',
                    );
                }
            })
            .catch(() => {});
    };

    const getUsers = async (datosBusqueda) => {
        setLoading(true);
        try {
            const llamada = await clienteAxios.get('/users_list', {
                params: datosBusqueda,
            });
            const users = llamada.data.data.users;
            const listaUsuariosArray = new Array();
            //para la tabla
            const tieneTelefono = (i) =>
                users[i].phone ? users[i].phone : '-';
            for (let i = 0; i < users.length; i++) {
                const newListaUsuarios = {
                    key: users[i].user_id,
                    id: users[i].user_id,
                    nombre: users[i].user_name,
                    correo: users[i].email,
                    telefono: tieneTelefono(i),
                    fecharegistro: dateDDMMYYYY(users[i].registration_date),
                };
                listaUsuariosArray.push(newListaUsuarios);
            }
            setListaUsuarios(listaUsuariosArray);
            setCantidadRegistros(llamada?.data?.data?.total_users || 1);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener los datos',
            );
        }

        setLoading(false);
    };

    const initialValues = {
        filtro: 'user_id',
    };

    const paginacionChangeHandler = (page, pageSize) => {
        setParamsListaUsuarios((state) => {
            const newState = { ...state, page: page, items_per_page: pageSize };
            getUsers(newState);
            return newState;
        });
    };

    return (
        <ABMLayout titulo={'Lista de Usuarios'}>
            <Card>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form
                            layout="vertical"
                            form={filtrosBusquedaForm}
                            initialValues={initialValues}>
                            <Row gutter={12}>
                                <Col span={4}>
                                    <Form.Item
                                        label="Filtrar por:"
                                        name="filtro"
                                        rules={[{ required: true }]}>
                                        <Select style={{ width: '100%' }}>
                                            <Select.Option value="user_id">
                                                ID
                                            </Select.Option>
                                            <Select.Option value="full_name">
                                                Nombre y apellido
                                            </Select.Option>
                                            <Select.Option value="email">
                                                Correo
                                            </Select.Option>
                                            <Select.Option value="phone">
                                                Teléfono
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item label="Usuario" name="busqueda">
                                        <Input
                                            onPressEnter={dataFiltrosBusqueda}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={4}>
                                    <Form.Item style={{ marginTop: 26 }}>
                                        <Button
                                            type="primary"
                                            onClick={dataFiltrosBusqueda}>
                                            Buscar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            loading={loading}
                            // className={classes.tablaCupones}
                            columns={columns}
                            dataSource={listaUsuarios}
                            pagination={{
                                pageSize: paramsListaUsuarios?.items_per_page,
                                total: cantidadRegistros,
                                current: paramsListaUsuarios?.page
                                    ? paramsListaUsuarios.page
                                    : 1,
                                showSizeChanger: false,
                                onChange: (page, pageSize) =>
                                    paginacionChangeHandler(page, pageSize),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ListaUsuarios;
