import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { Menu, Layout } from 'antd';
import { BuildOutlined } from '@ant-design/icons';
import SubMenu from 'antd/lib/menu/SubMenu';

import classes from './Sidebar1.module.css';
import LogoMonchis from '../../../assets/images/monchisLogo.png';

import AuthContext from '../../../context/autenticacion/authContext';
import PrefContext from '../../../context/preferencias/prefContext';

import Icon from '../../utils-components/Icon';
import navegacionConfig from '../../../config/NavegacionConfig';

const { Sider } = Layout;

const SideBar = () => {
    const authContext = useContext(AuthContext);
    const { redirectPath, permisos } = authContext;

    // Conexion con el context de Preferencias del usuario
    const prefContext = useContext(PrefContext);
    const { isSidebarAbierto } = prefContext;

    const history = useHistory();
    const location = useLocation();

    const [selectedKeys, setSelectedKeys] = useState();
    const [openKeys, setOpenKeys] = useState();
    const [menuNavegacion, setMenuNavegacion] = useState();

    useEffect(() => {
        if (redirectPath && permisos) {
            let menuFiltrado = [];
            navegacionConfig.forEach((menuNivel1) => {
                if (evaluarPermisos(menuNivel1)) {
                    let menuNivel1Temp = { ...menuNivel1, submenu: [] };
                    // Recorrer submenu del nivel 1
                    if (menuNivel1.submenu.length > 0) {
                        menuNivel1.submenu.forEach((menuNivel2) => {
                            if (evaluarPermisos(menuNivel2)) {
                                let menuNivel2Temp = {
                                    ...menuNivel2,
                                    submenu: [],
                                };
                                // Recorrer submenu del nivel 2
                                if (menuNivel2.submenu.length > 0) {
                                    menuNivel2.submenu.forEach((menuNivel3) => {
                                        if (evaluarPermisos(menuNivel3)) {
                                            // Nivel 3 sin submenus
                                            menuNivel2Temp.submenu.push({
                                                ...menuNivel3,
                                            });
                                        }
                                    });
                                }
                                //
                                menuNivel1Temp.submenu.push(menuNivel2Temp);
                            }
                        });
                    }
                    //
                    menuFiltrado.push(menuNivel1Temp);
                }
            });
            setMenuNavegacion(menuFiltrado);
            obtenerSelectedOpenKeys(redirectPath);
        }
    }, []);

    const evaluarPermisos = (menuNivel1) => {
        // Evaluar menu, con posibles sub-menus. Desde el punto de vista de esta
        // funcion, el menu evaluado es de nivel 1 y podria tener sub-menus. En caso
        // que se detecte al menos un nivel de menu con permiso se retorna 'true'

        // Obtener si tiene permiso el menu de nivel 1
        let tienePermiso = Object.keys(permisos).includes(
            menuNivel1?.permission,
        );
        // Evaluar posible segundo nivel de submenus
        !tienePermiso &&
            menuNivel1?.submenu.length > 0 &&
            menuNivel1.submenu.forEach((menuitem) => {
                // Obtener si tiene permiso el menu de nivel 2
                let tienePermisoMenuNivel2 = Object.keys(permisos).includes(
                    menuitem?.permission,
                );

                // Evaluar posible tercer nivel de submenu
                !tienePermiso && tienePermisoMenuNivel2
                    ? (tienePermiso = tienePermisoMenuNivel2)
                    : menuitem?.submenu?.length > 0 &&
                      menuitem?.submenu.forEach((submenuitem) => {
                          // Obtener si tiene permiso el menu de nivel 3
                          let tienePermisoTest2 = Object.keys(
                              permisos,
                          ).includes(submenuitem?.permission);
                          tienePermisoTest2 &&
                              !tienePermiso &&
                              (tienePermiso = tienePermisoTest2);
                      });
            });
        return tienePermiso;
    };

    useEffect(() => {
        if (location?.pathname) {
            obtenerSelectedOpenKeys(location.pathname);
        }
    }, [location]);

    const obtenerSelectedOpenKeys = (locationAddress) => {
        if (locationAddress === '/') {
            setSelectedKeys('/panel');
            setOpenKeys('/panel');
        } else {
            let primerNivel = locationAddress.indexOf('/', 1);
            let primerKey = '';
            let segundoNivel = null;
            let segundaKey = '';
            let terceraKey = '';

            if (primerNivel === -1) {
                primerKey = locationAddress.substring(
                    1,
                    locationAddress.length,
                );
                setSelectedKeys('/' + primerKey);
                setOpenKeys('/' + primerKey);
            } else {
                primerKey = locationAddress.substring(1, primerNivel);

                segundoNivel = locationAddress.indexOf('/', primerNivel + 2);
                if (segundoNivel === -1) {
                    segundaKey = locationAddress.substring(
                        primerKey.length + 2,
                        locationAddress.length,
                    );
                    setSelectedKeys('/' + primerKey + '/' + segundaKey);
                    setOpenKeys('/' + primerKey + '/' + segundaKey);
                } else {
                    segundaKey = locationAddress.substring(
                        primerKey.length + 2,
                        segundoNivel,
                    );

                    terceraKey = locationAddress.substring(
                        segundoNivel + 1,
                        locationAddress.length,
                    );
                    setSelectedKeys(
                        '/' + primerKey + '/' + segundaKey + '/' + terceraKey,
                    );
                    setOpenKeys('/' + primerKey + '/' + segundaKey);
                }
            }
        }
    };

    const monchisLogoTexto = isSidebarAbierto ? (
        <p>
            Monchis{' '}
            {process.env.REACT_APP_NODE_ENV === 'dev' && (
                <small style={{ color: 'var(--rojo-monchis)' }}>- Dev</small>
            )}
        </p>
    ) : undefined;

    return (
        <>
            <Sider
                className={classes.sideBar}
                width="220px"
                trigger={null}
                collapsible
                collapsed={!isSidebarAbierto}>
                <Link to="/" className={classes.LogoBox}>
                    <img className={classes.Logo} src={LogoMonchis} />
                    {monchisLogoTexto}
                </Link>
                {selectedKeys && openKeys && menuNavegacion ? (
                    <Menu
                        mode="inline"
                        style={{ height: '100vh' }}
                        theme="light"
                        selectedKeys={[selectedKeys]}>
                        {menuNavegacion.map((menuNivel1) =>
                            menuNivel1.submenu.length > 0 ? (
                                <Menu.ItemGroup
                                    key={menuNivel1.path}
                                    title={menuNivel1.title}>
                                    {menuNivel1.submenu.map((menuNivel2) =>
                                        menuNivel2.submenu.length > 0 ? (
                                            <SubMenu
                                                icon={
                                                    menuNivel2.icon ? (
                                                        <Icon
                                                            type={
                                                                menuNivel2?.icon
                                                            }
                                                        />
                                                    ) : null
                                                }
                                                key={menuNivel2.path}
                                                title={menuNivel2.title}>
                                                {menuNivel2.submenu.map(
                                                    (menuNivel3) => (
                                                        <Menu.Item
                                                            key={
                                                                menuNivel3?.path
                                                            }
                                                            onClick={() => {
                                                                history.push(
                                                                    menuNivel3?.path,
                                                                );
                                                            }}>
                                                            {menuNivel3?.icon ? (
                                                                <Icon
                                                                    type={
                                                                        menuNivel3?.icon
                                                                    }
                                                                />
                                                            ) : null}
                                                            <span>
                                                                {
                                                                    menuNivel3?.title
                                                                }
                                                            </span>
                                                        </Menu.Item>
                                                    ),
                                                )}
                                            </SubMenu>
                                        ) : (
                                            <Menu.Item
                                                key={menuNivel2.path}
                                                onClick={() => {
                                                    history.push(
                                                        menuNivel2.path,
                                                    );
                                                }}>
                                                {menuNivel2.icon ? (
                                                    <Icon
                                                        type={menuNivel2.icon}
                                                    />
                                                ) : null}
                                                <span>{menuNivel2.title}</span>
                                            </Menu.Item>
                                        ),
                                    )}
                                </Menu.ItemGroup>
                            ) : (
                                <Menu.Item
                                    key={menuNivel1.path}
                                    onClick={() => {
                                        history.push(menuNivel1.path);
                                    }}>
                                    {menuNivel1.icon ? (
                                        <Icon type={menuNivel1?.icon} />
                                    ) : null}
                                    <span>{menuNivel1?.title}</span>
                                </Menu.Item>
                            ),
                        )}
                        <Menu.ItemGroup title="Versiones anteriores">
                            <Menu.Item
                                key="/cambiar/monchis-admin-v1"
                                onClick={() => {
                                    window.open(
                                        'https://admin.monchis.com.py/',
                                        '_blank',
                                    );
                                }}>
                                <Icon type={BuildOutlined} />
                                <span>Monchis Admin V1</span>
                            </Menu.Item>
                        </Menu.ItemGroup>
                    </Menu>
                ) : null}
            </Sider>
        </>
    );
};

export default SideBar;
