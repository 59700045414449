import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';

import clienteAxios from '../../../../config/axios';

import ReporteComisionesReducer, {
    initialState,
} from './ReporteComisionesReducer';
import ReporteComisionesContext from './ReporteComisionesContext';

import {
    HISTORIAL_TOTALES,
    OBTENER_FRANQUICIA_SELECT,
    OBTENER_LISTADO_HISTORIAL_COMISION,
    SELECCIONAR_PAGINA_HISTORIAL,
} from '../../../../types';

const ReporteComisionesState = ({ children }) => {
    const [loadingTablaHistorial, setLoadingTablaHistorial] = useState(false);
    const [loadingSelectFranquicia, setLoadingSelectFranquicia] =
        useState(false);
    const [state, dispatch] = useReducer(
        ReporteComisionesReducer,
        initialState,
    );

    const obtenerHistorialComisiones = async (datos) => {
        setLoadingTablaHistorial(true);

        try {
            const resp = await clienteAxios.get('/commissions', {
                params: datos,
            });
            dispatch({
                type: OBTENER_LISTADO_HISTORIAL_COMISION,
                payload: resp?.data?.data?.commissions,
            });
            dispatch({
                type: HISTORIAL_TOTALES,
                payload: resp?.data?.data?.total_rows,
            });
        } catch (error) {
            message.error('Hubo un error, vuelva a intentarlo');
            console.error(error);
        }
        setLoadingTablaHistorial(false);
    };

    const obtenerFranquicias = async () => {
        setLoadingSelectFranquicia(true);
        try {
            const resp = await clienteAxios.get('/franchises');
            dispatch({
                type: OBTENER_FRANQUICIA_SELECT,
                payload: resp?.data?.data?.franchises,
            });
        } catch (error) {
            message.error('Hubo un error al obtener franquicia');
            console.error(error);
        }
        setLoadingSelectFranquicia(false);
    };

    const seleccionarPaginadoTabla = async (page) => {
        dispatch({
            type: SELECCIONAR_PAGINA_HISTORIAL,
            payload: page,
        });
    };

    return (
        <ReporteComisionesContext.Provider
            value={{
                listaHistorialComisiones: state.listaHistorialComisiones,
                franquicias: state.franquicias,
                totalHistorial: state.totalHistorial,
                paginaActualHistorial: state.paginaActualHistorial,

                obtenerHistorialComisiones,
                obtenerFranquicias,
                seleccionarPaginadoTabla,
                loadingTablaHistorial,
                loadingSelectFranquicia,
            }}>
            {children}
        </ReporteComisionesContext.Provider>
    );
};

export default ReporteComisionesState;

ReporteComisionesState.propTypes = {
    children: PropTypes.object,
};
