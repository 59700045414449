import React, { useContext, useEffect } from 'react';
import {
    Button,
    Tooltip,
    Modal,
    Row,
    Col,
    Card,
    List,
    Typography,
    Empty,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import ABMLayout from '../../../../layouts/ABMLayout';
import UserLevelItem from '../../../molecules/ProgramaRecomensa/NivelUsuario/UserLevelItem';
import classes from './ListaNiveles.module.css';
import UserLevelForm from './UserLevelForm';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../utils-components/Number';
import NivelUsuarioContext from '../../../../context/programaRecompensa/nivelUsuario/NivelUsuarioContext.js';
import LISTA_TEMAS from '../../../../constants/temasMonchisApp';

const { Title } = Typography;

const ListaNiveles = () => {
    const {
        getUserLevels,
        userLevelList,
        loading,
        showUserLevelDetail,
        isShowUserLevelModalVisible,
        hideUserLevelDetail,
        selectedUserLevel,
        rewardsList,
        isRewardsListLoading,
        getRewards,
        newUserLevel,
        setSelectedUserLevel,
    } = useContext(NivelUsuarioContext);

    const history = useHistory();

    const userLevelListHasData = Boolean(userLevelList?.length);

    useEffect(() => {
        getUserLevels();
        getRewards();
    }, []);

    const showDetalleButtonHandler = (selectedUserLevel) => {
        showUserLevelDetail(selectedUserLevel);
    };

    const cancelButtonUserLevelDetailHandler = () => {
        hideUserLevelDetail();
    };

    const editButtonHandler = (selectedUserLevel) => {
        setSelectedUserLevel(selectedUserLevel);
        history.push('/programa-recompensa/nivel-usuario/editar');
    };

    const saveButtonHandler = (values, onFormReset) => {
        const userLevel = {
            name: values?.name,
            amount: values?.amount,
            points: values?.points,
            price: values?.price,
            from: values?.from,
            to: values?.to,
            colours: values?.colours,
            rewards: values?.rewards?.map((item) => +item.reward) || [],
            quantity:
                values?.rewards?.map((item) => +Number(item?.quantity)) || [],
        };
        newUserLevel(userLevel, onFormReset);
    };

    return (
        <>
            <ABMLayout titulo={'Niveles de usuario'}>
                {/* User level list */}
                <Card className={classes.userLevelCardContainer}>
                    <Title level={3}>Niveles disponibles</Title>
                    {userLevelListHasData ? (
                        <div
                            className={
                                classes.scrollableUserLevelListContainer
                            }>
                            <List
                                dataSource={userLevelList}
                                renderItem={(userLevel) => {
                                    return (
                                        <List.Item>
                                            <UserLevelItem
                                                name={userLevel?.name}
                                                points={+userLevel?.points}
                                                amount={+userLevel?.amount}
                                                rewardsList={userLevel?.rewards}
                                                color={userLevel?.colours}
                                                onEdit={() =>
                                                    editButtonHandler(userLevel)
                                                }
                                                onViewDetails={() =>
                                                    showDetalleButtonHandler(
                                                        userLevel,
                                                    )
                                                }
                                            />
                                        </List.Item>
                                    );
                                }}
                            />
                        </div>
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={false}
                        />
                    )}
                </Card>

                {/* Create new user level form */}
                <Card className={classes.userLevelCardContainer}>
                    <Title level={3}>Crear nivel</Title>
                    <UserLevelForm
                        onSave={saveButtonHandler}
                        loading={loading}
                        rewardsList={rewardsList}
                        isRewardsListLoading={isRewardsListLoading}
                    />
                </Card>
            </ABMLayout>

            {/* View user level details modal */}
            <Modal
                open={isShowUserLevelModalVisible}
                onCancel={cancelButtonUserLevelDetailHandler}
                footer={null}>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <div className={classes.userLevelViewDetailTitle}>
                            <div
                                style={{
                                    background: selectedUserLevel?.colours,
                                }}
                            />
                            <h4>{selectedUserLevel?.name}</h4>
                            <Tooltip title="Editar nivel">
                                <Button
                                    type="link"
                                    icon={<EditOutlined />}
                                    onClick={() =>
                                        editButtonHandler(selectedUserLevel)
                                    }
                                />
                            </Tooltip>
                        </div>
                    </Col>
                    <Col span={24}>
                        <span>
                            {formatoGuaranies(Number(selectedUserLevel?.from))}{' '}
                            - {formatoGuaranies(Number(selectedUserLevel?.to))}{' '}
                            |{' '}
                            {formatoNumeros(Number(selectedUserLevel?.points))}{' '}
                            punto
                            {Number(selectedUserLevel?.points) > 1
                                ? 's'
                                : ''} ={' '}
                            {formatoGuaranies(
                                Number(selectedUserLevel?.amount),
                            )}
                        </span>
                    </Col>
                    <Col span={24}>
                        <span className={classes.nivelSubTitulo}>Tema:</span>
                        <span>
                            {
                                LISTA_TEMAS.find(
                                    (theme) =>
                                        theme.color ===
                                        selectedUserLevel?.colours,
                                )?.nombre
                            }
                        </span>
                    </Col>
                    <Col span={24}>
                        <div>
                            <span className={classes.nivelSubTitulo}>
                                Beneficios:
                            </span>
                        </div>
                        <div className={classes.rewardsList}>
                            <ul>
                                {selectedUserLevel?.rewards?.length ? (
                                    selectedUserLevel?.rewards.map(
                                        (item, index) => (
                                            <li
                                                key={
                                                    index
                                                }>{`${item?.name} (${item?.quantity})`}</li>
                                        ),
                                    )
                                ) : (
                                    <li>Nivel sin beneficios</li>
                                )}
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Modal>
        </>
    );
};

export default ListaNiveles;
