import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';

const RevertirCancelacionModal = ({
    idOrden,
    isRevertirCancelacionModalVisible,
    revertirCancelacionLoading,
    cerrarRevertirCancelacion,
    confirmarRevertirCancelacion,
}) => {
    return (
        <Modal
            open={isRevertirCancelacionModalVisible}
            footer={null}
            closable={null}>
            <ModalContent
                title={`¿Revertir la cancelación de la orden #${idOrden}?`}
                okText="CONFIRMAR"
                cancelText="CANCELAR"
                onOk={confirmarRevertirCancelacion}
                onCancel={cerrarRevertirCancelacion}
                isLoading={revertirCancelacionLoading}
                showWarnIcon
            />
        </Modal>
    );
};

RevertirCancelacionModal.propTypes = {
    idOrden: PropTypes.any,
    isRevertirCancelacionModalVisible: PropTypes.bool,
    revertirCancelacionLoading: PropTypes.bool,
    cerrarRevertirCancelacion: PropTypes.func,
    confirmarRevertirCancelacion: PropTypes.func,
};

export default RevertirCancelacionModal;
