import React, { useState, createRef } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import Emoji from '../Emoji';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const PushMensajeUsuario = ({
    pushForm,
    prefix,
    maxCantidadCaracteresTitulo,
    maxCantidadCaracteresMensaje,
}) => {
    //state para emoji
    const [mostrarEmojisTitulo, setMostrarEmojisTitulo] = useState(false);
    const [mostrarEmojisMensaje, setMostrarEmojisMensaje] = useState(false);

    const inputRefTitulo = createRef();
    const inputRefMensaje = createRef();

    const obtenerTextareaTitulo = document.getElementById(
        'basic_titulousuarios',
    );
    const obtenerTextareaMensaje = document.getElementById(
        'basic_mensajeusuarios',
    );

    // se guarda el emoji seleccionado
    const pickEmojiTitulo = (e, { emoji }) => {
        const refTitulo = obtenerTextareaTitulo;
        let titulo = pushForm.getFieldsValue(['titulousuarios']).titulousuarios;
        refTitulo.focus();
        const start = titulo.slice(0, refTitulo.selectionStart);
        const end = titulo.slice(refTitulo.selectionEnd);
        const text = start + emoji + end;

        // se tiene que reiniciar al poner un emoji nuevo
        setMostrarEmojisTitulo();

        //establecer titulo nuevo en la pantalla
        pushForm.setFieldsValue({ titulousuarios: text });
    };

    // se guarda el emoji seleccionado
    const pickEmojiMensaje = (e, { emoji }) => {
        const refMensaje = obtenerTextareaMensaje;
        let mensaje = pushForm.getFieldsValue([
            'mensajeusuarios',
        ]).mensajeusuarios;
        refMensaje.focus();
        const start = mensaje.slice(0, obtenerTextareaMensaje.selectionStart);
        const end = mensaje.slice(obtenerTextareaMensaje.selectionEnd);
        const text = start + emoji + end;

        // se tiene que reiniciar al poner un emoji nuevo
        setMostrarEmojisMensaje();

        //establecer titulo nuevo en la pantalla
        pushForm.setFieldsValue({ mensajeusuarios: text });
    };

    // abre el picker del emoji
    const handleMostrarEmojisTitulo = () => {
        setMostrarEmojisTitulo(!mostrarEmojisTitulo);
    };

    // abre el picker del emoji
    const handleMostrarEmojisMensaje = () => {
        setMostrarEmojisMensaje(!mostrarEmojisMensaje);
    };

    const showEmojiTitle = mostrarEmojisTitulo ? (
        <Emoji pickEmoji={pickEmojiTitulo} />
    ) : null;

    const showEmojiMessage = mostrarEmojisMensaje ? (
        <Emoji pickEmoji={pickEmojiMensaje} />
    ) : null;

    return (
        <>
            <Card title="Su mensaje">
                <Form.Item
                    name={'titulo' + prefix}
                    label="Título"
                    rules={[{ required: true }]}>
                    <TextArea
                        className={styles.textAreaTitulo}
                        name={'titulo' + prefix}
                        maxLength={maxCantidadCaracteresTitulo}
                        autoSize="false"
                        showCount
                        ref={inputRefTitulo}
                        cols={58}
                        rows={1}
                    />
                </Form.Item>
                <SmileOutlined
                    onClick={handleMostrarEmojisTitulo}
                    style={{ position: 'absolute', top: 100, right: 30 }}
                />
                {showEmojiTitle}

                <Form.Item
                    name={'mensaje' + prefix}
                    label="Mensaje"
                    rules={[{ required: true }]}>
                    <TextArea
                        style={{ resize: 'none' }}
                        ref={inputRefMensaje}
                        name={'mensaje' + prefix}
                        maxLength={maxCantidadCaracteresMensaje}
                        autoSize="false"
                        showCount
                        cols={58}
                        rows={1}
                    />
                </Form.Item>
                <SmileOutlined
                    onClick={handleMostrarEmojisMensaje}
                    style={{ position: 'absolute', top: 195, right: 30 }}
                />
                {showEmojiMessage}
            </Card>
        </>
    );
};

export default PushMensajeUsuario;

const styles = {
    textAreaTitulo: {
        resize: 'none',
    },
};

PushMensajeUsuario.propTypes = {
    pushForm: PropTypes.object,
    prefix: PropTypes.string,
    maxCantidadCaracteresTitulo: PropTypes.number,
    maxCantidadCaracteresMensaje: PropTypes.number,
};
