import React, { useState, useEffect } from 'react';
import ABMLayout from '../../../../layouts/ABMLayout';
import {
    Card,
    Row,
    Col,
    Table,
    Button,
    Input,
    Modal,
    Form,
    Dropdown,
    Menu,
    message,
    Tag,
    Badge,
} from 'antd';
import {
    PlusCircleOutlined,
    ExclamationCircleOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import axios from '../../../../config/axios';
import { useHistory } from 'react-router-dom';

const { confirm } = Modal;

const menu = (
    onEditar,
    onDesHabilitarHabilitar,
    deshabilitarHabilitar,
    onEliminar,
) => (
    <Menu>
        <Menu.Item onClick={() => onEditar()}>Editar</Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => onDesHabilitarHabilitar()}>
            {deshabilitarHabilitar ? 'Deshabilitar' : 'Habilitar'}
        </Menu.Item>
        <Menu.Item onClick={() => onEliminar()}>Eliminar</Menu.Item>
    </Menu>
);

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (text) => (
            <span>
                #{text.id}{' '}
                <Badge
                    status={text.enabled === 'habilitado' ? 'success' : 'error'}
                />
            </span>
        ),
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Facturar a',
        dataIndex: 'invoice_name',
        key: 'invoice_name',
    },
    {
        title: 'Nro. Documento',
        dataIndex: 'document_number',
        key: 'document_number',
    },
    {
        title: 'Entidad',
        dataIndex: 'entity_code',
        key: 'entity_code',
    },
    {
        title: 'Cuenta',
        dataIndex: 'account',
        key: 'account',
        render: (text) => (
            <span>
                {text.account_number}{' '}
                {text.money === 'GUARANIES' ? (
                    <Tag color="blue">PYG</Tag>
                ) : (
                    <Tag color="green">USD</Tag>
                )}
            </span>
        ),
    },
    {
        title: '',
        key: 'action',
        render: (text, record) => (
            <Dropdown
                overlay={() =>
                    menu(
                        record?.onEditar,
                        record?.onDesactivar,
                        record?.id.enabled,
                        record?.onEliminar,
                    )
                }>
                <Button icon={<MoreOutlined />} />
            </Dropdown>
        ),
    },
];

const ListaCatastros = () => {
    const [paramsCatastros, setParamsCatastros] = useState({
        page: 1,
        items_per_page: 10,
    });
    const [formFiltros] = Form.useForm();
    const [listaCatastros, setListaCatastros] = useState();
    const [obteniendoDatosTabla, setObteniendoDatosTabla] = useState(false);
    const [cantidadRegistros, setCantidadRegistros] = useState();
    const history = useHistory();

    // Form Filtros
    useEffect(() => {
        if (window.history.state?.paramsCatastros) {
            // Restaurar filtros del Form
            setParamsCatastros(window.history.state.paramsCatastros);
        } else {
            formFiltros.setFieldsValue({ familia: '' });
        }
    }, []);

    const onFiltrosFinish = (values) => {
        const params = { page: 1, ...values };

        setParamsCatastros((state) => ({ ...state, ...params }));
    };

    // Tabla
    useEffect(() => {
        if (paramsCatastros) obtenerCatastros();
    }, [paramsCatastros]);

    const obtenerCatastros = async () => {
        setObteniendoDatosTabla(true);
        let arrCatastros = [];
        let numCantidadRegistros = 0;

        try {
            const respuesta = await axios.get('/get_all_cadastres', {
                params: paramsCatastros,
            });

            arrCatastros = respuesta.data.data.cadastres.map((item) => ({
                ...item,
                key: item.id,
                id: {
                    id: item.id,
                    enabled: item.enabled && 'habilitado',
                },
                account: {
                    account_number: item.account_number,
                    money: item.money,
                },
                onEditar: () => editarButtonHandler(item),
                onDesactivar: () =>
                    desactivarActivarButtonHandler(item.id, item.enabled),
                onEliminar: () => eliminarButtonHandler(item.id),
            }));

            numCantidadRegistros = respuesta.data.data.count;
        } catch (error) {
            message.error(error?.response.data.message || error.message);
        }

        setListaCatastros(arrCatastros);
        setCantidadRegistros(numCantidadRegistros);
        setObteniendoDatosTabla(false);
    };

    const desactivarActivarCatastro = async (id, enabled) => {
        setObteniendoDatosTabla(true);

        try {
            const body = {
                id: id,
                enabled: !enabled,
            };
            const respuesta = await axios.post('update_cadastre', body);

            message.success(respuesta.data.data.data);
            obtenerCatastros();
        } catch (error) {
            message.error(error.response.data.message);
        }

        setObteniendoDatosTabla(false);
    };

    const eliminarCatastro = async (id) => {
        setObteniendoDatosTabla(true);

        try {
            const respuesta = await axios.post('delete_cadastre', { id: id });
            message.success(respuesta.data.data.data);
            obtenerCatastros();
        } catch (error) {
            message.error(error.response.data.message);
        }

        setObteniendoDatosTabla(false);
    };

    const nuevoButtonHandler = () => {
        guardarFiltrosForm();

        // Ir a Nuevo
        history.push(`/administrar/catastros/nuevo`);
    };

    const editarButtonHandler = (itemSeleccionado) => {
        guardarFiltrosForm();

        // Ir a Editar
        history.push(`/administrar/catastros/editar`, {
            initialValues: itemSeleccionado,
        });
    };

    const guardarFiltrosForm = () => {
        window.history.pushState(
            { paramsCatastros: paramsCatastros },
            paramsCatastros,
            '/administrar/catastros/lista',
        );
    };

    const desactivarActivarButtonHandler = (id, enabled) => {
        confirm({
            title: `Esta seguro que quiere ${
                enabled ? 'deshabilitar' : 'habilitar'
            } este catastro?`,
            icon: <ExclamationCircleOutlined />,
            okText: `${enabled ? 'Deshabilitar' : 'Habilitar'}`,
            okType: `${enabled ? 'danger primary' : 'primary'}`,
            onOk() {
                desactivarActivarCatastro(id, enabled);
            },
            cancelText: 'Cancelar',
            cancelType: `${enabled ? 'primary' : 'secondary'}`,
            onCancel() {
                console.log('Cancelar');
            },
        });
    };

    const eliminarButtonHandler = (id) => {
        confirm({
            title: 'Esta seguro que quiere eliminar este catastro?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            okType: 'danger',
            onOk() {
                eliminarCatastro(id);
            },
            cancelText: 'Cancelar',
        });
    };

    const paginacionChangeHandler = (page, pageSize) => {
        setParamsCatastros((state) => ({
            ...state,
            page: page,
            items_per_page: pageSize,
        }));
    };

    return (
        <ABMLayout
            titulo={'Catastros'}
            acciones={
                <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={nuevoButtonHandler}>
                    Nuevo
                </Button>
            }>
            <Card>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form
                            name="filtros"
                            form={formFiltros}
                            layout="vertical"
                            onFinish={onFiltrosFinish}>
                            <Row gutter={12}>
                                <Col span={4}>
                                    <Form.Item label="Nombre" name="name">
                                        <Input allowClear={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Facturar a"
                                        name="invoice_name">
                                        <Input allowClear={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Nro. Documento"
                                        name="document_number">
                                        <Input allowClear={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item
                                        label="Entidad"
                                        name="entity_code">
                                        <Input allowClear={true} />
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item style={{ marginTop: 26 }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit">
                                            Buscar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            loading={obteniendoDatosTabla}
                            columns={columns}
                            dataSource={listaCatastros}
                            pagination={{
                                pageSize: paramsCatastros?.items_per_page,
                                total: cantidadRegistros,
                                current: paramsCatastros?.page
                                    ? paramsCatastros.page
                                    : 1,
                                showSizeChanger: true,
                                onChange: (page, pageSize) =>
                                    paginacionChangeHandler(page, pageSize),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ListaCatastros;
