import {
    SEASONS_LOADING,
    SEASONS_CURRENT_SEASON,
    SEASONS_LIST_SEASONS,
} from './types';

export const initialState = {
    loading: false,
    currentSeason: null,
    seasonsList: [],
};

const TemporadasReducer = (state = initialState, action) => {
    switch (action.type) {
        case SEASONS_LOADING:
            return {
                ...state,
                loading: action?.payload?.loading,
            };
        case SEASONS_CURRENT_SEASON: {
            // Get seasons list
            const seasonList = action?.payload?.seasonsList;

            let currentSeasonObj = null;
            const seasonListIsArray = Boolean(seasonList?.length);
            if (seasonListIsArray) {
                // Set the first object as the 'currentSeason'
                currentSeasonObj = seasonList[0];
            }

            return {
                ...state,
                currentSeason: currentSeasonObj,
            };
        }
        case SEASONS_LIST_SEASONS:
            return {
                ...state,
                seasonsList: action.payload.seasonsList,
            };
        default:
            return state;
    }
};

export default TemporadasReducer;
