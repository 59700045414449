import React, { useState } from 'react';
import { Col, Row, Space } from 'antd';
import PushAlcance from '../../molecules/Push/PushAlcance';
import PushVistaPrevia from '../../molecules/Push/PushVistaPrevia';
import PushMensajeUsuario from '../../molecules/Push/PushMensajeUsuario';
import PushDeepLink from '../../molecules/Push/PushDeepLink';
import PushAudiencia from '../../molecules/Push/PushAudiencia';
import ProgramarPush from '../../molecules/Push/PushProgramado.js';
import PropTypes from 'prop-types';

const PushUsuario = ({
    pushForm,
    fields,
    tab,
    titulo,
    mensaje,
    maxCantidadCaracteresTitulo,
    maxCantidadCaracteresMensaje,
    date,
    hours,
    checked,
    checkedSwitch,
}) => {
    const [cantidadUsuariosTotal, setCantidadUsuariosTotal] = useState(); // Total de usuarios en la BD
    const [cantidadUsuariosSeleccionados, setCantidadUsuariosSeleccionados] =
        useState(); // Total de usuarios en la BD

    return (
        <>
            <Row gutter="24">
                <Col span={12}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ width: '100%' }}>
                        <PushAudiencia
                            pushForm={pushForm}
                            fields={fields}
                            setCantidadUsuariosTotal={setCantidadUsuariosTotal}
                            setCantidadUsuariosSeleccionados={
                                setCantidadUsuariosSeleccionados
                            }
                            setCantidadUsuariosArchivo={
                                setCantidadUsuariosSeleccionados
                            }
                            cantidadUsuariosSeleccionados={
                                cantidadUsuariosSeleccionados
                            }
                        />
                        <PushMensajeUsuario
                            prefix="usuarios"
                            maxCantidadCaracteresTitulo={
                                maxCantidadCaracteresTitulo
                            }
                            maxCantidadCaracteresMensaje={
                                maxCantidadCaracteresMensaje
                            }
                            pushForm={pushForm}
                        />

                        <PushDeepLink
                            pushForm={pushForm}
                            tab={tab}
                            fields={fields}
                        />
                    </Space>
                </Col>
                <Col span={12}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ width: '100%' }}>
                        <ProgramarPush
                            pushForm={pushForm}
                            tab={tab}
                            setDate={date}
                            setHours={hours}
                            setChecked={checked}
                            checkedSwitch={checkedSwitch}></ProgramarPush>
                        <PushAlcance
                            pushForm={pushForm}
                            usuariosTotal={cantidadUsuariosTotal || 0}
                            usuariosSeleccionados={
                                cantidadUsuariosSeleccionados || 0
                            }
                        />
                        <PushVistaPrevia titulo={titulo} mensaje={mensaje} />
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default PushUsuario;

PushUsuario.propTypes = {
    pushForm: PropTypes.object,
    fields: PropTypes.array,
    tab: PropTypes.string,
    titulo: PropTypes.string,
    mensaje: PropTypes.string,
    maxCantidadCaracteresTitulo: PropTypes.number,
    maxCantidadCaracteresMensaje: PropTypes.number,
    date: PropTypes.any,
    hours: PropTypes.any,
    checked: PropTypes.any,
    checkedSwitch: PropTypes.any,
};
