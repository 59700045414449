import {
    USER_LEVEL_LOADING,
    USER_LEVEL_LIST,
    USER_LEVEL_SHOW_DETAIL,
    USER_LEVEL_HIDE_DETAIL,
    USER_LEVEL_CLOSE_EDIT,
    USER_LEVEL_REWARDS_LOADING,
    USER_LEVEL_REWARDS_LIST,
    USER_LEVEL_SET_SELECTED_USER_LEVEL,
} from './Types';

export const initialState = {
    userLevelList: [],
    selectedUserLevel: undefined,
    loading: false,
    isShowUserLevelModalVisible: false,
    rewardsList: [],
    isRewardsListLoading: false,
};

const NivelUsuarioReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LEVEL_LOADING: {
            return {
                ...state,
                loading: !state.loading,
            };
        }
        case USER_LEVEL_LIST: {
            return {
                ...state,
                userLevelList: action.payload.userLevelList,
                loading: false,
            };
        }
        case USER_LEVEL_SET_SELECTED_USER_LEVEL: {
            return {
                ...state,
                selectedUserLevel: action.payload.selectedUserLevel,
            };
        }
        case USER_LEVEL_SHOW_DETAIL: {
            return {
                ...state,
                selectedUserLevel: action.payload.selectedUserLevel,
                isShowUserLevelModalVisible: true,
            };
        }
        case USER_LEVEL_HIDE_DETAIL: {
            return {
                ...state,
                selectedUserLevel: undefined,
                isShowUserLevelModalVisible: false,
            };
        }
        case USER_LEVEL_CLOSE_EDIT: {
            return {
                ...state,
                selectedUserLevel: undefined,
                isShowUserLevelModalVisible: false,
            };
        }

        case USER_LEVEL_REWARDS_LOADING: {
            return {
                ...state,
                isRewardsListLoading: !state.isRewardsListLoading,
            };
        }
        case USER_LEVEL_REWARDS_LIST: {
            return {
                ...state,
                rewardsList: action.payload.rewardsList,
                isRewardsListLoading: false,
            };
        }

        default:
            return state;
    }
};

export default NivelUsuarioReducer;
