import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import ProtectedComponent from '../../../hoc/ProtectedComponent';
import UsuariosState from '../../../context/usuario/UsuariosState';
import { Permisos } from '../../../constants/Permisos';

import ListaUsuarios from './ListaUsuarios';
import DetallesUsuarios from './DetallesUsuarios';
import Clientes from './Clientes';

const Usuarios = ({ match }) => {
    return (
        <UsuariosState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.usuarios.listaUsuarios.verPagina}
                            componente={ListaUsuarios}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/detalles`}
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.usuarios.listaUsuarios.verPagina}
                            componente={DetallesUsuarios}
                        />
                    )}
                />
                <Route path={`${match.url}/clientes`} component={Clientes} />
            </Switch>
        </UsuariosState>
    );
};

export default Usuarios;

Usuarios.propTypes = {
    match: PropTypes.object,
};
