import React from 'react';
import { Tabs, Card } from 'antd';
import ABMLayout from '../../../../../layouts/ABMLayout';
import ListaRecibos from './Recibos/ListaRecibos';
import ListaFacturas from './Facturas/ListaFacturas';

const { TabPane } = Tabs;

const EmisionDocumentosLista = () => {
    return (
        <ABMLayout titulo="Emisión de documentos">
            <Card>
                <Tabs defaultActiveKey="1" animated={false}>
                    <TabPane tab="Facturas" key="1">
                        <ListaFacturas />
                    </TabPane>
                    <TabPane tab="Recibos" key="2">
                        <ListaRecibos />
                    </TabPane>
                </Tabs>
            </Card>
        </ABMLayout>
    );
};

export default EmisionDocumentosLista;
