import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import ListaComisiones from './ListaComisiones';

import { Permisos } from '../../../../../constants/Permisos';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import ReporteComisionesState from '../../../../../context/reportes/ventas/comisiones/ReporteComisionesState';

const ReporteComisiones = ({ match }) => {
    return (
        <ReporteComisionesState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.reportes.ventas.reporteComisiones
                                    .verPagina
                            }
                            componente={ListaComisiones}
                        />
                    )}
                />
            </Switch>
        </ReporteComisionesState>
    );
};

export default ReporteComisiones;

ReporteComisiones.propTypes = {
    match: PropTypes.object,
};
