import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Permisos } from 'constants/Permisos';

import ProtectedComponent from 'hoc/ProtectedComponent/index';

import ListaAtributos from './Lista/index';
import NuevoEditarForm from './NuevoEditarForm/index';

const Atributos = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.panel}
                        componente={ListaAtributos}
                    />
                )}
            />
            <Route
                path={`${match.url}/nuevo`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.panel}
                        componente={NuevoEditarForm}
                    />
                )}
            />
            <Route
                path={`${match.url}/editar`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.panel}
                        componente={NuevoEditarForm}
                    />
                )}
            />
        </Switch>
    );
};

export default Atributos;

Atributos.propTypes = {
    match: PropTypes.object,
};
