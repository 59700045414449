import React from 'react';
import { useParams } from 'react-router-dom';
import TimbradoForm from './TimbradoForm';

const EditarTimbrado = () => {
    const params = useParams();
    return <TimbradoForm mode="EDIT" param={params} />;
};

export default EditarTimbrado;
