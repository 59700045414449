import React, { useEffect, useState } from 'react';
import { Card, Form, Col, Row, Select, message } from 'antd';
import axios from '../../../config/axios';
import { urls } from '../../../constants';
import deeplinkOptions from '../../../constants/pushDeeplinkOptions';
import PropTypes from 'prop-types';

const PushDeepLink = ({
    pushForm,
    tab,
    fields,
    tipoZona,
    restablecerDeeplink,
    setRestablecerDeeplink,
}) => {
    // State de soporte para "select" de Seccion
    const [seccionData, setSeccionData] = useState([]); // datos a mostrar
    const [seccionLoading, setSeccionLoading] = useState(false);
    const [seccionDisabled, setSeccionDisabled] = useState(true);
    const [seccionRequired, setSeccionRequired] = useState(false);

    // Obtener Franquicias para "deeplink" del endpoint
    const obtenerFranquicias = async (franchiseId) => {
        let arrSeccion = [];

        try {
            setSeccionLoading(true);
            const respuesta = await axios.get('franchises_list');
            const arrFranquicias = respuesta.data.data.franchises;
            if (franchiseId) {
                arrSeccion = [
                    ...arrFranquicias.filter(
                        (item) => Number(item.id) === Number(franchiseId),
                    ),
                ];
            } else {
                arrSeccion = [...arrFranquicias];
            }
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las franquicias',
            );
            console.log(
                'Hubo un problema al obtener los datos de las franquicias',
                error,
            );
        }

        setSeccionLoading(false);

        setSeccionData(arrSeccion);
    };

    // Obtener Promociones para "deeplink" del endpoint
    const obtenerPromociones = async (franchises) => {
        const data = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            franchises: franchises && franchises.map((item) => Number(item)),
        };

        let arrSeccion = [];

        try {
            setSeccionLoading(true);
            const respuesta = await axios.post('get_promotions', data);
            const arrPromos = respuesta.data.data.promotions;
            arrSeccion = arrPromos.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las promociones',
            );
            console.log(
                'Hubo un problema al obtener los datos de las promociones',
                error,
            );
        }

        setSeccionLoading(false);
        if (arrSeccion.length > 0) {
            setSeccionDisabled(false);
            setSeccionRequired(true);
            setSeccionData(arrSeccion);
        } else {
            setSeccionDisabled(true);
            setSeccionRequired(false);
            setSeccionData([]);
        }
    };

    // Obtener Promociones para "deeplink" del endpoint
    const obtenerProductos = async (franchiseId) => {
        let params = {};
        params['axiosBaseURL'] = urls.REACT_APP_BACKEND_URL_C;
        params['franchise_id'] = franchiseId ? Number(franchiseId) : undefined;

        let arrSeccion = [];

        try {
            setSeccionLoading(true);
            const respuesta = await axios.get('get_products', {
                params: params,
            });
            const arrProductos = respuesta.data.data.products;
            arrSeccion = arrProductos.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de los productos',
            );
            console.log(
                'Hubo un problema al obtener los datos de los productos',
                error,
            );
        }

        setSeccionLoading(false);

        setSeccionData(arrSeccion);
    };

    // Manejar el cambio de opciones en el "select" de Pantalla
    const handlePantallaChange = (value) => {
        // limpiar valores en seccion
        pushForm.setFieldsValue({ seccion: null });
        setSeccionData([]);
        setSeccionRequired(false);
        setSeccionDisabled(true);
        setSeccionRequired(false);

        // obtener datos dependiendo de la seleccion
        switch (value) {
            case deeplinkOptions.home:
                setSeccionDisabled(true);
                setSeccionRequired(false);
                setSeccionData([]);
                break;
            case deeplinkOptions.franquicia:
                // Manejar caso de Pestaña "Zona", Seccion "Zona de Cobertura"
                if (tab === '2' && tipoZona === '1') {
                    // Extraer "zona de cobertura" del formulario
                    let zonasCobertura =
                        pushForm.getFieldsValue(true)?.zonasCobertura;
                    if (zonasCobertura) {
                        // extraer IDs de franquicias y remover duplicados
                        zonasCobertura = [
                            ...new Set(
                                zonasCobertura.map((item) => item.franchise_id),
                            ),
                        ];
                    }

                    // Obtener franquicias si existe una sola "zona de cobertura"
                    if (zonasCobertura.length === 1) {
                        setSeccionDisabled(false);
                        setSeccionRequired(true);
                        obtenerFranquicias(zonasCobertura[0].toString());
                    } else if (zonasCobertura.length > 1) {
                        setSeccionDisabled(true);
                        setSeccionRequired(false);
                        setSeccionData([]);
                    }
                } else {
                    setSeccionDisabled(false);
                    setSeccionRequired(true);
                    obtenerFranquicias();
                }
                break;
            case deeplinkOptions.promocion:
                // Manejar caso de Pestaña "Zona", Seccion "Zona de Cobertura"
                if (tab === '2' && tipoZona === '1') {
                    // Extraer "zona de cobertura" del formulario
                    let zonasCobertura =
                        pushForm.getFieldsValue(true)?.zonasCobertura;
                    if (zonasCobertura) {
                        // extraer IDs de franquicias y remover duplicados
                        zonasCobertura = [
                            ...new Set(
                                zonasCobertura.map((item) => item.franchise_id),
                            ),
                        ];
                    }
                    // Obtener datos de las promociones
                    obtenerPromociones(zonasCobertura);
                } else {
                    obtenerPromociones(undefined);
                }
                break;
            case deeplinkOptions.producto:
                // Manejar caso de Pestaña "Zona", Seccion "Zona de Cobertura"
                if (tab === '2' && tipoZona === '1') {
                    // Extraer "zona de cobertura" del formulario
                    let zonasCobertura =
                        pushForm.getFieldsValue(true)?.zonasCobertura;
                    if (zonasCobertura) {
                        // extraer IDs de franquicias y remover duplicados
                        zonasCobertura = [
                            ...new Set(
                                zonasCobertura.map((item) => item.franchise_id),
                            ),
                        ];
                    }

                    // Obtener franquicias si existe una sola "zona de cobertura"
                    if (zonasCobertura.length === 1) {
                        setSeccionDisabled(false);
                        setSeccionRequired(true);
                        // obtenerProductos(filterFranchiseId);
                        obtenerProductos(zonasCobertura[0].toString());
                    } else if (zonasCobertura.length > 1) {
                        setSeccionDisabled(true);
                        setSeccionRequired(false);
                        setSeccionData([]);
                    }
                } else {
                    setSeccionDisabled(true);
                    setSeccionRequired(false);
                    setSeccionData([]);
                }
                break;

            default:
                break;
        }
    };

    const handleSeccionChange = () => {};

    const restablecerComponente = () => {
        pushForm.setFieldsValue({
            pantalla: deeplinkOptions.home,
            seccion: null,
        });
        setSeccionData([]);
        setSeccionRequired(false);
        setSeccionDisabled(true);
    };

    useEffect(() => {
        if (restablecerDeeplink || fields === null) {
            restablecerDeeplink && setRestablecerDeeplink(false);
            restablecerComponente();
        }
    }, [restablecerDeeplink, fields]);

    useEffect(() => {
        if (fields === null) {
            restablecerComponente();
        }
    }, [fields]);

    return (
        <Card title="Enviar a">
            <Row gutter="24">
                <Col span="12">
                    <Form.Item
                        name="pantalla"
                        label="Pantalla"
                        rules={[{ required: true }]}>
                        <Select name="pantalla" onChange={handlePantallaChange}>
                            <Select.Option value={deeplinkOptions.home}>
                                Home
                            </Select.Option>
                            <Select.Option value={deeplinkOptions.franquicia}>
                                Franquicia
                            </Select.Option>
                            <Select.Option value={deeplinkOptions.promocion}>
                                Promoción
                            </Select.Option>
                            <Select.Option value={deeplinkOptions.coupon}>
                                Cupon
                            </Select.Option>
                            {tab === '2' && tipoZona === '1' ? (
                                <Select.Option value={deeplinkOptions.producto}>
                                    Producto
                                </Select.Option>
                            ) : null}
                        </Select>
                    </Form.Item>
                </Col>
                <Col span="12">
                    <Form.Item
                        name="seccion"
                        label="Sección"
                        rules={[{ required: seccionRequired }]}>
                        <Select
                            name="seccion"
                            showSearch
                            loading={seccionLoading}
                            onChange={handleSeccionChange}
                            disabled={seccionDisabled}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }>
                            {seccionData?.map((item) => (
                                <Select.Option key={item.id} value={item.id}>
                                    {item.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

PushDeepLink.propTypes = {
    pushForm: PropTypes.object,
    fields: PropTypes.array,
    tab: PropTypes.string,
    tipoZona: PropTypes.string,
    restablecerDeeplink: PropTypes.bool,
    setRestablecerDeeplink: PropTypes.func,
};

export default PushDeepLink;
