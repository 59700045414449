import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Button from '../atoms/Button';
import useRouterQuery from 'hooks/useRouterQueries';

const TablaResumen = (props) => {
    const history = useHistory();
    const { createQueryParams } = useRouterQuery();

    const columnasDetalleTransaccion = [
        {
            title: <strong>Nro de orden</strong>,
            dataIndex: 'order_id',
            key: 'nrodeorden',
            align: 'center',
        },
        {
            title: <strong>Fecha y Hora</strong>,
            dataIndex: 'date_time',
            key: 'fechahora',
            align: 'center',
        },
        {
            title: <strong>Tipo de Pago</strong>,
            dataIndex: 'payment_type',
            key: 'tipodepago',
            align: 'center',
        },
        {
            title: <strong>Sucursal</strong>,
            dataIndex: 'branch',
            key: 'sucursal',
            align: 'center',
        },
        {
            title: <strong>Driver Monchis</strong>,
            dataIndex: 'has_monchis_drivers',
            key: 'drivermonchis',
            align: 'center',
        },
        {
            title: <strong>Importe de la orden</strong>,
            dataIndex: 'total_order',
            key: 'importeorden',
            align: 'center',
        },
        {
            title: <strong>Importe Total</strong>,
            dataIndex: 'total_with_delivery',
            key: 'importetotal',
            align: 'center',
        },
        {
            title: <strong>SubTotal</strong>,
            dataIndex: 'subtotal_comission',
            key: 'subtotal',
            align: 'center',
        },
        {
            title: <strong>Comisión a cobrar</strong>,
            dataIndex: 'comission',
            key: 'comisioncobrar',
            align: 'center',
        },
        {
            title: <strong>Ver Orden</strong>,
            dataIndex: 'verorden',
            key: 'verorden',
            align: 'center',
            render: (orderId) => (
                <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => verOrdenBotonHandler(orderId)}
                />
            ),
        },
    ];

    // Detalle Transaccion - Boton Ver Orden
    const verOrdenBotonHandler = (orderId) => {
        // paramsDetalle viene de la tabla de cortes, contiene los parametros de cortes y detalle
        if (props.paramsDetalle) {
            // guardar state en la pagina actual
            window.history.pushState(
                { ...props.paramsDetalle, cortesKey: props.cortesKey },
                'paramsDetalle',
                '/reportes/cortes/corte-transaccion',
            );
        }

        const queryParams = createQueryParams({ order_id: orderId });
        history.push(
            `/reportes/ventas/detalle-transaccion/ver-orden${queryParams}`,
        );
    };

    return (
        <Table
            className="tabla-finanzas"
            columns={props.columns || columnasDetalleTransaccion}
            dataSource={props.data}
            {...props}
        />
    );
};

export default TablaResumen;

TablaResumen.propTypes = {
    paramsDetalle: PropTypes.object,
    cortesKey: PropTypes.object,
    columns: PropTypes.array,
    data: PropTypes.array,
};
