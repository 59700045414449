import React, { useEffect, useState } from 'react';
import axios from '../../config/axios';
import { Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import descargarArchivoRaw from '../utils-components/descargarArchivoRaw';

const TuFactura = () => {
    const params = useParams();
    const [obteniendoDatos, setObteniendoDatos] = useState(true);

    useEffect(() => {
        obtenerTuFactura(params.id, params.secreto);
    }, []);

    const obtenerTuFactura = async (id, secreto) => {
        try {
            setObteniendoDatos(true);

            const respuesta = await axios.get(
                `/get_invoice_without_token?invoice_id=${id}&secret=${secreto}`,
            );

            // eslint-disable-next-line no-undef
            const rawFile = Buffer.from(respuesta.data.data.body, 'base64');
            const fileName = 'tu-factura';
            const fileType = 'pdf';

            const respuestaContador = await axios.get(
                `/increase_invoice_count_viewed?invoice_id=${id}&secret=${secreto}`,
            );

            if (respuestaContador.data.success) {
                descargarArchivoRaw(rawFile, fileName, fileType);
            } else {
                throw 'Hubo un problema al obtener tu factura';
            }
        } catch (error) {
            message.error(error.response?.data.message || error);
        }
        setObteniendoDatos(false);
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <Spin tip="Obteniendo factura..." spinning={obteniendoDatos} />
        </div>
    );
};

export default TuFactura;
