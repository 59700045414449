import React, { useEffect, useState } from 'react';
import { Button, Space, Tabs, message, Form, Card, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import axios from '../../../config/axios';

import PushUsuarios from './PushUsuarios';
import PushZonas from './PushZonas';
import PushVistaPrevia from '../../molecules/Push/PushVistaPrevia';
import { urls } from '../../../constants';
import deeplinkOptions from '../../../constants/pushDeeplinkOptions';

import ABMLayout from '../../../layouts/ABMLayout';
import classes from './Push.module.css';

const { Text } = Typography;
const maxCantidadCaracteresTitulo = 55;
const maxCantidadCaracteresMensaje = 200;

const Push = () => {
    const [fields, setFields] = useState(); // Mantiene los campos del formulario
    const [tab, setTab] = useState('1'); // Mantiene la pestaña activa (usuarios o zonas)
    const [modalConfirmarEnvioPushVisible, setModalConfirmarEnvioPushVisible] =
        useState(false);
    const [loading, setLoading] = useState();
    // Guardar el tipo de zona seleccionada, "1" Cobertura, "2" Geografica, "3" Geografica Personalizada
    const [tipoZona, setTipoZona] = useState('1');
    const [date, setDate] = useState();
    const [hours, setHours] = useState();
    const [checked, setChecked] = useState();

    const dateConcat = `${date} ${hours}`;

    // Llamada al endpoint de envio de PUSH Usuarios
    const sendPushNotificationUsers = async (
        title,
        content,
        usersId,
        feature,
        extraData,
        usuariosSeleccionados,
        usuariosTotal,
    ) => {
        let dataPush = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            title,
            content,
            users_id: usersId,
            selected_users: usuariosSeleccionados,
            total_users: usuariosTotal,
        };

        if (
            feature !== deeplinkOptions.home ||
            feature !== deeplinkOptions.coupon
        ) {
            dataPush = {
                ...dataPush,
                feature,
                extraData,
            };
        }

        if (date && hours) {
            let newDate = new Date(dateConcat).toISOString();
            dataPush = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                date: newDate,
                push: {
                    title,
                    content,
                    users_id: usersId,
                    feature,
                    extraData,
                    selected_users: usuariosSeleccionados,
                    total_users: usuariosTotal,
                },
                type: 'push_notification',
            };
            dataPush.push.feature === 'home'
                ? delete dataPush.push.feature
                : null;
            dataPush.push.extra_data ? null : delete dataPush.push.extra_data;
        }

        setLoading(true);

        try {
            if (date && hours) {
                const response = await axios.post('/schedule_push', dataPush);
                message.success(
                    response?.message || 'Push programado con éxito.',
                );
            } else {
                const response = await axios.post(
                    '/push_notification',
                    dataPush,
                );
                message.success(
                    response?.message || 'Push enviado correctamente.',
                );
            }
        } catch (error) {
            message.error(
                error || 'Hubo un problema al enviar su mensaje push',
            );
        }
        setLoading(false);
        setChecked(false);
    };

    // Llamada al endpoint de envio de PUSH Zonas Cobertura
    const sendPushNotificationZonaCobertura = async (
        title,
        content,
        defaultAddress,
        zonasCobertura,
        feature,
        extraData,
        usuariosSeleccionados,
        usuariosTotal,
    ) => {
        let dataPush = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            title,
            content,
            defaultAddress,
            filters: zonasCobertura,
            selected_users: usuariosSeleccionados,
            total_users: usuariosTotal,
        };

        if (
            feature !== deeplinkOptions.home ||
            feature !== deeplinkOptions.coupon
        ) {
            dataPush = {
                ...dataPush,
                feature,
                extraData,
            };
        }

        setLoading(true);

        if (date && hours) {
            let newDate = new Date(dateConcat).toISOString();
            dataPush = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                date: newDate,
                push: {
                    title,
                    content,
                    defaultAddress,
                    filters: zonasCobertura,
                    feature,
                    extraData,
                    selected_users: usuariosSeleccionados,
                    total_users: usuariosTotal,
                },
                type: 'push_notification_for_zones',
            };
            dataPush.push.feature === 'home'
                ? delete dataPush.push.feature
                : null;
            dataPush.push.extra_data ? null : delete dataPush.push.extra_data;
        }

        try {
            if (date && hours) {
                const response = await axios.post('/schedule_push', dataPush);
                message.success(
                    response.message || 'Push programado con éxito.',
                );
            } else {
                const response = await axios.post(
                    '/push_notification_for_zones',
                    dataPush,
                );
                message.success(
                    response.message || 'Push enviado correctamente',
                );
            }
            message.success('Push enviado correctamente');
        } catch (error) {
            message.error(error);
            console.error('Hubo un problema al enviar su mensaje push', error);
        }
        setLoading(false);
        setChecked(false);

        // for testing..
        // setLoading(true);
        // console.log("dataPush", dataPush);
        // setTimeout(() => {
        //     setLoading(false);
        //     message.success("[TEST] Push enviado correctamente");
        // }, 4000);
    };

    // Llamada al endpoint de envio de PUSH Zonas Geográfica
    const sendPushNotificationZonaGeografica = async (
        title,
        content,
        defaultAddress,
        citiesId,
        neighborhoodsId,
        feature,
        extraData,
        usuariosSeleccionados,
        usuariosTotal,
    ) => {
        let dataPush = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            title,
            content,
            defaultAddress,
            citiesId: [citiesId],
            neighborhoodsId: neighborhoodsId ? [neighborhoodsId] : undefined,
            selected_users: usuariosSeleccionados,
            total_users: usuariosTotal,
        };

        if (
            feature !== deeplinkOptions.home ||
            feature !== deeplinkOptions.coupon
        ) {
            dataPush = {
                ...dataPush,
                feature,
                extraData,
            };
        }

        if (date && hours) {
            let newDate = new Date(dateConcat).toISOString();
            dataPush = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                date: newDate,
                push: {
                    title,
                    content,
                    defaultAddress,

                    citiesId: [citiesId],

                    neighborhoodsId: neighborhoodsId
                        ? [neighborhoodsId]
                        : undefined,
                    feature: feature,
                    extraData,
                    selected_users: usuariosSeleccionados,
                    total_users: usuariosTotal,
                },
                type: 'push_notification_for_geo_zone',
            };

            dataPush.push.feature === 'home'
                ? delete dataPush.push.feature
                : null;
            dataPush.push.extra_data ? null : delete dataPush.push.extra_data;
        }

        setLoading(true);

        try {
            if (date && hours) {
                const response = await axios.post('/schedule_push', dataPush);
                message.success(
                    response.message || 'Push programado con éxito.',
                );
            } else {
                const response = await axios.post(
                    '/push_notification',
                    dataPush,
                );
                message.success(
                    response.message || 'Push enviado correctamente',
                );
            }
            message.success('Push enviado correctamente');
        } catch (error) {
            message.error(
                error || 'Hubo un problema al enviar su mensaje push',
            );
        }
        setLoading(false);
        setChecked(false);

        // for testing..
        // setLoading(true);
        // console.log("dataPush", dataPush);
        // setTimeout(() => {
        //     setLoading(false);
        //     message.success("[TEST] Push enviado correctamente");
        // }, 4000);
    };

    // Llamada al endpoint de envio de PUSH Zona Geografica Personalizada
    const sendPushNotificationZonaGeograficaPersonalizada = async (
        title,
        content,
        defaultAddress,
        points,
        feature,
        extraData,
        usuariosSeleccionados,
        usuariosTotal,
    ) => {
        let dataPush = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            title,
            content,
            defaultAddress,
            points: [...points, points[0]],
            selected_users: usuariosSeleccionados,
            total_users: usuariosTotal,
        };

        if (
            feature !== deeplinkOptions.home ||
            feature !== deeplinkOptions.coupon
        ) {
            dataPush = {
                ...dataPush,
                feature,
                extraData,
            };
        }

        if (date && hours) {
            let newDate = new Date(dateConcat).toISOString();
            dataPush = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                date: newDate,
                push: {
                    title,
                    content,
                    defaultAddress,
                    points: [...points, points[0]],
                    feature,
                    extraData,
                    selected_users: usuariosSeleccionados,
                    total_users: usuariosTotal,
                },
                type: 'send_push_by_polygon',
            };
            dataPush.push.feature === 'home'
                ? delete dataPush.push.feature
                : null;
            dataPush.push.extra_data ? null : delete dataPush.push.extra_data;
        }

        setLoading(true);

        try {
            if (date && hours) {
                const response = await axios.post('/schedule_push', dataPush);
                message.success(
                    response.message || 'Push programado con éxito.',
                );
            } else {
                const response = await axios.post(
                    '/send_push_by_polygon',
                    dataPush,
                );
                message.success(
                    response.message || 'Push enviado correctamente',
                );
            }
            message.success('Push enviado correctamente');
        } catch (error) {
            message.error(
                error || 'Hubo un problema al enviar su mensaje push',
            );
        }
        setLoading(false);
        setChecked(false);

        // for testing..
        // setLoading(true);
        // console.log("dataPush", dataPush);
        // setTimeout(() => {
        //     setLoading(false);
        //     message.success("[TEST] Push enviado correctamente");
        // }, 4000);
    };

    // Formulario -- Configuracion
    const [pushForm] = Form.useForm();
    const formFieldsUsuarios = [
        'titulousuarios',
        'mensajeusuarios',
        'usuarios',
        'pantalla',
        'seccion',
        'usuariosSeleccionados',
        'usuariosTotal',
    ];
    const formFieldsZonaCobertura = [
        'titulozonas',
        'mensajezonas',
        'direccionesUsuario',
        'zonasCobertura',
        'pantalla',
        'seccion',
        'usuariosSeleccionados',
        'usuariosTotal',
    ];
    const formFieldsZonaGeografica = [
        'titulozonas',
        'mensajezonas',
        'direccionesUsuario',
        'ciudad',
        'barrio',
        'pantalla',
        'seccion',
        'usuariosSeleccionados',
        'usuariosTotal',
    ];
    const formFieldsZonaGeograficaPersonalizada = [
        'titulozonas',
        'mensajezonas',
        'direccionesUsuario',
        'poligonozonageografica',
        'usuariosSeleccionados',
        'usuariosTotal',
    ];
    // Modal Confirmar Envio Push
    // Validacion de datos del formulario
    const botonEnviarMostrarModal = () => {
        let camposFormValues = [];
        if (tab === '1') {
            checked
                ? (camposFormValues = [
                      ...formFieldsUsuarios,
                      'fecha',
                      'hora',
                      'usuariosSeleccionados',
                      'usuariosTotal',
                  ])
                : (camposFormValues = formFieldsUsuarios);
        } else if (tab === '2') {
            switch (tipoZona) {
                case '1': // Zona Cobertura
                    checked
                        ? (camposFormValues = [
                              ...formFieldsZonaCobertura,
                              'fecha',
                              'hora',
                              'usuariosSeleccionados',
                              'usuariosTotal',
                          ])
                        : (camposFormValues = formFieldsZonaCobertura);
                    break;
                case '2': // Zona Geografica
                    checked
                        ? (camposFormValues = [
                              ...formFieldsZonaGeografica,
                              'fecha',
                              'hora',
                              'usuariosSeleccionados',
                              'usuariosTotal',
                          ])
                        : (camposFormValues = formFieldsZonaGeografica);

                    break;
                case '3': // Zona Geografica Personalizada
                    checked
                        ? (camposFormValues = [
                              ...formFieldsZonaGeograficaPersonalizada,
                              'fecha',
                              'hora',
                              'usuariosSeleccionados',
                              'usuariosTotal',
                          ])
                        : (camposFormValues =
                              formFieldsZonaGeograficaPersonalizada);
                    break;
                default:
                    break;
            }
        }

        if (camposFormValues.length) {
            pushForm.validateFields(camposFormValues).then(() => {
                setModalConfirmarEnvioPushVisible(true);
            });
        } else {
            message.warning('Por favor complete los campos obligatorios.');
        }
    };

    // Extraer datos relacionados al tipo de push que se quiere enviar
    // Conectar con endpoint de envío de push
    const botonConfirmarEnvioPush = () => {
        if (tab === '1') {
            const formValues = pushForm.getFieldsValue(formFieldsUsuarios);
            sendPushNotificationUsers(
                formValues.titulousuarios,
                formValues.mensajeusuarios,
                formValues.usuarios,
                formValues.pantalla,
                formValues.seccion,
                formValues.usuariosSeleccionados,
                formValues.usuariosTotal,
            );
        } else if (tab === '2') {
            // Identificar cual tipo de zona se selecciono para enviar el push
            let formValues; // valores del form
            switch (tipoZona) {
                case '1':
                    formValues = pushForm.getFieldsValue(
                        formFieldsZonaCobertura,
                    );
                    sendPushNotificationZonaCobertura(
                        formValues.titulozonas,
                        formValues.mensajezonas,
                        formValues.direccionesUsuario,
                        formValues.zonasCobertura,
                        formValues.pantalla,
                        formValues.seccion,
                        formValues.usuariosSeleccionados,
                        formValues.usuariosTotal,
                    );
                    break;
                case '2':
                    formValues = pushForm.getFieldsValue(
                        formFieldsZonaGeografica,
                    );
                    sendPushNotificationZonaGeografica(
                        formValues.titulozonas,
                        formValues.mensajezonas,
                        formValues.direccionesUsuario,
                        formValues.ciudad,
                        formValues.barrio,
                        formValues.pantalla,
                        formValues.seccion,
                        formValues.usuariosSeleccionados,
                        formValues.usuariosTotal,
                    );
                    break;
                case '3':
                    formValues = pushForm.getFieldsValue(
                        formFieldsZonaGeograficaPersonalizada,
                    );
                    sendPushNotificationZonaGeograficaPersonalizada(
                        formValues.titulozonas,
                        formValues.mensajezonas,
                        formValues.direccionesUsuario,
                        formValues.poligonozonageografica,
                        formValues.pantalla,
                        formValues.seccion,
                        formValues.usuariosSeleccionados,
                        formValues.usuariosTotal,
                    );
                    break;
                default:
                    break;
            }
        }
        pushForm.resetFields();
        setFields(null);
        setDate(null);
        setHours(null);
    };

    // Cancelar envio de mensaje push
    const botonCancelarModal = () => {
        setModalConfirmarEnvioPushVisible(false);
    };

    // Ocultar modal cuando se finalicen las llamadas a endpoints
    useEffect(() => {
        if (loading === false) {
            setModalConfirmarEnvioPushVisible(false);
        }
    }, [loading]);

    // Titulo y mensaje por defecto
    const tituloDefecto = 'Escriba un título para el push';
    const mensajeDefecto = `Su mensaje para los usuarios 🤩 ... puede contener hasta ${maxCantidadCaracteresMensaje} caracteres!`;

    // State para manejo del "titulo y mensaje" en la Vista Previa
    const [titulo, setTitulo] = useState(tituloDefecto);
    const [mensaje, setMensaje] = useState(mensajeDefecto);

    // Detectar cambios en "fields, tab" y extraer "titulo y mensaje" para el Push
    // Vista Previa en vivo
    useEffect(() => {
        if (fields) {
            fields.forEach((item) => {
                // "1" Usuarios, "2" Zonas
                if (Number(tab) === 1 || tab === undefined) {
                    if (
                        item.name[0] === 'titulousuarios' &&
                        item.value &&
                        item.errors.length === 0
                    ) {
                        setTitulo(item.value);
                    } else if (
                        item.name[0] === 'titulousuarios' &&
                        (!item.value || item.errors.length > 0)
                    ) {
                        setTitulo(tituloDefecto);
                    }

                    if (
                        item.name[0] === 'mensajeusuarios' &&
                        item.value &&
                        item.errors.length === 0
                    ) {
                        setMensaje(item.value);
                    } else if (
                        item.name[0] === 'mensajeusuarios' ||
                        (!item.value && item.errors.length > 0)
                    ) {
                        setMensaje(mensajeDefecto);
                    }
                } else if (Number(tab) === 2) {
                    if (
                        item.name[0] === 'titulozonas' &&
                        item.value &&
                        item.errors.length === 0
                    ) {
                        setTitulo(item.value);
                    } else if (
                        item.name[0] === 'titulozonas' ||
                        (!item.value && item.errors.length > 0)
                    ) {
                        setTitulo(tituloDefecto);
                    }

                    if (
                        item.name[0] === 'mensajezonas' &&
                        item.value &&
                        item.errors.length === 0
                    ) {
                        setMensaje(item.value);
                    } else if (
                        item.name[0] === 'mensajezonas' ||
                        (!item.value && item.errors.length > 0)
                    ) {
                        setMensaje(mensajeDefecto);
                    }
                }
            });
        } else {
            setTitulo(tituloDefecto);
            setMensaje(mensajeDefecto);
        }
    }, [fields, tab]);

    const actionsButtonText = checked ? 'Programar Push' : 'Enviar ahora';

    const showPreview = !loading ? (
        <PushVistaPrevia titulo={titulo} mensaje={mensaje} max />
    ) : (
        <div className={classes.pushPreviewContainer}>
            <LoadingOutlined className={classes.pushPreviewLoader} />
            <Text className={classes.pushPreviewText}>Enviando...</Text>
        </div>
    );

    const pushItems = [
        {
            key: '1',
            label: `Usuarios`,
            children: (
                <PushUsuarios
                    pushForm={pushForm}
                    fields={fields}
                    tab={tab}
                    obtenerSeleccion={modalConfirmarEnvioPushVisible}
                    maxCantidadCaracteresTitulo={maxCantidadCaracteresTitulo}
                    maxCantidadCaracteresMensaje={maxCantidadCaracteresMensaje}
                    titulo={titulo}
                    mensaje={mensaje}
                    date={setDate}
                    hours={setHours}
                    checked={setChecked}
                    checkedSwitch={checked}
                />
            ),
        },
        {
            key: '2',
            label: `Zonas`,
            children: (
                <PushZonas
                    pushForm={pushForm}
                    fields={fields}
                    tab={tab}
                    maxCantidadCaracteresTitulo={maxCantidadCaracteresTitulo}
                    maxCantidadCaracteresMensaje={maxCantidadCaracteresMensaje}
                    tipoZona={tipoZona}
                    setTipoZona={setTipoZona}
                    titulo={titulo}
                    mensaje={mensaje}
                    date={setDate}
                    hours={setHours}
                    checked={setChecked}
                    checkedSwitch={checked}
                />
            ),
        },
    ];
    return (
        <ABMLayout
            titulo="Enviar push"
            acciones={
                <Space>
                    <Button
                        type="secondary"
                        onClick={() => {
                            pushForm.resetFields();
                            setFields(null);
                        }}
                        className={classes.restablecerButton}>
                        Restablecer
                    </Button>
                    <Button type="primary" onClick={botonEnviarMostrarModal}>
                        {actionsButtonText}
                    </Button>
                </Space>
            }>
            {/* Tabs */}
            <Card>
                <Form
                    name="basic"
                    form={pushForm}
                    initialValues={{
                        pantalla: deeplinkOptions.home,
                        direccionesUsuario: true,
                    }}
                    layout="vertical"
                    validateMessages={{
                        required: 'Este campo es obligatorio.',
                    }}
                    onFieldsChange={(_, allFields) => setFields(allFields)}>
                    <div className="card-container">
                        <Tabs
                            type="card"
                            defaultActiveKey="1"
                            onChange={(key) => setTab(key)}
                            animated={false}
                            items={pushItems}
                        />
                    </div>
                    <Modal
                        title="Confirmar envío de mensaje push"
                        open={modalConfirmarEnvioPushVisible}
                        onOk={botonConfirmarEnvioPush}
                        onCancel={botonCancelarModal}
                        closable={false}
                        footer={[
                            <Button
                                key="back"
                                onClick={botonCancelarModal}
                                disabled={loading}>
                                Volver
                            </Button>,
                            <Button
                                key="submit"
                                type="primary"
                                loading={loading}
                                onClick={botonConfirmarEnvioPush}>
                                Confirmar envío
                            </Button>,
                        ]}>
                        {showPreview}
                    </Modal>
                </Form>
            </Card>
        </ABMLayout>
    );
};

export default Push;
