import {
    UTILIDADES_TIPOS_PAGO_LISTA,
    UTILIDADES_TIPOS_PAGO_LOADING,
    UTILITIES_ORDERS_STATES,
    UTILITIES_BRANCH_STATES,
    UTILITIES_CATEGORIES,
    UTILITIES_FRANCHISE_CATEGORIES,
} from './types';

export const initialState = {
    loadingTiposPago: false,
    listaTiposPago: [],
    listaOpcionesSelectTipoPago: [],
    ordersStates: [],
    branchStates: [],
    categories: [],
    franchiseCategoriesByFamily: [],
};

const UtilidadesReducer = (state = initialState, action) => {
    switch (action.type) {
        case UTILIDADES_TIPOS_PAGO_LISTA:
            return {
                ...state,
                listaTiposPago: action.payload.listaTiposPago,
                listaOpcionesSelectTipoPago:
                    action.payload.listaOpcionesSelectTipoPago,
                loadingTiposPago: false,
            };
        case UTILIDADES_TIPOS_PAGO_LOADING:
            return {
                ...state,
                loadingTiposPago: !state.loadingTiposPago,
            };
        case UTILITIES_ORDERS_STATES:
            return {
                ...state,
                ordersStates: action.payload.ordersStates,
            };
        case UTILITIES_BRANCH_STATES:
            return {
                ...state,
                branchStates: action.payload.branchStates,
            };
        case UTILITIES_CATEGORIES:
            return {
                ...state,
                categories: action.payload.categories,
            };
        case UTILITIES_FRANCHISE_CATEGORIES:
            return {
                ...state,
                franchiseCategoriesByFamily:
                    action.payload.franchiseCategoriesByFamily,
            };
        default:
            return state;
    }
};

export default UtilidadesReducer;
