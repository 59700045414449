import { Form, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UtilidadesContext from '../../../../../context/utilidades/UtilidadesContext';

const { Option } = Select;

const FormItemSelectTipoPago = ({
    formInstance,
    formItemProps,
    selectProps,
    disabled,
    esUsuarioComercio,
    tienePermisoVerPagoOnline,
    initialValue,
}) => {
    const [formItemInitialValue, setFormItemInitialValue] = useState(null);

    const {
        obtenerListaTiposPago,
        loadingTiposPago,
        listaOpcionesSelectTipoPago,
    } = useContext(UtilidadesContext);

    useEffect(() => {
        obtenerListaTiposPago(esUsuarioComercio, tienePermisoVerPagoOnline);
    }, []);

    useEffect(() => {
        if (!initialValue && listaOpcionesSelectTipoPago?.length) {
            if (initialValue) {
                setFormItemInitialValue({ payment_type: initialValue });
            } else {
                // Establecer valor iniciar
                setFormItemInitialValue((state) =>
                    state?.payment_type
                        ? { ...state }
                        : { payment_type: listaOpcionesSelectTipoPago[0].id },
                );
            }
        }
    }, [listaOpcionesSelectTipoPago, initialValue]);

    useEffect(() => {
        formInstance.setFieldsValue(formItemInitialValue);
    }, [formItemInitialValue]);

    return (
        <Form.Item name="payment_type" label="Tipo de pago" {...formItemProps}>
            <Select
                {...selectProps}
                disabled={disabled || loadingTiposPago}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }>
                {listaOpcionesSelectTipoPago &&
                    listaOpcionesSelectTipoPago.map((item) => (
                        <Option key={item?.id} value={item?.id}>
                            {item?.name}
                        </Option>
                    ))}
            </Select>
        </Form.Item>
    );
};

export default FormItemSelectTipoPago;

FormItemSelectTipoPago.propTypes = {
    formItemProps: PropTypes.any,
    selectProps: PropTypes.any,
    formInstance: PropTypes.any,
    disabled: PropTypes.bool,
    esUsuarioComercio: PropTypes.bool,
    tienePermisoVerPagoOnline: PropTypes.bool,
    initialValue: PropTypes.object,
};
