import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import copy from 'copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import classes from './Acciones1.module.css';
import Button from 'components/atoms/Button';
import PropTypes from 'prop-types';
import { differenceInMinutes } from 'date-fns';
import { Tooltip } from '../../../../../node_modules/antd/lib/index';

const EnviarWhatsapp = ({
    nombreSucursal,
    fechaPedido,
    numeroOrden,
    usuario,
}) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const nombre = localStorage.getItem('nombre');
    const [timeAgo, setTimeAgo] = useState('');

    useEffect(() => {
        const intervalId = setInterval(() => {
            const fechaPedidoUTC = new Date(fechaPedido); // Parsea la fecha en UTC
            const fechaLocal = new Date(); // Fecha local
            const offsetMinutes = fechaLocal.getTimezoneOffset(); // Se obtiene el desfase horario local en minutos
            const fechaUTC = new Date(
                fechaLocal.getTime() - offsetMinutes * 60 * 1000,
            ); // Convierte a fecha UTC

            const minutesDifference = differenceInMinutes(
                fechaUTC,
                fechaPedidoUTC,
            );

            if (minutesDifference >= 60) {
                const hours = Math.floor(minutesDifference / 60);
                const minutes = minutesDifference % 60;
                setTimeAgo(`${hours} horas y ${minutes} minutos`);
            } else {
                setTimeAgo(`${minutesDifference} minutos`);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [fechaPedido]);

    const textToCopy = `
    Buenas ${nombreSucursal}, te saluda ${nombre} de Monchis.
    Tenes un pedido con ${timeAgo} en pendiente en el Support Monchis.
    Orden: ${numeroOrden}
    Usuario: ${usuario}
    El estado del pedido en tiempo y forma ofrece un tracking real al usuario y disminuye/evita consultas/reclamos de los mismos.
    `;

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        message.success('¡Texto copiado satisfactoriamente!');
        copy(textToCopy);
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ display: 'flex' }}>
            <Tooltip title="Copiar">
                <button onClick={showModal} className={classes.buttonCopy}>
                    <CopyOutlined style={{ fontSize: '16px' }} />
                </button>
            </Tooltip>
            <Modal
                title={
                    <>
                        <span className={classes.modalTitle}>
                            Aviso de orden con Atraso
                        </span>
                    </>
                }
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={[
                    <Button
                        key="cancel"
                        onClick={handleCancel}
                        className={classes.cancelarModal}>
                        CANCELAR
                    </Button>,
                    <Button
                        key="ok"
                        type="primary"
                        onClick={handleOk}
                        className={classes.ButtonVerPedido}>
                        COPIAR
                    </Button>,
                ]}>
                <pre className={classes.Aviso}>{textToCopy}</pre>
            </Modal>
        </div>
    );
};

export default EnviarWhatsapp;

EnviarWhatsapp.propTypes = {
    nombreSucursal: PropTypes.string,
    fechaPedido: PropTypes.string,
    numeroOrden: PropTypes.number,
    usuario: PropTypes.string,
};
