import { useEffect, useState, useCallback, useContext } from 'react';
import { urls } from '../../constants';
import axios from 'axios';
import moment from 'moment';

import { message } from 'antd';
import authContext from '../../context/autenticacion/authContext';

const useFranchiseHistoryAudits = () => {
    const franchiseAuditWS = `${urls.REACT_APP_BACKEND_URL_C}/get_last_audits?`;
    const { token } = useContext(authContext);
    const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
    const [selectedFranchiseName, setSelectedFranchiseName] = useState();
    const [selectedBranchId, setSelectedBranchId] = useState();
    const [loadingFranchiseHistory, setLoadingFranchiseHistory] = useState();
    const [obtainedFranchiseHistoryData, setObtainedFranchiseHistoryData] =
        useState([]);

    const viewHistoryModal = (record) => {
        setIsHistoryModalOpen(true);
        setSelectedFranchiseName(record.branch);
        setSelectedBranchId(record.branchId);
    };
    const closeViewHistoryModal = () => {
        setIsHistoryModalOpen(false);
        setSelectedBranchId();
        setObtainedFranchiseHistoryData([]);
    };
    const getFranchiseHistoryApi = useCallback(
        async (branchId) => {
            axios.defaults.headers.common['Authorization'] = token;
            try {
                const response = await axios.get(
                    `${franchiseAuditWS}limit=10&branch_ids=${branchId}`,
                );
                return response.data?.data;
            } catch (error) {
                const parsedMessage =
                    error?.response?.data?.message ||
                    error?.message ||
                    'Hubo un problema al volver a generar el historial para esta franquicia';
                message.error(parsedMessage);
                return null;
            }
        },
        [token, franchiseAuditWS],
    );
    useEffect(() => {
        const fetchData = async () => {
            setLoadingFranchiseHistory(true);
            if (selectedBranchId) {
                const desiredValues = [
                    'datetime',
                    'new_state',
                    'user_name',
                    'tipo_baja',
                    'motivo',
                ];
                const extractedData = await getFranchiseHistoryApi(
                    selectedBranchId,
                );
                const extractedObject = extractedData?.last_states;
                const filteredTableValues = extractedObject.map((obj) => {
                    let filteredObj = {};

                    desiredValues.forEach((value) => {
                        if (obj.hasOwnProperty.call(obj, value)) {
                            filteredObj[value] = obj[value];
                        }
                    });

                    const {
                        datetime,
                        new_state: newState,
                        user_name,
                        tipo_baja,
                        motivo,
                    } = filteredObj;

                    const formattedDateTime = moment
                        .utc(datetime)
                        .format('DD/MM/YYYY [a las] HH:mm:ss');

                    filteredObj = {
                        date_time: formattedDateTime,
                        current_state:
                            newState === true ? 'Habilitado' : 'Deshabilitado',
                        disable_type: tipo_baja,
                        reason: motivo,
                        responsable: user_name,
                    };

                    return filteredObj;
                });
                setObtainedFranchiseHistoryData(filteredTableValues);
            }
            setLoadingFranchiseHistory(false);
        };
        fetchData();
    }, [getFranchiseHistoryApi, selectedBranchId]);

    return {
        closeViewHistoryModal,
        isHistoryModalOpen,
        loadingFranchiseHistory,
        obtainedFranchiseHistoryData,
        selectedFranchiseName,
        viewHistoryModal,
    };
};
export default useFranchiseHistoryAudits;
