import React, { useReducer, useState } from 'react';
import ClientesContext from './ClientesContext';
import clienteAxios from '../../../config/axios';
import { urls } from '../../../constants/index';
import ClientesReducer, { initialState } from './ClientesReducer';
import {
    CLIENTES_TOTALES,
    OBTENER_LISTADO_CLIENTES,
    PAGINADO_CLIENTES,
} from './types';
import PropTypes from 'prop-types';
import { message } from 'antd';

const baseURL = urls.REACT_APP_BACKEND_URL_F;

const ClientesState = ({ children }) => {
    const [state, dispatch] = useReducer(ClientesReducer, initialState);
    const [loadingTabla, setLoadingTabla] = useState(false);

    const obtenerClientes = async (datos) => {
        setLoadingTabla(true);
        try {
            const { data } = await clienteAxios.get(
                '/api/users/confirm-phone-faileds',
                { params: datos, baseURL },
            );
            dispatch({
                type: OBTENER_LISTADO_CLIENTES,
                payload: data?.data?.data,
            });
            dispatch({
                type: CLIENTES_TOTALES,
                payload: data?.data?.total,
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Ocurrió un problema al obtener clientes',
            );
            setLoadingTabla(false);
        }
        setLoadingTabla(false);
    };

    const confirmarNumero = async (id) => {
        try {
            const { data } = await clienteAxios.post(
                `/api/user/${id}/phone/confirm`,
                null,
                { baseURL },
            );
            message.success(data?.message);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Ocurrió un problema al confirmar número',
            );
        }
    };

    const revalidarNumero = async (id) => {
        let datos = {
            user_id: id,
        };
        try {
            const { data } = await clienteAxios.post(
                '/api/users/restore-attempts',
                datos,
                { baseURL },
            );
            message.success(data?.data?.data);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Ocurrió un problema al reestablecer intentos',
            );
        }
    };

    const seleccionarPaginadoTabla = async (page) => {
        dispatch({
            type: PAGINADO_CLIENTES,
            payload: page,
        });
    };
    return (
        <ClientesContext.Provider
            value={{
                obtenerClientes,
                seleccionarPaginadoTabla,
                revalidarNumero,
                confirmarNumero,
                loadingTabla,

                listaClientes: state.listaClientes,
                totalClientes: state.totalClientes,
                paginaActualClientes: state.paginaActualClientes,
            }}>
            {children}
        </ClientesContext.Provider>
    );
};

export default ClientesState;

ClientesState.propTypes = {
    children: PropTypes.node,
};
