import React from 'react';
import { Typography, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import classes from './Integraciones.module.css';

import { LEVEL_TAGS } from 'constants/tags';
import Receip from 'assets/svg/Receip';

const { Text } = Typography;

const DatosCliente = ({ nombre, id, razonSocial, RUC, level }) => {
    const idCliente = `ID: ${id}`;
    const datosContribuyente = (
        <div>
            <div className={classes.datosContri}>{idCliente}</div>
            <div>Razón Social: {razonSocial}</div>
            <div>RUC: {RUC}</div>
        </div>
    );

    const LevelTag = () => {
        let levelComponent = null;
        // Obtener roles del localStorage
        const storedRoles = JSON.parse(localStorage.getItem('role')) || {};
        const admins = [1, 22, 10];
        // Verificar si el usuario tiene el rol de soporteMonchisAdm o superadmin
        if (admins.indexOf(storedRoles) !== -1) {
            levelComponent = LEVEL_TAGS[level];
        }
        return levelComponent ? levelComponent() : null;
    };

    return (
        <>
            {!razonSocial ? (
                <Tooltip placement="topLeft" title={idCliente}>
                    <Text className={classes.TextNombre}>
                        {nombre}
                        <Tooltip title="Nivel Monchis">
                            <span style={{ marginLeft: '10px' }}>
                                {LevelTag()}
                            </span>
                        </Tooltip>
                    </Text>
                </Tooltip>
            ) : (
                <div className={classes.nombrediv}>
                    <Text className={classes.nombre}>
                        {nombre}
                        <Tooltip title="Nivel Monchis">
                            <span style={{ marginLeft: '10px' }}>
                                {LevelTag()}
                            </span>
                        </Tooltip>
                    </Text>
                    <span style={{ cursor: 'pointer' }}>
                        <Tooltip title={datosContribuyente}>
                            <Receip
                                razonSocial={razonSocial}
                                RUC={RUC}
                                id={id}
                            />
                        </Tooltip>
                    </span>
                </div>
            )}
        </>
    );
};

DatosCliente.propTypes = {
    nombre: PropTypes.string,
    id: PropTypes.any,
    level: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    solicitoFactura: PropTypes.bool,
    razonSocial: PropTypes.string,
    RUC: PropTypes.any,
};

export default React.memo(DatosCliente);
