import {
    FACTURAS_TOTALES,
    OBTENER_LISTADO_EMISION_FACTURAS,
    SELECCIONAR_PAGINA_FACTURA,
    LOADING_NOTIFICACION,
    RECIBOS_OBTENER_LISTA,
    RECIBOS_LISTA_LOADING,
    RECIBOS_MODAL_LOADING,
} from './index';

export const initialState = {
    listadoFacturas: [],
    paginaActualFactura: 1,
    totalFacturas: 0,
    loadingNotificacion: false,
    recibosLista: [],
    recibosTotalPaginas: 1,
    recibosListaLoading: false,
    recibosModalLoading: false,
};

const EmisionDocumentosReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_LISTADO_EMISION_FACTURAS:
            return {
                ...state,
                listadoFacturas: action.payload,
            };
        case SELECCIONAR_PAGINA_FACTURA:
            return {
                ...state,
                paginaActualFactura: action.payload,
            };
        case FACTURAS_TOTALES:
            return {
                ...state,
                totalFacturas: action.payload,
            };

        case LOADING_NOTIFICACION:
            return {
                ...state,
                loadingNotificacion: !state.loadingNotificacion,
            };

        case RECIBOS_OBTENER_LISTA:
            return {
                ...state,
                recibosLista: action.payload.recibosLista,
                recibosTotalPaginas: action.payload.recibosTotalPaginas,
            };
        case RECIBOS_LISTA_LOADING:
            return {
                ...state,
                recibosListaLoading: !state.recibosListaLoading,
            };
        case RECIBOS_MODAL_LOADING:
            return {
                ...state,
                recibosModalLoading: !state.recibosModalLoading,
            };

        default:
            return state;
    }
};

export default EmisionDocumentosReducer;
