import { useCallback, useState } from 'react';
import useSocket from '../../useSocket';
import { message } from 'antd';
import useParseCsvToJson from '../../useParseCsvToJson';
import clienteAxios from '../../../config/axios';
import { urls } from '../../../constants';

const uploadBaseURL = urls.REACT_APP_BACKEND_URL_C;

const uploadCSVKey = 'uploadCSV';

const useUpdateProducts = () => {
    const [userUploadedCSV, setUserUploadedCSV] = useState({
        productQuantity: 0,
        jsonFile: null,
    });

    const { connectSocket, disconnectSocket } = useSocket();
    const { convertCsvToJson } = useParseCsvToJson();

    const onSocketMessage = (socketMessage) => {
        const objResponse = JSON.parse(socketMessage.data);

        disconnectSocketHandler();

        if (!objResponse.success) {
            message.error({
                content:
                    'Hubo un problema al procesar tus actualizaciones: ' +
                    objResponse.message,
            });
            return;
        }

        message.success({
            content:
                'Procesamos tus actualizaciones con éxito. ' +
                objResponse.message,
        });
    };

    const onSocketError = (error) => {
        const parsedError = `Hubo un problema con la conexion a la subida del archivo: ${
            error?.response?.data?.message || error?.message
        }`;
        message.error(parsedError);
        disconnectSocketHandler();
    };

    const parseUserFileToCSV = async (userUploadedFile) => {
        // Parse to JSON
        let jsonFile;
        try {
            jsonFile = await convertCsvToJson(userUploadedFile);
        } catch (error) {
            message.error('Hubo un problema al convertir tu archivo.');
            return false;
        }

        // Validate file format
        // 1. Length
        if (!jsonFile || !jsonFile?.length) {
            message.warning('Tu archivo esta vacío.');
            return false;
        }
        // 2. Header
        // 2.1 Expected info
        const expectedHeader = [
            'id',
            'internalcode',
            'barcode',
            'productname',
            'productdescription',
            'productprice',
            'category',
            'franchise_id',
            'enabled',
            'deleted',
        ];
        const expectedHeaderLength = expectedHeader.length;

        // 2.2 First row extract from dataset
        const jsonFileExtract = jsonFile[0];
        const extractKeys = Object.keys(jsonFileExtract);

        // 2.3 Validation
        if (
            extractKeys.length !== expectedHeaderLength ||
            extractKeys.toString() !== expectedHeader.toString()
        ) {
            message.warning('Tu archivo no tiene el formato correcto.');
            return false;
        }
        // 2.4 Remove header from dataset
        jsonFile.shift();

        // Store dataset
        setUserUploadedCSV({
            productQuantity: jsonFile.length,
            jsonFile: jsonFile,
        });

        // Connect to socket
        connectSocket(false, onSocketMessage, onSocketError);

        return true;
    };

    const uploadCSV = async () => {
        message.info({
            content: 'Cargando listado...',
            key: uploadCSVKey,
            duration: 0,
        });

        let productDataArray = [];

        userUploadedCSV.jsonFile?.forEach((item) => {
            const formattedFile = {
                id: item.id,
                name: item.productname,
                description: item.productdescription,
                price: Number(item.productprice),
                category_name: item.category,
                franchise_id: Number(item.franchise_id),
                barcode: item.barcode,
                internal_code: item.internalcode,
                enabled: item.enabled === 'SI' ? true : false,
                deleted: item.deleted === 'SI' ? true : false,
            };
            productDataArray.push(formattedFile);
        });

        const body = {
            csv: productDataArray,
        };

        try {
            await clienteAxios.put(`${uploadBaseURL}/products_csv`, body);
        } catch (error) {
            const parsedError = `Hubo un problema al intentar cargar el archivo: ${
                error?.response?.data?.message || error?.message
            }`;
            message.error(parsedError);
        }
    };

    const disconnectSocketHandler = useCallback(() => {
        message.destroy(uploadCSVKey);
        disconnectSocket();
    }, [disconnectSocket]);

    return { userUploadedCSV, parseUserFileToCSV, uploadCSV };
};

export default useUpdateProducts;
