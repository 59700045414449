import {
    OBTENER_LISTADO_DETALLE_COMISIONES,
    SELECCIONAR_PAGINA_DETALLE_COMISION,
    TOTAL_DETALLE_COMISION,
} from './index';

export const initialState = {
    listadoDetalleComisiones: [],
    paginaActualDetalleComisiones: 1,
    totalDetalleComisiones: 0,
};

const DetalleComisionesReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_LISTADO_DETALLE_COMISIONES:
            return {
                ...state,
                listadoDetalleComisiones: action.payload,
            };
        case SELECCIONAR_PAGINA_DETALLE_COMISION:
            return {
                ...state,
                paginaActualDetalleComisiones: action.payload,
            };
        case TOTAL_DETALLE_COMISION:
            return {
                ...state,
                totalDetalleComisiones: action.payload,
            };

        default:
            return state;
    }
};

export default DetalleComisionesReducer;
