export const SUPPORT_CAMBIAR_PAGINA = 'SUPPORT_CAMBIAR_PAGINA';
export const SUPPORT_LOADING = 'SUPPORT_LOADING';
export const SUPPORT_CONNECT_WEBSOCKET = 'SUPPORT_CONNECT_WEBSOCKET';

export const LISTA_PERFILES = 'LISTA_PERFILES';

export const SUPPORT_SOLICITAR_DRIVER = 'SUPPORT_SOLICITAR_DRIVER';
export const SUPPORT_CERRAR_MODAL_SOLICITAR_DRIVER =
    'SUPPORT_CERRAR_MODAL_SOLICITAR_DRIVER';
export const SUPPORT_LOADING_SOLICITAR_DRIVER =
    'SUPPORT_LOADING_SOLICITAR_DRIVER';

export const SUPPORT_REVERTIR_PAGO = 'SUPPORT_REVERTIR_PAGO';
export const SUPPORT_CERRAR_MODAL_REVERTIR_PAGO =
    'SUPPORT_CERRAR_MODAL_REVERTIR_PAGO';
export const SUPPORT_LOADING_REVERTIR_PAGO = 'SUPPORT_LOADING_REVERTIR_PAGO';
export const SUPPORT_ACTUALIZAR_ORDEN_REVERTIR_PAGO =
    'SUPPORT_ACTUALIZAR_ORDEN_REVERTIR_PAGO';

export const SUPPORT_LOADING_CANCELAR_ORDEN = 'SUPPORT_LOADING_CANCELAR_ORDEN';
export const SUPPORT_CANCELAR_ORDEN = 'SUPPORT_CANCELAR_ORDEN';
export const SUPPORT_CERRAR_MODAL_CANCELAR_ORDEN =
    'SUPPORT_CERRAR_MODAL_CANCELAR_ORDEN';

export const SUPPORT_CAMBIAR_ESTADO = 'SUPPORT_CAMBIAR_ESTADO';
export const SUPPORT_CERRAR_MODAL_CAMBIAR_ESTADO =
    'SUPPORT_CERRAR_MODAL_CAMBIAR_ESTADO';
export const SUPPORT_LOADING_CAMBIAR_ESTADO = 'SUPPORT_LOADING_CAMBIAR_ESTADO';

export const SUPPORT_REVERTIR_CANCELACION = 'SUPPORT_REVERTIR_CANCELACION';
export const SUPPORT_CERRAR_MODAL_REVERTIR_CANCELACION =
    'SUPPORT_CERRAR_MODAL_REVERTIR_CANCELACION';
export const SUPPORT_LOADING_REVERTIR_CANCELACION =
    'SUPPORT_LOADING_REVERTIR_CANCELACION';

export const SUPPORT_LOADING_IMPRIMIR_ORDEN = 'SUPPORT_LOADING_IMPRIMIR_ORDEN';

export const SUPPORT_VER_ORDEN = 'SUPPORT_VER_ORDEN';
export const SUPPORT_CERRAR_MODAL_VER_ORDEN = 'SUPPORT_CERRAR_MODAL_VER_ORDEN';

export const SUPPORT_CERRAR_MODAL_ACEPTAR_AGENDADO =
    'SUPPORT_CERRAR_MODAL_ACEPTAR_AGENDADO';
export const SUPPORT_ABRIR_MODAL_ACEPTAR_AGENDADO =
    'SUPPORT_ABRIR_MODAL_ACEPTAR_AGENDADO';

export const SUPPORT_CERRAR_MODAL_RECHAZAR_AGENDADO =
    'SUPPORT_CERRAR_MODAL_RECHAZAR_AGENDADO';
export const SUPPORT_ABRIR_MODAL_RECHAZAR_AGENDADO =
    'SUPPORT_ABRIR_MODAL_RECHAZAR_AGENDADO';

export const SUPPORT_CONFIRMAR_AGENDADO_LOADING =
    'SUPPORT_CONFIRMAR_AGENDADO_LOADING';
export const SUPPORT_RECHAZAR_AGENDADO_LOADING =
    'SUPPORT_RECHAZAR_AGENDADO_LOADING';

export const SUPPORT_VERIFICAR_DIRECCION = 'SUPPORT_VERIFICAR_DIRECCION';
export const SUPPORT_CERRAR_MODAL_VERIFICAR_DIRECCION =
    'SUPPORT_CERRAR_MODAL_VERIFICAR_DIRECCION';
export const SUPPORT_LOADING_VERIFICAR_DIRECCION =
    'SUPPORT_LOADING_VERIFICAR_DIRECCION';
export const SUPPORT_ACTUALIZAR_VERIFICAR_DIRECCION =
    'SUPPORT_ACTUALIZAR_VERIFICAR_DIRECCION';

export const SUPPORT_VACIAR_TABLA_BUSQUEDA = 'SUPPORT_VACIAR_TABLA_BUSQUEDA';

export const SUPPORT_OBTENER_ORDENES_BUSQUEDA =
    'SUPPORT_OBTENER_ORDENES_BUSQUEDA';

export const SUPPORT_ABRIR_MODAL_ENVIAR_INTEGRACION =
    'SUPPORT_ABRIR_MODAL_ENVIAR_INTEGRACION';
export const SUPPORT_ABRIR_MODAL_ENVIAR_JEDI =
    'SUPPORT_ABRIR_MODAL_ENVIAR_JEDI';
export const SUPPORT_CERRAR_MODAL_ENVIAR_INTEGRACION =
    'SUPPORT_CERRAR_MODAL_ENVIAR_INTEGRACION';
export const SUPPORT_CERRAR_MODAL_ENVIAR_JEDI =
    'SUPPORT_CERRAR_MODAL_ENVIAR_JEDI';
export const SUPPORT_OBTENER_LISTA_PRODUCTOS_INTEGRACION_JEDI =
    'SUPPORT_OBTENER_LISTA_PRODUCTOS_INTEGRACION_JEDI';
export const SUPPORT_ABRIR_MODAL_ESTADO_JEDI =
    'SUPPORT_ABRIR_MODAL_ESTADO_JEDI';
export const SUPPORT_CERRAR_MODAL_ESTADO_JEDI =
    'SUPPORT_CERRAR_MODAL_ESTADO_JEDI';
export const SUPPORT_OBTENER_DETALLES_ESTADO_JEDI =
    'SUPPORT_OBTENER_DETALLES_ESTADO_JEDI';
export const SUPPORT_LOADING_INTEGRACIONES = 'SUPPORT_LOADING_INTEGRACIONES';
export const SUPPORT_DESHABILITAR_BOTON_INTEGRACION =
    'SUPPORT_DESHABILITAR_BOTON_INTEGRACION';
export const SUPPORT_FRANQUICIA_CON_INTEGRACION =
    'SUPPORT_FRANQUICIA_CON_INTEGRACION';
export const SUPPORT_ACTUALIZAR_ORDEN_INTEGRACION =
    'SUPPORT_ACTUALIZAR_ORDEN_INTEGRACION';

export const SUPPORT_ACTUALIZAR_ORDEN_DRIVER_SOLICITADO =
    'SUPPORT_ACTUALIZAR_ORDEN_DRIVER_SOLICITADO';
export const SUPPORT_ABRIR_MODAL_ORDENES_SELECCIONADAS =
    'SUPPORT_ABRIR_MODAL_ORDENES_SELECCIONADAS';
export const SUPPORT_CERRAR_MODAL_CONFIRMACION_JEDI =
    'SUPPORT_CERRAR_MODAL_CONFIRMACION_JEDI';
export const SUPPORT_ABRIR_MODAL_CONFIRMACION_JEDI =
    'SUPPORT_ABRIR_MODAL_CONFIRMACION_JEDI';

export const SUPPORT_ACTUALIZAR_TIPO_USUARIO =
    'SUPPORT_ACTUALIZAR_TIPO_USUARIO';

export const SUPPORT_LOADING_PRINT_ORDER = 'SUPPORT_LOADING_PRINT_ORDER';

export const SUPPORT_HAS_PERMITION_TO_CHANGE_STATE =
    'SUPPORT_HAS_PERMITION_TO_CHANGE_STATE';
