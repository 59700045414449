import React from 'react';
import { DatePicker } from 'antd';
import 'antd/dist/antd.css';

const { RangePicker } = DatePicker;

const datePicker = (props) => {
    return (
        <RangePicker
            style={{ borderRadius: 10 }}
            placeholder={['Fecha Inicio', 'Fecha Fin']}
            {...props}
        />
    );
};

export const rangePickerAntd = (props) => {
    return (
        <RangePicker
            style={{ borderRadius: 10, width: '100%' }}
            placeholder={['Fecha Inicio', 'Fecha Fin']}
            {...props}
        />
    );
};

export const datePickerAntd = (props) => {
    return (
        <DatePicker
            style={{ borderRadius: 10, width: '100%' }}
            placeholder="Fecha Inicio"
            {...props}
        />
    );
};

export default datePicker;
