import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Tabs, Card } from 'antd';

import CuentaUsuario from './Cuenta/CuentaUsuario';
import DatosContribuyentesUsuario from './DatosContribuyentes/DatosContribuyentesUsuario';
import DireccionesUsuario from './Direcciones/DireccionesUsuario';
import HeaderUsuario from './HeaderUsuario/HeaderUsuario';
import PedidosUsuarios from './Pedidos/PedidosUsuarios';
import CuponesUsuario from './Cupones/CuponesUsuario';
import TarjetasUsuario from './TarjetasUsuario/index';
import UsuariosContext from '../../../../context/usuario/UsuariosContext';
import Analitica from './Analitica/Analitica';
import ABMLayout from '../../../../layouts/ABMLayout';

const { TabPane } = Tabs;

const DetallesUsuarios = () => {
    // loader de la cabecera y "cuenta"
    const location = useLocation();
    const {
        usuarioSeleccionado,
        recuperarDatosSessionStorage,
        cambiarTabSeleccionada,
        tabSeleccionada,
        pathname,
        setPathname,
        guardarInfoUsuario,
    } = useContext(UsuariosContext);
    const PATH_NAME = window.location.pathname;

    useEffect(() => {
        setPathname(PATH_NAME);
        pathname !== '/usuarios/detalles' && cambiarTabSeleccionada('1');
    }, [PATH_NAME]);

    useEffect(() => {
        if (usuarioSeleccionado?.id) {
            const searchString = location.search.substring(
                1,
                location.search.length + 1,
            );
            const searchParams = new URLSearchParams(searchString);
            const orderId = searchParams.get('orders');
            if (orderId) {
                cambiarTabSeleccionada('6');
            }
        }
    }, [location, usuarioSeleccionado]);

    useEffect(() => {
        if (usuarioSeleccionado?.id) {
            guardarInfoUsuario();
        }
    }, [usuarioSeleccionado]);

    useEffect(() => {
        if (!usuarioSeleccionado?.id) {
            recuperarDatosSessionStorage();
        }
    }, []);

    const history = useHistory();

    const btnVolverHandler = () => {
        history.goBack();
    };

    return (
        <ABMLayout
            titulo="Detalles"
            acciones={
                <Button onClick={btnVolverHandler}>Volver a la lista</Button>
            }>
            {usuarioSeleccionado?.id && (
                <Card>
                    {/* header secundario (datos basicos del usuario) */}
                    <HeaderUsuario />
                    <Tabs
                        defaultActiveKey="1"
                        animated={false}
                        destroyInactiveTabPane
                        activeKey={tabSeleccionada}
                        onChange={(tab) => cambiarTabSeleccionada(tab)}>
                        <TabPane tab="Cuenta" key="1">
                            <CuentaUsuario />
                        </TabPane>
                        <TabPane tab="Direcciones" key="2">
                            <DireccionesUsuario />
                        </TabPane>
                        <TabPane tab="Tarjetas" key="3">
                            <TarjetasUsuario />
                        </TabPane>
                        {/* <TabPane tab="Cupones & Puntos" key="4">
                                <FormCuponPunto />
                            </TabPane> */}
                        <TabPane tab="Datos contribuyentes" key="5">
                            <DatosContribuyentesUsuario />
                        </TabPane>
                        <TabPane tab="Pedidos" key="6">
                            <PedidosUsuarios />
                        </TabPane>
                        <TabPane tab="Cupones" key="7">
                            <CuponesUsuario />
                        </TabPane>
                        <TabPane tab="Analíticas" key="8">
                            <Analitica />
                        </TabPane>
                    </Tabs>
                </Card>
            )}
        </ABMLayout>
    );
};

export default DetallesUsuarios;
