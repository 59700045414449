import React from 'react';
import { Card, Form, Select, Space, Typography } from 'antd';
import PushZonaCollapse from 'components/molecules/Push/PushZonaCollapse';
import PropTypes from 'prop-types';
import styles from './SelectedAudience.module.css';
import useGetAudience from 'components/hooks/ProgramaRecompensa/Promociones/useGetAudience';

const SelectedAudience = ({
    form,
    setCantidadUsuariosSeleccionados,
    tipoZona,
    setTipoZona,
    setRestablecerDeeplink,
    setAlcanceLoading,
    coverageZone,
    coordenates,
    disabled,
    handleZoneValidation,
}) => {
    const { Title } = Typography;
    const { Option } = Select;
    const { audience } = useGetAudience();

    const handleAudienceChange = (value) => {
        handleZoneValidation(value !== undefined);
    };

    return (
        <Card
            type="inner"
            title={
                <span>
                    <span className={styles.requiredTick}>* </span>
                    Mostrar promo a público seleccionado{' '}
                </span>
            }>
            <Space direction="vertical" className={styles.space}>
                <Title level={5}>Mostrar por audiencia</Title>
                <Form.Item label="Seleccionar audiencia" name="audience">
                    <Select
                        disabled={disabled}
                        className={styles.select}
                        onChange={handleAudienceChange}
                        placeholder="Seleccionar">
                        {audience?.map((audience) => (
                            <Option key={audience?.id} value={audience?.id}>
                                {audience?.name}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
            </Space>
            <Space direction="vertical" className={styles.space}>
                <Title level={5}>Mostrar por zona</Title>
                <PushZonaCollapse
                    pushForm={form}
                    setCantidadUsuariosSeleccionados={
                        setCantidadUsuariosSeleccionados
                    }
                    tipoZona={tipoZona}
                    setTipoZona={setTipoZona}
                    setRestablecerDeeplink={setRestablecerDeeplink}
                    setAlcanceLoading={setAlcanceLoading}
                    coverageZone={coverageZone}
                    coordenates={coordenates}
                    disabled={disabled}
                    handleZoneValidation={handleZoneValidation}
                />
            </Space>
        </Card>
    );
};

SelectedAudience.propTypes = {
    form: PropTypes.object,
    fields: PropTypes.array,
    setCantidadUsuariosSeleccionados: PropTypes.func,
    tipoZona: PropTypes.string,
    setTipoZona: PropTypes.func,
    setRestablecerDeeplink: PropTypes.any,
    setAlcanceLoading: PropTypes.any,
    coverageZone: PropTypes.array,
    coordenates: PropTypes.array,
    disabled: PropTypes.bool,
    handleZoneValidation: PropTypes.func,
};

export default SelectedAudience;
