const LISTA_TEMAS = [
    {
        nombre: 'Red',
        color: '#f21e4c',
        image: 'red.png',
    },
    {
        nombre: 'Black',
        color: '#333333',
        image: 'black.png',
    },
    {
        nombre: 'Golden',
        color: '#FDF1DB',
        image: 'golden.png',
    },
];

export default LISTA_TEMAS;
