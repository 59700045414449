import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';
import useSupport from '../../../../hooks/support/useSupport';

const AceptarAgendadoModal = ({
    ordenSeleccionada,
    isAceptarAgendadoModalVisible,
}) => {
    const {
        cerrarAceptarAgendado,
        confirmarAceptarAgendado,
        aceptarAgendadoLoading,
    } = useSupport();
    let aceptarAgendadoModal;

    aceptarAgendadoModal = (
        <Modal
            open={isAceptarAgendadoModalVisible}
            footer={null}
            closable={null}>
            <ModalContent
                title={`Desea ACEPTAR el agendamiento de la orden #${ordenSeleccionada?.id} ?`}
                okText="Confirmar"
                cancelText="Cancelar"
                onOk={() => confirmarAceptarAgendado(ordenSeleccionada)}
                onCancel={() => cerrarAceptarAgendado()}
                isLoading={aceptarAgendadoLoading}
            />
        </Modal>
    );

    return <>{aceptarAgendadoModal}</>;
};

AceptarAgendadoModal.propTypes = {
    ordenSeleccionada: PropTypes.any,
    isAceptarAgendadoModalVisible: PropTypes.any,
};

export default AceptarAgendadoModal;
