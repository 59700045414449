import {
    AUTH_INICIO,
    AUTH_FALLO,
    AUTH_EXITO,
    AUTH_REDIRECT_PATH,
    CERRAR_SESION,
} from '../../types';

export const initialState = {
    token: null,
    permisos: null,
    id: null,
    error: null,
    usuario: null,
    nombre: null,
    apellido: null,
    email: null,
    loading: false,
    autenticado: false,
    redirectPath: '/',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTH_INICIO:
            return {
                ...state,
                error: null,
                loading: true,
            };
        case AUTH_EXITO:
            return {
                ...state,
                token: action.payload.token,
                permisos: action.payload.permisos,
                id: action.payload.id,
                autenticado: true,
                error: null,
                loading: false,
                nombre: action.payload.nombre,
                apellido: action.payload.apellido,
            };
        case AUTH_FALLO:
            return {
                ...state,
                token: null,
                permisos: null,
                error: action.payload.mensajeError,
                usuario: null,
                autenticado: null,
                loading: false,
            };
        case AUTH_REDIRECT_PATH:
            return {
                ...state,
                redirectPath: action.payload.redirectPath,
            };
        case CERRAR_SESION:
            return {
                ...state,
                token: null,
                permisos: null,
                error: null,
                usuario: null,
                autenticado: null,
                loading: false,
                redirectPath: '/',
            };

        default:
            return state;
    }
};

export default reducer;
