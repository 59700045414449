import useObtenerDatosCupones from '../../../../hooks/useObtenerDatosCupones';
import { formatoFechaSinHora } from '../../../../utils-components/Date';
import { formatoNumeros } from '../../../../utils-components/Number';

const FormateoDatos = () => {
    const { datosCupones } = useObtenerDatosCupones();
    let cupones = [];

    datosCupones.forEach((cupon) => {
        const usoEnSingularOPlural = () =>
            cupon?.max_per_user > 1 ? 'usos' : 'uso';
        const cuponEnSigularOPlural = () =>
            cupon?.usage_limit > 1 ? 'cupones' : 'cupón';
        const monto = () => {
            return formatoNumeros(cupon?.amount).length > 3
                ? `${formatoNumeros(cupon?.amount)} Gs.`
                : `${formatoNumeros(cupon?.amount)}%`;
        };

        cupones.push({
            id: cupon?.id,
            key: cupon?.id,
            codigo: cupon?.code,
            inicio: formatoFechaSinHora(cupon?.valid_from),
            fin: formatoFechaSinHora(cupon?.valid_to),
            estado: cupon?.state,
            monto: monto(),
            condiciones: [
                `${cupon?.usage_limit} ${cuponEnSigularOPlural()} - ${
                    cupon?.max_per_user
                } ${usoEnSingularOPlural()} por persona`,
                `${
                    cupon?.discount_code_details
                        ? 'ver condiciones'
                        : 'sin condiciones'
                }`,
            ],
            motivo: cupon?.reason ? cupon?.reason : '-',
            creador:
                cupon?.createdby?.first_name && cupon?.createdby?.last_name
                    ? `${cupon?.createdby?.first_name} ${cupon?.createdby?.last_name}`
                    : '-',
            detalles: cupon?.discount_code_details,
            auto_aplicable: cupon?.self_applicable,
            delivery_gratis: cupon?.free_delivery,
            monto_minimo: formatoNumeros(cupon?.minimum_amount),
            zona: cupon?.discount_code_details?.geo_zone,
            cupon_por_usuario: cupon?.usage_limit,
            id_de_ordenes: cupon?.orders_id,
        });
    });

    return cupones;
};

export default FormateoDatos;
