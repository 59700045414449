import React from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
const { Option } = Select;

const ConfirmModalContent = ({ estado }) => (
    <>
        <div>
            <p>Esta acción hara lo siguiente:</p>
            <ul>
                <li>Cambiará el estado de Pendiente a Facturado</li>
            </ul>
        </div>
        <div>
            <p>Enviar una notificación vía SMS y correo</p>
            <Select
                disabled={estado === 'pendiente' && false}
                style={{ width: '50%' }}
                defaultValue={false}>
                <Option value={false}>No</Option>
                <Option value={true}>Si</Option>
            </Select>
        </div>
    </>
);

ConfirmModalContent.propTypes = {
    estado: PropTypes.string,
};

export default ConfirmModalContent;
