import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Select } from 'antd';

import useFranchiseAndBranchStatus from '../../../hooks/useFranchiseAndBranchStatus';
import ABMLayout from '../../../../layouts/ABMLayout';
import { Divider, Space } from '../../../../../node_modules/antd/lib/index';
import MatchearModal from './MatchearModal';
import { useHistory } from 'react-router-dom';

const Matchear = () => {
    const brandsHook = useFranchiseAndBranchStatus();
    const history = useHistory();
    const [form] = Form.useForm();
    const [modalMatchear, setmodalMatchear] = useState(false);

    const handleCancelMatchear = () => {
        setmodalMatchear(false);
    };

    const volverListado = () => {
        history.push('/administrar/integraciones/sucursales');
    };

    return (
        <ABMLayout
            titulo="Matchear Sucursal"
            acciones={
                <Space>
                    <Button
                        type="link"
                        style={{ color: '#3F81F7' }}
                        onClick={() => volverListado()}>
                        Volver al listado
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setmodalMatchear(true)}>
                        Matchear
                    </Button>
                </Space>
            }
            loading={brandsHook.loadingBrands}>
            <Card>
                <strong>
                    <h3>Franquicia:</h3>
                </strong>
                <Divider></Divider>
                <div className="mt36">
                    <Form form={form} layout="vertical">
                        <Row gutter={12} align="middle">
                            <Col span={5} style={{ marginLeft: '50px' }}>
                                <Form.Item label="Sucursal">
                                    <label>Fernando Sur</label>
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item
                                    label="Sucursal Monchis"
                                    name="refineSearch">
                                    <Select
                                        allowClear
                                        optionFilterProp="children"
                                        placeholder="Seleccionar de la lista"></Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Card>
            <MatchearModal
                modalMatchear={modalMatchear}
                handleCancelMatchear={handleCancelMatchear}
            />
        </ABMLayout>
    );
};

export default Matchear;
