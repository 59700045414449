import React, { useReducer } from 'react';

import PrefContext from './prefContext';
import PrefReducer, { initialState } from './prefReducer';
import PropTypes from 'prop-types';

import { PREF_ABRIR_CERRAR_SIDEBAR } from './prefTypes';

const PrefState = ({ children }) => {
    // definir el reducer
    const [state, dispatch] = useReducer(PrefReducer, initialState);

    // Autenticar usuario
    const abrirCerrarSidebar = (isSidebarAbierto) => {
        dispatch({
            type: PREF_ABRIR_CERRAR_SIDEBAR,
            payload: {
                isSidebarAbierto,
            },
        });
    };

    return (
        <PrefContext.Provider
            value={{
                isSidebarAbierto: state.isSidebarAbierto,

                abrirCerrarSidebar,
            }}>
            {children}
        </PrefContext.Provider>
    );
};

PrefState.propTypes = {
    children: PropTypes.any,
};

export default PrefState;
