import { useState } from 'react';
import { message } from '../../../node_modules/antd/lib/index';
import clienteAxios from 'config/axios';
import { urls } from 'constants/index';
import moment from 'moment';

const useOrdersReport = () => {
    const [ordersTable, setOrdersTable] = useState();
    const [loadingOrders, setLoadingOrders] = useState();
    const [franchiseList, setFranchiseList] = useState();
    const [orderStatesList, setorderStatesList] = useState();
    const [deliveryTypes, setDeliveryTypes] = useState();
    const [paymentTypes, setPaymentTypes] = useState();
    const [summaryData, setSummaryData] = useState();
    const baseUrl = urls.REACT_APP_BACKEND_URL_B;

    const getOrdersList = async (params) => {
        setLoadingOrders(true);
        const searchParams = {
            date_start: moment(params?.dates[0]).format('YYYY/MM/DD'),
            date_end: moment(params?.dates[1]).format('YYYY/MM/DD'),
            user_id: params?.clientID,
            order_states: params?.orderState,
            payment_type: params?.paymentType,
            delivery_type: params?.deliveryType,
            orders_id: params?.orderNumber,
            franchise_id: params?.franchiseId,
            branch_id: params?.branchId,
            page: params?.page || 1,
        };

        try {
            const response = await clienteAxios.get('get_orders', {
                params: searchParams,
                baseURL: baseUrl,
            });
            const table = {
                rows: response?.data?.data?.orders,
                page: searchParams?.page,
                pageSize: response?.data?.data?.items_per_page,
                total: response?.data?.data?.total,
            };
            setOrdersTable(table);
            setSummaryData({
                count: response?.data?.data?.count,
                total: response?.data?.data?.orders?.total,
            });
            const total = response?.data?.data?.orders.map((item) =>
                Number(item.total),
            );
            const totalSum = total.reduce((acc, curr) => acc + curr, 0);
            setSummaryData({
                count: response?.data?.data?.count,
                total: totalSum,
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al obtener listado, vuelva a intentarlo',
            );
        }
        setLoadingOrders(false);
    };

    const getFranchises = async () => {
        setLoadingOrders(true);
        let arrFranchises = [];
        try {
            const response = await clienteAxios.get('/franchises_list');
            arrFranchises = response?.data?.data?.franchises?.map((item) => ({
                ...item,
                key: item.id,
            }));
        } catch (error) {
            console.error(error?.response?.data?.message || error);
        }
        setFranchiseList(arrFranchises);
        setLoadingOrders(false);
    };

    const getOrdersStates = async () => {
        let orderStates = {};
        let param = { axiosBaseURL: baseUrl };
        try {
            const respuesta = await clienteAxios.get('/get_order_states', {
                params: param,
            });
            const states = respuesta.data?.data;
            orderStates = [{ id: 0, name: 'Todos', key: 0 }, ...states];
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
        setorderStatesList(orderStates);
    };

    const getDeliveryTypes = async () => {
        let param = { axiosBaseURL: baseUrl };
        let deliveryTypesList = {};
        try {
            const respuesta = await clienteAxios.get('/get_delivery_types', {
                params: param,
            });
            const deliveryTypes = respuesta.data?.data;
            deliveryTypesList = [
                { id: 0, type_name: 'Todos' },
                ...deliveryTypes,
            ];
        } catch (error) {
            message.error(error?.response?.data?.message);
        }
        setDeliveryTypes(deliveryTypesList);
    };

    const getPaymentTypes = async () => {
        let param = { axiosBaseURL: baseUrl };
        let dataset;
        try {
            const response = await clienteAxios.get('get_payment_types', {
                params: param,
            });
            dataset = response?.data.data;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de tipos de pago',
            );
        }
        setPaymentTypes(dataset);
    };

    return {
        ordersTable,
        loadingOrders,
        franchiseList,
        orderStatesList,
        deliveryTypes,
        paymentTypes,
        summaryData,

        getOrdersList,
        getFranchises,
        getOrdersStates,
        getDeliveryTypes,
        getPaymentTypes,
    };
};

export default useOrdersReport;
