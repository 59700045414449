import {
    OBTENER_USUARIOS,
    BUSCA_USUARIO_CUPON,
    OBTENER_ID_USUARIO_CUPON,
    GUARDAR_LISTA_USUARIOS_SELECCIONADOS,
    USUARIOS_LISTA_ELIMINAR,
    OBTENER_MOTIVOS,
    RESTABLECER_ID_USUARIO_CUPON,
} from '../../../types';

import {
    CUPONES_LISTA_LOADING,
    CUPONES_LISTA,
    CUPONES_ESTABLECER_SELECCIONADO,
    CUPONES_LOADING_FORM,
    CUPONES_TIPOS_PAGO_LOADING,
    CUPONES_TIPOS_PAGO_LISTA,
    CUPONES_TARJETAS_LOADING,
    CUPONES_TARJETAS_LISTA,
    CUPONS_CUSTOMIZED_NOTIFICATIONS,
} from './Types';

export const initialState = {
    listaCupones: [],
    loadingListaCupones: false,
    cantidadTotalListaCupones: 0,
    cuponSeleccionado: undefined,
    loadingFormCupones: false,

    cupones: [],

    usuarios: [],
    obtenerIdUsuarioCupon: [],
    obtenerIdCupon: '',
    usuariosSeleccionados: [],
    usuariosRemover: [],
    obtenerMotivosSelect: [],

    loadingTiposPago: false,
    listaTiposPagos: [],

    listaTarjetas: [],
    tarjetasLoading: false,

    customizedNotification: false,
};

const CuponesReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUPONES_LISTA_LOADING: {
            return {
                ...state,
                loadingListaCupones: !state.loadingListaCupones,
            };
        }
        case CUPONES_LISTA: {
            return {
                ...state,
                listaCupones: action.payload.listaCupones,
                cantidadTotalListaCupones:
                    action.payload.cantidadTotalListaCupones,
                loadingListaCupones: false,
            };
        }
        case CUPONES_ESTABLECER_SELECCIONADO: {
            return {
                ...state,
                cuponSeleccionado: action.payload.cuponSeleccionado,
            };
        }
        case CUPONES_LOADING_FORM: {
            return {
                ...state,
                loadingFormCupones: !state.loadingFormCupones,
            };
        }

        case OBTENER_MOTIVOS:
            return {
                ...state,
                obtenerMotivosSelect: action.payload,
            };

        // Lista de usuarios Form Item
        case OBTENER_USUARIOS:
            return {
                ...state,
                usuarios: action.payload,
            };
        case BUSCA_USUARIO_CUPON:
            return {
                ...state,
                usuarios: state.usuarios.filter(
                    (usuario) =>
                        usuario.nombreCompleto
                            .toLowerCase()
                            .includes(action.payload.toLowerCase()) ||
                        usuario.idUser
                            .toString()
                            .includes(action.payload.toString()),
                ),
            };
        case OBTENER_ID_USUARIO_CUPON: {
            return {
                ...state,
                obtenerIdUsuarioCupon: [
                    ...state.obtenerIdUsuarioCupon,
                    ...action.payload,
                ],
            };
        }
        case RESTABLECER_ID_USUARIO_CUPON: {
            return {
                ...state,
                obtenerIdUsuarioCupon: [],
                usuariosSeleccionados: [],
                usuarios: [],
                usuariosRemover: [],
            };
        }
        case GUARDAR_LISTA_USUARIOS_SELECCIONADOS:
            return {
                ...state,
                usuariosSeleccionados: action.payload,
            };
        case USUARIOS_LISTA_ELIMINAR:
            return {
                ...state,
                usuariosRemover: [...state.usuariosRemover, action.payload],
                obtenerIdUsuarioCupon: state.obtenerIdUsuarioCupon.filter(
                    (idUser) => idUser !== action.payload,
                ),
            };
        case CUPONES_TIPOS_PAGO_LOADING:
            return {
                ...state,
                tiposPagoLoading: !state.loadingTiposPago,
            };
        case CUPONES_TIPOS_PAGO_LISTA:
            return {
                ...state,
                listaTiposPagos: action.payload.listaTiposPagos,
                tiposPagoLoading: false,
            };
        case CUPONES_TARJETAS_LOADING:
            return {
                ...state,
                tarjetasLoading: !state.tarjetasLoading,
            };
        case CUPONES_TARJETAS_LISTA:
            return {
                ...state,
                listaTarjetas: action.payload.listaTarjetas,
                tarjetasLoading: false,
            };

        case CUPONS_CUSTOMIZED_NOTIFICATIONS:
            return {
                ...state,
                customizedNotification: action.payload.customizedNotification,
            };

        default:
            return state;
    }
};

export default CuponesReducer;
