export const USER_LEVEL_LOADING = 'USER_LEVEL_LOADING';
export const USER_LEVEL_LIST = 'USER_LEVEL_LIST';
export const USER_LEVEL_SHOW_DETAIL = 'USER_LEVEL_SHOW_DETAIL';
export const USER_LEVEL_HIDE_DETAIL = 'USER_LEVEL_HIDE_DETAIL';
export const USER_LEVEL_CLOSE_EDIT = 'USER_LEVEL_CLOSE_EDIT';
export const USER_LEVEL_SET_SELECTED_USER_LEVEL =
    'USER_LEVEL_SET_SELECTED_USER_LEVEL';

export const USER_LEVEL_REWARDS_LOADING = 'USER_LEVEL_REWARDS_LOADING';
export const USER_LEVEL_REWARDS_LIST = 'USER_LEVEL_REWARDS_LIST';
