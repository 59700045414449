import {
    MonchisRed,
    MonchisBlack,
    MonchisGold,
    MonchisSilver,
    Gold,
    Black,
    Silver,
} from 'components/atoms/tags/tags';

export const LEVEL_TAGS = {
    'Monchis Red': MonchisRed,
    'Monchis Black': MonchisBlack,
    'Monchis Gold': MonchisGold,
    'Monchis Silver': MonchisSilver,
    Gold: Gold,
    Black: Black,
    Silver: Silver,
};
