// inputs
export const formatoFechaInput = 'DD/MM/YYYY';

/**
 * Formatea fechas del tipo 2021-03-29T10:45:00.000Z
 */
export const formatoFecha = (unformatedDate) => {
    let dateformat = '';
    const dateTimeArray = unformatedDate?.split('T');
    if (dateTimeArray?.length > 1) {
        const date = dateTimeArray[0];
        const timeArray = dateTimeArray[1].split('.');
        if (timeArray?.length > 1) {
            const time = timeArray[0];
            dateformat = `${date} ${time}`;
        }
    }
    return dateformat;
};

export const formatoHora = (unformatedDate) => {
    let dateFormat = '';
    const dateTimeArray = unformatedDate?.split('T');
    if (dateTimeArray?.length > 1) {
        const timeArray = dateTimeArray[1].split('.');
        if (timeArray?.length > 1) {
            dateFormat = timeArray[0];
        }
    }
    return dateFormat;
};

export const formatoFechaSinHora = (unformatedDate) => {
    let dateformat = '';
    const dateTimeArray = unformatedDate?.split('T');
    if (dateTimeArray?.length > 1) {
        const date = dateTimeArray[0];
        if (date?.length > 1) {
            dateformat = `${reverse(date)}`;
        }
    }
    return dateformat;
};

export const formatoFechaSinHoraConGuion = (unformatedDate) => {
    let dateformat = '';
    const dateTimeArray = unformatedDate?.split('T');
    if (dateTimeArray?.length > 1) {
        const date = dateTimeArray[0];
        if (date?.length > 1) {
            dateformat = date;
        }
    }
    return dateformat;
};

function reverse(s) {
    return s.split('-').reverse().join('/');
}

export const formatoFechaLocal = (dateUTCZero) => {
    let dateformat = '';
    const dateTimeArray = dateUTCZero?.split('T');
    if (dateTimeArray?.length > 1) {
        const date = dateTimeArray[0];
        const time = dateTimeArray[1];
        const yearMonthDaysArray = date.split('-');
        const hoursMinsSecsArray = time.split(':');
        if (yearMonthDaysArray.length > 2 && hoursMinsSecsArray.length > 2) {
            const year = yearMonthDaysArray[0];
            const month = `${parseInt(yearMonthDaysArray[1], 10) - 1}`;
            const days = yearMonthDaysArray[2];
            const hours = hoursMinsSecsArray[0];
            const mins = hoursMinsSecsArray[1];
            const secsArray = hoursMinsSecsArray[2].split('.');
            if (secsArray?.length > 1) {
                const secs = secsArray[0];
                const date = new Date(
                    new Date(year, month, days).setHours(hours, mins, secs) +
                        new Date().getTimezoneOffset() * -1 * 60 * 1000,
                );
                dateformat =
                    [
                        date.getFullYear(),
                        `${date.getMonth() + 1}`.length > 1
                            ? date.getMonth() + 1
                            : '0' + (date.getMonth() + 1),
                        `${date.getDate()}`.length > 1
                            ? date.getDate()
                            : '0' + date.getDate(),
                    ].join('-') +
                    ' ' +
                    [
                        `${date.getHours()}`.length > 1
                            ? date.getHours()
                            : '0' + date.getHours(),
                        `${date.getMinutes()}`.length > 1
                            ? date.getMinutes()
                            : '0' + date.getMinutes(),
                        `${date.getSeconds()}`.length > 1
                            ? date.getSeconds()
                            : '0' + date.getSeconds(),
                    ].join(':');
            }
        }
    }
    return dateformat;
};

// Devuelve la fecha de hoy:
export const getTodaysDate = () => {
    return new Date().toLocaleDateString();
};

// Duevuelve formato DD/MM/AAAA:

export const dateDDMMYYYY = (date) => {
    return new Date(date).toLocaleDateString('es-PY', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    });
};

// Devuelve fecha con formato DD/MM/AAAA y hora:
export const dateDMYyHora = (date) => {
    const fecha = new Date(date).toLocaleDateString('es-PY', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    });
    const hora = new Date(date).toLocaleTimeString('es-PY');
    return `${fecha} ${hora}`;
};

/**
 * Formatea fechas del tipo 2021-03-29T10:45:00.000Z
 */
export const getOnlyHourFromADate = (date) => {
    return date.slice(11, 19);
};

export const getTheDayFromADate = (date) => {
    const weekday = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];

    const completeDate = new Date(date);
    const day = weekday[completeDate.getDay()];
    return day;
};
