import React from 'react';
import { Typography, Row, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import classes from './Local.module.css';
import { DELIVERY_TAGS, PAYMENT_TAGS } from 'constants/tags';

const { Title } = Typography;

const Local = ({
    nombreLocal,
    tipoPago,
    tipoEntrega,
    codigoOnline,
    telefonoLocal,
}) => {
    const PaymentTag = PAYMENT_TAGS[tipoPago];
    const DeliveryTag = DELIVERY_TAGS[tipoEntrega];

    const PaymentTagComponent = PaymentTag ? (
        <PaymentTag codigoPagoOnline={codigoOnline} />
    ) : null;

    const DeliveryTagComponent = DeliveryTag ? <DeliveryTag /> : null;

    return (
        <Tooltip placement="topLeft" title={telefonoLocal}>
            <div className={classes.divTitle}>
                <Title level={4} className={classes.nombreLocal}>
                    {nombreLocal}
                </Title>
                <Row>
                    {PaymentTagComponent}
                    {DeliveryTagComponent}
                </Row>
            </div>
        </Tooltip>
    );
};

Local.propTypes = {
    nombreLocal: PropTypes.any,
    tipoPago: PropTypes.any,
    tipoEntrega: PropTypes.any,
    codigoOnline: PropTypes.any,
    telefonoLocal: PropTypes.any,
    tienePermiso: PropTypes.bool,
};

export default React.memo(Local);
