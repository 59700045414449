import React, { useContext, useEffect, useState } from 'react';
import { Col, Divider, Empty, message, Row, Spin } from 'antd';
import { urls } from '../../../../../constants';
import clienteAxios from '../../../../../config/axios';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import CardDias from '../../../../molecules/User360/Analiticas/CardDias';
import CardDirecciones from '../../../../molecules/User360/Analiticas/CardDirecciones';
import CardGraficoCircular from '../../../../molecules/User360/Analiticas/CardGraficoCircular';
import CardHoras from '../../../../molecules/User360/Analiticas/CardHoras';
import CardProductosMasComprados from '../../../../molecules/User360/Analiticas/CardProductosMasComprados';
import CardTotalVsPromedio from '../../../../molecules/User360/Analiticas/CardTotalVsPromedio';

const Analitica = () => {
    const { usuarioSeleccionado } = useContext(UsuariosContext);
    const [topComercios, setTopComercios] = useState([]);
    const [topProductos, setTopProductos] = useState([]);
    const [topDirecciones, setTopDirecciones] = useState([]);
    const [topDias, setTopDias] = useState([]);
    const [topHorarios, setTopHorarios] = useState([]);
    const [loader, setLoader] = useState();

    useEffect(() => {
        obtenerDatosAnalitica();
    }, []);

    const obtenerDatosAnalitica = async () => {
        setLoader(true);
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_E,
        };
        try {
            const api = await clienteAxios.get(
                `/get_user_analytics/${usuarioSeleccionado?.id}`,
                { params },
            );
            const respuesta = api?.data?.data;
            setTopComercios(respuesta?.top_franchises);
            setTopProductos(respuesta?.top_products);
            setTopDirecciones(respuesta?.top_addresses);
            setTopDias(respuesta?.requests_by_day);
            setTopHorarios(respuesta?.requests_by_hour);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    error?.message ||
                    'Hubo un error al obtener los datos',
            );
        }
        setLoader(false);
    };

    return (
        <>
            {topComercios?.length ? (
                <>
                    <Spin spinning={loader} style={{ border: '0px' }}>
                        <Divider orientation="left">
                            Comercios con mayor nivel de compra
                        </Divider>
                        <Row style={{ display: 'flex', flexWrap: 'nowrap' }}>
                            <CardTotalVsPromedio
                                datosComercios={topComercios}
                            />
                            <CardGraficoCircular
                                datosComercios={topComercios}
                            />
                        </Row>
                        <Divider orientation="left">
                            Productos más comprados
                        </Divider>
                        <CardProductosMasComprados
                            datosProductosTop10={topProductos}
                        />
                        <Row
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginTop: '4rem',
                            }}>
                            <Col>
                                <Divider orientation="left">
                                    Direcciones más utilizadas
                                </Divider>
                                <CardDirecciones
                                    datosDireccionesTop3={topDirecciones}
                                />
                            </Col>
                            <Col>
                                <Divider orientation="left">
                                    Días de mayor compra
                                </Divider>
                                <CardDias datosDiasMayorCompra={topDias} />
                            </Col>
                            <Col>
                                <Divider orientation="left">
                                    Horarios de mayor compra
                                </Divider>
                                <CardHoras
                                    datosHorarioMayorCompra={topHorarios}
                                />
                            </Col>
                        </Row>
                    </Spin>
                </>
            ) : (
                <Spin spinning={loader} style={{ border: '0px' }}>
                    <Empty
                        description={<span>Aún no registra compras</span>}
                        style={{ padding: '6rem 0' }}
                    />
                </Spin>
            )}
        </>
    );
};

export default Analitica;
