import { message } from 'antd';
import clienteAxios from 'config/axios';
import { useState } from 'react';

export const useTransactionsDetails = () => {
    const [datosCsv, setdatosCsv] = useState([]);
    const [datosCanceladoCsv, setdatosCanceladoCsv] = useState([]);
    const [datosNoProcesadosCsv, setdatosNoProcesadosCsv] = useState([]);
    const [sucursales, setSucursales] = useState([]);

    // consultas para las transacciones
    const transaccionDetailDescarga = async (params) => {
        try {
            const resp = await clienteAxios.get(
                '/transaction_details_download',
                { params },
            );
            setdatosCsv(resp?.data?.data?.link_transaction_details);
        } catch (error) {
            console.log(error);
        }
    };

    // consultas para transacciones canceladas
    const transaccionCancelados = async (date) => {
        const params = {
            dateStart: date?.dateStart,
            dateEnd: date?.dateEnd,
        };
        try {
            const resp = await clienteAxios.get('canceled_transactions', {
                params,
            });
            setdatosCanceladoCsv(resp?.data?.data?.link_canceled_transactions);
        } catch (error) {
            console.log(error);
        }
    };

    // consultas para transacciones no procesadas
    const transaccionNoProcesados = async (date) => {
        const params = {
            dateStart: date?.dateStart,
            dateEnd: date?.dateEnd,
        };
        try {
            const resp = await clienteAxios.get('unprocessed_transactions', {
                params,
            });
            setdatosNoProcesadosCsv(
                resp?.data?.data?.link_unprocessed_transactions,
            );
        } catch (error) {
            console.log(error);
        }
    };

    // consultas para obtener las sucursales de la franquicia seleccionada
    const obtenerSucursales = async (franchiseId) => {
        const params = { franchise_id: franchiseId.toString() || undefined };
        try {
            const respuesta = await clienteAxios.get('/branches', { params });
            setSucursales(respuesta?.data?.data?.branches);
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de las sucursales',
            );
        }
    };

    return {
        transaccionDetailDescarga,
        transaccionCancelados,
        transaccionNoProcesados,
        obtenerSucursales,

        datosCsv,
        datosCanceladoCsv,
        datosNoProcesadosCsv,
        sucursales,
    };
};
