import React, { useContext, useEffect, useState } from 'react';
import {
    Row,
    Button,
    Table,
    Select,
    DatePicker,
    Form,
    Col,
    Input,
    message,
    // Divider,
    Card,
    // Typography,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import classes from './VerReporteCupones/reporteCupones.module.css';
import { useHistory } from 'react-router-dom';
import ReporteCuponesContext from '../../../../context/reportes/reporteCupones/ReporteCuponesContext';
import CuponesContext from '../../../../context/programaRecompensa/cupones/CuponesContext';
import { formatoFechaSinHora } from '../../../utils-components/Date';
import {
    formatoGuaranies,
    // formatoNumeros,
} from '../../../utils-components/Number';
import clienteAxios from '../../../../config/axios';
import { urls } from '../../../../constants';
import ABMLayout from '../../../../layouts/ABMLayout';

// const { Text } = Typography;

const { RangePicker } = DatePicker;
const { Option } = Select;

const baseURL = urls.REACT_APP_BACKEND_URL_F;

const ReporteCupones = () => {
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [idUsuario, setIdUsuario] = useState('');
    const [obtenerValoresForm, setObtenerValoresForm] = useState({});
    // const [mostrarResumen, setMostrarResumen] = useState(false);
    const [loadingExel, setLoadingExcel] = useState(false);
    const {
        obtenerCupones,
        cuponSeleccionado,
        listaReporteCupones,
        loading,
        seleccionarPaginadoTabla,
        totalReporte,
        // resumenReporte,
        paginaActualReporte,
    } = useContext(ReporteCuponesContext);
    const { obtenerMotivos, obtenerMotivosSelect } = useContext(CuponesContext);
    const history = useHistory();
    const [reporteCuponesForm] = Form.useForm();

    useEffect(() => {
        obtenerMotivos();
    }, []);

    useEffect(() => {
        if (!fechaDesde && !fechaHasta) return;
        let sgtePagina = {
            ...obtenerValoresForm,
            page: paginaActualReporte,
        };
        obtenerCupones(sgtePagina);
    }, [paginaActualReporte]);

    const VerReporteCupones = (row) => {
        cuponSeleccionado(row);
        history.push('/reportes/programa-recompensa/cupones/detalle');
    };

    const handleSubmit = (valores) => {
        let datos = {
            date_start: valores?.periodoValidez[0].format('MM-DD-YYYY'),
            date_end: valores?.periodoValidez[1].format('MM-DD-YYYY'),
            user_id: valores?.idUsuario || undefined,
            items_per_page: 15,
            page: 1,
            code: valores?.codigoCupon || undefined,
            reason_id: valores?.motivoCupon || undefined,
        };
        obtenerCupones(datos);
        setObtenerValoresForm(datos);
        seleccionarPaginadoTabla(1);
        // setMostrarResumen(true);
    };

    const descargarExcel = async () => {
        let datos = {};
        if (fechaDesde && fechaHasta) {
            datos = {
                date_start: fechaDesde,
                date_end: fechaHasta,
                user_id: idUsuario || undefined,
                export_to: 'excel',
            };
        }
        setLoadingExcel(true);
        const cerrar = message.loading('Descargando excel', 0);
        try {
            const { data } = await clienteAxios.get('/discounts', {
                params: datos,
                baseURL,
            });
            window.open(data?.data?.fileUrl);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'hubo un error al obtener los datos',
            );
            setLoadingExcel(false);
        }
        setLoadingExcel(false);
        setTimeout(cerrar, loadingExel);
    };

    const rangoFechas = (valor) => {
        let fechadesde = null;
        let fechahasta = null;
        if (valor !== null) {
            if (valor.length > 0) {
                fechadesde = valor[0].format('MM-DD-YYYY');
                fechahasta = valor[1].format('MM-DD-YYYY');
            }
        }
        setFechaDesde(fechadesde);
        setFechaHasta(fechahasta);
    };

    const filtroIdUsuario = (valor) => {
        setIdUsuario(valor.target.value);
    };

    // const emitidos = totalReporte?.total_creados;
    // const usados = resumenReporte?.cantidad_usados;
    // const gastado = resumenReporte?.total_gastado;
    // const totalComision = resumenReporte?.total_comision;

    // const resumen = (
    //     <Card>
    //         <Row className={classes.cardResumen}>
    //             <Col className={classes.colsResumen}>
    //                 <Text strong className={classes.emitidos}>
    //                     {(emitidos && formatoNumeros(Number(emitidos))) || '-'}
    //                 </Text>
    //                 <Text>{emitidos > 1 ? 'cupones' : 'cupón'}</Text>
    //                 <Text>{emitidos > 1 ? 'emitidos' : 'emitido'}</Text>
    //             </Col>
    //             <Divider type="vertical" className={classes.dividers} />
    //             <Col className={classes.colsResumen}>
    //                 <Text strong className={classes.usados}>
    //                     {(usados && formatoNumeros(Number(usados))) || '-'}
    //                 </Text>
    //                 <Text>{usados > 1 ? 'cupones' : 'cupón'}</Text>
    //                 <Text>{usados > 1 ? 'usados' : 'usado'}</Text>
    //             </Col>
    //             <Divider type="vertical" className={classes.dividers} />
    //             <Col className={classes.colsResumen}>
    //                 <Text strong className={classes.gastado}>
    //                     {(gastado && formatoGuaranies(Number(gastado))) || '-'}
    //                 </Text>
    //                 <Text>Monto total</Text>
    //                 <Text>gastado</Text>
    //             </Col>
    //             <Divider type="vertical" className={classes.dividers} />
    //             <Col className={classes.colsResumen}>
    //                 <Text strong className={classes.comision}>
    //                     {(totalComision &&
    //                         formatoGuaranies(Number(totalComision))) ||
    //                         '-'}
    //                 </Text>
    //                 <Text>Monto de</Text>
    //                 <Text>comisión</Text>
    //             </Col>
    //         </Row>
    //     </Card>
    // );

    const reporteCuponesTabla = listaReporteCupones.map((cupon) => {
        const {
            user_id,
            fecha_pedido,
            estado,
            usuario,
            total_orden,
            comision_orden,
            monto_descuento,
            codigo_descuento,
            franquicia,
            motivo,
            gastado_antes,
            comision_antes,
            total_gastado_despues,
            total_comision_despues,
        } = cupon;
        return {
            fecha: formatoFechaSinHora(fecha_pedido),
            usuarioId: user_id,
            usuario,
            codigoDescuento: codigo_descuento,
            montoDescuento: formatoGuaranies(monto_descuento),
            montoTotalPedido: formatoGuaranies(total_orden),
            franquicia,
            motivo,
            gastadoAntes: formatoGuaranies(gastado_antes),
            comisionAntes: formatoGuaranies(comision_antes),
            totalGastadoDespues: formatoGuaranies(total_gastado_despues),
            totalComisionDespues: formatoGuaranies(total_comision_despues),
            comisionOrden: formatoGuaranies(comision_orden),
            estado,
        };
    });

    const changePage = (page) => {
        seleccionarPaginadoTabla(page);
    };

    const columns = [
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
        },
        {
            title: 'ID usuario',
            dataIndex: 'usuarioId',
            key: 'usuarioId',
            width: '6rem',
        },
        {
            title: 'Usuario',
            dataIndex: 'usuario',
            key: 'usuario',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },
        {
            title: 'Código de descuento',
            dataIndex: 'codigoDescuento',
            key: 'codigoDescuento',
        },
        {
            title: 'Motivo',
            dataIndex: 'motivo',
            key: 'motivo',
        },
        {
            title: 'Monto de descuento',
            dataIndex: 'montoDescuento',
            key: 'montoDescuento',
        },
        {
            title: 'Monto total del pedido',
            dataIndex: 'montoTotalPedido',
            key: 'montoTotalPedido',
        },
        {
            title: '',
            dataIndex: 'acciones',
            key: 'acciones',
            width: '3rem',
            render: (_, elm) => (
                <div className={classes.divDetalles}>
                    <SearchOutlined onClick={() => VerReporteCupones(elm)} />
                </div>
            ),
        },
    ];

    return (
        <ABMLayout titulo="Reporte de cupones" loading={loading}>
            <Card className={classes.cardReporteCupones}>
                <Form
                    layout="vertical"
                    form={reporteCuponesForm}
                    onFinish={handleSubmit}>
                    <Row gutter={[16, 16]}>
                        <Col span={6}>
                            <Form.Item
                                name="periodoValidez"
                                label="Rango de fechas"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo no puede ir vacío',
                                    },
                                ]}>
                                <RangePicker
                                    onChange={rangoFechas}
                                    className={classes.rangoFechas}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="motivoCupon" label="Motivo">
                                <Select
                                    allowClear
                                    placeholder="Seleccione un motivo">
                                    {obtenerMotivosSelect &&
                                        obtenerMotivosSelect?.map((motivo) => (
                                            <Option
                                                key={motivo.id}
                                                value={motivo.id}>
                                                {motivo.name}
                                            </Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="codigoCupon" label="Código">
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item name="idUsuario" label="Id de usuario">
                                <Input onChange={filtroIdUsuario} />
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item>
                                <Button
                                    className={classes.marginBotones}
                                    type="primary"
                                    disabled={!(fechaDesde && fechaHasta)}
                                    htmlType="submit">
                                    Buscar
                                </Button>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Button
                                    className={classes.marginBotones}
                                    type="link"
                                    disabled={!(fechaDesde && fechaHasta)}
                                    onClick={descargarExcel}>
                                    Exportar Excel
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                {/* Resumen de cupones TODO: conexión a nuevo endpoint. Aguardamos que la tarea NA-1107 se finalice. */}
                {/* {mostrarResumen ? resumen : <></>} */}
                <Row>
                    <Col span={24}>
                        <Table
                            className="tablaCupones"
                            dataSource={reporteCuponesTabla}
                            columns={columns}
                            rowKey={columns.id}
                            pagination={{
                                pageSize: 15,
                                total: totalReporte,
                                showSizeChanger: false,
                                current: paginaActualReporte,
                                onChange: (page) => changePage(page),
                            }}
                            size="small"
                            tableLayout="fixed"
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ReporteCupones;
