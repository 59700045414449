import { formatoGuaranies } from '../../utils-components/Number';
import React from 'react';
const FinalPrice = ({ ordenOriginal }) => {
    const discount = ordenOriginal?.discount;
    const costAssumedByFranchise = discount?.franchise_assume_cost;
    const finalPriceWithDiscount = formatoGuaranies(
        Number(discount?.total_with_discount || 0),
    );
    const finalPriceWithoutDiscount = formatoGuaranies(
        Number(ordenOriginal?.total) + Number(ordenOriginal?.delivery_price) ||
            0,
    );
    const resultado = () => {
        return (
            <div>
                {costAssumedByFranchise
                    ? finalPriceWithDiscount
                    : finalPriceWithoutDiscount}
            </div>
        );
    };
    return resultado();
};

export default FinalPrice;
