import React from 'react';
import { useLocation } from 'react-router-dom';

import ProductosTabLayout from 'layouts/ProductosTabLayout/index';

const ListaProductos = () => {
    const location = useLocation();

    return (
        <ProductosTabLayout
            title="Productos"
            currentTab={location?.pathname}></ProductosTabLayout>
    );
};

export default ListaProductos;
