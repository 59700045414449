import React, { useContext, useEffect, useState } from 'react';
import ABMLayout from '../../../../../../layouts/ABMLayout';
import {
    Button,
    Card,
    Row,
    Col,
    Space,
    Form,
    Select,
    DatePicker,
    Spin,
    Switch,
    message,
    InputNumber,
} from 'antd';
import clienteAxios from '../../../../../../config/axios';
import { useHistory } from 'react-router-dom';
import TimbradoContext from '../../../../../../context/facturacion/timbrado/TimbradoContext';
import moment from 'moment';

const { RangePicker } = DatePicker;

const formFields = [
    'id',
    'nro_timbrado',
    'validezfecha',
    'puntosExpedicion',
    'sucursal',
    'numeroFacturaInicio',
    'numeroFacturaFin',
    'numeroAutorizacion',
    'tipoComprobante',
    'motivo',
    'switchTimbrado',
];

const ADD = 'ADD';
const EDIT = 'EDIT';

const index = (props) => {
    const [valorSelect, setValorSelect] = useState([]);
    const [valorInicial, setValorInicial] = useState();

    const { mode = ADD, param } = props;

    // datos del context
    const { crearNuevoTimbrado, listadoTimbrados, editarTimbrado } =
        useContext(TimbradoContext);
    const [timbradoForm] = Form.useForm();
    const history = useHistory();

    const tipoComprobanteSelect = async () => {
        try {
            const resp = await clienteAxios.get('/get_voucher_types');
            const tiposComprobantes = resp.data.data.response?.voucher_types;
            setValorSelect(tiposComprobantes);
            setValorInicial({
                switchTimbrado: true,
                tipoComprobante:
                    tiposComprobantes?.length > 0 && tiposComprobantes[0].id,
            });
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        tipoComprobanteSelect();
    }, []);

    useEffect(() => {
        if (mode === EDIT) {
            const { id } = param;
            const idEdit = Number(id);
            let timbradosData = listadoTimbrados?.find(
                (timbrado) => timbrado.id === idEdit,
            );

            // unir las fechas y formatear
            let fechaFrom = timbradosData?.date_from;
            let fechaTo = timbradosData?.date_to;
            let fechaNormalizadaFrom =
                fechaFrom.substring(0, fechaFrom.indexOf('.')) +
                moment().format('Z');
            let fechaNormalizadaTo =
                fechaTo.substring(0, fechaTo.indexOf('.')) +
                moment().format('Z');
            let fechaDesde = moment(fechaNormalizadaFrom).format('YYYY-MM-DD');
            let fechaHasta = moment(fechaNormalizadaTo).format('YYYY-MM-DD');

            // completar el formulario con los datos para editar
            timbradoForm.setFieldsValue({
                id: timbradosData?.id,
                nro_timbrado: timbradosData?.stamped,
                validezfecha: [moment(fechaDesde), moment(fechaHasta)],
                puntosExpedicion: timbradosData?.expedition_point,
                sucursal: timbradosData?.branch,
                numeroFacturaInicio: timbradosData?.bill_number_start,
                numeroFacturaFin: timbradosData?.bill_number_end,
                tipoComprobante: timbradosData?.voucher_type_id,
                motivo: timbradosData?.reason,
                switchTimbrado: timbradosData?.enabled,
                numeroAutorizacion: timbradosData?.authorization_number,
            });
        }
    }, []);

    const guardarButtonHandler = async () => {
        await timbradoForm
            .validateFields(formFields)
            .then((values) => {
                setValorInicial(values);
                const fechaDesde = values.validezfecha[0];
                const fechaHasta = values.validezfecha[1];
                let valoresFormTimbrados = {
                    ...values,
                    validodesde: fechaDesde.format('YYYY-MM-DD'),
                    validohasta: fechaHasta.format('YYYY-MM-DD'),
                };
                if (mode === ADD) {
                    crearNuevoTimbrado(valoresFormTimbrados);
                    timbradoForm.resetFields();

                    //redireccionar
                    history.goBack();
                }
                if (mode === EDIT) {
                    editarTimbrado(valoresFormTimbrados);

                    //redireccionar
                    history.goBack();
                }
            })
            .catch(() =>
                message.warning('Por favor complete los campos obligatorios.'),
            );
    };

    const cancelarButtonHandler = () => {
        history.goBack();
    };

    return (
        <ABMLayout
            titulo={mode === 'ADD' ? 'Nuevo Timbrado' : 'Editar Timbrado'}
            acciones={
                <Space>
                    <Button onClick={cancelarButtonHandler}>Cancelar</Button>
                    <Button type="primary" onClick={guardarButtonHandler}>
                        Guardar
                    </Button>
                </Space>
            }>
            <Spin spinning={false}>
                {valorInicial && (
                    <Form
                        name="franquiciaForm"
                        layout="vertical"
                        form={timbradoForm}
                        initialValues={valorInicial}>
                        <Row gutter={12}>
                            <Col span={16}>
                                <Card title="Timbrado" bordered>
                                    <Row gutter={12}>
                                        <Col span={12}>
                                            <Form.Item
                                                label="Numero de timbrado"
                                                name="nro_timbrado"
                                                rules={[{ required: true }]}>
                                                <InputNumber
                                                    min={0}
                                                    maxLength={8}
                                                    style={{ width: '200px' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="switchTimbrado"
                                                label="Habilitar timbrado"
                                                valuePropName="checked">
                                                <Switch
                                                    disabled={
                                                        mode === 'EDIT'
                                                            ? false
                                                            : true
                                                    }
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col span={12}>
                                            <Form.Item
                                                name="validezfecha"
                                                label="Fecha Inicio - Fecha Fin"
                                                rules={[{ required: true }]}>
                                                <RangePicker
                                                    style={{ width: '100%' }}
                                                    placeholder={[
                                                        'Desde',
                                                        'Hasta',
                                                    ]}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Form.Item
                                                name="numeroAutorizacion"
                                                label="Numero de Autorización"
                                                rules={[{ required: true }]}>
                                                <InputNumber
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row gutter={12}>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Punto de Expedicion"
                                                name="puntosExpedicion"
                                                rules={[{ required: true }]}>
                                                <InputNumber
                                                    min={0}
                                                    maxLength={3}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Sucursal"
                                                name="sucursal"
                                                rules={[{ required: true }]}>
                                                <InputNumber
                                                    min={0}
                                                    maxLength={3}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Nro de factura inicio"
                                                name="numeroFacturaInicio"
                                                rules={[{ required: true }]}>
                                                <InputNumber
                                                    min={0}
                                                    maxLength={7}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item
                                                label="Nro de factura fin"
                                                name="numeroFacturaFin"
                                                rules={[{ required: true }]}>
                                                <InputNumber
                                                    min={0}
                                                    maxLength={7}
                                                    style={{ width: '100%' }}
                                                />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Form.Item
                                        label="Tipo de comprobante"
                                        name="tipoComprobante"
                                        rules={[{ required: true }]}>
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }
                                            placeholder="-- Seleccione motivo --">
                                            {valorSelect &&
                                                valorSelect.map((item) => (
                                                    <Select.Option
                                                        key={item.id}
                                                        value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Card>
                            </Col>

                            <Col span={8}>
                                <Card title="Motivo">
                                    <Form.Item
                                        label="Seleccione un motivo"
                                        name="motivo"
                                        rules={[{ required: true }]}>
                                        <Select placeholder="-- Seleccione comprobante --">
                                            <Select.Option
                                                value={'vencidoPorFecha'}>
                                                Vencido por fecha
                                            </Select.Option>
                                            <Select.Option value={'noHayRango'}>
                                                No hay mas rango
                                            </Select.Option>
                                            <Select.Option
                                                value={'noSePuedeFacturar'}>
                                                No se puede facturar
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Card>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Spin>
        </ABMLayout>
    );
};

export default index;
