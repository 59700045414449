const getErrorMessage = (
    error,
    alternativeMessage = 'Hubo un error en la ejecución del proceso',
) => {
    return (
        error?.response?.data?.message ||
        error?.message ||
        error ||
        error?.errorFields[0]?.errors[0] ||
        alternativeMessage
    );
};

export default getErrorMessage;
