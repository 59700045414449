import React from 'react';

function Menos() {
    return (
        <svg
            style={{ cursor: 'pointer' }}
            xmlns="http://www.w3.org/2000/svg"
            width="31"
            height="30"
            viewBox="0 0 31 30"
            fill="none">
            <path
                d="M21.2424 14.0611H10.296C10.1651 14.0611 10.058 14.1666 10.058 14.2955V15.7018C10.058 15.8307 10.1651 15.9361 10.296 15.9361H21.2424C21.3733 15.9361 21.4804 15.8307 21.4804 15.7018V14.2955C21.4804 14.1666 21.3733 14.0611 21.2424 14.0611Z"
                fill="#F11E4C"
            />
            <path
                d="M15.7695 1.875C8.41036 1.875 2.44336 7.75195 2.44336 15C2.44336 22.248 8.41036 28.125 15.7695 28.125C23.1286 28.125 29.0956 22.248 29.0956 15C29.0956 7.75195 23.1286 1.875 15.7695 1.875ZM15.7695 25.8984C9.65968 25.8984 4.70404 21.0176 4.70404 15C4.70404 8.98242 9.65968 4.10156 15.7695 4.10156C21.8792 4.10156 26.8349 8.98242 26.8349 15C26.8349 21.0176 21.8792 25.8984 15.7695 25.8984Z"
                fill="#F11E4C"
            />
        </svg>
    );
}

export default Menos;
