import React, { useContext } from 'react';
import { Avatar, Tag, Row, Col, Typography } from 'antd';
import { ShoppingOutlined, ShoppingCartOutlined } from '@ant-design/icons';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
const { Title } = Typography;
import { dateDDMMYYYY } from '../../../../utils-components/Date';

const HeaderUsuario = () => {
    const { infoUsuario } = useContext(UsuariosContext);

    return (
        <Row style={{ display: 'flex', margin: '1.5rem 0' }}>
            <Col style={{ display: 'flex', marginRight: '50px' }}>
                <Avatar size={70} src={infoUsuario?.avatar}></Avatar>
                <Col style={{ marginLeft: '15px' }}>
                    <Title level={4}>
                        {infoUsuario?.first_name} {infoUsuario?.last_name}
                    </Title>
                    <Tag>#{infoUsuario?.id}</Tag>
                </Col>
            </Col>
            <Col style={{ marginRight: '50px' }}>
                <Title level={5} style={{ fontSize: '17px', color: 'grey' }}>
                    Último pedido
                </Title>
                <Title
                    level={5}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <ShoppingOutlined
                        style={{ fontSize: '25px', marginRight: '0.5rem' }}
                    />
                    {infoUsuario?.last_order
                        ? dateDDMMYYYY(infoUsuario?.last_order)
                        : 'Nunca'}
                </Title>
            </Col>
            <Col>
                <Title level={5} style={{ fontSize: '17px', color: 'grey' }}>
                    Pedidos
                </Title>
                <Title
                    level={5}
                    style={{ display: 'flex', alignItems: 'center' }}>
                    <ShoppingCartOutlined
                        style={{ fontSize: '25px', marginRight: '0.5rem' }}
                    />
                    {infoUsuario?.order_count
                        ? infoUsuario?.order_count
                        : 'Nunca'}
                </Title>
            </Col>
            {/* <Col>
                <Title level={5} style={{fontSize: '17px', color: 'grey'}}>
                    Puntos
                </Title>
                <Title level={5}>
                    <TrophyOutlined style={{fontSize: '25px'}} />
                    3000
                </Title>
            </Col>
            <Col>
                <Title level={5} style={{fontSize: '17px', color: 'grey'}}>
                    Participación
                </Title>
                <Title level={5}>
                    <ArrowUpOutlined style={{fontSize: '25px'}} />
                    28%
                </Title>
            </Col> */}
        </Row>
    );
};

export default HeaderUsuario;
