import React from 'react';
import Picker from 'emoji-picker-react';
import PropTypes from 'prop-types';

const Emoji = ({ pickEmoji }) => {
    return (
        <Picker
            pickerStyle={{
                position: 'absolute',
                zIndex: 1,
                height: '250px',
                right: 0,
            }}
            native
            onEmojiClick={pickEmoji}
        />
    );
};

Emoji.propTypes = {
    pickEmoji: PropTypes.func,
};

export default Emoji;
