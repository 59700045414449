import React, { useState, useEffect, useContext } from 'react';
import {
    Card,
    Row,
    Col,
    Button,
    Form,
    Select,
    Upload,
    Modal,
    Typography,
    message,
    Tooltip,
} from 'antd';
import {
    PlusCircleOutlined,
    DownloadOutlined,
    UploadOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import ABMLayout from '../../../../../layouts/ABMLayout';
import actualizarProductos from './actualizarProductos.module.css';
import AdministrarContext from '../../../../../context/administrar/AdministrarContext';
import NewProductModal from '../../../../molecules/administrar/NewProductModal';
import ModalContent from '../../../../atoms/ModalContent';
import useUpdateProducts from '../../../../../hooks/administrar/productos/useUpdateProducts';

const { Text } = Typography;

const PRODUCTSTATE = {
    all: { name: 'TODOS', value: '' },
    enabled: { name: 'HABILITADOS', value: true },
    disabled: { name: 'DESHABILITADOS', value: false },
};

const SEARCHDELETED = {
    all: { name: 'TODOS', value: '' },
    deleted: { name: 'ELIMINADOS', value: true },
    nonDeleted: { name: 'NO_ELIMINADOS', value: false },
};

const ActualizarProductos = () => {
    const { getFranchiseList, franchiseSelectList, downloadProducts, loading } =
        useContext(AdministrarContext);

    const { userUploadedCSV, parseUserFileToCSV, uploadCSV } =
        useUpdateProducts();

    const [formFilters] = Form.useForm();

    const [isDownloadModalVisible, setIsDownloadModalVisible] = useState(false);
    const [newProductVisible, setNewProductVisible] = useState(false);
    const [uploadProductVisible, setUploadProductVisible] = useState(false);
    const [fileList, setFileList] = useState([]);

    const { Option } = Select;

    useEffect(() => {
        getFranchiseList();
        formFilters.resetFields();
    }, []);

    const franchiseOptions = () => {
        return (
            franchiseSelectList &&
            franchiseSelectList.map((item) => (
                <Option value={item.id} key={item.id}>
                    {item.value}
                </Option>
            ))
        );
    };

    // <--- ACTIONS --->

    const handleDownloadCSV = () => {
        formFilters.getFieldValue('franchise_id')
            ? setIsDownloadModalVisible(true)
            : message.error(
                  'Seleccioná una franquicia para descargar el listado',
              );
    };

    // Download modal
    const handleConfirmDownload = () => {
        formFilters
            .validateFields()
            .then((values) => {
                const params = { ...values };
                switch (values.enabled) {
                    case PRODUCTSTATE.all.name:
                        delete params.enabled;
                        break;
                    case PRODUCTSTATE.enabled.name:
                        params.enabled = PRODUCTSTATE.enabled.value;
                        break;
                    case PRODUCTSTATE.disabled.name:
                        params.enabled = PRODUCTSTATE.disabled.value;
                        break;
                    default:
                        break;
                }
                switch (values.deleted) {
                    case SEARCHDELETED.all.name:
                        delete params.deleted;
                        break;
                    case SEARCHDELETED.deleted.name:
                        params.deleted = SEARCHDELETED.deleted.value;
                        break;
                    case SEARCHDELETED.nonDeleted.name:
                        params.deleted = SEARCHDELETED.nonDeleted.value;
                        break;
                    default:
                        break;
                }
                params.franchise_id = params.franchise_id.join();
                downloadProducts(params);
                setIsDownloadModalVisible(false);
                formFilters.resetFields();
            })
            .catch(() => {});
    };

    const handleDownloadCancel = () => {
        setIsDownloadModalVisible(false);
    };

    // New product modal

    const openNewProductModal = () => {
        setNewProductVisible(true);
    };

    const handleCreateNewProduct = () => {
        setNewProductVisible(false);
    };

    const closeNewProductModal = () => {
        setNewProductVisible(false);
    };

    // Upload button

    const handleUploadCancel = () => {
        setUploadProductVisible(false);
    };

    const handleUpload = () => {
        uploadCSV();
        setFileList([]);
        setUploadProductVisible(false);
    };

    const uploadProps = {
        name: 'file',
        accept: '.csv',
        onRemove: () => {
            setFileList([]);
        },
        beforeUpload: (file) => {
            setFileList([file]);
            parseUserFileToCSV(file).then(
                (response) => response && setUploadProductVisible(true),
            );
            return false;
        },
        maxCount: 1,
        fileList,
        itemRender: (_originNode, file, _fileList, actions) => {
            let fileName = file.name;
            if (fileName.length > 12) {
                fileName = fileName.substring(0, 10) + '...';
            }
            return (
                <div>
                    <Tooltip title={file.name}>
                        <span>{fileName}</span>
                        <Button
                            type="link"
                            icon={<DeleteOutlined />}
                            size="small"
                            onClick={actions.remove}
                        />
                    </Tooltip>
                </div>
            );
        },
    };

    // TO-DO: AGREGAR VISUALIZACIÓN DE TABLA DE PRODUCTOS (FALTA ENDPOINT)
    // const columns = [
    //     {
    //         title: 'Franquicia',
    //         dataIndex: 'franchise',
    //         key: 'franchise',
    //     },
    //     {
    //         title: 'Producto',
    //         dataIndex: 'product',
    //         key: 'product',
    //     },
    //     {
    //         title: 'Estado',
    //         dataIndex: 'productState',
    //         key: 'productState',
    //     },
    //     {
    //         title: 'Última actualización / Responsable',
    //         dataIndex: 'updateResponsable',
    //         key: 'updateResponsable',
    //         render: (text, record) => (
    //             <p>
    //                 {record.updateDate} - {record.updateHour}hs. - <strong>{record.updateResponsable}</strong>
    //             </p>
    //         ),
    //     },
    // ];

    const uploadModalText =
        userUploadedCSV.productQuantity >= 0 &&
        userUploadedCSV.productQuantity < 100 ? (
            <Text>
                <br />
                ¿Confirmás que querés subirlo?
            </Text>
        ) : userUploadedCSV.productQuantity >= 100 &&
          userUploadedCSV.productQuantity < 500 ? (
            <Text>
                <br />
                Esto podría tardar un poco. ¿Aún así querés subirlo?
            </Text>
        ) : (
            ''
        );

    return (
        <>
            <ABMLayout
                titulo={'Actualizar productos'}
                acciones={
                    <Button
                        type="primary"
                        disabled={true}
                        icon={<PlusCircleOutlined />}
                        onClick={openNewProductModal}>
                        Nuevo producto
                    </Button>
                }>
                <Card
                    bodyStyle={{
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}>
                    <Form
                        name="formFilters"
                        form={formFilters}
                        layout="vertical"
                        disabled={loading}
                        className={actualizarProductos.formContainer}
                        initialValues={{
                            enabled: PRODUCTSTATE.all.name,
                            deleted: SEARCHDELETED.all.name,
                        }}>
                        <Row gutter={12}>
                            <Col span={6}>
                                <Form.Item
                                    label="Buscar por franquicia"
                                    name="franchise_id"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Seleccioná una franquicia',
                                        },
                                    ]}>
                                    <Select
                                        mode="multiple"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        maxTagCount={3}>
                                        {franchiseOptions()}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Seleccionar estado"
                                    name="enabled">
                                    <Select>
                                        <Option
                                            key={PRODUCTSTATE.all.name}
                                            value={PRODUCTSTATE.all.name}>
                                            Habilitados y deshabilitados
                                        </Option>
                                        <Option
                                            key={PRODUCTSTATE.enabled.name}
                                            value={PRODUCTSTATE.enabled.name}>
                                            Habilitados
                                        </Option>
                                        <Option
                                            key={PRODUCTSTATE.disabled.name}
                                            value={PRODUCTSTATE.disabled.name}>
                                            Deshabilitados
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    label="Incluir eliminados"
                                    name="deleted">
                                    <Select>
                                        <Option
                                            key={SEARCHDELETED.all.name}
                                            value={SEARCHDELETED.all.name}>
                                            Eliminados y no eliminados
                                        </Option>
                                        <Option
                                            key={SEARCHDELETED.deleted.name}
                                            value={SEARCHDELETED.deleted.name}>
                                            Eliminados
                                        </Option>
                                        <Option
                                            key={SEARCHDELETED.nonDeleted.name}
                                            value={
                                                SEARCHDELETED.nonDeleted.name
                                            }>
                                            No eliminados
                                        </Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item label=" ">
                                    <Button
                                        type="primary"
                                        icon={<DownloadOutlined />}
                                        onClick={handleDownloadCSV}>
                                        Descargar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                    <Upload {...uploadProps}>
                        <Button
                            type="secondary"
                            icon={<UploadOutlined />}
                            disabled={loading}
                            className={actualizarProductos.uploadButton}>
                            Subir CSV
                        </Button>
                    </Upload>

                    {/* <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            loading={gettingTableData}
                            columns={columns}
                            dataSource={productList}
                            pagination={{
                                pageSize: paramsProducts?.items_per_page,
                                total: 50,
                                current: paramsProducts?.page ? paramsProducts.page : 1,
                                showSizeChanger: false,
                                onChange: (page, pageSize) => paginationChangeHandler(page, pageSize),
                            }}
                        />
                    </Col>
                </Row> */}
                </Card>
            </ABMLayout>

            <NewProductModal
                isVisible={newProductVisible}
                onConfirm={handleCreateNewProduct}
                onCancel={closeNewProductModal}
            />

            <Modal
                centered
                open={isDownloadModalVisible}
                title="Descargar archivo"
                footer={null}
                closable={null}>
                <ModalContent
                    showWarnIcon={true}
                    isLoading={false}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    onOk={handleConfirmDownload}
                    onCancel={handleDownloadCancel}>
                    <p>
                        Estás por descargar una lista de productos. ¿Confirmás
                        la descarga?
                    </p>
                </ModalContent>
            </Modal>
            <Modal
                centered
                open={uploadProductVisible}
                title="Subir archivo"
                footer={null}
                closable={null}>
                <ModalContent
                    showWarnIcon={true}
                    isLoading={false}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    onOk={() => handleUpload()}
                    onCancel={handleUploadCancel}>
                    <Text>
                        Detectamos{' '}
                        <strong>
                            {userUploadedCSV.productQuantity} productos
                        </strong>{' '}
                        en el listado.
                    </Text>
                    {uploadModalText}
                </ModalContent>
            </Modal>
        </>
    );
};

export default ActualizarProductos;
