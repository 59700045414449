import React from 'react';
import PropTypes from 'prop-types';
import { Button, Spin } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import classes from './ModalContent.module.css';

const ModalContent = ({
    title,
    cancelText,
    onCancel,
    okText,
    onOk,
    customFooter,
    showWarnIcon,
    isDelete,
    invertPrimary,
    isLoading,
    children,
}) => (
    <Spin spinning={isLoading}>
        <div className={classes.content}>
            {showWarnIcon && (
                <div className={classes.icon}>
                    <ExclamationCircleOutlined />
                </div>
            )}

            <div className={classes.body}>
                {title && <h3>{title}</h3>}
                {children}
            </div>
        </div>
        {!customFooter ? (
            <div className={classes.footer}>
                <div>
                    <Button
                        onClick={onCancel}
                        type={
                            invertPrimary || (isDelete && invertPrimary)
                                ? 'primary'
                                : 'secondary'
                        }>
                        {cancelText}
                    </Button>
                    <Button
                        onClick={onOk}
                        className="ButtonModal"
                        type={!invertPrimary ? 'primary' : 'secondary'}
                        danger={isDelete || (isDelete && invertPrimary)}>
                        <span style={{ marginRight: '5px' }}>{okText}</span>
                    </Button>
                </div>
            </div>
        ) : (
            <>{customFooter}</>
        )}
    </Spin>
);

ModalContent.propTypes = {
    title: PropTypes.any,
    okText: PropTypes.string,
    onOk: PropTypes.func,
    cancelText: PropTypes.string,
    onCancel: PropTypes.func,
    customFooter: PropTypes.node,
    showWarnIcon: PropTypes.bool,
    isDelete: PropTypes.bool,
    invertPrimary: PropTypes.bool,
    isLoading: PropTypes.bool,
    children: PropTypes.any,
};

export default ModalContent;
