import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../../../../config/axios';
import { useMixpanel } from 'react-mixpanel-browser';
import { Table, Tag, Menu, message } from 'antd';
import {
    MoreOutlined,
    DownloadOutlined,
    LoadingOutlined,
    MailOutlined,
} from '@ant-design/icons';

import Dropdown from '../../../atoms/Dropdown';
import { formatoGuaranies } from '../../../utils-components/Number';

const TablaCortes = (props) => {
    const history = useHistory();
    const mixpanel = useMixpanel();

    const columnasCortes = [
        {
            dataIndex: 'pagoAnterior',
            key: 'pagoAnterior',
            align: 'center',
            width: 140,
            render: (text, record) => (
                <>
                    {record?.ultimoCorte ? (
                        <Tag style={{ borderColor: 'red' }} color={'volcano'}>
                            {record.ultimoCorte}
                        </Tag>
                    ) : null}
                    {record?.corteEspecial ? (
                        <Tag style={{ borderColor: 'blue' }} color={'blue'}>
                            {record.corteEspecial}
                        </Tag>
                    ) : null}
                </>
            ),
        },
        {
            title: <strong>#</strong>,
            dataIndex: 'nroAcreditacion',
            key: 'nroAcreditacion',
            align: 'center',
            defaultSortOrder: 'descend',
            sortDirections: 'descend',
            width: 80,
            sorter: (a, b) => a.nroAcreditacion - b.nroAcreditacion,
        },
        {
            title: (
                <strong>
                    Fecha
                    <br />
                    Inicio
                </strong>
            ),
            dataIndex: 'fechaInicio',
            key: 'fechaInicio',
            align: 'center',
            width: 120,
        },
        {
            title: (
                <strong>
                    Fecha
                    <br />
                    Cierre
                </strong>
            ),
            dataIndex: 'fechaCierre',
            key: 'fechaCierre',
            align: 'center',
            width: 120,
        },
        {
            title: <strong>Sucursal</strong>,
            dataIndex: 'sucursal',
            key: 'sucursal',
            align: 'center',
        },
        {
            title: (
                <strong>
                    Transacciones
                    <br />
                    Online
                </strong>
            ),
            dataIndex: 'transaccionesOnline',
            key: 'transaccionesOnline',
            align: 'center',
        },
        {
            title: (
                <strong>
                    Pagos
                    <br />
                    pendientes
                </strong>
            ),
            dataIndex: 'pagosPendientes',
            key: 'pagosPendientes',
            align: 'center',
        },
        {
            title: (
                <strong>
                    Pagos
                    <br />
                    realizados
                </strong>
            ),
            dataIndex: 'pagosRealizados',
            key: 'pagosRealizados',
            align: 'center',
        },
        {
            title: (
                <strong>
                    Monto
                    <br />
                    retenido
                </strong>
            ),
            dataIndex: 'montoRetenido',
            key: 'montoRetenido',
            align: 'center',
        },
        {
            title: (
                <strong>
                    Monto
                    <br />
                    Transferido
                </strong>
            ),
            dataIndex: 'montoTransferido',
            key: 'montoTransferido',
            align: 'center',
        },
        {
            title: <strong>Comisión</strong>,
            dataIndex: 'comision',
            key: 'comision',
            align: 'center',
        },
        {
            title: <strong>Saldo</strong>,
            dataIndex: 'saldo',
            key: 'saldo',
            align: 'center',
            render: (saldo, record) => (
                <>
                    {saldo < 0 ? (
                        <>
                            <small>A favor de {record.sucursal}</small>
                            <p>{formatoGuaranies(saldo * -1)}</p>
                        </>
                    ) : (
                        <>
                            <small>A favor de Monchis</small>
                            <p>{formatoGuaranies(saldo)}</p>
                        </>
                    )}
                </>
            ),
        },
        {
            dataIndex: 'botones',
            key: 'verorden',
            align: 'center',
            fixed: 'right',
            width: 100,
            render: (_text, record) => (
                <Dropdown
                    menu={() =>
                        menuAccionesTabla(
                            record.paramsBalance,
                            record.paramsDetalle,
                            record.paramsCorte,
                            record,
                        )
                    }
                    icon={<MoreOutlined />}
                    primary
                    placement="bottomRight"
                />
            ),
        },
    ];

    const menuAccionesTabla = (
        paramsBalance,
        paramsDetalle,
        paramsCorte,
        record,
    ) => (
        <Menu
            onClick={(event) =>
                menuClickHandler(
                    event,
                    paramsBalance,
                    paramsDetalle,
                    paramsCorte,
                    record,
                )
            }>
            <Menu.Item key="1">Ir a Reporte del Balance</Menu.Item>
            <Menu.Item key="2">Ir a Detalle de Órdenes</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3" icon={<MailOutlined />}>
                Enviar correo
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="4" icon={<DownloadOutlined />}>
                Exportar Detalle de Órdenes
            </Menu.Item>
        </Menu>
    );

    function menuClickHandler(
        event,
        paramsBalance,
        paramsDetalle,
        paramsCorte,
        record,
    ) {
        switch (event.key) {
            case '1': // Ver reporte de balance
                mixpanel.track(
                    'click_on_transactions_cuts_view_balance_button',
                    { paramsCorte },
                );
                guardarRouteState(paramsCorte);
                history.push('/reportes/ventas/reporte-balance', {
                    ...paramsBalance,
                });
                break;
            case '2': // Ver detalle de ordenes
                mixpanel.track(
                    'click_on_transactions_cuts_view_cut_details_confirm_button',
                    { paramsCorte },
                );
                guardarRouteState(paramsCorte);
                history.push('/reportes/ventas/detalle-transaccion/lista', {
                    ...paramsDetalle,
                });
                break;
            case '3': {
                // Enviar correo
                const idCorte = record.key;
                mixpanel.track(
                    'click_on_transactions_cuts_send_cut_email_button',
                    { idCorte },
                );
                props.enviarCorreoButtonHandler(idCorte);
                break;
            }
            case '4': // Exportar detalle de ordenes
                mixpanel.track('click_on_transactions_cuts_export_cut_button', {
                    paramsDetalle,
                });
                obtenerDatosDetalleOrden(paramsDetalle);
                break;

            default:
                break;
        }
    }

    const guardarRouteState = (paramsCorte) => {
        window.history.pushState(
            { paramsCorte: { ...paramsCorte } },
            'paramsCorte',
            '/reportes/cortes/corte-transaccion',
        );
    };

    // Detalle Transaccion - Obtener datos
    const obtenerDatosDetalleOrden = async (paramsDetalle) => {
        let tempParams = { ...paramsDetalle }; // parametros iniciales

        message.info({
            content: 'Generando archivo...',
            key: 'exportar',
            duration: 0,
            icon: <LoadingOutlined />,
        });
        try {
            const response = await axios.get('/transaction_details_download', {
                params: { ...tempParams },
            });
            window.open(response.data.data.link_transaction_details, '_blank');
        } catch (error) {
            message.destroy('exportar');
            message.error(error.response.data.message);
        }
        message.destroy('exportar');
    };

    return (
        <Table
            columns={columnasCortes}
            bordered
            rowKey={(record) => record.key}
            {...props}
        />
    );
};

export default TablaCortes;

TablaCortes.propTypes = {
    enviarCorreoButtonHandler: PropTypes.func,
};
