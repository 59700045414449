import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';

const EnviarIntegracionModal = ({
    enviarIntegracionModalVisible,
    ordenSeleccionada,
    cerrarModalEnviarIntegracion,
    confirmarEnviarIntegracion,
    loadingIntegraciones,
}) => {
    const ordenId = ordenSeleccionada?.id;
    const idLocal = ordenSeleccionada?.branch?.franchise_id;
    const stateId = ordenSeleccionada?.order_state_id;
    return (
        <Modal
            open={enviarIntegracionModalVisible}
            footer={null}
            closable={null}>
            <ModalContent
                title={`Desea enviar a la sucursal la orden #${ordenId} ?`}
                okText="CONFIRMAR"
                cancelText="CANCELAR"
                onOk={() =>
                    confirmarEnviarIntegracion(ordenId, idLocal, stateId)
                }
                onCancel={() => cerrarModalEnviarIntegracion()}
                isLoading={loadingIntegraciones}
                showWarnIcon={true}
            />
        </Modal>
    );
};

EnviarIntegracionModal.propTypes = {
    enviarIntegracionModalVisible: PropTypes.any,
    ordenSeleccionada: PropTypes.any,
    cerrarModalEnviarIntegracion: PropTypes.any,
    confirmarEnviarIntegracion: PropTypes.any,
    loadingIntegraciones: PropTypes.any,
};

export default EnviarIntegracionModal;
