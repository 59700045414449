import React, { useEffect, useState } from 'react';
import {
    Card,
    Col,
    Form,
    Input,
    Row,
    Upload,
    Divider,
    Checkbox,
    Space,
    Select,
} from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import styles from './GeneralInfo.module.css';
import PropTypes from 'prop-types';

const GeneralInfo = ({ imageUrl, formInstance, disabled }) => {
    const { Dragger } = Upload;

    const [fileList, setFileList] = useState([]);

    const imageName = imageUrl?.split('/').pop()?.split('_').pop();

    useEffect(() => {
        if (imageUrl) {
            setFileList([
                {
                    uid: '-1',
                    name: imageName,
                    status: 'done',
                    url: imageUrl,
                },
            ]);

            formInstance.setFieldsValue({
                image: [
                    {
                        uid: '-1',
                        name: imageName,
                        status: 'done',
                        url: imageUrl,
                    },
                ],
            });
        } else {
            setFileList([]);
            formInstance.setFieldsValue({
                image: [],
            });
        }
    }, [imageUrl, formInstance, imageName]);

    const onChange = (info) => {
        let newFileList = [...info.fileList];
        newFileList = newFileList.slice(-1);
        setFileList(newFileList);
        formInstance.setFieldsValue({
            image: newFileList,
        });
    };

    const onRemove = () => {
        setFileList([]);
        formInstance.setFieldsValue({
            image: [],
        });
    };

    return (
        <Card
            type="inner"
            title="Información general"
            className={styles.wrapper}>
            <Form form={formInstance}>
                <Row justify="center">
                    <Col span={11}>
                        <Form.Item
                            label="Nombre de la promoción"
                            name="promotionName"
                            rules={[
                                {
                                    required: true,
                                    message:
                                        'Por favor, ingrese el nombre de la promoción.',
                                },
                            ]}>
                            <Input />
                        </Form.Item>
                        <Space>
                            <Form.Item
                                label="Mostrar como"
                                name="showAs"
                                rules={[
                                    {
                                        required: true,
                                        message:
                                            'Por favor, seleccione una opción.',
                                    },
                                ]}>
                                <Select
                                    disabled={disabled}
                                    placeholder="Seleccionar"
                                    options={[
                                        {
                                            label: 'Productos',
                                            value: 'products',
                                        },
                                        {
                                            label: 'Franquicias',
                                            value: 'franchises',
                                        },
                                    ]}
                                    className={styles.select}
                                />
                            </Form.Item>
                            <Form.Item
                                className={styles.item}
                                name="showHome"
                                valuePropName="checked">
                                <Checkbox
                                    disabled={disabled}
                                    className={styles.checkbox}>
                                    Mostrar banner en la Home
                                </Checkbox>
                            </Form.Item>
                        </Space>
                    </Col>

                    <Col span={2}>
                        <Row
                            justify="center"
                            className={styles.dividerContainer}>
                            <Col>
                                <Divider
                                    type="vertical"
                                    className={styles.divider}
                                />
                            </Col>
                        </Row>
                    </Col>

                    <Col span={11}>
                        <Form.Item
                            name="image"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, subí una imagen.',
                                },
                            ]}>
                            <Dragger
                                className={styles.dragger}
                                multiple={false}
                                maxCount={1}
                                // En beforeUpload se le debe pasar una función que
                                // devuelva false para que no se envíe la imagen al
                                // servidor de forma automática
                                beforeUpload={() => false}
                                accept="image/png, image/jpeg, image/jpg"
                                onChange={onChange}
                                onRemove={onRemove}
                                fileList={fileList}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">
                                    <span className={styles.requiredTick}>
                                        *{' '}
                                    </span>
                                    Hacé clic o arrastrá tu banner acá
                                </p>
                                <p className="ant-upload-hint">
                                    El archivo debe ser de 1800x900 px (jpg,
                                    jpeg o png)
                                </p>
                            </Dragger>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    );
};

export default GeneralInfo;

GeneralInfo.propTypes = {
    imageUrl: PropTypes.string,
    formInstance: PropTypes.object.isRequired,
    disabled: PropTypes.bool,
};
