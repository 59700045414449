import React from 'react';
import { MixpanelProvider } from 'react-mixpanel-browser';

import AuthState from './context/autenticacion/authState';
import PrefState from './context/preferencias/prefState';
import UtilidadesState from './context/utilidades/UtilidadesState';
import { CookiesProvider } from 'react-cookie';

import TimeAgo from 'javascript-time-ago';
import es from 'javascript-time-ago/locale/es.json';
TimeAgo.addDefaultLocale(es);

import { AppRouter } from './routes/AppRouter';
import { ConfigProvider } from 'antd';
import esES from 'antd/es/locale/es_ES';

import 'antd/dist/antd.css';

function App() {
    return (
        <ConfigProvider locale={esES}>
            <CookiesProvider>
                <MixpanelProvider>
                    <AuthState>
                        <PrefState>
                            <UtilidadesState>
                                <AppRouter />
                            </UtilidadesState>
                        </PrefState>
                    </AuthState>
                </MixpanelProvider>
            </CookiesProvider>
        </ConfigProvider>
    );
}

export default App;
