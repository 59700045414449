import React, { useReducer } from 'react';
import { notification } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import NotificationIcon from '../../components/utils-components/Icon/customIcon';
import AuthContext from './authContext';
import AuthReducer, { initialState } from './authReducer';
import PropTypes from 'prop-types';
import { clearLocalStorage, localStorageItems } from '../../constants';
import {
    AUTH_INICIO,
    AUTH_EXITO,
    AUTH_FALLO,
    AUTH_REDIRECT_PATH,
    CERRAR_SESION,
} from '../../types';
import clienteAxios, { setAuthToken } from '../../config/axios';

const AuthState = ({ children }) => {
    // definir el reducer
    const [state, dispatch] = useReducer(AuthReducer, initialState);
    //Hook de mixpanel
    const mixpanel = useMixpanel();

    // Autenticar usuario
    const iniciarSesion = async (datos) => {
        dispatch({ type: AUTH_INICIO });

        try {
            const respuesta = await clienteAxios.post('/login', datos);
            // extraer el token
            const permisos = {
                ...respuesta?.data?.data?.data?.role?.permissions,
            };

            //Desestructuración de los roles
            const {
                token: token,
                id: id,
                first_name: nombre,
                last_name: apellido,
                email: email,
                role: { id: roleId, name: roleName, slug: roleSlug },
            } = respuesta?.data?.data?.data;

            //Identificación unica del usuario para mixpanel
            mixpanel.identify(id);

            //Se registran superpropiedades persistentes en todos los eventos
            mixpanel.register({
                nombre: nombre,
                apellido: apellido,
                id: id,
                token: token,
                email: email,
                roleId: roleId,
                roleName: roleName,
                roleSlug: roleSlug,
            });

            //Evento del Login
            mixpanel.track('click_on_login');

            //Personalización del perfil de usuario en mixpanel
            mixpanel.people.set({
                $name: String(nombre + ' ' + apellido),
                $email: email,
                $role: roleName,
            });

            // Guardar nombre y apellido
            localStorage.setItem(localStorageItems.nombre, nombre);
            localStorage.setItem(localStorageItems.apellido, apellido);

            //Guardar email
            localStorage.setItem(localStorageItems.email, email);

            //Guardar Rol
            localStorage.setItem(localStorageItems.role, roleId);

            // Guardar Token
            localStorage.setItem(
                localStorageItems.token,
                JSON.stringify(token),
            );
            localStorage.setItem(localStorageItems.id, id);

            // Guardar permisos de acceso
            localStorage.setItem(
                localStorageItems.permisos,
                JSON.stringify(permisos),
            );

            dispatch({
                type: AUTH_EXITO,
                payload: {
                    token,
                    permisos,
                    id,
                    nombre,
                    apellido,
                },
            });
        } catch (error) {
            const mensajeError =
                error?.response?.data?.data?.data ||
                'Hubo un problema con su inicio de sesion';

            //Evento de error en el Login
            mixpanel.track('error_login', { mensajeError });

            dispatch({
                type: AUTH_FALLO,
                payload: { mensajeError },
            });
        }
    };

    const verificarAutenticacion = () => {
        const token =
            JSON.parse(localStorage.getItem(localStorageItems.token)) ||
            undefined;
        const permisos =
            JSON.parse(localStorage.getItem(localStorageItems.permisos)) ||
            undefined;
        const nombre = localStorage.getItem('nombre');
        const apellido = localStorage.getItem('apellido');

        try {
            if ((token, permisos, nombre, apellido)) {
                setAuthToken(token);
                dispatch({
                    type: AUTH_EXITO,
                    payload: { token, permisos, nombre, apellido },
                });
            } else {
                dispatch({
                    type: CERRAR_SESION,
                });
            }
        } catch (error) {
            console.error(error);
            dispatch({
                type: CERRAR_SESION,
            });
        }
    };

    const cerrarSesion = () => {
        clearLocalStorage();

        dispatch({
            type: CERRAR_SESION,
        });
    };

    const setRedirectPath = (redirectPath) => {
        dispatch({
            type: AUTH_REDIRECT_PATH,
            payload: {
                redirectPath,
            },
        });
    };

    //notificacion de facturas pendientes
    const openNotification = () => {
        notification.open({
            message: 'Factura pendiente de pago',
            description: `Estimado Cliente, su cuenta posee cargos pendientes. Favor verifique el panel “Mis facturas” para más detalles. Si tiene alguna consulta contacte con administracion@monchis.com.py`,
            icon: <NotificationIcon style={{ color: '#108ee9' }} />,
            duration: 5,
        });
    };

    const getFacturasPendientes = async () => {
        try {
            const llamada = await clienteAxios.get('/verify_invoice_pending');
            const respuesta = llamada?.data?.data;
            if (respuesta?.total > 0) {
                openNotification(respuesta.total);
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <AuthContext.Provider
            value={{
                token: state.token,
                permisos: state.permisos,
                id: state.id,
                nombre: state.nombre,
                apellido: state.apellido,
                email: state.email,
                usuario: state.usuario,
                error: state.error,
                redirectPath: state.redirectPath,
                autenticado: state.autenticado,

                iniciarSesion,
                cerrarSesion,
                verificarAutenticacion,
                setRedirectPath,
                getFacturasPendientes,
            }}>
            {children}
        </AuthContext.Provider>
    );
};

AuthState.propTypes = {
    children: PropTypes.any,
};

export default AuthState;
