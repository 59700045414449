import React, { useState } from 'react';
import { Row, Col, Form, Button, Select, Table } from 'antd';
import useFranchiseAndBranchStatus from '../../../../hooks/useFranchiseAndBranchStatus';
import classes from './../../Integraciones/Integraciones.module.css';
import InfoBar from 'components/molecules/Integraciones/InfoBar/InfoBar';
import No from '../SVG/No';
import Check from '../SVG/Check';
import DesmatchearModal from '../DesmatchearModal';
import MatchearProducto from '../MatchearProducto';
import { useHistory } from 'react-router-dom';

const ProductosTab = () => {
    const brandsHook = useFranchiseAndBranchStatus();
    const [modalDesmatchear, setModalDesmatchear] = useState(false);
    const [form] = Form.useForm();
    const [matchearProductoVisible, setMatchearProductoVisible] =
        useState(false);

    const pageChangeHandler = (page) => {
        window.scrollTo({ top: 100, behavior: 'smooth' });
        brandsHook.getBrandsList(page);
    };

    const history = useHistory();

    const handleCancelDesmatchear = () => {
        setModalDesmatchear(false);
    };

    const openMatchearProducto = () => {
        history.push('/administrar/matchearProducto');
    };

    const columns = [
        {
            title: 'ID Externo',
            dataIndex: 'id',
            key: 'id',
            align: 'flex-start',
            width: '15%',
        },
        {
            title: 'Producto',
            dataIndex: 'product',
            key: 'product',
            align: 'flex-start',
            width: '15%',
        },
        {
            title: 'Tipo',
            dataIndex: 'type',
            key: 'type',
            align: 'flex-start',
            width: '15%',
        },
        {
            title: 'Sincronizado',
            dataIndex: 'synchronized',
            key: 'synchronized',
            align: 'left',
            width: '15%',
        },
        {
            title: 'Matcheado',
            dataIndex: 'matched',
            key: 'matched',
            render: (text) => {
                if (text === 'Si') {
                    return <No />;
                } else if (text === 'No') {
                    return <Check />;
                }
                return text;
            },
            align: 'center',
            width: '12%',
        },
        {
            title: 'Acción',
            dataIndex: 'action',
            key: 'action',
            render: (text) => {
                if (text === 'Acción 1') {
                    return {
                        props: {
                            className: classes.BackgroundTabla1,
                            onClick: () => setModalDesmatchear(true),
                        },
                        children: (
                            <div className={classes.TablaAccionDesmatchear}>
                                <span style={{ marginRight: '10px' }}>
                                    Desmatchear
                                </span>
                                <No />
                            </div>
                        ),
                    };
                } else if (text === 'Acción 2') {
                    return {
                        props: {
                            className: classes.BackgroundTabla2,
                            onClick: () => openMatchearProducto(true),
                        },
                        children: (
                            <div className={classes.TablaAccionMatchear}>
                                <span
                                    style={{
                                        marginRight: '10px',
                                    }}>
                                    Matchear
                                </span>
                                <Check />
                            </div>
                        ),
                    };
                }
                return text;
            },
            align: 'center',
            width: '5%',
        },
    ];

    // Datos simulados
    const simulatedData = [
        {
            id: 87,
            product: 'BBQ Carne',
            type: 'Individual',
            synchronized: (
                <div style={{ textAlign: 'left' }}>
                    05-09-2023 23:51:26
                    <br />
                    Por: Ceci Falcón
                </div>
            ),
            matched: 'No',
            action: 'Acción 1',
        },
        {
            id: 88,
            product: 'BBQ Pollo',
            type: 'Combo',
            synchronized: (
                <div style={{ textAlign: 'left' }}>
                    05-09-2023 23:51:26
                    <br />
                    Por: Ceci Falcón
                </div>
            ),
            matched: 'Si',
            action: 'Acción 2',
        },
    ];

    const fakeBarData = [
        {
            id: '1',
            label: 'Productos',
            value: '0',
        },
        {
            id: '2',
            label: 'Sin Matcheo',
            value: '0',
        },
        {
            id: '3',
            label: 'Matcheados',
            value: '0',
        },
    ];

    return (
        <div className="mt36">
            <Form form={form} layout="vertical">
                <Row gutter={12} align="middle">
                    <Col span={4}>
                        <Form.Item label="Integración" name="branch">
                            <Select
                                allowClear
                                optionFilterProp="children"
                                placeholder="Ninguna"></Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label="Franquicia" name="franchise">
                            <Select
                                allowClear
                                optionFilterProp="children"
                                placeholder="Todas las franquicias"></Select>
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item label="Tipo de Producto" name="refineSearch">
                            <Select
                                allowClear
                                optionFilterProp="children"
                                placeholder="Todos"></Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Form form={form} layout="vertical">
                <Row gutter={12} align="middle">
                    <Col span={4}>
                        <Form.Item label="Nombre o ID externo" name="franchise">
                            <Select
                                maxTagCount="responsive"
                                mode="multiple"
                                optionFilterProp="children"
                                placeholder="Buscá por nombre o ID externo"></Select>
                        </Form.Item>
                    </Col>

                    <Col span={5}>
                        <Form.Item
                            label="Nombre o ID Monchis"
                            name="refineSearch">
                            <Select
                                maxTagCount="responsive"
                                mode="multiple"
                                optionFilterProp="children"
                                placeholder="Buscá por nombre o ID externo"></Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Row align="bottom">
                            <Col span={12}>
                                <Button type="primary" htmlType="button">
                                    Buscar
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>

            <InfoBar data={fakeBarData} />

            <Row span={24} className="mt32">
                <Table
                    className={classes.customTableHeader2 + ' ' + 'w100'}
                    columns={columns}
                    dataSource={simulatedData}
                    rowKey="id"
                    pagination={{
                        pageSize: 15,
                        showSizeChanger: false,
                        total: simulatedData.length,
                        current: 1,
                        onChange: pageChangeHandler,
                    }}
                />
            </Row>
            <DesmatchearModal
                modalDesmatchear={modalDesmatchear}
                handleCancelDesmatchear={handleCancelDesmatchear}
            />
            {matchearProductoVisible && (
                <MatchearProducto
                    onClose={() => setMatchearProductoVisible(false)}
                />
            )}
        </div>
    );
};

export default ProductosTab;
