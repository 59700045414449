import React, { useEffect, useState, useRef } from 'react';
import {
    ExclamationCircleOutlined,
    InfoCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Divider,
    message,
    Modal,
    InputNumber,
    Tooltip,
} from 'antd';
import ABMLayout from '../../../../../layouts/ABMLayout';

import classes from '../../../Balance/Balance.module.css';
import CustomSVG from '../../../../../assets/svg/support/CustomSVG';

import axios from '../../../../../config/axios';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../../utils-components/Number';
import { formatoFechaInput } from '../../../../utils-components/Date';
import { Permisos } from '../../../../../constants/Permisos';

import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import { useVerificarPermiso } from '../../../../hooks/useVerificarPermiso';
import Card from '../../../../atoms/Card/Card';
import Button from '../../../../atoms/Button';
import { datePickerAntd as DatePicker } from '../../../../atoms/DatePicker';
import BalanceTable from '../../../../molecules/Finanzas/Facturacion/Balance/TablaBalance';
import FormItemSelectFranquicia from '../../../../atoms/Form/Select/Franquicia';

import {
    columnasFacturacionBalance,
    columnasResumenMonchisDrivers,
    columnasResumenMonchisMarketplace,
    columnasResumenImportes,
} from '../../../Balance/BalanceColumnasTabla';
import FormItemSelectFamilia from '../../../../atoms/Form/Select/Familias';
import FormItemSelectTipoPago from '../../../../atoms/Form/Select/TipoPago';

const totalRegistrosPorPaginaBalance = 5;
const montoMinimoCorte = 0;

const Balance = () => {
    const { esUsuarioComercio } = useVerificarPermiso;

    // Formulario
    const [balanceForm] = Form.useForm();

    // Formulario - Valores iniciales form
    const [valoresInicialesForm, setValoresInicialesForm] = useState();

    useEffect(() => {
        let valoresInicialesFormTemp = {
            fecha: moment(
                moment().subtract(1, 'd').format(formatoFechaInput),
                formatoFechaInput,
            ),
            franquiciaId: '',
        };

        setValoresInicialesForm(valoresInicialesFormTemp);
    }, []);

    // Formulario - Datos recibidos del backend
    const [balanceData, setBalanceData] = useState();
    const [cantidadRegistrosBalance, setCantidadRegistrosBalance] = useState();
    const [paramsBalance, setParamsBalance] = useState();
    const [franquiciasConFormato, setFranquiciasConFormato] = useState();

    // Tablas de datos
    // Tablas de datos -- Balance -- Datos formateados
    const [obteniendoDatos, setObteniendoDatos] = useState(false);
    const [tablaBalanceData, setTablaBalanceData] = useState();

    // Formulario - Boton Ver Balance
    const verBalanceButtonHandler = (values) => {
        setCantidadRegistrosBalance(null);

        let tempParamsBalance = { date_end: values.fecha.format('YYYY-MM-DD') };

        if (values.payment_type)
            tempParamsBalance['payment_type'] = values.payment_type;

        if (values.franquiciaId)
            tempParamsBalance['ruc_or_franchise'] = values.franquiciaId;

        if (values.familiaId) tempParamsBalance['family'] = values.familiaId;

        setParamsBalance({ ...tempParamsBalance, page: 1 });
    };

    // Tabla - Actualizar cambio de pagina
    const cambioDePaginaHandler = (page) => {
        setParamsBalance({
            ...paramsBalance,
            page: page,
        });
    };

    // Fomulario - Obtener datos del balance
    const obtenerBalance = async () => {
        setObteniendoDatos(true);
        setTablaBalanceData(null);

        try {
            // obtener datos
            const respuestaBalance = await axios.get('/get_balance', {
                params: paramsBalance,
            });

            // actualizar el numero total de paginas
            if (!cantidadRegistrosBalance) {
                setCantidadRegistrosBalance(
                    respuestaBalance.data.data.total_franchises,
                );
            }
            // datos para las tarjetas
            setDataTarjetas({
                ...respuestaBalance.data.data,
                finances: null,
            });

            // extrer detalles del balance
            const respuestaBalanceArr = respuestaBalance.data.data.finances;
            let franchiseIdArr = [];
            respuestaBalanceArr.forEach((element) => {
                franchiseIdArr.push(element.franchise_society_id);
            });
            // guardar detalles
            let balanceArr = [];
            respuestaBalanceArr.forEach((objectoBalance) => {
                balanceArr.push({ ...objectoBalance });
            });
            setBalanceData(balanceArr);

            // guardar las franquicias del balance actual
            try {
                let franquiciasArr =
                    respuestaBalance.data.data.all_franchises_id.map((item) =>
                        Number(item),
                    );
                setFranquiciasConFormato(franquiciasArr);
            } catch (err) {
                // continue regardless of error
            }
        } catch (error) {
            message.error('Hubo un problema al obtener los datos');
            console.log('Hubo un problema al obtener los datos', error);
        }
        setObteniendoDatos(false);
    };

    // Indicador de hacer refresco a la tabla
    const [refrescarDatos, setRefrescarDatos] = useState(false);

    // Indicador de hacer refresco a la tabla

    useEffect(() => {
        if (paramsBalance) {
            obtenerBalance();
        }
        if (refrescarDatos) {
            refrescarDatos && setRefrescarDatos(false);
        }
    }, [paramsBalance, refrescarDatos]);

    const refrescarDatosHandler = () => {
        setRefrescarDatos(true);
        setParamsBalance((currState) => ({ ...currState, page: 1 }));
    };

    // Boton hacer TXT
    const [dataEdnpointTXT, setDataEndpointTXT] = useState(false);

    const csvInstance = useRef();
    useEffect(() => {
        if (
            dataEdnpointTXT &&
            csvInstance.current &&
            csvInstance.current.link
        ) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setDataEndpointTXT(false);
            });
        }
    }, [dataEdnpointTXT]);

    // Boton hacer corte
    const [haciendoCorte, setHaciendoCorte] = useState(false);

    const hacerCorteHandler = async () => {
        setHaciendoCorte(true);
        try {
            if (franquiciasConFormato && paramsBalance) {
                let dataToQuery = {};
                dataToQuery['franchises'] = franquiciasConFormato;
                dataToQuery['date_end'] = paramsBalance.date_end;

                const respuesta = await axios.post('/cut_balance', dataToQuery);
                const franquiciasConFallos = respuesta.data.data.fails;
                const mensaje = respuesta.data.data.data;

                if (franquiciasConFallos.length === 0) {
                    message.success(mensaje);
                } else if (franquiciasConFallos.length > 0) {
                    message.error(
                        `Ya se han generado cortes de ${franquiciasConFallos.length} franquicia/s`,
                    );
                } else {
                    message.error(
                        'Hubo un problema inesperado al realizar el corte',
                    );
                }

                refrescarDatosHandler();
            } else {
                message.info('Primero obtenga datos en pantalla');
            }
        } catch (err) {
            message.error(
                'No se pudo contactar al servidor para hacer el corte',
            );
        }
        setHaciendoCorte(false);
    };

    // Tarjetas
    // Tarjetas -- datos para las tarjetas en la cabecera
    const [tarjetasCabecera, setTarjetasCabecera] = useState({
        cantidadVentas: 0,
        totalVentas: formatoGuaranies(0),
        cantidadDrivers: 0,
        totalDrivers: formatoGuaranies(0),
        totalPagoOnline: formatoGuaranies(0),
        totalEfectivo: formatoGuaranies(0),
    });
    const [dataTarjetas, setDataTarjetas] = useState();

    const refrescarDatosTarjetaCabecera = () => {
        let cantidadVentas = dataTarjetas?.total_orders || 0;
        let totalVentas = dataTarjetas?.amount_total_orders || 0;

        let cantidadDrivers = dataTarjetas?.quantity_delivery_with_monchis || 0;
        let totalDrivers = dataTarjetas?.amount_delivery_with_monchis || 0;

        let totalPagoOnline = dataTarjetas?.online_amount || 0;
        let totalEfectivo = dataTarjetas?.cash_amount || 0;

        setTarjetasCabecera({
            cantidadVentas: formatoNumeros(cantidadVentas),
            totalVentas: formatoGuaranies(totalVentas),
            cantidadDrivers: formatoNumeros(cantidadDrivers),
            totalDrivers: formatoGuaranies(totalDrivers),
            totalPagoOnline: formatoGuaranies(totalPagoOnline),
            totalEfectivo: formatoGuaranies(totalEfectivo),
        });
    };

    // Modal Hacer Corte Especial
    const [modalCorteEspecialVisible, setModalCorteEspecialVisible] =
        useState(false);
    const [franquiciaSeleccionada, setFranquiciaSeleccionada] = useState();
    const [haciendoCorteEspecial, setHaciendoCorteEspecial] = useState(false);
    const [corteEspecialForm] = Form.useForm();

    const abrirCorteEspecialModal = (idFranquicia) => {
        setModalCorteEspecialVisible(true);
        setFranquiciaSeleccionada(idFranquicia);
    };

    const HacerCorteEspecialModal = () => {
        const cancelarCorteEspecialHandler = () => {
            setModalCorteEspecialVisible(false);
        };

        const procesarCorteEspecialHandler = async () => {
            corteEspecialForm
                .validateFields()
                .then(async () => {
                    setHaciendoCorteEspecial(true);
                    try {
                        let formData = corteEspecialForm.getFieldsValue();

                        const monto = Number(formData.monto);

                        let idFranquicia = Number(franquiciaSeleccionada);

                        let dateEnd = paramsBalance.date_end;

                        let corteParams = {
                            special_cut: true,
                            franchises: [idFranquicia],
                            debit_amount: monto,
                            date_end: dateEnd,
                        };

                        const respuesta = await axios.post(
                            '/cut_balance',
                            corteParams,
                        );
                        const franquiciasConFallos = respuesta.data.data.fails;
                        if (franquiciasConFallos.length === 0) {
                            message.success(
                                'Corte especial realizado correctamente',
                            );
                            refrescarDatosHandler();
                        } else {
                            message.info(
                                'Ya se ha generado un corte para esta franquicia',
                            );
                        }
                    } catch (err) {
                        message.error(
                            'No se pudo contactar al servidor para hacer el corte',
                        );
                    }
                    setHaciendoCorteEspecial(false);
                    setModalCorteEspecialVisible(false);
                    corteEspecialForm.resetFields();
                })
                .catch(() => {
                    corteEspecialForm.resetFields();
                });
        };

        return (
            <Modal
                title="Corte especial"
                open={modalCorteEspecialVisible}
                okText="Procesar"
                onOk={procesarCorteEspecialHandler}
                confirmLoading={haciendoCorteEspecial}
                cancelText="Cerrar"
                onCancel={cancelarCorteEspecialHandler}
                cancelButtonProps={{ disabled: haciendoCorteEspecial }}>
                <Form name="basic" form={corteEspecialForm}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Ingrese un numero válido',
                            },
                        ]}
                        name="monto">
                        <InputNumber
                            style={{ width: '100%' }}
                            min={montoMinimoCorte}
                            formatter={(value) =>
                                `Gs. ${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ',',
                                )
                            }
                            parser={(value) =>
                                value.replace(/Gs.\s?|(,*)/g, '')
                            }
                            disabled={haciendoCorteEspecial}
                            validateStatus="error"
                        />
                    </Form.Item>
                </Form>
            </Modal>
        );
    };

    const refrescarDatosTabla = () => {
        // Agreagar formato para mostrar en la tabla
        let tablaBalanceConFormato = balanceData.map((item) => {
            // Balance
            let key = Math.random();
            let ruc = item.ruc;
            let razonSocial = item.legal_name;
            let franquicia = item.name;
            let franquiciaEspecial = item.is_special_franchise;

            const idFranquicia = Number(item?.franchise_society_id) || 0;

            let cantidadTotalOrdenes = Number(item.order_quantity) || 0;

            let cantidadTotalOrdenesDrivers = 0; // Cantidad de Ordenes realizadas con Monchis Drivers
            let totalesMonchisDrivers = Number(
                item.amount_delivery_with_monchis,
            ); // Total final de ingresos por delivery/drivers

            // let tienePagoOnline = Boolean(item?.has_online_payment);
            let resumenPagoOnline = Number(item.online_amount) || 0;
            let tieneMonchisDrivers = Boolean(item?.has_monchis_drivers);

            let importeOrden = 0; // Balance - Importe Orden
            let subtotalComision = 0; // Balance - Subtotal Comision
            let comisionACobrar = item.total_commission_gain;
            let corteAnteriorInicio = item.previous_balance_opened_date;
            const tablaResumen = item.summary_table;

            if (corteAnteriorInicio) {
                corteAnteriorInicio = moment(
                    corteAnteriorInicio.substring(
                        0,
                        corteAnteriorInicio.indexOf('.'),
                    ) + moment().format('Z'),
                ).format('DD/MM/YYYY');
            } else {
                corteAnteriorInicio = '- ';
            }

            let corteAnteriorFin = item.previous_balance_closing_date;

            if (corteAnteriorFin) {
                corteAnteriorFin = moment(
                    corteAnteriorFin.substring(
                        0,
                        corteAnteriorFin.indexOf('.'),
                    ) + moment().format('Z'),
                ).format('DD/MM/YYYY');
            } else {
                corteAnteriorFin = '- ';
            }

            let periodoCorteAnterior = `${corteAnteriorInicio} al ${corteAnteriorFin}`;

            // Resumen Monchis Drivers
            let monchisDriversArr = item.driver_prices;
            let tablaResumenMonchisDriversConFormato = [];

            // Monchis Drivers - Se recorre el objeto "driver_prices" si tiene datos
            if (monchisDriversArr.length > 0) {
                // Monchis Drivers - Preparar la tabla con formatos
                tablaResumenMonchisDriversConFormato = monchisDriversArr.map(
                    (itemDriver, indexDriver) => {
                        let driverCantidad = Number(itemDriver.quantity) || 0; // cantidad total de deliveries
                        let driverPrecios = Number(itemDriver.price) || 0; // precio del delivery
                        let driverTotal = Number(itemDriver.total) || 0; // monto total por delivery
                        // Monchis Drivers - Cantidad de Ordenes realizadas con Monchis Drivers se acumula y agrega fuera del map
                        cantidadTotalOrdenesDrivers += driverCantidad;
                        // Monchis Drivers - Retorno de tabla con formato
                        return {
                            key: indexDriver,
                            quantity_price: `${driverCantidad} x ${formatoGuaranies(
                                driverPrecios,
                            )}`,
                            total: formatoGuaranies(driverTotal),
                        };
                    },
                );
                // Monchis Drivers - Se agrega datos de columna Totales
                tablaResumenMonchisDriversConFormato.push({
                    key: tablaResumenMonchisDriversConFormato.length,
                    quantity_price: (
                        <strong>Total {cantidadTotalOrdenesDrivers}</strong>
                    ),
                    total: (
                        <strong>
                            {formatoGuaranies(totalesMonchisDrivers)}
                        </strong>
                    ),
                });
            } else {
                // Monchis Drivers - Dummy placeholder con datos en cero
                tablaResumenMonchisDriversConFormato = [
                    {
                        key: 0,
                        quantity_price: `0 x ${formatoGuaranies(0)}`,
                        total: formatoGuaranies(0),
                    },
                    {
                        key: 1,
                        quantity_price: (
                            <strong>Total {cantidadTotalOrdenesDrivers}</strong>
                        ),
                        total: (
                            <strong>
                                {formatoGuaranies(totalesMonchisDrivers)}
                            </strong>
                        ),
                    },
                ];
            }

            // Resumen de Monchis Marketplace
            let monchisMarketplaceArr = item.commissions;
            let tablaResumenMonchisMarketplaceConFormato = [];
            let importeTotalMonchisMarketplace = 0;

            // Monchis Marketplace - Se recorre el objeto "commissions" si tiene datos
            if (monchisMarketplaceArr.length > 0) {
                // Monchis Marketplace - Preparar la tabla con formatos
                tablaResumenMonchisMarketplaceConFormato =
                    monchisMarketplaceArr.map((itemMkt, indexMkt) => {
                        let ordenesCantidad = Number(itemMkt.orders) || 0;
                        let totalOrden = Number(itemMkt.total_orders) || 0;
                        let ordenesComision =
                            Number(itemMkt.commission_percent) || 0;
                        let ordenesImporte =
                            Number(itemMkt.total_commission) || 0;

                        // Monchis Marketplace - Columna Totales se acumula y agrega fuera del map
                        importeTotalMonchisMarketplace += ordenesImporte;

                        // Balance - Sumatoria de importes para la columna Importe Orden
                        importeOrden += totalOrden;

                        // Monchis Marketplace - Retorno de datos formateados
                        return {
                            key: indexMkt,
                            comisionesPorcentaje: `${ordenesCantidad} órdenes al ${ordenesComision}%`,
                            totalMontoComisiones:
                                formatoGuaranies(ordenesImporte),
                        };
                    });
                // Monchis Marketplace - Se agrega datos de columna Totales
                tablaResumenMonchisMarketplaceConFormato.push({
                    key: tablaResumenMonchisMarketplaceConFormato.length,
                    comisionesPorcentaje: (
                        <strong>Total {cantidadTotalOrdenes}</strong>
                    ),
                    totalMontoComisiones: (
                        <strong>
                            {formatoGuaranies(importeTotalMonchisMarketplace)}
                        </strong>
                    ),
                });
            } else {
                // Monchis Marketplace - Dummy placeholder con datos en cero
                tablaResumenMonchisDriversConFormato = [
                    {
                        key: 0,
                        comisionesPorcentaje: `Ordenes 0 = ${formatoGuaranies(
                            0,
                        )} x 0%`,
                        totalMontoComisiones: formatoGuaranies(0),
                    },
                    {
                        key: 1,
                        comisionesPorcentaje: <strong>Total 0</strong>,
                        totalMontoComisiones: (
                            <strong>{formatoGuaranies(0)}</strong>
                        ),
                    },
                ];
            }

            // Balance - Actualizar sub total comision
            subtotalComision = importeTotalMonchisMarketplace;

            // Resumen - Saldo
            // let resumenSaldo = Number(item.total_balance); //comisionACobrar - resumenPagoOnline;

            // Resumen - Datos con Formato
            let tablaResumenImportes = tablaResumen.map((item, index) => ({
                key: index,
                resumenPagos: (
                    <span
                        style={{
                            fontWeight: item.options.bold ? 'bold' : 'normal',
                        }}>
                        {item.title}
                    </span>
                ),
                totalResumenPagos: (
                    <span
                        style={{
                            color: item.options.color,
                            fontWeight: item.options.bold ? 'bold' : 'normal',
                        }}>
                        {item.value.replace('-', '')}
                    </span>
                ),
            }));

            // HABILITAR SI LA FRANQUICIA ES ESPECIAL O EXISTEN PAGOS ONLINE
            const habilitarCorteEspecial = Boolean(
                franquiciaEspecial || resumenPagoOnline > 0,
            );

            let botonesResumen = (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                    }}>
                    <Button
                        block={true}
                        type="primary"
                        className={classes.PrimaryCustom}
                        onClick={() => abrirCorteEspecialModal(idFranquicia)}
                        disabled={!habilitarCorteEspecial}>
                        Hacer corte especial
                    </Button>
                    <Tooltip
                        placement="left"
                        title="Habilitado cuando la franquicia es 'Especial' o existan 'Pagos Online'">
                        <InfoCircleOutlined />
                    </Tooltip>
                </div>
            );

            return {
                key: key,
                ruc: ruc,
                legal_name: razonSocial,
                name: franquicia,
                order_quantity: formatoNumeros(cantidadTotalOrdenes),
                commissions_total_orders: formatoGuaranies(importeOrden),
                order_monchis_quantity_delivery_amount: (
                    <>
                        {formatoNumeros(cantidadTotalOrdenesDrivers)}
                        <br />
                        {formatoGuaranies(totalesMonchisDrivers)}
                    </>
                ),
                subtotalComision: formatoGuaranies(subtotalComision),
                commissions_total_commission: formatoGuaranies(comisionACobrar),
                periodo_corte_anterior: periodoCorteAnterior,

                tieneMonchisDrivers: tieneMonchisDrivers,
                columnasResumenMonchisDrivers: columnasResumenMonchisDrivers,
                dataResumenMonchisDrivers: tablaResumenMonchisDriversConFormato,

                columnasResumenMonchisMarketplace:
                    columnasResumenMonchisMarketplace,
                dataResumenMonchisMarketplace:
                    tablaResumenMonchisMarketplaceConFormato,

                columnasResumenImportes: columnasResumenImportes,
                dataResumenImportesConFormato: tablaResumenImportes,

                botonesResumen: botonesResumen,
            };
        });

        setTablaBalanceData(tablaBalanceConFormato);
    };

    useEffect(() => {
        if (balanceData && dataTarjetas) {
            refrescarDatosTarjetaCabecera();
            refrescarDatosTabla();
        }
    }, [balanceData, dataTarjetas]);

    const disabledDate = (current) => {
        const day = moment().subtract(1).format('YYYY-MM-DD');
        return current > moment(day).subtract(1, 'd');
    };

    // modal del haciendo corte
    function modalCorte() {
        Modal.confirm({
            title: '¿Estás seguro/a que quieres hacer el corte?',
            icon: <ExclamationCircleOutlined />,
            content: 'presiona en Confirmar si estas seguro',
            okText: 'Confirmar',
            cancelText: 'Cancelar',
            onOk() {
                hacerCorteHandler();
            },
        });
    }

    const { tienePermiso } = useVerificarPermiso();
    const hasPermission = tienePermiso(
        Permisos.finanzas.facturacion.balance.verOpcionesPagoOnline,
    );

    const balanceChildren = (
        <div className={classes.balanceChildren}>
            {valoresInicialesForm && (
                <Form
                    name="basic"
                    layout="vertical"
                    form={balanceForm}
                    initialValues={valoresInicialesForm}
                    onFinish={(event) => verBalanceButtonHandler(event)}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Row gutter={24}>
                                <Col span={12}>
                                    <FormItemSelectFranquicia
                                        formInstance={balanceForm}
                                        disabled={obteniendoDatos}
                                    />
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col md={8} xl={6}>
                                    <Form.Item
                                        name="fecha"
                                        label="Fecha"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'Por favor seleccione una fecha',
                                            },
                                        ]}>
                                        <DatePicker
                                            name="fecha"
                                            format={formatoFechaInput}
                                            disabledDate={disabledDate}
                                            disabled={obteniendoDatos}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col md={8} xl={6}>
                                    <FormItemSelectTipoPago
                                        formInstance={balanceForm}
                                        esUsuarioComercio={esUsuarioComercio}
                                        tienePermisoVerPagoOnline={
                                            hasPermission
                                        }
                                        disabled={obteniendoDatos}
                                    />
                                </Col>
                                <Col md={8} xl={6}>
                                    <FormItemSelectFamilia
                                        formInstance={balanceForm}
                                        disabled={obteniendoDatos}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={4} style={{ marginTop: 'auto' }}>
                            <ProtectedComponent
                                permiso={
                                    Permisos.finanzas.facturacion.balance
                                        .generarArchivo
                                }>
                                <Form.Item>
                                    <Button
                                        type="secondary"
                                        htmlType="button"
                                        className="PrimaryOutlinedCustom"
                                        loading={
                                            haciendoCorte || obteniendoDatos
                                        }
                                        onClick={() => modalCorte()}
                                        block={true}>
                                        Hacer Corte
                                    </Button>
                                </Form.Item>
                            </ProtectedComponent>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    className="PrimaryCustom"
                                    htmlType="submit"
                                    loading={obteniendoDatos}
                                    block={true}>
                                    Ver Balance
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}
            <Row gutter={20} className={classes.cardsRow}>
                <Col span={6}>
                    <Card
                        icon={<CustomSVG name="money" />}
                        cargando={obteniendoDatos}
                        backgroundcolor="var(--amarillo)">
                        <strong>{tarjetasCabecera.cantidadVentas}</strong>
                        <small>Ventas</small>
                        <small>{tarjetasCabecera.totalVentas}</small>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        icon={<CustomSVG name="driver" />}
                        cargando={obteniendoDatos}
                        backgroundcolor="var(--naranja)">
                        <strong>{tarjetasCabecera.cantidadDrivers}</strong>
                        <small>Drivers solicitados</small>
                        <small>{tarjetasCabecera.totalDrivers}</small>
                    </Card>
                </Col>
                <Col span={6}>
                    <Card
                        icon={<CustomSVG name="money" />}
                        cargando={obteniendoDatos}
                        backgroundcolor="var(--rosa)">
                        <small>Online</small>
                        <small style={{ marginBottom: '10px' }}>
                            {tarjetasCabecera.totalPagoOnline}
                        </small>
                        <small>Efectivo</small>
                        <small>{tarjetasCabecera.totalEfectivo}</small>
                    </Card>
                </Col>
            </Row>
            <Divider style={{ border: 'none', margin: '1.2rem 0' }} />
            {/* Tabla balance */}
            <BalanceTable
                key="tabla-balance"
                columnasBalance={columnasFacturacionBalance} //columnasBalance
                dataBalance={tablaBalanceData} //tablaBalanceData
                refrescarDatos={refrescarDatosHandler} // accion de refrescar datos para los botones del resumen
                loading={obteniendoDatos}
                puedeCargarPago="false"
                pagination={{
                    pageSize: totalRegistrosPorPaginaBalance,
                    total: cantidadRegistrosBalance,
                    current: paramsBalance?.page ? paramsBalance.page : 1,
                    showSizeChanger: false,
                    onChange: (page) => cambioDePaginaHandler(page),
                }}></BalanceTable>
            <HacerCorteEspecialModal />
        </div>
    );
    return <ABMLayout titulo="Balance">{balanceChildren}</ABMLayout>;
};

export default Balance;
