import {
    PRODUCT_REPORT_LOADING,
    PRODUCT_LIST,
    FRANCHISES_SELECT_LIST,
    FILTERED_FRANCHISE_LIST,
    NEW_PRODUCT_LOADING,
    PRODUCT_ACTIONS_MODAL_VISIBLE,
    CLOSE_PRODUCT_ACTIONS_MODAL,
} from './Types';

export const initialState = {
    loading: false,
    franchiseSelectList: null,
    filters: null,
    productList: null,
    loadingNewProduct: false,
    productActionsModalVisible: false,
};

const AdministrarReducer = (state = initialState, action) => {
    switch (action.type) {
        case PRODUCT_LIST:
            return {
                ...state,
                productList: action.payload.productList,
            };
        case FRANCHISES_SELECT_LIST:
            return {
                ...state,
                franchiseSelectList: action.payload.franchiseSelectList,
            };
        case FILTERED_FRANCHISE_LIST:
            return {
                ...state,
                filters: action.payload.filters,
            };
        case PRODUCT_REPORT_LOADING:
            return {
                ...state,
                loading: !state.loading,
            };
        case PRODUCT_ACTIONS_MODAL_VISIBLE:
            return {
                ...state,
                productActionsModalVisible: !state.productActionsModalVisible,
            };
        case CLOSE_PRODUCT_ACTIONS_MODAL:
            return {
                ...state,
                productActionsModalVisible: false,
            };
        case NEW_PRODUCT_LOADING:
            return {
                ...state,
                loadingNewProduct: !state.loadingNewProduct,
            };
        default:
            return state;
    }
};

export default AdministrarReducer;
