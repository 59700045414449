import React, { useContext, useEffect, useRef, useState } from 'react';
import {
    Card,
    Menu,
    Row,
    Col,
    Form,
    Button,
    Select,
    Table,
    Switch,
    Modal,
    Space,
    Typography,
    Dropdown,
} from 'antd';
import { ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';

import prefContext from '../../../../context/preferencias/prefContext';

import useFranchiseAndBranchStatus from '../../../hooks/useFranchiseAndBranchStatus';

import classes from './index.module.css';

import ABMLayout from '../../../../layouts/ABMLayout';

import ScheduleDisableBranchesModal from '../../../molecules/administrar/Marcas/Estado/ScheduleDisableBranchesModal';
import ChooseDisableBranchesMethodModal from '../../../molecules/administrar/Marcas/Estado/ChooseDisableBranchesMethodModal';
import ConfirmDisableBranchesModal from '../../../molecules/administrar/Marcas/Estado/ConfirmDisableBranchesModal';
import FranchiseHistoryModal from '../../../molecules/administrar/Marcas/Historial/FranchiseHistoryModal';
import useFranchiseHistoryAudits from 'components/hooks/useFranchiseHistoryAudits';
import { Tooltip } from '../../../../../node_modules/antd/lib/index';

const Marcas = () => {
    const { Text } = Typography;
    const brandsHook = useFranchiseAndBranchStatus();
    const {
        closeViewHistoryModal,
        isHistoryModalOpen,
        loadingFranchiseHistory,
        obtainedFranchiseHistoryData,
        selectedFranchiseName,
        viewHistoryModal,
    } = useFranchiseHistoryAudits();

    const { abrirCerrarSidebar } = useContext(prefContext);

    const [chooseDisableBranchesModal, setChooseDisableBranchesModal] =
        useState({
            title: '',
            isChooseMethodVisible: false,
            isChooseReasonVisible: false,
            isConfirmDisableVisible: false,
            isManual: true,
            franchiseId: null,
            branchId: null,
            scheduleId: null,
            reasonId: null,
            reasonName: null,
            customUserReason: null,
            hours: null,
            isEdit: false,
            timeDown: null,
            time_up: null,
        });
    const [franchiseSelectOpen, setFranchiseSelectOpen] = useState(false);
    const selectFranchisesRef = useRef(null);

    // Search form actions handling

    const [form] = Form.useForm();
    const { Option } = Select;

    const franchiseChangeHandler = (userSelectedFranchises) => {
        setFranchiseSelectOpen(false);
        setSearchValue(null);
        brandsHook.updateSelectedFranchises(userSelectedFranchises);
        form.setFieldsValue({ refineSearch: null });
    };

    const branchChangeHandler = (userSelectedBranches) => {
        brandsHook.updateSelectedBranches(userSelectedBranches);
    };

    const franchiseSelectBlurChangeHandler = () => {
        setFranchiseSelectOpen(false);
    };

    const franchiseSelectClickHandler = () => {
        setFranchiseSelectOpen((currState) => !currState);
    };

    useEffect(() => {
        if (brandsHook.branchesList?.length) {
            setFranchiseSelectOpen(true);
            selectFranchisesRef.current.focus();
        }
    }, [brandsHook.branchesList]);

    const audienceChangeHandler = (userSelectedAudiences) => {
        brandsHook.updateSelectedAudicences(userSelectedAudiences);
    };

    const franchisesAreSelected = !!brandsHook.selectedFranchises.length;

    const franchisesOptions = brandsHook.franchisesList.map((franchise) => (
        <Option key={franchise.id} value={franchise.id}>
            {franchise.name}
        </Option>
    ));

    const branchesOptions = brandsHook.branchesList.map((branch) => (
        <Option key={branch.id} value={JSON.stringify(branch)}>
            {branch.name}
        </Option>
    ));

    const audiencesOptions = brandsHook.audiencesList.map((item) => (
        <Option key={item.id} value={item.id}>
            {item.name}
        </Option>
    ));

    const searchButtonHandler = () => {
        brandsHook.getBrandsList();
    };

    const pageChangeHandler = (page) => {
        window.scrollTo({ top: 100, behavior: 'smooth' });
        brandsHook.getBrandsList(page);
    };

    const downloadExcelButtonHandler = () => {
        brandsHook.getExcelFile();
    };

    const [openEnableAllModal, setOpenEnableAllModal] = useState();
    const [enableModalTitle, setEnableModalTitle] = useState();

    // Enable branches actions
    const showConfirmEnableBrandsModal = (
        title,
        franchiseId,
        branchId,
        scheduleId,
    ) => {
        Modal.confirm({
            title,
            icon: <ExclamationCircleOutlined />,
            okText: 'Sí, habilitar',
            centered: true,
            onOk: () =>
                brandsHook.enableManuallyBranches(
                    franchiseId,
                    branchId,
                    scheduleId,
                ),
        });
    };

    const openEnableAllBranches = () => {
        setOpenEnableAllModal(true);
        setEnableModalTitle('Habilitar todas las franquicias y sucursales');
    };

    const enableAllManuallyHandler = (franchiseId, branchId, scheduleId) => {
        brandsHook.enableManuallyBranches(franchiseId, branchId, scheduleId);
        setOpenEnableAllModal(false);
    };

    const enableAllScheduledHandler = (
        franchiseId,
        branchId,
        scheduleId,
        audienceId,
    ) => {
        brandsHook.enableScheduledBranches(
            franchiseId,
            branchId,
            scheduleId,
            audienceId,
        );
        setOpenEnableAllModal(false);
    };

    // Disable branches actions

    const disableAllBranchesButtonHandler = () => {
        setChooseDisableBranchesModal({
            title: 'Deshabilitar todas las sucursales',
            isChooseMethodVisible: true,
            isManual: true,
            franchiseId: null,
            branchId: null,
            scheduleId: null,
        });
    };

    const submitChosenDisableBranchesMethodHandler = (isManual) => {
        if (isManual) {
            setChooseDisableBranchesModal((currState) => ({
                ...currState,
                isChooseMethodVisible: false,
                isChooseReasonVisible: false,
                isConfirmDisableVisible: true,
                isManual: isManual,
            }));
        } else {
            setChooseDisableBranchesModal((currState) => ({
                ...currState,
                isChooseMethodVisible: false,
                isChooseReasonVisible: true,
                isConfirmDisableVisible: false,
                isManual: isManual,
            }));
        }
    };

    const submitScheduleDisableBranchesHandler = (scheduleData) => {
        setChooseDisableBranchesModal((currState) => ({
            ...currState,
            isChooseReasonVisible: false,
            isConfirmDisableVisible: true,
            reasonId: scheduleData.reasonId,
            reasonName: scheduleData.reasonName,
            customUserReason: scheduleData.customUserReason,
            hours: scheduleData.hours,
        }));
    };

    const confirmDisableBranchesContent =
        !chooseDisableBranchesModal.isManual && (
            <Space direction="vertical">
                <Space direction="horizontal">
                    <Text strong>Motivo: </Text>
                    <Text>{chooseDisableBranchesModal.reasonName}</Text>
                </Space>

                <Space direction="horizontal">
                    <Text strong>Habilitar en: </Text>
                    <Text>{chooseDisableBranchesModal.hours} hs.</Text>
                </Space>
            </Space>
        );

    const submitConfirmDisableBranches = () => {
        const disableBranchData = { ...chooseDisableBranchesModal };

        brandsHook.disableBranches(disableBranchData);

        cancelDisableBranchesButtonHandler();
    };

    const cancelDisableBranchesButtonHandler = () => {
        setChooseDisableBranchesModal({
            title: '',
            isChooseMethodVisible: false,
            isChooseReasonVisible: false,
            isConfirmDisableVisible: false,
            isManual: true,
            franchiseId: null,
            branchId: null,
            scheduleId: null,
            reasonId: null,
            reasonName: null,
            customUserReason: null,
            hours: null,
            isEdit: false,
            timeDown: null,
            time_up: null,
        });
    };

    const handleClickEditButton = (record) => {
        if (record?.lowType === 'Automatica') {
            setChooseDisableBranchesModal({
                title: 'Confirmá que queres deshabilitar esta sucursal',
                isChooseReasonVisible: true,
                isManual: false,
                franchiseId: null,
                branchId: null,
                scheduleId: record.id,
                reasonId: null,
                reasonName: null,
                customUserReason: null,
                hours: null,
                isEdit: true,
                timeDown: null,
                time_up: null,
            });
        }
    };

    // Screen actions handling

    const enableDisableBranchSwitchHandler = (
        enableBranch,
        franchiseId,
        branchId,
        scheduleId,
        type,
    ) => {
        if (enableBranch) {
            const title = 'Confirmá que querés habilitar esta sucursal';
            showConfirmEnableBrandsModal(
                title,
                franchiseId,
                branchId,
                scheduleId,
                type,
            );
        } else {
            setChooseDisableBranchesModal({
                title: 'Confirmá que querés deshabilitar esta sucursal',
                isChooseMethodVisible: true,
                franchiseId,
                branchId,
                scheduleId,
            });
        }
    };

    const columns = [
        {
            title: 'Último estado',
            dataIndex: 'lastState',
            key: 'lastState',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franchise',
            key: 'franchise',
        },
        {
            title: 'Sucursal',
            dataIndex: 'branch',
            key: 'branch',
        },
        {
            title: 'Se reactiva el',
            dataIndex: 'reactivate',
            key: 'reactivate',
        },
        {
            title: 'Motivo',
            dataIndex: 'reason',
            key: 'reason',
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
            render: (_, record) => {
                return (
                    <Switch
                        checked={record?.defaultChecked}
                        onClick={(isChecked) => {
                            const enableBranch = isChecked;
                            const franchiseId = record.franchiseId;
                            const branchId = record.branchId;
                            const scheduleId = record.id;
                            const type = record.lowType;
                            enableDisableBranchSwitchHandler(
                                enableBranch,
                                franchiseId,
                                branchId,
                                scheduleId,
                                type,
                            );
                        }}
                    />
                );
            },
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            key: 'actions',
            render: (_, record) => {
                const enableEditButton = () => {
                    const theSwitchIsDisable = !record?.branchEnabled;

                    if (record?.lowType === 'Automatica') {
                        if (theSwitchIsDisable) return true;
                    }

                    if (record?.lowType === 'Manual') {
                        return false;
                    }
                };

                const items = [
                    {
                        label: 'Ver historial',
                        key: '1',
                    },
                    enableEditButton() && {
                        label: 'Editar baja programada',
                        key: '2',
                    },
                ];

                const menuItemsClickHandler = (event) => {
                    const itemKey = event.key;
                    switch (itemKey) {
                        case '1': {
                            viewHistoryModal(record);
                            break;
                        }
                        case '2': {
                            handleClickEditButton(record);
                            break;
                        }
                        default:
                            break;
                    }
                };

                return (
                    <Dropdown
                        menu={{
                            items,
                            onClick: menuItemsClickHandler,
                        }}
                        placement="bottom">
                        <Button icon={<MoreOutlined />} />
                    </Dropdown>
                );
            },
        },
    ];

    useEffect(() => {
        brandsHook.getFranchisesList();
        brandsHook.getAudiencesList();
        brandsHook.getReasonsToScheduleDisable();
    }, []);

    useEffect(() => abrirCerrarSidebar(false), []);

    // Agregar un estado para rastrear el valor de búsqueda
    const [searchValue, setSearchValue] = useState('');
    const pageIsFocus = document.hasFocus();

    // Si existe un texto en el campo de búsqueda, se mantiene desplegado el dropdown y persiste el texto
    useEffect(() => {
        if (searchValue) {
            setFranchiseSelectOpen(true);
        }
    }, [searchValue, pageIsFocus]);

    // Función para manejar el cambio en el campo de búsqueda
    const handleSearchChange = (value) => {
        setSearchValue(value);
    };

    return (
        <ABMLayout titulo="Marcas" loading={brandsHook.loadingBrands}>
            <Card>
                <Menu mode="horizontal" defaultSelectedKeys={['state']}>
                    <Menu.Item key="state">Estado</Menu.Item>
                </Menu>

                <div className="mt36">
                    <Form form={form} layout="vertical">
                        <Row gutter={12} align="middle">
                            <Col span={4}>
                                <Form.Item label="Franquicia" name="franchise">
                                    <Select
                                        maxTagCount="responsive"
                                        mode="multiple"
                                        optionFilterProp="children"
                                        placeholder="Todas las franquicias"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={franchiseChangeHandler}
                                        open={franchiseSelectOpen}
                                        onClick={franchiseSelectClickHandler}
                                        ref={selectFranchisesRef}
                                        onBlur={
                                            franchiseSelectBlurChangeHandler
                                        }
                                        disabled={brandsHook.loadingBrands}
                                        onSearch={handleSearchChange}
                                        searchValue={searchValue}>
                                        {franchisesOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item label="Sucursal" name="branch">
                                    <Select
                                        maxTagCount="responsive"
                                        mode="multiple"
                                        optionFilterProp="children"
                                        placeholder="Todas las sucursales"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={branchChangeHandler}
                                        disabled={!franchisesAreSelected}>
                                        {branchesOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={10}>
                                <Form.Item
                                    label="Refinar búsqueda"
                                    name="refineSearch">
                                    <Select
                                        allowClear
                                        optionFilterProp="children"
                                        placeholder="Seleccioná una audiencia"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        onChange={audienceChangeHandler}
                                        disabled={franchisesAreSelected}>
                                        {audiencesOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Row align="bottom">
                                    <Col span={12}>
                                        <Button
                                            type="primary"
                                            htmlType="button"
                                            onClick={searchButtonHandler}>
                                            Buscar
                                        </Button>
                                    </Col>
                                    <Col span={12}>
                                        <Button
                                            htmlType="button"
                                            onClick={
                                                downloadExcelButtonHandler
                                            }>
                                            Descargar Excel
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>

                    <Row gutter={12} align="middle">
                        <Col span={18} className={classes.columnaMostrarDatos}>
                            <Row gutter={12} justify="center" className="w100">
                                <Col span={6} className="text-center">
                                    <span
                                        className={
                                            classes.textoFranquiciasHabilitadas
                                        }>
                                        {brandsHook.branchesEnabled}
                                    </span>{' '}
                                    {parseInt(brandsHook.branchesEnabled) === 1
                                        ? 'Sucursal habilitada'
                                        : 'Sucursales habilitadas'}
                                </Col>

                                <Col span={6} className="text-center">
                                    <span
                                        className={
                                            classes.textoFranquiciasDeshabilitadas
                                        }>
                                        {brandsHook.branchesDisabled}
                                    </span>{' '}
                                    {parseInt(brandsHook.branchesDisabled) === 1
                                        ? 'Sucursal deshabilitada'
                                        : 'Sucursales deshabilitadas'}
                                </Col>

                                <Col span={6} className="text-center">
                                    <span className={classes.textoDrivers}>
                                        {brandsHook.branchesWithDrivers}
                                    </span>{' '}
                                    {parseInt(
                                        brandsHook.branchesWithDrivers,
                                    ) === 1
                                        ? 'driver'
                                        : 'drivers'}
                                </Col>

                                <Col span={6} className="text-center">
                                    <span
                                        className={classes.textoAltasManuales}>
                                        {brandsHook.manuallyEnabledBranches}
                                    </span>{' '}
                                    {parseInt(
                                        brandsHook.manuallyEnabledBranches,
                                    ) === 1
                                        ? 'alta manual'
                                        : 'altas manuales'}{' '}
                                </Col>
                            </Row>
                        </Col>
                        <Col span={6}>
                            <Row gutter={12} justify="center">
                                <Col span={12}>
                                    <Tooltip title="Habilitar todas las franquicias y sucursales">
                                        <Button
                                            block
                                            type="link"
                                            disabled={
                                                !brandsHook.brandsList.length
                                            }
                                            onClick={openEnableAllBranches}>
                                            Habilitar todas
                                        </Button>
                                    </Tooltip>
                                </Col>
                                <Col span={12}>
                                    <Tooltip title="Deshabilitar todas las franquicias y sucursales con baja manual">
                                        <Button
                                            block
                                            danger
                                            type="link"
                                            disabled={
                                                !brandsHook.brandsList.length
                                            }
                                            onClick={
                                                disableAllBranchesButtonHandler
                                            }>
                                            Deshabilitar todas
                                        </Button>
                                    </Tooltip>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row span={24} className="mt32">
                        <Table
                            className="w100"
                            columns={columns}
                            dataSource={brandsHook.brandsList}
                            rowKey="id"
                            pagination={{
                                pageSize: 15,
                                showSizeChanger: false,
                                total: brandsHook.brandsListTotalRows,
                                current: brandsHook.brandsListPage,
                                onChange: pageChangeHandler,
                            }}
                        />
                    </Row>
                </div>
            </Card>
            <ChooseDisableBranchesMethodModal
                title={chooseDisableBranchesModal.title}
                isVisible={chooseDisableBranchesModal.isChooseMethodVisible}
                onSubmit={submitChosenDisableBranchesMethodHandler}
                onCancel={cancelDisableBranchesButtonHandler}
            />
            <ScheduleDisableBranchesModal
                title={chooseDisableBranchesModal.title}
                isVisible={chooseDisableBranchesModal.isChooseReasonVisible}
                reasonsToScheduleDisable={brandsHook.reasonsToScheduleDisable}
                onCancel={cancelDisableBranchesButtonHandler}
                onSubmit={submitScheduleDisableBranchesHandler}
            />
            <ConfirmDisableBranchesModal
                title={chooseDisableBranchesModal.title}
                isVisible={chooseDisableBranchesModal.isConfirmDisableVisible}
                content={confirmDisableBranchesContent}
                onCancel={cancelDisableBranchesButtonHandler}
                onSubmit={submitConfirmDisableBranches}
            />
            <FranchiseHistoryModal
                selectedFranchiseName={selectedFranchiseName}
                open={isHistoryModalOpen}
                closeViewHistoryModal={closeViewHistoryModal}
                franchiseHistoryDataSource={obtainedFranchiseHistoryData}
                isLoading={loadingFranchiseHistory}
            />
            <Modal
                open={openEnableAllModal}
                title={enableModalTitle}
                centered={true}
                width={600}
                onCancel={() => setOpenEnableAllModal(false)}
                footer={[
                    <Button
                        key="cancel"
                        onClick={() => setOpenEnableAllModal(false)}>
                        Cancelar
                    </Button>,
                    <Button
                        key="programmed"
                        type="primary"
                        onClick={enableAllScheduledHandler}>
                        Habilitar bajas programadas
                    </Button>,
                    <Button
                        key="manual"
                        type="primary"
                        onClick={enableAllManuallyHandler}>
                        Habilitar bajas manuales
                    </Button>,
                ]}>
                <Row className={classes.modalRow}>
                    <Col className={classes.exclamationIcon}>
                        <ExclamationCircleOutlined />
                    </Col>
                    <Col>
                        <Text strong>
                            Esta acción afectará a todas las franquicias y
                            sucursales.
                        </Text>
                        <br />
                        <Text type="secondary">
                            Asegurate de seleccionar la opción adecuada.
                        </Text>
                    </Col>
                </Row>
            </Modal>
        </ABMLayout>
    );
};

export default Marcas;
