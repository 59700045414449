import React, { useContext, useState } from 'react';
import { Table, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';

import styles from './styles.module.css';

import FormateoDatos from './FormateoDatos';
import useObtenerDatosCupones from '../../../../hooks/useObtenerDatosCupones';

import UsuariosContext from '../../../../../context/usuario/UsuariosContext';

import ModalCondiciones from './ModalCondiciones';

const CuponesUsuario = () => {
    const history = useHistory();
    const location = useLocation();
    const {
        abrirModalCondicionesCupones,
        isModalVisible,
        cambiarTabSeleccionada,
    } = useContext(UsuariosContext);
    const [datosCupon, setDatosCupon] = useState({});
    const { obteniendoDatos } = useObtenerDatosCupones();

    const verDetalleDeLaOrden = (cupon) => {
        setDatosCupon(cupon);
        abrirModalCondicionesCupones();
    };

    const verOrdenBotonHandler = (datosCupon) => {
        const ordersId = datosCupon?.id_de_ordenes?.join(',');

        if (ordersId) {
            cambiarTabSeleccionada('6');
            history.replace(`${location.pathname}?orders=${ordersId}`);
        }
    };

    const columns = [
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
        },
        {
            title: 'Fecha de inicio',
            dataIndex: 'inicio',
            key: 'inicio',
        },
        {
            title: 'Fecha de fin',
            dataIndex: 'fin',
            key: 'fin',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            key: 'monto',
        },
        {
            title: 'Condiciones de uso',
            dataIndex: 'condiciones',
            key: 'condiciones',
            render: (condiciones, record) => {
                return (
                    <>
                        <span key={condiciones[0]}>{condiciones[0]}</span>
                        <br />
                        <span key={condiciones[1]}>
                            <span className={styles.condiciones}>
                                Condiciones:{' '}
                            </span>
                            {/**
                             * Si el cupón no tiene condiciones, se genera solamente el texto, en caso contrario, se genera el botón.
                             */}
                            {condiciones[1] === 'sin condiciones' ? (
                                condiciones[1]
                            ) : (
                                <Button
                                    type="link"
                                    className={styles.botonCondiciones}
                                    onClick={() => verDetalleDeLaOrden(record)}>
                                    {condiciones[1]}
                                </Button>
                            )}
                        </span>
                    </>
                );
            },
        },
        {
            title: 'Motivo',
            dataIndex: 'motivo',
            key: 'motivo',
        },
        {
            title: 'Creador/a',
            dataIndex: 'creador',
            key: 'creador',
        },
        {
            title: '',
            dataIndex: 'boton',
            key: 'boton',
            render: (_, record) => {
                return (
                    <div>
                        <Button
                            shape="circle"
                            icon={<SearchOutlined />}
                            onClick={() => {
                                verOrdenBotonHandler(record);
                            }}
                            disabled={
                                record?.id_de_ordenes?.length ? false : true
                            }
                        />
                    </div>
                );
            },
        },
    ];

    const couponsList = FormateoDatos();

    return (
        <>
            <Table
                columns={columns}
                dataSource={couponsList}
                loading={obteniendoDatos}
            />
            {isModalVisible && <ModalCondiciones datosCupon={datosCupon} />}
            {/* <ModalCondiciones datosCupon={datosCupon} /> */}
        </>
    );
};

export default CuponesUsuario;
