import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Space, Button, Divider } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './PopUpConfirmCupon.module.css';
import { useContext } from 'react';
import CuponesContext from '../../../../context/programaRecompensa/cupones/CuponesContext';

const PopUpConfirmCupon = ({
    visible,
    onCancel,
    onOk,
    mode,
    couponHasUsers,
}) => {
    const { customizedNotification } = useContext(CuponesContext);

    const isEditing = mode === 'EDITAR';

    return !customizedNotification && !isEditing && couponHasUsers ? (
        <Modal
            open={visible}
            title="Crear cupón con notificaciones estándar"
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Personalizar
                </Button>,
                <Button key="submit" type="primary" onClick={onOk}>
                    Crear cupón
                </Button>,
            ]}>
            <Space direction="vertical">
                <Space>
                    <ExclamationCircleOutlined className={styles.icon} /> Al
                    crear el cupón se notifica a los usuarios con estos mensajes
                    predeterminados:
                </Space>

                <Divider className={styles.divider} />

                <Space className={styles.textContainer} direction="vertical">
                    <span className={styles.title}>Push: </span>
                    <p>
                        Tenés un cupón de descuento🎊🎉 ¡Aprovechá hasta el
                        [fecha de vencimiento]!
                    </p>
                </Space>

                <Space className={styles.textContainer} direction="vertical">
                    <span className={styles.title2}>Mensaje de texto: </span>
                    <p>
                        ¡Hola! Tenés un cupón de descuento en la App de Delivery
                        Monchis, aprovechá hasta [fecha de vencimiento],
                        exclusivo para pagos online http://bit.ly/Monchisapp
                    </p>
                </Space>

                <Divider className={styles.divider} />

                <Space className={styles.textContainer} direction="vertical">
                    <p>
                        Todavía podés personalizar estas notificaciones o
                        continuar creando el cupón.
                    </p>
                </Space>
            </Space>
        </Modal>
    ) : (
        <Modal
            open={visible}
            centered
            title={`¿${isEditing ? 'Editar' : 'Crear'} cupón?`}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Cancelar
                </Button>,
                <Button key="submit" type="primary" onClick={onOk}>
                    {isEditing ? 'Editar' : 'Crear'} cupón
                </Button>,
            ]}>
            <Space>
                <ExclamationCircleOutlined className={styles.icon} />
                {`¿Estás seguro de que quieres ${
                    isEditing ? 'editar' : 'crear'
                } este cupón?`}
            </Space>
        </Modal>
    );
};

PopUpConfirmCupon.propTypes = {
    visible: PropTypes.bool,
    onCancel: PropTypes.func,
    onOk: PropTypes.func,
    mode: PropTypes.string,
    couponHasUsers: PropTypes.bool,
};

export default PopUpConfirmCupon;
