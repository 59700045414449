import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CorteTransaccion from './CorteTransaccion';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';

const Cortes = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/corte-transaccion`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.reportes.cortes.corteTransaccion}
                        componente={CorteTransaccion}
                    />
                )}
            />
        </Switch>
    );
};

export default Cortes;

Cortes.propTypes = {
    match: PropTypes.object,
};
