import React, { useEffect, useState } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { tiempoTranscurrido as obtenerTiempoTranscurrido } from '../../../../constants';
import classes from './TiempoPedido1.module.css';
import ReactTimeAgo from 'react-time-ago';
const { Text } = Typography;
const tiempoResaltarAtrasado = 1000 * 60; //un minuto en milisegundos

const TiempoPedido = ({
    fechaPedido,
    tiempoEstimado,
    ordenOriginal,
    obtenerAtrasado,
}) => {
    const [ordenAtrasada, setOrdenAtrasada] = useState(false);
    const fechaPedidoFormateada = fechaPedido
        ? fechaPedido.toString().replace('.000Z', '').replace('T', ' ')
        : '';
    const fechaHoraHechoEl = `${new Date(
        fechaPedidoFormateada,
    ).toLocaleDateString('es-PY')} ${new Date(
        fechaPedidoFormateada,
    ).toLocaleTimeString('es-PY', { hour12: false, timeStyle: 'short' })} hs.`;

    const orderStateId = ordenOriginal.order_state_id;

    let tiempoTexto = 'Transcurrido: ';
    const tiempoHorasClass = `${ordenAtrasada ? classes.ordenAtrasada : ''}`;
    let tiempoHoras = (
        <>
            <ReactTimeAgo
                className={tiempoHorasClass}
                date={new Date(fechaPedidoFormateada)}
                timeStyle="twitter-now"
            />
        </>
    );

    let mostrarTiempoTotal = false;

    if (orderStateId === 6 || orderStateId === 2) {
        mostrarTiempoTotal = true;
        tiempoTexto = 'Tiempo total:';
        const orderStateHistories = ordenOriginal?.order_state_histories;
        if (orderStateHistories?.length) {
            const tiempoFinal = new Date(
                orderStateHistories[0]?.started_at
                    ?.toString()
                    ?.replace('T', ' ') || '',
            );
            const tiempoTotalObj = obtenerTiempoTranscurrido(
                fechaPedido,
                tiempoEstimado,
                tiempoFinal,
            );
            const tiempoTotal = tiempoTotalObj.transcurrido;
            tiempoHoras = (
                <Text className={classes.ordenAtrasada}> {tiempoTotal}</Text>
            );
        }
    }

    const resaltarAtrasado = (fechaPedido, tiempoEstimado) => {
        if (fechaPedido) {
            const { isAtrasado } = obtenerTiempoTranscurrido(
                fechaPedido,
                tiempoEstimado,
            );
            setOrdenAtrasada(isAtrasado);
        }
    };

    useEffect(() => {
        if (!mostrarTiempoTotal && fechaPedido && tiempoEstimado) {
            resaltarAtrasado(fechaPedido, tiempoEstimado);
            const timer = setInterval(() => {
                resaltarAtrasado(fechaPedido, tiempoEstimado);
            }, tiempoResaltarAtrasado);
            return () => {
                clearInterval(timer);
            };
        }
    }, [fechaPedido, orderStateId, tiempoEstimado]);

    if (obtenerAtrasado) {
        return ordenAtrasada;
    } else {
        return (
            <div>
                <div>
                    <Text> {fechaHoraHechoEl}</Text>
                </div>
                {orderStateId !== 11 &&
                    orderStateId !== 10 &&
                    orderStateId !== 12 && (
                        <>
                            <div>
                                <Text
                                    className={tiempoHorasClass}
                                    level={5}
                                    strong={false}>
                                    {tiempoTexto}
                                </Text>
                                <Text strong={false}>
                                    <strong>{tiempoHoras}</strong>
                                </Text>
                            </div>
                        </>
                    )}
            </div>
        );
    }
};

TiempoPedido.propTypes = {
    fechaPedido: PropTypes.any,
    tiempoEstimado: PropTypes.any,
    tiempoTranscurrido: PropTypes.any,
    isOrdenRetrasada: PropTypes.func,
    tiempoTotal: PropTypes.bool,
    tiempoEspera: PropTypes.any,
    obtenerAtrasado: PropTypes.bool,
    ordenOriginal: PropTypes.any,
};

export default TiempoPedido;
