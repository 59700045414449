export const CUPONES_LISTA_LOADING = 'CUPONES_LISTA_LOADING';
export const CUPONES_LISTA = 'CUPONES_LISTA';
export const CUPONES_ESTABLECER_SELECCIONADO =
    'CUPONES_ESTABLECER_SELECCIONADO';
export const CUPONES_LOADING_FORM = 'CUPONES_LOADING_FORM';
export const CUPONES_TIPOS_PAGO_LOADING = 'CUPONES_TIPOS_PAGO_LOADING';
export const CUPONES_TIPOS_PAGO_LISTA = 'CUPONES_TIPOS_PAGO_LISTA';
export const CUPONES_TARJETAS_LOADING = 'CUPONES_TARJETAS_LOADING';
export const CUPONES_TARJETAS_LISTA = 'CUPONES_TARJETAS_LISTA';
export const CUPONS_CUSTOMIZED_NOTIFICATIONS =
    'CUPONS_CUSTOMIZED_NOTIFICATIONS';
