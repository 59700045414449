import React, { useContext, useEffect, useState } from 'react';
import {
    Form,
    Row,
    Col,
    Select as SelectAntd,
    Table,
    Divider,
    message,
} from 'antd';
import classes from './ListaComisiones.module.css';

import { formatoGuaranies } from '../../../../utils-components/Number';
import clienteAxios from '../../../../../config/axios';

import ReporteComisionesContext from '../../../../../context/reportes/ventas/comisiones/ReporteComisionesContext';

import Select from '../../../../atoms/Select';
import DatePicker from '../../../../atoms/DatePicker';
import Button from '../../../../atoms/Button';
import ABMLayout from '../../../../../layouts/ABMLayout';

const ListaComisiones = () => {
    const [obtenerValoresForm, setObtenerValoresForm] = useState({});
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [franquiciaId, setFranquiciaId] = useState('');
    const [loadingPdf, setLoadingPdf] = useState(false);
    const [conDriver, setConDriver] = useState('');

    // context
    const {
        obtenerHistorialComisiones,
        listaHistorialComisiones,
        obtenerFranquicias,
        franquicias,
        loadingTablaHistorial,
        seleccionarPaginadoTabla,
        loadingSelectFranquicia,
        totalHistorial,
        paginaActualHistorial,
    } = useContext(ReporteComisionesContext);

    const [comisionForm] = Form.useForm();

    useEffect(() => {
        obtenerFranquicias();
    }, []);

    useEffect(() => {
        if (!fechaDesde && !fechaHasta) return;
        let sgtePagina = {
            ...obtenerValoresForm,
            page: paginaActualHistorial,
        };
        obtenerHistorialComisiones(sgtePagina);
    }, [paginaActualHistorial]);

    const handleSubmit = (values) => {
        let datos = {
            date_start: values?.periodo[0].format('YYYY/MM/DD'),
            date_end: values?.periodo[1].format('YYYY/MM/DD'),
            franchise_id: values?.franquiciaId || undefined,
            has_monchis_driver: values?.conDriver || undefined,
            page: 1,
        };
        obtenerHistorialComisiones(datos);
        setObtenerValoresForm(datos);
        seleccionarPaginadoTabla(1);
    };

    const descargarHistorialComisionExcel = async () => {
        let datos = {};
        if (fechaDesde && fechaHasta) {
            datos = {
                date_start: fechaDesde,
                date_end: fechaHasta,
                franchise_id: franquiciaId || undefined,
                has_monchis_driver: conDriver || undefined,
            };
        }
        try {
            setLoadingPdf(true);
            const resp = await clienteAxios.get('commissions_download', {
                params: datos,
            });
            if (resp.data.success) {
                setLoadingPdf(false);
            }
            window.open(resp.data.data.link_commissions);
        } catch (error) {
            setLoadingPdf(false);
            message.error(
                error.response?.data.message ||
                    'Hubo un error al descargar, vuelva a intentarlo',
            );
        }
    };

    const changePage = (page) => {
        seleccionarPaginadoTabla(page);
    };

    const historialComisionTabla = listaHistorialComisiones.map((comision) => {
        const {
            franchise_id,
            franchise,
            ruc,
            legal_name,
            has_monchis_driver,
            precio_22000,
            precio_18000,
            precio_15000,
            precio_10000,
            precio_0,
            total_delivery_monchis,
            total_delivery,
            total_orden,
            total_comision,
            porcentaje,
        } = comision;
        return {
            idFranquicia: franchise_id,
            franquicia: franchise,
            razonSocial: legal_name,
            ruc,
            monchisDriver: has_monchis_driver,
            delivery22: precio_22000,
            delivery18: precio_18000,
            delivery15: precio_15000,
            delivery10: precio_10000,
            delivery0: precio_0,
            totalCostoDeliveryMonchis: formatoGuaranies(total_delivery_monchis),
            total_comision: formatoGuaranies(total_comision),
            porcentaje: `${+porcentaje}%`,
            totalOrden: formatoGuaranies(total_orden),
            totalDelivery: formatoGuaranies(total_delivery),
        };
    });

    const columns = [
        {
            title: 'ID Franquicia',
            dataIndex: 'idFranquicia',
            key: 'idFranquicia',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },
        {
            title: 'Razón Social',
            dataIndex: 'razonSocial',
            key: 'razonSocial',
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },
        {
            title: 'Monchis Driver',
            dataIndex: 'monchisDriver',
            key: 'monchisDriver',
        },
        {
            title: 'Delivery 22.000',
            dataIndex: 'delivery22',
            key: 'delivery22',
        },
        {
            title: 'Delivery 18.000',
            dataIndex: 'delivery18',
            key: 'delivery18',
        },
        {
            title: 'Delivery 15.000',
            dataIndex: 'delivery15',
            key: 'delivery15',
        },
        {
            title: 'Delivery 10.000',
            dataIndex: 'delivery10',
            key: 'delivery10',
        },
        {
            title: 'Delivery 0',
            dataIndex: 'delivery0',
            key: 'delivery0',
        },
        {
            title: 'Total costo delivery monchis',
            dataIndex: 'totalCostoDeliveryMonchis',
            key: 'totalCostoDeliveryMonchis',
        },
        {
            title: 'Porcentaje Comisión',
            dataIndex: 'porcentaje',
            key: 'porcentaje',
        },
        {
            title: 'Total Comisión',
            dataIndex: 'total_comision',
            key: 'total_comision',
        },
        {
            title: 'Total Orden',
            dataIndex: 'totalOrden',
            key: 'totalOrden',
        },
        {
            title: 'Total Delivery',
            dataIndex: 'totalDelivery',
            key: 'totalDelivery',
        },
    ];

    const rangoFechas = (valor) => {
        let fechadesde = null;
        let fechahasta = null;
        if (valor !== null) {
            if (valor.length > 0) {
                fechadesde = valor[0].format('YYYY-MM-DD');
                fechahasta = valor[1].format('YYYY-MM-DD');
            }
        }
        setFechaDesde(fechadesde);
        setFechaHasta(fechahasta);
    };

    const idFranquicia = (valor) => {
        setFranquiciaId(valor);
    };

    const driver = (valor) => {
        setConDriver(valor);
    };

    const reporteComisionesChildren = (
        <div className={classes.cardForm}>
            <Form
                name="basic"
                layout="vertical"
                form={comisionForm}
                onFinish={handleSubmit}>
                <Row gutter={24}>
                    <Col span={20}>
                        <Row gutter={24}>
                            <Col md={8} xl={8}>
                                <Form.Item
                                    name="periodo"
                                    label="Rango"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Este campo no puede ir vacío.',
                                        },
                                    ]}>
                                    <DatePicker
                                        disabled={loadingTablaHistorial}
                                        name="periodo"
                                        onChange={rangoFechas}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="franquiciaId"
                                    label="Franquicia">
                                    <Select
                                        allowClear
                                        disabled={loadingTablaHistorial}
                                        onChange={idFranquicia}
                                        name="franquiciaId"
                                        placeholder="Seleccione una opción"
                                        showSearch
                                        loading={loadingSelectFranquicia}
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }>
                                        <SelectAntd.Option value={undefined}>
                                            Todas
                                        </SelectAntd.Option>
                                        {franquicias &&
                                            franquicias.map((item) => (
                                                <SelectAntd.Option
                                                    key={item.franchise_id}
                                                    value={item.franchise_id}>
                                                    {item.name}
                                                </SelectAntd.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={24} align="bottom">
                            <Col md={8} xl={8}>
                                <Form.Item name="conDriver" label="Con Driver">
                                    <Select
                                        allowClear
                                        disabled={loadingTablaHistorial}
                                        placeholder="Seleccione una opción"
                                        onChange={driver}>
                                        <SelectAntd.Option value={undefined}>
                                            Todas
                                        </SelectAntd.Option>
                                        <SelectAntd.Option value="si">
                                            Si
                                        </SelectAntd.Option>
                                        <SelectAntd.Option value="no">
                                            No
                                        </SelectAntd.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row span={4} style={{ marginTop: '20px' }}>
                    <Col span={2}>
                        <Form.Item>
                            <Button
                                type="primary"
                                disabled={
                                    !(fechaDesde && fechaHasta) ||
                                    loadingTablaHistorial
                                }
                                htmlType="submit">
                                Buscar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Button
                                loading={loadingPdf}
                                type="primary"
                                disabled={
                                    !(fechaDesde && fechaHasta) ||
                                    loadingTablaHistorial
                                }
                                onClick={descargarHistorialComisionExcel}>
                                Descargar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Divider style={{ border: 'none', margin: '1.2rem 0' }} />
            <Row gutter={12}>
                <Col span={24}>
                    <Table
                        columns={columns}
                        loading={loadingTablaHistorial}
                        dataSource={historialComisionTabla}
                        size="middle"
                        scroll={{ x: 2200 }}
                        pagination={{
                            pageSize: 15,
                            total: totalHistorial,
                            showSizeChanger: false,
                            current: paginaActualHistorial,
                            onChange: (page) => changePage(page),
                        }}
                    />
                </Col>
            </Row>
        </div>
    );

    return (
        <ABMLayout titulo="Reporte de comisiones">
            {reporteComisionesChildren}
        </ABMLayout>
    );
};

export default ListaComisiones;
