import React from 'react';
import { ListaClientes } from './ListaClientes';
import { Route, Switch } from 'react-router-dom';
import ClientesState from '../../../../context/usuario/Clientes/ClientesState';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';
import PropTypes from 'prop-types';

const Clientes = ({ match }) => {
    return (
        <ClientesState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.usuarios.clientes.verPagina}
                            componente={ListaClientes}
                        />
                    )}
                />
            </Switch>
        </ClientesState>
    );
};

export default Clientes;

Clientes.propTypes = {
    match: PropTypes.object,
};
