import { Layout } from 'antd';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SideBar from '../../components/molecules/Sidebar/Sidebar';
import classes from './AppLayout.module.css';
import PrefContext from '../../context/preferencias/prefContext';

const AppLayout = ({ children }) => {
    // Conexion con el context de Preferencias del usuario
    const prefContext = useContext(PrefContext);
    const { isSidebarAbierto } = prefContext;

    return (
        <Layout>
            <SideBar />
            <Layout
                className={[
                    classes.layout,
                    isSidebarAbierto ? classes.sidebarAbierto : '',
                ].join(' ')}>
                {children}
            </Layout>
        </Layout>
    );
};

export default AppLayout;

AppLayout.propTypes = {
    children: PropTypes.node,
};
