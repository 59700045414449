import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import AdministrarState from '../../../../context/administrar/AdministrarState';

import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';

import ActualizarProductos from './ActualizarProductos/index';
import TodosProductos from './ListaProductos/index';
import GrupoAtributos from './GrupoAtributosLista/index';
import Atributos from './AtributosLista/index';
import Categorias from './CategoriasLista/index';

const Productos = ({ match }) => {
    return (
        <AdministrarState>
            <Switch>
                {/* TO-DO: Revisar permisos */}
                <Route
                    path={`${match.url}/actualizar`}
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.administrar.catastros.verPagina}
                            componente={ActualizarProductos}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/todos-productos`}
                    render={TodosProductos}
                />
                <Route
                    path={`${match.url}/grupo-atributos`}
                    render={GrupoAtributos}
                />
                <Route path={`${match.url}/atributos`} render={Atributos} />
                <Route path={`${match.url}/categorias`} render={Categorias} />
            </Switch>
        </AdministrarState>
    );
};

export default Productos;

Productos.propTypes = {
    match: PropTypes.object,
};
