import React, { useEffect, useState } from 'react';
import {
    Modal,
    Row,
    Col,
    Typography,
    Form,
    Radio,
    InputNumber,
    Space,
    Input,
    Divider,
} from 'antd';
import PropTypes from 'prop-types';

import ModalContent from '../../../../atoms/ModalContent';

const ScheduleDisableBranchesModal = ({
    isVisible,
    title,
    reasonsToScheduleDisable,
    onSubmit,
    onCancel,
}) => {
    const [scheduleBranchDisableForm] = Form.useForm();
    const [userChosenReason, setUserChosenReason] = useState();
    const [otherReasonInput, setOtherReasonInput] = useState();

    const chosenReasonChangeHandler = (e) => {
        setUserChosenReason(e.target.value);
    };

    const submitScheduleDisableBranchesHandler = () => {
        scheduleBranchDisableForm
            .validateFields()
            .then((values) => {
                const reason = JSON.parse(values.reason);
                // Prepare schedule disable branches data
                const disableBranchData = {
                    reasonId: reason.id !== 8 ? reason.id : otherReasonInput.id,
                    reasonName:
                        reason.name !== 'Otros'
                            ? reason.name
                            : otherReasonInput.userReason,
                    customUserReason:
                        reason?.userReason !== 'Otros'
                            ? reason?.userReason
                            : otherReasonInput.userReason,
                    hours: values.hours,
                };
                resetForm();
                onSubmit(disableBranchData);
            })
            .catch(() => {});
    };

    const customUserReasonIsDisabled = userChosenReason
        ? JSON.parse(userChosenReason).id !== 8
        : true;

    const reasonsOptions = reasonsToScheduleDisable.map((reason) =>
        reason?.name !== 'Otros' ? (
            <Radio.Button
                value={JSON.stringify(reason)}
                key={reason.id}
                style={{ width: '50%' }}>
                {reason?.name}
            </Radio.Button>
        ) : (
            <>
                <Row style={{ width: '100%' }}>
                    <Radio.Button
                        value={JSON.stringify(reason)}
                        key={reason.id}
                        style={{ width: '50%', display: 'flex' }}>
                        <Col>{reason?.name}</Col>
                        <Col style={{ marginTop: '1rem' }}>
                            {userChosenReason &&
                            JSON.parse(userChosenReason)?.name === 'Otros' ? (
                                <Input
                                    disabled={customUserReasonIsDisabled}
                                    placeholder="Agregá un motivo distinto"
                                    style={{
                                        marginLeft: '-1.2rem',
                                        width: '18rem',
                                    }}
                                    onChange={(e) => {
                                        setOtherReasonInput({
                                            id: 8,
                                            userReason: e.target.value,
                                        });
                                    }}
                                />
                            ) : null}
                        </Col>
                    </Radio.Button>
                </Row>
            </>
        ),
    );

    const resetForm = () => {
        scheduleBranchDisableForm.setFieldsValue({
            reason: '',
            userReason: '',
            hours: 0,
        });
    };

    // Establish initial values
    useEffect(() => {
        resetForm();
    }, []);

    return (
        <Modal
            centered
            footer={null}
            open={isVisible}
            title={title}
            width={700}
            closable={false}
            destroyOnClose>
            <ModalContent
                showWarnIcon
                isLoading={false}
                okText="Continuar"
                cancelText="Cancelar"
                onCancel={onCancel}
                onOk={submitScheduleDisableBranchesHandler}>
                <Form
                    layout="vertical"
                    form={scheduleBranchDisableForm}
                    name="scheduleBranchDisableForm">
                    <Space
                        direction="vertical"
                        style={{ width: '100%', marginBottom: '1rem' }}>
                        <Typography.Text strong>
                            ¿Cuál es el motivo para deshabilitar?
                        </Typography.Text>
                        <Row>
                            <Col style={{ width: '100%' }}>
                                <Form.Item
                                    name="reason"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Por favor, seleccioná una opción',
                                        },
                                    ]}>
                                    <Radio.Group
                                        style={{ width: '100%' }}
                                        onChange={chosenReasonChangeHandler}>
                                        <Space
                                            direction="vertical"
                                            style={{ width: '100%' }}>
                                            {reasonsOptions}
                                        </Space>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>

                    <Divider />

                    <Space direction="vertical">
                        <Typography.Text strong>
                            Seleccione el tiempo para volver a habilitar
                        </Typography.Text>
                        <Row gutter={12}>
                            <Col span={16}>
                                <Typography.Text
                                    style={{ display: 'block', marginTop: 12 }}>
                                    Habilitar luego del tiempo indicado (en
                                    horas):
                                </Typography.Text>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    name="hours"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Ingresa una hora válida',
                                        },
                                    ]}>
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        min={1}
                                        controls={false}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </ModalContent>
        </Modal>
    );
};

export default ScheduleDisableBranchesModal;

ScheduleDisableBranchesModal.propTypes = {
    isVisible: PropTypes.bool,
    title: PropTypes.string,
    reasonsToScheduleDisable: PropTypes.array,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
};
