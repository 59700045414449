import React from 'react';
import { Card, Col, Divider, Row } from 'antd';
import clases from './analiticas.module.css';
import PropTypes from 'prop-types';

const CardProductosMasComprados = ({ datosProductosTop10 }) => {
    return (
        <Card className={clases.cardProductosMasComprados}>
            <div className={clases.productosMasComprados}>
                {datosProductosTop10 &&
                    datosProductosTop10?.map((item) => (
                        <Row key={item?.nro} className={clases.itemMasComprado}>
                            <Col>
                                <h5>{`${item?.nro}. ${
                                    item?.product?.length <= 25
                                        ? item?.product
                                        : item?.product?.slice(0, 25) + '...'
                                }`}</h5>
                                <p>{`${item?.cant} pedidos`}</p>
                            </Col>
                            <Col>
                                <span>
                                    {item?.franquicia.length <= 18
                                        ? item?.franquicia
                                        : item?.franquicia?.slice(0, 18) +
                                          '...'}
                                </span>
                            </Col>
                            <Divider style={{ marginTop: '0px' }} />
                        </Row>
                    ))}
            </div>
        </Card>
    );
};

export default CardProductosMasComprados;

CardProductosMasComprados.propTypes = {
    datosProductosTop10: PropTypes.array,
};
