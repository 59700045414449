import { useContext } from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import { Permisos } from '../../constants/Permisos';

export const useVerificarPermiso = () => {
    const { permisos } = useContext(AuthContext);
    const tienePermiso = (permisoComparar) =>
        Object.keys(permisos).includes(permisoComparar);

    const esUsuarioComercio = () => !tienePermiso(Permisos.support.verPerfiles);

    const tipoUsuario = esUsuarioComercio();

    return {
        tienePermiso,
        esUsuarioComercio,
        tipoUsuario,
    };
};
