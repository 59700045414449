import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Cortes from './Cortes';
import Ventas from './Ventas';
import CuponesReporte from './CuponesReportes';
import ReporteOrdenes from './ReporteOrdenes';

const Reportes = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/cortes`} component={Cortes} />
            <Route path={`${match.url}/ventas`} component={Ventas} />
            <Route
                path={`${match.url}/programa-recompensa`}
                component={CuponesReporte}
            />
            <Route path={`${match.url}/ordenes`} component={ReporteOrdenes} />
        </Switch>
    );
};

export default Reportes;

Reportes.propTypes = {
    match: PropTypes.object,
};
