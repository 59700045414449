export const USUARIOS_GUARDAR_USUARIO_SELECCIONADO =
    'USUARIOS_GUARDAR_USUARIO_SELECCIONADO';
export const USUARIOS_GUARDAR_INFO = 'USUARIOS_GUARDAR_INFO';
export const USUARIOS_LISTA_TARJETAS = 'USUARIOS_LISTA_TARJETAS';
export const USUARIOS_LOADING_LISTA_TARJETAS =
    'USUARIOS_LOADING_LISTA_TARJETAS';
export const USUARIOS_LOADING_TARJETA = 'USUARIOS_LOADING_TARJETA';
export const OBTENER_DIRECCION_USUARIO = 'OBTENER_DIRECCION_USUARIO';
export const USUARIOS_LOADING_LISTA_DIRECCION =
    'USUARIOS_LOADING_LISTA_DIRECCION';
export const USUARIOS_LOADING_EDITAR_DIRECCION =
    'USUARIOS_LOADING_EDITAR_DIRECCION';
export const USUARIOS_LOADING_NUEVA_DIRECCION =
    'USUARIOS_LOADING_NUEVA_DIRECCION';
export const USUARIOS_LOADING_ELIMINAR_DIRECCION =
    'USUARIOS_LOADING_ELIMINAR_DIRECCION';
export const USUARIOS_LOADING_ESTADO_PEDIDO = 'USUARIOS_LOADING_ESTADO_PEDIDO';
export const OBTENER_LISTADO_PEDIDOS = 'OBTENER_LISTADO_PEDIDOS';
export const OBTENER_ESTADO_PEDIDOS = 'OBTENER_ESTADO_PEDIDOS';
export const USUARIOS_LOADING_LISTA_PEDIDO = 'USUARIOS_LOADING_LISTA_PEDIDO';
export const USUARIOS_MODAL_CUPON_CONDICIONES =
    'USUARIOS_MODAL_CUPON_CONDICIONES';
export const USUARIOS_TAB_SELECCIONADA = 'USUARIOS_TAB_SELECCIONADA';
export const USUARIOS_PATHNAME = 'USUARIOS_PATHNAME';
