import React from 'react';
import { Button, Dropdown as DropdownAntd } from 'antd';
import 'antd/dist/antd.css';
import PropTypes from 'prop-types';

const Dropdown = (props) => {
    return (
        <DropdownAntd overlay={props.menu} {...props}>
            <Button>
                {props.placeholder && <>{props.placeholder} </>}
                {props.icon}
            </Button>
        </DropdownAntd>
    );
};

Dropdown.propTypes = {
    menu: PropTypes.any,
    placeholder: PropTypes.string,
    icon: PropTypes.element,
};

export default Dropdown;
