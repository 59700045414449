import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Typography,
    Modal,
    Form,
    List,
} from 'antd';
import PropTypes from 'prop-types';
import classes from './FormItemUsarEn1.module.css';
import React, { useEffect, useState } from 'react';
import MapaPolygon from '../../../mapa/MapaPolygon';
import ModalContent from '../../../atoms/ModalContent';

const { Option } = Select;
const { Title } = Typography;

const usarEnDataset1 = [
    'Zona Geográfica',
    'Ciudad',
    'Barrio',
    'Familia',
    'Franquicia',
];
const usarEnDataset2 = {
    Ciudad: ['Familia'],
    'Zona Geográfica': ['Familia'],
    Barrio: ['Familia'],
    Familia: ['Franquicia'],
    Franquicia: ['Sucursal'],
};
const usarEnDataset3 = {
    Sucursal: ['Producto'],
};

const FormItemUsarEn = ({
    esEditar,
    cuponForm,
    obtenerFranquicias,
    obtenerCiudades,
    obtenerFamilias,
    obtenerBarrios,
    obtenerSucursales,
    obtenerProductos,
}) => {
    const [listaUsarEn, setListaUsarEn] = useState([]); // [{id: '', nombre: '', valor: '', tipo: ''}, ...]
    const [listaUsarEnOriginal, setListaUsarEnOriginal] = useState([]);

    const [listaUsarEnLoading, setListaUsarEnLoading] = useState(false);

    const [usarEnSelectValue1, setUsarEnSelectValue1] = useState();
    const [usarEnSelectValue2, setUsarEnSelectValue2] = useState();
    const [usarEnSelectValue3, setUsarEnSelectValue3] = useState();

    const usarEnSelectData1 = usarEnDataset1;
    const [usarEnSelectData2, setUsarEnSelectData2] = useState([]);
    const [usarEnSelectData3, setUsarEnSelectData3] = useState([]);

    const [selectComboValue1, setSelectComboValue1] = useState();
    const [selectComboValue2, setSelectComboValue2] = useState();
    const [selectComboValue3, setSelectComboValue3] = useState();

    const [selectComboData1, setSelectComboData1] = useState([]);
    const [selectComboData2, setSelectComboData2] = useState([]);
    const [selectComboData3, setSelectComboData3] = useState([]);

    const [erroresLista, setErroresLista] = useState('');

    const [modalGeografica, setModalGeografica] = useState(false);

    const formData = cuponForm.getFieldsValue(true);

    useEffect(() => {
        const usarEn = formData?.usarEn;
        const usarEnOriginal = formData?.usarEnOriginal;
        if (usarEn?.length && esEditar && !listaUsarEn?.length) {
            cargarOpcionesSelectsUsarEn(usarEn);
        }
        if (
            usarEnOriginal?.length &&
            esEditar &&
            !listaUsarEnOriginal?.length
        ) {
            setListaUsarEnOriginal(usarEnOriginal);
        }
    }, [formData, esEditar]);

    const cargarOpcionesSelectsUsarEn = async (usarEn) => {
        if (usarEn && usarEn?.length) {
            const tipo1 = usarEn[0]?.item1?.tipo;
            const tipo2 = usarEn[0]?.item2?.tipo;
            const tipo3 = usarEn[0]?.item3?.tipo;

            let listaUsarEnArr = [];
            setListaUsarEnLoading(true);

            if (tipo1 === 'Zona Geográfica') {
                const rspFamilias = await obtenerFamilias();
                listaUsarEnArr = usarEn.map((item) => ({
                    ...item,
                    item2: {
                        ...item.item2,
                        nombre: rspFamilias.filter(
                            (familia) =>
                                Number(familia?.id) ===
                                Number(item?.item2?.valor),
                        )[0]?.name,
                    },
                }));
            }
            if (tipo1 === 'Ciudad') {
                const rspCiudades = await obtenerCiudades();
                const rspFamilias = await obtenerFamilias();
                listaUsarEnArr = usarEn.map((item) => ({
                    ...item,
                    item1: {
                        ...item.item1,
                        nombre: rspCiudades.filter(
                            (ciudad) =>
                                Number(ciudad?.id) ===
                                Number(item?.item1?.valor),
                        )[0]?.name,
                    },
                    item2: {
                        ...item.item2,
                        nombre: rspFamilias.filter(
                            (familia) =>
                                Number(familia?.id) ===
                                Number(item?.item2?.valor),
                        )[0]?.name,
                    },
                }));
            }
            if (tipo1 === 'Barrio') {
                const rspBarrios = await obtenerBarrios();
                const rspFamilias = await obtenerFamilias();
                listaUsarEnArr = usarEn.map((item) => ({
                    ...item,
                    item1: {
                        ...item.item1,
                        nombre: rspBarrios.filter(
                            (barrio) =>
                                Number(barrio?.id) ===
                                Number(item?.item1?.valor),
                        )[0]?.name,
                    },
                    item2: {
                        ...item.item2,
                        nombre: rspFamilias.filter(
                            (familia) =>
                                Number(familia?.id) ===
                                Number(item?.item2?.valor),
                        )[0]?.name,
                    },
                }));
            }
            if (tipo1 === 'Familia' && tipo2 === 'Franquicia') {
                const rspFamilias = await obtenerFamilias();
                const rspFranquicias = await obtenerFranquicias();
                listaUsarEnArr = usarEn.map((item) => ({
                    ...item,
                    item1: {
                        ...item.item1,
                        nombre: rspFamilias.filter(
                            (familia) =>
                                Number(familia?.id) ===
                                Number(item?.item1?.valor),
                        )[0]?.name,
                    },
                    item2: {
                        ...item.item2,
                        nombre: rspFranquicias.filter(
                            (franquicia) =>
                                Number(franquicia?.id) ===
                                Number(item?.item2?.valor),
                        )[0]?.name,
                    },
                }));
            }
            if (
                tipo1 === 'Franquicia' &&
                tipo2 === 'Sucursal' &&
                tipo3 === 'Producto'
            ) {
                const rspFranquicias = await obtenerFranquicias();
                listaUsarEnArr = await obtenerFranquiciaSucursalProducto(
                    usarEn,
                    rspFranquicias,
                );
            }

            setListaUsarEn(listaUsarEnArr);
            setListaUsarEnLoading(false);
            cuponForm.setFieldsValue({ usarEn: undefined });
        }
    };

    const obtenerFranquiciaSucursalProducto = async (
        usarEn,
        rspFranquicias,
    ) => {
        const promiseListaUsarEnArr = await usarEn.map(async (item) => {
            const franquiciaSeleccionadaArr = rspFranquicias.filter(
                (franquicia) =>
                    Number(franquicia?.id) === Number(item?.item1?.valor),
            );
            const franquiciaSeleccionada =
                franquiciaSeleccionadaArr?.length &&
                franquiciaSeleccionadaArr[0];
            const idFranquicia = franquiciaSeleccionada.id;

            const esFranquiciaSucursalProducto = Boolean(item?.item3?.valor);
            let item2;
            let item3;
            if (esFranquiciaSucursalProducto) {
                const rspSucursales = esFranquiciaSucursalProducto
                    ? await obtenerSucursales(idFranquicia)
                    : undefined;
                const rspProductos = esFranquiciaSucursalProducto
                    ? await obtenerProductos(idFranquicia)
                    : undefined;

                item2 = {
                    ...item.item2,
                    nombre: rspSucursales.filter(
                        (sucursal) =>
                            Number(sucursal?.id) === Number(item?.item2?.valor),
                    )[0]?.name,
                };
                item3 = {
                    ...item.item3,
                    nombre: rspProductos.filter(
                        (producto) =>
                            Number(producto?.id) === Number(item?.item3?.valor),
                    )[0]?.name,
                };
            }

            return {
                ...item,
                item1: { ...item.item1, nombre: franquiciaSeleccionada.name },
                item2,
                item3,
            };
        });
        return Promise.all(promiseListaUsarEnArr);
    };

    const obtenerDatosOpcionSeleccionada = async (selectValue, datosExtras) => {
        let dataset = [];
        switch (selectValue) {
            case 'Ciudad':
                dataset = await obtenerCiudades();
                break;

            case 'Barrio':
                dataset = await obtenerBarrios();
                break;

            case 'Familia':
                dataset = await obtenerFamilias();
                break;

            case 'Franquicia':
                dataset = await obtenerFranquicias();
                break;

            case 'Sucursal':
                dataset = datosExtras
                    ? await obtenerSucursales(datosExtras)
                    : [];
                break;
            case 'Producto':
                dataset = datosExtras
                    ? await obtenerProductos(datosExtras)
                    : [];
                break;
            default:
                break;
        }
        return dataset;
    };

    const establecerDatosSelectCombo = async (
        selectValue,
        setSelectComboData,
        setSelectComboValue,
        datosExtras,
    ) => {
        await setSelectComboData([]);
        const dataset = await obtenerDatosOpcionSeleccionada(
            selectValue,
            datosExtras,
        );
        await setSelectComboData(dataset);
        await setSelectComboValue(undefined);
    };

    const usarEnSelectOnChangeHandler1 = (value) => {
        // Establecer seleccion
        setUsarEnSelectValue1(value);

        // Preparar opciones del siguiente select combinado
        setUsarEnSelectValue2(
            usarEnDataset2[value]?.length === 1 && usarEnDataset2[value][0],
        );
        setUsarEnSelectData2(usarEnDataset2[value]);
    };

    useEffect(() => {
        if (usarEnSelectValue1 && usarEnSelectValue1 !== 'Zona Geográfica') {
            establecerDatosSelectCombo(
                usarEnSelectValue1,
                setSelectComboData1,
                setSelectComboValue1,
            );
        } else if (usarEnSelectValue1 === 'Zona Geográfica') {
            setSelectComboValue1([]);
            setSelectComboData1([]);
        }
    }, [usarEnSelectValue1]);

    const usarEnSelectOnChangeHandler2 = (value) => {
        // Establecer seleccion
        setUsarEnSelectValue2(value);

        // Preparar opciones del siguiente select combinado
        setUsarEnSelectValue3(
            usarEnDataset3[value]?.length === 1
                ? usarEnDataset3[value][0]
                : undefined,
        );
        setUsarEnSelectData3(
            usarEnDataset3[value] ? usarEnDataset3[value] : [],
        );
    };

    useEffect(() => {
        if (usarEnSelectValue2) {
            if (usarEnSelectValue2 !== 'Sucursal') {
                establecerDatosSelectCombo(
                    usarEnSelectValue2,
                    setSelectComboData2,
                    setSelectComboValue2,
                );
                setUsarEnSelectData3([]);
                setUsarEnSelectValue3(undefined);
                setSelectComboData3([]);
                setSelectComboValue3(undefined);
            } else {
                // Preparar opciones del siguiente select combinado
                setUsarEnSelectValue3(
                    usarEnDataset3[usarEnSelectValue2]?.length === 1 &&
                        usarEnDataset3[usarEnSelectValue2][0],
                );
                setUsarEnSelectData3(usarEnDataset3[usarEnSelectValue2]);
                if (selectComboValue1) {
                    establecerDatosSelectCombo(
                        usarEnSelectValue2,
                        setSelectComboData2,
                        setSelectComboValue2,
                        selectComboValue1,
                    );
                } else {
                    setSelectComboData2([]);
                    setSelectComboValue2(undefined);
                    setSelectComboData3([]);
                    setSelectComboValue3(undefined);
                }
            }
        }
    }, [usarEnSelectValue2, selectComboValue1]);

    const usarEnSelectOnChangeHandler3 = (value) => {
        // Establecer seleccion
        setUsarEnSelectValue3(value);
    };

    useEffect(() => {
        if (usarEnSelectValue3) {
            establecerDatosSelectCombo(
                usarEnSelectValue3,
                setSelectComboData3,
                setSelectComboValue3,
                selectComboValue1,
            );
        }
    }, [usarEnSelectValue3, selectComboValue2]);

    const agregarItemSeleccionListaButtonHandler = () => {
        // Validar existencia de datos
        if (usarEnSelectValue1 && selectComboValue1) {
            if (usarEnSelectValue2 !== 'Sucursal') {
                if (
                    selectComboValue2 &&
                    (usarEnSelectValue3 ? Boolean(selectComboValue3) : true)
                ) {
                    // Validar si es el primer registro
                    if (listaUsarEn?.length > 0) {
                        // Validar que se carguen datos del mismo tipo
                        if (
                            listaUsarEn[0]?.item1?.tipo === usarEnSelectValue1
                        ) {
                            guardarSeleccionLista();
                        } else {
                            setErroresLista(
                                'Este item no es del mismo tipo que los demás elementos que están en la lista.',
                            );
                        }
                    } else {
                        guardarSeleccionLista();
                    }
                } else {
                    setErroresLista('Primero realice una selección.');
                }
            } else {
                if (
                    listaUsarEn[0]?.item1?.tipo === usarEnSelectValue1 ||
                    listaUsarEn[0]?.item1?.tipo === undefined
                ) {
                    guardarSeleccionLista();
                } else {
                    setErroresLista(
                        'Este item no es del mismo tipo que los demás elementos que están en la lista.',
                    );
                }
            }
        } else {
            setErroresLista('Primero realice una selección.');
        }
    };

    const guardarSeleccionLista = () => {
        const valor1 = selectComboValue1;
        const valor2 = selectComboValue2;
        const valor3 = selectComboValue3;
        const tipo1 = usarEnSelectValue1;
        const tipo2 = usarEnSelectValue2;
        const tipo3 = usarEnSelectValue3;
        const data1 = selectComboData1;
        const data2 = selectComboData2;
        const data3 = selectComboData3;

        const isZonaGeo = Boolean(tipo1 === 'Zona Geográfica');

        const nuevoValor1 = isZonaGeo ? [...data1, data1[0]] : valor1;

        const idItem = generarIdItemLista(
            nuevoValor1,
            valor2,
            valor3,
            isZonaGeo,
        );

        // Verificar que no se carguen elementos repetidos a la lista
        if (listaUsarEn?.findIndex((item) => item.id === idItem) < 0) {
            if (isZonaGeo && listaUsarEn?.length) {
                setErroresLista('Se permite solamente una zona geográfica.');
            } else if (tipo1 === 'Franquicia' && valor2 && !valor3) {
                setErroresLista('Debes seleccionar también un producto');
            } else {
                const nuevoItem1 = {
                    nombre: isZonaGeo
                        ? 'Zona Geográfica'
                        : data1?.find((item) => item?.id === valor1)?.name,
                    valor: nuevoValor1,
                    tipo: tipo1,
                };
                let nuevoItem2;
                if (valor2) {
                    nuevoItem2 = {
                        nombre: data2?.find((item) => item?.id === valor2)
                            ?.name,
                        valor: valor2,
                        tipo: tipo2,
                    };
                }
                let nuevoItem3;
                if (valor3) {
                    nuevoItem3 = {
                        nombre: data3?.find((item) => item?.id === valor3)
                            ?.name,
                        valor: valor3,
                        tipo: tipo3,
                    };
                }

                const nuevoItemLista = {
                    id: idItem,
                    item1: nuevoItem1,
                    item2: nuevoItem2,
                    item3: nuevoItem3,
                };

                setListaUsarEn((state) => [...state, nuevoItemLista]);
                setErroresLista('');
            }
        } else {
            setErroresLista('Este item ya existe en la lista.');
        }
    };

    const generarIdItemLista = (valor1, valor2, valor3, isZonaGeo) => {
        const id1 = isZonaGeo
            ? valor1.map((item) => `${item?.lat},${item?.lng}`).toString() + ','
            : valor1;
        const idItem = `${id1}${valor2 ? valor2 : ''}${valor3 ? valor3 : ''}`;
        return idItem;
    };

    const removerItemSeleccionListaButtonHandler = (id) => {
        setListaUsarEn((state) => state.filter((item) => item.id !== id));
    };

    const crearZonaGeograficaButtonHandler = () => {
        setModalGeografica(true);
    };

    const guardarSeleccionMapaButtonHandler = () => {
        setModalGeografica(false);
    };

    const cancelarSeleccionMapaButtonHandler = () => {
        setModalGeografica(false);
    };

    const obtenerFieldValuesUsarEn = (objFieldValue, tipo, valor) => {
        let objFieldValueNuevo = objFieldValue;
        switch (tipo) {
            case 'Zona Geográfica':
                objFieldValueNuevo['geo_zone'] = !objFieldValueNuevo['geo_zone']
                    ? [...valor]
                    : [...objFieldValueNuevo['geo_zone'], ...valor];
                break;
            case 'Ciudad':
                objFieldValueNuevo['city_id'] = !objFieldValueNuevo['city_id']
                    ? `${valor}`
                    : objFieldValueNuevo['city_id'] + `,${valor}`;
                break;
            case 'Barrio':
                objFieldValueNuevo['neighborhood_id'] = !objFieldValueNuevo[
                    'neighborhood_id'
                ]
                    ? `${valor}`
                    : objFieldValueNuevo['neighborhood_id'] + `,${valor}`;
                break;
            case 'Familia':
                objFieldValueNuevo['franchise_family_id'] = !objFieldValueNuevo[
                    'franchise_family_id'
                ]
                    ? `${valor}`
                    : objFieldValueNuevo['franchise_family_id'] + `,${valor}`;
                break;
            case 'Franquicia':
                objFieldValueNuevo['franchise_id'] = !objFieldValueNuevo[
                    'franchise_id'
                ]
                    ? `${valor}`
                    : objFieldValueNuevo['franchise_id'] + `,${valor}`;
                break;
            case 'Sucursal':
                objFieldValueNuevo['branch_id'] = !objFieldValueNuevo[
                    'branch_id'
                ]
                    ? `${valor}`
                    : objFieldValueNuevo['branch_id'] + `,${valor}`;
                break;
            case 'Producto':
                objFieldValueNuevo['product_id'] = !objFieldValueNuevo[
                    'product_id'
                ]
                    ? `${valor}`
                    : objFieldValueNuevo['product_id'] + `,${valor}`;
                break;
            default:
                break;
        }
        return objFieldValueNuevo;
    };

    const obtenerUsarEnDiscountCodeDetails = () => {
        const listaActual = listaUsarEn;
        const listaOriginal = listaUsarEnOriginal.filter(
            (item) => !item?.payment_type_id,
        );

        // Nuevas 'condiciones'
        let tipoSeleccion = []; // Almacenar la lista de selecciones realizadas

        // Convertir lista de condiciones al formato que acepta el backend
        const elementosNuevosConFormato = listaActual.map((item) => {
            const tipoItem1 = item.item1.tipo;

            const valorItem1 = item?.item1?.valor;
            const valorItem2 = item?.item2?.valor;
            const valorItem3 = item?.item3?.valor;

            let objDiscountCodeDetail = {};
            switch (tipoItem1) {
                case 'Zona Geográfica':
                    objDiscountCodeDetail['geo_zone'] = valorItem1;
                    objDiscountCodeDetail['franchise_family_id'] = valorItem2;
                    if (!tipoSeleccion?.length)
                        tipoSeleccion = ['geo_zone', 'franchise_family_id'];
                    break;
                case 'Ciudad':
                    objDiscountCodeDetail['city_id'] = valorItem1;
                    objDiscountCodeDetail['franchise_family_id'] = valorItem2;
                    if (!tipoSeleccion?.length)
                        tipoSeleccion = ['city_id', 'franchise_family_id'];
                    break;
                case 'Barrio':
                    objDiscountCodeDetail['neighborhood_id'] = valorItem1;
                    objDiscountCodeDetail['franchise_family_id'] = valorItem2;
                    if (!tipoSeleccion?.length)
                        tipoSeleccion = [
                            'neighborhood_id',
                            'franchise_family_id',
                        ];
                    break;
                case 'Familia':
                    objDiscountCodeDetail['franchise_family_id'] = valorItem1;
                    objDiscountCodeDetail['franchise_id'] = valorItem2;
                    if (!tipoSeleccion?.length)
                        tipoSeleccion = ['franchise_family_id', 'franchise_id'];
                    break;
                case 'Franquicia':
                    objDiscountCodeDetail['franchise_id'] = valorItem1;
                    if (valorItem2)
                        objDiscountCodeDetail['branch_id'] = valorItem2;
                    if (valorItem3)
                        objDiscountCodeDetail['product_id'] = valorItem3;
                    if (!tipoSeleccion?.length)
                        tipoSeleccion = [
                            'franchise_id',
                            'branch_id',
                            'product_id',
                        ];
                    break;
                default:
                    break;
            }

            return objDiscountCodeDetail;
        });

        const cantidadCondiciones = tipoSeleccion.length;

        // Identificar las 'condiciones' nuevas
        // 'condiciones' que existen en la lista actual y no existen en la lista original
        let elementosNuevos = [];
        elementosNuevosConFormato.forEach((itemActual) => {
            let discountCodeObj;

            //  Buscar coincidencias
            if (cantidadCondiciones === 3) {
                const esFranquiciaSucursalProducto = Boolean(
                    itemActual[tipoSeleccion[2]],
                );
                if (esFranquiciaSucursalProducto) {
                    discountCodeObj = listaOriginal.find(
                        (itemOriginal) =>
                            itemOriginal[tipoSeleccion[0]] ===
                                itemActual[tipoSeleccion[0]] &&
                            itemOriginal[tipoSeleccion[1]] ===
                                itemActual[tipoSeleccion[1]] &&
                            itemOriginal[tipoSeleccion[2]] ===
                                itemActual[tipoSeleccion[2]],
                    );
                } else {
                    discountCodeObj = listaOriginal.find(
                        (itemOriginal) =>
                            itemOriginal[tipoSeleccion[0]] ===
                            itemActual[tipoSeleccion[0]],
                    );
                }
            } else {
                discountCodeObj = listaOriginal.find(
                    (itemOriginal) =>
                        itemOriginal[tipoSeleccion[0]] ===
                            itemActual[tipoSeleccion[0]] &&
                        itemOriginal[tipoSeleccion[1]] ===
                            itemActual[tipoSeleccion[1]],
                );
            }

            if (!discountCodeObj) {
                elementosNuevos.push(itemActual);
            }
        });

        // Identificar 'condiciones' a eliminar
        // 'condiciones' que no existen en la lista actual y si existen en la lista original
        const elementosEliminados = [];
        listaOriginal.forEach((itemOriginal) => {
            let discountCodeObj;

            //  Buscar coincidencias
            if (cantidadCondiciones === 3) {
                const esFranquiciaSucursalProducto = Boolean(
                    itemOriginal[tipoSeleccion[2]],
                );
                if (esFranquiciaSucursalProducto) {
                    discountCodeObj = elementosNuevosConFormato.find(
                        (itemActual) =>
                            itemOriginal[tipoSeleccion[0]] ===
                                itemActual[tipoSeleccion[0]] &&
                            itemOriginal[tipoSeleccion[1]] ===
                                itemActual[tipoSeleccion[1]] &&
                            itemOriginal[tipoSeleccion[2]] ===
                                itemActual[tipoSeleccion[2]],
                    );
                } else {
                    discountCodeObj = elementosNuevosConFormato.find(
                        (itemActual) =>
                            itemOriginal[tipoSeleccion[0]] ===
                            itemActual[tipoSeleccion[0]],
                    );
                }
            } else {
                discountCodeObj = elementosNuevosConFormato.find(
                    (itemActual) =>
                        itemOriginal[tipoSeleccion[0]] ===
                            itemActual[tipoSeleccion[0]] &&
                        itemOriginal[tipoSeleccion[1]] ===
                            itemActual[tipoSeleccion[1]],
                );
            }

            if (!discountCodeObj) {
                // Agregar el key 'deleted' = 'true' para indicar que se va a eliminar este elemento al enviar la peticion al backend
                elementosEliminados.push({ ...itemOriginal, deleted: true });
            }
        });

        // Identificar 'condiciones' sin modificacion
        // 'condiciones' que existen en ambas listas
        let elementosExistentes = [];
        elementosNuevosConFormato.forEach((itemActual) => {
            let discountCodeObj;

            //  Buscar coincidencias
            if (cantidadCondiciones === 3) {
                const esFranquiciaSucursalProducto = Boolean(
                    itemActual[tipoSeleccion[2]],
                );
                if (esFranquiciaSucursalProducto) {
                    discountCodeObj = listaOriginal.find(
                        (itemOriginal) =>
                            itemOriginal[tipoSeleccion[0]] ===
                                itemActual[tipoSeleccion[0]] &&
                            itemOriginal[tipoSeleccion[1]] ===
                                itemActual[tipoSeleccion[1]] &&
                            itemOriginal[tipoSeleccion[2]] ===
                                itemActual[tipoSeleccion[2]],
                    );
                } else {
                    discountCodeObj = listaOriginal.find(
                        (itemOriginal) =>
                            itemOriginal[tipoSeleccion[0]] ===
                            itemActual[tipoSeleccion[0]],
                    );
                }
            } else {
                discountCodeObj = listaOriginal.find(
                    (itemOriginal) =>
                        itemOriginal[tipoSeleccion[0]] ===
                            itemActual[tipoSeleccion[0]] &&
                        itemOriginal[tipoSeleccion[1]] ===
                            itemActual[tipoSeleccion[1]],
                );
            }

            if (discountCodeObj) {
                elementosExistentes.push(discountCodeObj);
            }
        });

        return [
            ...elementosNuevos,
            ...elementosEliminados,
            ...elementosExistentes,
        ];
    };

    useEffect(() => {
        // En caso que se seleccione la opcion de Franquicia, Sucursal, Producto. Ordenar el array
        // moviendo las franquicias con productos al principio y las independientes al final
        let usarEnArr = [];
        const esListaConFranquicias = Boolean(
            listaUsarEn.find((item) => item.item1.tipo === 'Franquicia'),
        );
        if (esListaConFranquicias) {
            let listaUsarEnArrFranquiciaSucursalProduco = [];
            let listaUsarEnArrFranquicia = [];

            // Separar franquicias
            listaUsarEn.forEach((item) => {
                const esFranquiciaSucursalProducto = Boolean(
                    item?.item3?.valor,
                );
                if (esFranquiciaSucursalProducto) {
                    listaUsarEnArrFranquiciaSucursalProduco.push(item);
                } else {
                    listaUsarEnArrFranquicia.push(item);
                }
            });

            // Incorporar ambos arrays
            usarEnArr.push(
                ...listaUsarEnArrFranquiciaSucursalProduco,
                ...listaUsarEnArrFranquicia,
            );
        } else {
            usarEnArr = listaUsarEn;
        }

        let objFieldValue = {};

        usarEnArr?.forEach((item) => {
            const item1 = obtenerFieldValuesUsarEn(
                objFieldValue,
                item.item1.tipo,
                item.item1.valor,
            );
            const item2 = item.item2
                ? obtenerFieldValuesUsarEn(
                      objFieldValue,
                      item.item2.tipo,
                      item.item2.valor,
                  )
                : undefined;
            const item3 = item.item3
                ? obtenerFieldValuesUsarEn(
                      objFieldValue,
                      item.item3.tipo,
                      item.item3.valor,
                  )
                : undefined;
            objFieldValue = { ...objFieldValue, ...item1, ...item2, ...item3 };
        });

        let usarEnDiscountCodeDetailsArr;
        if (esEditar)
            usarEnDiscountCodeDetailsArr = obtenerUsarEnDiscountCodeDetails();

        if (!usarEnArr?.length)
            objFieldValue = Object.assign({
                geo_zone: '',
                city_id: '',
                neighborhood_id: '',
                franchise_family_id: '',
                franchise_id: '',
                branch_id: '',
                product_id: '',
            });

        cuponForm?.setFieldsValue({ ...objFieldValue });
        cuponForm.setFieldsValue({
            usarEnDiscountCodeDetails: usarEnDiscountCodeDetailsArr,
        });
    }, [listaUsarEn]);

    return (
        <>
            {cuponForm && listaUsarEn && (
                <>
                    {/* Componentes de interfaz de usuario */}
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={24} md={12}>
                            <Title
                                type="secondary"
                                level={5}
                                className={classes.condicionesSubtitulos}>
                                Usar en
                            </Title>
                        </Col>
                    </Row>
                    <Row gutter={12} className={classes?.rowSelectUsarEn}>
                        <Col span={8}>
                            <Select
                                className={classes?.selectInput}
                                placeholder="Seleccione una opción"
                                onChange={usarEnSelectOnChangeHandler1}
                                disabled={
                                    !usarEnSelectData1?.length ||
                                    listaUsarEnLoading
                                }
                                value={usarEnSelectValue1}>
                                {usarEnSelectData1.length &&
                                    usarEnSelectData1.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={7}>
                            <Select
                                className={classes?.selectInput}
                                placeholder="Seleccione una opción"
                                onChange={usarEnSelectOnChangeHandler2}
                                disabled={
                                    !usarEnSelectData2?.length ||
                                    listaUsarEnLoading
                                }
                                value={usarEnSelectValue2}>
                                {usarEnSelectData2.length &&
                                    usarEnSelectData2.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={7}>
                            <Select
                                style={{ width: '100%' }}
                                placeholder="Seleccione una opción"
                                onChange={usarEnSelectOnChangeHandler3}
                                disabled={
                                    !usarEnSelectData3?.length ||
                                    listaUsarEnLoading
                                }
                                value={usarEnSelectValue3}>
                                {usarEnSelectData3.length &&
                                    usarEnSelectData3.map((item) => (
                                        <Select.Option key={item} value={item}>
                                            {item}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </Col>
                    </Row>
                    <Row gutter={12} className={classes?.rowSelectUsarEn}>
                        <Col span={8}>
                            {usarEnSelectValue1 === 'Zona Geográfica' ? (
                                <Button
                                    style={{ width: '100%', padding: 5 }}
                                    size="middle"
                                    type="link"
                                    onClick={crearZonaGeograficaButtonHandler}
                                    disabled={listaUsarEnLoading}>
                                    {selectComboData1.length > 0
                                        ? 'Editar zona geográfica'
                                        : 'Crear zona geográfica'}
                                </Button>
                            ) : (
                                <Select
                                    className={classes?.selectInput}
                                    placeholder="Seleccione una opción"
                                    disabled={
                                        !selectComboData1?.length ||
                                        listaUsarEnLoading
                                    }
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                    }
                                    value={selectComboValue1}
                                    onChange={setSelectComboValue1}>
                                    {selectComboData1.length &&
                                        selectComboData1.map((item) => (
                                            <Option
                                                key={item.id}
                                                value={item.id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>
                            )}
                        </Col>
                        <Col span={7}>
                            <Select
                                className={classes?.selectInput}
                                placeholder="Seleccione una opción"
                                disabled={
                                    !selectComboData2?.length ||
                                    listaUsarEnLoading
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectComboValue2}
                                onChange={setSelectComboValue2}>
                                {selectComboData2.length &&
                                    selectComboData2.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={7}>
                            <Select
                                className={classes?.selectInput}
                                placeholder="Seleccione una opción"
                                disabled={
                                    !selectComboData3?.length ||
                                    listaUsarEnLoading
                                }
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                value={selectComboValue3}
                                onChange={setSelectComboValue3}>
                                {selectComboData3.length &&
                                    selectComboData3.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Col>
                        <Col span={1}>
                            <Button
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={agregarItemSeleccionListaButtonHandler}
                                disabled={listaUsarEnLoading}
                            />
                        </Col>
                    </Row>
                    <div className={classes?.erroresLista}>{erroresLista}</div>
                    <List
                        dataSource={listaUsarEn}
                        loading={listaUsarEnLoading}
                        renderItem={(item) => (
                            <List.Item
                                actions={[
                                    <Button
                                        key="btnBorrar"
                                        size="small"
                                        danger
                                        shape="circle"
                                        icon={<DeleteOutlined />}
                                        onClick={() =>
                                            removerItemSeleccionListaButtonHandler(
                                                item.id,
                                            )
                                        }
                                    />,
                                ]}>
                                <div>
                                    <strong>
                                        {item.item1.tipo !== 'Zona Geográfica'
                                            ? `${item.item1.tipo}:`
                                            : 'Tipo:'}
                                    </strong>{' '}
                                    {item.item1.nombre}
                                    {item.item2 ? (
                                        <>
                                            {` - `}
                                            <strong>{item.item2.tipo}:</strong>
                                            {` ${item.item2.nombre}`}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                    {item.item3 ? (
                                        <>
                                            {` - `}
                                            <strong>{item.item3.tipo}:</strong>
                                            {` ${item.item3.nombre}`}
                                        </>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </List.Item>
                        )}
                    />

                    {/* Modal para seleccion de zona geografica */}
                    <Modal
                        centered
                        open={modalGeografica}
                        title="Seleccionar zona geográfica"
                        footer={null}
                        closable={null}
                        bodyStyle={styles.modalMapa}>
                        <ModalContent
                            showWarnIcon={false}
                            isLoading={false}
                            okText="Guardar"
                            cancelText="Cancelar"
                            onOk={guardarSeleccionMapaButtonHandler}
                            onCancel={cancelarSeleccionMapaButtonHandler}>
                            <MapaPolygon
                                puntosIniciales={selectComboData1}
                                guardarPuntos={setSelectComboData1}
                            />
                        </ModalContent>
                    </Modal>

                    {/* Form items expuestos al formulario */}
                    <Form.Item style={{ display: 'none' }} name="geo_zone">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} name="city_id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'none' }}
                        name="neighborhood_id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item
                        style={{ display: 'none' }}
                        name="franchise_family_id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} name="franchise_id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} name="branch_id">
                        <Input type="hidden" />
                    </Form.Item>
                    <Form.Item style={{ display: 'none' }} name="product_id">
                        <Input type="hidden" />
                    </Form.Item>
                </>
            )}
            <Form.Item style={{ display: 'none' }} name="usarEn">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item style={{ display: 'none' }} name="usarEnOriginal">
                <Input type="hidden" />
            </Form.Item>
            <Form.Item
                style={{ display: 'none' }}
                name="usarEnDiscountCodeDetails">
                <Input type="hidden" />
            </Form.Item>
        </>
    );
};

export default FormItemUsarEn;

const styles = {
    modalMapa: { padding: '0 5px 10px 5px' },
};

FormItemUsarEn.propTypes = {
    esEditar: PropTypes.bool,
    cuponForm: PropTypes.any,
    obtenerFranquicias: PropTypes.func,
    obtenerCiudades: PropTypes.func,
    obtenerFamilias: PropTypes.func,
    obtenerBarrios: PropTypes.func,
    obtenerSucursales: PropTypes.func,
    obtenerProductos: PropTypes.func,
};
