import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

const FormItemSelectMes = ({ onChange, rules, disabled }) => {
    return (
        <Form.Item name="mes" label="Mes" rules={rules}>
            <Select onChange={onChange} disabled={disabled}>
                <Option value="01">Enero</Option>
                <Option value="02">Febrero</Option>
                <Option value="03">Marzo</Option>
                <Option value="04">Abril</Option>
                <Option value="05">Mayo</Option>
                <Option value="06">Junio</Option>
                <Option value="07">Julio</Option>
                <Option value="08">Agosto</Option>
                <Option value="09">Septiembre</Option>
                <Option value="10">Octubre</Option>
                <Option value="11">Noviembre</Option>
                <Option value="12">Diciembre</Option>
            </Select>
        </Form.Item>
    );
};

export default FormItemSelectMes;

FormItemSelectMes.propTypes = {
    rules: PropTypes.any,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
};
