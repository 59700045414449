import { message } from 'antd';
import { useState } from 'react';
import clienteAxios from '../../../../../../config/axios';

export const useConsultasDetalleComision = () => {
    //state
    const [franquicias, setFranquicias] = useState([]);
    const [sucursales, setSucursales] = useState([]);
    const [descargaExcel, setDescargaExcel] = useState('');

    // Formulario - Obtener datos de Franquicias
    const obtenerFranquicias = async () => {
        let arrFranquicias = [{ franchise_id: '', name: 'Todas' }];
        // setObteniendoDatosForm(true);
        try {
            const { data } = await clienteAxios.get('/franchises');
            data?.data?.franchises.map((item) => arrFranquicias.push(item));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener los datos de las franquicias',
            );
        }
        // setObteniendoDatosForm(false);
        setFranquicias(arrFranquicias);
    };

    // consultas para obtener las sucursales de la franquicia seleccionada
    const obtenerSucursales = async (franchiseId) => {
        const params = { franchise_id: franchiseId?.toString() || undefined };
        try {
            const { data } = await clienteAxios.get('/branches', { params });
            setSucursales(data?.data?.branches);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener los datos de las sucursales',
            );
        }
    };

    const descargarDetalleComision = async (datos) => {
        try {
            const { data } = await clienteAxios.get(
                'commission_details_download',
                { params: datos },
            );
            setDescargaExcel(data?.data?.link_commission_datails);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener excel',
            );
        }
    };

    return {
        obtenerFranquicias,
        obtenerSucursales,
        descargarDetalleComision,
        descargaExcel,
        franquicias,
        sucursales,
    };
};
