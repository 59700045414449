import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

class Receip extends Component {
    render() {
        const { razonSocial, RUC, id } = this.props;

        return (
            <Tooltip
                title={
                    <div>
                        <div>ID: {id}</div>
                        <div>Razón Social: {razonSocial}</div>
                        <div>RUC: {RUC}</div>
                    </div>
                }>
                <svg
                    width="19"
                    height="26"
                    viewBox="0 0 19 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <g id="Receipt">
                        <g id="Receipt_2">
                            <path
                                id="Vector"
                                d="M5.64062 10.5625H13.3594"
                                stroke="#828282"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                id="Vector_2"
                                d="M5.64062 13.8125H13.3594"
                                stroke="#828282"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                id="Vector_3"
                                d="M2.375 21.125V5.6875C2.375 5.47201 2.43756 5.26535 2.54891 5.11298C2.66026 4.9606 2.81128 4.875 2.96875 4.875H16.0312C16.1887 4.875 16.3397 4.9606 16.4511 5.11298C16.5624 5.26535 16.625 5.47201 16.625 5.6875V21.125L14.25 19.5L11.875 21.125L9.5 19.5L7.125 21.125L4.75 19.5L2.375 21.125Z"
                                stroke="#828282"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </g>
                    </g>
                </svg>
            </Tooltip>
        );
    }
}
export default Receip;

Receip.propTypes = {
    razonSocial: PropTypes.string.isRequired,
    RUC: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
};
