import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgramPromotionsCard.module.css';
import { Card, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

export const ProgramPromotionsCard = ({
    day,
    hourRange,
    removeHandler,
    disabled,
}) => {
    const timeSlots = hourRange?.map((prop, index) => {
        const timeFrom =
            prop.promotionHourFrom &&
            moment(prop.promotionHourFrom).format('HH:mm');
        const timeTo =
            prop.promotionHourTo &&
            moment(prop?.promotionHourTo).format('HH:mm');
        const cardKey = `${timeFrom} - ${timeTo}`;
        return (
            <div className={styles.hourContainer} key={index}>
                <p className={styles.hour}>
                    {timeFrom}h - {timeTo}h
                </p>
                <DeleteOutlined
                    hidden={disabled}
                    id={cardKey}
                    className={styles.icon}
                    onClick={removeHandler}
                />
            </div>
        );
    });
    if (day !== 'Todos los días') {
        return (
            <Card title={day} className={styles.card}>
                <Space direction="vertical" className={styles.space}>
                    {timeSlots}
                </Space>
            </Card>
        );
    }
    return null;
};

ProgramPromotionsCard.propTypes = {
    day: PropTypes.string,
    hourRange: PropTypes.array,
    removeHandler: PropTypes.func,
    disabled: PropTypes.bool,
};
