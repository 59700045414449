import React from 'react';
import 'antd/dist/antd.css';
import { Col, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import classes from './Acciones1.module.css';
import Imprimir from 'assets/svg/support/Imprimir';

const ImprimirOrdenButton = ({
    permitirImprimirOrden,
    imprimirOrdenButtonHandler,
}) => (
    <>
        {permitirImprimirOrden ? (
            <Col>
                <Tooltip title="Imprimir orden">
                    <Button
                        type="link"
                        onClick={imprimirOrdenButtonHandler}
                        className={classes.ButtonImprimir}>
                        IMPRIMIR
                        <span>
                            <Imprimir />
                        </span>
                    </Button>
                </Tooltip>
            </Col>
        ) : (
            <div className={classes.emptySpaceAcciones} />
        )}
    </>
);

ImprimirOrdenButton.propTypes = {
    permitirImprimirOrden: PropTypes.bool,
    imprimirOrdenButtonHandler: PropTypes.func,
};

export default ImprimirOrdenButton;
