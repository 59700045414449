import React, { useContext, useEffect, useState } from 'react';
import {
    Col,
    Row,
    Form,
    Select,
    Divider,
    Table,
    Button,
    Tag,
    Dropdown,
    Modal,
} from 'antd';
import FormItemSelectFranquicia from '../../../../../atoms/Form/Select/Franquicia';
import FormItemSelectMes from '../../../../../atoms/Form/Select/Mes';
import FormItemSelectAnho from '../../../../../atoms/Form/Select/Anho';
import { invoice_states } from '../../../../../../constants';
import classes from './ListaRecibos.module.css';
import { obtenerIntervaloInicioFinDocumentosFacturacion } from '../../../../../utils-components/obtenerIntervaloInicioFinDocumentosFacturacion';

import { MoreOutlined } from '@ant-design/icons';
import EmisionDocumentosContext from '../../../../../../context/facturacion/emisionDocumentos/EmisionDocumentosContext';
import { formatoGuaranies } from '../../../../../utils-components/Number';
import ModalContent from '../../../../../atoms/ModalContent';

const { Option } = Select;
const itemsPorPagina = 20;

const estadosRecibos = invoice_states
    .map((estado) => {
        let nuevoEstado;
        if (estado.id === 1 || estado.id === 4) {
            nuevoEstado = {
                ...estado,
                name: estado.id === 1 ? 'activo' : estado.name,
            };
        }
        return nuevoEstado;
    })
    .filter((estado) => estado);

const ListaRecibos = () => {
    const [parametrosBusqueda, setParametrosBusqueda] = useState({
        date_start: '',
        date_end: '',
        franchise_ids: undefined,
        state_ids: [1],
        voucher_type_id: 2,
        page: 1,
    });
    const [isModalAnularVisible, setIsModalAnularVisible] = useState(false);
    const [reciboSeleccionado, setReciboSeleccionado] = useState();

    const emisionDocumentosContext = useContext(EmisionDocumentosContext);
    const {
        recibosObtenerListado,
        recibosLista,
        recibosTotalPaginas,
        recibosListaLoading,
        recibosModalLoading,
        recibosAnular,
        descargarPdf,
    } = emisionDocumentosContext;

    const [formFiltrosRecibos] = Form.useForm();

    const actionsColumn = (data) => (
        <>
            <Dropdown
                menu={{
                    items: [
                        {
                            label: 'Descargar PDF',
                            key: 'descargarPDF',
                        },
                        data.estado === 'pendiente' && {
                            label: 'Anular',
                            key: 'anular',
                        },
                    ],
                    onClick: ({ key }) => {
                        const { nroRecibo, idFactura, secret } = data;
                        switch (key) {
                            case 'descargarPDF':
                                descargarPdf(2, idFactura, secret);
                                break;
                            case 'anular':
                                anularReciboHandler(idFactura);
                                break;
                            default:
                                break;
                        }
                    },
                }}>
                <Button icon={<MoreOutlined />} />
            </Dropdown>
        </>
    );

    const columns = [
        {
            title: 'Nro. Recibo',
            dataIndex: 'nroRecibo',
            key: 'nroRecibo',
            render: (text) => text.replace('000-000-', ''),
        },
        {
            title: 'Nombre',
            dataIndex: 'nombreFranquicia',
            key: 'nombreFranquicia',
        },
        {
            title: 'Razón social',
            dataIndex: 'nombreLegal',
            key: 'nombreLegal',
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (text) => (
                <div style={{ textTransform: 'uppercase' }}>
                    <Tag color={text === 'pendiente' ? 'green' : 'volcano'}>
                        {text === 'pendiente' ? 'ACTIVO' : text}
                    </Tag>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text) => formatoGuaranies(+text),
        },
        {
            title: '',
            key: 'action',
            render: (_text, record) => actionsColumn(record),
        },
    ];

    useEffect(() => {
        // Obtener las fechas para la busqueda inicial

        // Obtener fecha del mes anterior al actual
        const fechaConsultar = new Date();
        fechaConsultar.setMonth(fechaConsultar.getMonth() - 1);

        // Obtener parametros de busqueda
        const { fechasBusqueda, mesStart, anhoStart } =
            obtenerIntervaloInicioFinDocumentosFacturacion(
                fechaConsultar.getMonth(),
                fechaConsultar.getFullYear(),
            );

        // Establecer parametros de busqueda en el form
        formFiltrosRecibos.setFieldsValue({
            mes: mesStart,
            anho: anhoStart,
            franquiciaId: '',
        });

        // Guardar parametros de busqueda en el state local
        setParametrosBusqueda((prevState) => ({
            ...prevState,
            ...fechasBusqueda,
        }));
    }, []);

    useEffect(() => {
        if (parametrosBusqueda.date_start && parametrosBusqueda.date_end) {
            recibosObtenerListado(parametrosBusqueda);
        }
    }, [parametrosBusqueda]);

    const buscarRecibosHandler = (values) => {
        const franquiciaBusqueda = values?.franquiciaId
            ? [values?.franquiciaId]
            : undefined;
        const estadoBusqueda = values?.estado ? [values?.estado] : undefined;
        const { fechasBusqueda } =
            obtenerIntervaloInicioFinDocumentosFacturacion(
                +values?.mes - 1,
                +values?.anho,
            );

        setParametrosBusqueda((prevState) => ({
            ...prevState,
            franchise_ids: franquiciaBusqueda,
            ...fechasBusqueda,
            state_ids: estadoBusqueda,
            page: 1,
        }));
    };

    const changePageHandler = (page) => {
        setParametrosBusqueda((prevState) => ({
            ...prevState,
            page,
        }));
    };

    const descargarPdfHandler = (idFranquicias) => {
        descargarPdf(2, idFranquicias);
    };

    const anularReciboHandler = (idFactura) => {
        setIsModalAnularVisible(true);
        setReciboSeleccionado(idFactura);
    };

    const confirmarAnularReciboHandler = async () => {
        await recibosAnular(reciboSeleccionado);
        setIsModalAnularVisible(false);
        setReciboSeleccionado(undefined);
        recibosObtenerListado(parametrosBusqueda);
    };

    const cancelarAnularReciboHandler = () => {
        setIsModalAnularVisible(false);
        setReciboSeleccionado(undefined);
    };

    return (
        <>
            <Row gutter={12}>
                <Col span={16}>
                    <Form
                        name="filtros"
                        layout="vertical"
                        form={formFiltrosRecibos}
                        onFinish={buscarRecibosHandler}>
                        <Row gutter={12} style={{ alignItems: 'center' }}>
                            <Col span={6}>
                                {/* Form.Item name=franquiciaId */}
                                <FormItemSelectFranquicia
                                    formInstance={formFiltrosRecibos}
                                    disabled={recibosListaLoading}
                                />
                            </Col>
                            <Col span={4}>
                                {/* Form.Item name=mes */}
                                <FormItemSelectMes
                                    rules={[{ required: true }]}
                                    disabled={recibosListaLoading}
                                />
                            </Col>
                            <Col span={4}>
                                {/* Form.Item name=anho */}
                                <FormItemSelectAnho
                                    rules={[{ required: true }]}
                                    disabled={recibosListaLoading}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item name="estado" label="Estado">
                                    <Select
                                        defaultValue=""
                                        disabled={recibosListaLoading}>
                                        <Option value="">Todos</Option>
                                        {estadosRecibos.map((estado) => (
                                            <Option
                                                value={estado.id}
                                                key={estado.id}>
                                                {estado.name.toUpperCase()}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item style={{ marginBottom: '0' }}>
                                    <Button
                                        disabled={recibosListaLoading}
                                        type="primary"
                                        htmlType="submit">
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col
                    span={8}
                    className={classes.acciones}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                    }}>
                    <Button
                        disabled={
                            !(
                                (formFiltrosRecibos.getFieldValue('mes') &&
                                    formFiltrosRecibos.getFieldValue('anho') &&
                                    recibosLista?.length &&
                                    parametrosBusqueda.state_ids?.includes(
                                        1,
                                    )) ||
                                !parametrosBusqueda?.state_ids
                            )
                        }
                        type="link"
                        onClick={() => descargarPdfHandler()}>
                        Exportar PDF
                    </Button>
                </Col>
            </Row>
            <Divider style={{ marginTop: 4 }} />
            <Row gutter={12}>
                <Col span={24}>
                    <Table
                        size="small"
                        loading={recibosListaLoading}
                        columns={columns}
                        dataSource={recibosLista}
                        pagination={{
                            pageSize: itemsPorPagina,
                            total: recibosTotalPaginas,
                            current: parametrosBusqueda.page,
                            showSizeChanger: false,
                            onChange: (page) => changePageHandler(page),
                        }}
                    />
                </Col>
            </Row>
            <Modal open={isModalAnularVisible} footer={null} closable={null}>
                <ModalContent
                    title={`Está seguro/a que quiere anular este recibo?`}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    onOk={confirmarAnularReciboHandler}
                    onCancel={cancelarAnularReciboHandler}
                    isLoading={recibosModalLoading}
                    showWarnIcon
                />
            </Modal>
        </>
    );
};

export default ListaRecibos;
