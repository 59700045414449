import React, { useReducer, useState } from 'react';
import clienteAxios from '../../../config/axios';
import TimbradoContext from './TimbradoContext';
import TimbradoReducer, { initialState } from './TimbradoReducer';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
    OBTENER_LISTADO_TIMBRADOS_TABLA,
    OBTENER_LISTADO_TIMBRADOS,
    SELECCIONAR_PAGINA_TIMBRADO,
    TIMBRADOS_TOTALES,
    BUSQUEDA_TIMBRADO,
} from '../../../types';
import { message } from 'antd';

const TimbradoState = ({ children }) => {
    const [loadingTablaTimbrados, setLoadingTablaTimbrados] = useState(false);
    const [state, dispatch] = useReducer(TimbradoReducer, initialState);

    const obtenerListadoTimbrados = async (page = 1) => {
        setLoadingTablaTimbrados(true);
        const params = {
            page,
        };
        try {
            const resp = await clienteAxios.get('/get_stampeds', { params });
            dispatch({
                type: TIMBRADOS_TOTALES,
                payload: resp?.data?.data?.response?.total,
            });
            dispatch({
                type: OBTENER_LISTADO_TIMBRADOS,
                payload: resp?.data?.data?.response?.stampeds,
            });
            const timbradosLista = resp?.data?.data?.response?.stampeds;
            let timbradoDataArray = [];
            timbradosLista.forEach((timbrado) => {
                // unir las fechas y formatear
                let fechaFrom = timbrado?.date_from;
                let fechaTo = timbrado?.date_to;
                let fechaNormalizadaFrom =
                    fechaFrom.substring(0, fechaFrom.indexOf('.')) +
                    moment().format('Z');
                let fechaNormalizadaTo =
                    fechaTo.substring(0, fechaTo.indexOf('.')) +
                    moment().format('Z');
                let fechaDesde =
                    moment(fechaNormalizadaFrom).format('DD/MM/YYYY');
                let fechaHasta =
                    moment(fechaNormalizadaTo).format('DD/MM/YYYY');
                let fechaUnida = `${fechaDesde} - ${fechaHasta}`;
                // unir numero de factura
                let expedicionPoint = timbrado?.expedition_point;
                let sucursalCode = timbrado?.branch;
                let billNumberStart = timbrado?.bill_number_start;
                let billNumberEnd = timbrado?.bill_number_end;

                // agrega ceros al comienzo
                let ZeroExpedicionPoint = expedicionPoint
                    .toString()
                    .padStart(3, '0');
                let ZeroSucursalCode = sucursalCode.toString().padStart(3, '0');
                let ZeroBillNumberStart = billNumberStart
                    .toString()
                    .padStart(7, '0');
                let ZeroBillNumberEnd = billNumberEnd
                    .toString()
                    .padStart(7, '0');

                // unificar todas las facturas
                let numeroFacturaUnida = `${ZeroExpedicionPoint}-${ZeroSucursalCode}-${ZeroBillNumberStart} / ${ZeroBillNumberEnd}`;

                const timbradoParaTabla = {
                    id: timbrado?.id,
                    timbrado: timbrado?.stamped,
                    fechaInicioFin: fechaUnida,
                    estado: timbrado?.enabled,
                    nroFactura: numeroFacturaUnida,
                };
                timbradoDataArray.push(timbradoParaTabla);
            });
            dispatch({
                type: OBTENER_LISTADO_TIMBRADOS_TABLA,
                payload: [...timbradoDataArray],
            });
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }

        setLoadingTablaTimbrados(false);
    };

    const seleccionarPageTabla = (page) => {
        dispatch({
            type: SELECCIONAR_PAGINA_TIMBRADO,
            payload: page,
        });
    };

    const busquedaTimbrado = (busqueda) => {
        dispatch({
            type: BUSQUEDA_TIMBRADO,
            payload: busqueda,
        });
    };

    const crearNuevoTimbrado = async (values) => {
        const data = {
            date_from: values?.validodesde,
            date_to: values?.validohasta,
            expedition_point: values?.puntosExpedicion,
            bill_number_start: values?.numeroFacturaInicio,
            bill_number_end: values?.numeroFacturaFin,
            reason: values?.motivo,
            branch: values?.sucursal,
            stamped: values?.nro_timbrado,
            voucher_type_id: values?.tipoComprobante,
            authorization_number: values?.numeroAutorizacion,
        };
        try {
            const resp = await clienteAxios.post('/create_stamped', data);
            message.success(resp?.data?.data?.data);
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }
    };

    const editarTimbrado = async (values) => {
        const data = {
            id: values?.id || undefined,
            date_from: values?.validodesde || undefined,
            date_to: values?.validohasta || undefined,
            voucher_type_id: values?.tipoComprobante || undefined,
            expedition_point: values?.puntosExpedicion || undefined,
            bill_number_start: values?.numeroFacturaInicio || undefined,
            bill_number_end: values?.numeroFacturaFin || undefined,
            reason: values?.motivo || undefined,
            branch: values?.sucursal || undefined,
            stamped: values?.nro_timbrado || undefined,
            authorization_number: values?.numeroAutorizacion || undefined,
            enabled: values?.switchTimbrado,
        };
        try {
            const resp = await clienteAxios.post('/update_stamped', data);
            message.success(resp?.data.data.data);
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }
    };

    return (
        <TimbradoContext.Provider
            value={{
                listadoTimbradosTabla: state.listadoTimbradosTabla,
                listadoTimbrados: state.listadoTimbrados,
                paginaActual: state.paginaActual,
                totalTimbrados: state.totalTimbrados,
                loadingTablaTimbrados,

                obtenerListadoTimbrados,
                crearNuevoTimbrado,
                seleccionarPageTabla,
                editarTimbrado,
                busquedaTimbrado,
            }}>
            {children}
        </TimbradoContext.Provider>
    );
};

export default TimbradoState;

TimbradoState.propTypes = {
    children: PropTypes.object,
};
