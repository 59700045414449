import {
    FACTURAS_LISTA_LOADING,
    FACTURAS_OBTENER_LISTA,
    RECIBOS_LISTA_LOADING,
    RECIBOS_OBTENER_LISTA,
} from './index';

export const initialState = {
    facturasLista: [],
    facturasTotalPaginas: 1,
    facturasListaLoading: false,

    recibosLista: [],
    recibosTotalPaginas: 1,
    recibosListaLoading: false,
};

const DocumentosComercioReducer = (state = initialState, action) => {
    switch (action.type) {
        case FACTURAS_OBTENER_LISTA:
            return {
                ...state,
                facturasLista: action.payload.facturasLista,
                facturasTotalPaginas: action.payload.facturasTotalPaginas,
            };
        case FACTURAS_LISTA_LOADING:
            return {
                ...state,
                facturasListaLoading: !state.facturasListaLoading,
            };
        case RECIBOS_OBTENER_LISTA:
            return {
                ...state,
                recibosLista: action.payload.recibosLista,
                recibosTotalPaginas: action.payload.recibosTotalPaginas,
            };
        case RECIBOS_LISTA_LOADING:
            return {
                ...state,
                recibosListaLoading: !state.recibosListaLoading,
            };

        default:
            return state;
    }
};

export default DocumentosComercioReducer;
