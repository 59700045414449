import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListaTimbrado from './ListaTimbrado';
import NuevoTimbrado from './NuevoTimbrado';
import EditarTimbrado from './EditarTimbrado';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../../constants/Permisos';

const Timbrado = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.finanzas.facturacion.timbrado}
                        componente={ListaTimbrado}
                    />
                )}
            />
            <Route
                path={`${match.url}/nuevo`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.finanzas.facturacion.timbrado}
                        componente={NuevoTimbrado}
                    />
                )}
            />
            <Route
                path={`${match.url}/editar/:id`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.finanzas.facturacion.timbrado}
                        componente={EditarTimbrado}
                    />
                )}
            />
        </Switch>
    );
};

export default Timbrado;

Timbrado.propTypes = {
    match: PropTypes.object,
};
