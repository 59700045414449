import React from 'react';
import {
    StyleSheet,
    Text,
    Font,
    Document,
    Page,
    Image,
    View,
} from '@react-pdf/renderer';
import LogoMonchisDocumentos from '../../assets/images/logo-monchis-documentos-2021.png';
import PropTypes from 'prop-types';

export const ResumenCortePDF = ({ resumenCorteData }) => {
    return (
        <Document>
            <Page size="A4" orientation="portrait" wrap style={styles.page}>
                <View style={{ flexDirection: 'row' }}>
                    <Image
                        src={LogoMonchisDocumentos}
                        style={styles.logo}></Image>
                    <View style={styles.headerDetails}>
                        <Text style={styles.small}>
                            {resumenCorteData?.razonSocial} -{' '}
                            {resumenCorteData?.ruc}
                        </Text>
                        <Text style={styles.small}>
                            {resumenCorteData?.telefono}
                        </Text>
                        <Text style={styles.small}>
                            {resumenCorteData?.correo}
                        </Text>
                    </View>
                    <View style={styles.headerDetails}>
                        <Text style={styles.header4}>Cuenta a acreditar</Text>
                        <Text style={styles.small}>
                            {resumenCorteData?.banco}
                        </Text>
                        <Text style={styles.small}>
                            {resumenCorteData?.cuentaBanco}
                        </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.header1}>
                        Resumen de corte #{resumenCorteData?.nroCorte}
                    </Text>
                    <View style={{ flexDirection: 'row' }}>
                        <Text
                            style={{
                                ...styles.paragraph,
                                ...styles.bold,
                                marginRight: 5,
                            }}>
                            Fecha de corte:
                        </Text>
                        <Text style={styles.paragraph}>
                            {resumenCorteData?.fechaCorte}
                        </Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text
                            style={{
                                ...styles.paragraph,
                                ...styles.bold,
                                marginRight: 5,
                            }}>
                            Periodo de corte:
                        </Text>
                        <Text style={styles.paragraph}>
                            {resumenCorteData?.periodoCorte}
                        </Text>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.header1}>Detalle del corte</Text>
                    <View style={styles.detalleCorte}>
                        <Text style={{ ...styles.paragraph, ...styles.bold }}>
                            Saldo anterior
                        </Text>
                        <Text style={{ ...styles.paragraph, ...styles.green }}>
                            {resumenCorteData?.saldoPendiente}
                        </Text>
                    </View>
                    <View style={styles.detalleCorte}>
                        <Text style={{ ...styles.paragraph, ...styles.bold }}>
                            Amortizaciones
                        </Text>
                        <Text style={{ ...styles.paragraph, ...styles.green }}>
                            {resumenCorteData?.amortizaciones}
                        </Text>
                    </View>
                    {resumenCorteData?.tienePagoOnline && (
                        <View style={styles.detalleCorte}>
                            <Text
                                style={{ ...styles.paragraph, ...styles.bold }}>
                                Pago Online
                            </Text>
                            <Text
                                style={{
                                    ...styles.paragraph,
                                    ...styles.green,
                                }}>
                                {resumenCorteData?.pagosOnline}
                            </Text>
                        </View>
                    )}

                    {resumenCorteData?.tieneMonchisDrivers && (
                        <View style={styles.detalleCorte}>
                            <Text
                                style={{ ...styles.paragraph, ...styles.bold }}>
                                Monchis Drivers
                            </Text>
                            <Text
                                style={{ ...styles.paragraph, ...styles.red }}>
                                {resumenCorteData?.monchisDrivers}
                            </Text>
                        </View>
                    )}
                    <View style={styles.detalleCorte}>
                        <Text style={{ ...styles.paragraph, ...styles.bold }}>
                            Ventas totales
                        </Text>
                        <Text style={{ ...styles.paragraph, ...styles.red }}>
                            {resumenCorteData?.ventasTotales}
                        </Text>
                    </View>
                    <View style={styles.detalleCorte}>
                        <Text style={{ ...styles.paragraph, ...styles.bold }}>
                            Comisión
                        </Text>
                        <Text style={{ ...styles.paragraph, ...styles.red }}>
                            {resumenCorteData?.monchisMarketplace}
                        </Text>
                    </View>

                    <View
                        style={{
                            width: '100%',
                            borderBottom: '1 solid #f11e4c',
                            marginTop: 5,
                            marginBottom: 10,
                        }}
                    />

                    <View style={styles.detalleCorte}>
                        <Text style={{ ...styles.paragraph, ...styles.bold }}>
                            {resumenCorteData?.resumenSaldoLabel}
                        </Text>
                        <Text style={styles.paragraph}>
                            {resumenCorteData?.resumenSaldoValue}
                        </Text>
                    </View>

                    <View
                        style={{
                            width: '100%',
                            borderBottom: '1 solid #f11e4c',
                            marginTop: 5,
                            marginBottom: 10,
                        }}
                    />
                </View>

                <View style={styles.section}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={{ width: '50%' }}>
                            <View style={styles.subDetalle}>
                                <Text style={styles.header3}>
                                    Detalle de la comisión
                                </Text>
                                {resumenCorteData?.resumenMonchisMarketplace}
                            </View>
                        </View>

                        {resumenCorteData?.tieneMonchisDrivers && (
                            <View style={{ width: '50%' }}>
                                <View style={styles.subDetalle}>
                                    <Text style={styles.header3}>
                                        Detalle de Monchis Drivers
                                    </Text>
                                    {resumenCorteData?.resumenMonchisDrivers}
                                </View>
                            </View>
                        )}
                    </View>
                </View>
            </Page>
        </Document>
    );
};

Font.register({
    family: 'MuliRegular',
    fonts: [
        {
            src: '../../assets/fonts/Muli-Regular.ttf',
            fontWeight: 'normal',
        },
        {
            src: '../../assets/fonts/Muli-Bold.ttf',
            fontWeight: 700,
        },
    ],
});

export const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontWeight: 'normal',
    },
    header1: {
        fontWeight: 700,
        fontSize: 18,
        marginBottom: 10,
    },
    header2: {
        fontWeight: 700,
        fontSize: 16,
        marginBottom: 8,
    },
    header3: {
        fontWeight: 700,
        fontSize: 12,
        marginBottom: 8,
    },
    header4: {
        fontWeight: 700,
        fontSize: 10,
        marginBottom: 8,
    },
    paragraph: {
        fontSize: 12,
        marginBottom: 8,
    },
    bold: {
        fontWeight: 700,
    },
    small: {
        fontSize: 8,
        marginBottom: 6,
    },
    section: {
        marginTop: 20,
    },
    red: {
        color: '#f11e4c',
    },
    green: {
        color: '#86c967',
    },

    logo: {
        height: 64,
        width: 64,
    },

    headerDetails: {
        flexGrow: 1,
        marginHorizontal: 10,
        height: 64,
        padding: 10,
        borderWidth: 0.5,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
    },

    detalleCorte: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginVertical: 2,
    },
    subDetalle: {
        borderWidth: 0.5,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        padding: 10,
        marginHorizontal: 5,
    },
});

ResumenCortePDF.propTypes = {
    resumenCorteData: PropTypes.object,
    razonSocial: PropTypes.string,
    ruc: PropTypes.string,
    telefono: PropTypes.string,
    correo: PropTypes.string,
    banco: PropTypes.string,
    cuentaBanco: PropTypes.string,
    nroCorte: PropTypes.string,
    fechaCorte: PropTypes.string,
    periodoCorte: PropTypes.string,
    saldoPendiente: PropTypes.string,
    pagosOnline: PropTypes.string,
    monchisDrivers: PropTypes.string,
    monchisMarketplace: PropTypes.string,
    resumenSaldoLabel: PropTypes.string,
    resumenSaldoValue: PropTypes.string,
    amortizaciones: PropTypes.string,
    resumenMonchisDrivers: PropTypes.string,
    resumenMonchisMarketplace: PropTypes.string,
};
