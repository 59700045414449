import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import NivelUsuario from './NivelUsuario';
import Temporadas from './Temporadas';
import Cupones from './Cupones';
import TemporadasState from '../../../context/programaRecompensa/temporadas/TemporadasState';
import NivelUsuarioState from '../../../context/programaRecompensa/nivelUsuario/NivelUsuarioState';
import BannersPromo from './Promociones/index';

const ProgramaRecompensa = ({ match }) => {
    return (
        <TemporadasState>
            <NivelUsuarioState>
                <Switch>
                    <Route
                        path={`${match.url}/nivel-usuario`}
                        component={NivelUsuario}
                    />
                    <Route
                        path={`${match.url}/temporada`}
                        component={Temporadas}
                    />
                    <Route path={`${match.url}/cupones`} component={Cupones} />
                    <Route
                        path={`${match.url}/promociones`}
                        component={BannersPromo}
                    />
                </Switch>
            </NivelUsuarioState>
        </TemporadasState>
    );
};

export default ProgramaRecompensa;

ProgramaRecompensa.propTypes = {
    match: PropTypes.object,
};
