import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Table, Space, Button } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import PropTypes from 'prop-types';
import TiempoPedido from '../../../../atoms/support/TiempoPedido/TiempoPedido';
import Local from '../../../../atoms/support/Local';
import DatosCliente from '../../../../atoms/support/DatosCliente';
import Acciones from '../../../../atoms/support/acciones/Acciones';
import UltimaActualizacion from '../../../../atoms/support/UltimaActualizacion/UltimaActualizacion';
import classes from './Preparando1.module.css';
import Integraciones from '../../../../atoms/support/Integraciones';
import CargarMasButton from '../../../../atoms/support/CargarMasButton/CargarMasButton';
import tablas from '../tablas.module.css';
import useSupport from '../../../../../hooks/support/useSupport';
import FinalPrice from '../../../../atoms/support/FinalPrice';
import { DoubleRightOutlined } from '@ant-design/icons';

const Preparando = ({ preparando, online, conectarSocket, loading }) => {
    const [selectedRow, setSelectedRow] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [ordenesSeleccionadas, setOrdenesSeleccionadas] = useState([]);
    const rowSelectionConfig = {
        selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRow(selectedRows);
            setSelectedRowKeys(selectedRowKeys);
        },
        onSelectAll: (selected, selectedRow) => {
            setSelectedAll(selected);
            setSelectedRow(selectedRow);
        },
        onSelect: () => {
            selectedAll && setSelectedAll(false);
        },
    };

    const {
        solicitarDriver,
        paginaActualPreparando,
        totalPaginasPreparando,

        permisoVerIntegraciones,
        obtenerTodasOrdenes,
        cambiarEstado,
        cancelarOrden,
        cargarMas,
        abrirModalCambioEstadoSeleccionados,
        verOrdenModal,
        imprimirOrden,
        revertirPago,
        revertirCancelacion,

        deshabilitarBotonIntegracion,
    } = useSupport();

    const mixpanel = useMixpanel();

    //Se trackea el tiempo en esta pantalla antes de solicitar driver
    useEffect(() => {
        mixpanel.time_event('request_driver');
        mixpanel.track('click_on_preparing_tab');
    }, [mixpanel]);

    const obtenerOrdenesHandler = () => {
        obtenerTodasOrdenes();
        conectarSocket();
    };

    const cargarMasButtonHandler = useCallback(() => {
        cargarMas(4, paginaActualPreparando, totalPaginasPreparando);
    }, [cargarMas, paginaActualPreparando, totalPaginasPreparando]);

    // UseEffect para la logica de los select en caso de
    // estar todas las ordenes seleccionadas
    useEffect(() => {
        if (selectedAll) {
            const keys = [];
            selectedRow.forEach((order) => keys.push(order?.key));
            const ordersFromLoadMoreButton = preparando.filter(
                (order) => order.ordersFromLoadMoreButton && order,
            );
            ordersFromLoadMoreButton.forEach((order) => keys.push(order?.key));
            setSelectedRow([...selectedRow, ...ordersFromLoadMoreButton]);
            setSelectedRowKeys(keys);
        }
    }, [preparando, selectedAll, selectedRow]);

    useEffect(
        () => selectedAll && setSelectedAll(false),
        [preparando, selectedAll],
    );

    useEffect(() => {
        let ordenesSeleccionadasArr = [];
        selectedRow.forEach(async (orden) => {
            if (orden.permitirCambiarEstado) {
                ordenesSeleccionadasArr.push(orden);
            }
        });
        setOrdenesSeleccionadas(ordenesSeleccionadasArr);
    }, [selectedRow]);

    const columnaIntegraciones = useMemo(
        () =>
            permisoVerIntegraciones
                ? {
                      dataIndex: 'integraciones',
                      key: 'integraciones',
                      render: (_text, record) => {
                          return (
                              <Integraciones
                                  jediId={record?.jediId}
                                  fechaJedi={record?.fechaJEdi}
                                  idMcDonalds={record?.idMcDonalds}
                                  ordenSeleccionada={record?.ordenOriginal}
                                  esIntegracion={record?.hasIntegration}
                                  esJedi={record?.hasJedi}
                                  estadoOrden={record?.estadoOrden}
                                  idIntegracion={record?.idIntegracion}
                                  envioIntegracion={record?.envioIntegracion}
                                  deshabilitarBotonIntegracion={
                                      deshabilitarBotonIntegracion
                                  }
                              />
                          );
                      },
                  }
                : null,
        [permisoVerIntegraciones, deshabilitarBotonIntegracion],
    );

    const columnasParte1 = useMemo(
        () => [
            {
                dataIndex: 'nombreCliente',
                key: 'nombreCliente',
                render: (_text, record) => (
                    <>
                        <Local
                            nombreLocal={record?.nombreLocal}
                            tipoEntrega={record?.tipoEntrega}
                            codigoOnline={record?.codigoPagoOnline}
                            telefonoLocal={record?.telefonoLocal}
                            tienePermiso={record?.canSeeOnlinePaymentType}
                        />
                        <div>
                            <strong> Orden ID: </strong>
                            {record?.ordenOriginal?.id}
                        </div>
                        <DatosCliente
                            nombre={record?.nombreCliente}
                            id={record?.idCliente}
                            level={record?.level}
                            razonSocial={record?.razonSocial}
                            RUC={record?.ruc}
                        />
                    </>
                ),
            },
            {
                dataIndex: 'precioFinal',
                key: 'precioFinal',
                render: (_text, record) => (
                    <>
                        <Local
                            tipoPago={record?.tipoPago}
                            codigoOnline={record?.codigoPagoOnline}
                        />
                        <strong>
                            <FinalPrice ordenOriginal={record?.ordenOriginal} />
                        </strong>
                    </>
                ),
            },

            {
                dataIndex: 'tiempo_pedido',
                key: 'tiempo_pedido',
                render: (_text, record) => (
                    <TiempoPedido
                        fechaPedido={record?.fechaPedido}
                        tiempoEstimado={record?.tiempoEstimado}
                        ordenOriginal={record?.ordenOriginal}
                    />
                ),
            },
        ],
        [],
    );

    const columnasParte2 = useMemo(
        () => [
            {
                dataIndex: 'acciones',
                key: 'acciones',
                render: (_text, record) => (
                    <Acciones
                        ordenSeleccionada={record?.ordenOriginal}
                        tieneMonchisDriver={record?.tieneMonchisDriver}
                        permitirSolicitarDriver={
                            record?.permitirSolicitarDriver
                        }
                        solicitarDriver={solicitarDriver}
                        tipoPago={record?.tipoPago}
                        permitirRevertirPagoOnline={
                            record?.permitirRevertirPagoOnline
                        }
                        revertirPago={revertirPago}
                        permitirCancelarOrden={record?.permitirCancelarOrden}
                        cancelarOrden={cancelarOrden}
                        permitirCambiarEstado={record?.permitirCambiarEstado}
                        cambiarEstado={cambiarEstado}
                        permitirRevertirCancelacion={
                            record?.permitirRevertirCancelacion
                        }
                        revertirCancelacion={revertirCancelacion}
                        permitirImprimirOrden={record?.permitirImprimirOrden}
                        imprimirOrden={imprimirOrden}
                        verOrden={verOrdenModal}
                        permitirVerOrden={record?.permitirVerOrden}
                        tipoPagoSoloCupon={record?.tipoPagoSoloCupon}
                        tienePermiso={record?.canSeeOnlinePaymentType}
                    />
                ),
            },
        ],
        [
            solicitarDriver,
            revertirPago,
            cancelarOrden,
            cambiarEstado,
            revertirCancelacion,
            imprimirOrden,
            verOrdenModal,
        ],
    );

    const columns = useMemo(
        () =>
            columnaIntegraciones
                ? [...columnasParte1, columnaIntegraciones, ...columnasParte2]
                : [...columnasParte1, ...columnasParte2],
        [columnaIntegraciones, columnasParte1, columnasParte2],
    );

    const classNameRow = (record) => {
        let clase;
        const ordenAtrasada = TiempoPedido({
            fechaPedido: record.fechaPedido,
            tiempoEstimado: record.tiempoEstimado,
            ordenOriginal: record.ordenOriginal,
            obtenerAtrasado: true,
        });
        if (ordenAtrasada) {
            clase = classes.rowClass;
        } else {
            clase = 'table-row-dark';
        }
        return clase;
    };
    const combinedRowClassName = (record, index) => {
        return `${tablas.tableRow} ${classNameRow(record, index)}`;
    };

    const enviarOrdenesSeleccionadas = () => {
        abrirModalCambioEstadoSeleccionados(ordenesSeleccionadas);
    };

    return (
        <Space direction="vertical" size="small" className={classes.space}>
            <UltimaActualizacion
                onClick={obtenerOrdenesHandler}
                online={online}
            />
            <Button
                type="link"
                onClick={enviarOrdenesSeleccionadas}
                disabled={!ordenesSeleccionadas?.length}
                loading={loading}>
                <DoubleRightOutlined />
                Pasar a Delivery / Pickup
            </Button>
            <Table
                className={tablas.bordeNegro}
                columns={columns}
                pagination={false}
                dataSource={preparando}
                showHeader={true}
                rowClassName={combinedRowClassName}
                rowSelection={rowSelectionConfig}
                loading={loading}
            />
            <CargarMasButton
                onCargarMas={cargarMasButtonHandler}
                loading={loading}
                paginaActual={paginaActualPreparando}
                totalPaginas={totalPaginasPreparando}
            />
        </Space>
    );
};

export default React.memo(Preparando);

Preparando.propTypes = {
    preparando: PropTypes.array,
    online: PropTypes.bool,
    conectarSocket: PropTypes.func,
    loading: PropTypes.bool,
};
