import { Alert } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import styles from './NotaVerOrden.module.css';

const NotaVerOrden = ({ contenido }) => {
    const mensaje = (
        <>
            <strong>Nota: </strong> {contenido}
        </>
    );
    return (
        contenido && (
            <Alert message={mensaje} type="error" className={styles.note} />
        )
    );
};

NotaVerOrden.propTypes = {
    contenido: PropTypes.string,
};

export default NotaVerOrden;
