import React from 'react';
import { Card, Typography } from 'antd';
import classes from '../../pages/Push/Push.module.css';
import pushMolecules from './PushMolecules.module.css';
import PropTypes from 'prop-types';

const { Text, Title } = Typography;

// Componente de "Vista previa" del mensaje Push

const PushVistaPrevia = ({ titulo, mensaje }) => {
    return (
        <Card title="Vista previa">
            <Card className={pushMolecules.cardVistaPrevia}>
                <div className={classes.cabeceraCard}>
                    <div className={classes.cabeceraCard_cuadrado}>
                        <div className={classes.cuadrado}></div>
                        <div>
                            <Text>Monchis</Text>
                        </div>
                    </div>
                    <div>
                        <Text>ahora</Text>
                    </div>
                </div>
                <Title level={4} className={classes.titulo}>
                    {titulo}
                </Title>
                <Text>{mensaje}</Text>
            </Card>
        </Card>
    );
};

export default PushVistaPrevia;

PushVistaPrevia.propTypes = {
    titulo: PropTypes.string,
    mensaje: PropTypes.string,
};
