import React, { useContext } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import EmisionDocumentosContext from '../../context/facturacion/emisionDocumentos/EmisionDocumentosContext';
import { ResumenFacturacionPDF } from '../reportes/ResumenFacturacionPDF';
import { pdf } from '@react-pdf/renderer';
import { invoice_states } from '../../constants';
import ConfirmModalContent from '../molecules/Finanzas/Facturacion/EmisionDocumentos/Factura/ConfirmModalContent';
const { confirm } = Modal;

export const useModalesFinanzas = () => {
    const { cambiarEstadoFactura } = useContext(EmisionDocumentosContext);
    const invoicePending = invoice_states[0];
    const invoiceBilled = invoice_states[1];
    const invoicePaid = invoice_states[2];
    const invoiceCanceled = invoice_states[3];

    // se descargar el pdf
    const PdfButtonHandler = async (factura) => {
        const blob = await pdf(
            <ResumenFacturacionPDF factura={factura} esMonchis={true} />,
        ).toBlob();
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `factura.pdf`;
        link.click();
    };

    // se genera un modal pdf
    const estadoButtonHandler = (idFactura, estado) => {
        const idEstado = cambiarEstadoAnumero(estado);
        const idArray = [];
        idArray.push(idFactura);

        // si es id de facturado se muestra el confirm
        if (idEstado === invoiceBilled.id) {
            confirm({
                width: 500,
                title: 'Está seguro/a que quiere facturar esta franquicia?',
                content: <ConfirmModalContent estado={estado} />,
                icon: <ExclamationCircleOutlined />,
                okText: 'Facturar',
                okType: 'primary',
                onOk: () => cambiarEstadoFactura(idArray, idEstado),
                cancelText: 'Cancelar',
            });
        }

        if (idEstado === invoicePaid.id) {
            confirm({
                title: '¿Está seguro/a que quiere cambiar de estado?',
                icon: <ExclamationCircleOutlined />,
                okText: 'Sí, cambiar de estado',
                okType: 'primary',
                onOk: async () =>
                    idEstado >= 1 && cambiarEstadoFactura(idArray, idEstado),
                cancelText: 'Cancelar',
            });
        }

        if (estado === invoiceCanceled.id) {
            confirm({
                title: 'Está seguro/a que quiere anular esta factura?',
                icon: <ExclamationCircleOutlined />,
                okText: 'Confirmar',
                okType: 'primary',
                onOk: async () => cambiarEstadoFactura(idArray, estado),
                cancelText: 'Cancelar',
            });
        }
    };

    const cambiarEstadoAnumero = (estadoConvertir) => {
        const convertir = {
            pendiente: invoicePending.id,
            facturado: invoiceBilled.id,
            pagado: invoicePaid.id,
        };
        return cambiarAlsiguienteEstado(convertir[estadoConvertir]);
    };

    const cambiarAlsiguienteEstado = (resultado) => {
        const cambiar = {};
        cambiar[`${invoicePending.id}`] = invoiceBilled.id; // pendiente es id 1 facturado id 2
        cambiar[`${invoiceBilled.id}`] = invoicePaid.id; // pagado es id 3 anulado id 4
        return cambiar[resultado];
    };

    return {
        PdfButtonHandler,
        estadoButtonHandler,
    };
};
