import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {
    Button,
    Col,
    Empty,
    Form,
    Image,
    Input,
    InputNumber,
    message,
    Radio,
    Row,
    Select,
    Space,
    Spin,
} from 'antd';
import React, { useEffect, useState } from 'react';
import classes from './UserLevelForm.module.css';
import PropTypes from 'prop-types';
import LISTA_TEMAS from '../../../../../constants/temasMonchisApp';

const validateMessages = {
    required: 'Este campo requerido.',
};

const { Option } = Select;

const UserLevelForm = ({
    onSave,
    onCancel,
    loading,
    initialValues,
    isRewardsListLoading,
    rewardsList,
}) => {
    const [userLevelForm] = Form.useForm();
    const [cancelButtonText, setCancelButtonText] = useState();
    const [imageSource, setImageSource] = useState('');

    useEffect(() => {
        if (initialValues) {
            setCancelButtonText('Cancelar');
            userLevelForm.setFieldsValue({
                name: initialValues?.name,
                amount: Number(initialValues?.amount) || undefined,
                price: Number(initialValues?.price) || undefined,
                from: Number(initialValues?.from) || undefined,
                to: Number(initialValues?.to) || undefined,
                points: Number(initialValues?.points) || undefined,
                colours: initialValues?.colours,
                rewards: initialValues?.rewards?.map((item) => ({
                    reward: item?.id?.toString(),
                    quantity: Number(item?.quantity) || 1,
                })),
            });
            onThemeChangeHandler();
        } else {
            setCancelButtonText('Restablecer');
        }
    }, [initialValues]);

    const saveButtonHandler = (values) => {
        onSave(values, cancelButtonHandler);
    };

    const cancelButtonHandler = () => {
        userLevelForm.resetFields();
        onCancel && onCancel();
    };

    const onThemeChangeHandler = () => {
        try {
            if (userLevelForm.getFieldValue('colours')) {
                const src = `/img/nivelesUsuarios/${
                    LISTA_TEMAS.find(
                        (item) =>
                            item?.color ===
                            userLevelForm.getFieldValue('colours'),
                    ).image
                }`;
                setImageSource(src);
            }
        } catch (error) {
            const parsedError =
                error?.response?.data?.message ||
                error?.message ||
                'Hubo un problema al obtener la imagen de este tema';

            message.error(parsedError);
        }
    };

    return (
        <Spin spinning={loading}>
            <Form
                form={userLevelForm}
                layout="vertical"
                validateMessages={validateMessages}
                onFinish={saveButtonHandler}>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            label="Nombre"
                            name="name"
                            rules={[{ required: true }]}
                            style={{ flex: 1 }}>
                            <Input />
                        </Form.Item>

                        <div className="ant-col ant-form-item-label">
                            <label className="ant-form-item-required">
                                Rango de compras en la app
                            </label>
                        </div>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    className={classes.formItemContainer}>
                                    <span>De:</span>
                                    <Form.Item
                                        name="from"
                                        rules={[{ required: true }]}
                                        className={classes.flex1Container}>
                                        <InputNumber
                                            className={
                                                classes.width100Container
                                            }
                                            min={1}
                                            formatter={(value) =>
                                                `Gs. ${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ',',
                                                )
                                            }
                                            parser={(value) =>
                                                value.replace(
                                                    /Gs.\s?|(,*)/g,
                                                    '',
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    className={classes.formItemContainer}>
                                    <span>A:</span>
                                    <Form.Item
                                        name="to"
                                        rules={[{ required: true }]}
                                        className={classes.flex1Container}>
                                        <InputNumber
                                            className={
                                                classes.width100Container
                                            }
                                            min={1}
                                            formatter={(value) =>
                                                `Gs. ${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ',',
                                                )
                                            }
                                            parser={(value) =>
                                                value.replace(
                                                    /Gs.\s?|(,*)/g,
                                                    '',
                                                )
                                            }
                                        />
                                    </Form.Item>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={12}>
                            <Col span={12}>
                                <Form.Item
                                    label="Valor de acceso a puntos"
                                    name="amount"
                                    rules={[{ required: true }]}>
                                    <InputNumber
                                        className={classes.width100Container}
                                        min={1}
                                        formatter={(value) =>
                                            `Gs. ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ',',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Gs.\s?|(,*)/g, '')
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Row
                                    gutter={12}
                                    className={classes.pointsFormItemContainer}>
                                    <Col style={{ padding: '0 1rem' }}>=</Col>
                                    <Col
                                        flex={1}
                                        className={classes.flexContainer}>
                                        <Form.Item
                                            noStyle
                                            name="points"
                                            rules={[{ required: true }]}>
                                            <InputNumber
                                                min={1}
                                                className={
                                                    classes.flex1Container
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col>puntos</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <Form.Item
                                    label="Valor en guaraníes por punto obtenido"
                                    name="price"
                                    rules={[{ required: true }]}>
                                    <InputNumber
                                        style={{ width: '100%' }}
                                        min={1}
                                        formatter={(value) =>
                                            `Gs. ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                ',',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Gs.\s?|(,*)/g, '')
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row gutter={12}>
                            <Col span={12}>
                                <div className={classes.themeFormItemContainer}>
                                    <Form.Item
                                        label="Tema"
                                        name="colours"
                                        rules={[{ required: true }]}>
                                        <Radio.Group
                                            className={
                                                classes.themeRadioGroupContainer
                                            }
                                            onChange={onThemeChangeHandler}>
                                            <Space direction="vertical">
                                                {LISTA_TEMAS?.length ? (
                                                    LISTA_TEMAS?.map((tema) => (
                                                        <Radio
                                                            key={tema.nombre}
                                                            value={tema.color}>
                                                            {tema.nombre}
                                                        </Radio>
                                                    ))
                                                ) : (
                                                    <></>
                                                )}
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </Col>
                            <Col span={12}>
                                {imageSource ? (
                                    <Image
                                        width={200}
                                        height={400}
                                        preview={false}
                                        src={imageSource}
                                    />
                                ) : (
                                    <Empty
                                        className={classes.emptyThemeContainer}
                                        description="Seleccione un tema"
                                    />
                                )}
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className={classes.rewardsFormContainer}>
                    <Col span={24}>
                        <div className="ant-col ant-form-item-label">
                            <label className="ant-form-item-required">
                                Lista de beneficios
                            </label>
                        </div>
                        <div className={classes.rewardsFormListContainer}>
                            <Form.List name="rewards">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(
                                            ({ key, name, ...restField }) => (
                                                <Row key={key} gutter={12}>
                                                    <Col span={20}>
                                                        <Form.Item
                                                            label="Beneficio"
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'reward',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}>
                                                            <Select
                                                                className={
                                                                    classes.rewardsSelectContainer
                                                                }
                                                                showSearch
                                                                loading={
                                                                    isRewardsListLoading
                                                                }
                                                                placeholder="Seleccione un beneficio"
                                                                optionFilterProp="children"
                                                                filterOption={(
                                                                    input,
                                                                    option,
                                                                ) =>
                                                                    option.children
                                                                        .toLowerCase()
                                                                        .indexOf(
                                                                            input.toLowerCase(),
                                                                        ) >= 0
                                                                }>
                                                                {rewardsList?.length ? (
                                                                    <>
                                                                        {rewardsList?.map(
                                                                            (
                                                                                reward,
                                                                            ) => {
                                                                                return (
                                                                                    <Option
                                                                                        key={
                                                                                            reward?.id
                                                                                        }
                                                                                        value={
                                                                                            reward?.id
                                                                                        }
                                                                                        disabled={userLevelForm
                                                                                            .getFieldValue(
                                                                                                'rewards',
                                                                                            )
                                                                                            ?.find(
                                                                                                (
                                                                                                    item,
                                                                                                ) =>
                                                                                                    item?.reward ===
                                                                                                    reward?.id,
                                                                                            )}>
                                                                                        {reward?.name ||
                                                                                            'Sin nombre'}
                                                                                    </Option>
                                                                                );
                                                                            },
                                                                        )}
                                                                    </>
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Form.Item
                                                            label="Cantidad"
                                                            name={[
                                                                name,
                                                                'quantity',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                },
                                                            ]}>
                                                            <InputNumber
                                                                min={1}
                                                                className={
                                                                    classes.width100Container
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col
                                                        span={2}
                                                        className={[
                                                            classes.flexContainer,
                                                            classes.rewardsRemoveButtonContainer,
                                                        ]}>
                                                        <MinusCircleOutlined
                                                            onClick={() =>
                                                                remove(name)
                                                            }
                                                            className={
                                                                classes.rewardsRemoveButton
                                                            }
                                                        />
                                                    </Col>
                                                </Row>
                                            ),
                                        )}
                                        <Form.Item>
                                            <Button
                                                block
                                                type="dashed"
                                                onClick={() => add()}
                                                disabled={
                                                    userLevelForm.getFieldValue(
                                                        'rewards',
                                                    )?.length ===
                                                    rewardsList?.length
                                                }
                                                icon={<PlusOutlined />}>
                                                Agregar un beneficio
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Col>
                </Row>
                <div className={classes.actionsFormItemContainer}>
                    <Button onClick={cancelButtonHandler}>
                        {cancelButtonText}
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Guardar
                    </Button>
                </div>
            </Form>
        </Spin>
    );
};

export default UserLevelForm;

UserLevelForm.propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    loading: PropTypes.bool,
    initialValues: PropTypes.object,
    isRewardsListLoading: PropTypes.bool,
    rewardsList: PropTypes.array,
};
