import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Facturacion from './Facturacion';
import TimbradoState from '../../../context/facturacion/timbrado/TimbradoState';

const Finanzas = ({ match }) => {
    return (
        <TimbradoState>
            <Switch>
                <Route
                    path={`${match.url}/facturacion`}
                    component={Facturacion}
                />
            </Switch>
        </TimbradoState>
    );
};

export default Finanzas;

Finanzas.propTypes = {
    match: PropTypes.object,
};
