import React, { useContext, useEffect, useState } from 'react';
import './cupones.css';
import {
    Row,
    Button,
    Input,
    Table,
    Badge,
    Menu,
    Dropdown,
    Modal,
    Card,
    Col,
    Form,
    Typography,
} from 'antd';
import {
    EyeOutlined,
    DeleteOutlined,
    EllipsisOutlined,
    PlusCircleOutlined,
} from '@ant-design/icons';
import { useMixpanel } from 'react-mixpanel-browser';
import { useHistory } from 'react-router-dom';
import CuponesContext from '../../../../context/programaRecompensa/cupones/CuponesContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import ABMLayout from '../../../../layouts/ABMLayout';

const { Text } = Typography;

const defaultParams = {
    page: 1,
};

const ListaCupones = () => {
    const cuponesContext = useContext(CuponesContext);
    const {
        editarCupon,
        listaCupones,
        loadingListaCupones,
        loadingFormCupones,
        cantidadTotalListaCupones,
        obtenerListaCupones,
        establecerCuponSeleccionado,
    } = cuponesContext;
    const [paramsCupones, setParamsCupones] = useState();
    const [formFiltros] = Form.useForm();
    let history = useHistory();

    const mixpanel = useMixpanel();

    const EllipsisDropdown = (row) => {
        return (
            <Dropdown
                overlay={row.menu}
                placement="bottomRight"
                trigger={['click']}>
                <div className="ellipsis-dropdown">
                    <EllipsisOutlined />
                </div>
            </Dropdown>
        );
    };

    const dropdownMenu = (row) => (
        <Menu className="dropdownMenu">
            <Menu.Item
                key={`${row.id}-ver`}
                onClick={() => editarCuponButtonHandler(row)}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        color: '#4B5563',
                    }}>
                    <EyeOutlined />
                    <Text style={{ marginLeft: '10px' }}>Ver detalles</Text>
                </div>
            </Menu.Item>

            <Menu.Item
                key={`${row.id}-eliminar`}
                onClick={() => ModalConfirmarEliminar(row)}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        color: '#4B5563',
                    }}>
                    <DeleteOutlined />
                    <Text style={{ marginLeft: '10px' }}>Eliminar</Text>
                </div>
            </Menu.Item>
        </Menu>
    );

    const columns = [
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            key: 'monto',
        },
        {
            title: 'Usar en',
            dataIndex: 'usaren',
            key: 'usaren',
        },
        {
            title: 'Límites',
            dataIndex: 'limites',
            key: 'limites',
            render: (value) => (
                <>
                    <div>
                        {value?.limiteUso === 1
                            ? value?.limiteUso + ' cupón'
                            : value?.limiteUso + ' cupones'}
                    </div>
                    <div>
                        {value?.limitePorUser === 1
                            ? value?.limitePorUser + ' uso por persona'
                            : value?.limitePorUser + ' usos por persona'}
                    </div>
                </>
            ),
        },
        {
            title: 'Validez',
            dataIndex: 'validez',
            key: 'validez',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (value) => (
                <div style={{ textAlign: 'center' }}>
                    {value ? (
                        <Badge status="success" text="Habilitado" />
                    ) : (
                        <Badge status="error" text="No habilitado" />
                    )}
                </div>
            ),
        },
        {
            title: 'Creado el ',
            dataIndex: 'creado',
            key: 'creado',
        },
        {
            title: '',
            dataIndex: 'acciones',
            key: 'acciones',
            render: (_, elm) => (
                <div style={{ textAlign: 'right' }}>
                    <EllipsisDropdown menu={dropdownMenu(elm)} />
                </div>
            ),
        },
    ];

    const nuevoCuponButtonHandler = () => {
        guardarFiltrosForm();
        mixpanel.track('click_on_coupon_add_button');
        history.push(`/programa-recompensa/cupones/nuevo`);
    };

    const editarCuponButtonHandler = (cuponSeleccionado) => {
        guardarFiltrosForm();
        establecerCuponSeleccionado(cuponSeleccionado);
        mixpanel.track('click_on_coupon_edit_button', { ...cuponSeleccionado });
        history.push({ pathname: `/programa-recompensa/cupones/editar` });
    };

    // modal para remover cupon
    const ModalConfirmarEliminar = (row) => {
        mixpanel.track('click_on_coupon_delete_button', { ...row });
        Modal.confirm({
            title: 'Estas seguro/a que deseas eliminar el cupón?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Confirmar',
            cancelText: 'Cancelar',
            onOk: () => eliminarCuponButtonHandler(row),
        });
    };

    const eliminarCuponButtonHandler = (row) => {
        const { id } = row;
        let deleted = true;
        editarCupon({ id, deleted }, paramsCupones);
    };

    const onFiltrosFinish = (values) => {
        let params = { page: 1 };
        params['search'] = values?.search || undefined;
        setParamsCupones((state) => ({ ...state, ...params }));
    };

    const guardarFiltrosForm = () => {
        window.history.pushState(
            { paramsCupones },
            paramsCupones,
            window.location.pathname,
        );
    };

    const cambioDePaginaHandler = (page) => {
        const params = { page };
        setParamsCupones((state) => ({ ...state, ...params }));
    };

    // Form Filtros
    useEffect(() => {
        if (window.history.state?.paramsCupones) {
            // Restaurar filtros del Form
            setParamsCupones(window.history.state.paramsCupones);
            const search = window.history.state.paramsCupones?.search || '';
            formFiltros.setFieldsValue({ search });
        } else {
            setParamsCupones({ ...defaultParams });
            formFiltros.setFieldsValue({ search: '' });
        }
    }, []);

    // Tabla
    useEffect(() => {
        if (paramsCupones) obtenerListaCupones(paramsCupones);
    }, [paramsCupones]);

    const loading = loadingFormCupones || loadingListaCupones;

    return (
        <ABMLayout
            titulo={'Cupones'}
            acciones={
                <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={nuevoCuponButtonHandler}>
                    Nuevo
                </Button>
            }>
            <Card>
                <Form
                    name="filtros"
                    form={formFiltros}
                    layout="vertical"
                    onFinish={onFiltrosFinish}>
                    <Row gutter={12}>
                        <Col span={4}>
                            <Form.Item label="Buscar por código" name="search">
                                <Input disabled={loading} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item style={{ marginTop: 26 }}>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    disabled={loading}>
                                    Buscar
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>

                <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            loading={loading}
                            columns={columns}
                            dataSource={listaCupones}
                            pagination={{
                                pageSize: 20,
                                total: cantidadTotalListaCupones,
                                current: paramsCupones?.page || 1,
                                showSizeChanger: false,
                                onChange: (page) => cambioDePaginaHandler(page),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ListaCupones;
