import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import SolicitarDriverButton from './SolicitarDriverButton';
import VerOrdenButton from './VerOrdenButton';
import RevertirPagoButton from './RevertirPagoButton';
import CambiarEstadoButton from './CambiarEstadoButton';
import useSupport from '../../../../hooks/support/useSupport';
import EnviarWhatsapp from './EnviarWhatsapp';
import classes from './Acciones1.module.css';
import RevertirCancelacionButton from './RevertirCancelacionButton';
import AlertComponent from '../AlertaPorRetraso/AlertaPorRetraso';

const Acciones = ({
    ordenSeleccionada,
    tieneMonchisDriver,
    permitirSolicitarDriver,
    solicitarDriver,
    tipoPago,
    revertirPago,
    permitirRevertirPagoOnline,
    permitirCambiarEstado,
    cambiarEstado,
    permitirRevertirCancelacion,
    revertirCancelacion,
    verOrden,
    tiempoEstimado,
    permitirVerOrden,
    tipoPagoSoloCupon,
    pagoRevertido,
    alertSent,
}) => {
    const { setHasPermitionToChangeState } = useSupport();

    const solicitarDriverButtonHandler = () =>
        solicitarDriver(ordenSeleccionada);

    const revertirPagoButtonHandler = () => revertirPago(ordenSeleccionada);

    const cambiarEstadoButtonHandler = () => cambiarEstado(ordenSeleccionada);

    const verOrdenButtonHandler = () => {
        setHasPermitionToChangeState(permitirCambiarEstado);
        verOrden({ ordenSeleccionada });
    };

    const revertirCancelacionButtonHandler = () =>
        revertirCancelacion(ordenSeleccionada);

    const storedRoles = JSON.parse(localStorage.getItem('role')) || {};
    const admins = [1, 22, 10];
    // Verificar si el usuario tiene el rol de soporteMonchisAdm o superadmin

    const idEstadoOrden = ordenSeleccionada?.order_state_id || 0;

    return (
        <Row wrap={false}>
            {idEstadoOrden === 2 && !pagoRevertido && (
                <RevertirPagoButton
                    tipoPago={tipoPago}
                    permitirRevertirPagoOnline={permitirRevertirPagoOnline}
                    revertirPagoButtonHandler={revertirPagoButtonHandler}
                    tipoPagoSoloCupon={tipoPagoSoloCupon}
                    pagoRevertido={pagoRevertido}
                />
            )}

            {idEstadoOrden === 3 && admins.indexOf(storedRoles) !== -1 ? (
                <EnviarWhatsapp
                    nombreSucursal={ordenSeleccionada?.branch?.name}
                    fechaPedido={ordenSeleccionada?.confirmed_at}
                    numeroOrden={ordenSeleccionada?.id}
                    tiempoEstimado={tiempoEstimado}
                    usuario={`${ordenSeleccionada?.user?.first_name} ${ordenSeleccionada?.user?.last_name}`}
                />
            ) : (
                ''
            )}

            {idEstadoOrden === 3 && admins.indexOf(storedRoles) !== -1 ? (
                <AlertComponent
                    alertSent={alertSent}
                    fechaPedido={ordenSeleccionada?.confirmed_at}
                />
            ) : (
                ''
            )}

            <div className={classes.botonesContainer}>
                <SolicitarDriverButton
                    tieneMonchisDriver={tieneMonchisDriver}
                    permitirSolicitarDriver={permitirSolicitarDriver}
                    solicitarDriverButtonHandler={solicitarDriverButtonHandler}
                    idEstadoOrden={idEstadoOrden}
                    ordenSeleccionada={ordenSeleccionada}
                />

                <VerOrdenButton
                    permitirVerOrden={permitirVerOrden}
                    verOrdenButtonHandler={verOrdenButtonHandler}
                />

                {!(idEstadoOrden === 6) && (
                    <CambiarEstadoButton
                        permitirCambiarEstado={permitirCambiarEstado}
                        cambiarEstadoButtonHandler={cambiarEstadoButtonHandler}
                        idEstadoOrden={idEstadoOrden}
                    />
                )}
            </div>
            {!pagoRevertido && (
                <RevertirCancelacionButton
                    permitirRevertirCancelacion={permitirRevertirCancelacion}
                    revertirCancelacionButtonHandler={
                        revertirCancelacionButtonHandler
                    }
                />
            )}
        </Row>
    );
};

Acciones.propTypes = {
    ordenSeleccionada: PropTypes.object,
    tieneMonchisDriver: PropTypes.bool,
    permitirSolicitarDriver: PropTypes.bool,
    alertSent: PropTypes.bool,
    solicitarDriver: PropTypes.func,
    tipoPago: PropTypes.any,
    revertirPago: PropTypes.func,
    permitirRevertirPagoOnline: PropTypes.bool,
    cancelarOrden: PropTypes.func,
    permitirCancelarOrden: PropTypes.bool,
    revertirCancelacion: PropTypes.func,
    permitirRevertirCancelacion: PropTypes.bool,
    cambiarEstado: PropTypes.func,
    permitirCambiarEstado: PropTypes.bool,
    imprimirOrden: PropTypes.func,
    permitirImprimirOrden: PropTypes.bool,
    verOrden: PropTypes.func,
    tiempoEstimado: PropTypes.any,
    permitirVerOrden: PropTypes.bool,
    obtenerSiguienteEstado: PropTypes.func,
    tipoPagoSoloCupon: PropTypes.any,
    pagoRevertido: PropTypes.bool,
};

export default React.memo(Acciones);
