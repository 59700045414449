import React, { useReducer } from 'react';
import clienteAxios from '../../../config/axios';
import DocumentosComercioReducer, {
    initialState,
} from './DocumentosComercioReducer';
import DocumentosComercioContext from './DocumentosComercioContext';
import PropTypes from 'prop-types';
import { message } from 'antd';
import {
    FACTURAS_LISTA_LOADING,
    FACTURAS_OBTENER_LISTA,
    RECIBOS_LISTA_LOADING,
    RECIBOS_OBTENER_LISTA,
} from './index';

const DocumentosComercioState = ({ children }) => {
    const [state, dispatch] = useReducer(
        DocumentosComercioReducer,
        initialState,
    );

    const obtenerListaDocumentos = async (data) => {
        let respuesta;
        try {
            respuesta = await clienteAxios.post('/get_invoices_by_user', data);
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }

        return respuesta?.data?.data;
    };

    const facturasObtenerLista = async (params) => {
        dispatch({
            type: FACTURAS_LISTA_LOADING,
        });

        const documentos = await obtenerListaDocumentos(params);
        const facturas = documentos.invoices;
        const invoicesArray = new Array();
        for (let i = 0; i < facturas.length; i++) {
            const newInvoices = {
                key: facturas[i]?.invoice?.id,
                numero: facturas[i]?.invoice?.invoice_number,
                emision: facturas[i]?.invoice?.date,
                franquicia: facturas[i]?.franchise_society?.name,
                razonSocial: facturas[i]?.franchise_society?.legal_name,
                ruc: facturas[i]?.franchise_society?.ruc,
                impresiones: facturas[i]?.invoice?.viewed_count,
                estado: facturas[i]?.invoice_state?.name,
                total: facturas[i]?.invoice?.amount,
                factura: facturas[i],
                timbrado: facturas[i].stamped?.stamped,
            };
            invoicesArray.push(newInvoices);
        }

        dispatch({
            type: FACTURAS_OBTENER_LISTA,
            payload: {
                facturasLista: invoicesArray,
                facturasTotalPaginas: documentos?.total || 1,
            },
        });

        dispatch({
            type: FACTURAS_LISTA_LOADING,
        });
    };

    const recibosObtenerLista = async (parametros) => {
        dispatch({
            type: RECIBOS_LISTA_LOADING,
        });

        const listaDocumentos = await obtenerListaDocumentos(parametros);

        // Extraer datos relevantes
        const recibos = listaDocumentos?.invoices.map((recibo) => ({
            key: recibo.invoice.id,
            idFactura: recibo.invoice.id,
            nroRecibo: recibo.invoice.invoice_number,
            nombreFranquicia: recibo.franchise_society.name,
            nombreLegal: recibo.franchise_society.legal_name,
            ruc: recibo.franchise_society.ruc,
            estado: recibo.invoice_state.name,
            total: recibo.invoice.amount,
            idFranquicia: recibo.franchise_society.id,
            secreto: recibo.invoice.secret,
        }));

        dispatch({
            type: RECIBOS_OBTENER_LISTA,
            payload: {
                recibosLista: recibos,
                recibosTotalPaginas: listaDocumentos?.total || 1,
            },
        });

        dispatch({
            type: RECIBOS_LISTA_LOADING,
        });
    };

    return (
        <DocumentosComercioContext.Provider
            value={{
                facturasLista: state.facturasLista,
                facturasTotalPaginas: state.facturasTotalPaginas,
                facturasListaLoading: state.facturasListaLoading,

                recibosLista: state.recibosLista,
                recibosTotalPaginas: state.recibosTotalPaginas,
                recibosListaLoading: state.recibosListaLoading,

                facturasObtenerLista,
                recibosObtenerLista,
            }}>
            {children}
        </DocumentosComercioContext.Provider>
    );
};

export default DocumentosComercioState;

DocumentosComercioState.propTypes = {
    children: PropTypes.object,
};
