import React, { useCallback, useContext, useEffect } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';
import UltimaActualizacion from '../../../../atoms/support/UltimaActualizacion/UltimaActualizacion';
import { Space, Table, Tag } from 'antd';
import Acciones from '../../../../atoms/support/acciones/Acciones';
import SupportContext from '../../../../../context/support/SupportContext';
import TiempoPedido from '../../../../atoms/support/TiempoPedido/TiempoPedido';
import Local from '../../../../atoms/support/Local';
import DatosCliente from '../../../../atoms/support/DatosCliente';
import classes from './Cancelados1.module.css';
import { pagoOnline } from '../../../../../constants/tiposPago';
import CargarMasButton from '../../../../atoms/support/CargarMasButton/CargarMasButton';
import PropTypes from 'prop-types';
import tablas from '../tablas.module.css';
import FinalPrice from '../../../../atoms/support/FinalPrice';
import useSupport from '../../../../../hooks/support/useSupport';

const Cancelados = ({ cancelados, online, conectarSocket, loading }) => {
    const { cambiarEstado } = useContext(SupportContext);

    const {
        paginaActualCancelados,
        totalPaginasCancelados,

        cancelarOrden,
        revertirCancelacion,
        obtenerTodasOrdenes,
        cargarMas,
        verOrdenModal,
        imprimirOrden,
        solicitarDriver,
        revertirPago,
    } = useSupport();

    const mixpanel = useMixpanel();

    //Se trackea el tiempo en esta pantalla antes de solicitar driver
    useEffect(() => {
        mixpanel.time_event('request_driver');
        mixpanel.track('click_on_canceled_tab');
    }, [mixpanel]);

    const obtenerOrdenesHandler = () => {
        obtenerTodasOrdenes();
        conectarSocket();
    };

    const cargarMasButtonHandler = useCallback(() => {
        cargarMas(2, paginaActualCancelados, totalPaginasCancelados);
    }, [cargarMas, paginaActualCancelados, totalPaginasCancelados]);

    const estadoPagoOnline = (pagoRevertido) => {
        return pagoRevertido ? (
            <Tag className={classes.pagoReversado}>Pago online reversado</Tag>
        ) : (
            <Tag className={classes.pendienteReversion}>
                Pago online PENDIENTE de reversión
            </Tag>
        );
    };

    const columns = [
        {
            dataIndex: 'nombreCliente',
            key: 'nombreCliente',
            render: (_text, record) => (
                <>
                    <Local
                        nombreLocal={record?.nombreLocal}
                        tipoEntrega={record?.tipoEntrega}
                        codigoOnline={record?.codigoPagoOnline}
                        telefonoLocal={record?.telefonoLocal}
                        tienePermiso={record?.canSeeOnlinePaymentType}
                    />
                    <div>
                        <strong> Orden ID: </strong>
                        {record?.ordenOriginal?.id}
                    </div>
                    <DatosCliente
                        nombre={record?.nombreCliente}
                        id={record?.idCliente}
                        level={record?.level}
                        razonSocial={record?.razonSocial}
                        RUC={record?.ruc}
                    />
                </>
            ),
        },
        {
            dataIndex: 'precioFinal',
            key: 'precioFinal',
            render: (_text, record) => (
                <>
                    <Local
                        tipoPago={record?.tipoPago}
                        codigoOnline={record?.codigoPagoOnline}
                    />
                    <strong>
                        <FinalPrice ordenOriginal={record?.ordenOriginal} />
                    </strong>
                </>
            ),
        },
        {
            dataIndex: 'tiempoPedido',
            key: 'tiempoPedido',
            render: (_text, record) => (
                <TiempoPedido
                    fechaPedido={record?.fechaPedido}
                    tiempoEstimado={record?.tiempoEstimado}
                    ordenOriginal={record?.ordenOriginal}
                />
            ),
        },

        {
            dataIndex: 'estadoPago',
            key: 'estadoPago',
            render: (_text, record) =>
                record?.tipoPago === 'CASH' || record?.tipoPago === 'POS'
                    ? '----'
                    : pagoOnline
                    ? estadoPagoOnline(record?.pagoRevertido)
                    : '---',
        },
        {
            dataIndex: 'motivoCancelacion',
            key: 'motivoCancelacion',
            render: (_text, record) =>
                record?.motivoCancelacion || 'No disponible',
        },
        {
            dataIndex: 'acciones',
            key: 'acciones',
            render: (_text, record) => (
                <Acciones
                    ordenSeleccionada={record?.ordenOriginal}
                    tieneMonchisDriver={record?.tieneMonchisDriver}
                    permitirSolicitarDriver={record?.permitirSolicitarDriver}
                    solicitarDriver={solicitarDriver}
                    tipoPago={record?.tipoPago}
                    permitirRevertirPagoOnline={
                        record?.permitirRevertirPagoOnline
                    }
                    revertirPago={revertirPago}
                    permitirCancelarOrden={record?.permitirCancelarOrden}
                    cancelarOrden={cancelarOrden}
                    permitirCambiarEstado={record?.permitirCambiarEstado}
                    cambiarEstado={cambiarEstado}
                    permitirRevertirCancelacion={
                        record?.permitirRevertirCancelacion
                    }
                    revertirCancelacion={revertirCancelacion}
                    permitirImprimirOrden={record?.permitirImprimirOrden}
                    imprimirOrden={imprimirOrden}
                    verOrden={verOrdenModal}
                    permitirVerOrden={record?.permitirVerOrden}
                    tipoPagoSoloCupon={record?.tipoPagoSoloCupon}
                    pagoRevertido={record?.pagoRevertido}
                />
            ),
        },
    ];

    return (
        <Space direction="vertical" size="small" className={classes.space}>
            <UltimaActualizacion
                onClick={obtenerOrdenesHandler}
                online={online}
            />
            <Table
                columns={columns}
                pagination={false}
                dataSource={cancelados}
                loading={loading}
                showHeader={false}
                rowClassName={tablas.tableRow}
            />
            <CargarMasButton
                onCargarMas={cargarMasButtonHandler}
                loading={loading}
                paginaActual={paginaActualCancelados}
                totalPaginas={totalPaginasCancelados}
            />
        </Space>
    );
};

export default Cancelados;

Cancelados.propTypes = {
    cancelados: PropTypes.array,
    online: PropTypes.bool,
    conectarSocket: PropTypes.func,
    loading: PropTypes.bool,
};
