import React from 'react';
import 'antd/dist/antd.css';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const suffix = (
    <SearchOutlined
        style={{
            fontSize: 16,
            color: 'gray',
        }}
    />
);

const Inputs = (props) => {
    return (
        <div>
            {props.title && <h5>{props.title}</h5>}
            <Input
                {...props}
                suffix={suffix}
                placeholder={props.subtitle}></Input>
        </div>
    );
};

Inputs.propTypes = {
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    prefix: PropTypes.element,
    icon: PropTypes.element,
    title: PropTypes.string,
};

export default Inputs;
