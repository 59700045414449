const descargarArchivoRaw = (rawFile, fileName, fileType) => {
    const url = window.URL.createObjectURL(new Blob([rawFile]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.${fileType}`);
    document.body.appendChild(link);
    link.click();
};

export default descargarArchivoRaw;
