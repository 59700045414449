import { Card, Col, Row, Typography } from 'antd';
import React from 'react';
import clases from './analiticas.module.css';
import reloj from '../../../../assets/svg/support/reloj.svg';
import PropTypes from 'prop-types';

const { Text } = Typography;

const CardHoras = ({ datosHorarioMayorCompra }) => {
    return (
        <Card className={clases.cardDias}>
            <div className={clases.divImg}>
                <img src={reloj} className={clases.imgCalendario} />
            </div>
            <div className={clases.topDirecciones}>
                {datosHorarioMayorCompra &&
                    datosHorarioMayorCompra.map((item) => (
                        <Row key={item?.key} className={clases.dias}>
                            <Col>
                                <Text strong>{`${item?.dia}:`}</Text>
                            </Col>
                            <Col>
                                <span>{`${item?.total} ${
                                    item?.total > 1 ? 'pedidos' : 'pedido'
                                }`}</span>
                            </Col>
                        </Row>
                    ))}
            </div>
        </Card>
    );
};

export default CardHoras;

CardHoras.propTypes = {
    datosHorarioMayorCompra: PropTypes.array,
};
