import React from 'react';

function Check() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none">
            <path
                d="M17.0654 8.6174H15.9203C15.6713 8.6174 15.4345 8.73703 15.288 8.94211L11.4501 14.2644L9.71184 11.8523C9.56536 11.6496 9.33098 11.5276 9.07952 11.5276H7.9345C7.77581 11.5276 7.68303 11.7082 7.77581 11.8376L10.8178 16.0564C10.8897 16.1567 10.9844 16.2384 11.0941 16.2948C11.2039 16.3512 11.3255 16.3806 11.4489 16.3806C11.5723 16.3806 11.6939 16.3512 11.8037 16.2948C11.9134 16.2384 12.0081 16.1567 12.08 16.0564L17.2216 8.92746C17.3168 8.79807 17.224 8.6174 17.0654 8.6174Z"
                fill="#00C54F"
            />
            <path
                d="M12.5 1.5625C6.45996 1.5625 1.5625 6.45996 1.5625 12.5C1.5625 18.54 6.45996 23.4375 12.5 23.4375C18.54 23.4375 23.4375 18.54 23.4375 12.5C23.4375 6.45996 18.54 1.5625 12.5 1.5625ZM12.5 21.582C7.48535 21.582 3.41797 17.5146 3.41797 12.5C3.41797 7.48535 7.48535 3.41797 12.5 3.41797C17.5146 3.41797 21.582 7.48535 21.582 12.5C21.582 17.5146 17.5146 21.582 12.5 21.582Z"
                fill="#00C54F"
            />
        </svg>
    );
}

export default Check;
