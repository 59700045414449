import React, { useState, createRef } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import { Card, Form, Input } from 'antd';
import Emoji from '../Emoji';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const PushMensaje = ({
    pushForm,
    prefix,
    maxCantidadCaracteresTitulo,
    maxCantidadCaracteresMensaje,
}) => {
    //state para emoji
    const [mostrarEmojisTitulo, setMostrarEmojisTitulo] = useState(false);
    const [mostrarEmojisMensaje, setMostrarEmojisMensaje] = useState(false);

    const inputRefTitulo = createRef();
    const inputRefMensaje = createRef();

    const obtenerTextareaTitulo = document.getElementById('basic_titulozonas');
    const obtenerTextareaMensaje =
        document.getElementById('basic_mensajezonas');

    // se guarda el emoji seleccionado
    const pickEmojiTitulo = (e, { emoji }) => {
        const refTitulo = obtenerTextareaTitulo;
        let titulo = pushForm.getFieldsValue(['titulozonas']).titulozonas;
        refTitulo.focus();
        const start = titulo.slice(0, refTitulo.selectionStart);
        const end = titulo.slice(refTitulo.selectionEnd);
        const text = start + emoji + end;

        // se tiene que reiniciar al poner un emoji nuevo
        setMostrarEmojisTitulo();

        //establecer titulo nuevo en la pantalla
        pushForm.setFieldsValue({ titulozonas: text });
    };

    const pickEmojiMensaje = (e, { emoji }) => {
        const refMensaje = obtenerTextareaMensaje;
        let mensaje = pushForm.getFieldsValue(['mensajezonas']).mensajezonas;
        refMensaje.focus();
        const start = mensaje.slice(0, refMensaje.selectionStart);
        const end = mensaje.slice(refMensaje.selectionEnd);
        const text = start + emoji + end;
        // se tiene que reiniciar al poner un emoji nuevo
        setMostrarEmojisMensaje();
        //establecer titulo nuevo en la pantalla
        pushForm.setFieldsValue({ mensajezonas: text });
    };

    // abre el picker del emoji
    const handleMostrarEmojisTitulo = () => {
        setMostrarEmojisTitulo(!mostrarEmojisTitulo);
    };

    // abre el picker del emoji
    const handleMostrarEmojisMensaje = () => {
        setMostrarEmojisMensaje(!mostrarEmojisMensaje);
    };

    return (
        <>
            <Card title="Su mensaje">
                <Form.Item
                    name={'titulo' + prefix}
                    label="Titulo"
                    rules={[{ required: true }]}>
                    <TextArea
                        name={'titulo' + prefix}
                        maxLength={maxCantidadCaracteresTitulo}
                        showCount
                        autoSize="false"
                        ref={inputRefTitulo}
                        cols={58}
                        rows={1}
                    />
                </Form.Item>
                <SmileOutlined
                    onClick={handleMostrarEmojisTitulo}
                    style={{ position: 'absolute', top: 100, right: 30 }}
                />
                {mostrarEmojisTitulo ? (
                    <Emoji pickEmoji={pickEmojiTitulo} />
                ) : null}

                <Form.Item
                    name={'mensaje' + prefix}
                    label="Mensaje"
                    rules={[{ required: true }]}>
                    <TextArea
                        ref={inputRefMensaje}
                        name={'mensaje' + prefix}
                        maxLength={maxCantidadCaracteresMensaje}
                        autoSize="false"
                        showCount
                        cols={58}
                        rows={1}
                    />
                </Form.Item>
                <SmileOutlined
                    onClick={handleMostrarEmojisMensaje}
                    style={{ position: 'absolute', top: 195, right: 30 }}
                />
                {mostrarEmojisMensaje ? (
                    <Emoji pickEmoji={pickEmojiMensaje} />
                ) : null}
            </Card>
        </>
    );
};

export default PushMensaje;

PushMensaje.propTypes = {
    pushForm: PropTypes.any,
    prefix: PropTypes.string,
    maxCantidadCaracteresTitulo: PropTypes.number,
    maxCantidadCaracteresMensaje: PropTypes.number,
};
