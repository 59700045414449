import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Row, Col } from 'antd';

const PermissionsAlert = ({ hasPermissionsLocked }) => {
    return (
        <>
            {hasPermissionsLocked === true ? (
                <Row gutter={12}>
                    <Col span={24} style={styles.alert}>
                        <Alert
                            message="Los permisos del navegador están bloqueados, recomendamos activarlos junto con el sonido para una mejor atención"
                            type="error"
                            showIcon
                        />
                    </Col>
                </Row>
            ) : (
                ''
            )}
        </>
    );
};

PermissionsAlert.propTypes = {
    hasPermissionsLocked: PropTypes.bool,
};

export default PermissionsAlert;

const styles = {
    alert: {
        marginBottom: '10px',
    },
};
