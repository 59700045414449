import React from 'react';
import { Divider, Modal, Typography, Tag, Table, Spin } from 'antd';
import PropTypes from 'prop-types';
import classes from './EstadoJediModal.module.css';
import NotaVerOrden from '../../../atoms/support/NotaVerOrden/NotaVerOrden';
const { Text } = Typography;
const EstadoJediModal = ({
    modalEstadoJediVisible,
    cerrarModalVerEstadoJedi,
    listaTableEstadoJedi,
    datosModalVerEstadoJedi,
    loadingIntegraciones,
}) => {
    const columns = [
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
        },
        {
            title: 'Producto',
            dataIndex: 'producto',
            key: 'producto',
            render: (_text, record) => (
                <>
                    <Text strong={true}>{record?.producto}</Text>
                    {record?.agregadosJedi?.map((item) => (
                        <p key={item}>{item}</p>
                    ))}
                </>
            ),
        },
        {
            title: 'Precio Unitario',
            dataIndex: 'precioUnitario',
            key: 'precioUnitario',
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            key: 'monto',
        },
        {
            title: 'Comentarios',
            dataIndex: 'comentarios',
            key: 'comentarios',
        },
    ];

    return (
        <Modal
            open={modalEstadoJediVisible}
            onCancel={cerrarModalVerEstadoJedi}
            footer={false}
            width="60%">
            <Spin spinning={loadingIntegraciones}>
                <div className={classes.headerEstadoJedi}>
                    <span style={{ display: 'flex' }}>
                        <Text level={5} strong={true}>
                            {datosModalVerEstadoJedi?.estado}
                        </Text>
                        <Tag
                            style={{
                                marginLeft: '10px',
                                borderRadius: '10px',
                            }}>
                            {datosModalVerEstadoJedi?.modulo}
                        </Tag>
                    </span>
                    <span style={{ display: 'flex' }}>
                        <Text level={5} strong={true}>
                            Sucursal:
                        </Text>
                        <Text level={5}>
                            {' '}
                            {datosModalVerEstadoJedi?.sucursal}
                        </Text>
                    </span>
                    <Text level={5} strong={true}>
                        JEDI #{datosModalVerEstadoJedi?.id}
                    </Text>
                </div>
                <div className={classes.headerDetalle}>
                    <div className={classes.box}>
                        <span>
                            <strong>{datosModalVerEstadoJedi?.persona}</strong>
                        </span>
                        {datosModalVerEstadoJedi?.ruc && (
                            <>
                                <span>
                                    <strong>Razón social:</strong>{' '}
                                    {datosModalVerEstadoJedi?.persona}
                                </span>
                                <span>
                                    <strong>RUC:</strong>{' '}
                                    {datosModalVerEstadoJedi?.ruc}
                                </span>
                            </>
                        )}
                        <span>
                            <strong>Teléfono:</strong>{' '}
                            {datosModalVerEstadoJedi?.telefono}
                        </span>
                    </div>
                    <div className={classes.box}>
                        <span>
                            <strong>Dirección:</strong>{' '}
                            {datosModalVerEstadoJedi?.direccion}
                        </span>
                    </div>
                    <div className={classes.box}>
                        <div className={classes.boxTotal}>
                            <strong>Total Orden</strong>
                            <span>{datosModalVerEstadoJedi?.total}</span>
                        </div>
                    </div>
                </div>
                {listaTableEstadoJedi?.comentarioOrden && (
                    <NotaVerOrden
                        contenido={datosModalVerEstadoJedi?.obervacion}
                    />
                )}
                <Table
                    pagination={false}
                    columns={columns}
                    dataSource={listaTableEstadoJedi}></Table>
            </Spin>
        </Modal>
    );
};

export default EstadoJediModal;

EstadoJediModal.propTypes = {
    modalEstadoJediVisible: PropTypes.any,
    cerrarModalVerEstadoJedi: PropTypes.any,
    listaTableEstadoJedi: PropTypes.any,
    datosModalVerEstadoJedi: PropTypes.any,
    loadingIntegraciones: PropTypes.any,
};
