import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ReporteCupones from './ReporteCupones';
import ReporteVerCupones from './VerReporteCupones/ReporteVerCupones';
import ReporteCuponesState from '../../../../context/reportes/reporteCupones/ReporteCuponesState';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';
import PropTypes from 'prop-types';
import CuponesState from '../../../../context/programaRecompensa/cupones/CuponesState';

const CuponesReporte = ({ match }) => {
    return (
        <ReporteCuponesState>
            <CuponesState>
                <Switch>
                    <Route
                        path={`${match.url}/cupones/lista`}
                        render={() => (
                            <ProtectedComponent
                                permiso={
                                    Permisos.reportes.programaRecompensa
                                        .verReporteCupones
                                }
                                componente={ReporteCupones}
                            />
                        )}
                    />
                    <Route
                        path={`${match.url}/cupones/detalle`}
                        render={() => (
                            <ProtectedComponent
                                permiso={
                                    Permisos.reportes.programaRecompensa
                                        .verReporteCupones
                                }
                                componente={ReporteVerCupones}
                            />
                        )}
                    />
                </Switch>
            </CuponesState>
        </ReporteCuponesState>
    );
};

export default CuponesReporte;

CuponesReporte.propTypes = {
    match: PropTypes.object,
};
