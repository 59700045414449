import { MonitorOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';

const SinDatosPlaceholder = ({ titulo, style }) => {
    return (
        <div style={{ ...styles.container, ...style }}>
            <Space direction="vertical" size="middle" style={styles.space}>
                <MonitorOutlined style={styles.icono} />
                <p>{titulo}</p>
            </Space>
        </div>
    );
};

SinDatosPlaceholder.propTypes = {
    titulo: PropTypes.string,
    style: PropTypes.object,
};

export default SinDatosPlaceholder;

const styles = {
    container: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
    },
    space: { width: '100%' },
    icono: {
        fontSize: 54,
        color: 'var(--azul)',
    },
};
