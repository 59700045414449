import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Form,
    Input,
    List,
    Modal,
    Col,
    Row,
    Select,
    Typography,
    Alert,
} from 'antd';
import ModalContent from 'components/atoms/ModalContent';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import styles from './AddProductModal.module.css';
import PropTypes from 'prop-types';
import filterOption from 'components/utils-components/filterOption';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';

const { Option } = Select;
const { Text } = Typography;

const getOptionsFromList = (list) => {
    const uniqueItems = [...new Set(list)];
    return uniqueItems.map((item) => {
        const [id, name] = item.split('|');
        return (
            <Option key={id} value={id}>
                {name}
            </Option>
        );
    });
};

const AddProductModal = ({ visible, onSave, onClose }) => {
    const {
        productList,
        productListPageSize,
        productListTotal,
        characteristics,

        getProductsList,
        setProductList,
        setSelectedProducts,
    } = useContext(PromotionsContext);

    const { obtenerFranquicias, obtenerSucursales } =
        useContext(UtilidadesContext);

    const [searchForm] = Form.useForm();
    const [loader, setLoader] = useState();
    const [addedProducts, setAddedProducts] = useState([]);
    const [franchises, setFranchises] = useState([]);
    const [obtainingFranchises, setObtainingFranchises] = useState(false);
    const [branches, setBranches] = useState([]);
    const [obtainingBranches, setObtainingBranches] = useState(false);
    const [errorItem, setErrorItem] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [selectedFranchises, setSelectedFranchises] = useState(false);

    const franchisesSelected = characteristics
        ?.map((item) => item.franchises)
        .filter(Boolean);

    const branchesSelected = characteristics
        ?.map((item) => item.branches)
        .filter(Boolean);

    const franchisesOptions =
        franchisesSelected?.length > 0
            ? getOptionsFromList(franchisesSelected)
            : franchises.map((item) => (
                  <Option key={item.id} value={item.id}>
                      {item.name}
                  </Option>
              ));

    const branchesOptions =
        branchesSelected?.length > 0
            ? getOptionsFromList(branchesSelected)
            : branches.map((item) => (
                  <Option key={item.id} value={item.id}>
                      {item.name}
                  </Option>
              ));

    useEffect(() => {
        if (visible) {
            if (franchisesSelected.length > 0) {
                setLoader(true);
                const productName = null;
                let franchisesToString = null;
                let branchesToString = null;

                franchisesToString = getIdList(franchisesSelected);

                const branchesList = getIdList(branchesSelected);
                if (branchesList.length > 0) {
                    branchesToString = branchesList;
                }

                getProducts(
                    productName,
                    franchisesToString,
                    branchesToString,
                    currentPage,
                );
            } else {
                setObtainingFranchises(true);
                obtenerFranquicias()
                    .then((response) => {
                        const filteredFranchises = response.filter(
                            (franchise) => !franchise.name.includes('CERRADO'),
                        );
                        setFranchises(filteredFranchises);
                    })
                    .catch((error) => {
                        console.error('Error fetching franchises:', error);
                    })
                    .finally(() => {
                        setObtainingFranchises(false);
                    });
            }
        }

        return () => {
            searchForm.resetFields();
            setErrorItem(null);
            setProductList([]);
            setCurrentPage(1);
            setBranches([]);
            setSelectedFranchises(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, searchForm]);

    const onChangeFranchises = async (id) => {
        if (!id || !Array.isArray(id) || id.length < 1) {
            setBranches([]);
            searchForm.setFieldsValue({ branches: [] });
            setSelectedFranchises(false);
            setObtainingBranches(false);
            return;
        }

        setSelectedFranchises(true);
        setObtainingBranches(true);

        try {
            const branches = await obtenerSucursales(id[0]);
            setBranches(branches);
        } catch (error) {
            console.error('Error fetching branches:', error);
        } finally {
            setObtainingBranches(false);
        }
    };

    const getIdList = (list) => {
        const uniqueIds = [...new Set(list.map((item) => item.split('|')[0]))];
        return uniqueIds.join(',');
    };

    const getProducts = (productName, franchises, branches, page) => {
        getProductsList(productName, franchises, branches, page)
            .then(() => {
                setLoader(false);
            })
            .catch((error) => {
                console.error(error);
                setLoader(false);
            });
    };

    const okHandler = () => {
        onSave(addedProducts);
    };

    const searchParams = (values) => {
        const productName = values?.productName || null;
        const selectedFranchises = values?.franchises
            ? values.franchises
            : getIdList(franchisesSelected);
        const selectedBranches = values?.branches
            ? values.branches
            : getIdList(branchesSelected);

        const franchisesToString = !selectedFranchises.length
            ? null
            : Array.isArray(selectedFranchises)
            ? selectedFranchises.join(',')
            : selectedFranchises;

        const branchesToString = !selectedBranches.length
            ? null
            : Array.isArray(selectedBranches)
            ? selectedBranches.join(',')
            : selectedBranches;

        return { productName, franchisesToString, branchesToString };
    };

    const onSearch = (values) => {
        setLoader(true);
        const page = 1;

        const { productName, franchisesToString, branchesToString } =
            searchParams(values);

        setCurrentPage(page);
        getProducts(productName, franchisesToString, branchesToString, page);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
        setLoader(true);

        const values = {
            productName: searchForm.getFieldValue('productName'),
            franchises: searchForm.getFieldValue('franchises'),
            branches: searchForm.getFieldValue('branches'),
        };

        const { productName, franchisesToString, branchesToString } =
            searchParams(values);

        getProducts(productName, franchisesToString, branchesToString, page);
    };

    const onAddProduct = (product) => {
        const isProductAdded = addedProducts.some(
            (item) =>
                item.franchise_name === product.franchise_name &&
                item.product_name === product.product_name,
        );
        if (isProductAdded) {
            setErrorItem(product);
            return;
        } else {
            const updatedProductsList = [...addedProducts, product];
            setAddedProducts(updatedProductsList);
            setSelectedProducts(updatedProductsList);
        }
    };

    const onRemoveProduct = (product) => {
        const updatedProductsList = addedProducts.filter(
            (item) =>
                item.franchise_name !== product.franchise_name ||
                item.product_name !== product.product_name,
        );
        setAddedProducts(updatedProductsList);
        setSelectedProducts(updatedProductsList);
    };

    const ProductsData = (item) => (
        <>
            <List.Item
                actions={[
                    <Button
                        key={item.product_name}
                        type="link"
                        onClick={() => {
                            onAddProduct(item);
                        }}>
                        Agregar
                    </Button>,
                ]}>
                <List.Item.Meta
                    title={<Text>{item.product_name}</Text>}
                    description={<Text>{item.franchise_name}</Text>}
                />
            </List.Item>
            {errorItem &&
                errorItem.product_name === item.product_name &&
                errorItem.franchise_name === item.franchise_name && (
                    <Alert
                        message="El producto ya fue agregado"
                        type="error"
                        showIcon
                    />
                )}
        </>
    );

    const ItemSelected = (item) => {
        return (
            <>
                <List.Item
                    actions={[
                        <Button
                            key={item.user_id}
                            type="link"
                            danger
                            onClick={() => onRemoveProduct(item)}>
                            Eliminar
                        </Button>,
                    ]}>
                    <List.Item.Meta
                        title={<Text>{item.product_name}</Text>}
                        description={<Text>{item.franchise_name}</Text>}
                    />
                </List.Item>
            </>
        );
    };

    return (
        <>
            <Modal
                title="Agregar productos"
                open={visible}
                onCancel={onClose}
                footer={null}
                width="65%"
                destroyOnClose
                centered>
                <ModalContent
                    isLoading={false}
                    cancelText="Cancelar"
                    onCancel={onClose}
                    okText="Guardar"
                    onOk={okHandler}>
                    <Form
                        form={searchForm}
                        onFinish={onSearch}
                        layout="vertical">
                        <Row align="bottom" gutter={[8, 8]}>
                            <Col span={6}>
                                <Form.Item
                                    label="Nombre del producto"
                                    name="productName">
                                    <Input placeholder="Escribí el nombre del producto" />
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Franquicia" name="franchises">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        mode="multiple"
                                        onChange={onChangeFranchises}
                                        disabled={
                                            obtainingFranchises ||
                                            obtainingBranches ||
                                            loader
                                        }
                                        loading={obtainingFranchises}
                                        placeholder="Seleccionar">
                                        {franchisesOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Form.Item label="Sucursal" name="branches">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        mode="multiple"
                                        disabled={
                                            !branches.length ||
                                            obtainingBranches ||
                                            loader
                                        }
                                        loading={obtainingBranches}
                                        placeholder="Seleccionar">
                                        {branchesOptions}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6}>
                                <Button
                                    disabled={
                                        !franchisesSelected?.length &&
                                        !selectedFranchises
                                    }
                                    htmlType="submit"
                                    className={styles.marginBottom}>
                                    Buscar
                                </Button>
                            </Col>
                        </Row>

                        {visible &&
                            !franchisesSelected.length &&
                            !selectedFranchises && (
                                <Alert
                                    message="Selecciona una franquicia para buscar productos"
                                    type="warning"
                                    showIcon
                                />
                            )}
                    </Form>
                    <List
                        loading={loader}
                        bordered
                        dataSource={productList}
                        renderItem={ProductsData}
                        pagination={{
                            position: 'bottom',
                            align: 'right',
                            total: productListTotal,
                            current: currentPage ? currentPage : 1,
                            pageSize: productListPageSize
                                ? productListPageSize
                                : 15,
                            onChange: onPageChange,
                            showSizeChanger: false,
                        }}
                        className={styles.productBox}
                    />
                    <List
                        bordered
                        dataSource={addedProducts}
                        renderItem={ItemSelected}
                        className={styles.addedProductBox}
                        pagination={{
                            position: 'bottom',
                            align: 'right',
                            showSizeChanger: false,
                            pageSize: 5,
                            hideOnSinglePage: true,
                        }}
                    />
                </ModalContent>
            </Modal>
        </>
    );
};

export default AddProductModal;

AddProductModal.propTypes = {
    visible: PropTypes.bool,
    onSave: PropTypes.func,
    onClose: PropTypes.func,
};
