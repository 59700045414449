import { Card, Col, Row } from 'antd';
import React from 'react';
import placeholder from '../../../../assets/svg/support/placeholder.svg';
import clases from './analiticas.module.css';
import PropTypes from 'prop-types';

const CardDirecciones = ({ datosDireccionesTop3 }) => {
    return (
        <Card className={clases.cardDirecciones}>
            <div className={clases.divImg}>
                <img src={placeholder} width="90rem" height="90rem" />
            </div>
            <div className={clases.topDirecciones}>
                {datosDireccionesTop3?.length &&
                    datosDireccionesTop3.map(
                        (item, index) =>
                            index <= 4 && (
                                <Row
                                    key={item?.key}
                                    className={clases.flexRowBetween}>
                                    <Col>
                                        <h5>{`${index + 1}. ${item?.name}`}</h5>
                                    </Col>
                                    <Col>
                                        <span>{`${item?.total} ${
                                            item?.total > 1
                                                ? 'pedidos'
                                                : 'pedido'
                                        }`}</span>
                                    </Col>
                                </Row>
                            ),
                    )}
            </div>
        </Card>
    );
};

export default CardDirecciones;

CardDirecciones.propTypes = {
    datosDireccionesTop3: PropTypes.array,
};
