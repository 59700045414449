import { useContext, useEffect, useState } from 'react';

import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';

const useGetAudience = () => {
    const { getAudience, audienceList } = useContext(PromotionsContext);

    const [audience, setAudience] = useState([]);

    useEffect(() => {
        if (!audienceList.length) {
            getAudience();
        }
        setAudience(audienceList);
    }, [audienceList]);

    return { audience };
};

export default useGetAudience;
