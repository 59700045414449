import React from 'react';

function No() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none">
            <path
                d="M16.7331 8.63914C16.7331 8.53172 16.6452 8.44383 16.5377 8.44383L14.9264 8.45116L12.4997 11.3442L10.0753 8.4536L8.46157 8.44627C8.35415 8.44627 8.26626 8.53172 8.26626 8.64159C8.26626 8.68797 8.28335 8.73192 8.31264 8.76854L11.4889 12.5527L8.31264 16.3345C8.28314 16.3702 8.26677 16.415 8.26626 16.4614C8.26626 16.5688 8.35415 16.6567 8.46157 16.6567L10.0753 16.6494L12.4997 13.7563L14.924 16.647L16.5353 16.6543C16.6427 16.6543 16.7306 16.5688 16.7306 16.459C16.7306 16.4126 16.7135 16.3686 16.6842 16.332L13.5128 12.5503L16.6891 8.7661C16.7184 8.73192 16.7331 8.68553 16.7331 8.63914Z"
                fill="#F11E4C"
            />
            <path
                d="M12.5 1.5625C6.45996 1.5625 1.5625 6.45996 1.5625 12.5C1.5625 18.54 6.45996 23.4375 12.5 23.4375C18.54 23.4375 23.4375 18.54 23.4375 12.5C23.4375 6.45996 18.54 1.5625 12.5 1.5625ZM12.5 21.582C7.48535 21.582 3.41797 17.5146 3.41797 12.5C3.41797 7.48535 7.48535 3.41797 12.5 3.41797C17.5146 3.41797 21.582 7.48535 21.582 12.5C21.582 17.5146 17.5146 21.582 12.5 21.582Z"
                fill="#F11E4C"
            />
        </svg>
    );
}

export default No;
