import { Form, Input, Button, message } from 'antd';
import React, { useEffect, useState } from 'react';
import classes from './RestablecerContrasena.module.css';
import clienteAxios from '../../../config/axios';
import { useHistory, useParams } from 'react-router-dom';
import { urls } from '../../../constants';
import decodeJWT from 'jwt-decode';
import AuthCard from '../../molecules/auth/authCard';

const RestablecerContrasena = () => {
    const params = useParams();
    let history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [token, setToken] = useState();
    const [restablecerCredencialesForm] = Form.useForm();

    const redirigirAlLogin = () => {
        localStorage.clear();
        history.replace('/');
    };

    useEffect(() => {
        let paramToken = params?.token;
        let tokenFormatoCorrecto = false;
        if (paramToken) {
            try {
                // eslint-disable-next-line
                const tokenDecodificado = decodeJWT(paramToken);
                tokenFormatoCorrecto = true;
            } catch (error) {
                // Continue regardless of the error
            }
        }
        if (!tokenFormatoCorrecto) {
            redirigirAlLogin();
        } else {
            setToken(paramToken);
        }
    }, [params]);

    const onRestablecerCredenciales = async (values) => {
        const repetirContrasena = values.repetirContrasena;
        const contrasena = values.contrasena;
        setIsLoading(true);

        if (token) {
            // enviar req al endpoint
            const data = {
                password: contrasena,
                repeat_password: repetirContrasena,
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_E,
            };
            try {
                const respuesta = await clienteAxios.post(
                    `/change_admin_user_password?token=${token}`,
                    data,
                );
                message.success(respuesta?.data?.message);
                setTimeout(() => {
                    setIsLoading(false);
                    redirigirAlLogin();
                }, 2000);
            } catch (error) {
                setIsLoading(false);
                message.error(
                    error.response?.data.message ||
                        'Hubo un problema al obtener restablecer su contraseña',
                );
            }
        } else {
            setIsLoading(false);
            message.error(
                'Nos faltan datos para procesar este pedido. Por favor ingrese de vuelta al enlace que le llegó por correo.',
            );
        }
    };

    const onRestablecerCredencialesFailed = () => {};

    return (
        <AuthCard loading={isLoading}>
            <div
                className={[classes.textCenter, classes.formContainer].join(
                    ' ',
                )}>
                <h2>
                    Restablecimiento de contraseña{' '}
                    <span className={classes.textRed}>*</span>
                </h2>
                <Form
                    name="restablecerCredencialesForm"
                    form={restablecerCredencialesForm}
                    layout="vertical"
                    onFinish={onRestablecerCredenciales}
                    onFinishFailed={onRestablecerCredencialesFailed}
                    autoComplete="off">
                    <Form.Item
                        label="Nueva contraseña"
                        name="contrasena"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor ingrese su contraseña',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item
                        label="Repetir nueva contraseña"
                        name="repetirContrasena"
                        rules={[
                            {
                                required: true,
                                message: 'Por favor repita su contraseña',
                            },
                        ]}>
                        <Input.Password />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            disabled={
                                restablecerCredencialesForm.contrasena !==
                                restablecerCredencialesForm.repetirContrasena
                            }>
                            Restablecer
                        </Button>
                    </Form.Item>
                </Form>
            </div>
            <div>
                <span className={classes.textRed}>* </span>
                <span>
                    Al restablecer su contraseña debe cerrar sesión e iniciar
                    nuevamente en sus aplicaciones de <b>Monchis Admin</b>,{' '}
                    <b>Monchis Support</b> y su App de{' '}
                    <b>Recepción de pedidos</b>.
                </span>
            </div>
        </AuthCard>
    );
};

export default RestablecerContrasena;
