import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Table,
    Button,
    Select,
    Form,
    Dropdown,
    Tag,
    Divider,
    Modal,
    Checkbox,
    Input,
    Tooltip,
    Typography,
    message,
} from 'antd';
import {
    MoreOutlined,
    InfoCircleOutlined,
    PlusOutlined,
    SendOutlined,
    FileDoneOutlined,
    CopyOutlined,
} from '@ant-design/icons';
import copy from 'copy-to-clipboard';
import { useModalesFinanzas } from '../../../../../hooks/useModalesFinanzas';
import EmisionDocumentosContext from '../../../../../../context/facturacion/emisionDocumentos/EmisionDocumentosContext';
import { formatoGuaranies } from '../../../../../utils-components/Number';
import classes from './ListaFacturas.module.css';
import externalLinkImg from '../../../../../../assets/images/externalLink.png';

import { invoice_states, urls } from '../../../../../../constants';
import { capitalizar } from '../../../../../utils-components/Capitalizar';
import ModalContent from '../../../../../atoms/ModalContent';
import FormItemSelectFranquicia from '../../../../../atoms/Form/Select/Franquicia';
import FormItemSelectMes from '../../../../../atoms/Form/Select/Mes';
import FormItemSelectAnho from '../../../../../atoms/Form/Select/Anho';

import { useHistory } from 'react-router-dom';
import clienteAxios from 'config/axios';

const { Option } = Select;
const { Text } = Typography;

const pageSize = 20;

const ListaFacturas = () => {
    const history = useHistory();
    let anho = new Date().getFullYear();
    let mess = new Date().getMonth() + 1;
    // states
    const [selectedRows, setSelectedRows] = useState([]);
    const [valores, setValores] = useState({
        date_start: '',
        date_end: '',
        franchise_ids: '',
        estado: 0,
    });
    const [isModalFacturarVisible, setIsModalFacturarVisible] = useState(false);
    const [isModalNotificarVisible, setIsModalNotificarVisible] =
        useState(false);

    const [seleccionarTodos, setSeleccionarTodos] = useState(false); // Se acuerda si haces clic en Select All
    const [seleccionPaginaTemp, setSeleccionPaginaTemp] = useState([]); // Guarda los id de la siguiente pagina
    const [facturasPendientes, setfacturasPendientes] = useState([]);
    const [facturasFacturadas, setfacturasFacturadas] = useState([]);
    const [rowSelectionConfig, setRowSelectionConfig] = useState({
        onSelectAll: (selected) => {
            seleccionarTodosHandler(selected);
        },
        type: 'checkbox',
        onChange: (_selectedRowKeys, selectedRows) => {
            cambiarSeleccionHandler(selectedRows);
        },
    }); // Config de la tabla
    const [facturasExcluidas, setFacturasExcluidas] = useState([]);
    const [mes, setMes] = useState('');
    const [anhoActual, setAnhoActual] = useState(anho);
    const [mesActual, setMesActual] = useState(mess);
    const [facturaForm] = Form.useForm();

    const [enviarNotificacionForm] = Form.useForm();

    // context
    const emisionDocumentosContext = useContext(EmisionDocumentosContext);

    const {
        obtenerListadoFacturas,
        listadoFacturas,
        seleccionarPaginadoTabla,
        paginaActualFactura,
        totalFacturas,
        loadingTablaFactura,
        cambiarEstadoFactura,
        loadingModalOk,
        enviarNotificacion,
        loadingNotificacion,
        descargarPdf,
    } = emisionDocumentosContext;
    // custom hook modales
    const { estadoButtonHandler } = useModalesFinanzas();

    const showModal = () => {
        setIsModalFacturarVisible(true);
    };

    useEffect(() => {
        if (selectedRows) {
            const facturasPendientesFiltradas = filtrarFacturasSeleccionadas(1);
            setfacturasPendientes(facturasPendientesFiltradas);
            const facturasFacturadasFiltradas = filtrarFacturasSeleccionadas(2);
            setfacturasFacturadas(facturasFacturadasFiltradas);
        }
    }, [selectedRows]);

    const filtrarFacturasSeleccionadas = (idEstadoFiltrar) => {
        const datosFilter = selectedRows.filter((factura) => {
            const { id, estado } = factura;
            return [estado === invoice_states[idEstadoFiltrar - 1].name, id];
        });
        return datosFilter;
    };

    const handleOk = () => {
        const estado = invoice_states[1].id; // estado facturado
        //se extraen todas las facturas con estados pendientes
        const datosFilter = filtrarFacturasSeleccionadas(1);
        // retornamos un array con los id en estado pendiente
        const datos = datosFilter.map((dato) => {
            const { id } = dato;
            return id;
        });
        cambiarEstadoFactura(
            datos,
            estado,
            seleccionarTodos,
            facturasExcluidas,
            notificar,
        );
    };

    useEffect(() => {
        if (!loadingModalOk) setIsModalFacturarVisible(loadingModalOk);
    }, [loadingModalOk]);

    const handleCancel = () => {
        setIsModalFacturarVisible(false);
    };

    useEffect(() => {
        const anhoParam = mesActual === 1 ? anhoActual - 1 : anhoActual;
        const mesParam =
            mesActual === 1 ? 12 : mesActual === 2 ? mesActual - 1 : mesActual;

        const primeraFechaConsultar = {
            ...calcularParametrosFechas(anhoParam, mesParam, false),
        };
        let nuevoStateValores = {
            ...valores,
            ...primeraFechaConsultar,
        };
        setValores((prevState) => ({
            ...prevState,
            ...primeraFechaConsultar,
        }));
        obtenerListadoFacturas(nuevoStateValores, paginaActualFactura);
    }, [paginaActualFactura]);

    const handleCopyInfo = (info) => {
        copy(info);
        message.success('Copiado!');
    };

    const copyButton = (data) => {
        return (
            <>
                <Tooltip title="Copiar">
                    {data}
                    <Button type="link" onClick={() => handleCopyInfo(data)}>
                        <CopyOutlined />
                    </Button>
                </Tooltip>
            </>
        );
    };

    const estado = (record) => {
        let estadoActual = '';
        if (record?.estado === 'pendiente') estadoActual = 'Facturar';
        if (record?.estado === 'facturado') estadoActual = 'Pagado';
        return estadoActual;
    };

    const goTodownloadItem = (data) => {
        return (
            <Link
                to={{
                    pathname: 'https://monchis.kigafe.com/consultacomprobante',
                }}
                target="_blank"
                disabled={
                    !data?.factura?.invoice?.id ||
                    !data?.factura?.invoice?.secret
                }>
                Ir a descargar
                <img
                    className={classes.externalLinkImg}
                    src={externalLinkImg}
                />
            </Link>
        );
    };

    const actionsColumn = (data) => {
        return (
            <>
                {data.estado !== 'anulado' && (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    label: goTodownloadItem(data),
                                    key: 'irADescargar',
                                },
                                {
                                    label: 'Descargar PDF',
                                    key: 'descargarPDF',
                                },
                                data?.estado === invoice_states[1].name && {
                                    label: 'Enviar notificación',
                                    key: 'enviarNotificacion',
                                },
                                {
                                    label: `Cambiar a ${estado(data)}`,
                                    key: 'cambiarDeEstado',
                                },
                                {
                                    label: 'Anular',
                                    key: 'anular',
                                },
                            ],
                            onClick: ({ key }) => {
                                const { id, estado, factura } = data;

                                switch (key) {
                                    case 'descargarPDF':
                                        descargarPdf(
                                            1,
                                            id,
                                            factura?.invoice?.secret,
                                        );
                                        break;
                                    case 'enviarNotificacion':
                                        enviarNotificacionModal([id]);
                                        break;
                                    case 'cambiarDeEstado':
                                        estadoButtonHandler(id, estado);
                                        break;
                                    case 'anular':
                                        estadoButtonHandler(
                                            id,
                                            invoice_states[3].id,
                                        );
                                        break;
                                    default:
                                        break;
                                }
                            },
                        }}>
                        <Button icon={<MoreOutlined />} />
                    </Dropdown>
                )}
            </>
        );
    };

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Razón social',
            dataIndex: 'legalName',
            key: 'legalName',
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },
        {
            title: 'Impresiones',
            dataIndex: 'impresiones',
            key: 'impresiones',
            align: 'center',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (_, record) => (
                <div style={{ textTransform: 'uppercase' }}>
                    <Tag
                        color={
                            record.estado === 'pendiente'
                                ? 'orange'
                                : record.estado === 'facturado'
                                ? 'cyan'
                                : record.estado === 'pagado'
                                ? 'green'
                                : 'volcano'
                        }>
                        {record.estado}
                    </Tag>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text) => formatoGuaranies(+text),
        },
        {
            title: 'Timbrado',
            dataIndex: 'timbrado',
            key: 'timbrado',
            render: (record) => copyButton(record),
        },
        {
            title: 'Nro. comprobante',
            dataIndex: 'invoice_number',
            key: 'invoice_number',
            width: 180,
            render: (record) => copyButton(record),
        },
        {
            title: 'Acciones',
            key: 'action',
            render: (_text, record) => actionsColumn(record),
        },
    ];

    // Select All - Handler para la seleccion de todos los registros
    const seleccionarTodosHandler = (selected) => {
        setSeleccionarTodos(selected);
    };

    const cambiarSeleccionHandler = (selectedRows) => {
        setSelectedRows(selectedRows);
    };

    // Select All - Al cambiar de pagina, cargar los siguientes IDs para mantenerlos seleccionados
    useEffect(() => {
        if (seleccionarTodos) {
            setSeleccionPaginaTemp(
                listadoFacturas
                    .map((factura) => factura.invoice.id)
                    .filter((id) => !facturasExcluidas?.includes(id)),
            );
        } else {
            setSeleccionPaginaTemp([]);
        }
    }, [listadoFacturas]);

    // Select All - Actualizar la configuracion de la tabla al seleccionar todos los elementos
    useEffect(() => {
        if (seleccionPaginaTemp.length > 0) {
            if (seleccionarTodos) {
                setRowSelectionConfig((state) => ({
                    ...state,
                    selectedRowKeys: seleccionPaginaTemp,
                }));
            }
        }
    }, [seleccionPaginaTemp]);

    // Select All - Guarda los ids destildados
    useEffect(() => {
        if (seleccionarTodos) {
            setRowSelectionConfig((state) => ({
                ...state,
                onSelect: (record, selected) => {
                    if (selected) {
                        setFacturasExcluidas((state) =>
                            state.filter((id) => id !== record?.id),
                        );
                        setSeleccionPaginaTemp((state) => [
                            ...state,
                            record?.id,
                        ]);
                    } else {
                        setFacturasExcluidas((state) => [...state, record?.id]);
                        setSeleccionPaginaTemp((state) =>
                            state.filter((id) => id !== record?.id),
                        );
                    }
                },
            }));
        } else {
            setRowSelectionConfig((state) => {
                let nuevoState = { ...state };
                try {
                    delete nuevoState.selectedRowKeys;
                    delete nuevoState.onSelect;
                } catch (error) {
                    // continuar independientemente del error
                }
                return { ...nuevoState };
            });
            setSeleccionarTodos(false);
            setFacturasExcluidas([]);
            setSeleccionPaginaTemp([]);
        }
    }, [seleccionarTodos]);

    const changePage = (page) => {
        seleccionarPaginadoTabla(page);
    };

    const buscarHandle = (valores) => {
        const {
            mes,
            franquiciaId,
            anho,
            estado,
            invoice_number: invoiceNumber,
        } = valores;
        const data = {
            franchise_ids: franquiciaId !== '' ? franquiciaId : null,
            state: estado !== '' ? estado : null,
            invoice_number: invoiceNumber ? invoiceNumber : null,
            ...calcularParametrosFechas(anho, mes, true),
        };
        obtenerListadoFacturas(data, 1);
        setValores(data);
        seleccionarPaginadoTabla(1);
        setSelectedRows([]);
        setSeleccionPaginaTemp([]);
        setFacturasExcluidas([]);
        setSeleccionarTodos(false);
    };

    const calcularParametrosFechas = (anho, mes, isBuscar) => {
        setAnhoActual(anho);
        setMesActual(mes);
        const mesActual = Number(mes) || 0;
        let mesSgte = mesActual + 1;
        let anhoSgte = Number(anho) || 0;
        if (mesSgte > 12) {
            mesSgte = 1;
            anhoSgte = anhoSgte + 1;
        }
        if (isBuscar === false) {
            facturaForm.setFieldsValue({
                anho: anho,
                mes: `${Number(mes) < 10 ? '0' : ''}${Number(mes)}`,
            });
        }

        return {
            date_start: mes ? `${anho}-${mes}-01` : null,
            date_end: anhoSgte ? `${anhoSgte}-${mesSgte}-01` : null,
        };
    };

    //datos para la tabla
    const emisionFacturaTabla = listadoFacturas.map((factura) => {
        const isACustomer = Boolean(factura?.customer);

        const name = isACustomer
            ? factura?.customer?.name
            : factura?.franchise_society?.name;
        const legalName = isACustomer
            ? factura?.customer?.legal_name
            : factura?.franchise_society?.legal_name;
        const ruc = isACustomer
            ? factura?.customer?.document_number
            : factura?.franchise_society?.ruc;

        // desestructuracion de objetos
        const {
            invoice: { id, viewed_count, amount, invoice_number },
            invoice_state,
            stamped,
        } = factura;

        return {
            id,
            invoice_number,
            name,
            legalName,
            ruc,
            impresiones: viewed_count,
            estado: invoice_state.name,
            total: amount,
            factura: factura,
            timbrado: stamped?.stamped,
        };
    });

    const datosMes = (valor) => {
        setMes(valor);
    };

    const generarIntervaloInicioFin = (mes, anho) => {
        // El mes llega con base 1 (uno)
        const mesActual = +mes;
        const anhoActual = +anho;

        // El mes siguiente debe volver a enero (01) si se selecciona como mes actual diciembre (12).
        // Tambien, considerar los casos en los que el mes debe tener un cero (0) de prefijo.
        const mesSgte =
            mesActual === 12
                ? '01'
                : mesActual + 1 >= 10
                ? `${mesActual + 1}`
                : `0${mesActual + 1}`;
        const anhoSgte = mesActual === 12 ? `${anhoActual + 1}` : anho;

        let dateStart = `${anho}-${mes}-01`;
        let dateEnd = `${anhoSgte}-${mesSgte}-01`;

        return { dateStart, dateEnd };
    };

    const descargarExcel = (mes, anho) => {
        const { dateStart, dateEnd } = generarIntervaloInicioFin(mes, anho);
        window.open(
            `${urls.REACT_APP_BACKEND_URL_D}/excel_invoice?date_start=${dateStart}&date_end=${dateEnd}`,
        );
    };

    const exportarPdf = async (mes, anho) => {
        const { dateStart, dateEnd } = generarIntervaloInicioFin(mes, anho);
        window.open(
            `${urls.REACT_APP_BACKEND_URL_D}/invoice_pdf?date_start=${dateStart}&date_end=${dateEnd}`,
        );
    };

    const descargarExcelV2 = async (mes, anho, finalizer) => {
        try {
            const { dateStart, dateEnd } = generarIntervaloInicioFin(mes, anho);
            const response = await clienteAxios.get(
                `${urls.REACT_APP_BACKEND_URL_D}/excel_invoice_V2?date_start=${dateStart}&date_end=${dateEnd}`,
            );
            window.open(response.data?.data?.link);
        } finally {
            finalizer();
        }
    };

    const enviarNotificacionModal = (arrIdFactura) => {
        setIsModalNotificarVisible(true);
        enviarNotificacionForm.setFieldsValue({
            arrayId: arrIdFactura,
            tipoNotificacion: ['SMS', 'Correo'],
        });
    };

    const enviarNotificacionButtonHandler = async () => {
        enviarNotificacionForm
            .validateFields()
            .then(async (values) => {
                const { arrayId, tipoNotificacion } = values;
                const correo = Boolean(
                    tipoNotificacion.filter((item) => item === 'Correo').length,
                );
                const sms = Boolean(
                    tipoNotificacion.filter((item) => item === 'SMS').length,
                );
                const facturasExcluidasString = facturasExcluidas.join(',');
                await enviarNotificacion(
                    arrayId,
                    correo,
                    sms,
                    seleccionarTodos,
                    facturasExcluidasString,
                );
                setIsModalNotificarVisible(false);
            })
            .catch(() => {});
    };

    const cancelarEnviarNotificacionButtonHandler = () => {
        setIsModalNotificarVisible(false);
    };

    const goNuevaFactura = () => {
        history.push('/finanzas/facturacion/emision-documentos/nueva-factura');
    };

    const filtrarFacturasConEstadoPendiente = selectedRows.filter((item) => {
        return item?.factura?.invoice_state?.name === 'pendiente';
    });

    const [notificar, setNotificar] = useState(false);
    const idEstadoFacturado = 2;
    const itemFacturado = selectedRows.filter((item) => {
        return item.factura.invoice_state.id === idEstadoFacturado;
    });
    const existeFacturaSeleccionada = Boolean(itemFacturado.length);
    const habilitarBotonEnviarNotificacion =
        seleccionarTodos || existeFacturaSeleccionada;

    const [loadingExcelV2, setLoadingExcelV2] = useState(false);

    return (
        <>
            <div className={classes.menuAcciones}>
                <Button
                    type="link"
                    onClick={showModal}
                    disabled={
                        !(
                            facturasPendientes.length > 0 ||
                            facturasExcluidas.length > 0
                        )
                    }>
                    <FileDoneOutlined /> Facturar
                </Button>

                <Button type="link" onClick={goNuevaFactura}>
                    <PlusOutlined /> Nueva factura
                </Button>

                <Button
                    disabled={!habilitarBotonEnviarNotificacion}
                    type="link"
                    onClick={() =>
                        enviarNotificacionModal(
                            facturasFacturadas.map((item) => item.id),
                        )
                    }>
                    <SendOutlined /> Enviar notificación
                </Button>

                <Button
                    disabled={
                        !(
                            facturaForm.getFieldValue('mes') &&
                            facturaForm.getFieldValue('anho')
                        )
                    }
                    type="link"
                    onClick={() =>
                        descargarExcel(
                            facturaForm.getFieldValue('mes'),
                            facturaForm.getFieldValue('anho'),
                        )
                    }>
                    Exportar Excel
                </Button>

                <Button
                    disabled={
                        !(
                            facturaForm.getFieldValue('mes') &&
                            facturaForm.getFieldValue('anho')
                        )
                    }
                    type="link"
                    onClick={() =>
                        exportarPdf(
                            facturaForm.getFieldValue('mes'),
                            facturaForm.getFieldValue('anho'),
                        )
                    }>
                    Exportar PDF
                </Button>
                <Button
                    disabled={
                        !(
                            facturaForm.getFieldValue('mes') &&
                            facturaForm.getFieldValue('anho')
                        )
                    }
                    loading={loadingExcelV2}
                    type="primary"
                    onClick={() => {
                        setLoadingExcelV2(true);
                        descargarExcelV2(
                            facturaForm.getFieldValue('mes'),
                            facturaForm.getFieldValue('anho'),
                            () => setLoadingExcelV2(false),
                        );
                    }}>
                    Descargar Excel
                </Button>
            </div>
            <Divider style={{ marginTop: 4 }} />
            <Row gutter={12}>
                <Col span={20}>
                    {/* FILTROS FORM */}
                    <Form
                        name="filtros"
                        layout="vertical"
                        form={facturaForm}
                        onFinish={buscarHandle}>
                        <Row gutter={12} style={{ alignItems: 'center' }}>
                            <Col span={5}>
                                <Form.Item
                                    name="invoice_number"
                                    label="Nro. Factura">
                                    <Input
                                        placeholder="Ingrese el Nro. de Factura"
                                        prefix={
                                            <Tooltip title="001-001-0001234">
                                                <InfoCircleOutlined
                                                    style={{
                                                        color: 'rgba(0,0,0,.45)',
                                                    }}
                                                />
                                            </Tooltip>
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <FormItemSelectFranquicia
                                    formInstance={facturaForm}
                                />
                            </Col>
                            <Col span={4}>
                                <FormItemSelectMes onChange={datosMes} />
                            </Col>
                            <Col span={4}>
                                <FormItemSelectAnho
                                    disabledTodos={Boolean(Number(mes) !== 0)}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item name="estado" label="Estado">
                                    <Select
                                        disabled={loadingTablaFactura}
                                        defaultValue="">
                                        <Option value="">Todos</Option>
                                        {invoice_states.map((el) => (
                                            <Option value={el.id} key={el.id}>
                                                {capitalizar(el.name)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={2}>
                                <Form.Item style={{ marginBottom: '0' }}>
                                    <Button
                                        disabled={loadingTablaFactura}
                                        type="primary"
                                        htmlType="submit">
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Divider style={{ marginTop: 4 }} />
            <Row gutter={12}>
                <Col span={24}>
                    <Text>
                        Ha seleccionado{' '}
                        {seleccionarTodos
                            ? Number(totalFacturas) - facturasExcluidas.length
                            : selectedRows.length}{' '}
                        registros
                    </Text>
                    <Table
                        size="small"
                        loading={loadingTablaFactura}
                        columns={columns}
                        dataSource={emisionFacturaTabla}
                        rowKey="id"
                        rowSelection={rowSelectionConfig}
                        pagination={{
                            pageSize: pageSize,
                            total: Number(totalFacturas),
                            current: paginaActualFactura,
                            showSizeChanger: false,
                            onChange: (page) => changePage(page),
                        }}
                        style={{ marginTop: '1rem' }}
                    />
                </Col>
            </Row>

            <Modal
                open={isModalFacturarVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Facturar"
                confirmLoading={loadingModalOk}>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '35px' }}>
                        <InfoCircleOutlined
                            style={{ color: 'orange', fontSize: '22px' }}
                        />
                    </div>
                    <div>
                        <strong>
                            Estás seguro/a que quieres facturar las franquicias
                            seleccionadas?
                        </strong>
                        <p>Esta acción hara lo siguiente:</p>
                        <ul>
                            <li>Cambiara el estado de Pendiente a Facturado</li>
                        </ul>
                        <div>
                            <p>Enviar una notificación vía SMS y correo</p>
                            <Select
                                disabled={
                                    filtrarFacturasConEstadoPendiente && false
                                }
                                style={{ width: '50%' }}
                                defaultValue={false}
                                onChange={(value) => setNotificar(value)}>
                                <Select.Option value={false}>No</Select.Option>
                                <Select.Option value={true}>Si</Select.Option>
                            </Select>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={isModalNotificarVisible} footer={null} closable={null}>
                <ModalContent
                    title={`Esta por enviar una notificación de ${
                        enviarNotificacionForm.getFieldValue('tipoNotificacion')
                            ?.length <= 1
                            ? 'esta factura'
                            : 'estas facturas'
                    }`}
                    okText="Enviar"
                    cancelText="Cancelar"
                    onOk={enviarNotificacionButtonHandler}
                    onCancel={cancelarEnviarNotificacionButtonHandler}
                    isLoading={loadingNotificacion}
                    showWarnIcon>
                    <Form
                        form={enviarNotificacionForm}
                        layout="vertical"
                        validateMessages={{
                            required: 'Este campo es requerido.',
                        }}>
                        <Form.Item
                            name="tipoNotificacion"
                            label="Enviar vía:"
                            rules={[{ required: true }]}>
                            <Checkbox.Group options={['SMS', 'Correo']} />
                        </Form.Item>
                        <Form.Item name="arrayId" style={{ display: 'none' }}>
                            <Input type="hidden" />
                        </Form.Item>
                    </Form>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ListaFacturas;
