import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import {
    Form,
    Row,
    Col,
    Divider,
    Select as SelectAntd,
    message,
    Modal,
    Spin,
    InputNumber,
    Input,
    DatePicker,
} from 'antd';
import { Text, View } from '@react-pdf/renderer';

import classesBalance from '../../../Balance/Balance.module.css';
import CustomSVG from '../../../../../assets/svg/support/CustomSVG';
import classes from './ListaReporteBalance.module.css';
import { styles } from '../../../../reportes/ResumenCortePDF';

import axios from '../../../../../config/axios';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../../utils-components/Number';
import { formatoFechaInput } from '../../../../utils-components/Date';
import { validateMessages } from '../../../../../constants/FormOptions';
import { Permisos } from '../../../../../constants/Permisos';

import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import { useVerificarPermiso } from '../../../../hooks/useVerificarPermiso';
import Select from '../../../../atoms/Select';
import Card from '../../../../atoms/Card/Card';
import Button from '../../../../atoms/Button';
import { rangePickerAntd as RangePicker } from '../../../../atoms/DatePicker';
import BalanceTable from '../../../../molecules/Finanzas/Facturacion/Balance/TablaBalance';

import {
    columnasBalance,
    columnasResumenMonchisDrivers,
    columnasResumenMonchisMarketplace,
    columnasResumenImportes,
    requestHistoryColumns,
} from '../../../Balance/BalanceColumnasTabla';
import FormItemSelectFamilia from '../../../../atoms/Form/Select/Familias';
import FormItemSelectFranquicia from '../../../../atoms/Form/Select/Franquicia';
import FormItemSelectTipoPago from '../../../../atoms/Form/Select/TipoPago';
import ABMLayout from '../../../../../layouts/ABMLayout';

const totalRegistrosPorPaginaBalance = 5;

const paymentTypes = {
    cash: 'Efectivo',
    check: 'Cheque',
    transfer: 'Transferencia',
};

const ListaReporteBalance = () => {
    const history = useHistory();
    // State para consultas al Backend
    const [paramsBalance, setParamsBalance] = useState();
    const [balanceData, setBalanceData] = useState();
    const [resumenCortePDF, setResumenCortePDF] = useState(null);
    const [reporteBalanceForm] = Form.useForm();

    const [cantidadItemsBalance, setCantidadItemsBalance] = useState();

    const [franquiciasData, setFranchicesData] = useState();

    // Formulario
    const [balanceForm, setBalanceForm] = useState({});

    // Formulario - Primera vez que carga la pantalla
    useEffect(() => {
        let tempValoresInicialesForm = {
            fanquiciaId: '',
            date: [
                moment(moment().format(formatoFechaInput), formatoFechaInput),
                moment(moment().format(formatoFechaInput), formatoFechaInput),
            ],
        };

        // Cuando se ingresa desde otra pantalla
        let stateTemp = {};
        try {
            if (history.location.state?.ruc_or_franchise) {
                // Establecer parametros de busqueda
                stateTemp = { ...history.location.state };
            }
        } catch (error) {
            // continue regardless of error
        }

        // Cuando se regresa desde otra pantalla
        try {
            if (window.history.state?.paramsBalance) {
                // Establecer parametros de busqueda
                stateTemp = window.history.state.paramsBalance;
            }
        } catch (error) {
            // continue regardless of error
        }

        if (stateTemp?.ruc_or_franchise) {
            setParamsBalance({
                ruc_or_franchise: stateTemp?.ruc_or_franchise,
                date_start: stateTemp?.date_start,
                date_end: stateTemp?.date_end,
                payment_type: stateTemp?.payment_type,
                family: stateTemp?.family,
            });

            if (stateTemp?.resumenCortePDF)
                setResumenCortePDF({ ...stateTemp?.resumenCortePDF });

            // Establecer valores iniciales del form
            tempValoresInicialesForm = {
                date:
                    stateTemp?.date_start && stateTemp?.date_end
                        ? [
                              moment(
                                  moment(stateTemp.date_start, 'YYYY-MM-DD'),
                                  formatoFechaInput,
                              ),
                              moment(
                                  moment(stateTemp.date_end, 'YYYY-MM-DD'),
                                  formatoFechaInput,
                              ),
                          ]
                        : tempValoresInicialesForm?.date,
            };
        }

        reporteBalanceForm.setFieldsValue(tempValoresInicialesForm);
    }, []);

    const formSelectChangeHandler = (e) => {
        setBalanceForm({
            ...balanceForm,
            [e.target.name]: e.target.value,
        });
    };

    // Tablas de datos
    // Tablas de datos -- Balance -- Datos formateados
    const [obteniendoDatos, setObteniendoDatos] = useState(false);
    const [tablaBalanceData, setTablaBalanceData] = useState();

    // Tarjetas
    // Tarjetas -- datos para las tarjetas en la cabecera
    const [tarjetasCabecera, setTarjetasCabecera] = useState({
        cantidadVentas: 0,
        totalVentas: formatoGuaranies(0),
        cantidadDrivers: 0,
        totalDrivers: formatoGuaranies(0),
        totalPagoOnline: formatoGuaranies(0),
        totalEfectivo: formatoGuaranies(0),
        cantidadLocalesAcreditar: 0,
        totalLocalesAcreditar: formatoGuaranies(0),
        cantidadLocalesMorosos: 0,
        totalLocalesMorosos: formatoGuaranies(0),
    });
    const [dataTarjetas, setDataTarjetas] = useState();

    // boton ver resumen
    const verBalanceButtonHandler = (values) => {
        setCantidadItemsBalance(null);

        let tempParamsBalance = { page: 1 };

        if (values?.franquiciaId)
            tempParamsBalance['ruc_or_franchise'] = values.franquiciaId;
        if (values?.date.length === 2)
            tempParamsBalance['date_start'] =
                values.date[0].format('YYYY-MM-DD');
        if (values?.date.length === 2)
            tempParamsBalance['date_end'] = values.date[1].format('YYYY-MM-DD');
        if (values?.payment_type)
            tempParamsBalance['payment_type'] = values.payment_type;
        if (values?.familiaId) tempParamsBalance['family'] = values.familiaId;
        setParamsBalance(tempParamsBalance);
    };

    const obtenerDatosEndpoint = async () => {
        setObteniendoDatos(true);
        setTablaBalanceData(null);

        try {
            //
            const paramsBalanceTemp = {
                ...paramsBalance,
                allow_payments: undefined,
            };
            // obtener datos
            const respuestaBalance = await axios.get('/get_finances', {
                params: paramsBalanceTemp,
            });

            setFranchicesData(respuestaBalance.data.data.all_franchises_id);

            // actualizar el numero total de paginas
            if (!cantidadItemsBalance) {
                setCantidadItemsBalance(
                    respuestaBalance.data.data.total_franchises,
                );
            }
            // datos para las tarjetas
            setDataTarjetas({
                ...respuestaBalance.data.data,
                finances: null,
            });

            // extrer detalles del balance
            const respuestaBalanceArr = respuestaBalance.data.data.finances;
            let franchiseIdArr = [];
            respuestaBalanceArr.forEach((element) => {
                franchiseIdArr.push(element.franchise_society_id);
            });
            // guardar detalles
            let balanceArr = [];
            respuestaBalanceArr.forEach((objectoBalance) => {
                balanceArr.push({ ...objectoBalance });
            });
            setBalanceData(balanceArr);
        } catch (error) {
            message.error(error.response.data.message);
        } finally {
            setObteniendoDatos(false);
        }
    };

    // GoBackRefresh - Refrescar datos al regresar
    // GoBackRefresh - State
    const [refrescarDatos, setRefrescarDatos] = useState(false);

    // GoBackRefresh - Handler
    const refrescarDatosHandler = () => {
        setRefrescarDatos(true);
    };

    // Obtener datos del Backend
    useEffect(() => {
        if (paramsBalance) {
            obtenerDatosEndpoint();
            refrescarDatos && setRefrescarDatos(false);
        }
    }, [paramsBalance, refrescarDatos]);

    // Botón de "Detalle Orden"
    const detalleOrdenHandler = (rucFranchise) => {
        // Guardar parametros de "balance" en la pantalla actual
        window.history.pushState(
            { paramsBalance: { ...paramsBalance } },
            'paramsBalance',
            '/reportes/ventas/reporte-balance',
        );

        // Preparar parametros para la siguiente pantalla
        const paramsDetalle = {
            dateStart: paramsBalance.date_start,
            dateEnd: paramsBalance.date_end,
            franchiseId: rucFranchise,
        };

        // Navegar a la siguiente pantalla con los parametros necesarios
        historyHook.push('/reportes/ventas/detalle-transaccion/lista', {
            ...paramsDetalle,
        });
    };

    const refrescarDatosTarjetaCabecera = () => {
        let cantidadVentas = dataTarjetas?.total_orders || 0;
        let totalVentas = dataTarjetas?.amount_total_orders || 0;

        let cantidadDrivers = dataTarjetas?.quantity_delivery_with_monchis || 0;
        let totalDrivers = dataTarjetas?.amount_delivery_with_monchis || 0;

        let totalPagoOnline = dataTarjetas?.amount_online || 0;
        let totalEfectivo = dataTarjetas?.amount_cash || 0;

        setTarjetasCabecera({
            cantidadVentas: formatoNumeros(cantidadVentas),
            totalVentas: formatoGuaranies(totalVentas),
            cantidadDrivers: formatoNumeros(cantidadDrivers),
            totalDrivers: formatoGuaranies(totalDrivers),
            totalPagoOnline: formatoGuaranies(totalPagoOnline),
            totalEfectivo: formatoGuaranies(totalEfectivo),
        });
    };

    const verDetalle = () => {
        setObteniendoDatos(true);

        const token = JSON.parse(localStorage.getItem('token'));
        const nroCorte = resumenCortePDF.nroCorte;

        axios
            .get(
                `/get_finance_token?getBalanceHTML=true&balanceId=${nroCorte}&token=${token}`,
            )
            .then((respuesta) => {
                const balanceHTML = respuesta?.data?.data?.balanceHTML;
                window.open().document.write(balanceHTML);
            })
            .catch((error) =>
                message.error(`Ha ocurrido el siguiente error: ${error}`),
            )
            .finally(() => {
                setObteniendoDatos(false);
            });
    };

    const refrescarDatosTabla = () => {
        // Agreagar formato para mostrar en la tabla
        let tablaBalanceConFormato = balanceData.map((item) => {
            // Tabla Balance
            let key = Math.random();
            const franquiciaId = item?.franchise_society_id;
            let ruc = item.ruc;
            let razonSocial = item.legal_name;
            let franquicia = item.name;
            let cantidadTotalOrdenes = Number(item.order_quantity) || 0;

            let cantidadTotalOrdenesDrivers = 0; // Cantidad de Ordenes realizadas con Monchis Drivers
            let totalesMonchisDrivers = Number(
                item.amount_delivery_with_monchis,
            ); // Total final de ingresos por delivery/drivers

            let importeOrden = 0; // Balance - Importe Orden
            let subtotalComision = 0; // Balance - Subtotal Comision
            let comisionACobrar = item.total_commission_gain; //

            // Tabla resumen monchis drivers
            let monchisDriversArr = item.driver_prices;
            let tablaResumenMonchisDriversConFormato = [];
            let monchisDriversArrPDF = undefined;

            // Tabla monchis marketplace
            let monchisMarketplaceArr = item.commissions;
            let tablaResumenMonchisMarketplaceConFormato = [];
            let importeTotalMonchisMarketplace = 0;
            let monchisMarketplaceArrPDF = undefined;

            // Tabla resumen importes
            const tablaResumen = item.summary_table;

            // Botones
            let tieneMonchisDrivers = Boolean(item?.has_monchis_drivers);

            // Tabla Monchis Drivers - Se recorre el objeto "driver_prices" si tiene datos
            if (monchisDriversArr.length > 0) {
                // Monchis Drivers - Preparar la tabla con formatos
                tablaResumenMonchisDriversConFormato = monchisDriversArr.map(
                    (itemDriver, indexDriver) => {
                        let driverCantidad = Number(itemDriver.quantity) || 0; // cantidad total de deliveries
                        let driverPrecios = Number(itemDriver.price) || 0; // precio del delivery
                        let driverTotal = Number(itemDriver.total) || 0; // monto total por delivery
                        // Monchis Drivers - Cantidad de Ordenes realizadas con Monchis Drivers se acumula y agrega fuera del map
                        cantidadTotalOrdenesDrivers += driverCantidad;

                        monchisDriversArrPDF = (
                            <>
                                {monchisDriversArrPDF}
                                <View style={styles.detalleCorte}>
                                    <Text
                                        style={{
                                            ...styles.small,
                                            ...styles.bold,
                                        }}>
                                        {driverCantidad} x{' '}
                                        {formatoGuaranies(driverPrecios)}
                                    </Text>
                                    <Text style={styles.small}>
                                        {formatoGuaranies(driverTotal)}
                                    </Text>
                                </View>
                            </>
                        );

                        // Monchis Drivers - Retorno de tabla con formato
                        return {
                            key: indexDriver,
                            quantity_price: `${driverCantidad} x ${formatoGuaranies(
                                driverPrecios,
                            )}`,
                            total: formatoGuaranies(driverTotal),
                        };
                    },
                );

                monchisDriversArrPDF = (
                    <>
                        {monchisDriversArrPDF}
                        <View
                            style={{
                                width: '100%',
                                borderBottom: '1 solid #000',
                                marginTop: 5,
                                marginBottom: 10,
                            }}
                        />
                        <View style={styles.detalleCorte}>
                            <Text style={{ ...styles.small, ...styles.bold }}>
                                Total {cantidadTotalOrdenesDrivers}
                            </Text>
                            <Text style={styles.small}>
                                {formatoGuaranies(totalesMonchisDrivers)}
                            </Text>
                        </View>
                    </>
                );

                // Monchis Drivers - Se agrega datos de columna Totales
                tablaResumenMonchisDriversConFormato.push({
                    key: tablaResumenMonchisDriversConFormato.length,
                    quantity_price: (
                        <strong>Total {cantidadTotalOrdenesDrivers}</strong>
                    ),
                    total: (
                        <strong>
                            {formatoGuaranies(totalesMonchisDrivers)}
                        </strong>
                    ),
                });
            } else {
                // Monchis Drivers - Dummy placeholder con datos en cero
                monchisDriversArrPDF = (
                    <>
                        <View style={styles.detalleCorte}>
                            <Text style={{ ...styles.small, ...styles.bold }}>
                                0 x {formatoGuaranies(0)}
                            </Text>
                            <Text style={styles.small}>
                                {formatoGuaranies(0)}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: '100%',
                                borderBottom: '1 solid #000',
                                marginTop: 5,
                                marginBottom: 10,
                            }}
                        />
                        <View style={styles.detalleCorte}>
                            <Text style={{ ...styles.small, ...styles.bold }}>
                                Total 0
                            </Text>
                            <Text style={styles.small}>
                                {formatoGuaranies(0)}
                            </Text>
                        </View>
                    </>
                );
                tablaResumenMonchisDriversConFormato = [
                    {
                        key: 0,
                        quantity_price: `0 x ${formatoGuaranies(0)}`,
                        total: formatoGuaranies(0),
                    },
                    {
                        key: 1,
                        quantity_price: <strong>Total 0</strong>,
                        total: <strong>{formatoGuaranies(0)}</strong>,
                    },
                ];
            }

            // Tabla Monchis Marketplace - Se recorre el objeto "commissions" si tiene datos
            if (monchisMarketplaceArr.length > 0) {
                // Monchis Marketplace - Preparar la tabla con formatos
                tablaResumenMonchisMarketplaceConFormato =
                    monchisMarketplaceArr.map((itemMkt, indexMkt) => {
                        let ordenesCantidad = Number(itemMkt.orders) || 0;
                        let totalOrden = Number(itemMkt.total_orders) || 0;
                        let ordenesComision =
                            Number(itemMkt.commission_percent) || 0;
                        let ordenesImporte =
                            Number(itemMkt.total_commission) || 0;

                        // Monchis Marketplace - Columna Totales se acumula y agrega fuera del map
                        importeTotalMonchisMarketplace += ordenesImporte;

                        // Balance - Sumatoria de importes para la columna Importe Orden
                        importeOrden += totalOrden;

                        monchisMarketplaceArrPDF = (
                            <>
                                {monchisMarketplaceArrPDF}
                                <View style={styles.detalleCorte}>
                                    <Text
                                        style={{
                                            ...styles.small,
                                            ...styles.bold,
                                        }}>
                                        {ordenesCantidad} órdenes al{' '}
                                        {ordenesComision}%
                                    </Text>
                                    <Text style={styles.small}>
                                        {formatoGuaranies(ordenesImporte)}
                                    </Text>
                                </View>
                            </>
                        );

                        // Monchis Marketplace - Retorno de datos formateados
                        return {
                            key: indexMkt,
                            comisionesPorcentaje: `${ordenesCantidad} órdenes al ${ordenesComision}%`,
                            totalMontoComisiones:
                                formatoGuaranies(ordenesImporte),
                        };
                    });

                monchisMarketplaceArrPDF = (
                    <>
                        {monchisMarketplaceArrPDF}
                        <View
                            style={{
                                width: '100%',
                                borderBottom: '1 solid #000',
                                marginTop: 5,
                                marginBottom: 10,
                            }}
                        />
                        <View style={styles.detalleCorte}>
                            <Text style={{ ...styles.small, ...styles.bold }}>
                                Total {cantidadTotalOrdenes}
                            </Text>
                            <Text style={styles.small}>
                                {formatoGuaranies(
                                    importeTotalMonchisMarketplace,
                                )}
                            </Text>
                        </View>
                    </>
                );

                // Monchis Marketplace - Se agrega datos de columna Totales
                tablaResumenMonchisMarketplaceConFormato.push({
                    key: tablaResumenMonchisMarketplaceConFormato.length,
                    comisionesPorcentaje: (
                        <strong>Total {cantidadTotalOrdenes}</strong>
                    ),
                    totalMontoComisiones: (
                        <strong>
                            {formatoGuaranies(importeTotalMonchisMarketplace)}
                        </strong>
                    ),
                });
            } else {
                // Monchis Marketplace - Dummy placeholder con datos en cero
                monchisMarketplaceArrPDF = (
                    <>
                        <View style={styles.detalleCorte}>
                            <Text style={{ ...styles.small, ...styles.bold }}>
                                0 órdenes al 0%
                            </Text>
                            <Text style={styles.small}>
                                {formatoGuaranies(0)}
                            </Text>
                        </View>
                        <View
                            style={{
                                width: '100%',
                                borderBottom: '1 solid #000',
                                marginTop: 5,
                                marginBottom: 10,
                            }}
                        />
                        <View style={styles.detalleCorte}>
                            <Text style={{ ...styles.small, ...styles.bold }}>
                                Total 0
                            </Text>
                            <Text style={styles.small}>
                                {formatoGuaranies(0)}
                            </Text>
                        </View>
                    </>
                );

                tablaResumenMonchisDriversConFormato = [
                    {
                        key: 0,
                        comisionesPorcentaje: `0 órdenes al 0%`,
                        totalMontoComisiones: formatoGuaranies(0),
                    },
                    {
                        key: 1,
                        comisionesPorcentaje: <strong>Total 0</strong>,
                        totalMontoComisiones: (
                            <strong>{formatoGuaranies(0)}</strong>
                        ),
                    },
                ];
            }

            // Tabla Balance - Actualizar sub total comision
            subtotalComision = importeTotalMonchisMarketplace;

            // Resumen Importes
            let resumenSaldoAnterior = Number(item.previous_balance_real) || 0;

            // Tabla Resumen Importes - Datos con Formato
            let tablaResumenImportes = tablaResumen.map((item, index) => ({
                key: index,
                resumenPagos: (
                    <span
                        style={{
                            fontWeight: item.options.bold ? 'bold' : 'normal',
                        }}>
                        {item.title}
                    </span>
                ),
                totalResumenPagos: (
                    <span
                        style={{
                            color: item.options.color,
                            fontWeight: item.options.bold ? 'bold' : 'normal',
                        }}>
                        {item.value.replace('-', '')}
                    </span>
                ),
            }));

            const sePuedeCargarPago = item?.enable_pay;
            const sePuedeVerDetalle = Boolean(resumenCortePDF);

            const botonesResumen = (
                <>
                    {sePuedeCargarPago && (
                        <Button
                            block={true}
                            type="primary"
                            className={classesBalance.PrimaryCustom}
                            onClick={() => {
                                setCargarPagoModalVisible(true);
                                const montoMaximo = resumenSaldoAnterior;
                                cargarPagoForm.setFieldsValue({
                                    amount: montoMaximo,
                                });
                                setCargarPagoFranquiciaId(franquiciaId);
                            }}>
                            Cargar Pago
                        </Button>
                    )}
                    <Button
                        block={true}
                        type="primary"
                        className={classesBalance.PrimaryCustom}
                        onClick={() => detalleOrdenHandler(franquiciaId)}>
                        Detalle de Ordenes
                    </Button>
                    {sePuedeVerDetalle && (
                        <Button
                            block={true}
                            type="primary"
                            className={classesBalance.PrimaryCustom}
                            onClick={verDetalle}>
                            Ver detalle del corte
                        </Button>
                    )}
                </>
            );

            return {
                key: key,
                ruc: ruc,
                legal_name: razonSocial,
                name: franquicia,
                order_quantity: formatoNumeros(cantidadTotalOrdenes),
                order_monchis_quantity_delivery_amount: (
                    <>
                        {formatoNumeros(cantidadTotalOrdenesDrivers)}
                        <br />
                        {formatoGuaranies(totalesMonchisDrivers)}
                    </>
                ),
                commissions_total_orders: formatoGuaranies(importeOrden),
                subtotalComision: formatoGuaranies(subtotalComision),
                commissions_total_commission: formatoGuaranies(comisionACobrar),
                columnasResumenMonchisDrivers: columnasResumenMonchisDrivers,
                dataResumenMonchisDrivers: tablaResumenMonchisDriversConFormato,
                columnasResumenMonchisMarketplace:
                    columnasResumenMonchisMarketplace,
                dataResumenMonchisMarketplace:
                    tablaResumenMonchisMarketplaceConFormato,
                columnasResumenImportes: columnasResumenImportes,
                dataResumenImportesConFormato: tablaResumenImportes,
                tieneMonchisDrivers: tieneMonchisDrivers,
                paramsBalance: paramsBalance,
                botonesResumen: botonesResumen,
            };
        });

        setTablaBalanceData(tablaBalanceConFormato);
    };

    useEffect(() => {
        if (balanceData && dataTarjetas) {
            refrescarDatosTarjetaCabecera();
            refrescarDatosTabla();
        }
    }, [balanceData, dataTarjetas]);

    // Botones Resumen - State Cargar Pago
    const [cargarPagoModalVisible, setCargarPagoModalVisible] = useState(false);
    const [cargarPagoFranquiciaId, setCargarPagoFranquiciaId] = useState();
    const [cargarPagoLoading, setCargarPagoLoading] = useState(false);
    const [cargarPagoForm] = Form.useForm();
    const cargarPagoMontoMinimo = 0;

    const cargarPagoConfirmarHandler = () => {
        setCargarPagoLoading(true);
        cargarPagoForm
            .validateFields()
            .then(async (values) => {
                try {
                    let data = cargarPagoForm.getFieldsValue();
                    data = {
                        ...data,
                        franchise_id: cargarPagoFranquiciaId,
                        action: 'discount',
                        is_amortization: true,
                        receipt_date: values.receipt_date.format('YYYY-MM-DD'),
                    };

                    const respuesta = await axios.post(
                        '/balance_payment',
                        data,
                    );

                    message.success(
                        respuesta?.data?.data ||
                            'Pago registrado correctamente',
                    );
                } catch (error) {
                    message.error(
                        error?.response?.data?.message ||
                            'Hubo un problema con el cargo del pago',
                    );
                }
            })
            .catch((error) => {
                message.error(error);
            })
            .finally(() => {
                cargarPagoForm.resetFields();
                setCargarPagoModalVisible(false);
                setCargarPagoLoading(false);
                setRefrescarDatos(true);
            });
    };

    // Boton hacer TXT
    const [dataEndpointTXT, setDataEndpointTXT] = useState(false);

    const generarTXTButtonHandler = async () => {
        if (paramsBalance && franquiciasData) {
            const dataEndpoint = {
                date_start: paramsBalance.date_start,
                date_end: paramsBalance.date_end,
                franchise_id: franquiciasData,
            };

            try {
                const respuesta = await axios.post(
                    '/get_cadastres',
                    dataEndpoint,
                );
                const cadastresArray = respuesta?.data?.data?.cadastres || [];
                message.success(respuesta?.data?.data?.data);
                return cadastresArray;
            } catch (error) {
                message.error(
                    'Hubo un problema al obtener los datos para generar el archivo: ' +
                        error?.response?.data?.message ||
                        error?.response?.message,
                );
                return [];
            }
        } else {
            message.error('Primero genere un Balance');
            return undefined;
        }
    };

    const csvInstance = useRef();
    useEffect(() => {
        if (
            dataEndpointTXT &&
            csvInstance.current &&
            csvInstance.current.link
        ) {
            setTimeout(() => {
                csvInstance.current.link.click();
                setDataEndpointTXT(false);
            });
        }
    }, [dataEndpointTXT]);

    const historyHook = useHistory();

    // Actualizar pagina en parametros para refrescar los datos de la tabla
    const cambioDePaginaHandler = (page) => {
        setParamsBalance((state) => ({
            ...state,
            page: page,
        }));
    };

    const { tienePermiso, esUsuarioComercio } = useVerificarPermiso();
    const isACommercialUser = esUsuarioComercio();
    const hasPermission = tienePermiso(
        Permisos.reportes.ventas.detalleTransaccion.verOpcionesPagoOnline,
    );

    const reporteBalanceChildren = (
        <div className={classes.reporteBalanceChildren}>
            {/* Formulario y Acciones extras */}
            <Form
                name="basic"
                layout="vertical"
                form={reporteBalanceForm}
                onFinish={(event) => verBalanceButtonHandler(event)}>
                <Row gutter={24}>
                    <Col span={20}>
                        <Row gutter={24}>
                            <Col span={8}>
                                <FormItemSelectFranquicia
                                    formInstance={reporteBalanceForm}
                                    disabled={obteniendoDatos}
                                    initialValue={
                                        paramsBalance?.ruc_or_franchise
                                    }
                                />
                            </Col>
                        </Row>
                        <Row gutter={24}>
                            <Col span={8}>
                                <Form.Item
                                    name="date"
                                    label="Periodo"
                                    rules={[
                                        {
                                            type: 'array',
                                            required: true,
                                            message:
                                                'Por favor seleccione un periodo',
                                        },
                                    ]}>
                                    <RangePicker
                                        format={formatoFechaInput}
                                        disabled={obteniendoDatos}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <FormItemSelectTipoPago
                                    formInstance={reporteBalanceForm}
                                    esUsuarioComercio={isACommercialUser}
                                    tienePermisoVerPagoOnline={hasPermission}
                                    disabled={obteniendoDatos}
                                    initialValue={paramsBalance?.payment_type}
                                />
                            </Col>
                            <Col span={8}>
                                <FormItemSelectFamilia
                                    formInstance={reporteBalanceForm}
                                    disabled={obteniendoDatos}
                                    initialValue={paramsBalance?.family}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4}>
                        <ProtectedComponent
                            permiso={
                                Permisos.reportes.ventas.reporteBalance
                                    .generarArchivo
                            }>
                            <Form.Item>
                                <Button
                                    type="secondary"
                                    className="PrimaryOutlinedCustom"
                                    htmlType="button"
                                    loading={obteniendoDatos}
                                    block={true}
                                    onClick={async () => {
                                        const newCsvData =
                                            await generarTXTButtonHandler();
                                        newCsvData &&
                                            setDataEndpointTXT(newCsvData);
                                    }}
                                    onChange={(evt) =>
                                        formSelectChangeHandler(evt)
                                    }>
                                    Generar Archivo
                                </Button>
                            </Form.Item>
                        </ProtectedComponent>

                        <ProtectedComponent
                            permiso={
                                Permisos.reportes.ventas.reporteBalance
                                    .generarArchivo
                            }>
                            <Form.Item>
                                {dataEndpointTXT ? (
                                    <CSVLink
                                        filename={'Informe para Banco.xls'}
                                        ref={csvInstance}
                                        headers={
                                            requestHistoryColumns
                                                ? requestHistoryColumns.map(
                                                      (head) => head.title,
                                                  )
                                                : []
                                        }
                                        data={
                                            dataEndpointTXT
                                                ? dataEndpointTXT.map((row) =>
                                                      requestHistoryColumns.map(
                                                          (head) =>
                                                              `${
                                                                  row[head.key]
                                                              }`,
                                                      ),
                                                  )
                                                : []
                                        }
                                    />
                                ) : undefined}
                            </Form.Item>
                        </ProtectedComponent>

                        <Form.Item>
                            <Button
                                type="primary"
                                className="PrimaryCustom"
                                htmlType="submit"
                                loading={obteniendoDatos}
                                block={true}>
                                Ver Balance
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <Divider style={{ border: 'none', margin: '0.5rem 0' }} />
            {/* Tarjetas */}
            <Row gutter={24}>
                <Col span={6}>
                    <Card
                        icon={<CustomSVG name="money" />}
                        cargando={obteniendoDatos}
                        backgroundcolor="var(--amarillo)">
                        <strong>{tarjetasCabecera.cantidadVentas}</strong>
                        <small>Ventas</small>
                        <small>{tarjetasCabecera.totalVentas}</small>
                    </Card>
                </Col>
            </Row>
            <Divider style={{ border: 'none', margin: '1.2rem 0' }} />
            {/* Tabla balance */}
            <BalanceTable
                key="tabla-balance"
                columnasBalance={columnasBalance} //columnasBalance
                dataBalance={tablaBalanceData} //tablaBalanceData
                refrescarDatos={refrescarDatosHandler} // accion de refrescar datos para los botones del resumen
                loading={obteniendoDatos}
                mostrarBotonVerPDF={true}
                pagination={{
                    pageSize: totalRegistrosPorPaginaBalance,
                    total: cantidadItemsBalance,
                    current: paramsBalance?.page ? paramsBalance.page : 1,
                    showSizeChanger: false,
                    onChange: (page) => cambioDePaginaHandler(page),
                }}></BalanceTable>
            <Modal
                title="Cargar Pago"
                closable={false}
                open={cargarPagoModalVisible}
                footer={null}>
                <Spin spinning={cargarPagoLoading}>
                    <Form
                        form={cargarPagoForm}
                        layout="vertical"
                        validateMessages={validateMessages}
                        onFinish={cargarPagoConfirmarHandler}>
                        <Form.Item label=" " className={classes.rowFormItem}>
                            <Form.Item
                                label="Tipo de pago"
                                name="type"
                                rules={[{ required: true }]}>
                                <Select
                                    disabled={cargarPagoLoading}
                                    placeholder="-- Seleccione una opción --">
                                    {Object.keys(paymentTypes).map((key) => (
                                        <SelectAntd.Option
                                            key={key}
                                            value={key}>
                                            {paymentTypes[key]}
                                        </SelectAntd.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label="Fecha de depósito"
                                name="deposit_date"
                                rules={[{ required: true }]}>
                                <DatePicker
                                    name="receipt_date"
                                    format={formatoFechaInput}
                                    disabledDate={(current) =>
                                        current && current > moment()
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label=" " className={classes.rowFormItem}>
                            <Form.Item
                                label="Número del Recibo"
                                name="receipt_number"
                                rules={[{ required: true }]}>
                                <Input disabled={cargarPagoLoading} />
                            </Form.Item>

                            <Form.Item
                                label="Monto a pagar"
                                rules={[{ required: true }]}
                                name="amount">
                                <InputNumber
                                    style={{ width: '100%' }}
                                    min={cargarPagoMontoMinimo}
                                    // max={cargarPagoMontoMaximo}
                                    disabled={cargarPagoLoading}
                                    formatter={(value) =>
                                        `Gs. ${value}`.replace(
                                            /\B(?=(\d{3})+(?!\d))/g,
                                            ',',
                                        )
                                    }
                                    parser={(value) =>
                                        value.replace(/Gs.\s?|(,*)/g, '')
                                    }
                                />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label=" " className={classes.rowFormItem}>
                            <Form.Item
                                label="Fecha del recibo"
                                name="receipt_date"
                                rules={[{ required: true }]}>
                                <DatePicker
                                    name="receipt_date"
                                    format={formatoFechaInput}
                                    disabledDate={(current) =>
                                        current && current > moment()
                                    }
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item
                                label="Nro. de factura a la que aplica"
                                name="invoice_number"
                                rules={[{ required: true }]}>
                                <Input disabled={cargarPagoLoading} />
                            </Form.Item>
                        </Form.Item>

                        <Form.Item label="Observaciones" name="reference">
                            <Input
                                disabled={cargarPagoLoading}
                                placeholder="..."
                            />
                        </Form.Item>

                        <Row gutter={24} justify="end">
                            <Col>
                                <Form.Item>
                                    <Button
                                        onClick={() => {
                                            setCargarPagoModalVisible(false);
                                            cargarPagoForm.resetFields();
                                        }}>
                                        Cancelar
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Guardar pago
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Spin>
            </Modal>
        </div>
    );

    return (
        <ABMLayout titulo="Reporte de balance">
            {reporteBalanceChildren}
        </ABMLayout>
    );
};

export default ListaReporteBalance;
