import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import classes from './EstadoPedidoAgendado.module.css';

const { Text } = Typography;

const EstadoPedidoAgendado = ({ fechaAgendado, estado }) => {
    let estadoTexto;
    const fecha = fechaAgendado
        ? fechaAgendado.toString().replace('.000Z', '').replace('T', ' ')
        : '';

    const fechaHoraAgendamiento = `${new Date(fecha).toLocaleDateString(
        'es-PY',
    )} - ${new Date(fecha).toLocaleTimeString('es-PY', {
        hour12: false,
        timeStyle: 'short',
    })} hs.`;

    switch (estado) {
        case 11:
            estadoTexto = 'Pendiente de aprobación';
            break;
        case 12:
            estadoTexto = 'Aprobado';
            break;
        case 10:
            estadoTexto = 'Rechazado';
            break;
        default:
            break;
    }
    return (
        <>
            <div>
                <Text
                    className={
                        estado === 11
                            ? classes.estadoTextoPendiente
                            : estado === 12
                            ? classes.estadoTextoAprobado
                            : estado === 10
                            ? classes.estadoTextoRechazado
                            : null
                    }>
                    {estadoTexto}
                </Text>
            </div>
            <div>
                <Text level={5} strong={true}>
                    Agendado para:
                </Text>
                <Text> {fechaHoraAgendamiento}</Text>
            </div>
        </>
    );
};

EstadoPedidoAgendado.propTypes = {
    fechaAgendado: PropTypes.any,
    estado: PropTypes.any,
};

export default EstadoPedidoAgendado;
