import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    Table,
    Button,
    Col,
    Row,
    Select,
    DatePicker,
    Tag,
    Form,
    message,
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';

import { formatoGuaranies } from '../../../../utils-components/Number';
import { formatoFechaSinHora } from '../../../../utils-components/Date';
import clienteAxios from '../../../../../config/axios';

import { urls } from '../../../../../constants';
import { Permisos } from '../../../../../constants/Permisos';

import FormItemSelectTipoPago from '../../../../atoms/Form/Select/TipoPago';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import { useVerificarPermiso } from '../../../../hooks/useVerificarPermiso';
import useRouterQuery from 'hooks/useRouterQueries';

const { RangePicker } = DatePicker;
const { Option } = Select;

const initialFormValues = {
    date: [moment('2016/01/01'), moment()],
    franchise: 0,
    paymentType: 0,
    orderState: 6,
    deliveryType: 0,
};

const PedidosUsuarios = () => {
    const history = useHistory();
    const location = useLocation();

    const { tienePermiso, esUsuarioComercio } = useVerificarPermiso();

    const { createQueryParams } = useRouterQuery();

    const {
        obtenerListadoPedido,
        ordersTable,
        loadingListaPedido,
        tabSeleccionada,
    } = useContext(UsuariosContext);

    const [params, setParams] = useState();
    const [loading, setLoading] = useState(false);
    const [franchises, setFranchises] = useState();
    const [orderStates, setOrderStates] = useState();
    const [deliveryTypes, setDeliveryTypes] = useState();

    const [ordersForm] = Form.useForm();

    useEffect(() => {
        getFormOptions();
    }, []);

    useEffect(() => {
        if (tabSeleccionada === 6) {
            searchOrdersHandler(initialFormValues);
        } else if (location.search) {
            try {
                const urlParams = new URLSearchParams(location.search);

                const ordersIdParam = urlParams.get('orders');

                const formValues = {
                    ...initialFormValues,
                    orderState: 0,
                    ordersId: ordersIdParam,
                };

                searchOrdersHandler(formValues);
            } catch (error) {
                message.warning(
                    'Hubo un problema al intentar obtener las órdenes del cupón. Mostrando todas las órdenes.',
                );
                searchOrdersHandler(initialFormValues);
            }
        }
    }, [tabSeleccionada, location.search]);

    // TODO: USUARIOS - REFACTOR - Migrate to 'Utilidades state' once merged with the lastest updates
    const getFormOptions = async () => {
        setLoading(true);

        let arrFranquicias = {};
        try {
            const respuesta = await clienteAxios.get('/franchises_list');
            const franquicias = respuesta.data?.data?.franchises.map(
                (item) => ({
                    ...item,
                    key: item.id,
                }),
            );
            arrFranquicias = [{ id: 0, name: 'Todas', key: 0 }, ...franquicias];
        } catch (error) {
            message.error(error?.response?.data?.message);
        }

        setFranchises(arrFranquicias);

        let estados = {};
        let param = { axiosBaseURL: urls.REACT_APP_BACKEND_URL_B };
        try {
            const respuesta = await clienteAxios.get('/get_order_states', {
                params: param,
            });
            const states = respuesta.data?.data;
            estados = [{ id: 0, name: 'Todos', key: 0 }, ...states];
        } catch (error) {
            message.error(error?.response?.data?.message);
        }

        setOrderStates(estados);

        param = { axiosBaseURL: urls.REACT_APP_BACKEND_URL_B };
        let tiposEntrega = {};
        try {
            const respuesta = await clienteAxios.get('/get_delivery_types', {
                params: param,
            });
            const deliveryTypes = respuesta.data?.data;
            tiposEntrega = [{ id: 0, type_name: 'Todos' }, ...deliveryTypes];
        } catch (error) {
            message.error(error?.response?.data?.message);
        }

        setDeliveryTypes(tiposEntrega);

        setLoading(false);
    };

    const searchOrdersHandler = (values) => {
        let paymentType = undefined;
        if (+values.paymentType) {
            paymentType = +values.paymentType;
        }

        let orderStates = undefined;
        if (+values.orderState) {
            orderStates = +values.orderState;
        }

        let deliveryType = undefined;
        if (+values.deliveryType) {
            deliveryType = +values.deliveryType;
        }

        let searchParams = {
            dateStart: values.date
                ? values.date[0].format('YYYY/MM/DD')
                : undefined,
            dateEnd: values.date
                ? values.date[1].format('YYYY/MM/DD')
                : undefined,
            franchiseId: +values.franchise || undefined,
            paymentType,
            orderStates,
            deliveryType,
            page: values?.page || 1,
            ordersId: values?.ordersId || undefined,
        };

        obtenerListadoPedido(searchParams);
        setParams(searchParams);
    };

    const verOrdenBotonHandler = (record) => {
        const orderId = record.orden;
        const queryParams = createQueryParams({ order_id: orderId });
        history.push(
            `/reportes/ventas/detalle-transaccion/ver-orden${queryParams}`,
        );
    };

    // Definimos los tipos de entrega
    const tipoDeEntrega = (entrega) => {
        let text;
        switch (entrega) {
            case 'Delivery Monchis':
                text = <Tag color="red">{'Delivery de Monchis'}</Tag>;
                break;
            case 'Delivery':
                text = <Tag color="orange">{'Delivery del Local'}</Tag>;
                break;
            case 'Pickup':
                text = <Tag color="cyan">{'Pasar a buscar'}</Tag>;
                break;
            default:
                text = <Tag>{'No definido'}</Tag>;
        }
        return text;
    };

    // Definimos los tipos de pago
    const tipoDePago = (tipo) => {
        switch (tipo) {
            case 'CASH':
                return 'Efectivo';
            case 'BANCARD':
                return 'Bancard';
            case 'ITAU_PAGO':
                return 'Itaú Pago';
            case 'POS':
                return 'POS';
            case 'DISCOUNT':
                return 'Descuento';
            default:
                return 'tipo';
        }
    };

    const changePageHandler = (page) => {
        setParams((currentParams) => {
            const newParams = {
                ...currentParams,
                page: page,
            };

            searchOrdersHandler(newParams);

            return newParams;
        });
    };

    const columns = [
        {
            title: 'Orden',
            dataIndex: 'orden',
            render: (orden) => `# ${orden}`,
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
        },
        {
            title: 'Tipo de pago',
            dataIndex: 'tipo',
            render: (tipo) => tipoDePago(tipo),
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
        },
        {
            title: 'Entrega',
            dataIndex: 'entrega',
            render: (entrega) => tipoDeEntrega(entrega),
        },
        {
            title: 'Costo orden',
            dataIndex: 'costo',
        },
        {
            title: 'Total',
            dataIndex: 'total',
        },
        {
            title: ' ',
            dataIndex: 'detalles',
            render: (_, record) => (
                <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => verOrdenBotonHandler(record)}
                />
            ),
        },
    ];

    const ordersList = ordersTable?.rows?.map((pedido) => {
        // TODO: USUARIOS - REFACTOR - BACKEND - Correct spelling of 'frsnchise' attribute
        const {
            confirmed_at,
            total,
            branch: {
                frsnchise: { name, category },
            },
            id,
            payment_type,
            delivery_price,
            status,
            delivery_type_name,
        } = pedido;
        let totalConDelivery = Number(delivery_price) + Number(total);

        return {
            orden: id,
            fecha: formatoFechaSinHora(confirmed_at),
            franquicia: name,
            tipo: payment_type,
            categoria: category,
            total: formatoGuaranies(totalConDelivery),
            estado: status,
            costo: formatoGuaranies(total),
            entrega: delivery_type_name,
        };
    });

    const tienePermisoVerOpcionesPagoOnline = tienePermiso(
        Permisos.usuarios.listaUsuarios.verOpcionesPagoOnline,
    );

    const ordersTablePaginationConfig = params?.ordersId
        ? false
        : {
              pageSize: ordersTable?.pageSize || 1,
              total: ordersTable?.total || 1,
              showSizeChanger: false,
              current: ordersTable?.page || 1,
              onChange: changePageHandler,
          };

    return (
        <>
            <Form
                layout="vertical"
                form={ordersForm}
                onFinish={searchOrdersHandler}
                initialValues={initialFormValues}>
                <Row gutter={[16, 16]} justify="space-between" align="bottom">
                    <Col span={6}>
                        <Form.Item name="date" label="Periodo">
                            <RangePicker
                                disabled={loading}
                                format={'DD/MM/YYYY'}
                                style={{ borderRadius: 10 }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="franchise" label="Franquicia">
                            <Select
                                disabled={loading}
                                placeholder="Seleccione una franquicia"
                                name="franquiciaId"
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }>
                                {franchises &&
                                    franchises.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <FormItemSelectTipoPago
                            formInstance={ordersForm}
                            disabled={loading}
                            esUsuarioComercio={esUsuarioComercio()}
                            tienePermisoVerPagoOnline={
                                tienePermisoVerOpcionesPagoOnline
                            }
                        />
                    </Col>
                    <Col span={4}>
                        <Form.Item name="orderState" label="Estado">
                            <Select
                                placeholder="Seleccione estado"
                                disabled={loading}
                                showSearch>
                                {orderStates &&
                                    orderStates.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item name="deliveryType" label="Entrega">
                            <Select
                                placeholder="Seleccione estado"
                                disabled={loading}
                                showSearch>
                                {deliveryTypes &&
                                    deliveryTypes.map((item) => (
                                        <Option key={item.id} value={item.id}>
                                            {item.type_name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                disabled={loading || loadingListaPedido}>
                                Buscar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table
                scroll={{ x: 1200 }}
                columns={columns}
                dataSource={ordersList}
                loading={loadingListaPedido}
                pagination={ordersTablePaginationConfig}
            />
        </>
    );
};

export default PedidosUsuarios;
