import { Col, message, Row, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import PushAlcance from '../../molecules/Push/PushAlcance';
import PushVistaPrevia from '../../molecules/Push/PushVistaPrevia';
import PushMensaje from '../../molecules/Push/PushMensaje';
import PushDeepLink from '../../molecules/Push/PushDeepLink';
import PushZonaCollapse from '../../molecules/Push/PushZonaCollapse';
import axios from '../../../config/axios';
import PushDireccionesUsuario from '../../molecules/Push/PushDireccionesUsuario';
import ProgramarPush from '../../molecules/Push/PushProgramado.js';
import classes from './Push.module.css';
import PropTypes from 'prop-types';

const PushZonas = ({
    pushForm,
    fields,
    tab,
    maxCantidadCaracteresTitulo,
    maxCantidadCaracteresMensaje,
    tipoZona,
    setTipoZona,
    titulo,
    mensaje,
    date,
    hours,
    checked,
    checkedSwitch,
}) => {
    const [cantidadUsuariosTotal, setCantidadUsuariosTotal] = useState(); // Total de usuarios en la BD
    const [cantidadUsuariosSeleccionados, setCantidadUsuariosSeleccionados] =
        useState(); // Total de usuarios en la BD

    const [restablecerDeeplink, setRestablecerDeeplink] = useState(false);
    const [alcanceLoading, setAlcanceLoading] = useState(false);

    const getUsers = async () => {
        const params = {
            user_id: undefined,
            full_name: undefined,
            email: undefined,
        };
        try {
            // obtener datos
            const response = await axios.get('/users_list', { params: params });

            // extraer cantidad total de usuarios
            const tempTotalUsuarios = response.data.data.total_users;

            setCantidadUsuariosTotal(tempTotalUsuarios);
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de los usuarios' ||
                    error,
            );
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <>
            <Row gutter="24">
                <Col span={12}>
                    <Space
                        direction="vertical"
                        size="middle"
                        className={classes.pushZonasCollapse}>
                        <PushZonaCollapse
                            pushForm={pushForm}
                            fields={fields}
                            setCantidadUsuariosSeleccionados={
                                setCantidadUsuariosSeleccionados
                            }
                            tipoZona={tipoZona}
                            setTipoZona={setTipoZona}
                            setRestablecerDeeplink={setRestablecerDeeplink}
                            setAlcanceLoading={setAlcanceLoading}
                        />

                        <PushMensaje
                            prefix="zonas"
                            pushForm={pushForm}
                            maxCantidadCaracteresTitulo={
                                maxCantidadCaracteresTitulo
                            }
                            maxCantidadCaracteresMensaje={
                                maxCantidadCaracteresMensaje
                            }
                        />
                        <PushDeepLink
                            pushForm={pushForm}
                            tab={tab}
                            tipoZona={tipoZona}
                            fields={fields}
                            restablecerDeeplink={restablecerDeeplink}
                            setRestablecerDeeplink={setRestablecerDeeplink}
                        />
                    </Space>
                </Col>
                <Col span={12}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{ width: '100%' }}>
                        <ProgramarPush
                            pushForm={pushForm}
                            tipoZona={tipoZona}
                            setDate={date}
                            setHours={hours}
                            setChecked={checked}
                            checkedSwitch={checkedSwitch}></ProgramarPush>
                        <PushDireccionesUsuario />

                        <PushAlcance
                            pushForm={pushForm}
                            usuariosTotal={cantidadUsuariosTotal}
                            usuariosSeleccionados={
                                cantidadUsuariosSeleccionados
                            }
                            alcanceLoading={alcanceLoading}
                        />
                        <PushVistaPrevia titulo={titulo} mensaje={mensaje} />
                    </Space>
                </Col>
            </Row>
        </>
    );
};

export default PushZonas;

PushZonas.propTypes = {
    pushForm: PropTypes.any,
    fields: PropTypes.any,
    tab: PropTypes.any,
    maxCantidadCaracteresTitulo: PropTypes.any,
    maxCantidadCaracteresMensaje: PropTypes.any,
    tipoZona: PropTypes.any,
    setTipoZona: PropTypes.any,
    titulo: PropTypes.any,
    mensaje: PropTypes.any,
    date: PropTypes.any,
    hours: PropTypes.any,
    checked: PropTypes.any,
    checkedSwitch: PropTypes.any,
};
