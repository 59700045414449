import axios from 'axios';
import { urls } from '../constants';

const clienteAxios = axios.create({
    baseURL: urls.REACT_APP_BACKEND_URL_A,
});

export const setAuthToken = (token) => {
    if (token) {
        try {
            clienteAxios.defaults.headers.common['Authorization'] = token;
        } catch (error) {
            console.error('No se ha encontrado un token');
        }
    }
};

clienteAxios.interceptors.request.use(
    function (config) {
        const method = config.method;
        const axiosParamsUrl = config?.params?.axiosBaseURL || config.baseURL;
        const axiosDataUrl = config?.data?.axiosBaseURL || config.baseURL;
        const integrationJediUrl =
            urls.REACT_APP_BACKEND_URL_INTEGRATION_JEDI_G;
        const integrationHUrl = urls.REACT_APP_BACKEND_URL_INTEGRATION_H;

        const putOrPushCases = () => {
            config.baseURL = axiosDataUrl;
            config.data = { ...config.data, axiosBaseURL: undefined };
            if (
                axiosDataUrl === integrationJediUrl ||
                axiosDataUrl === integrationHUrl
            ) {
                config.headers.common.Authorization = `Bearer ${config.headers.common.Authorization}`;
            }
        };

        switch (method) {
            case 'get':
                config.baseURL = axiosParamsUrl;
                config.params = { ...config.params, axiosBaseURL: undefined };
                break;

            case 'post':
                putOrPushCases();
                break;

            case 'put':
                putOrPushCases();
                break;

            case 'patch':
                putOrPushCases();
                break;

            case 'delete':
                config.baseURL = axiosDataUrl;
                config.data = { ...config.data, axiosBaseURL: undefined };
                break;

            default:
                break;
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

// TODO: Habilitar cuando se notifique que el back esta listo - 2022-07-19
// clienteAxios.interceptors.response.use(
//     (response) => {
//         return response;
//     },
//     (error) => {
//         const errorStatusCodeArray = [401, 403];
//         const statusCode = error.response.status;
//         if (errorStatusCodeArray.includes(statusCode)) {
//             // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
//             clearLocalStorage();
//             window.location.replace('/');
//         }
//         return Promise.reject(error);
//     },
// );

export default clienteAxios;
