import React, { useState, useEffect } from 'react';
import { Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { differenceInMinutes } from 'date-fns';
import classes from './AlertaPorRetraso.module.css';

const AlertComponent = ({ alertSent, fechaPedido }) => {
    const [showAlert, setShowAlert] = useState(false);

    useEffect(() => {
        const calculateTimeAgo = () => {
            const fechaPedidoUTC = new Date(fechaPedido);
            const fechaLocal = new Date();
            const offsetMinutes = fechaLocal.getTimezoneOffset();
            const fechaUTC = new Date(
                fechaLocal.getTime() - offsetMinutes * 60 * 1000,
            );

            const minutesDifference = differenceInMinutes(
                fechaUTC,
                fechaPedidoUTC,
            );

            if (minutesDifference >= 5) {
                setShowAlert(true);
            }
        };

        calculateTimeAgo();

        const intervalId = setInterval(calculateTimeAgo, 1000);

        return () => clearInterval(intervalId);
    }, [fechaPedido]);

    return (
        <div>
            {showAlert && (
                <Tooltip
                    title={alertSent ? 'Alerta Enviada' : 'Alerta No Enviada'}>
                    {alertSent ? (
                        <CheckCircleOutlined
                            className={classes.checkCircleOutlined}
                        />
                    ) : (
                        <CloseCircleOutlined
                            className={classes.closeCircleOutlined}
                        />
                    )}
                </Tooltip>
            )}
        </div>
    );
};

AlertComponent.propTypes = {
    alertSent: PropTypes.bool,
    fechaPedido: PropTypes.string,
};

export default AlertComponent;
