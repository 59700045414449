import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmisionDocumentos from './EmisionDocumentos';
import Timbrado from './Timbrado';
import Balance from './Balance/FacturacionBalance';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';
import ListaRecibos from './ListaRecibos';
import ListaDocumentosComercio from './DocumentosComercio';

const Facturacion = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/balance`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.finanzas.facturacion.balance.verPagina
                        }
                        componente={Balance}
                    />
                )}
            />
            <Route
                path={`${match.url}/emision-documentos`}
                component={EmisionDocumentos}
            />
            <Route path={`${match.url}/timbrado`} component={Timbrado} />
            <Route
                path={`${match.url}/documentos-comercio`}
                component={ListaDocumentosComercio}
            />
            <Route path={`${match.url}/recibos`} component={ListaRecibos} />
        </Switch>
    );
};

export default Facturacion;

Facturacion.propTypes = {
    match: PropTypes.object,
};
