import React, { useEffect, useState } from 'react';
import { Card, Col, Progress, Row, Space, Form, Input } from 'antd';
import { MonitorOutlined, UserOutlined } from '@ant-design/icons';
import { formatoNumeros } from '../../utils-components/Number';
import PropTypes from 'prop-types';

const PushAlcance = ({
    pushForm,
    usuariosTotal,
    usuariosSeleccionados,
    alcanceLoading,
}) => {
    const [porcentajeProgressBar, setPorcentajeProgressBar] = useState();

    useEffect(() => {
        try {
            let tempPorcentajeProgressBar = 0;
            if (usuariosSeleccionados) {
                tempPorcentajeProgressBar =
                    (usuariosSeleccionados * 100) / usuariosTotal;
            } else {
                tempPorcentajeProgressBar =
                    (usuariosTotal * 100) / usuariosTotal;
            }
            if (tempPorcentajeProgressBar < 2) {
                tempPorcentajeProgressBar = 2;
            }

            setPorcentajeProgressBar(tempPorcentajeProgressBar);
            pushForm.setFieldsValue({
                usuariosSeleccionados: usuariosSeleccionados,
            });
            pushForm.setFieldsValue({ usuariosTotal: usuariosTotal });
        } catch (error) {
            setPorcentajeProgressBar(undefined);
        }
    }, [usuariosSeleccionados, usuariosTotal]);

    return (
        <>
            <Form>
                {/* form item de usuarios oculto */}
                <Form.Item
                    name="usuariosSeleccionados"
                    style={{ display: 'none' }}
                    rules={[{ required: true }]}>
                    <Input name="usuariosSeleccionados" type="hidden" />
                </Form.Item>
                <Form.Item
                    name="usuariosTotal"
                    style={{ display: 'none' }}
                    rules={[{ required: true }]}>
                    <Input name="usuariosTotal" type="hidden" />
                </Form.Item>
                <Card title="Alcance">
                    {porcentajeProgressBar ? (
                        <Space
                            direction="vertical"
                            size="middle"
                            style={{ width: '100%' }}>
                            <Progress
                                percent={porcentajeProgressBar}
                                showInfo={false}
                                status={
                                    alcanceLoading
                                        ? 'active'
                                        : usuariosSeleccionados > 0
                                        ? 'normal'
                                        : 'success'
                                }
                            />
                            <Row>
                                <Col span="12">Específica</Col>
                                <Col span="12" style={{ textAlign: 'right' }}>
                                    Amplia
                                </Col>
                            </Row>
                            <Row>
                                <Col span="12">
                                    <UserOutlined
                                        style={{
                                            marginRight: 5,
                                            color: 'var(--azul)',
                                        }}
                                    />
                                    Alcance potencial de usuarios
                                </Col>
                                <Col span="12" style={{ textAlign: 'right' }}>
                                    <strong>
                                        {formatoNumeros(
                                            usuariosSeleccionados ||
                                                usuariosTotal,
                                        )}{' '}
                                        aproximadamente
                                    </strong>
                                </Col>
                            </Row>
                        </Space>
                    ) : (
                        <div style={{ textAlign: 'center' }}>
                            <Space
                                direction="vertical"
                                size="middle"
                                style={{ width: '100%' }}>
                                <MonitorOutlined
                                    style={{
                                        fontSize: 54,
                                        color: 'var(--azul)',
                                    }}
                                />
                                <p>Sin datos del alcance potencial</p>
                            </Space>
                        </div>
                    )}
                </Card>
            </Form>
        </>
    );
};

PushAlcance.propTypes = {
    usuariosTotal: PropTypes.number,
    usuariosSeleccionados: PropTypes.number,
    alcanceLoading: PropTypes.bool,
};

export default PushAlcance;
