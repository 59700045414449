import React, { useContext, useEffect } from 'react';
import ListarTarjetasUsuario from './ListarTarjetasUsuario';

import { Card, Typography, Spin } from 'antd';
import clases from '../pagoOnline.module.css';
import PagoOnlineContext from '../../../../context/pagoOnline/PagoOnlineContext';

const { Title } = Typography;

const RecorrerListarTarjetas = () => {
    // extraer proyectos de state inicial
    const pagoOnlineContext = useContext(PagoOnlineContext);
    const { tarjetas, descargarTarjetasBd, idPersona, loadingTarjeta } =
        pagoOnlineContext;

    useEffect(() => {
        if (!idPersona) return;
        descargarTarjetasBd(idPersona);
    }, [idPersona]);

    // si no se selecciona un usuario o si no posee tarjeta
    if (!idPersona) {
        return (
            <Title level={5}>Seleccione un usuario para obtener tarjetas</Title>
        );
    } else if (tarjetas.length === 0 && !loadingTarjeta)
        return <Title level={5}>El usuario no posee tarjetas</Title>;

    return (
        <>
            <Card title="Tarjetas" className={clases.cardTarjetas} cover>
                <Spin spinning={loadingTarjeta}>
                    {tarjetas.map((tarjeta) => (
                        <ListarTarjetasUsuario
                            key={tarjeta?.cardId}
                            datoTarjeta={tarjeta}
                        />
                    ))}
                </Spin>
            </Card>
        </>
    );
};

export default RecorrerListarTarjetas;
