import React from 'react';
import clases from './pagoOnline.module.css';
import { Row, Col, Card } from 'antd';
import TablaUsuarios from './TablaUsuarios';
import TablaTarjetas from './TablaTarjetas';
import PagoOnlineState from '../../../context/pagoOnline/PagoOnlineState';
import ABMLayout from '../../../layouts/ABMLayout';

const ListaUsuarios = () => {
    return (
        <ABMLayout titulo="Lista de usuarios">
            <Card>
                <PagoOnlineState>
                    <Row className={clases.contentCaja}>
                        <Col span={13}>
                            <TablaUsuarios />
                        </Col>
                        <Col span={10}>
                            <TablaTarjetas />
                        </Col>
                    </Row>
                </PagoOnlineState>
            </Card>
        </ABMLayout>
    );
};

export default ListaUsuarios;
