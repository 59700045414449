import { Tabs, Card } from 'antd';
import React from 'react';
import ABMLayout from '../../../../../layouts/ABMLayout';
import ListaRecibosComercio from './Recibos/ListaRecibosComercio';
import ListaFacturasComercio from './Facturas/ListaFacturasComercio';

const DocumentosComercioLista = () => {
    const tabsItems = [
        {
            key: 'facturas',
            label: `Facturas`,
            children: <ListaFacturasComercio />,
        },
        {
            key: 'recibos',
            label: `Recibos`,
            children: <ListaRecibosComercio />,
        },
    ];
    return (
        <ABMLayout titulo="Mis documentos">
            <Card>
                <Tabs
                    defaultActiveKey="facturas"
                    animated={false}
                    items={tabsItems}
                />
            </Card>
        </ABMLayout>
    );
};

export default DocumentosComercioLista;
