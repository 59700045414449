import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import DetalleTransaccion from './DetalleTransaccion';
import ReporteComisiones from './ReporteComisiones';
import ReporteBalance from './ReporteBalance/ListaReporteBalance';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';
import ListaDetalleComisiones from './DetalleComisiones';

const Ventas = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/detalle-transaccion`}
                component={DetalleTransaccion}
            />
            <Route
                path={`${match.url}/reporte-balance`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.reportes.ventas.reporteBalance.verPagina
                        }
                        componente={ReporteBalance}
                    />
                )}
            />
            <Route
                path={`${match.url}/reporte-comisiones`}
                component={ReporteComisiones}
            />
            <Route
                path={`${match.url}/detalle-comisiones`}
                component={ListaDetalleComisiones}
            />
        </Switch>
    );
};

export default Ventas;

Ventas.propTypes = {
    match: PropTypes.object,
};
