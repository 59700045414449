import {
    SELECCIONAR_ID_TABLA,
    TARJETA_ELIMINADA_EXITO,
    DESCARGA_TARJETA_EXITO,
    SELECCIONAR_PAGINA_ACTUAL,
    OBTENER_USUARIOS_ACTUALIZADOS,
    BUSCAR_USUARIO,
    USUARIOS_TOTALES,
} from '../../types';

// crear el state inicial
export const initialState = {
    idPersona: '',
    tarjetas: [],
    paginaActual: 1,
    usuarios: [],
    totalUsuarios: 0,
};

const PagoOnlineReducer = (state = initialState, action) => {
    switch (action.type) {
        case DESCARGA_TARJETA_EXITO:
            return {
                ...state,
                tarjetas: action.payload,
            };

        case SELECCIONAR_ID_TABLA:
            return {
                ...state,
                idPersona: action.payload,
            };

        case TARJETA_ELIMINADA_EXITO:
            return {
                ...state,
                tarjetas: state.tarjetas.filter(
                    (tarjeta) => tarjeta.alias_token !== action.payload,
                ),
            };

        case BUSCAR_USUARIO:
            return {
                ...state,
                usuarios: state.usuarios.filter(
                    (usuario) =>
                        usuario.nombreCompleto
                            .toLowerCase()
                            .includes(action.payload.toLowerCase()) ||
                        usuario.idUser
                            .toString()
                            .includes(action.payload.toString()), //por si actualizen el endpoint y se pueda buscar por id
                ),
            };

        case SELECCIONAR_PAGINA_ACTUAL:
            return {
                ...state,
                paginaActual: action.payload,
            };

        case OBTENER_USUARIOS_ACTUALIZADOS:
            return {
                ...state,
                usuarios: action.payload,
                tarjetas: [],
                idPersona: '',
                paginaActual:
                    state.totalUsuarios >= 15 ? state.paginaActual : 1,
            };

        case USUARIOS_TOTALES:
            return {
                ...state,
                totalUsuarios: action.payload,
            };

        default:
            return state;
    }
};
export default PagoOnlineReducer;
