import React from 'react';

const Imprimir = () => (
    <svg
        width="20"
        height="18"
        viewBox="0 0 20 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.7313 7.30636H15.8501C15.7532 7.30636 15.6739 7.38672 15.6739 7.48493V8.37779C15.6739 8.476 15.7532 8.55636 15.8501 8.55636H16.7313C16.8282 8.55636 16.9075 8.476 16.9075 8.37779V7.48493C16.9075 7.38672 16.8282 7.30636 16.7313 7.30636ZM17.4361 4.98493H14.7928V0.25279C14.7928 0.154576 14.7135 0.0742188 14.6166 0.0742188H5.27684C5.17991 0.0742188 5.10061 0.154576 5.10061 0.25279V4.98493H2.45729C1.48366 4.98493 0.695068 5.78404 0.695068 6.77065V14.0921C0.695068 14.4872 1.01006 14.8064 1.39996 14.8064H5.10061V17.7528C5.10061 17.851 5.17991 17.9314 5.27684 17.9314H14.6166C14.7135 17.9314 14.7928 17.851 14.7928 17.7528V14.8064H18.4935C18.8834 14.8064 19.1984 14.4872 19.1984 14.0921V6.77065C19.1984 5.78404 18.4098 4.98493 17.4361 4.98493ZM6.5985 1.59208H13.2949V4.98493H6.5985V1.59208ZM13.2949 16.4135H6.5985V10.2528H13.2949V16.4135ZM17.7005 13.2885H14.7928V8.73493H5.10061V13.2885H2.19295V6.77065C2.19295 6.62333 2.3119 6.50279 2.45729 6.50279H17.4361C17.5815 6.50279 17.7005 6.62333 17.7005 6.77065V13.2885Z"
            fill="#1779BA"
        />
    </svg>
);

export default Imprimir;
