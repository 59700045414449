import React, { useReducer, useState } from 'react';
import listaReciboContext from './ListaReciboContext';
import ListaReciboReducer, { initialState } from './ListaReciboReducer';
import clienteAxios from '../../../config/axios';
import {
    OBTENER_LISTADO_RECIBO,
    SELECCIONAR_PAGINA_RECIBO,
    RECIBO_TOTALES,
    RECIBO_GUARDAR_SELECCIONADO,
} from '../../../types';
import { message } from 'antd';
import PropTypes from 'prop-types';
import { urls } from '../../../constants';

const ListaReciboState = ({ children }) => {
    const [loadingTablaRecibo, setLoadingTablaRecibo] = useState(false);
    const [state, dispatch] = useReducer(ListaReciboReducer, initialState);

    const obtenerListadoRecibos = async (datos, page = 1) => {
        const { date_start, date_end, type } = datos;
        setLoadingTablaRecibo(true);
        const params = {
            date_start,
            date_end,
            type,
            page,
        };
        try {
            const resp = await clienteAxios.post(
                '/get_balance_payments',
                params,
            );
            dispatch({
                type: OBTENER_LISTADO_RECIBO,
                payload: resp.data.data.balance_payments,
            });
            dispatch({ type: RECIBO_TOTALES, payload: resp.data.data.total });
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }
        setLoadingTablaRecibo(false);
    };

    const seleccionarPaginadoTabla = (page) => {
        dispatch({
            type: SELECCIONAR_PAGINA_RECIBO,
            payload: page,
        });
    };

    const guardarReciboSeleccionado = (recibo) => {
        dispatch({
            type: RECIBO_GUARDAR_SELECCIONADO,
            payload: recibo,
        });
    };

    const removerReciboSeleccionado = async (id) => {
        let respuesta = false;
        try {
            const params = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            };
            const resp = await clienteAxios.get(
                `/delete_balance_payment/${id}`,
                { params: params },
            );
            message.success(resp.data.data.message);
            respuesta = true;
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }
        return respuesta;
    };

    return (
        <listaReciboContext.Provider
            value={{
                listadoRecibos: state.listadoRecibos,
                paginaActualRecibo: state.paginaActualRecibo,
                totalRecibos: state.totalRecibos,
                reciboSeleccionado: state.reciboSeleccionado,

                obtenerListadoRecibos,
                seleccionarPaginadoTabla,
                loadingTablaRecibo,
                guardarReciboSeleccionado,
                removerReciboSeleccionado,
            }}>
            {children}
        </listaReciboContext.Provider>
    );
};

export default ListaReciboState;

ListaReciboState.propTypes = {
    children: PropTypes.object,
};
