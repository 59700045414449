import React from 'react';
import 'antd/dist/antd.css';
import { Button as ButtonAntd } from 'antd';
import PropTypes from 'prop-types';

const Button = (props) => {
    return (
        <ButtonAntd
            {...props}
            style={{ borderRadius: 10 }}
            className={props.className}
            type={props.type}
            size={props.size}
            disabled={props.disabled}
            loading={props.loading}
            danger={props.danger}
            block={props.block}
            onClick={props.onClick}>
            {props.children}
        </ButtonAntd>
    );
};

Button.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    size: PropTypes.string,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    danger: PropTypes.bool,
    block: PropTypes.bool,
    onClick: PropTypes.func,
    children: PropTypes.any,
};

export default Button;
