import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Filter from 'assets/svg/Filter';
import { Button, Menu, Dropdown, Radio } from 'antd';
import UtilidadesContext from 'context/utilidades/UtilidadesContext';

const OrderStatusSelect = ({
    name,
    isSupport,
    isBancard,
    formInstance,
    setFilterStatus,
}) => {
    const [statusArr, setStatusArr] = useState([]);
    const { ordersStates } = useContext(UtilidadesContext);
    const [selectedStatus, setSelectedStatus] = useState('');

    useEffect(() => {
        obtenerEstados();
    }, []);

    useEffect(() => {
        if (statusArr.length >= 2) {
            if (isBancard) {
                setStatusArr((state) => {
                    return state.map((item) => {
                        let nuevoItem = { ...item };
                        if (item.id === 'todos') {
                            nuevoItem = { ...nuevoItem, isDisabled: true };
                        }
                        return nuevoItem;
                    });
                });
                const nombre = 'OrderStatusSelect' || name;
                let formItem = {};
                formItem[nombre] = statusArr[1].id;
                formInstance.setFieldsValue({ ...formItem });
            } else {
                setStatusArr((state) => {
                    return state.map((item) => {
                        let nuevoItem = { ...item };
                        if (item.id === 'todos') {
                            nuevoItem = { ...nuevoItem, isDisabled: false };
                        }
                        return nuevoItem;
                    });
                });
            }
        }
    }, [isBancard]);

    useEffect(() => {
        if (ordersStates) {
            let arrayDeEstados = ordersStates?.filter((item) => item.id !== 0);
            if (isSupport) {
                arrayDeEstados = arrayDeEstados?.filter(
                    (estadoOrden) =>
                        ordersStates?.findIndex(
                            (state) => state?.id === estadoOrden?.id,
                        ) >= 0,
                );
                arrayDeEstados = arrayDeEstados?.map((item) => ({
                    ...item,
                    name: item.name,
                }));
            }
            setStatusArr([{ id: 'todos', name: 'Todos' }, ...arrayDeEstados]);
        }
    }, [ordersStates, isSupport]);

    const obtenerEstados = async () => {
        let arrayDeEstados = ordersStates?.filter((item) => item.id !== 0);
        if (isSupport) {
            arrayDeEstados = arrayDeEstados?.filter(
                (estadoOrden) =>
                    ordersStates?.findIndex(
                        (state) => state?.id === estadoOrden?.id,
                    ) >= 0,
            );
            arrayDeEstados = arrayDeEstados?.map((item) => ({
                ...item,
                name: item.name,
            }));
        }
        setStatusArr([{ id: 'todos', name: 'Todos' }, ...arrayDeEstados]);
    };

    const handleMenuClick = ({ key }) => {
        setSelectedStatus(key);
        setFilterStatus(key); // Llama a la función setFilterStatus con el estado seleccionado
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {statusArr
                .filter(
                    (item) =>
                        ![
                            'Pendiente',
                            'Finalizada',
                            'Eliminada',
                            'Rechazada',
                        ].includes(item.name),
                )
                .map((item) => (
                    <Menu.Item key={item.id} disabled={item.isDisabled}>
                        <Radio
                            checked={selectedStatus === item.id}
                            onChange={() => setSelectedStatus(item.id)}>
                            {item.name}
                        </Radio>
                    </Menu.Item>
                ))}
        </Menu>
    );

    return (
        <Dropdown overlay={menu} placement="bottomLeft" trigger={['click']}>
            <Button style={{ background: 'none', border: 'none', padding: 0 }}>
                <Filter style={{ fontSize: '1.5em' }} />
            </Button>
        </Dropdown>
    );
};

export default OrderStatusSelect;

OrderStatusSelect.propTypes = {
    name: PropTypes.string,
    isSupport: PropTypes.any,
    isBancard: PropTypes.bool,
    formInstance: PropTypes.any,
    setFilterStatus: PropTypes.func,
};
