import React, { useContext, useState } from 'react';
import {
    Button,
    Card,
    List,
    Typography,
    Empty,
    Row,
    Col,
    Form,
    Input,
} from 'antd';
import styles from './SelectProducts.module.css';
import AddProductModal from './AddProductModal';
import PropTypes from 'prop-types';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';

const SelectProducts = ({ formInstance, disabled }) => {
    const { Text } = Typography;
    const [addProductsModalVisible, setaddProductsModalVisible] =
        useState(false);
    const { selectedProducts } = useContext(PromotionsContext);

    const ItemsSelected = (item) => (
        <>
            <List.Item>
                <List.Item.Meta
                    title={<Text>{item.product_name}</Text>}
                    description={<Text>{item.franchise_name}</Text>}
                />
            </List.Item>
        </>
    );

    const handleOpenCloseModal = () => {
        setaddProductsModalVisible(!addProductsModalVisible);
    };

    const EmptyText = () => {
        return (
            <div className={styles.empty}>
                <Empty description={false} />
                <Text strong>Ningún producto especificado.</Text>
                <p>
                    Para personalizar la selección, dale clic al siguiente
                    botón.
                </p>
            </div>
        );
    };

    const updateSelectedProducts = (selectedId) => {
        const selectedIdString = selectedId.toString();
        // actualizar en el form
        formInstance.setFieldValue({ selectedProducts: selectedIdString });
        handleOpenCloseModal(true);
    };

    return (
        <>
            <Form.Item name="selectedProducts">
                <Input hidden />
            </Form.Item>

            <Card
                type="inner"
                title="Seleccionar productos"
                className={styles.card}>
                <Row>
                    <Col className={styles.itemsSelected}>
                        <div>
                            <List
                                locale={{ emptyText: <EmptyText /> }}
                                dataSource={selectedProducts}
                                renderItem={ItemsSelected}></List>
                        </div>
                        <div className={styles.alignCenter}>
                            <Button
                                disabled={disabled}
                                onClick={handleOpenCloseModal}>
                                Agregar productos
                            </Button>
                        </div>
                    </Col>
                </Row>

                <AddProductModal
                    visible={addProductsModalVisible}
                    onSave={updateSelectedProducts}
                    onClose={handleOpenCloseModal}
                />
            </Card>
        </>
    );
};

export default SelectProducts;

SelectProducts.propTypes = {
    formInstance: PropTypes.object,
    disabled: PropTypes.bool,
};
