import React, { useEffect } from 'react';
import { Collapse } from 'antd';
import MapZonaGeograficaPersonalizada from './PushZonaMapGeograficaPersonalizada';
import SelectZonaCobertura from './PushZonaSelectCobertura';
import PushZonaMapGeograficaPersonalizada from './PushZonaSelectGeografica';
import PropTypes from 'prop-types';

const PushZonaSelectCobertura = ({
    pushForm,
    fields,
    setCantidadUsuariosSeleccionados,
    tipoZona,
    setTipoZona,
    setRestablecerDeeplink,
    setAlcanceLoading,
    coverageZone,
    coordenates,
    disabled,
    handleZoneValidation,
}) => {
    // Collapse Zonas
    const { Panel } = Collapse;

    // Establecer el "tipoZona" al detectar cambio en el "collapse"
    // Restablecer componente de "Deeplink"
    function callbackZonasCollapse(key) {
        setTipoZona(key);
        setRestablecerDeeplink(true);
    }

    // Ejecutar limpieza del componente al detectar cambio en "fields"
    useEffect(() => {
        // Limpiar cuando se haga clic en el boton "Restablecer"
        if (fields === null) {
            callbackZonasCollapse('1');
        }
    }, [fields]);

    return (
        <Collapse
            accordion
            defaultActiveKey={['1']}
            onChange={callbackZonasCollapse}
            activeKey={[tipoZona]}>
            <Panel header="Zona de cobertura" key="1">
                <SelectZonaCobertura
                    pushForm={pushForm}
                    fields={fields}
                    setCantidadUsuariosSeleccionados={
                        setCantidadUsuariosSeleccionados
                    }
                    setRestablecerDeeplink={setRestablecerDeeplink}
                    setAlcanceLoading={setAlcanceLoading}
                    coverageZone={coverageZone}
                    disabled={disabled}
                    handleZoneValidation={handleZoneValidation}
                />
            </Panel>
            <Panel header="Zona geográfica" key="2">
                <PushZonaMapGeograficaPersonalizada
                    tipoZona={tipoZona}
                    pushForm={pushForm}
                    fields={fields}
                    setCantidadUsuariosSeleccionados={
                        setCantidadUsuariosSeleccionados
                    }
                    disabled={disabled}
                    handleZoneValidation={handleZoneValidation}
                />
            </Panel>
            <Panel header="Zona geográfica personalizada" key="3">
                <MapZonaGeograficaPersonalizada
                    fields={fields}
                    pushForm={pushForm}
                    setCantidadUsuariosSeleccionados={
                        setCantidadUsuariosSeleccionados
                    }
                    coordenates={coordenates}
                    disabled={disabled}
                    handleZoneValidation={handleZoneValidation}
                />
            </Panel>
        </Collapse>
    );
};

export default PushZonaSelectCobertura;

PushZonaSelectCobertura.propTypes = {
    pushForm: PropTypes.object,
    fields: PropTypes.any,
    setCantidadUsuariosSeleccionados: PropTypes.func,
    tipoZona: PropTypes.any,
    setTipoZona: PropTypes.func,
    setRestablecerDeeplink: PropTypes.func,
    setAlcanceLoading: PropTypes.func,
    coverageZone: PropTypes.array,
    coordenates: PropTypes.array,
    disabled: PropTypes.bool,
    handleZoneValidation: PropTypes.func,
};
