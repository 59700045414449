import React, { useState, useEffect } from 'react';
import {
    Card,
    Form,
    Col,
    Row,
    Switch,
    TimePicker,
    DatePicker,
    Typography,
} from 'antd';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import classes from './PushMolecules.module.css';

const { Text } = Typography;

const ProgramarPush = ({ setDate, setHours, setChecked, checkedSwitch }) => {
    const [disabled, setDisabled] = useState(true);
    const timeFormat = 'HH:mm';
    const dateFormat = 'YYYY-MM-DD';

    useEffect(() => {
        if (checkedSwitch) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [checkedSwitch]);

    const onChangeDate = (_date, dateString) => {
        setDate(dateString);
    };

    const onChangeTime = (_date, timeString) => {
        setHours(timeString);
    };

    const onChangeSwitch = (checked) => {
        setChecked(checked);
        if (checkedSwitch) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    };

    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day');
    };

    return (
        <Card title="Programar">
            <div className={classes.habilitarProgramacionContainer}>
                <Switch
                    onChange={onChangeSwitch}
                    checked={checkedSwitch}></Switch>
                <Text className={classes.habilitarProgramacionLabel}>
                    Habilitar programación
                </Text>
            </div>
            <Row gutter="20">
                <Col span="10">
                    <Form.Item
                        name="fecha"
                        label="Enviar el"
                        rules={[{ required: true }]}>
                        <DatePicker
                            format={dateFormat}
                            onChange={onChangeDate}
                            disabledDate={disabledDate}
                            disabled={disabled}
                            className={classes.width100}
                        />
                    </Form.Item>
                </Col>
                <Col>
                    <Form.Item
                        name="hora"
                        label="A las"
                        rules={[{ required: true }]}>
                        <TimePicker
                            format={timeFormat}
                            onChange={onChangeTime}
                            disabled={disabled}></TimePicker>
                    </Form.Item>
                </Col>
            </Row>
        </Card>
    );
};

export default ProgramarPush;

ProgramarPush.propTypes = {
    setDate: PropTypes.any,
    setHours: PropTypes.any,
    setChecked: PropTypes.any,
    checkedSwitch: PropTypes.bool,
};
