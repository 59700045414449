import React, { useContext, useEffect, useState } from 'react';
import {
    Menu,
    Table,
    Dropdown,
    Modal,
    Row,
    Col,
    Input,
    Switch,
    Form,
    message,
    Button,
    Tag,
    Divider,
} from 'antd';
import {
    ExclamationCircleOutlined,
    PlusCircleOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import ModalContent from '../../../../atoms/ModalContent';
import Mapa from '../../../../mapa/Mapa';

const DireccionesUsuario = () => {
    //instancia para el form
    const [formModalDireccion] = Form.useForm();
    // datos del context
    const {
        usuarioSeleccionado,
        obtenerDireccionUsuario,
        direccionUsuario,
        editarDireccionUsuario,
        loadingDireccion,
        loadingDireccionEditar,
        loadingDireccionNuevo,
        agregarDireccionUsuario,
        eliminarDireccionUsuario,
        loadingDireccionEliminar,
    } = useContext(UsuariosContext);

    const [openModalEditarDirecciones, setOpenModalEditarDirecciones] =
        useState(false);
    const [openModalDireccionNueva, setOpenModalDireccionNueva] =
        useState(false);
    const [editarDireccion, setIsEditarDireccion] = useState(false);
    const [usuarioDireccion, setUsuarioDireccion] = useState({});
    const [latitudSeleccionada, setLatitudSeleccionada] = useState(undefined);
    const [longitudSeleccionado, setLongitudSeleccionado] = useState(undefined);
    const [errorUbicacion, setErrorUbicacion] = useState(false);

    // extraer id del reducer
    const { id } = usuarioSeleccionado;

    //extraer datos del state
    const {
        name,
        street1,
        street2,
        reference,
        number,
        is_default,
        idDireccion,
        latitude,
        longitude,
    } = usuarioDireccion;

    //obtener direccion del usuario
    useEffect(() => {
        obtenerDireccionUsuario(id);
    }, []);

    useEffect(() => {
        if (latitudSeleccionada && longitudSeleccionado)
            setErrorUbicacion(!(latitudSeleccionada && longitudSeleccionado));
    }, [latitudSeleccionada, longitudSeleccionado]);

    // cargar los datos al modal
    useEffect(() => {
        if (openModalEditarDirecciones) {
            formModalDireccion.setFieldsValue({
                idDireccion,
                nombre: name,
                callePrincipal: street1,
                calleSecundaria: street2,
                nroCasa: number,
                referencia: reference,
                principal: is_default,
                latitud: latitude,
                longitud: longitude,
            });
        } else {
            formModalDireccion.resetFields();
        }
    }, [openModalEditarDirecciones]);

    useEffect(() => {
        if (openModalEditarDirecciones) {
            formModalDireccion.setFieldsValue({
                latitud: latitudSeleccionada,
                longitud: longitudSeleccionado,
            });
        }
    }, [latitudSeleccionada, longitudSeleccionado]);

    useEffect(() => {
        if (openModalDireccionNueva) {
            formModalDireccion.setFieldsValue({
                latitud: latitudSeleccionada,
                longitud: longitudSeleccionado,
            });
        }
    }, [latitudSeleccionada, longitudSeleccionado]);

    useEffect(() => {
        if (openModalDireccionNueva) {
            formModalDireccion.resetFields();
        }
    }, [openModalDireccionNueva]);

    //datos para la tabla
    const direccionTabla = direccionUsuario.map((item) => {
        // desestructurar
        const {
            name,
            is_default,
            street1,
            street2,
            number,
            reference,
            id: idDireccion,
        } = item;
        let address = street1;
        //concatenar las direcciones
        if (street2 && street2.length > 0) {
            address += ` y ${street2}`;
        }
        if (number && number.length > 0) {
            address += ` ${number}`;
        }
        if (reference && reference.length > 0) {
            address += `, ${reference}`;
        }
        return {
            idDireccion,
            principal: is_default,
            nombre: name,
            direccion: address,
            ...item,
        };
    });

    const camposFormDireccion = [
        'nombre',
        'callePrincipal',
        'calleSecundaria',
        'nroCasa',
        'referencia',
        'idDireccion',
        'principal',
        'latitud',
        'longitud',
    ];

    const onFinishEditar = async () => {
        formModalDireccion.getFieldsValue();
        let respuesta = false;
        await formModalDireccion
            .validateFields(camposFormDireccion)
            .then(async (values) => {
                if (editarDireccion) {
                    respuesta = await editarDireccionUsuario(values);
                } else {
                    respuesta = await eliminarDireccionUsuario(idDireccion);
                }
            })
            .catch(() => {
                message.warning('Por favor complete los campos obligatorios.');
            });
        if (respuesta) {
            obtenerDireccionUsuario(id);
            setOpenModalEditarDirecciones(false);
            setIsEditarDireccion(false);
        }
    };

    const onFinishNuevo = async () => {
        formModalDireccion.getFieldsValue();
        let respuesta = false;
        setErrorUbicacion(!(latitudSeleccionada && longitudSeleccionado));

        await formModalDireccion
            .validateFields(camposFormDireccion)
            .then(async (values) => {
                let valoresFormDireccion = {
                    ...values,
                    id,
                    latitud: latitudSeleccionada,
                    longitud: longitudSeleccionado,
                };
                respuesta = await agregarDireccionUsuario(valoresFormDireccion);
            })
            .catch(() => {
                message.warning('Por favor complete los campos obligatorios.');
            });
        if (respuesta) {
            obtenerDireccionUsuario(id);
            setOpenModalDireccionNueva(false);
        }
    };

    // td del nombre de la dirección principal con un check
    const direccionPrincipal = (elm) => {
        return (
            <>
                <span style={{ marginRight: '5px' }}>{elm.name}</span>
                <Tag color="green">Principal</Tag>
            </>
        );
    };

    const openModalEditar = (row) => {
        setUsuarioDireccion(row);
        setOpenModalEditarDirecciones(true);
        setIsEditarDireccion(true);
    };

    const openModalEliminar = (row) => {
        setUsuarioDireccion(row);
        setOpenModalEditarDirecciones(true);
    };

    const closeModalDirecciones = () => {
        setOpenModalEditarDirecciones(false);
        setIsEditarDireccion(false);
    };

    const openDireccionNuevaModal = () => {
        setOpenModalDireccionNueva(true);
    };

    const closeModalDireccionNueva = () => {
        setOpenModalDireccionNueva(false);
    };

    const ModalDireccionNuevo = () => (
        <Modal
            title="Nueva Dirección"
            open={openModalDireccionNueva}
            footer={null}
            closable={null}
            width={800}
            style={{ top: 20 }}
            bodyStyle={{
                paddingTop: 0,
                paddingRight: '24px',
                paddingLeft: '24px',
                paddingBottom: '24px',
            }}>
            <ModalContent
                isLoading={loadingDireccionNuevo}
                cancelText="Cancelar"
                okText="Guardar"
                onOk={onFinishNuevo}
                onCancel={closeModalDireccionNueva}>
                <Form
                    form={formModalDireccion}
                    layout="vertical"
                    validateMessages={{
                        required: 'Este campo es obligatorio.',
                    }}
                    initialValues={{ principal: false }}>
                    <Row gutter={24}>
                        <Col span={10}>
                            <Form.Item
                                label="Nombre"
                                name="nombre"
                                rules={[{ required: true }]}
                                style={{ margin: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Calle principal"
                                name="callePrincipal"
                                rules={[{ required: true }]}
                                style={{ margin: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Calle secundaria"
                                name="calleSecundaria"
                                rules={[{ required: true }]}
                                style={{ margin: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Nro. de casa"
                                name="nroCasa"
                                rules={[{ required: true }]}
                                style={{ margin: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Referencia"
                                name="referencia"
                                rules={[{ required: true }]}
                                style={{ margin: 10 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Dirección principal"
                                name="principal">
                                <Switch />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item>
                                <Mapa
                                    latitud={latitudSeleccionada}
                                    longitud={longitudSeleccionado}
                                    obtenerLatitud={setLatitudSeleccionada}
                                    obtenerLongitud={setLongitudSeleccionado}
                                />
                                <span
                                    style={{ color: 'red', marginTop: '50px' }}>
                                    {errorUbicacion
                                        ? 'Posicione el puntero sobre su ubicación'
                                        : ''}
                                </span>
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'none' }}
                                name="latitud"
                                rules={[{ required: true }]}>
                                <Input type="hidden" />
                            </Form.Item>
                            <Form.Item
                                style={{ display: 'none' }}
                                name="longitud"
                                rules={[{ required: true }]}>
                                <Input type="hidden" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </ModalContent>
        </Modal>
    );

    const ModalDirecciones = () => (
        <Modal
            title={editarDireccion && 'Editar dirección'}
            open={openModalEditarDirecciones}
            footer={null}
            closable={null}
            width={800}
            style={editarDireccion && { top: 20 }}
            bodyStyle={{
                paddingTop: 0,
                paddingRight: '24px',
                paddingLeft: '24px',
                paddingBottom: '24px',
            }}>
            <ModalContent
                isLoading={
                    loadingDireccionEditar
                        ? loadingDireccionEditar
                        : loadingDireccionEliminar
                }
                isDelete={!editarDireccion && true}
                cancelText="Cancelar"
                okText={editarDireccion ? 'Guardar' : 'Eliminar'}
                onOk={onFinishEditar}
                onCancel={closeModalDirecciones}>
                {editarDireccion ? (
                    <Form
                        form={formModalDireccion}
                        layout="vertical"
                        validateMessages={{
                            required: 'Este campo es obligatorio.',
                        }}>
                        <Row gutter={24}>
                            <Col span={10}>
                                <Form.Item
                                    label="Nombre"
                                    name="nombre"
                                    rules={[{ required: true }]}
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Calle principal"
                                    name="callePrincipal"
                                    rules={[{ required: true }]}
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Calle secundaria"
                                    name="calleSecundaria"
                                    rules={[{ required: true }]}
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Nro. de casa"
                                    name="nroCasa"
                                    rules={[{ required: true }]}
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Referencia"
                                    name="referencia"
                                    rules={[{ required: true }]}
                                    style={{ margin: 10 }}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Dirección principal"
                                    name="principal">
                                    {/* eslint-disable-next-line  */}
                                    <Switch
                                        defaultChecked={is_default}
                                        disabled={is_default}
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <div>
                                    <Mapa
                                        latitud={latitude}
                                        longitud={longitude}
                                        obtenerLatitud={setLatitudSeleccionada}
                                        obtenerLongitud={
                                            setLongitudSeleccionado
                                        }
                                    />
                                </div>
                                <Form.Item
                                    style={{ display: 'none' }}
                                    name="latitud">
                                    <Input type="hidden" />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: 'none' }}
                                    name="longitud">
                                    <Input type="hidden" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                ) : (
                    <h3>
                        <ExclamationCircleOutlined
                            style={{
                                color: 'orange',
                                fontSize: '25px',
                                margin: '10px',
                            }}
                        />
                        Está seguro que quiere eliminar esta dirección?
                    </h3>
                )}
            </ModalContent>
        </Modal>
    );

    const dropdownMenu = (row) => (
        <Menu>
            <Menu.Item onClick={() => openModalEditar(row)}>Editar</Menu.Item>
            <>
                {!row.is_default ? (
                    <Menu.Item onClick={() => openModalEliminar(row)}>
                        Eliminar
                    </Menu.Item>
                ) : null}
            </>
        </Menu>
    );

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            render: (_, elm) =>
                elm.is_default ? direccionPrincipal(elm) : elm.name,
        },
        {
            title: 'Dirección',
            dataIndex: 'direccion',
            key: 'direccion',
        },
        {
            title: '',
            dataIndex: 'acciones',
            key: 'acciones',
            render: (_, elm) => (
                <Dropdown overlay={() => dropdownMenu(elm)}>
                    <Button icon={<MoreOutlined />} />
                </Dropdown>
            ),
        },
    ];

    const headerTable = () => {
        return (
            <Row
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    height: '60px',
                }}>
                <Button
                    type="primary"
                    disabled={loadingDireccion}
                    icon={<PlusCircleOutlined />}
                    onClick={openDireccionNuevaModal}>
                    Nueva
                </Button>
                <Divider></Divider>
            </Row>
        );
    };

    return (
        <>
            {openModalEditarDirecciones && ModalDirecciones()}
            {openModalDireccionNueva && ModalDireccionNuevo()}
            <Table
                columns={columns}
                dataSource={direccionTabla}
                loading={loadingDireccion}
                pagination={false}
                title={headerTable}
            />
        </>
    );
};

export default DireccionesUsuario;
