import React from 'react';
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
    Font,
} from '@react-pdf/renderer';
import logo from '../../assets/images/logo-monchis-documentos-2021.png';
import { formatoNumeros, Millones } from '../utils-components/Number';
import moment from 'moment';
import PropTypes from 'prop-types';

const ContenidoFactura = ({ factura, piePagina }) => (
    <View style={{ display: 'flex', marginBottom: 5 }}>
        <View style={{ display: 'flex', flexDirection: 'row' }}>
            {/* Codigo de timbrado o algo asi */}
            <View style={{ justifyContent: 'center' }}>
                <Text style={{ ...styles.timbrado }}>123456789</Text>
            </View>
            {/* Factura */}
            <View
                style={{
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    ...styles.roundedCorners,
                }}>
                {/* Cabecera */}
                <View style={{ display: 'flex', lineHeight: 1.5 }}>
                    <View style={{ ...styles.cabecera }}>
                        {/* Logo Monchis */}
                        <View style={{ width: 100 }}>
                            <Image
                                style={{ ...styles.logoMonchis }}
                                src={logo}
                            />
                        </View>
                        {/* Datos Monchis */}
                        <View style={{ alignItems: 'center' }}>
                            <Text style={{ fontSize: 14 }}>HANOI S.A.</Text>
                            <Text>
                                Analista de sistemas - Programador informatico
                            </Text>
                            <Text>
                                DR BERNARDINO CABALLERO 1270 ENTRE LILLO Y TTE
                                VERA
                            </Text>
                            <Text>Tel: (0976) 328 306</Text>
                            <Text>Asuncion - Paraguay</Text>
                        </View>
                        {/* Datos factura Monchis */}
                        <View>
                            <Text>RUC: {factura?.invoice?.invoice_ruc}</Text>
                            <Text style={styles.titulo2}>
                                Timbrado N° {factura?.stamped?.stamped}
                            </Text>
                            <Text>
                                Válido desde:{' '}
                                {moment(factura?.stamped?.date_from).format(
                                    'DD/MM/YYYY',
                                )}
                            </Text>
                            <Text>
                                Válido hasta:{' '}
                                {moment(factura?.stamped?.date_to).format(
                                    'DD/MM/YYYY',
                                )}
                            </Text>
                            <Text style={styles.titulo2}>FACTURA</Text>
                            <Text>N° {factura?.invoice?.invoice_number}</Text>
                        </View>
                    </View>
                    {/* Datos del cliente */}
                    <View style={{ ...styles.datosCliente }}>
                        <View>
                            <Text>
                                Fecha de Emisión:{' '}
                                {moment(factura?.invoice?.date).format(
                                    'DD/MM/YYYY',
                                )}
                            </Text>
                            <Text>
                                R.U.C: {factura?.franchise_society?.ruc}
                            </Text>
                            <Text>
                                Nombre o Razón Social:{' '}
                                {factura?.franchise_society?.legal_name}
                            </Text>
                            <Text>
                                Direccion: {factura?.franchise_society?.address}
                            </Text>
                        </View>
                        <View>
                            <Text>
                                Condición de venta:{' '}
                                {factura?.invoice?.sale_condition}
                            </Text>
                            <Text>
                                Vendedor: {factura?.stamped?.expedition_point}
                            </Text>
                            <Text>
                                Teléfono: {factura?.franchise_society?.phone}
                            </Text>
                        </View>
                    </View>
                </View>
                {/* Cuerpo, agregar justifyContent: 'space-between' para hacer el cuerpo de la tabla expandible */}
                <View
                    style={{
                        display: 'flex',
                        paddingTop: 5,
                        borderTopColor: 'black',
                        borderTopWidth: 1,
                    }}>
                    {/* Tabla */}
                    <View style={{ display: 'flex', lineHeight: 1.5 }}>
                        {/* Cabecera Tabla, agregar alignItems: 'flex-end' */}
                        <View
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-end',
                                marginBottom: 5,
                            }}>
                            <View style={{ ...styles.tableBodyText }}>
                                <Text>Código</Text>
                            </View>
                            <View style={{ flexGrow: 1, alignItems: 'center' }}>
                                <Text>Descripcion Mercadería</Text>
                            </View>
                            <View style={{ ...styles.tableBodyText }}>
                                <Text>Cantidad</Text>
                            </View>
                            <View style={{ ...styles.tableBodyText }}>
                                <Text>Precio Unitario </Text>
                            </View>
                            <View
                                style={{
                                    width: 180,
                                    display: 'flex',
                                    alignItems: 'center',
                                }}>
                                <View>
                                    <Text>VALOR DE VENTAS</Text>
                                </View>
                                <View
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}>
                                    <View style={{ ...styles.tableBodyText }}>
                                        <Text> Excentas</Text>
                                    </View>
                                    <View style={{ ...styles.tableBodyText }}>
                                        <Text>5%</Text>
                                    </View>
                                    <View style={{ ...styles.tableBodyText }}>
                                        <Text>10%</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                        {/* Cuerpo Tabla, imprimir 4 lineas */}
                        {[0, 1, 2, 3].map((_, index) => (
                            <View key={Math.random()}>
                                {factura?.invoice_details?.length - 1 >=
                                index ? (
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: 5,
                                        }}>
                                        <Text
                                            style={{ ...styles.tableBodyText }}>
                                            {
                                                factura?.invoice_details[index]
                                                    ?.code
                                            }
                                        </Text>
                                        <Text style={{ flexGrow: 1 }}>
                                            {
                                                factura?.invoice_details[index]
                                                    ?.description
                                            }
                                        </Text>
                                        <Text
                                            style={{ ...styles.tableBodyText }}>
                                            {
                                                factura?.invoice_details[index]
                                                    ?.quantity
                                            }
                                        </Text>
                                        <Text
                                            style={{ ...styles.tableBodyText }}>
                                            {factura?.invoice_details[index]
                                                ?.total &&
                                                formatoNumeros(
                                                    factura?.invoice_details[
                                                        index
                                                    ]?.total,
                                                )}
                                        </Text>
                                        <Text
                                            style={{
                                                ...styles.tableBodyText,
                                            }}></Text>
                                        <Text
                                            style={{ ...styles.tableBodyText }}>
                                            {factura?.invoice_details[index]
                                                ?.iva5 &&
                                                formatoNumeros(
                                                    factura?.invoice_details[
                                                        index
                                                    ]?.iva5,
                                                )}
                                        </Text>
                                        <Text
                                            style={{ ...styles.tableBodyText }}>
                                            {factura?.invoice_details[index]
                                                ?.iva10 &&
                                                formatoNumeros(
                                                    factura?.invoice_details[
                                                        index
                                                    ]?.iva10,
                                                )}
                                        </Text>
                                    </View>
                                ) : (
                                    <View
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: 5,
                                        }}>
                                        <Text
                                            style={{ ...styles.tableBodyText }}>
                                            {'‏‏‎ ‎'}
                                        </Text>
                                        {/* <Text style={{flexGrow: 1}}>{'‏‏‎ ‎'}</Text> */}
                                        {/* <Text style={{...styles.tableBodyText}}>{factura?.invoice_details[index]?.quantity}</Text>
                                <Text style={{...styles.tableBodyText}}>{factura?.invoice_details[index]?.total && formatoNumeros(factura?.invoice_details[index]?.total)}</Text>
                                <Text style={{...styles.tableBodyText}}></Text>
                                <Text style={{...styles.tableBodyText}}>{factura?.invoice_details[index]?.iva5 && formatoNumeros(factura?.invoice_details[index]?.iva5)}</Text>
                                <Text style={{...styles.tableBodyText}}>{factura?.invoice_details[index]?.iva10 && formatoNumeros(factura?.invoice_details[index]?.iva10)}</Text> */}
                                    </View>
                                )}
                            </View>
                        ))}
                        {/* Pie Totales */}
                        <View style={{ display: 'flex' }}>
                            <View style={{ ...styles.itemPieTotales }}>
                                <Text>SUB TOTAL</Text>
                                <Text>
                                    {formatoNumeros(factura?.invoice?.amount)}
                                </Text>
                            </View>
                            <View style={{ ...styles.itemPieTotales }}>
                                <Text>
                                    TOTAL {Millones(factura?.invoice?.amount)}
                                </Text>
                                <Text>
                                    {formatoNumeros(factura?.invoice?.amount)}
                                </Text>
                            </View>
                            <View style={{ ...styles.itemPieTotales }}>
                                <Text>LIQUIDACION DEL IVA</Text>
                                <Text>5%</Text>
                                <Text></Text>
                                <Text>10%</Text>
                                <Text>
                                    {factura?.invoice_details &&
                                        formatoNumeros(
                                            factura?.invoice_details.reduce(
                                                (acumulador, detalle) =>
                                                    acumulador +
                                                    Number(detalle.iva10),
                                                0,
                                            ),
                                        )}
                                </Text>
                                <Text>TOTAL IVA</Text>
                                <Text>
                                    {factura?.invoice_details &&
                                        formatoNumeros(
                                            factura?.invoice_details.reduce(
                                                (acumulador, detalle) =>
                                                    acumulador +
                                                    Number(detalle.iva10),
                                                0,
                                            ),
                                        )}
                                </Text>
                            </View>
                            <View
                                style={{
                                    ...styles.itemPieTotales,
                                    display: 'flex',
                                }}>
                                <Text
                                    style={{
                                        width: 540,
                                        fontSize: 5,
                                        textAlign: 'justify',
                                    }}>
                                    Recibí conforme según descripcion de esta
                                    factura y pagare el importe consignado al
                                    vencimineto del plazo señalado. El simple
                                    vencimiento establecerá la mora y devengará
                                    un interés punitorio del ........ mensual,
                                    sin necesidad de protesto. Autorizando
                                    además la inclusión a la base de datos de
                                    IMFORMCONF a lo establecido en la Ley 1682,
                                    también para que se pueda proveer
                                    información a terceros interesados. A
                                    efectos de reclamo judicial, autorizo desde
                                    ya al acreedor a ejecutar este documento a
                                    todos los efectos legales emergentes a esta
                                    obligación quedando sometidos a la
                                    jurisdicción de los tribunales ordinarios de
                                    Asunción.
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </View>
        </View>
        <View>
            <Text
                style={{
                    paddingLeft: 35,
                    fontSize: 6,
                    marginTop: 3,
                    marginBottom: 3,
                }}>
                {piePagina}
            </Text>
        </View>
    </View>
);

ContenidoFactura.propTypes = {
    factura: PropTypes.object,
    piePagina: PropTypes.string,
};

export const ResumenFacturacionPDF = ({ factura, esMonchis }) => {
    const piePaginaMonchis = ['Original', 'Duplicado', 'Triplicado'];
    const piePaginaComercio = ['Original', 'Copia'];
    return (
        <Document>
            {esMonchis ? (
                <>
                    {factura?.length >= 0 ? (
                        <>
                            {factura.map((factura) => (
                                <Page
                                    wrap
                                    size="LEGAL"
                                    style={{ padding: 5, fontSize: 7 }}
                                    key={Math.random()}>
                                    {piePaginaMonchis.map((piePagina) => (
                                        <ContenidoFactura
                                            key={Math.random()}
                                            factura={factura}
                                            piePagina={piePagina}
                                        />
                                    ))}
                                </Page>
                            ))}
                        </>
                    ) : (
                        <Page
                            wrap
                            size="LEGAL"
                            style={{ padding: 5, fontSize: 7 }}>
                            {piePaginaMonchis.map((piePagina) => (
                                <ContenidoFactura
                                    key={Math.random()}
                                    factura={factura}
                                    piePagina={piePagina}
                                />
                            ))}
                        </Page>
                    )}
                </>
            ) : (
                <Page wrap size="LEGAL" style={{ padding: 5, fontSize: 7 }}>
                    <ContenidoFactura
                        factura={factura}
                        piePagina={
                            Number(factura?.invoice?.viewed_count) === 0
                                ? piePaginaComercio[0]
                                : piePaginaComercio[1]
                        }
                    />
                </Page>
            )}
        </Document>
    );
};

ResumenFacturacionPDF.propTypes = {
    factura: PropTypes.any,
    esMonchis: PropTypes.bool,
};

Font.register({
    family: 'MuliRegular',
    fonts: [
        {
            src: '../../assets/fonts/Muli-Regular.ttf',
            fontWeight: 'normal',
        },
        {
            src: '../../assets/fonts/Muli-Bold.ttf',
            fontWeight: 700,
        },
    ],
});

// Estilos para el PDF
const styles = StyleSheet.create({
    logoMonchis: {
        width: 70,
    },
    roundedCorners: {
        borderColor: 'black',
        borderStyle: 'solid',
        borderWidth: 1,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
    },
    tableBodyText: { width: 60, textAlign: 'center' },
    itemPieTotales: {
        borderTopColor: 'black',
        borderTopStyle: 'solid',
        borderTopWidth: 1,
        paddingHorizontal: 5,
        paddingVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    datosCliente: {
        display: 'flex',
        flexDirection: 'row',
        marginHorizontal: 8,
        paddingVertical: 5,
        justifyContent: 'space-between',
    },
    timbrado: {
        transform: 'rotate(-90reg)',
        marginBottom: -15,
        fontSize: 6,
    },
    cabecera: {
        paddingHorizontal: 5,
        paddingVertical: 2,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});
