export const PROMOTIONS_LIST = 'PROMOTIONS_LIST';
export const PROMOTIONS_NEW_OR_EDIT = 'PROMOTIONS_NEW_OR_EDIT';
export const PROMOTIONS_ID = 'PROMOTIONS_ID';
export const PROMOTIONS_ENABLE = 'PROMOTIONS_ENABLE';
export const PROMOTIONS_SHOW_DEACTIVATE_MODAL =
    'PROMOTIONS_SHOW_DEACTIVATE_MODAL';
export const PROMOTIONS_SHOW_ACTIVATE_MODAL = 'PROMOTIONS_SHOW_ACTIVATE_MODAL';
export const PROMOTIONS_CURRENT_PAGE = 'PROMOTIONS_CURRENT_PAGE';
export const PROMOTIONS_FILTERS_TO_LIST = 'PROMOTIONS_FILTERS_TO_LIST';
export const PROMOTIONS_SHOW_ADD_PRODUCT_MODAL =
    'PROMOTIONS_SHOW_ADD_PRODUCT_MODAL';
export const PROMOTIONS_SELECTED_PRODUCTS = 'PROMOTIONS_SELECTED_PRODUCTS';
export const PROMOTIONS_FRANCHISES_SELECTED = 'PROMOTIONS_FRANCHISES_SELECTED';
export const PROMOTIONS_BRANCHES_SELECTED = 'PROMOTIONS_BRANCHES_SELECTED';
export const PROMOTIONS_PRODUCT_LIST = 'PROMOTIONS_PRODUCT_LIST';
export const PROMOTIONS_PRODUCT_LIST_PAGE = 'PROMOTIONS_PRODUCT_LIST_PAGE';
export const PROMOTIONS_AUDIENCE_LIST = 'PROMOTIONS_AUDIENCE_LIST';
export const PROMOTION_CHARACTERISTICS = 'PROMOTION_CHARACTERISTICS';
export const PROMOTIONS_LOADING_PROMOTIONS_LIST =
    'PROMOTIONS_LOADING_PROMOTIONS_LIST';
export const PROMOTIONS_DATA_SOURCE = 'PROMOTIONS_DATA_SOURCE';
export const PROMOTIONS_PAGE_SIZE = 'PROMOTIONS_PAGE_SIZE';
export const PROMOTIONS_TOTAL = 'PROMOTIONS_TOTAL';
export const PROMOTIONS_CREAR_PROMO = 'PROMOTIONS_CREAR_PROMO';
