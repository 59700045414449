import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { notification } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import SupportContext from './SupportContext';
import SupportReducer, { initialState } from './SupportReducer';
import '../../assets/css/styles.css';
import getErrorMessage from '../../components/utils-components/getErrorMessage';

import {
    SUPPORT_ABRIR_MODAL_ESTADO_JEDI,
    SUPPORT_CERRAR_MODAL_ESTADO_JEDI,
    SUPPORT_LOADING_INTEGRACIONES,
    SUPPORT_OBTENER_DETALLES_ESTADO_JEDI,
} from './Types.js';
import { urls } from '../../constants';
import clienteAxios from '../../config/axios';

import { formatoGuaranies } from '../../components/utils-components/Number';

const SupportState = ({ children }) => {
    const [state, dispatch] = useReducer(SupportReducer, initialState);

    //Hook de mixpanel
    const mixpanel = useMixpanel();

    const baseURLIntegracionesJedi =
        urls.REACT_APP_BACKEND_URL_INTEGRATION_JEDI_G;

    // FUNCIONES AUXILIARES

    // Modal de 'Ver Estado'
    const obtenerDatosJedi = async (orderId) => {
        const data = {
            axiosBaseURL: baseURLIntegracionesJedi,
            order_id: orderId,
        };

        try {
            dispatch({
                type: SUPPORT_LOADING_INTEGRACIONES,
            });
            const respuesta = await clienteAxios.post(`/show-order`, data);
            const datosModalVerEstado = respuesta?.data?.data;
            const datosTabla = respuesta?.data?.data?.detalle;
            let datosJEDI = [];
            if (datosTabla?.length) {
                for (let i = 0; i < datosTabla.length; i++) {
                    const datos = {
                        cantidad: datosTabla[i]?.cantidad,
                        producto: datosTabla[i]?.articulo,
                        agregadosJedi: datosTabla[i]?.combos.map(
                            (item) => item?.articulo,
                        ),
                        precioUnitario: formatoGuaranies(datosTabla[i]?.precio),
                        monto: formatoGuaranies(datosTabla[i]?.monto),
                        comentarios: datosTabla[i]?.comentario,
                    };
                    datosJEDI.push(datos);
                }
                dispatch({
                    type: SUPPORT_OBTENER_DETALLES_ESTADO_JEDI,
                    payload: {
                        listaTableEstadoJedi: datosJEDI,
                        datosModalVerEstadoJedi: datosModalVerEstado,
                    },
                });
            } else {
                throw respuesta?.data;
            }
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Ocurrio un error al obtener datos de JEDI',
            );

            notification.error({
                description: errorMessage,
                duration: 0,
            });
            dispatch({
                type: SUPPORT_CERRAR_MODAL_ESTADO_JEDI,
            });
            dispatch({
                type: SUPPORT_LOADING_INTEGRACIONES,
            });
            mixpanel.track('support_error_in_get_jedi_data', {
                error: errorMessage,
            });
        }
    };

    const abrirModalVerEstadoJedi = (ordenSeleccionada) => {
        dispatch({
            type: SUPPORT_ABRIR_MODAL_ESTADO_JEDI,
            payload: {
                ordenSeleccionada,
            },
        });
        obtenerDatosJedi(ordenSeleccionada.id);
    };

    const cerrarModalVerEstadoJedi = () => {
        dispatch({
            type: SUPPORT_CERRAR_MODAL_ESTADO_JEDI,
        });
    };

    return (
        <SupportContext.Provider
            value={{
                modalEstadoJediVisible: state.modalEstadoJediVisible,
                listaTableEstadoJedi: state.listaTableEstadoJedi,
                datosModalVerEstadoJedi: state.datosModalVerEstadoJedi,
                loadingIntegraciones: state.loadingIntegraciones,
                abrirModalVerEstadoJedi,
                cerrarModalVerEstadoJedi,
            }}>
            {children}
        </SupportContext.Provider>
    );
};

export default SupportState;

SupportState.propTypes = {
    children: PropTypes.node,
};
