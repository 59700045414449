import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'antd';
import UsuariosContext from '../../../../../context/usuario/UsuariosContext';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import UtilidadesContext from '../../../../../context/utilidades/UtilidadesContext';
import { useVerificarPermiso } from '../../../../hooks/useVerificarPermiso';
import { Permisos } from '../../../../../constants/Permisos';

const ModalCondiciones = ({ datosCupon }) => {
    const {
        isModalVisible,
        abrirModalCondicionesCupones,
        obtenerListaTarjetas,
        listaTarjetas,
        infoUsuario,
    } = useContext(UsuariosContext);
    const { tienePermiso, esUsuarioComercio } = useVerificarPermiso();
    const {
        obtenerListaTiposPago,
        listaTiposPago,
        obtenerCiudades,
        obtenerFamilias,
    } = useContext(UtilidadesContext);
    const [pagoConBancard, setPagoConBancard] = useState(false);
    const [ciudades, setCiudades] = useState([]);
    const [familias, setFamilias] = useState([]);
    const [tiposDePago, setTiposDePago] = useState([]);
    const idUsuario = infoUsuario?.infoUsuario?.id;

    const separarStringDeCondiciones = () => {
        const condicion1 = datosCupon?.condiciones[0].split(' - ')[0];
        const condicion2 = datosCupon?.condiciones[0].split(' - ')[1];
        return [condicion1, condicion2];
    };

    const filtrarCiudades = () => {
        let arrayCiudades = [];
        ciudades?.forEach((ciudad) => {
            datosCupon?.detalles?.forEach((detalle) => {
                detalle?.city_id === parseInt(ciudad?.id) &&
                    arrayCiudades.push(ciudad.name);
            });
        });
        return arrayCiudades;
    };

    const filtrarFamilia = () => {
        let arrayFamilias = [];
        familias?.forEach((familia) => {
            datosCupon?.detalles?.forEach((detalle) => {
                detalle?.franchise_family_id === parseInt(familia?.id) &&
                    arrayFamilias.push(familia.name);
            });
        });
        return arrayFamilias;
    };

    const ciudadesYFamilias = () => {
        const arrayCiudadesYFamilias = [];
        filtrarCiudades()?.forEach((ciudadFiltrada) => {
            filtrarFamilia()?.forEach((familiaFiltrada) => {
                arrayCiudadesYFamilias.push({
                    ciudad: ciudadFiltrada,
                    familia: familiaFiltrada,
                });
            });
        });
        return arrayCiudadesYFamilias;
    };

    const filtrarPagos = () => {
        let arrayPagos = [];
        tiposDePago?.forEach((pago) => {
            datosCupon?.detalles?.forEach((detalle) => {
                detalle?.payment_type_id === parseInt(pago?.id) &&
                    arrayPagos.push(pago?.slug);
            });
        });
        return arrayPagos;
    };

    const pagosQueNoSeanBancard = () => {
        const arrayPagosNoBancard = [];
        filtrarPagos()?.forEach(
            (pago) =>
                pago !== 'Pago Online Bancard' &&
                arrayPagosNoBancard.push(pago),
        );
        return arrayPagosNoBancard;
    };

    const condiciones = {
        puede_ver_formas_de_pago:
            !esUsuarioComercio() &&
            tienePermiso(
                Permisos.finanzas.facturacion.balance.verOpcionesPagoOnline,
            ),
        tiene_monto_minimo: datosCupon?.monto_minimo !== 0,
        tiene_delivery_gratis: datosCupon?.delivery_gratis,
        es_autoaplicable: datosCupon?.auto_aplicable,
        ciudades_y_familias_tienen_contenido:
            filtrarCiudades()?.length > 0 && filtrarFamilia()?.length > 0,
        no_es_comercio_y_puede_ver_pagos_online:
            !esUsuarioComercio() &&
            tienePermiso(
                Permisos.finanzas.facturacion.balance.verOpcionesPagoOnline,
            ),
    };

    const espaciado = () => {
        return (
            <>
                <br />
                <br />
            </>
        );
    };

    useEffect(() => {
        /**
         * Al obtener la lista de tipos de pagos y de tarjetas, estas se guardan en las variables listaTiposPago y listaTarjetas.
         */
        tiposDePago.length === 0 &&
            obtenerListaTiposPago(
                esUsuarioComercio(),
                tienePermiso(
                    Permisos.finanzas.facturacion.balance.verOpcionesPagoOnline,
                ),
            ).then(() => setTiposDePago(listaTiposPago));
        ciudades.length === 0 &&
            obtenerCiudades().then((ciudades) => setCiudades(ciudades));
        familias.length === 0 &&
            obtenerFamilias().then((familias) => setFamilias(familias));
        listaTarjetas.length === 0 &&
            idUsuario &&
            obtenerListaTarjetas(idUsuario);

        /**
         * Filtramos los pagos para saber si existe la opción de Bancard dentro de ella
         */
        const pagoConBancardHabilitado = filtrarPagos().includes(
            'Pago Online Bancard',
        );
        setPagoConBancard(pagoConBancardHabilitado);
    }, [
        tiposDePago,
        ciudades,
        familias,
        listaTarjetas,
        datosCupon,
        esUsuarioComercio,
        tienePermiso,
        idUsuario,
    ]);

    return (
        <Modal
            title="Condiciones de uso del cupón"
            open={isModalVisible}
            footer={null}
            onCancel={() => abrirModalCondicionesCupones()}>
            <span className={styles.modalTitulos}>
                Código: {datosCupon?.codigo}
            </span>
            <ul>
                {datosCupon?.condiciones && (
                    <li>{separarStringDeCondiciones()[0]}</li>
                )}
                {datosCupon?.condiciones && (
                    <li>{separarStringDeCondiciones()[1]}</li>
                )}
                {condiciones.tiene_monto_minimo && (
                    <li>Monto mínimo de uso: {datosCupon?.monto_minimo} Gs.</li>
                )}
                {condiciones.tiene_delivery_gratis && <li>Delivery Gratis</li>}
                {condiciones.es_autoaplicable && <li>Auto Aplicable</li>}

                {/**
                 * Sección de ciudades y familias habilitadas para el pago
                 */}
                {condiciones.ciudades_y_familias_tienen_contenido &&
                    espaciado()}
                {condiciones.ciudades_y_familias_tienen_contenido && (
                    <span className={styles.modalTitulos}>Usar en</span>
                )}
                {condiciones.ciudades_y_familias_tienen_contenido && (
                    <>
                        {ciudadesYFamilias()?.map((data, index) => {
                            return (
                                <li key={index}>
                                    Ciudad: {data.ciudad} - Familia:{' '}
                                    {data.familia}
                                </li>
                            );
                        })}
                    </>
                )}

                {/**
                 * Sección de tipos de pagos
                 */}
                {(pagoConBancard || filtrarPagos()?.length > 0) && espaciado()}
                {condiciones.no_es_comercio_y_puede_ver_pagos_online &&
                filtrarPagos()?.length > 0 ? (
                    <span className={styles.modalTitulos}>
                        Opciones de pagos online
                    </span>
                ) : (
                    <li>Pago Online</li>
                )}
                {condiciones.no_es_comercio_y_puede_ver_pagos_online && (
                    <>
                        {pagosQueNoSeanBancard()?.map((pago, index) => {
                            return <li key={index}>{pago}</li>;
                        })}
                    </>
                )}
                {condiciones.no_es_comercio_y_puede_ver_pagos_online &&
                    pagoConBancard && (
                        <>
                            <br />
                            <li>BANCARD: </li>
                        </>
                    )}
                {condiciones.no_es_comercio_y_puede_ver_pagos_online &&
                    pagoConBancard && (
                        <>
                            {listaTarjetas?.map((tarjeta, index) => {
                                return (
                                    <li className={styles.tarjetas} key={index}>
                                        {tarjeta?.card_brand}
                                    </li>
                                );
                            })}
                        </>
                    )}
            </ul>
        </Modal>
    );
};

ModalCondiciones.propTypes = {
    datosCupon: PropTypes.object,
};

export default ModalCondiciones;
