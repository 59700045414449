import React from 'react';
import { Card, Col, Row, Typography } from 'antd';
import calendario from '../../../../assets/svg/support/calendario.svg';
import PropTypes from 'prop-types';
import clases from './analiticas.module.css';

const { Text } = Typography;

const CardDias = ({ datosDiasMayorCompra }) => {
    return (
        <Card className={clases.cardDias}>
            <div className={clases.divImg}>
                <img src={calendario} className={clases.imgCalendario} />
            </div>
            <div className={clases.topDirecciones}>
                {datosDiasMayorCompra?.length &&
                    datosDiasMayorCompra.map(
                        (item, index) =>
                            index <= 2 && (
                                <Row key={index} className={clases.dias}>
                                    <Col>
                                        <Text strong>{`${item?.dia}:`}</Text>
                                    </Col>
                                    <Col>
                                        <span>{`${item?.total} ${
                                            item?.total > 1
                                                ? 'pedidos'
                                                : 'pedido'
                                        }`}</span>
                                    </Col>
                                </Row>
                            ),
                    )}
            </div>
        </Card>
    );
};

export default CardDias;

CardDias.propTypes = {
    datosDiasMayorCompra: PropTypes.array,
};
