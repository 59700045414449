import { usePapaParse } from 'react-papaparse';

const convertArrayToObject = (jsonData) => {
    return jsonData
        .map((csvLine) => {
            // skip header and empty lines
            const isEmptyLine = csvLine.every((value) => value.length === 0);
            if (isEmptyLine) {
                return null;
            } else {
                return csvLine.reduce(
                    (acc, value, index) => ({
                        ...acc,
                        [jsonData[0][index]]: value,
                    }),
                    {},
                );
            }
        })
        .filter((filter) => !!filter); // filter empty lines
};

// Read the csv data and transform it into JSON format
const useParseCsvToJson = () => {
    const { readString } = usePapaParse();
    const convertCsvToJson = (file) => {
        return new Promise((resolve, reject) => {
            readString(file, {
                worker: true,
                complete: (results) => {
                    // Extract data
                    const jsonData = results.data;
                    // Transform the data into an object
                    const arrayToObject = convertArrayToObject(jsonData);
                    // Resolve object
                    resolve(arrayToObject);
                },
                skipEmptyLines: true,
                error: (error) => reject(error),
            });
        });
    };
    return { convertCsvToJson };
};

export default useParseCsvToJson;
