import { Form, Input, Button, Layout, Image, Card, message } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';

import Avatar from 'antd/lib/avatar/avatar';

import AuthContext from '../../../context/autenticacion/authContext';

import './index.module.css';
import { Redirect } from 'react-router-dom';
import { setAuthToken } from '../../../config/axios';

const { Header, Footer, Content } = Layout;

const LoginScreen = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    //extraer los valores del context
    const authContext = useContext(AuthContext);
    const { token, iniciarSesion, error } = authContext;

    //state para iniciar sesion
    const [usuarioForm, setUsuarioForm] = useState({
        username: '',
        password: '',
    });
    // extraer los valores de usuario
    const { username, password } = usuarioForm;

    // guardar en el state
    const onChange = (e) => {
        setUsuarioForm({
            ...usuarioForm,
            [e.target.name]: e.target.value,
        });
    };

    // cuando el usuario quiere iniciar sesion
    const onSubmit = () => {
        setLoading(true);

        // pasar al action
        iniciarSesion({ username, password });
    };

    const key = 'updatable';
    useEffect(() => {
        if (error) {
            setLoading(false);
            message.error({ content: error, key, duration: 3 });
        }
    }, [error]);

    let redireccion = null;
    if (token) {
        setAuthToken(token);
        redireccion = <Redirect to="/panel" />;
    }

    return (
        <Layout
            style={{
                width: '50%',
                margin: 'auto',
                marginTop: 20,
                borderRadius: '20px',
                boxShadow: '3px 4px #ccc',
            }}>
            <Header
                style={{
                    padding: 0,
                    paddingLeft: 5,
                    paddingRight: 5,
                    textAlign: 'center',
                    color: '#fff',
                    borderRadius: '10px',
                }}>
                <div>
                    <h1 style={{ display: 'block', color: '#fff' }}>
                        ¡Acceda con su cuenta{' '}
                        <strong>
                            MONCHIS!{' '}
                            <Avatar
                                size={40}
                                icon={<Image src="/img/logo.png" />}
                            />{' '}
                        </strong>
                    </h1>
                </div>
            </Header>
            <Content style={{ padding: 0, textAlign: 'center' }}>
                <Card size="small">
                    <Form
                        name="normalLogin"
                        form={form}
                        className="login-form"
                        style={{
                            padding: 20,
                            border: '1px solid #ccc',
                            width: '100%',
                        }}
                        onFinish={onSubmit}>
                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: 'Ingrese el nombre de usuario!',
                                },
                            ]}>
                            <Input
                                prefix={
                                    <UserOutlined className="site-form-item-icon" />
                                }
                                size="large"
                                disabled={loading}
                                onChange={(evt) => onChange(evt)}
                                placeholder="Username"
                                name="username"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: 'Por favor, ingrese su Password!',
                                },
                            ]}>
                            <Input
                                prefix={
                                    <LockOutlined className="site-form-item-icon" />
                                }
                                type="password"
                                size="large"
                                disabled={loading}
                                onChange={(evt) => onChange(evt)}
                                placeholder="Password"
                                name="password"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                className="login-form-button">
                                Ingresar
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </Content>
            <Footer
                style={{
                    borderRadius: '20px',
                }}>
                <strong style={{ float: 'right' }}>©Monchis INC</strong>
            </Footer>

            {redireccion}
        </Layout>
    );
};

export default LoginScreen;
