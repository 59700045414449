import React, { useReducer } from 'react';
import ReporteCuponesContext from './ReporteCuponesContext';
import PropTypes from 'prop-types';
import { urls } from '../../../constants';
import clienteAxios from '../../../config/axios';
import ReporteComisionesReducer, {
    initialState,
} from './ReporteCuponesReducer';
import {
    LISTADO_REPORTE_CUPONES,
    PAGINADO_REPORTE_CUPONES,
    REPORTE_CUPONES_LOADING,
    REPORTE_CUPONES_TOTALES,
    REPORTE_CUPONES_RESUMEN,
    REPORTE_CUPON_SELECCIONADO,
} from './types';
import { message } from 'antd';

const baseURL = urls.REACT_APP_BACKEND_URL_F;

const ReporteCuponesState = ({ children }) => {
    const [state, dispatch] = useReducer(
        ReporteComisionesReducer,
        initialState,
    );

    const obtenerCupones = async (datos) => {
        dispatch({
            type: REPORTE_CUPONES_LOADING,
        });
        try {
            const { data } = await clienteAxios.get('/discounts', {
                params: datos,
                baseURL,
            });
            dispatch({
                type: LISTADO_REPORTE_CUPONES,
                payload: { listaReporteCupones: data?.data?.discounts },
            });
            dispatch({
                type: REPORTE_CUPONES_TOTALES,
                payload: { totalReporte: data?.data?.count },
            });
            dispatch({
                type: REPORTE_CUPONES_RESUMEN,
                payload: { resumenReporte: data?.data?.totales },
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'hubo un error al obtener los datos',
            );
            dispatch({
                type: REPORTE_CUPONES_LOADING,
            });
        }
        dispatch({
            type: REPORTE_CUPONES_LOADING,
        });
    };

    const cuponSeleccionado = (cuponSeleccionado) => {
        dispatch({
            type: REPORTE_CUPON_SELECCIONADO,
            payload: { reporteSeleccionado: cuponSeleccionado },
        });
    };

    const seleccionarPaginadoTabla = async (page) => {
        dispatch({
            type: PAGINADO_REPORTE_CUPONES,
            payload: { paginaActualReporte: page },
        });
    };

    return (
        <ReporteCuponesContext.Provider
            value={{
                obtenerCupones,
                cuponSeleccionado,
                seleccionarPaginadoTabla,

                reporteSeleccionado: state.reporteSeleccionado,
                listaReporteCupones: state.listaReporteCupones,
                totalReporte: state.totalReporte,
                resumenReporte: state.resumenReporte,
                paginaActualReporte: state.paginaActualReporte,
                loading: state.loading,
            }}>
            {children}
        </ReporteCuponesContext.Provider>
    );
};

export default ReporteCuponesState;

ReporteCuponesState.propTypes = {
    children: PropTypes.node,
};
