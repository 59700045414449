import React from 'react';
import classes from './InfoBar.module.css';
import { SyncOutlined } from '@ant-design/icons';

const InfoBar = (props) => {
    return (
        <section className={classes.infoBarSection}>
            {props.data.map((data) => {
                return (
                    <div key={data.id}>
                        <span>{data.value}</span>
                        <h5>{data.label}</h5>
                    </div>
                );
            })}

            <div className={classes.sincronizarContainer}>
                <SyncOutlined style={{ color: '#1890FF' }} />
                <h5 style={{ color: '#1890FF' }}>Sincronizar</h5>
            </div>
        </section>
    );
};

export default InfoBar;
