import React, { useReducer } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';

import TemporadasContext from './TemporadasContext';
import TemporadasReducer, { initialState } from './TemporadasReducer';

import axios from '../../../config/axios';
import { urls } from '../../../constants';

import {
    SEASONS_LOADING,
    SEASONS_CURRENT_SEASON,
    SEASONS_LIST_SEASONS,
} from './types';

const svUserBaseURL = urls.REACT_APP_BACKEND_URL_E;

const TemporadasState = ({ children }) => {
    const [state, dispatch] = useReducer(TemporadasReducer, initialState);

    /**
     * It makes an API call to get a list of seasons, and then dispatches an action to update the state
     * with the list of seasons
     * @param currentSeason - boolean - Weather to get the current season or all seasons
     */
    const getSeasonsList = () => {
        dispatch({
            type: SEASONS_LOADING,
            payload: {
                loading: true,
            },
        });

        const paramsAllSeasons = {
            itemsPerPage: 200,
        };

        axios
            .get('season', { params: paramsAllSeasons, baseURL: svUserBaseURL })
            .then((response) => {
                const allSeasonsList = response?.data?.data?.season || [];

                dispatch({
                    type: SEASONS_LIST_SEASONS,
                    payload: {
                        seasonsList: allSeasonsList,
                    },
                });
            })
            .catch((error) => {
                const parsedError =
                    error?.response?.data?.message ||
                    error?.message ||
                    error ||
                    'Hubo un problema al obtener la lista de temporadas';
                message.error(parsedError);
            })
            .finally(() => {
                // Get current season

                const paramsCurrentSeason = {
                    currentOnly: true,
                };

                axios
                    .get('season', {
                        params: paramsCurrentSeason,
                        baseURL: svUserBaseURL,
                    })
                    .then((response) => {
                        const currentSeasonsList =
                            response?.data?.data?.season || [];

                        dispatch({
                            type: SEASONS_CURRENT_SEASON,
                            payload: {
                                seasonsList: currentSeasonsList,
                            },
                        });
                    })
                    .catch((error) => {
                        const parsedError =
                            error?.response?.data?.message ||
                            error?.message ||
                            error ||
                            'Hubo un problema al obtener la temporada actual';
                        message.error(parsedError);
                    })
                    .finally(() => {
                        dispatch({
                            type: SEASONS_LOADING,
                            payload: {
                                loading: false,
                            },
                        });
                    });
            });
    };

    /**
     * It creates a new season, and if it succeeds, it tries to assign user levels to the season
     * @param data - object with attributes to create a season
     */
    const newSeason = (data, onFormReset) => {
        dispatch({
            type: SEASONS_LOADING,
            payload: {
                loading: true,
            },
        });

        const newSeasonData = {
            ...data,
            user_levels: undefined,
        };
        const userLevels = data?.user_levels;

        const userLevelsHasData = Boolean(userLevels?.length);
        if (!userLevelsHasData)
            throw 'No se ha recibido una lista de niveles de usuario';

        axios
            .post('season', newSeasonData, { baseURL: svUserBaseURL })
            .then((response) => {
                const seasonId = response?.data?.data?.id;
                if (!seasonId) {
                    throw 'No se ha recibido el identificador de la temporada creada';
                }

                Promise.allSettled(
                    assignUserLevelToSeason(seasonId, userLevels),
                ).finally(() => {
                    getSeasonsList();
                    onFormReset();
                    message.success(
                        response?.data?.message ||
                            'Temporada creada correctamente',
                    );
                });
            })
            .catch((error) => {
                const parsedError =
                    error?.response?.data?.message ||
                    error?.message ||
                    error ||
                    'Hubo un problema al crear la temporada';
                message.error(parsedError);
                dispatch({
                    type: SEASONS_LOADING,
                    payload: {
                        loading: false,
                    },
                });
            });
    };

    /**
     * It updates a season, and if the season is enabled, it assigns and removes user levels to the
     * season
     * @param data - The data to be sent to the server.
     */
    const updateSeason = async (data, onFormReset) => {
        dispatch({
            type: SEASONS_LOADING,
            payload: {
                loading: true,
            },
        });

        const updateSeasonData = {
            ...data,
            user_levels: undefined,
            original_user_levels: undefined,
        };

        axios
            .put(`season/${updateSeasonData?.id}`, updateSeasonData, {
                baseURL: svUserBaseURL,
            })
            .then((response) => {
                const seasonId = data?.id;
                const currentUserLevels = data?.user_levels || [];
                const originalUserLevels = data?.original_user_levels || [];

                const userLevelsToAdd = currentUserLevels.filter(
                    (item) => !originalUserLevels.includes(item),
                );
                const userLevelsToRemove = originalUserLevels.filter(
                    (item) => !currentUserLevels.includes(item),
                );

                const userLevelsToAddHasData = Boolean(userLevelsToAdd?.length);
                const userLevelsToRemoveHasData = Boolean(
                    originalUserLevels?.length,
                );

                let promisesArray = [];
                if (userLevelsToAddHasData)
                    promisesArray.push(
                        ...assignUserLevelToSeason(seasonId, userLevelsToAdd),
                    );
                if (userLevelsToRemoveHasData)
                    promisesArray.push(
                        ...removeUserLevelToSeason(
                            seasonId,
                            userLevelsToRemove,
                        ),
                    );

                Promise.allSettled(promisesArray).finally(() => {
                    getSeasonsList();
                    onFormReset();
                    message.success(
                        response?.data?.message ||
                            'Temporada actualizada correctamente',
                    );
                });
            })
            .catch((error) => {
                const parsedError =
                    error?.response?.data?.message ||
                    error?.message ||
                    error ||
                    'Hubo un problema al crear la temporada';
                message.error(parsedError);
                dispatch({
                    type: SEASONS_LOADING,
                    payload: {
                        loading: false,
                    },
                });
            });
    };

    /**
     * It takes a seasonId and a list of userLevels and associates the userLevels to the season
     * @param seasonId - The id of the season you want to assign the user levels to.
     * @param userLevelList - Array of user levels to be assigned to the season.
     * @returns A promise that resolves when all the promises in the array have resolved.
     */
    const assignUserLevelToSeason = (seasonId, userLevelList) => {
        const userLevelListHasData = Boolean(userLevelList?.length);
        if (!parseInt(seasonId) && !userLevelListHasData) {
            throw 'Faltan datos para poder asociar los niveles de usuario a la temporada';
        }

        const promisesArr = userLevelList.map((userLevel) => {
            const promise = axios
                .post(`/season/${seasonId}/user_level/${userLevel}`, null, {
                    baseURL: svUserBaseURL,
                })
                .then(() => {})
                .catch((error) => {
                    const parsedError =
                        error?.response?.data?.message ||
                        error?.message ||
                        error ||
                        'Hubo un problema al agregar niveles de usuario a la temporada';
                    throw parsedError;
                });
            return promise;
        });

        return promisesArr;
    };

    /**
     * It removes user levels from a season
     * @param seasonId - The id of the season you want to remove the user levels from.
     * @param userLevels - Array of user levels to be removed from the season.
     * @returns A promise that resolves to an array of promises.
     */
    const removeUserLevelToSeason = (seasonId, userLevels) => {
        if (!parseInt(seasonId) && !userLevels?.length) {
            throw 'Faltan datos para poder remover los niveles de usuario de la temporada';
        }
        const promisesArr = userLevels.map((userLevel) => {
            const promise = axios
                .delete(`/season/${seasonId}/user_level/${userLevel}`, {
                    baseURL: svUserBaseURL,
                })
                .then(() => {})
                .catch((error) => {
                    const parsedError =
                        error?.response?.data?.message ||
                        error?.message ||
                        error ||
                        'Hubo un problema al remover niveles de usuario de la temporada';
                    throw parsedError;
                });
            return promise;
        });

        return promisesArr;
    };

    return (
        <TemporadasContext.Provider
            value={{
                getSeasonsList,
                newSeason,
                updateSeason,

                loading: state.loading,
                currentSeason: state.currentSeason,
                seasonsList: state.seasonsList,
            }}>
            {children}
        </TemporadasContext.Provider>
    );
};

export default TemporadasState;

TemporadasState.propTypes = {
    children: PropTypes.node,
};
