import { useState, useEffect, useContext } from 'react';
import UtilidadesContext from '../../../../context/utilidades/UtilidadesContext';
import { Form } from 'antd';

export const useGetFilters = () => {
    const [franchises, setFranchises] = useState([]);
    const [franchiseSelected, setFranchiseSelected] = useState(null);
    const [branches, setBranches] = useState([]);
    const [form] = Form.useForm();

    const { obtenerFranquicias, obtenerSucursales } =
        useContext(UtilidadesContext);

    useEffect(() => {
        if (franchiseSelected === 'all') {
            form.resetFields(['branch']);
            branches.length && setBranches([]);
        }
    }, [franchiseSelected, branches.length, form]);

    useEffect(() => {
        if (!franchises.length) {
            obtenerFranquicias().then((response) => setFranchises(response));
        }
    }, [obtenerFranquicias, franchises.length]);

    useEffect(() => {
        if (franchiseSelected && franchiseSelected !== 'all') {
            obtenerSucursales(franchiseSelected).then((response) =>
                setBranches(response),
            );
        }
    }, [franchiseSelected, obtenerSucursales]);

    return {
        franchises,
        branches,
        form,

        setFranchiseSelected,
    };
};
