import { useCallback, useContext, useRef, useState } from 'react';

import authContext from '../context/autenticacion/authContext';
import { urls } from '../constants';

const serverPath = urls.REACT_APP_BACKEND_URL_WS_SUPPORT;
// const tiempoValidacionReconexionSocket = 1000 * 60 * 10; // Diez minutos en milisegundos
// const socketReadyState = {
//     CONNECTING: 0,
//     OPEN: 1,
//     CLOSING: 2,
//     CLOSED: 3,
// };

const useSocket = () => {
    const socketRef = useRef();
    // const [extraReconnectActions, setExtraReconnectActions] = useState();
    const [willReconnectSocket, setWillReconnectSocket] = useState(false);

    const { token } = useContext(authContext);

    const disconnectSocket = useCallback(() => {
        socketRef.current?.close();
    }, []);

    const connectSocket = useCallback(
        (willReconnect, onMessage, onError) => {
            if (token) {
                try {
                    socketRef.current = new WebSocket(
                        `${serverPath}/?token=${token}`,
                    );

                    socketRef.current.onmessage = (message) => {
                        onMessage(message);
                    };

                    socketRef.current.onerror = (error) => {
                        onError(error);
                    };

                    if (willReconnect) {
                        setWillReconnectSocket(willReconnect);
                    }
                } catch (error) {
                    disconnectSocket();
                    throw error;
                }
            } else {
                disconnectSocket();
                throw 'Falló la autenticación del usuario';
            }
        },
        [token, disconnectSocket],
    );

    // const reopenSocket = useCallback(
    //     (extraActions) => {
    //         const socketState = socketRef.current?.readyState;
    //         if (
    //             socketState !== socketReadyState.CONNECTING ||
    //             socketState !== socketReadyState.OPEN
    //         ) {
    //             if (!socketRef) return;

    //             connectSocket();

    //             if (!extraActions) return;

    //             extraActions();
    //         }
    //     },
    //     [connectSocket],
    // );

    // const reconnectSocket = useCallback(
    //     (extraActions) => {
    //         reopenSocket(() => {
    //             const forzarReconexion = setInterval(() => {
    //                 reopenSocket(extraActions);
    //             }, tiempoValidacionReconexionSocket);

    //             return () => {
    //                 clearInterval(forzarReconexion);
    //                 disconnectSocket();
    //             };
    //         });
    //     },
    //     [reopenSocket, disconnectSocket],
    // );

    const updateExtraReconnectActions = (extraActions) => {
        if (typeof extraActions !== 'function') return;

        // setExtraReconnectActions(extraActions);
    };

    const updateReconnectStatus = (status) => {
        willReconnectSocket(status);
    };

    return {
        socket: socketRef,
        connectSocket,
        disconnectSocket,
        updateExtraReconnectActions,
        updateReconnectStatus,
    };
};

export default useSocket;
