import React, { useEffect } from 'react';

import useMigrarCredenciales from '../../hooks/auth/useMigrarCredenciales';
import { Redirect } from 'react-router-dom';
import { Typography } from 'antd';
import AuthCard from '../../molecules/auth/authCard/index';

const MigrarCredenciales = () => {
    const { redirectURL, error, getRedirectURL } = useMigrarCredenciales();

    useEffect(() => {
        getRedirectURL();
    }, [getRedirectURL]);

    if (!redirectURL) {
        return (
            <AuthCard loading={false}>
                <Typography.Text>
                    {error || 'Migrando credenciales ...'}
                </Typography.Text>
            </AuthCard>
        );
    }

    console.log(redirectURL);

    return <Redirect to={redirectURL} />;
};

export default MigrarCredenciales;
