export const Permisos = {
    panel: 'new.admin.view.home',
    finanzas: {
        verFinanzas: 'new.admin.view',
        facturacion: {
            verFacturacion: 'new.admin.view',
            balance: {
                verPagina: 'new.admin.view.balance',
                generarArchivo: 'new.admin.view.balance.generar.archivo',
                hacerCorte: '', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
                hacerCorteEspecial: '', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
                verOpcionesPagoOnline: 'commissions.report',
            },
            emisionDocumentos: {
                verPagina: 'new.admin.view.create.invoice',
                facturas: 'new.admin.view.create.invoice',
                recibos: 'new.admin.view.create.invoice',
            },
            timbrado: 'new.admin.view.stamped',
            misDocumentos: 'new.admin.view.invoice',
            listaRecibos: 'new.admin.view.receipts',
        },
    },
    reportes: {
        verReportes: 'new.admin.view.transaccion.detail',
        ventas: {
            verVentas: 'new.admin.view.transaccion.detail',
            detalleTransaccion: {
                verPagina: 'new.admin.view.transaccion.detail',
                columnasDetalleDescuento: 'commissions.report',
                botonDescargarCancelados: 'commissions.report',
                botonDescargarNoProcesadas: 'commissions.report',
                verOpcionesPagoOnline: 'commissions.report',
            },
            reporteBalance: {
                verPagina: 'new.admin.view.balance.report',
                generarArchivo: 'new.admin.view.balance.generar.archivo',
                cargarPago: 'new.admin.view.balance.report.cargar.pago',
                verPDF: '', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
                verOpcionesPagoOnline: 'commissions.report',
            },
            reporteComisiones: { verPagina: 'new.admin.view.commissions' },
            detalleComisiones: { verPagina: 'new.admin.view' },
        },
        cortes: {
            verCortes: 'new.admin.view.transaccion.cut',
            corteTransaccion: 'new.admin.view.transaccion.cut',
        },
        programaRecompensa: {
            verReporteCupones: 'discount.code.report',
        },
        ordenes: {
            verOrdenes: 'view.order.report.new.admin',
            cancelOrder: 'order.cancel',
        },
    },
    push: {
        verPush: 'new.admin.view.send.push',
        enviarPush: 'new.admin.view.send.push',
        listaPush: 'new.admin.view.send.push',
    },
    programaRecompensa: {
        verProgramaRecompensa: 'new.admin.view.coupons',
        cupones: {
            verCupones: 'new.admin.view.coupons',
            usoCupones: '', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
            listaCupones: 'new.admin.view.coupons',
        },
        nivelUsuario: {
            listaNiveles: 'new.admin.view.user.levels',
        },
        promociones: {
            crearEditarBanner: 'new.admin.promotion.created|editar',
            habilitarDeshabilitarBanner: 'new.admin.promotion.ban|unban',
            eliminarBanner: 'new.admin.promotion.delete',
            listarBanners: 'new.admin.promotion.list',
        },
        temporadas: {
            listarTemporadas: 'new.admin.main.season',
            crearEditarTemporada: 'new.admin.season.create|edit',
            eliminarTemporada: 'new.admin.season.deleted',
        },
    },
    pagoOnline: {
        tarjetas: {
            listaUsuarios: 'new.admin.view.credit.card',
            removerTarjeta: 'view.and.delete.cards',
        },
    },
    usuarios: {
        verUsuarios: 'new.admin.view.user.address',
        listaUsuarios: {
            verPagina: 'new.admin.view.user.address',
            cuenta: '', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
            direcciones: '', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
            tarjetas: {
                listaUsuarios: 'new.admin.view.credit.card',
                eliminarTarjeta: 'view.and.delete.cards',
            },
            verOpcionesPagoOnline: 'commissions.report',
        },
        clientes: {
            verPagina: 'client.confirm_phone',
        },
    },
    administrar: {
        verAdministrar: '',
        franquicias: {
            verPagina: 'new.admin.view.franchises',
        },
        catastros: {
            verPagina: 'new.admin.view.cadastre',
        },
    },
    support: {
        verPerfiles: 'new.admin.support.view.set.profile',
        verificarDireccion: 'new.admin.verify.address',
        verSupport: 'order.newinbox',
    },
    cambiarA: 'new.admin.view', // TODO: Ver con el equipo de backend si sera necesario agregar este permiso
};
