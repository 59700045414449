import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListaFranquicias from './ListaFranquicias';
import NuevoFranquicias from './NuevaFranquicias';
import EditarFranquicias from './EditarFranquicias';
import PropTypes from 'prop-types';
import { Permisos } from '../../../../constants/Permisos';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';

const Franquicias = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista-franquicias`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.administrar.franquicias.verPagina}
                        componente={ListaFranquicias}
                    />
                )}
            />
            <Route
                path={`${match.url}/nuevo-franquicias`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.administrar.franquicias.verPagina}
                        componente={NuevoFranquicias}
                    />
                )}
            />
            <Route
                path={`${match.url}/editar-franquicias`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.administrar.franquicias.verPagina}
                        componente={EditarFranquicias}
                    />
                )}
            />
        </Switch>
    );
};

export default Franquicias;

Franquicias.propTypes = {
    match: PropTypes.object,
};
