import React from 'react';
import 'antd/dist/antd.css';
import { Select as SelectAntd } from 'antd';
import PropTypes from 'prop-types';

const Select = (props) => {
    return <SelectAntd {...props}>{props.children}</SelectAntd>;
};

Select.propTypes = {
    children: PropTypes.any,
};

export default Select;
