import React, { useEffect, useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Switch, message } from 'antd';
import { EditTwoTone } from '@ant-design/icons';
import styles from 'components/pages/ProgramaRecompensa/Promociones/ListPromsBanners.module.css';
import PromotionsContext from 'context/programaRecompensa/Promociones/PromotionsContext';
import { urls } from 'constants/index';
import clienteAxios from 'config/axios';

const useListPromsBanners = () => {
    const {
        currentPage,
        promotionsList,
        pageSize,
        total,
        loadingPromotionList,
        dataSource,

        setDataSource,
        setNewOrEditProm,
        setId,
        setEnable,
        setShowDeactivatePromotionModal,
        setShowActivatePromotionModal,
    } = useContext(PromotionsContext);

    const history = useHistory();

    useEffect(() => {
        if (promotionsList !== dataSource) {
            setDataSource(promotionsList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [promotionsList, dataSource]);

    const columns = [
        {
            title: 'Nro',
            dataIndex: 'number',
            key: 'number',
        },
        {
            title: 'Promoción',
            dataIndex: 'promotion',
            key: 'promotion',
        },
        {
            title: 'Rango fechas',
            dataIndex: 'dates',
            key: 'dates',
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
            render: (text, record) => (
                <div className={styles.state}>
                    <Switch
                        onClick={(checked) =>
                            handleClickSwitch(checked, record)
                        }
                        checked={record?.checked}
                    />
                    <EditTwoTone
                        onClick={() => goToEditView(record?.id)}
                        className={styles.editButton}
                    />
                </div>
            ),
        },
    ];

    const handleClickSwitch = (checked, record) => {
        const id = record?.id;
        setId(id);
        setEnable(checked);

        if (checked) {
            setShowActivatePromotionModal(true);
        }

        if (!checked) {
            setShowDeactivatePromotionModal(true);
        }
    };

    const getBannerById = useCallback(async (bannerId) => {
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.get(`/promotion/${bannerId}`, {
                params,
            });
            return response?.data?.data;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al obtener los datos',
            );
            return null;
        }
    }, []);

    const getBannerData = useCallback(
        async (bannerId) => {
            const bannerData = await getBannerById(bannerId);
            return bannerData;
        },
        [getBannerById],
    );

    const goToEditView = (id) => {
        // getBannerById(id);
        setNewOrEditProm('edit');
        history.push(
            `/programa-recompensa/promociones/banners-promo/editar-promo/${id}`,
        );
    };

    const goToNewPromView = () => {
        setNewOrEditProm('new');
        history.push(
            '/programa-recompensa/promociones/banners-promo/nueva-promo',
        );
    };

    return {
        loading: loadingPromotionList,
        columns,
        dataSource,
        pageSize,
        total,
        currentPage,

        goToNewPromView,
        getBannerData,
    };
};

export default useListPromsBanners;
