import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from 'react-router-dom';

import DashboardRouter from './DashboardRouter';

import LoginScreen from '../components/auth/InicioSesion';
import TuFactura from '../components/pages/TuFactura';
import ResumenCorte from '../components/pages/ResumenCorte/index.js';
import RestablecerContrasena from '../components/auth/RestablecerContrasena';
import MigrarCredenciales from '../components/auth/MigrarCredenciales/index';

export const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <Route path="/login" exact component={LoginScreen} />
                <Route path="/tu-factura/:id/:secreto" component={TuFactura} />
                <Route path="/resumen-corte/:token" component={ResumenCorte} />
                <Route
                    path="/cambiar-credenciales/:token"
                    component={RestablecerContrasena}
                />
                <Route
                    path="/migrar-credenciales/:userId"
                    component={MigrarCredenciales}
                />
                <Route path="/" component={DashboardRouter} />
                <Redirect to="/login" />
            </Switch>
        </Router>
    );
};
