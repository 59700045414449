import React from 'react';
import 'antd/dist/antd.css';
import { Col, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import revertirCancelacionIcon from '../../../../assets/svg/support/revertirCancelacion.svg';

import classes from './Acciones1.module.css';

const RevertirCancelacionButton = ({
    permitirRevertirCancelacion,
    revertirCancelacionButtonHandler,
}) => (
    <>
        {permitirRevertirCancelacion ? (
            <Col>
                <Tooltip title="Revertir cancelación">
                    <Button
                        type="link"
                        icon={
                            <img
                                src={revertirCancelacionIcon}
                                className={classes.iconoButton}
                            />
                        }
                        onClick={revertirCancelacionButtonHandler}
                    />
                </Tooltip>
            </Col>
        ) : null}
    </>
);

RevertirCancelacionButton.propTypes = {
    permitirRevertirCancelacion: PropTypes.bool,
    revertirCancelacionButtonHandler: PropTypes.func,
};

export default RevertirCancelacionButton;
