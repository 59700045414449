import React from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

const FormItemSelectAnho = ({ rules, disabled }) => {
    const anhos = () => {
        const anhoActual = new Date().getFullYear();
        let anhos = [];

        for (let i = anhoActual; i >= 2016; i--) {
            anhos.push(i);
        }

        return anhos;
    };

    return (
        <Form.Item name="anho" label="Año" rules={rules}>
            <Select disabled={disabled}>
                {anhos().map((anho) => {
                    return (
                        <Option value={anho} key={anho}>
                            {anho}
                        </Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
};

export default FormItemSelectAnho;

FormItemSelectAnho.propTypes = {
    rules: PropTypes.any,
    disabled: PropTypes.bool,
    disabledTodos: PropTypes.bool,
};
