import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Row, Col, Spin } from 'antd';

const { Header, Content } = Layout;

const ABMLayout = ({ titulo, busqueda, acciones, loading, children }) => {
    return (
        <Layout style={styles.layout}>
            <Spin spinning={loading || false}>
                <Header style={styles.header}>
                    <Row gutter={12}>
                        <Col
                            span={busqueda ? 12 : 16}
                            style={styles.titleContainer}>
                            <h2 style={styles.title}>{titulo}</h2>
                        </Col>
                        {busqueda && (
                            <Col span={12} style={styles.busqueda}>
                                {busqueda}
                            </Col>
                        )}
                        <Col span={8} style={styles.acciones}>
                            {acciones}
                        </Col>
                    </Row>
                </Header>
                <Content>{children}</Content>
            </Spin>
        </Layout>
    );
};

export default ABMLayout;

const styles = {
    layout: {
        height: '100vh',
    },
    header: {
        background: 'none',
        color: 'black',
        padding: '0 20px',
    },
    titleContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginBottom: 0,
    },
    acciones: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    busqueda: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
};

ABMLayout.propTypes = {
    titulo: PropTypes.string,
    acciones: PropTypes.node,
    loading: PropTypes.bool,
    children: PropTypes.node,
    busqueda: PropTypes.node,
};
