import React, { useContext, useEffect, useState } from 'react';
import { Col, Row, Select, Button, List, Tooltip } from 'antd';
import Search from 'antd/lib/input/Search';
import CuponesContext from '../../../../context/programaRecompensa/cupones/CuponesContext';
import { InfoCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

export const ListarUsuarios = () => {
    const cuponesContext = useContext(CuponesContext);
    const {
        usuarios,
        obtenerUsuarios,
        seleccionarUsuarioId,
        usuariosSeleccionados,
        guardarUsuariosSelecionados,
        obtenerUsuariosEliminar,
    } = cuponesContext;

    const [tipoBusqueda, setTipoBusqueda] = useState('id');
    const [valueBusqueda, setValueBusqueda] = useState('');
    const [usuariosFiltrados, setUsuariosFiltrados] = useState([]); // lista de usuarios filtrados

    const handleChange = async (value) => {
        setValueBusqueda(value);
    };

    const tipoBusquedaHandler = (tipo) => {
        setTipoBusqueda(tipo);
    };

    const agregarUsuarioHandler = (usuario) => {
        const { idUser } = usuario;
        // guardar en el state nuevo usuario
        let nuevaListaUsuarios =
            usuariosSeleccionados && usuariosSeleccionados.length > 0
                ? usuariosSeleccionados.map((usuario) => ({ ...usuario }))
                : [];

        //Agregar nuevo usuario
        nuevaListaUsuarios.push(usuario);

        guardarUsuariosSelecionados(nuevaListaUsuarios);
        let nuevaListaUsuariosId = idUser;

        seleccionarUsuarioId(nuevaListaUsuariosId);
    };

    const removerUsuarioHandler = (usuario) => {
        const { idUser } = usuario;
        const usuarios = idUser;
        // guardar en el state nuevo usuario
        let nuevaListaUsuarios = usuariosSeleccionados.filter(
            (usuarioSeleccionado) =>
                usuarioSeleccionado.idUser !== usuario.idUser,
        );

        guardarUsuariosSelecionados(nuevaListaUsuarios);

        // pasar id de los usuarios al context
        obtenerUsuariosEliminar(usuarios);
    };

    useEffect(() => {
        if (usuarios) {
            let usuariosFiltradosTemp = [];

            if (usuariosSeleccionados && usuariosSeleccionados?.length > 0) {
                // Filtrar usarios que ya existen en la liste de usuarios seleccionados
                usuarios.forEach((usuario) => {
                    // Buscar el ID del usuario en la lista de usuarios seleccionados
                    const usuarioSeleccionadoIdx =
                        usuariosSeleccionados.findIndex(
                            (usuarioSeleccionado) =>
                                usuarioSeleccionado.idUser === usuario.idUser,
                        );
                    // Si no se encuentra, agregar a la lista de usuarios filtrados
                    if (usuarioSeleccionadoIdx === -1) {
                        // Guardar la lista filtrada
                        usuariosFiltradosTemp.push(usuario);
                    }
                });
            } else {
                usuariosFiltradosTemp = usuarios;
            }

            setUsuariosFiltrados(usuariosFiltradosTemp);
        }
    }, [usuariosSeleccionados, usuarios]);

    useEffect(() => {
        obtenerUsuarios('nombre', '');
    }, []);

    useEffect(() => {
        // llama a obtener usuarios despues de 0.5 segundos
        const timeout = setTimeout(() => {
            if (valueBusqueda) {
                obtenerUsuarios(tipoBusqueda, valueBusqueda);
            } else {
                obtenerUsuarios('nombre', '');
            }
        }, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [valueBusqueda, tipoBusqueda]);

    return (
        <>
            {/* lista de resultados de la busqueda */}
            <label>Usuarios asignados </label>
            <Tooltip
                title="Podés seleccionar los usuarios de forma manual o subir el listado en formato excel"
                placement="right">
                <InfoCircleOutlined />
            </Tooltip>
            <div
                style={{
                    height: '150px',
                    overflow: 'auto',
                    border: '1px solid #e8e8e8',
                    borderRadius: '4px',
                    marginBottom: '20px',
                }}>
                <List
                    itemLayout="horizontal"
                    dataSource={usuariosSeleccionados}
                    size="small"
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    key={item.user_id}
                                    type="link"
                                    onClick={() => removerUsuarioHandler(item)}>
                                    Remover
                                </Button>,
                            ]}>
                            <List.Item.Meta
                                title={
                                    <span style={{ fontSize: '10pt' }}>
                                        {item.nombreCompleto} #{item.idUser}
                                    </span>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>

            {/* formulario de busqueda */}
            <Row gutter="24" style={styles.contenedorBuscadorPersonas}>
                <Col span={16}>
                    <p>Agregar una persona</p>
                    <Search
                        name="buscarPersona"
                        placeholder="Buscar a una persona"
                        onChange={(evt) =>
                            handleChange(evt.currentTarget.value)
                        }
                        value={valueBusqueda}
                        style={styles.fullWidth}
                    />
                </Col>
                <Col span={8}>
                    {/* <Form.Item name="selectPersona"> */}
                    <p>Buscar por</p>
                    <Select
                        defaultValue="id"
                        style={styles.fullWidth}
                        onChange={tipoBusquedaHandler}>
                        <Select.Option value="id">ID</Select.Option>
                        <Select.Option value="nombre">
                            Nombre y apellido
                        </Select.Option>
                        <Select.Option value="correo">Correo</Select.Option>
                    </Select>
                    {/* </Form.Item> */}
                </Col>
            </Row>
            {/* lista de resultados de la busqueda */}
            <div
                style={{
                    height: '163px',
                    overflow: 'auto',
                    border: '1px solid #e8e8e8',
                    borderRadius: '4px',
                }}>
                <List
                    itemLayout="horizontal"
                    dataSource={usuariosFiltrados}
                    size="small"
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    key={item.user_id}
                                    type="link"
                                    onClick={() => agregarUsuarioHandler(item)}>
                                    Agregar
                                </Button>,
                            ]}>
                            <List.Item.Meta
                                title={
                                    <span style={{ fontSize: '10pt' }}>
                                        {item.nombreCompleto} #{item.idUser}
                                    </span>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>
        </>
    );
};

const styles = {
    contenedorSubirArchivo: { border: '2px dashed gray', height: '10rem' },
    contenedorBuscadorPersonas: { marginBottom: 12 },
    fullWidth: { width: '100%' },
    inputSubirArchivo: {
        display: 'flex',
        width: '100%',
        padding: '20px 20px 10px 20px',
    },
    warningIcon: { color: '#faad14' },
    checkIcon: { color: '#52c41a' },
    infoIcon: { color: '#1890ff' },
};

ListarUsuarios.proptypes = {
    isSubirArchivo: PropTypes.bool,
};
