import React, { useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import PropTypes from 'prop-types';
import { urls } from '../../constants';

const googleMapApiKey = urls.REACT_APP_GOOGLE_MAP_API_KEY;

const containerStyle = {
    marginTop: '20px',
    width: '400px',
    height: '400px',
};

const googleMapOptions = {
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
    zoomControl: true,
    zoom: 16,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: [
        {
            stylers: [{ visibility: 'on' }],
            featureType: 'poi',
        },
    ],
};

// latitud por defecto si no llega ningun dato
const latitudAsuncion = -25.2922909;
const longitudAsuncion = -57.5588664;

const Mapa = ({ latitud, longitud, obtenerLatitud, obtenerLongitud }) => {
    //state
    const [latitudMapa, setlatitudMapa] = useState(null);
    const [longitudMapa, setlongitudMapa] = useState(null);
    // positicion inicial del mapa
    const center = {
        lat: Number(latitudMapa) || latitudAsuncion,
        lng: Number(longitudMapa) || longitudAsuncion,
    };

    //position del marker
    const position = {
        lat: Number(latitudMapa) || latitudAsuncion, // latitud
        lng: Number(longitudMapa) || longitudAsuncion, // longitud
    };

    useEffect(() => {
        // enviar al state latitud, longitud
        obtenerLatitud(latitudMapa);
        obtenerLongitud(longitudMapa);
    }, [latitud, longitud]);

    // selecciona la posicion del marcador latitud longitud
    const onDragEnd = (coord) => {
        const { latLng } = coord;
        const lat = latLng.lat();
        const lng = latLng.lng();
        setlatitudMapa(lat);
        setlongitudMapa(lng);
    };

    useEffect(() => {
        latitud.length > 0 && setlatitudMapa(latitud);
        longitud.length > 0 && setlongitudMapa(longitud);
    }, [latitud, longitud]);

    return (
        <LoadScript googleMapsApiKey={googleMapApiKey}>
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                options={googleMapOptions}>
                <>
                    <Marker
                        position={position}
                        draggable={true}
                        onDragEnd={onDragEnd}
                    />
                </>
            </GoogleMap>
        </LoadScript>
    );
};

export default Mapa;

Mapa.propTypes = {
    latitud: PropTypes.string,
    longitud: PropTypes.string,
    obtenerLatitud: PropTypes.func,
    obtenerLongitud: PropTypes.func,
};
