import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import ListaDetalleComisiones from './ListaDetalleComisiones';

import { Permisos } from '../../../../../constants/Permisos';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import DetalleComisionesState from '../../../../../context/reportes/ventas/detalleComisiones/DetalleComisionesState';

const DetalleComisiones = ({ match }) => {
    return (
        <DetalleComisionesState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.reportes.ventas.detalleComisiones
                                    .verPagina
                            }
                            componente={ListaDetalleComisiones}
                        />
                    )}
                />
            </Switch>
        </DetalleComisionesState>
    );
};

export default DetalleComisiones;

DetalleComisiones.propTypes = {
    match: PropTypes.object,
};
