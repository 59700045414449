import { message } from 'antd';
import getErrorMessage from 'components/utils-components/getErrorMessage';
import { default as axios, default as clienteAxios } from 'config/axios';
import { urls } from 'constants/index';
import PropTypes from 'prop-types';
import React, { useCallback, useReducer } from 'react';
import UtilidadesContext from './UtilidadesContext';
import UtilidadesReducer, { initialState } from './UtilidadesReducer';
import {
    UTILIDADES_TIPOS_PAGO_LISTA,
    UTILIDADES_TIPOS_PAGO_LOADING,
    UTILITIES_BRANCH_STATES,
    UTILITIES_CATEGORIES,
    UTILITIES_FRANCHISE_CATEGORIES,
    UTILITIES_ORDERS_STATES,
} from './types';

const UtilidadesState = ({ children }) => {
    const [state, dispatch] = useReducer(UtilidadesReducer, initialState);

    const obtenerListaTiposPago = async (
        esUsuarioComercio,
        tienePermisoVerPagoOnline,
    ) => {
        if (!state?.listaTiposPago?.length) {
            dispatch({
                type: UTILIDADES_TIPOS_PAGO_LOADING,
            });
            let params = { axiosBaseURL: urls.REACT_APP_BACKEND_URL_B };
            let listaTiposPagoArr = [];
            let listaOpcionesSelectTipoPagoArr = [];
            try {
                const respuesta = await clienteAxios.get('get_payment_types', {
                    params: params,
                });
                listaTiposPagoArr = respuesta.data?.data;

                // Renombrar opciones Pago Online

                listaTiposPagoArr = listaTiposPagoArr.map((tipo) => {
                    let tipoRenombrado = tipo;
                    if (tipo?.online) {
                        tipoRenombrado = {
                            ...tipo,
                            slug: `Pago Online ${tipo.slug}`,
                        };
                    }
                    return tipoRenombrado;
                });

                // La etiqueta de PO se mostrara como 'Pago Online' o 'Todos los pagos online' dependiendo de las condiciones dadas
                const mostrarComoPagoOnline =
                    !tienePermisoVerPagoOnline || esUsuarioComercio;

                // Atributos obligatorios: id, slug
                const opcionTodosLosTiposDePago = { id: '', slug: 'Todos' };

                // Obtener lista de todos los IDs de Pago Online
                const listaIdsPO = listaTiposPagoArr
                    .filter((item) => Boolean(item?.online))
                    .map((item) => item?.id);

                // Crear opcion de 'Todos los Pagos Online'
                const opcionTodosLosPO = {
                    id: listaIdsPO.toString(),
                    slug: 'Pago Online',
                };

                if (mostrarComoPagoOnline) {
                    // Filtrar opciones - Remover PO
                    const opcionesSinPOFiltradasArr = listaTiposPagoArr.filter(
                        (item) => Boolean(!item?.online),
                    );

                    // Filtrar opciones - Renombrar PO
                    listaTiposPagoArr = listaTiposPagoArr.map((tipo) => {
                        let tipoRenombrado = tipo;
                        if (tipo?.online) {
                            tipoRenombrado = { ...tipo, slug: `Pago Online` };
                        }
                        return tipoRenombrado;
                    });

                    // Constriur las opciones para el select
                    const nuevasOpcionesSelect = [
                        opcionTodosLosTiposDePago,
                        ...opcionesSinPOFiltradasArr,
                        opcionTodosLosPO,
                    ];

                    // Retornar opciones del select
                    listaOpcionesSelectTipoPagoArr = nuevasOpcionesSelect;
                } else {
                    // Constriur las opciones para el select
                    const nuevasOpcionesSelect = [
                        opcionTodosLosTiposDePago,
                        { ...opcionTodosLosPO, slug: 'Todos los Pagos Online' },
                        ...listaTiposPagoArr,
                    ];

                    // Retornar opciones del select
                    listaOpcionesSelectTipoPagoArr = nuevasOpcionesSelect;
                }
            } catch (error) {
                message.error(
                    error?.response?.data?.message ||
                        error?.message ||
                        error ||
                        'Hubo un problema al obtener la lista de tipos de pagos',
                );
            }

            listaOpcionesSelectTipoPagoArr = listaOpcionesSelectTipoPagoArr.map(
                (tipo) => ({
                    id: tipo?.id,
                    name: tipo?.slug,
                }),
            );

            dispatch({
                type: UTILIDADES_TIPOS_PAGO_LISTA,
                payload: {
                    listaTiposPago: listaTiposPagoArr,
                    listaOpcionesSelectTipoPago: listaOpcionesSelectTipoPagoArr,
                },
            });
        }
    };

    const obtenerCiudades = async () => {
        let dataset = [];
        try {
            const respuesta = await clienteAxios.get('/city_names');
            dataset = respuesta?.data?.data?.cities.map((item) => ({
                id: item.city_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las ciudades',
            );
        }
        return dataset;
    };

    const obtenerBarrios = async () => {
        let dataset = [];
        try {
            const respuesta = await axios.get('/neighborhoods');
            // Remover duplicados
            const barrios = [
                ...new Map(
                    respuesta?.data?.data?.neighborhoods?.map((v) => [
                        v?.name,
                        v,
                    ]),
                ).values(),
            ];
            dataset = barrios.map((item) => ({
                id: item.city_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de los barrios',
            );
        }
        return dataset;
    };

    const obtenerFamilias = async () => {
        let dataset = [];
        try {
            const respuesta = await clienteAxios.get('/families');
            dataset = respuesta?.data?.data?.families;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las familias',
            );
        }
        return dataset;
    };

    /**
     * obtenerFranquicias
     * @param {String} franchiseCategory
     * @returns {Array<object>}
     */
    const obtenerFranquicias = useCallback(async (franchiseCategory) => {
        let dataset = [];
        const params = {
            franchise_categories_ids: franchiseCategory,
        };
        try {
            const respuesta = await axios.get('/franchises_list', { params });
            dataset = respuesta?.data?.data?.franchises;
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las franquicias',
            );
        }
        return dataset;
    }, []);

    /**
     * obtenerSucursales
     * @param {Number} franchiseId Puede recibir solamente un id
     * @returns {Array<object>} Array de sucursales
     */
    const obtenerSucursales = async (franchiseId) => {
        let dataset;
        let response;
        try {
            if (franchiseId) {
                response = await clienteAxios.get(
                    `/branches_list?franchise_id=${franchiseId}`,
                );
            } else {
                response = await clienteAxios.get('/branches_list');
            }
            dataset = response?.data?.data?.branches.map((item) => ({
                id: item.branch_id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de las sucursales',
            );
        }
        return dataset;
    };

    const obtenerProductos = async (franchiseId) => {
        let params = {};
        params['axiosBaseURL'] = urls.REACT_APP_BACKEND_URL_C;
        params['franchise_id'] = franchiseId ? Number(franchiseId) : undefined;
        let arrSeccion = [];
        try {
            const respuesta = await axios.get('get_products', {
                params: params,
            });
            const arrProductos = respuesta?.data?.data?.products;
            arrSeccion = arrProductos.map((item) => ({
                id: item.id,
                name: item.name,
            }));
        } catch (error) {
            message.error(
                'Hubo un problema al obtener los datos de los productos',
            );
        }

        return arrSeccion;
    };

    const getOrdersStates = async () => {
        if (!state.ordersStates.length) {
            let formattedStates = [];
            let params = { axiosBaseURL: urls.REACT_APP_BACKEND_URL_B };
            try {
                const response = await clienteAxios.get('/get_order_states', {
                    params,
                });
                const states = response?.data?.data;
                formattedStates = [{ id: 0, name: 'Todos' }, ...states];

                dispatch({
                    type: UTILITIES_ORDERS_STATES,
                    payload: {
                        ordersStates: formattedStates,
                    },
                });
            } catch (error) {
                message.error(
                    error?.response?.data?.message ||
                        'Ocurrió un problema al obtener los datos',
                );
            }
        }
    };

    /**
     * getFranchiseStatements
     * @param {String} idFranquicia Ids separados por comas en caso de que sean varias franquicias
     * @param {String} idSucursal Ids separados por comas en caso de que sean varias sucursales
     * @param {Number} page
     * @param {Boolean} isOnline
     * @param {Boolean} hasMonchisDrivers
     * @param {String} statesIds Ids separados por comas
     * @param {String} statesIds Ids separados por comas
     * @param {Boolean} exportToExcel
     * @param {Boolean} enabled
     * @returns {Array<object>}
     */
    const getFranchiseStatements = async (
        franchiseId = undefined,
        branchId = undefined,
        page = undefined,
        isOnline,
        hasMonchisDriver,
        statesIds = undefined,
        exportToExcel,
        enabled = undefined,
        audiencesIds,
    ) => {
        let dataset = [];

        const params = {
            franchise_id: franchiseId,
            branch_id: branchId,
            page: page,
            is_online: isOnline ? true : undefined,
            has_monchis_driver: hasMonchisDriver ? true : undefined,
            states_ids: statesIds,
            export_to: exportToExcel ? 'excel' : undefined,
            enabled,
            audience_id: audiencesIds,

            axiosBaseURL: urls.REACT_APP_BACKEND_URL_SV_RECEPTION,
        };

        try {
            const response = await clienteAxios.get('/get_franchise_states', {
                params,
            });
            dataset = response?.data?.data;
        } catch (error) {
            message.error(
                `Hubo un problema al obtener los estados de las franquicias.`,
            );
        }
        return dataset;
    };

    /**
     * getBranchStates
     * @param {String} slug
     *
     */
    const getBranchStatements = async (slug = undefined) => {
        if (!state?.branchStates?.length) {
            let dataset;
            const params = {
                slug,

                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            };

            try {
                const response = await clienteAxios.get('/get_branch_states', {
                    params,
                });
                dataset = response?.data?.data?.branch_states;
                dispatch({
                    type: UTILITIES_BRANCH_STATES,
                    payload: {
                        branchStates: dataset,
                    },
                });
            } catch (error) {
                message.error(
                    `Hubo un problema al obtener los estados de las franquicias.`,
                );
            }
        }
    };

    const getCategories = async (id) => {
        const params = {
            franchise_id: id,

            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.get('/categories', { params });
            const categories = response?.data?.categories;

            if (!response?.success)
                throw new Error('No hemos podido obtener las caterías');

            dispatch({
                type: UTILITIES_CATEGORIES,
                payload: {
                    categories,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(error);
            message.error(errorMessage);
        }
    };

    const getFranchiseCategoriesByFamily = async (ids) => {
        const familyIds = ids?.join(',');

        const params = {
            family_ids: familyIds,

            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };

        try {
            const response = await clienteAxios.get('/categories/family', {
                params,
            });

            if (!response?.data?.success)
                throw new Error(
                    'No hemos podido obtener las categorías de franquicias',
                );

            const franchiseCategoriesByFamily =
                response?.data?.data?.categories;

            dispatch({
                type: UTILITIES_FRANCHISE_CATEGORIES,
                payload: {
                    franchiseCategoriesByFamily,
                },
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'No hemos podido obtener las categorías de franquicias',
            );
            message.error(errorMessage);
        }
    };

    const setFranchiseCategoriesByFamiy = (franchiseCategoriesByFamily) => {
        dispatch({
            type: UTILITIES_FRANCHISE_CATEGORIES,
            payload: {
                franchiseCategoriesByFamily,
            },
        });
    };
    return (
        <UtilidadesContext.Provider
            value={{
                loadingTiposPago: state.loadingTiposPago,
                listaTiposPago: state.listaTiposPago,
                listaOpcionesSelectTipoPago: state.listaOpcionesSelectTipoPago,
                ordersStates: state.ordersStates,
                branchStates: state.branchStates,
                franchiseCategoriesByFamily: state.franchiseCategoriesByFamily,

                obtenerListaTiposPago,
                obtenerCiudades,
                obtenerBarrios,
                obtenerFamilias,
                obtenerFranquicias,
                obtenerSucursales,
                obtenerProductos,
                getOrdersStates,
                getFranchiseStatements,
                getBranchStatements,
                getCategories,
                getFranchiseCategoriesByFamily,
                setFranchiseCategoriesByFamiy,
            }}>
            {children}
        </UtilidadesContext.Provider>
    );
};

export default UtilidadesState;

UtilidadesState.propTypes = {
    children: PropTypes.node,
};
