export const franquiciasIntegracionesSupport = [
    {
        idFranquicia: 4,
        franquicia: 'BK',
        nombre: 'Burger King',
        tipo: 'INTEGRACION',
    },
    { idFranquicia: 890, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 1, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 674, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 66, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 1069, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 1070, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 867, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 866, franquicia: '', nombre: '', tipo: 'JEDI' },
    { idFranquicia: 12, franquicia: '', nombre: '', tipo: 'JEDI' },
    {
        idFranquicia: 3,
        franquicia: 'MC',
        nombre: "McDonald's",
        tipo: 'INTEGRACION',
    },
    {
        idFranquicia: 447,
        franquicia: 'BACON',
        nombre: 'Bacon - Deloreant',
        tipo: 'INTEGRACION',
    },
    {
        idFranquicia: 2273,
        franquicia: 'PARIS',
        nombre: 'Paris',
        tipo: 'INTEGRACION',
    },
];
