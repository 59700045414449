import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ListaTemporadas } from './ListaTemporadas';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';

const Temporadas = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista`}
                render={() => (
                    <ProtectedComponent
                        permiso={
                            Permisos.programaRecompensa.temporadas
                                .listarTemporadas
                        }
                        componente={ListaTemporadas}
                    />
                )}
            />
        </Switch>
    );
};

export default Temporadas;

Temporadas.propTypes = {
    match: PropTypes.object,
};
