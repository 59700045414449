import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';

const SolicitarDriverModal = ({
    idOrden,
    isSolicitarDriverModalVisible,
    solicitarDriverLoading,
    cancelarSolicitarDriver,
    confirmarSolicitarDriver,
}) => {
    return (
        <Modal
            centered
            open={isSolicitarDriverModalVisible}
            footer={null}
            closable={null}>
            <ModalContent
                title={`¿Solicitar driver para la orden #${idOrden}?`}
                okText="CONFIRMAR"
                cancelText="CANCELAR"
                onOk={confirmarSolicitarDriver}
                onCancel={cancelarSolicitarDriver}
                isLoading={solicitarDriverLoading}
                showWarnIcon
            />
        </Modal>
    );
};

SolicitarDriverModal.propTypes = {
    idOrden: PropTypes.any,
    isSolicitarDriverModalVisible: PropTypes.bool,
    solicitarDriverLoading: PropTypes.bool,
    cancelarSolicitarDriver: PropTypes.func,
    confirmarSolicitarDriver: PropTypes.func,
};

export default SolicitarDriverModal;
