import { Select, Spin } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import PropTypes from 'prop-types';

const DebounceSelect = ({
    fetchOptions,
    options,
    debounceTimeout = 1500,
    ...props
}) => {
    const [fetching, setFetching] = useState(false);
    const [selectedValue, setSelectedValue] = useState();

    useEffect(() => {
        if (selectedValue) {
            const delayDebounceFn = setTimeout(() => {
                setFetching(true);
                fetchOptions(selectedValue);
            }, debounceTimeout);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [selectedValue]);

    useEffect(() => {
        setFetching(false);
    }, [options]);

    const searchHandler = (value) => {
        setSelectedValue(value);
    };

    return (
        <Select
            labelInValue
            showSearch
            filterOption={false}
            onSearch={searchHandler}
            notFoundContent={
                fetching ? (
                    <Spin size="small" />
                ) : (
                    'No se encontraron coincidencias'
                )
            }
            {...props}
            options={options}
        />
    );
};

export default DebounceSelect;

DebounceSelect.propTypes = {
    fetchOptions: PropTypes.array,
    options: PropTypes.func,
    debounceTimeout: PropTypes.number,
};
