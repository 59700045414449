import React from 'react';
import { Table, Row, Col, Card } from 'antd';
import PropTypes from 'prop-types';

const TablaBalance = (props) => {
    const TablaResumen = (
        columnasResumenMonchisDrivers,
        dataResumenMonchisDrivers,
        columnasResumenMonchisMarketplace,
        dataResumenMonchisMarketplace,
        columnasResumenImportes,
        dataResumenImportesConFormato,
        botonesResumen,
        tieneMonchisDrivers,
    ) => {
        return (
            <Row gutter={10} align="top">
                <Col span={6}>
                    <Card>
                        <Table
                            columns={columnasResumenMonchisMarketplace}
                            dataSource={dataResumenMonchisMarketplace}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </Card>
                </Col>
                {tieneMonchisDrivers && (
                    <Col span={6}>
                        <Card>
                            <Table
                                columns={columnasResumenMonchisDrivers}
                                dataSource={dataResumenMonchisDrivers}
                                bordered
                                pagination={false}
                                size="small"
                            />
                        </Card>
                    </Col>
                )}
                <Col span={6}>
                    <Card>
                        <Table
                            columns={columnasResumenImportes}
                            dataSource={dataResumenImportesConFormato}
                            bordered
                            pagination={false}
                            size="small"
                        />
                    </Card>
                </Col>
                {!tieneMonchisDrivers && <Col span={6}></Col>}
                <Col span={6}>
                    <Card>
                        <Row gutter={[16, 24]}>{botonesResumen}</Row>
                    </Card>
                </Col>
            </Row>
        );
    };

    return (
        <div>
            <Table
                className="tabla-finanzas"
                columns={props.columnasBalance}
                dataSource={props.dataBalance}
                bordered
                scroll={{ x: 1200 }}
                rowKey={(record) => record.key.toString()}
                expandable={
                    props.dataBalance
                        ? {
                              expandedRowRender: (record) =>
                                  TablaResumen(
                                      record.columnasResumenMonchisDrivers,
                                      record.dataResumenMonchisDrivers,
                                      record.columnasResumenMonchisMarketplace,
                                      record.dataResumenMonchisMarketplace,
                                      record.columnasResumenImportes,
                                      record.dataResumenImportesConFormato,
                                      record.botonesResumen,
                                      record.tieneMonchisDrivers,
                                  ),
                              expandedRowKeys: props.dataBalance.map((item) =>
                                  item.key.toString(),
                              ),
                          }
                        : null
                }
                expandIconAsCell={false}
                expandIcon={() => null}
                expandIconColumnIndex={-1}
                {...props}
            />
        </div>
    );
};

export default TablaBalance;

TablaBalance.propTypes = {
    columnasBalance: PropTypes.array,
    dataBalance: PropTypes.array,
};
