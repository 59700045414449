import { useState, useEffect, useContext } from 'react';
import clienteAxios from '../../config/axios';
import { urls } from '../../constants';
import UsuariosContext from '../../context/usuario/UsuariosContext';
import { message } from 'antd';

const useObtenerDatosCupones = () => {
    const [obteniendoDatos, setObteniendoDatos] = useState(true);
    const [datosCupones, setDatosCupones] = useState([]);
    const { infoUsuario } = useContext(UsuariosContext);
    const idUsuario = infoUsuario?.id;

    const obtenerDatos = () => {
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_E,
        };
        clienteAxios
            .get(`/user/${idUsuario}/discounts`, { params })
            .then((respuesta) => {
                setDatosCupones(respuesta?.data?.data);
                setObteniendoDatos(false);
            })
            .catch((error) =>
                message.error(
                    error?.response?.data?.message ||
                        'Ocurrió un problema al obtener los datos',
                ),
            );
    };

    useEffect(() => {
        datosCupones.length === 0 && obtenerDatos();
    }, []);

    return { datosCupones, obteniendoDatos };
};

export default useObtenerDatosCupones;
