import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import EmisionDocumentosLista from './EmisionDocumentosLista';
import EmisionDocumentosState from '../../../../../context/facturacion/emisionDocumentos/EmisionDocumentosState';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import NuevaFactura from './Facturas/NuevaFactura';
import { Permisos } from '../../../../../constants/Permisos';

const EmisionDocumentos = ({ match }) => {
    return (
        <EmisionDocumentosState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.finanzas.facturacion.emisionDocumentos
                                    .verPagina
                            }
                            componente={EmisionDocumentosLista}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/nueva-factura`}
                    render={() => (
                        <ProtectedComponent
                            permiso={
                                Permisos.finanzas.facturacion.emisionDocumentos
                                    .verPagina
                            }
                            componente={NuevaFactura}
                        />
                    )}
                />
            </Switch>
        </EmisionDocumentosState>
    );
};

export default EmisionDocumentos;

EmisionDocumentos.propTypes = {
    match: PropTypes.object,
};
