import React, { useState, useEffect } from 'react';
import {
    Card,
    Row,
    Col,
    Table,
    Button,
    Input,
    Modal,
    Select,
    Form,
    Dropdown,
    Menu,
    message,
} from 'antd';
import {
    PlusCircleOutlined,
    ExclamationCircleOutlined,
    MoreOutlined,
} from '@ant-design/icons';
import { urls } from '../../../../constants';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';
import ABMLayout from '../../../../layouts/ABMLayout';
import axios from '../../../../config/axios';

const { confirm } = Modal;

const menu = (onEdit, onDisable, onDelete) => (
    <Menu>
        <Menu.Item key="editar" onClick={() => onEdit()}>
            Editar
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="desactivar" onClick={() => onDisable()} disabled>
            Desactivar
        </Menu.Item>
        <Menu.Item key="eliminar" onClick={() => onDelete()}>
            Eliminar
        </Menu.Item>
    </Menu>
);

const columns = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        render: (text) => <span>#{text}</span>,
    },
    {
        title: 'Nombre',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Facturar a',
        dataIndex: 'legal_name',
        key: 'legal_name',
    },
    {
        title: 'Documento',
        dataIndex: 'ruc',
        key: 'ruc',
    },
    {
        title: 'Teléfono',
        dataIndex: 'phone',
        key: 'phone',
    },
    {
        title: 'Correo',
        dataIndex: 'email',
        key: 'email',
        render: (text) => (text.length > 20 ? text.slice(0, 20) + '...' : text),
    },
    {
        title: '',
        key: 'action',
        fixed: 'right',
        render: (record) => (
            <Dropdown
                overlay={() =>
                    menu(record?.onEdit, record?.onDisable, record?.onDelete)
                }>
                <Button icon={<MoreOutlined />} />
            </Dropdown>
        ),
    },
];

const pageSize = 15;

const ListaFranquicias = () => {
    const [familiesList, setFamiliesList] = useState();
    const [processingForm, setProcesandoForm] = useState(false);
    const [paramsFranchises, setParamsFranchises] = useState({
        axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        page: 1,
    });
    const [formFilters] = Form.useForm();
    const [franchisesList, setFranchisesList] = useState();
    const [gettingTableData, setGettingTableData] = useState();
    const [recordsNumber, setRecordsNumber] = useState();
    const history = useHistory();
    const mixpanel = useMixpanel();

    // Form Filtros
    useEffect(() => {
        getFamilies();
        if (window.history.state?.paramsFranquicias) {
            // Restaurar filtros del Form
            setParamsFranchises(window.history.state.paramsFranquicias);
            let familia = window.history.state.paramsFranquicias?.family || '';
            formFilters.setFieldsValue({ familia: familia });
        } else {
            formFilters.setFieldsValue({ family: '' });
        }
    }, []);

    const getFamilies = async () => {
        setProcesandoForm(true);
        let familiesArray = [{ id: '', name: 'Todas' }];

        try {
            const axiosResponse = await axios.get('/families');
            axiosResponse?.data?.data?.families?.forEach((item) =>
                familiesArray.push(item),
            );
        } catch (error) {
            message.error(error.response.data.message);
        }

        setFamiliesList(familiesArray);
        setProcesandoForm(false);
    };

    const onFiltersFinish = (values) => {
        let params = { page: 1 };
        params['family'] = values?.family || undefined;
        params['ruc_or_franchise'] = values?.ruc_or_franchise || undefined;

        setParamsFranchises((state) => ({ ...state, ...params }));
    };

    // Tabla
    useEffect(() => {
        if (paramsFranchises) getFranchises();
    }, [paramsFranchises]);

    const getFranchises = async () => {
        setGettingTableData(true);
        let arrFranquicias = [];
        let numberOfRecords = 0;

        try {
            const axiosResponse = await axios.get('/get_franchises', {
                params: paramsFranchises,
            });

            arrFranquicias = axiosResponse?.data?.data?.data?.map((item) => ({
                ...item,
                key: item.id,
                onEdit: () => editarButtonHandler(item),
                onDelete: () => deleteButtonHandler(item.id),
            }));

            numberOfRecords = axiosResponse.data.data.total_rows;
        } catch (error) {
            message.error(error.response.data.message);
        }

        setFranchisesList(arrFranquicias);
        setRecordsNumber(numberOfRecords);
        setGettingTableData(false);
    };

    const deleteFranchise = async (id) => {
        setGettingTableData(true);

        try {
            const axiosResponse = await axios.delete('delete_franchise', {
                data: { axiosBaseURL: urls.REACT_APP_BACKEND_URL_C, id: id },
            });
            message.success(axiosResponse?.data?.data?.data);
            mixpanel.track('franchises_list_franchise_delete_success', { id });
            getFranchises();
        } catch (error) {
            message.error(error?.response?.data?.message);
            mixpanel.track('franchises_list_franchise_delete_error', {
                error: error?.response?.data?.message,
            });
        }

        setGettingTableData(false);
    };

    const newFranchiseHandler = () => {
        saveFiltersForm();
        mixpanel.track('click_on_franchises_list_add_button');

        // Ir a Nuevo
        history.push(`/administrar/franquicias/nuevo-franquicias`);
    };

    const editarButtonHandler = (itemSelected) => {
        saveFiltersForm();
        mixpanel.track('click_on_franchises_list_franchise_edit_button', {
            itemSelected,
        });
        // Ir a Editar
        history.push(`/administrar/franquicias/editar-franquicias`, {
            initialValues: itemSelected,
        });
    };

    const saveFiltersForm = () => {
        window.history.pushState(
            { paramsFranquicias: paramsFranchises },
            paramsFranchises,
            '/administrar/franquicias/lista-franquicias',
        );
    };

    const deleteButtonHandler = (id) => {
        mixpanel.track('click_on_franchises_list_franchise_delete_button', {
            id,
        });

        confirm({
            title: 'Está seguro de que quiere eliminar esta franquicia?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Eliminar',
            okType: 'danger',
            onOk() {
                deleteFranchise(id);
            },
            cancelText: 'Cancelar',
        });
    };

    const cambioDePaginaHandler = (page) => {
        setParamsFranchises((state) => ({ ...state, page: page }));
    };

    return (
        <ABMLayout
            titulo={'Franquicias'}
            acciones={
                <Button
                    type="primary"
                    icon={<PlusCircleOutlined />}
                    onClick={newFranchiseHandler}>
                    Nuevo
                </Button>
            }>
            <Card>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form
                            name="filtros"
                            form={formFilters}
                            layout="vertical"
                            onFinish={onFiltersFinish}>
                            <Row gutter={12}>
                                <Col span={6}>
                                    <Form.Item label="Familia" name="family">
                                        <Select
                                            showSearch
                                            optionFilterProp="item"
                                            filterOption={(input, option) =>
                                                option.item
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }
                                            disabled={processingForm}>
                                            {familiesList &&
                                                familiesList.map((item) => (
                                                    <Select.Option
                                                        key={item.id}
                                                        value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="Nombre o RUC"
                                        name="ruc_or_franchise">
                                        <Input disabled={processingForm} />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item style={{ marginTop: 26 }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            disabled={processingForm}>
                                            Buscar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
                <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            loading={gettingTableData}
                            columns={columns}
                            dataSource={franchisesList}
                            pagination={{
                                pageSize: pageSize,
                                total: recordsNumber,
                                current: paramsFranchises?.page
                                    ? paramsFranchises.page
                                    : 1,
                                showSizeChanger: false,
                                onChange: (page) => cambioDePaginaHandler(page),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </ABMLayout>
    );
};

export default ListaFranquicias;
