import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ABMLayout from '../../../../../layouts/ABMLayout';
import listaReciboContext from '../../../../../context/facturacion/ListaRecibos/ListaReciboContext';
import { formatoGuaranies } from '../../../../utils-components/Number';
import {
    Card,
    Row,
    Col,
    Table,
    Button,
    Select,
    Form,
    Divider,
    DatePicker,
    message,
    Menu,
    Dropdown,
    Modal,
} from 'antd';
import {
    EyeOutlined,
    DeleteOutlined,
    EllipsisOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { urls } from '../../../../../constants';
import ModalContent from '../../../../atoms/ModalContent';

const { Option } = Select;
const { RangePicker } = DatePicker;

const ListarRecibos = () => {
    //extraemos del context
    const {
        obtenerListadoRecibos,
        listadoRecibos,
        seleccionarPaginadoTabla,
        totalRecibos,
        loadingTablaRecibo,
        paginaActualRecibo,
        guardarReciboSeleccionado,
        removerReciboSeleccionado,
    } = useContext(listaReciboContext);

    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [valores, setValores] = useState({
        date_start: '',
        date_end: '',
    });
    const [isModalVisible, setModalVisible] = useState(false);
    const [reciboSeleccionado, setReciboSeleccionado] = useState();
    const [isModalLoading, setModalLoading] = useState(false);
    const [reciboForm] = Form.useForm();

    let history = useHistory();

    const verDetalles = (row) => {
        guardarReciboSeleccionado(row.datosOriginales);
        history.push('/finanzas/facturacion/recibos/detalle');
    };

    const deleteModal = (row) => {
        setModalVisible(true);
        setReciboSeleccionado(row.id);
    };

    const removerReciboHandler = async () => {
        setModalLoading(true);
        const resultado = await removerReciboSeleccionado(reciboSeleccionado);
        setModalVisible(false);
        setModalLoading(false);
        if (resultado) obtenerListadoRecibos(valores, paginaActualRecibo);
    };

    const cancelarHandler = () => {
        setModalVisible(false);
    };

    const dropdownMenu = (row) => (
        <Menu
            style={{ borderRadius: '10px', padding: '10px', color: '#d1d5db' }}>
            <Menu.Item onClick={() => verDetalles(row)}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        color: '#4B5563',
                    }}>
                    <EyeOutlined />
                    <span style={{ marginLeft: '10px' }}>Ver Detalles</span>
                </div>
            </Menu.Item>
            <Menu.Item onClick={() => deleteModal(row)}>
                <div
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        color: '#4B5563',
                    }}>
                    <DeleteOutlined />
                    <span style={{ marginLeft: '10px' }}>Eliminar</span>
                </div>
            </Menu.Item>
        </Menu>
    );

    const EllipsisDropdown = (props) => {
        return (
            <Dropdown
                overlay={() => props.menu}
                placement="bottomLeft"
                trigger={['click']}>
                <div className="ellipsis-dropdown">
                    <EllipsisOutlined />
                </div>
            </Dropdown>
        );
    };

    EllipsisDropdown.propTypes = {
        menu: PropTypes.node,
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <span>#{text}</span>,
        },
        {
            title: 'Franquicia',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Razón social',
            dataIndex: 'razon_social',
            key: 'razon_social',
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },
        {
            title: 'Tipo de recibo',
            dataIndex: 'tipoRecibo',
            key: 'tipoRecibo',
            render: (concept) => concept,
        },
        {
            title: 'Monto',
            dataIndex: 'monto',
            key: 'monto',
        },
        {
            title: 'Detalles',
            dataIndex: 'detalles',
            key: 'detalles',
            render: (_, elm) => (
                <div style={{ textAlign: 'center' }}>
                    <EllipsisDropdown menu={dropdownMenu(elm)} />
                </div>
            ),
        },
    ];

    const descargarExcel = async () => {
        message.info('obteniendo Excel');
        let datos = {};
        if (fechaDesde && fechaHasta) {
            datos = {
                date_start: fechaDesde,
                date_end: fechaHasta,
            };
        }
        try {
            const resp = await axios.get(
                `${urls.REACT_APP_BACKEND_URL_D}/excel_balance_payments`,
                { params: datos, responseType: 'blob' },
            );
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'lista-recibos.xlsx');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            message.error('Hubo un error al descargar, vuelva a intentarlo');
        }
    };

    const rangoFechas = (valor) => {
        let fechadesde = null;
        let fechahasta = null;
        if (valor !== null) {
            if (valor.length > 0) {
                fechadesde = valor[0].format('YYYY-MM-DD');
                fechahasta = valor[1].format('YYYY-MM-DD');
            }
        }
        setFechaDesde(fechadesde);
        setFechaHasta(fechahasta);
    };

    // cada vez que cambia el paginado
    useEffect(() => {
        let sgtePagina = {
            ...valores,
        };
        obtenerListadoRecibos(sgtePagina, paginaActualRecibo);
    }, [paginaActualRecibo]);

    // cada vez que cambia la pagina
    const changePage = (page) => {
        seleccionarPaginadoTabla(page);
    };

    const buscarHandle = (valores) => {
        //extraemos los datos de valores
        const { tipoRecibo, periodo } = valores;
        let fechaDesde = undefined;
        let fechaHasta = undefined;
        // validamos que la fecha no es este vacio y su longitud
        if (periodo !== undefined) {
            if (periodo.length > 0) {
                fechaDesde = valores.periodo[0].format('YYYY-MM-DD');
                fechaHasta = valores.periodo[1].format('YYYY-MM-DD');
            }
        }

        // datos para el backend
        const data = {
            date_start: fechaDesde,
            date_end: fechaHasta,
            type: tipoRecibo !== '' ? tipoRecibo : undefined,
        };
        obtenerListadoRecibos(data, 1);
        setValores(data);
        seleccionarPaginadoTabla(1);
    };

    // datos para la tabla
    const listadoReciboTabla = listadoRecibos.map((recibo) => {
        // desestructuracion de objetos
        const {
            franchise_society: { name, legal_name, ruc },
            id,
            concept,
            amount,
        } = recibo;
        return {
            id,
            tipoRecibo: concept,
            name,
            razon_social: legal_name,
            ruc,
            monto: formatoGuaranies(amount),
            datosOriginales: recibo,
        };
    });

    return (
        <ABMLayout titulo="Lista de recibos">
            <Card className={styles.cardFondo}>
                <Row gutter={12}>
                    <Col span={16}>
                        <Form
                            name="filtros"
                            layout="vertical"
                            form={reciboForm}
                            onFinish={buscarHandle}
                            initialValues={{ tipoRecibo: '' }}>
                            <Row gutter={12} style={{ alignItems: 'center' }}>
                                <Col span={11}>
                                    <Form.Item name="periodo" label="Periodo">
                                        <RangePicker
                                            disabled={loadingTablaRecibo}
                                            onChange={rangoFechas}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        name="tipoRecibo"
                                        label="Tipo de recibo">
                                        <Select disabled={loadingTablaRecibo}>
                                            <Option value="">Todos</Option>
                                            <Option value="Recibo de dinero Cta. Cte.">
                                                Recibo de dinero Cta. cte.
                                            </Option>
                                            <Option value="Comprobante Interno">
                                                Comprobante interno
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Form.Item style={{ marginBottom: '0' }}>
                                        <Button
                                            disabled={loadingTablaRecibo}
                                            type="primary"
                                            htmlType="submit">
                                            Buscar
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={8} style={styles.acciones}>
                        <Button
                            type="link"
                            onClick={descargarExcel}
                            disabled={!(fechaDesde && fechaHasta)}>
                            Descargar Excel
                        </Button>
                    </Col>
                </Row>
                <Divider />
                <Row gutter={12}>
                    <Col span={24}>
                        <Table
                            size="small"
                            columns={columns}
                            dataSource={listadoReciboTabla}
                            loading={loadingTablaRecibo}
                            rowKey="id"
                            pagination={{
                                pageSize: 20,
                                total: totalRecibos,
                                current: paginaActualRecibo,
                                showSizeChanger: false,
                                onChange: (page) => changePage(page),
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <Modal open={isModalVisible} footer={null} closable={null}>
                <ModalContent
                    style={{ padding: '20px' }}
                    title="Está seguro de que quiere eliminar este recibo?"
                    okText="Eliminar"
                    cancelText="Cancelar"
                    onOk={removerReciboHandler}
                    onCancel={cancelarHandler}
                    isLoading={isModalLoading}
                    showWarnIcon
                    isDelete
                    invertPrimary
                />
            </Modal>
        </ABMLayout>
    );
};

export default ListarRecibos;

const styles = {
    acciones: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    select: {
        width: '100px',
        backgroundColor: 'blue',
    },
};
