import React from 'react';
import { Modal, Table } from 'antd';
import ModalContent from '../../../../atoms/ModalContent';
import PropTypes from 'prop-types';
import classes from './FranchiseHistoryModal.module.css';
import Paragraph from 'antd/lib/typography/Paragraph';

const FranchiseHistoryModal = ({
    selectedFranchiseName,
    open,
    closeViewHistoryModal,
    isLoading,
    franchiseHistoryDataSource,
}) => {
    const historyColumns = [
        {
            title: 'Fecha y hora',
            dataIndex: 'date_time',
            key: 'date_time',
            width: 400,
        },
        {
            title: 'Estado',
            dataIndex: 'current_state',
            key: 'current_state',
            width: 400,
        },
        {
            title: 'Tipo de baja',
            dataIndex: 'disable_type',
            key: 'disable_type',
            width: 400,
        },
        {
            title: 'Motivo de baja',
            dataIndex: 'reason',
            key: 'reason',
            width: 400,
            render: (record) => (record === 'Baja Manual' ? '-' : record),
        },
        {
            title: 'Responsable',
            dataIndex: 'responsable',
            key: 'responsable',
            width: 400,
        },
    ];

    return (
        <Modal
            centered
            open={open}
            header
            width={900}
            footer={null}
            onCancel={closeViewHistoryModal}
            destroyOnClose
            className={classes.modalAdjustments}>
            <ModalContent
                onOk={closeViewHistoryModal}
                title={
                    <div>
                        Historial de altas y bajas - {selectedFranchiseName}
                        <Paragraph className={classes.helperText}>
                            Se listan los últimos 10 eventos de habilitar o
                            deshabilitar
                        </Paragraph>
                    </div>
                }
                onCancel={null}
                cancelText={null}
                okText={'Cerrar'}
                invertPrimary={false}
                closable={true}
                isLoading={isLoading}>
                <Table
                    bordered={false}
                    columns={historyColumns}
                    dataSource={franchiseHistoryDataSource}
                    className={classes.historyTable}
                    pagination={false}
                />
            </ModalContent>
        </Modal>
    );
};

export default FranchiseHistoryModal;

FranchiseHistoryModal.propTypes = {
    selectedFranchiseName: PropTypes.string,
    open: PropTypes.bool,
    isLoading: PropTypes.bool,
    closeViewHistoryModal: PropTypes.func,
    franchiseHistoryDataSource: PropTypes.array,
};
