import { Modal } from 'antd';
import React, { useContext } from 'react';
import PromotionsContext from '../../../../../context/programaRecompensa/Promociones/PromotionsContext';
import ModalContent from '../../../../atoms/ModalContent';
import { useGetPromotions } from '../../../../hooks/ProgramaRecompensa/Promociones/useGetPromotions';

const DeactivatePromotionModal = () => {
    const {
        activatePromotion,
        enable,
        id,

        setLoadingPromotionList,
        setShowDeactivatePromotionModal,
        showDeactivatePromotionModal,
    } = useContext(PromotionsContext);

    const { onRefreshList } = useGetPromotions();

    const onOk = () => {
        setLoadingPromotionList(true);
        activatePromotion(enable, id, onRefreshList);
        onCancel();
    };

    const onCancel = () => {
        setShowDeactivatePromotionModal(false);
    };

    return (
        <Modal
            centered
            open={showDeactivatePromotionModal}
            footer={null}
            closable={false}>
            <ModalContent
                isDelete
                invertPrimary
                isLoading={false}
                showWarnIcon={true}
                title="¿Estás seguro de desactivar esta promoción?"
                okText="Desactivar"
                cancelText="Cancelar"
                onOk={onOk}
                onCancel={onCancel}
            />
        </Modal>
    );
};

export default DeactivatePromotionModal;
