import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { urls } from '../../constants';
import { useMixpanel } from 'react-mixpanel-browser';
import audioSocket from '../../assets/audio/audio-support.mp3';
import logoMonchis from '../../assets/images/logoMonchisRedondo.jpg';
import authContext from '../../context/autenticacion/authContext';
import useSupport from '../../hooks/support/useSupport';

const serverPath = urls.REACT_APP_BACKEND_URL_WS_SUPPORT;
const tiempoValidacionReconexionSocket = 1000 * 60 * 10; // Diez minutos en milisegundos
const socketReadyState = {
    CONNECTING: 0,
    OPEN: 1,
    CLOSING: 2,
    CLOSED: 3,
};
const APOSTALA_FRANCHISE_ID = 1846;
const APOSTALA_BRANCH_ID = 2382;
const SALDO_FRANCHISE_ID = 2209;
const SALDO_BRANCH_ID = 2879;

const audio = new Audio(audioSocket);

export const useSocketSupport = () => {
    const [online, setOnline] = useState(false);
    const socketRef = useRef();
    const mixpanel = useMixpanel();
    const {
        obtenerTodasOrdenes,
        actualizarOrdenesWS,
        setHasPermissionsLocked,
    } = useSupport();
    const { token } = useContext(authContext);

    const playAudio = useCallback(() => {
        audio.play();
    }, []);

    const desconectarSocket = useCallback(() => {
        socketRef.current?.close();
        setOnline(false);
    }, []);

    const conectarSocket = useCallback(() => {
        if (token) {
            try {
                socketRef.current = new WebSocket(
                    `${serverPath}/?token=${token}`,
                );

                socketRef.current.onmessage = (message) => {
                    const objResponse = JSON.parse(message.data);
                    // TODO: Evitar enviar la orden de apostala y la carga de saldo desde el backend
                    if (
                        (objResponse?.franchise_id === APOSTALA_FRANCHISE_ID &&
                            objResponse?.branch_id === APOSTALA_BRANCH_ID) ||
                        (objResponse?.franchise_id === SALDO_FRANCHISE_ID &&
                            objResponse?.branch_id === SALDO_BRANCH_ID)
                    ) {
                        return;
                    }
                    if (
                        objResponse?.order_state_id === 3 &&
                        !objResponse?.mcdonals_external_invoice_id &&
                        !objResponse?.sent_to_integration &&
                        !objResponse?.updated
                    ) {
                        playAudio();
                        notificacionBrowser('', 'Tiene una nueva orden');
                    }
                    actualizarOrdenesWS(objResponse);
                };

                socketRef.current.onerror = (error) => {
                    mixpanel.track('support_socket_error', {
                        error,
                        token,
                    });
                    desconectarSocket();
                };

                setOnline(true);
                permisoNotificacionBrowser(setHasPermissionsLocked);
            } catch (error) {
                mixpanel.track('support_socket_connection_error', {
                    error,
                    token,
                });
                desconectarSocket();
            }
        } else {
            desconectarSocket();
        }
    }, [token, actualizarOrdenesWS, playAudio, mixpanel, desconectarSocket]);

    useEffect(() => {
        if (!online) {
            conectarSocket();
            // Forzar reconexion cada X minutos
            const forzarReconexion = setInterval(() => {
                const socketState = socketRef.current?.readyState;
                if (
                    socketState !== socketReadyState.CONNECTING ||
                    socketState !== socketReadyState.OPEN
                ) {
                    obtenerTodasOrdenes();
                    conectarSocket();
                }
            }, tiempoValidacionReconexionSocket);
            return () => {
                clearInterval(forzarReconexion);
                desconectarSocket();
            };
        }
    }, []);

    return { online, conectarSocket, playAudio };
};

const notificacionBrowser = (titulo, cuerpo, logo) => {
    let notification = new Notification(titulo, {
        icon: logo || logoMonchis,
        body: cuerpo,
    });
    notification.onclick = function () {
        window.focus();
    };
};

const permisoNotificacionBrowser = (setHasPermissionsLocked) => {
    // Comprobamos si el navegador soporta las notificaciones
    if (!('Notification' in window)) {
        alert(
            'Este navegador no es compatible con las notificaciones de escritorio',
        );
    }

    // Si no, pedimos permiso para la notificación
    else if (Notification.permission !== 'denied') {
        setHasPermissionsLocked(false);
        Notification.requestPermission().then(function (permission) {
            // Si el usuario nos lo concede, creamos la notificación
            if (permission === 'granted') {
                notificacionBrowser(
                    'Monchis',
                    'Las notificaciones han sido activadas',
                );
            } else {
                setHasPermissionsLocked(true);
            }
        });
    } else if (Notification.permission === 'denied') {
        setHasPermissionsLocked(true);
    }
};
