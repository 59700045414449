import {
    OBTENER_LISTADO_TIMBRADOS_TABLA,
    OBTENER_LISTADO_TIMBRADOS,
    SELECCIONAR_PAGINA_TIMBRADO,
    TIMBRADOS_TOTALES,
    BUSQUEDA_TIMBRADO,
} from '../../../types';

export const initialState = {
    listadoTimbradosTabla: [],
    listadoTimbrados: [],
    paginaActual: 1,
    totalTimbrados: '',
};

const TimbradoReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_LISTADO_TIMBRADOS_TABLA:
            return {
                ...state,
                listadoTimbradosTabla: action.payload,
                paginaActual: state.totalTimbrados ? state.paginaActual : 1,
            };
        case OBTENER_LISTADO_TIMBRADOS:
            return {
                ...state,
                listadoTimbrados: action.payload,
            };
        case SELECCIONAR_PAGINA_TIMBRADO:
            return {
                ...state,
                paginaActual: action.payload,
            };
        case TIMBRADOS_TOTALES:
            return {
                ...state,
                totalTimbrados: action.payload,
            };
        case BUSQUEDA_TIMBRADO:
            return {
                ...state,
                listadoTimbradosTabla: state.listadoTimbradosTabla.filter(
                    (timbrado) =>
                        timbrado.timbrado
                            .toString()
                            .includes(action.payload.toString()) ||
                        timbrado.id
                            .toString()
                            .includes(action.payload.toString()),
                ),
            };
        default:
            return state;
    }
};

export default TimbradoReducer;
