import React from 'react';
import { Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import classes from './Integraciones.module.css';
import PropTypes from 'prop-types';

const BotonPasarSucursalComponent = (props) => {
    const {
        envioIntegracion,
        esJedi,
        abrirModalEnviarJedi,
        abrirModalEnviarIntegracion,
        ordenSeleccionada,
        esVerDetalle,
    } = props;

    const className = [
        classes.botonIntegraciones,
        envioIntegracion ? classes.botonIntegracionesDisabled : '',
    ];
    const concatClassNameToString = className.join(' ');

    const conditions = {
        ifIsJedi: esJedi
            ? () => abrirModalEnviarJedi(ordenSeleccionada, esVerDetalle)
            : () =>
                  abrirModalEnviarIntegracion(ordenSeleccionada, esVerDetalle),
        ifIsNotIntegration: !envioIntegracion && <RightOutlined />,
        ifIsIntegration: envioIntegracion ? 'ENVIADO A SUCURSAL' : 'A SUCURSAL',
    };

    return (
        <Button
            disabled={envioIntegracion}
            className={concatClassNameToString}
            onClick={conditions.ifIsJedi}>
            {conditions.ifIsIntegration}
            <span
                role="img"
                aria-label="right"
                className="anticon anticon-right">
                <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="right"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true">
                    <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                </svg>
            </span>
        </Button>
    );
};

BotonPasarSucursalComponent.propTypes = {
    envioIntegracion: PropTypes.bool,
    esJedi: PropTypes.bool,
    abrirModalEnviarJedi: PropTypes.func,
    abrirModalEnviarIntegracion: PropTypes.func,
    ordenSeleccionada: PropTypes.any,
    esVerDetalle: PropTypes.bool,
};

export default BotonPasarSucursalComponent;
