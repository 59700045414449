import React, { useState, useRef, useContext } from 'react';
import { Card, Typography, Input, Row, Form, Col, Tooltip } from 'antd';
import { InfoCircleOutlined, SmileOutlined } from '@ant-design/icons';
import styles from './FormItemCustomizeNotifications.module.css';
import Emoji from '../../Emoji';
import PropTypes from 'prop-types';
import CuponesContext from '../../../../context/programaRecompensa/cupones/CuponesContext';
import { useEffect } from 'react';

const FormItemCustomizeNotifications = ({ formInstance, mode }) => {
    const { Title } = Typography;
    const { TextArea } = Input;

    const { setCustomizedNotification, verifySMSRules } =
        useContext(CuponesContext);

    const refPushTitle = useRef(null);
    const refPushMessage = useRef(null);

    const [emojiPickerTitleVisible, setEmojiPickerTitleVisible] =
        useState(false);
    const [emojiPickerMessageVisible, setEmojiPickerMessageVisible] =
        useState(false);

    const [SMSWithoutCharacters, setSMSWithoutCharacters] = useState(true);

    const [pushTitle, setPushTitle] = useState('');
    const [pushMessage, setPushMessage] = useState('');
    const [smsMessage, setSmsMessage] = useState('');

    const emojiTitleHandler = (emojiData, event) => {
        const cursorTitle =
            refPushTitle?.current?.resizableTextArea?.textArea?.selectionStart;
        const textBeforeCursor = pushTitle.slice(0, cursorTitle);
        const textAfterCursor = pushTitle.slice(cursorTitle);
        const emoji = event.emoji;
        const title = textBeforeCursor + emoji + textAfterCursor;
        setPushTitle(title);
        formInstance.setFieldsValue({ pushTitle: title });
        setEmojiPickerTitleVisible(false);
    };

    const emojiMessageHandler = (emojiData, event) => {
        const cursorMessage =
            refPushMessage?.current?.resizableTextArea?.textArea
                ?.selectionStart;
        const textBeforeCursor = pushMessage.slice(0, cursorMessage);
        const textAfterCursor = pushMessage.slice(cursorMessage);
        const emoji = event.emoji;
        const message = textBeforeCursor + emoji + textAfterCursor;
        setPushMessage(message);
        formInstance.setFieldsValue({ pushMessage: message });
        setEmojiPickerMessageVisible(false);
    };

    useEffect(() => {
        !pushTitle.length || !postMessage.length || !smsMessage.length
            ? setCustomizedNotification(false)
            : setCustomizedNotification(true);
    }, [pushTitle, pushMessage, smsMessage]);

    useEffect(() => {
        const correctMessage = verifySMSRules(smsMessage);
        setSMSWithoutCharacters(correctMessage);
    }, [smsMessage]);

    return (
        <Card
            title={
                <>
                    Personalizar notificaciones{' '}
                    <Tooltip title="En caso de no completar estos campos, se envían notificaciones por defecto.">
                        <InfoCircleOutlined />
                    </Tooltip>
                </>
            }>
            <Title level={5}>Push</Title>

            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Título" name="pushTitle">
                                <TextArea
                                    disabled={mode === 'EDITAR' ? true : false}
                                    className={styles.textAreaPush}
                                    showCount
                                    rows="1"
                                    maxLength={55}
                                    ref={refPushTitle}
                                    onChange={(event) =>
                                        setPushTitle(event.target.value)
                                    }
                                />
                            </Form.Item>
                            <SmileOutlined
                                className={styles.icon}
                                onClick={() =>
                                    setEmojiPickerTitleVisible(
                                        !emojiPickerTitleVisible,
                                    )
                                }
                                onChange={(event) =>
                                    setPushMessage(event.target.value)
                                }
                            />
                            {emojiPickerTitleVisible && mode !== 'EDITAR' && (
                                <Emoji pickEmoji={emojiTitleHandler} />
                            )}
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item label="Mensaje" name="pushMessage">
                                <TextArea
                                    disabled={mode === 'EDITAR' ? true : false}
                                    className={styles.textAreaPush}
                                    showCount
                                    rows="1"
                                    maxLength={200}
                                    ref={refPushMessage}
                                    onChange={(event) =>
                                        setPushMessage(event.target.value)
                                    }
                                />
                            </Form.Item>
                            <SmileOutlined
                                className={styles.icon}
                                onClick={() =>
                                    setEmojiPickerMessageVisible(
                                        !emojiPickerMessageVisible,
                                    )
                                }
                            />
                            {emojiPickerMessageVisible && mode !== 'EDITAR' && (
                                <Emoji pickEmoji={emojiMessageHandler} />
                            )}
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Title level={5}>Mensajes de texto</Title>
            <Row>
                <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="smsMessage"
                                label={
                                    <>
                                        Mensaje
                                        <Tooltip title="Este campo acepta texto en mayúscula y minúscula. Sin acentos ni emojis.">
                                            <InfoCircleOutlined
                                                className={styles.info}
                                            />
                                        </Tooltip>
                                        {!SMSWithoutCharacters ? (
                                            <p className={styles.errorMessage}>
                                                El mensaje no debe tener emojis
                                                ni acentos
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                }>
                                <TextArea
                                    disabled={mode === 'EDITAR' ? true : false}
                                    className={styles.textAreaSMS}
                                    showCount
                                    rows="3"
                                    maxLength={160}
                                    suffix={<SmileOutlined />}
                                    onChange={(event) =>
                                        setSmsMessage(event.target.value)
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Card>
    );
};

export default FormItemCustomizeNotifications;

FormItemCustomizeNotifications.propTypes = {
    formInstance: PropTypes.object,
    mode: PropTypes.string,
};
