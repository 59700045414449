import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    Divider,
    Select,
    Button,
    Card,
    Table,
    Avatar,
} from 'antd';
import classes from './ListaDetalleComisiones.module.css';
import DatePicker from '../../../../atoms/DatePicker';
import {
    CalculatorOutlined,
    InfoOutlined,
    SearchOutlined,
    DownloadOutlined,
} from '@ant-design/icons';
import DetalleComisionesContext from '../../../../../context/reportes/ventas/detalleComisiones/DetalleComisionesContext';
import { formatoFechaSinHora } from '../../../../utils-components/Date';
import {
    formatoGuaranies,
    formatoGuaraniesRedondeadoEnteroCercano,
    formatoNumeros,
} from '../../../../utils-components/Number';
import clienteAxios from '../../../../../config/axios';
import { useConsultasDetalleComision } from './consultas/useConsultasDetalleComision';
import useRouterQuery from 'hooks/useRouterQueries';

const ListaDetalleComisiones = () => {
    const history = useHistory();

    const {
        obtenerDetalleComisiones,
        listadoDetalleComsiones,
        paginaActualDetalleComisiones,
        seleccionarPaginadoTabla,
        totalDetalleComisiones,
        loadingTablaObtenerDetalle,
        totalComision,
    } = useContext(DetalleComisionesContext);

    const {
        obtenerFranquicias,
        obtenerSucursales,
        descargarDetalleComision,
        descargaExcel,
        franquicias,
        sucursales,
    } = useConsultasDetalleComision();

    const { createQueryParams } = useRouterQuery();

    //state
    const [obtenerValores, setObtenerValores] = useState({
        sucursales: [],
    });
    const [sucursalDisabled, setSucursalDisabled] = useState(true);
    const [ordenEstado, setOrdenEstado] = useState([]);
    const [obtenerValoresForm, setObtenerValoresForm] = useState({});
    const [fechaDesde, setFechaDesde] = useState('');
    const [fechaHasta, setFechaHasta] = useState('');
    const [seleccionFranquicia, setSeleccionFranquicia] = useState([]);
    const [descargarDisabled, setDescargarDisabled] = useState(true);

    //instancia form
    const [detalleComisionForm] = Form.useForm();

    const columns = [
        {
            title: '#',
            dataIndex: 'numero',
            key: 'numero',
            // render: (text) => <span>#{text}</span>,
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            key: 'fecha',
        },
        {
            title: 'Franquicia',
            dataIndex: 'franquicia',
            key: 'franquicia',
        },
        {
            title: 'Sucursal',
            dataIndex: 'sucursal',
            key: 'sucursal',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Costo delivery',
            dataIndex: 'costoDelivery',
            key: 'costoDelivery',
        },
        {
            title: 'Tipo entrega',
            dataIndex: 'tipoEntrega',
            key: 'tipoEntrega',
        },
        {
            title: 'Categoría',
            dataIndex: 'categoria',
            key: 'categoria',
        },
        {
            title: 'Con driver',
            dataIndex: 'conDriver',
            key: 'conDriver',
        },
        {
            title: 'Comisión orden',
            dataIndex: 'comisionOrden',
            key: 'comisionOrden',
        },
        {
            title: 'Comisión driver',
            dataIndex: 'comisionDriver',
            key: 'comisionDriver',
        },
        {
            title: 'Total comisión',
            dataIndex: 'totalComision',
            key: 'totalComision',
        },
        {
            title: 'Total comisión con delivery',
            dataIndex: 'totalComisionConDelivery',
            key: 'totalComisionConDelivery',
        },
        {
            title: 'Comisión orden %',
            dataIndex: 'comisionOrdenPorcentaje',
            key: 'comisionOrdenPorcentaje',
        },
        {
            title: 'Comisión driver %',
            dataIndex: 'comisionDriverPorcentaje',
            key: 'comisionDriverPorcentaje',
        },
        {
            title: 'Total comisión %',
            dataIndex: 'totalComisionPorcentaje',
            key: 'totalComisionPorcentaje',
        },
        {
            title: 'Comisión con delivery',
            dataIndex: 'comisionDelivery',
            key: 'comisionDelivery',
        },
        {
            title: ' ',
            dataIndex: 'detalles',
            render: (_, record) => (
                <Button
                    shape="circle"
                    icon={<SearchOutlined />}
                    onClick={() => verOrdenBotonHandler(record)}
                />
            ),
        },
    ];

    useEffect(() => {
        obtenerFranquicias();
    }, []);

    useEffect(() => {
        obtenerSucursales();
    }, []);

    useEffect(() => {
        estadoOrden();
    }, []);

    useEffect(() => {
        if (!fechaDesde && !fechaHasta) return;
        let sgtePagina = {
            ...obtenerValoresForm,
            page: paginaActualDetalleComisiones,
        };
        obtenerDetalleComisiones(sgtePagina);
    }, [paginaActualDetalleComisiones]);

    useEffect(() => {
        let { franquiciaId } = obtenerValores;
        let deshabilitarSucursal = true;
        deshabilitarSucursal &&
            detalleComisionForm.setFieldsValue({ sucursales: undefined });
        if (franquiciaId) {
            // Permitir seleccionar 'TODAS' luego de marcar franquicias
            if (franquiciaId[franquiciaId.length - 1] === '') {
                detalleComisionForm.setFieldsValue({ franquiciaId: [''] });
            } else {
                // Cuando ya esta seleccionado 'TODAS' pero se marca otra franquicia, remover la opcion de 'TODAS'
                franquiciaId = franquiciaId.filter((id) => id !== '');
                if (franquiciaId.length > 0) {
                    // Actualizar datos del form
                    detalleComisionForm.setFieldsValue({ franquiciaId });
                    // Obtener sucursales
                    obtenerSucursales(franquiciaId);
                    // Habilitar select de sucursales
                    deshabilitarSucursal = false;
                } else {
                    detalleComisionForm.setFieldsValue({ franquiciaId: [''] });
                }
            }
        }
        setSucursalDisabled(deshabilitarSucursal);
    }, [seleccionFranquicia]);

    const seleccionarFranquicia = (valor) => {
        setSeleccionFranquicia(valor);
    };

    const rangoFechas = (valor) => {
        let fechadesde = null;
        let fechahasta = null;
        if (valor !== null) {
            if (valor.length > 0) {
                fechadesde = valor[0].format('YYYY-MM-DD');
                fechahasta = valor[1].format('YYYY-MM-DD');
            }
        }
        setFechaDesde(fechadesde);
        setFechaHasta(fechahasta);
    };

    const handleSubmit = (values) => {
        let datos = {
            date_start: values?.periodo[0].format('YYYY/MM/DD'),
            date_end: values?.periodo[1].format('YYYY/MM/DD'),
            franchise_id:
                values?.franquiciaId[0] !== ''
                    ? values?.franquiciaId.toString()
                    : undefined,
            branch_id:
                values.sucursales?.length >= 1
                    ? values.sucursales.toString()
                    : undefined,
            has_monchis_driver: values.conDriver
                ? values?.conDriver
                : undefined,
            state_id:
                values.estados?.length > 0
                    ? values?.estados.toString()
                    : undefined,
            page: 1,
        };
        obtenerDetalleComisiones(datos);
        setObtenerValoresForm(datos);
        handlerDescargarExcel({
            ...datos,
            page: undefined,
            branch_id: undefined,
            state_id: undefined,
        });
        seleccionarPaginadoTabla(1);
        setDescargarDisabled(false);
    };

    const estadoOrden = async () => {
        try {
            const { data } = await clienteAxios.get('order_states');
            setOrdenEstado(data?.data?.states);
        } catch (error) {
            console.error(error);
        }
    };

    const datosTabla = listadoDetalleComsiones.map((detalle) => {
        const {
            order_id,
            state,
            date_time,
            franchise,
            branch,
            delivery_type,
            total,
            delivery_price,
            franchise_category,
            // total_with_delivery,
            commission_order,
            commission_driver,
            total_commission,
            commission_percent: commissionPercent,
            driver_commission_percentge: driverCommissionPercentge,
            total_commission_percent: totalCommissionPercent,
            total_total_commission,
            has_monchis_driver: hasMonchisDriver,
            commission_order_plus_delivery: commissionOrderPlusDelivery,
        } = detalle;
        let tieneDriver = hasMonchisDriver ? 'Si' : 'No';
        const comisionDelivery = commissionOrderPlusDelivery ? 'Si' : 'No';
        return {
            numero: order_id,
            estado: state,
            fecha: formatoFechaSinHora(date_time),
            franquicia: franchise,
            sucursal: branch,
            total: formatoGuaranies(total),
            costoDelivery: formatoGuaranies(delivery_price),
            tipoEntrega: delivery_type,
            categoria: franchise_category,
            conDriver: tieneDriver,
            comisionOrden: formatoGuaranies(commission_order),
            comisionDriver: commission_driver,
            totalComision: formatoGuaranies(total_commission),
            totalComisionConDelivery: formatoGuaranies(total_total_commission),
            comisionOrdenPorcentaje: `${+commissionPercent}%`,
            comisionDriverPorcentaje: `${driverCommissionPercentge}%`,
            totalComisionPorcentaje: `${+totalCommissionPercent}%`,
            comisionDelivery: comisionDelivery,
        };
    });

    const changePage = (page) => {
        seleccionarPaginadoTabla(page);
    };

    const handlerDescargarExcel = (data) => {
        descargarDetalleComision(data);
    };

    const verOrdenBotonHandler = (record) => {
        const orderId = record.numero;
        const queryParams = createQueryParams({ order_id: orderId });
        history.push(
            `/reportes/ventas/detalle-transaccion/ver-orden${queryParams}`,
        );
    };

    // useEffect(() => {
    //     let dataStorage = {};
    //     let dataParse = {};
    //     if (localStorage.getItem('data')) {
    //         dataStorage = localStorage.getItem('data');
    //         dataParse = JSON.parse(dataStorage);
    //         setObtenerValores(dataParse);
    //         detalleComisionForm.setFieldsValue({
    //             periodo: [moment(dataParse?.date_start), moment(dataParse?.date_end)],
    //             franquiciaId: dataStorage?.franchise_id,
    //             conDriver: dataStorage?.has_monchis_driver,
    //             estados: dataStorage?.state_id,
    //         });
    //     } else {
    //         localStorage.removeItem('data');
    //     }
    // }, []);

    return (
        <>
            {/* Formulario */}
            <Form
                name="basic"
                layout="vertical"
                form={detalleComisionForm}
                onFinish={handleSubmit}
                initialValues={{ franquiciaId: [''] }}
                onValuesChange={(valores) => setObtenerValores(valores)}>
                <div className={classes.cardForm}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Row gutter={24}>
                                <Col md={8} xl={8}>
                                    <Form.Item
                                        name="periodo"
                                        label="Periodo"
                                        rules={[
                                            {
                                                required: true,
                                                message:
                                                    'este campo no puede ir vacio',
                                            },
                                        ]}>
                                        <DatePicker
                                            onChange={rangoFechas}
                                            disabled={
                                                loadingTablaObtenerDetalle
                                            }
                                            format={'DD/MM/YYYY'}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="franquiciaId"
                                        label="Seleccionar Franquicia">
                                        <Select
                                            mode="multiple"
                                            onChange={seleccionarFranquicia}
                                            disabled={
                                                loadingTablaObtenerDetalle
                                            }
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {franquicias &&
                                                franquicias.map((item) => (
                                                    <Select.Option
                                                        key={item.franchise_id}
                                                        value={
                                                            item.franchise_id
                                                        }>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24} align="bottom">
                                <Col md={8} xl={8}>
                                    <Form.Item
                                        name="sucursales"
                                        label="Sucursales">
                                        <Select
                                            name="sucursales"
                                            mode="multiple"
                                            disabled={sucursalDisabled}
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {sucursales &&
                                                sucursales.map((item) => (
                                                    <Select.Option
                                                        key={item.branch_id}
                                                        value={item.branch_id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={8} xl={6}>
                                    <Form.Item
                                        name="conDriver"
                                        label="Con driver">
                                        <Select
                                            allowClear
                                            disabled={
                                                loadingTablaObtenerDetalle
                                            }
                                            placeholder="Seleccione una opcion">
                                            <Select.Option
                                                key={1}
                                                value="not null">
                                                Si
                                            </Select.Option>
                                            <Select.Option key={2} value="null">
                                                No
                                            </Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col md={8} xl={6}>
                                    <Form.Item name="estados" label="Estados">
                                        <Select
                                            showSearch
                                            mode="multiple"
                                            placeholder="Seleccione una opcion"
                                            disabled={
                                                loadingTablaObtenerDetalle
                                            }
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {ordenEstado &&
                                                ordenEstado.map((item) => (
                                                    <Select.Option
                                                        key={item.id}
                                                        value={item.id}>
                                                        {item.name}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={4} style={{ marginTop: 'auto' }}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    block={true}
                                    htmlType="submit"
                                    disabled={loadingTablaObtenerDetalle}>
                                    Ver Resumen
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'right' }}>
                        <Button
                            href={descargaExcel}
                            loading={loadingTablaObtenerDetalle}
                            disabled={descargarDisabled}
                            icon={<DownloadOutlined />}>
                            Descargar
                        </Button>
                    </div>
                </div>
            </Form>
            <Divider style={{ border: 'none', margin: '0.5rem 0' }} />

            {/* Tarjetas */}
            <Row gutter={24} justify="start" style={{ margin: '30px 0' }}>
                <Col span={8}>
                    <Card className={classes.cardPrincipales}>
                        <div
                            className={
                                loadingTablaObtenerDetalle
                                    ? classes.bloquearCard
                                    : null
                            }>
                            <div className={classes.cardTransaccion}>
                                <Avatar
                                    style={{ backgroundColor: '#3B82F6' }}
                                    size={64}
                                    icon={<InfoOutlined />}
                                />
                                <div className={classes.iconoNumeroTotal}>
                                    <div className={classes.cantidadTotal}>
                                        <strong>
                                            {formatoNumeros(
                                                totalDetalleComisiones,
                                            )}
                                        </strong>
                                    </div>
                                    <div className={classes.iconoSub}>
                                        <small>Cantidad de Órdenes</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card className={classes.cardPrincipales}>
                        <div
                            className={
                                loadingTablaObtenerDetalle
                                    ? classes.bloquearCard
                                    : null
                            }>
                            <div className={classes.cardTransaccion}>
                                <Avatar
                                    style={{ backgroundColor: '#8B5CF6' }}
                                    size={64}
                                    icon={<CalculatorOutlined />}
                                />
                                <div className={classes.iconoNumeroTotal}>
                                    <div className={classes.cantidadTotal}>
                                        <strong>
                                            {totalComision
                                                ? formatoGuaraniesRedondeadoEnteroCercano(
                                                      totalComision,
                                                  )
                                                : 0}
                                        </strong>
                                    </div>
                                    <div className={classes.iconoSub}>
                                        <small>
                                            Total Comisión con Delivery Monchis
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>

            <Divider style={{ border: 'none', margin: '1.2rem 0' }} />

            <Table
                columns={columns}
                dataSource={datosTabla}
                loading={loadingTablaObtenerDetalle}
                rowKey="id"
                scroll={{ x: 2800 }}
                pagination={{
                    pageSize: 20,
                    total: totalDetalleComisiones,
                    current: paginaActualDetalleComisiones,
                    showSizeChanger: false,
                    onChange: (page) => changePage(page),
                }}
            />
        </>
    );
};
export default ListaDetalleComisiones;
