import { Form, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clienteAxios from '../../../../../config/axios';
import classes from '../../../../pages/Reportes/Ventas/ReporteBalance/ListaReporteBalance.module.css';

const { Option } = Select;

const FormItemSelectFranquicia = ({
    formInstance,
    formItemProps,
    selectProps,
    disabled,
    showRuc,
    initialValue,
}) => {
    const [loading, setLoading] = useState(false);
    const [franquicias, setFranquicias] = useState();
    const [formItemInitialValue, setFormItemInitialValue] = useState(null);

    useEffect(() => {
        obtenerFranquicias();
    }, []);

    useEffect(() => {
        if (initialValue)
            setFormItemInitialValue({ franquiciaId: initialValue.toString() });
    }, [initialValue]);

    useEffect(() => {
        formInstance.setFieldsValue(formItemInitialValue);
    }, [formItemInitialValue]);

    // Formulario - Obtener datos
    const obtenerFranquicias = async () => {
        let arrFranquicias = [{ franchise_id: '', name: 'Todas' }];
        setLoading(true);
        try {
            const respuesta = await clienteAxios.get('/franchises');
            arrFranquicias = [
                ...arrFranquicias,
                ...respuesta?.data?.data?.franchises,
            ];
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener la lista de franquicias',
            );
        }
        setLoading(false);
        setFormItemInitialValue((state) =>
            state?.franquiciaId
                ? { ...state }
                : { franquiciaId: arrFranquicias[0].franchise_id },
        );
        setFranquicias(arrFranquicias);
    };

    return (
        <Form.Item
            name="franquiciaId"
            label={showRuc ? 'RUC' : 'Franquicia'}
            {...formItemProps}
            className={classes.filters}>
            <Select
                {...selectProps}
                disabled={disabled || loading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                }>
                {franquicias &&
                    franquicias.map((item) => (
                        <Option
                            key={item.franchise_id}
                            value={item.franchise_id}>
                            {showRuc ? item.ruc : item.name}
                        </Option>
                    ))}
            </Select>
        </Form.Item>
    );
};

export default FormItemSelectFranquicia;

FormItemSelectFranquicia.propTypes = {
    formItemProps: PropTypes.any,
    selectProps: PropTypes.any,
    formInstance: PropTypes.instanceOf(Form),
    disabled: PropTypes.bool,
    showRuc: PropTypes.bool,
    initialValue: PropTypes.object,
};
