import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Row,
    Col,
    Space,
    Form,
    Input,
    Select,
    message,
    Spin,
} from 'antd';
import PropTypes from 'prop-types';
import ABMLayout from '../../../../../layouts/ABMLayout';
import axios from '../../../../../config/axios';
import { urls } from '../../../../../constants';
import { useHistory } from 'react-router-dom';
import { useMixpanel } from 'react-mixpanel-browser';

const validateMessages = {
    required: '${label} es un campo requerido.',
    types: {
        email: '${label} is not validate email!',
    },
};

const formFields = [
    'contact_name',
    'phone',
    'email',
    'address',
    'name',
    'legal_name',
    'ruc',
    'franchise_id',
];

const FranquiciasForm = ({ mode, initialValues }) => {
    const [procesandoForm, setProcesandoForm] = useState(false);
    const [listaFranquicias, setListaFranquicias] = useState(false);
    const [franquiciaForm] = Form.useForm();
    const history = useHistory();
    const mixpanel = useMixpanel();

    useEffect(() => {
        obtenerFranquicias();
    }, []);

    useEffect(() => {
        switch (mode) {
            case 'ADD':
                // Continuar sin cambios
                break;
            case 'EDIT':
                franquiciaForm.setFieldsValue({
                    contact_name: initialValues?.contact_name,
                    phone: initialValues?.phone,
                    email: initialValues?.email,
                    address: initialValues?.address,
                    name: initialValues?.name,
                    legal_name: initialValues?.legal_name,
                    ruc: initialValues?.ruc,
                    franchise_id: initialValues?.franchise_id,
                });
                break;

            default:
                break;
        }
    }, [listaFranquicias, mode, initialValues]);

    const obtenerFranquicias = async () => {
        setProcesandoForm(true);
        let arrFranquicias = [];

        try {
            const respuesta = await axios.get('/franchises_list');

            arrFranquicias = respuesta.data.data.franchises.map((item) => ({
                ...item,
                key: item.id,
            }));
        } catch (error) {
            message.error(error.response.data.message);
        }

        setListaFranquicias(arrFranquicias);
        setProcesandoForm(false);
    };

    const guardarButtonHandler = () => {
        franquiciaForm
            .validateFields(formFields)
            .then((values) => {
                let email = values?.email.split(',');

                email.forEach((item) => {
                    if (
                        !String(item)
                            .toLowerCase()
                            .match(
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            )
                    )
                        throw {
                            message: 'Verifique el campo de correo electronico',
                        };
                });
                switch (mode) {
                    case 'ADD':
                        nuevaFranquicia(values);

                        break;
                    case 'EDIT':
                        editarFranquicia(values);

                        break;

                    default:
                        break;
                }
            })
            .catch((error) => {
                message.warning(
                    error?.message ||
                        'Por favor complete los campos obligatorios.',
                );
            });
    };

    const cancelarButtonHandler = () => {
        history.goBack();
    };

    const nuevaFranquicia = async (franchise) => {
        setProcesandoForm(true);

        try {
            const body = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                ...franchise,
            };
            const respuesta = await axios.post('create_franchise', body);

            message.success(respuesta.data.data.data);
            mixpanel.track('franchises_list_franchise_add_success');
            history.goBack();
        } catch (error) {
            message.error(error.response.data.message);
            mixpanel.track('franchises_list_franchise_add_error');
        }

        setProcesandoForm(false);
    };

    const editarFranquicia = async (franchise) => {
        setProcesandoForm(true);

        try {
            const body = {
                axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
                ...franchise,
                id: initialValues?.id,
            };
            const respuesta = await axios.put('update_franchise', body);

            message.success(respuesta?.data?.data?.data);
            mixpanel.track('franchises_list_franchise_edit_success');
            history.goBack();
        } catch (error) {
            const parsedError =
                error?.response?.data?.message ||
                error?.message ||
                'Hubo un problema al editar esta franquicia';
            message.error(parsedError);
            mixpanel.track('franchises_list_franchise_edit_error', {
                error: parsedError,
            });
        }

        setProcesandoForm(false);
    };

    return (
        <ABMLayout
            titulo={mode === 'ADD' ? 'Nueva franquicia' : 'Editar franquicia'}
            acciones={
                <Space>
                    <Button onClick={cancelarButtonHandler}>Cancelar</Button>
                    <Button type="primary" onClick={guardarButtonHandler}>
                        Guardar
                    </Button>
                </Space>
            }>
            <Spin spinning={procesandoForm}>
                <Form
                    name="franquiciaForm"
                    form={franquiciaForm}
                    layout="vertical"
                    validateMessages={validateMessages}>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Card title="Contacto">
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Nombre"
                                            name="contact_name"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Teléfono"
                                            name="phone"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Form.Item
                                    label="Dirección de correo"
                                    name="email"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Dirección"
                                    name="address"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card title="Franquicia">
                                <Form.Item
                                    label="Nombre"
                                    name="name"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Nombre en la factura"
                                            name="legal_name"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="RUC"
                                            name="ruc"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item label="Marca" name="franchise_id">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        placeholder="-- Seleccione una marca --"
                                        disabled={mode === 'EDIT'}>
                                        {listaFranquicias &&
                                            listaFranquicias.map((item) => (
                                                <Select.Option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ABMLayout>
    );
};

export default FranquiciasForm;

FranquiciasForm.propTypes = {
    mode: PropTypes.string,
    initialValues: PropTypes.object,
};
