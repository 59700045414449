import React from 'react';
import { Button, Col, Row, Tag, Typography, Avatar } from 'antd';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';
import { formatoFechaSinHora } from '../../../utils-components/Date';
import PropTypes from 'prop-types';

const { Title, Text } = Typography;

const SeasonItem = ({
    title,
    isSeasonExpired,
    fromDate,
    toDate,
    description,
    userLevelList,
    editButtonDisabled,
    onEdit,
    copyButtonDisabled,
    onCopy,
    showCopy,
    disableButtonDisabled,
    onDisable,
}) => {
    const userLevelListHasData = Boolean(userLevelList?.length);
    return (
        <Row gutter={12} style={styles.seasonItemContainer}>
            <Col span={18}>
                <Row gutter={12}>
                    <Col>
                        <Title level={4}>{title}</Title>
                    </Col>

                    {isSeasonExpired && (
                        <Col>
                            <Tag color="blue">Temporada pasada</Tag>
                        </Col>
                    )}
                </Row>
                <Row gutter={[12, 12]}>
                    <Col span={24}>
                        <Text type="secondary">{`${formatoFechaSinHora(
                            fromDate,
                        )} - ${formatoFechaSinHora(toDate)}`}</Text>
                    </Col>
                    <Col span={24}>{description}</Col>
                    <Col>
                        <Text underline strong>
                            Niveles de usuario relacionados:
                        </Text>
                    </Col>
                    <Col flex={1}>
                        <Row gutter={12}>
                            {userLevelListHasData ? (
                                <>
                                    {userLevelList?.map((userLevelItem) => (
                                        <Col
                                            key={userLevelItem?.id}
                                            style={
                                                styles.seasonRewardsContainer
                                            }>
                                            <Avatar
                                                style={{
                                                    ...styles.seasonRewardColor,
                                                    backgroundColor:
                                                        userLevelItem?.colours,
                                                }}
                                                size="small"
                                            />
                                            <Text
                                                style={styles.seasonRewardName}>
                                                {userLevelItem?.name}
                                            </Text>
                                        </Col>
                                    ))}
                                </>
                            ) : (
                                <Text>Sin niveles</Text>
                            )}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={6} style={styles.seasonItemActionsContainer}>
                <ProtectedComponent
                    permiso={
                        Permisos.programaRecompensa.temporadas
                            .crearEditarTemporada
                    }>
                    <Button
                        onClick={onEdit}
                        type="link"
                        disabled={editButtonDisabled}>
                        Editar
                    </Button>
                    {showCopy && (
                        <Button
                            onClick={onCopy}
                            type="link"
                            disabled={copyButtonDisabled}>
                            Duplicar
                        </Button>
                    )}
                    <Button
                        onClick={onDisable}
                        type="link"
                        disabled={disableButtonDisabled}>
                        Deshabilitar
                    </Button>
                </ProtectedComponent>
            </Col>
        </Row>
    );
};

export default SeasonItem;

const styles = {
    seasonItemContainer: {
        padding: '1rem',
        width: '100%',
    },
    seasonItemActionsContainer: {
        display: 'flex',
        justifyContent: 'end',
    },
    seasonRewardsContainer: {
        display: 'flex',
        alignContent: 'center',
    },
    seasonRewardColor: {
        marginRight: '0.5rem',
    },
    seasonRewardName: {
        paddingTop: '0.2rem',
    },
};

SeasonItem.propTypes = {
    title: PropTypes.string,
    isSeasonExpired: PropTypes.bool,
    fromDate: PropTypes.string,
    toDate: PropTypes.string,
    description: PropTypes.string,
    userLevelList: PropTypes.array,
    editButtonDisabled: PropTypes.bool,
    onEdit: PropTypes.func,
    copyButtonDisabled: PropTypes.bool,
    onCopy: PropTypes.func,
    showCopy: PropTypes.bool,
    disableButtonDisabled: PropTypes.bool,
    onDisable: PropTypes.func,
};
