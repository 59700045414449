import { PREF_ABRIR_CERRAR_SIDEBAR } from './prefTypes';

export const initialState = {
    isSidebarAbierto: true,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PREF_ABRIR_CERRAR_SIDEBAR:
            return {
                ...state,
                isSidebarAbierto:
                    action?.payload?.isSidebarAbierto !== undefined
                        ? action.payload.isSidebarAbierto
                        : !state.isSidebarAbierto,
            };

        default:
            return state;
    }
};

export default reducer;
