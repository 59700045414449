import React, { useEffect, useState } from 'react';
import {
    Form,
    message,
    Select,
    Divider,
    Button,
    Input,
    Modal,
    Row,
    Col,
    Spin,
} from 'antd';
import PropTypes from 'prop-types';
import clienteAxios from '../../../../../config/axios';
import { urls } from '../../../../../constants';

const FormItemSelectCustomer = ({
    formItemProps,
    selectProps,
    disabled,
    onChangeSelect,
}) => {
    const [nuevoContribuyenteForm] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [openModalNuevoContribuyente, setOpenModalNuevoContribuyente] =
        useState(false);
    const [datosNuevoContribuyente, setDatosNuevoContribuyente] = useState();
    const [loadingdata, setLoadingData] = useState(false);

    useEffect(() => {
        getCustomer();
    }, []);

    useEffect(() => {
        if (datosNuevoContribuyente) {
            sendDatosBack();
        }
    }, [datosNuevoContribuyente]);

    const getCustomer = async () => {
        let arrCustomer = [];
        const data = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
            itemsPerPage: 0,
        };
        setLoading(true);
        try {
            // Get the quantity of customers
            const qtyResponse = await clienteAxios.get('/customer', {
                params: data,
            });

            // Update the quantity of customers
            const updatedQuantity = qtyResponse?.data?.data?.total || 0;
            data.itemsPerPage = updatedQuantity;

            // Get the quantity of customers
            const customersListResponse = await clienteAxios.get('/customer', {
                params: data,
            });

            // Update customers list
            const rawCustomersList =
                customersListResponse?.data?.data?.customers;
            arrCustomer = rawCustomersList.map((item) => ({
                label: `${item?.document_number} (${item?.name})`,
                value: JSON.stringify(item),
            }));
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener los datos del RUC',
            );
        }
        setLoading(false);
        setCustomers(arrCustomer);
    };

    const getDataform = () => {
        nuevoContribuyenteForm
            .validateFields()
            .then((values) => {
                try {
                    const data = values;
                    setDatosNuevoContribuyente(data);
                } catch (err) {
                    console.error(err);
                }
            })
            .catch(() => {});
    };

    const sendDatosBack = async () => {
        setLoadingData(true);
        const baseURL = urls.REACT_APP_BACKEND_URL_C;
        const data = {
            ...datosNuevoContribuyente,
            legalName: undefined,
            documentNumber: undefined,
            phoneNumber: undefined,
            legal_name: datosNuevoContribuyente?.legalName,
            document_number: datosNuevoContribuyente?.documentNumber,
            phone_number: datosNuevoContribuyente?.phoneNumber,
        };
        try {
            const respuesta = await clienteAxios.post('/customer', data, {
                baseURL,
            });
            message.success(respuesta.data.message);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    error.message ||
                    'Hubo un problema al crear el contribuyente',
            );
        }
        setOpenModalNuevoContribuyente(false);
        setLoadingData(false);
        getCustomer();
        nuevoContribuyenteForm.resetFields();
    };

    const modalNuevoContribuyente = () => (
        <Modal
            title="Nuevo Contribuyente"
            open={openModalNuevoContribuyente}
            footer={false}
            closable={false}>
            <Spin spinning={loadingdata}>
                <Form
                    layout="vertical"
                    form={nuevoContribuyenteForm}
                    onFinish={getDataform}>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Nombre"
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es obligatorio',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Razon Social"
                                name="legalName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es obligatorio',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Número de documento"
                                name="documentNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es obligatorio',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Dirección"
                                name="address"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es obligatorio',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label="Número de teléfono"
                                name="phoneNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es obligatorio',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Dirección de correo"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Este campo es obligatorio',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div
                        style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            style={{ marginRight: '10px' }}
                            onClick={() =>
                                setOpenModalNuevoContribuyente(false)
                            }>
                            Cancelar
                        </Button>
                        <Button type="primary" htmlType="submit">
                            Aceptar
                        </Button>
                    </div>
                </Form>
            </Spin>
        </Modal>
    );

    return (
        <>
            {openModalNuevoContribuyente && modalNuevoContribuyente()}
            <Form.Item
                name="customerId"
                label={'RUC'}
                {...formItemProps}
                style={{ marginLeft: '10px' }}
                rules={[
                    { required: true, message: 'Este campo es obligatorio' },
                ]}>
                <Select
                    onChange={onChangeSelect}
                    style={{ width: '340px' }}
                    {...selectProps}
                    disabled={disabled || loading}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    dropdownRender={(menu) => (
                        <div style={{ margin: 4 }}>
                            {menu}
                            <Divider style={{ margin: '4px 0' }} />
                            <Button
                                block
                                onClick={() =>
                                    setOpenModalNuevoContribuyente(true)
                                }>
                                Agregar nuevo contribuyente
                            </Button>
                        </div>
                    )}
                    options={customers}
                />
            </Form.Item>
        </>
    );
};

export default FormItemSelectCustomer;

FormItemSelectCustomer.propTypes = {
    formItemProps: PropTypes.any,
    selectProps: PropTypes.any,
    disabled: PropTypes.bool,
    onChangeSelect: PropTypes.func,
};
