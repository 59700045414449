import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';

const EnviarJediModal = ({
    enviarJediModalVisible,
    ordenSeleccionada,
    cerrarModalConfirmacionJedi,
    confirmarEnviarJedi,
    loadingIntegraciones,
}) => {
    const ordenId = ordenSeleccionada?.id;
    const stateId = ordenSeleccionada?.order_state_id;
    return (
        <Modal open={enviarJediModalVisible} footer={null} closable={null}>
            <ModalContent
                title={`Desea enviar a la sucursal la orden #${ordenId} ?`}
                okText="CONFIRMAR"
                cancelText="CANCELAR"
                onOk={() => confirmarEnviarJedi(ordenId, stateId)}
                onCancel={() => cerrarModalConfirmacionJedi()}
                isLoading={loadingIntegraciones}
                showWarnIcon={true}
            />
        </Modal>
    );
};

EnviarJediModal.propTypes = {
    enviarJediModalVisible: PropTypes.any,
    ordenSeleccionada: PropTypes.any,
    cerrarModalConfirmacionJedi: PropTypes.any,
    confirmarEnviarJedi: PropTypes.any,
    loadingIntegraciones: PropTypes.any,
};

export default EnviarJediModal;
