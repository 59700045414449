import React from 'react';
import 'antd/dist/antd.css';
import { Col, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import revertirPagoTransparenteIcon from '../../../../assets/svg/support/reverPagoTransparent.svg';
import IconoBan from 'assets/svg/IconoBan';

const RevertirPagoButton = (props) => {
    const {
        tipoPago,
        permitirRevertirPagoOnline,
        revertirPagoButtonHandler,
        tipoPagoSoloCupon,
        pagoRevertido,
    } = props;

    const typeOfPaymentIs = {
        inCash: tipoPago === 'CASH',
        withPOS: tipoPago === 'POS',
        withTigoMoney: tipoPago === 'TIGO_MONEY',
        withItau: tipoPago === 'ITAU_PAGO',
    };

    const conditions = {
        ifPaymentIsCashOrPOSOrTigoMoneyOrItau:
            typeOfPaymentIs.inCash ||
            typeOfPaymentIs.withPOS ||
            typeOfPaymentIs.withTigoMoney ||
            typeOfPaymentIs.withItau,
        title: pagoRevertido ? 'Pago reversado' : 'Revertir pago online',
        source: pagoRevertido ? revertirPagoTransparenteIcon : IconoBan,
    };

    return (
        <>
            {conditions.ifPaymentIsCashOrPOSOrTigoMoneyOrItau ||
            tipoPagoSoloCupon ||
            !permitirRevertirPagoOnline ? null : (
                <Col style={{ marginLeft: '-40px' }}>
                    <Tooltip title={conditions.title}>
                        <Button
                            type="link"
                            icon={<IconoBan />}
                            onClick={revertirPagoButtonHandler}
                            disabled={pagoRevertido}
                        />
                    </Tooltip>
                </Col>
            )}
        </>
    );
};

RevertirPagoButton.propTypes = {
    tipoPago: PropTypes.any,
    permitirRevertirPagoOnline: PropTypes.bool,
    revertirPagoButtonHandler: PropTypes.func,
    tipoPagoSoloCupon: PropTypes.any,
    pagoRevertido: PropTypes.bool,
};

export default RevertirPagoButton;
