import React, { useContext, useEffect, useState } from 'react';
import TemporadasContext from '../../../../context/programaRecompensa/temporadas/TemporadasContext';
import {
    Row,
    Col,
    Typography,
    Card,
    Empty,
    Button,
    List,
    Modal,
    Form,
} from 'antd';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';
import { PlusCircleOutlined } from '@ant-design/icons';
import ABMLayout from '../../../../layouts/ABMLayout';
import SeasonItem from '../../../molecules/ProgramaRecomensa/Temporadas/SeasonItem';
import ModalContent from '../../../atoms/ModalContent';
import SeasonsForm from './TemporadasForm';
import NivelUsuarioContext from '../../../../context/programaRecompensa/nivelUsuario/NivelUsuarioContext';
import moment from 'moment';

const { Title } = Typography;

export const ListaTemporadas = () => {
    const [seasonsForm] = Form.useForm();
    const [seasonFormModal, setSeasonFormModal] = useState({
        isEditForm: false,
        selectedSeason: null,
        seasonsNewEditFormModalVisible: false,
        seasonsDisableFormModalVisible: false,
    });

    const {
        getSeasonsList,
        loading,
        currentSeason,
        seasonsList,
        newSeason,
        updateSeason,
    } = useContext(TemporadasContext);
    const { getUserLevels, userLevelList: userLevelList } =
        useContext(NivelUsuarioContext);

    const seasonsListHasData = Boolean(seasonsList?.length);

    useEffect(() => {
        getSeasonsList();

        // Get list of all user levels
        getUserLevels();
    }, []);

    const newSeasonButtonHandler = () => {
        setSeasonFormModal({
            isEditForm: false,
            selectedSeason: null,
            seasonsNewEditFormModalVisible: true,
            seasonsDisableFormModalVisible: false,
        });
    };

    const editSeasonButtonHandler = (seasonItem) => {
        setSeasonFormModal({
            isEditForm: true,
            selectedSeason: seasonItem,
            seasonsNewEditFormModalVisible: true,
            seasonsDisableFormModalVisible: false,
        });
    };

    const copySeasonButtonHandler = (seasonItem) => {
        // Remove dates
        const updatedSeasonItem = {
            ...seasonItem,
            start_date: undefined,
            end_date: undefined,
        };
        setSeasonFormModal({
            isEditForm: false,
            selectedSeason: updatedSeasonItem,
            seasonsNewEditFormModalVisible: true,
            seasonsDisableFormModalVisible: false,
        });
    };

    const disableSeasonButtonHandler = (seasonItem) => {
        setSeasonFormModal({
            isEditForm: false,
            selectedSeason: seasonItem,
            seasonsNewEditFormModalVisible: false,
            seasonsDisableFormModalVisible: true,
        });
    };

    const resetFormModal = () => {
        setSeasonFormModal({
            isEditForm: false,
            selectedSeason: null,
            seasonsNewEditFormModalVisible: false,
            seasonsDisableFormModalVisible: false,
        });

        seasonsForm.resetFields();
    };

    const saveFormButtonHandler = () => {
        seasonsForm.validateFields().then((values) => {
            const seasonData = {
                ...values,
                date: undefined,
                start_date: moment(values?.date[0]).format('YYYY-MM-DD'),
                end_date: moment(values?.date[1]).format('YYYY-MM-DD'),
                user_levels: values?.user_levels?.map((item) => parseInt(item)),
            };

            if (!seasonFormModal?.isEditForm) {
                const newSeasonData = {
                    ...seasonData,
                    id: undefined,
                };
                newSeason(newSeasonData, resetFormModal);
            } else {
                const updateSeasonData = {
                    ...seasonData,
                    enable: true,
                    original_user_levels:
                        seasonFormModal?.selectedSeason?.user_levels?.map(
                            (userLevel) => parseInt(userLevel?.id),
                        ),
                };
                updateSeason(updateSeasonData, resetFormModal);
            }
        });
    };

    const confirmDisableButtonHandler = () => {
        const disableSeasonData = {
            id: seasonFormModal?.selectedSeason?.id,
            enabled: false,
        };
        updateSeason(disableSeasonData, resetFormModal);
    };

    const cancelFormButtonHandler = () => {
        resetFormModal();
    };

    return (
        <ABMLayout
            titulo={'Temporadas'}
            loading={loading}
            acciones={
                <ProtectedComponent
                    permiso={
                        Permisos.programaRecompensa.temporadas
                            .crearEditarTemporada
                    }>
                    <Button
                        type="primary"
                        icon={<PlusCircleOutlined />}
                        onClick={newSeasonButtonHandler}>
                        Nueva temporada
                    </Button>
                </ProtectedComponent>
            }>
            {/* Current season */}
            <Card>
                <Row gutter={24}>
                    <Col span={24} style={styles.cardTitleContainer}>
                        <Title level={3}>Temporada actual</Title>
                    </Col>
                    <Col span={24}>
                        {currentSeason ? (
                            <SeasonItem
                                title={currentSeason?.name}
                                fromDate={currentSeason?.start_date}
                                toDate={currentSeason?.end_date}
                                description={currentSeason?.description}
                                userLevelList={currentSeason?.user_levels}
                                onEdit={() =>
                                    editSeasonButtonHandler(currentSeason)
                                }
                                onDisable={() =>
                                    disableSeasonButtonHandler(currentSeason)
                                }
                            />
                        ) : (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={false}
                            />
                        )}
                    </Col>
                </Row>
            </Card>

            {/* Seasons list */}
            <Card>
                <Row gutter={24}>
                    <Col span={24} style={styles.cardTitleContainer}>
                        <Title level={3}>Lista de temporadas</Title>
                    </Col>
                    <Col span={24}>
                        {seasonsListHasData ? (
                            <div style={styles.scrollableSeasonListContainer}>
                                <List
                                    dataSource={seasonsList}
                                    renderItem={(seasonItem) => {
                                        const seasonItemIsExpired = moment(
                                            seasonItem?.end_date,
                                        ).isBefore(moment());
                                        return (
                                            <List.Item>
                                                <SeasonItem
                                                    title={seasonItem?.name}
                                                    isSeasonExpired={
                                                        seasonItemIsExpired
                                                    }
                                                    fromDate={
                                                        seasonItem?.start_date
                                                    }
                                                    toDate={
                                                        seasonItem?.end_date
                                                    }
                                                    description={
                                                        seasonItem?.description
                                                    }
                                                    userLevelList={
                                                        seasonItem?.user_levels
                                                    }
                                                    editButtonDisabled={
                                                        seasonItemIsExpired
                                                    }
                                                    onEdit={() =>
                                                        editSeasonButtonHandler(
                                                            seasonItem,
                                                        )
                                                    }
                                                    onCopy={() =>
                                                        copySeasonButtonHandler(
                                                            seasonItem,
                                                        )
                                                    }
                                                    showCopy
                                                    disableButtonDisabled={
                                                        !seasonItem?.enabled ||
                                                        seasonItemIsExpired
                                                    }
                                                    onDisable={() =>
                                                        disableSeasonButtonHandler(
                                                            seasonItem,
                                                        )
                                                    }
                                                />
                                            </List.Item>
                                        );
                                    }}
                                />
                            </div>
                        ) : (
                            <Empty
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={false}
                            />
                        )}
                    </Col>
                </Row>
            </Card>

            {/* New, Copy and Edit Form modal */}
            <Modal
                centered
                open={seasonFormModal?.seasonsNewEditFormModalVisible}
                title={`${
                    !seasonFormModal?.isEditForm ? 'Nueva' : 'Editar'
                } temporada`}
                footer={null}
                closable={null}
                width={700}
                destroyOnClose>
                <ModalContent
                    isLoading={loading}
                    okText="Guardar"
                    cancelText="Cancelar"
                    onOk={saveFormButtonHandler}
                    onCancel={cancelFormButtonHandler}>
                    <SeasonsForm
                        formInstance={seasonsForm}
                        isEditForm={seasonFormModal?.isEditForm}
                        initialValues={seasonFormModal?.selectedSeason}
                        userLevelList={userLevelList}
                    />
                </ModalContent>
            </Modal>

            <Modal
                centered
                open={seasonFormModal?.seasonsDisableFormModalVisible}
                footer={null}
                closable={null}
                destroyOnClose>
                <ModalContent
                    isLoading={loading}
                    showWarnIcon
                    isDelete
                    invertPrimary
                    okText="Confirmar"
                    cancelText="Cancelar"
                    onOk={confirmDisableButtonHandler}
                    onCancel={cancelFormButtonHandler}>
                    <Title level={3}>
                        Está seguro de deshabilitar la Temporada de{' '}
                        {seasonFormModal?.selectedSeason?.name}?
                    </Title>
                </ModalContent>
            </Modal>
        </ABMLayout>
    );
};

const styles = {
    cardTitleContainer: {
        textAlign: 'center',
    },
    scrollableSeasonListContainer: {
        height: '400px',
        overflow: 'auto',
        padding: '0 16px',
    },
};
