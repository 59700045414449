import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListaCatastros from './ListaCatastros';
import NuevoCatastros from './NuevoCatastros';
import EditarCatastros from './EditarCatastros';
import PropTypes from 'prop-types';
import ProtectedComponent from '../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../constants/Permisos';

const Catastros = ({ match }) => {
    return (
        <Switch>
            <Route
                path={`${match.url}/lista`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.administrar.catastros.verPagina}
                        componente={ListaCatastros}
                    />
                )}
            />
            <Route
                path={`${match.url}/nuevo`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.administrar.catastros.verPagina}
                        componente={NuevoCatastros}
                    />
                )}
            />
            <Route
                path={`${match.url}/editar`}
                render={() => (
                    <ProtectedComponent
                        permiso={Permisos.administrar.catastros.verPagina}
                        componente={EditarCatastros}
                    />
                )}
            />
        </Switch>
    );
};

export default Catastros;

Catastros.propTypes = {
    match: PropTypes.object,
};
