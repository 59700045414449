import React from 'react';
import 'antd/dist/antd.css';
import { Col, Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';

import classes from './Acciones1.module.css';

const SolicitarDriverButton = ({
    tieneMonchisDriver,
    permitirSolicitarDriver,
    solicitarDriverButtonHandler,
    idEstadoOrden,
    ordenSeleccionada,
}) => (
    <>
        {idEstadoOrden !== 2 &&
            idEstadoOrden !== 0 &&
            idEstadoOrden !== 6 &&
            idEstadoOrden !== 11 &&
            idEstadoOrden !== 10 &&
            idEstadoOrden !== 12 && (
                <>
                    {tieneMonchisDriver ? (
                        permitirSolicitarDriver ||
                        ordenSeleccionada?.driver_requests?.length ? (
                            <Col>
                                <Tooltip title="Driver solicitado">
                                    <Button
                                        className={classes.ButtonDelivery}
                                        disabled={true}>
                                        MOTO SOLICITADA
                                    </Button>
                                </Tooltip>
                            </Col>
                        ) : (
                            <Col>
                                <Tooltip title="Solicitar driver">
                                    <Button
                                        type="link"
                                        className={classes.ButtonPedirMoto}
                                        onClick={solicitarDriverButtonHandler}>
                                        PEDIR MOTO
                                    </Button>
                                </Tooltip>
                            </Col>
                        )
                    ) : (
                        <div className={classes.emptySpaceAcciones} />
                    )}
                </>
            )}
    </>
);

SolicitarDriverButton.propTypes = {
    tieneMonchisDriver: PropTypes.bool,
    permitirSolicitarDriver: PropTypes.bool,
    solicitarDriverButtonHandler: PropTypes.func,
    idEstadoOrden: PropTypes.number,
    ordenSeleccionada: PropTypes.object,
};

export default SolicitarDriverButton;
