import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ListarRecibos from './ListarRecibos';
import ListaReciboState from '../../../../../context/facturacion/ListaRecibos/ListaReciboState';
import ProtectedComponent from '../../../../../hoc/ProtectedComponent';
import { Permisos } from '../../../../../constants/Permisos';
import PropTypes from 'prop-types';
import DetalleRecibos from './DetalleRecibos/DetalleRecibos';

const ListaRecibos = ({ match }) => {
    return (
        <ListaReciboState>
            <Switch>
                <Route
                    path={`${match.url}/lista`}
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.finanzas.facturacion.listaRecibos}
                            componente={ListarRecibos}
                        />
                    )}
                />
                <Route
                    path={`${match.url}/detalle`}
                    component={DetalleRecibos}
                />
            </Switch>
        </ListaReciboState>
    );
};

export default ListaRecibos;

ListaRecibos.propTypes = {
    match: PropTypes.object,
};
