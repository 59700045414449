import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Franquicias from './Franquicias';
import Catastros from './Catastros';
import Productos from './Productos';
import Marcas from './Marcas';
import Integraciones from './Integraciones';
import Matchear from './Integraciones/Matchear';
import MatchearProducto from './Integraciones/MatchearProducto';
import MatchearAtributo from './Integraciones/MatchearAtributos';

const Administrar = ({ match }) => {
    return (
        <Switch>
            <Route path={`${match.url}/franquicias`} component={Franquicias} />
            <Route path={`${match.url}/catastros`} component={Catastros} />
            <Route path={`${match.url}/productos`} component={Productos} />
            <Route path={`${match.url}/marcas`} component={Marcas} />
            <Route
                path={`${match.url}/integraciones`}
                component={Integraciones}
            />
            <Route
                path={`${match.url}/matchearSucursal`}
                component={Matchear}
            />
            <Route
                path={`${match.url}/matchearProducto`}
                component={MatchearProducto}
            />
            <Route
                path={`${match.url}/matchearAtributo`}
                component={MatchearAtributo}
            />
        </Switch>
    );
};

export default Administrar;

Administrar.propTypes = {
    match: PropTypes.object,
};
