import React from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useVerificarPermiso } from '../../components/hooks/useVerificarPermiso';

const ProtectedComponent = (props) => {
    const { tienePermiso } = useVerificarPermiso();

    let renderComponente;
    const renderComponenteSinPermiso = null;
    renderComponente =
        props?.children ||
        (props?.componente &&
            React.createElement(props.componente, props.componenteProps));
    renderComponente = props.esPagina ? (
        <Redirect to="/panel" />
    ) : (
        renderComponente
    );
    renderComponente = props.esObjeto ? props.objeto : renderComponente;

    return tienePermiso(props.permiso)
        ? renderComponente
        : renderComponenteSinPermiso;
};

export default ProtectedComponent;

ProtectedComponent.propTypes = {
    componente: PropTypes.any,
    permiso: PropTypes.any,
    objeto: PropTypes.object,
    esPagina: PropTypes.bool,
    esObjeto: PropTypes.bool,
};
