import React from 'react';
import { Card } from 'antd';
import classes from '../../pages/Support/Perfiles/perfiles.module.css';
import PropTypes from 'prop-types';

export const CardPerfiles = ({ perfil, descripcion, seleccionado }) => {
    return (
        <Card
            bordered={false}
            hoverable={!seleccionado}
            className={[
                classes.card,
                seleccionado && classes.bordeSeleccionado,
            ]}>
            <div className={classes.perfilCenter}>
                <p className="margB0 bold text-underline">{perfil}</p>
                <p>{descripcion}</p>
            </div>
        </Card>
    );
};

CardPerfiles.propTypes = {
    perfil: PropTypes.string,
    seleccionado: PropTypes.bool,
    descripcion: PropTypes.string,
};
