import { useCallback } from 'react';
import { message, notification } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useMixpanel } from 'react-mixpanel-browser';
import { useHistory } from 'react-router-dom';
import lodash from 'lodash';

import clienteAxios from '../../config/axios';
import {
    cantidadPaginasSupport,
    cantidadPaginasSupportPendientes,
    urls,
    orders_states,
} from '../../constants';
import { franquiciasIntegracionesSupport } from '../../constants/franquiciasIntegracionesSupport';
import { formatoGuaranies } from '../../components/utils-components/Number';
import getErrorMessage from '../../components/utils-components/getErrorMessage';

import {
    updateOrders,
    loadingOrders,
    updateOrdersLoadMore,
    updateOrdersFromWS,
    permissionsLocked,
    verOrdenModalRD,
    cerrarOrdenModalRD,
    toggleOrdenPrintLoading,
    toggleSupportLoading,
    cargarListaPerfiles,
    loadingSolicitarDriver,
    cerrarModalSolicitarDriver,
    requestDriver,
    actualizarOrdenDriverSolicitado,
    loadingCancelarOrden,
    cancelOrder,
    cerrarModalCancelarOrder,
    cambiarEstadoReducer,
    cerrarModalCambiarEstado,
    cambiarEstadoLoadingReducer,
    abrirModalOrdenesSeleccionadas,
    revertirCancelacionRD,
    cerrarModalRevertirCancelacionRD,
    loadingRevertirCancelacionRD,
    verificarDireccionRD,
    cerrarModalVerificarDireccion,
    loadingVerificarDireccion,
    actualizarVerificarDireccionRD,
    abrirModalAceptarAgendado,
    cerrarModalAceptarAgendado,
    abrirModalRechazarAgendado,
    cerrarModalRechazarAgendado,
    confirmarAceptarAgendadoLoading,
    confirmarRechazarAgendadoLoading,
    actualizarTipoUsuario,
    obtenerOrdenesBusquedaReducer,
    vaciarTablaBusquedaReducer,
    hasPermitionToChangeState,
    franquiciaConIntegracion,
    revertirPagoReducer,
    cerrarModalRevertirPago,
    loadingRevertirPago,
    actualizarOrdenRevertirPagoReducer,
    abrirModalEnviarIntegracionReducer,
    cerrarModalEnviarIntegracionReducer,
    loadingIntegracionesReducer,
    actualizarOrdenIntegracionReducer,
    abrirModalEnviarJediReducer,
    obtenerListaProductosIntegracionJediReducer,
    cerrarModalEnviarJediReducer,
    abrirModalConfirmacionJedi,
    cerrarModalConfirmacionJediReducer,
    actualizarListaEstados,
    removeOrdenSeleccionada,
} from '../../redux/support/reducer';

const paramsGeneralesPendientes = {
    items_per_page: cantidadPaginasSupportPendientes,
};
const paramsGenerales = {
    items_per_page: cantidadPaginasSupport,
};
const baseURL = urls.REACT_APP_BACKEND_URL_F;
const baseURLIntegraciones = urls.REACT_APP_BACKEND_URL_INTEGRATION_H;
const baseURLIntegracionesParis = urls.REACT_APP_BACKEND_URL_B;
const baseURLIntegracionesJedi = urls.REACT_APP_BACKEND_URL_INTEGRATION_JEDI_G;
export const formatoTablaOrdenes = (
    respuestaOrdenes,
    ordersFromLoadMoreButton = false,
) => {
    let listaOrdenesArr = [];
    if (respuestaOrdenes?.length) {
        for (let i = 0; i < respuestaOrdenes.length; i++) {
            let orderListTable = {};
            if (!respuestaOrdenes[i]?.ordenOriginal) {
                orderListTable = {
                    key: respuestaOrdenes[i]?.id,
                    ordenOriginal: lodash.cloneDeep(respuestaOrdenes[i]),
                    numeroOrden: respuestaOrdenes[i]?.id,
                    totalMasDelivery: formatoGuaranies(
                        Number(respuestaOrdenes[i]?.total) +
                            Number(respuestaOrdenes[i]?.delivery_price),
                    ),
                    tipoEntrega: respuestaOrdenes[i]?.delivery_type,
                    pagoRevertido: respuestaOrdenes[i]?.actions?.is_reversed,
                    tipoPago: respuestaOrdenes[i]?.payment_type,
                    fechaPedido: respuestaOrdenes[i]?.confirmed_at,
                    fechaAgendamiento: respuestaOrdenes[i]?.scheduled_order_at,
                    tiempoEstimado: respuestaOrdenes[i]?.branch?.delivery_delay,
                    nombreLocal: respuestaOrdenes[i]?.branch?.name,
                    telefonoLocal: respuestaOrdenes[i]?.branch?.phone,
                    level: respuestaOrdenes[i]?.user?.level_name,
                    alertSent: respuestaOrdenes[i]?.alert_sent,
                    cartId: respuestaOrdenes[i]?.cart_id,
                    codigoPagoOnline: lodash.cloneDeep(
                        respuestaOrdenes[i]?.payments[0].payment_type_id === 9
                            ? respuestaOrdenes[i]?.payments?.map(
                                  (item) => item?.additional_reference,
                              )
                            : respuestaOrdenes[i]?.payments?.map(
                                  (item) => item?.reference,
                              ),
                    ),
                    nombreCliente: `${respuestaOrdenes[i]?.user?.first_name} ${respuestaOrdenes[i]?.user?.last_name}`,
                    idCliente: respuestaOrdenes[i]?.user_id,
                    razonSocial: respuestaOrdenes[i]?.invoice_name,
                    ruc: respuestaOrdenes[i]?.invoice_ruc,
                    estadoOrden: respuestaOrdenes[i]?.order_state_id,
                    telefonoCliente: respuestaOrdenes[i]?.user?.phone,
                    direccionCliente: `${respuestaOrdenes[i]?.address?.street1} ${respuestaOrdenes[i]?.address?.street2}`,
                    referenciaDireccion:
                        respuestaOrdenes[i]?.address?.reference,
                    totalSinDelivery: respuestaOrdenes[i]?.total,
                    latitudDireccionCliente:
                        respuestaOrdenes[i]?.address?.latitude,
                    longitudDireccionCliente:
                        respuestaOrdenes[i]?.address?.longitude,
                    costoDelivery: formatoGuaranies(
                        respuestaOrdenes[i]?.delivery_price,
                    ),
                    motivoCancelacion: respuestaOrdenes[i]?.cancel_reason,
                    tipoPagoSoloCupon:
                        respuestaOrdenes[i]?.payments?.find(
                            (item) => item?.payment_type?.name === 'DISCOUNT',
                        ) && respuestaOrdenes[i]?.payments?.length === 1,

                    // Acciones
                    tieneMonchisDriver:
                        respuestaOrdenes[i]?.actions?.has_monchis_driver,
                    permitirSolicitarDriver:
                        respuestaOrdenes[i]?.actions?.request_driver,
                    permitirRevertirPagoOnline:
                        respuestaOrdenes[i]?.actions?.reverse_bancard ||
                        respuestaOrdenes[i]?.payments.some(
                            (payment) => payment.pre_authorization === true,
                        ),
                    permitirCancelarOrden:
                        respuestaOrdenes[i]?.actions?.cancel_order,
                    permitirCambiarEstado:
                        respuestaOrdenes[i]?.actions?.change_state,
                    permitirRevertirCancelacion:
                        respuestaOrdenes[i]?.actions?.enabled_canceled,
                    permitirImprimirOrden:
                        respuestaOrdenes[i]?.actions?.print_ticket,
                    permitirVerOrden: respuestaOrdenes[i]?.actions?.show_order,
                    hasIntegration:
                        respuestaOrdenes[i]?.actions?.has_integration,
                    canSeeOnlinePaymentType:
                        respuestaOrdenes[i]?.actions?.showPaymentype,

                    //Integraciones
                    hasJedi: respuestaOrdenes[i]?.has_jedi,
                    jediId: respuestaOrdenes[i]?.jedi_id,
                    fechaJEdi: respuestaOrdenes[i]?.sent_to_jedi_date,
                    idMcDonalds:
                        respuestaOrdenes[i]?.mcdonals_external_invoice_id,
                    idIntegracion: respuestaOrdenes[i]?.external_order_id,
                    envioIntegracion: respuestaOrdenes[i]?.sent_to_integration,

                    ordersFromLoadMoreButton,
                };
            } else {
                orderListTable = respuestaOrdenes[i];
            }

            listaOrdenesArr.push(lodash.cloneDeep(orderListTable));
        }
    }

    listaOrdenesArr = Array.from(
        new Set(listaOrdenesArr.map((a) => a.numeroOrden)),
    ).map((numeroOrden) => {
        return listaOrdenesArr.find((a) => a.numeroOrden === numeroOrden);
    });

    const ordersListFormatted = {
        AGENDADOS: lodash.cloneDeep(
            listaOrdenesArr?.filter(
                (orden) =>
                    orden?.estadoOrden === 11 ||
                    orden?.estadoOrden === 12 ||
                    orden?.estadoOrden === 10,
            ),
        ),
        CANCELADOS: lodash.cloneDeep(
            listaOrdenesArr?.filter((orden) => orden?.estadoOrden === 2),
        ),
        DELIVERYPICKUP: lodash.cloneDeep(
            listaOrdenesArr?.filter(
                (orden) => orden?.estadoOrden === 5 || orden?.estadoOrden === 9,
            ),
        ),
        FINALIZADOS: lodash.cloneDeep(
            listaOrdenesArr?.filter((orden) => orden?.estadoOrden === 6),
        ),
        PENDIENTES: lodash.cloneDeep(
            listaOrdenesArr?.filter((orden) => orden?.estadoOrden === 3),
        ),
        PREPARANDO: lodash.cloneDeep(
            listaOrdenesArr?.filter((orden) => orden?.estadoOrden === 4),
        ),
    };

    return ordersListFormatted;
};

const useSupport = () => {
    let history = useHistory();

    const {
        pedidos,
        ordenSeleccionada,
        ordenesSeleccionadas,
        isVerOrdenModalVisible,
        totalPendientes,
        totalPreparando,
        totalDeliveryPickup,
        totalFinalizados,
        totalCancelados,
        totalAgendados,
        paginaActualPendientes,
        paginaActualPreparando,
        paginaActualDeliveryPickup,
        paginaActualFinalizados,
        paginaActualCancelados,
        paginaActualAgendados,
        totalPaginasPendientes,
        totalPaginasPreparando,
        totalPaginasDeliveryPickup,
        totalPaginasFinalizados,
        totalPaginasCancelados,
        totalPaginasAgendados,

        loading,
        hasPermissionsLocked,
        imprimirOrdenLoading,
        cancelarOrdenLoading,
        cambiarEstadoLoading,
        revertirPagoLoading,

        siguienteEstado,
        isCambiarEstadoModalVisible,
        isRevertirCancelacionModalVisible,
        revertirCancelacionLoading,
        verificarDireccionLoading,

        listaPerfiles,
        listaOrdenes,
        listaOrdenesBusqueda,
        listaMotivosCancelacion,
        listaOrdenesCambioEstado,

        isSolicitarDriverModalVisible,
        solicitarDriverLoading,
        isCancelarOrdenModalVisible,
        isVerificarDireccionModalVisible,

        isAceptarAgendadoModalVisible,
        aceptarAgendadoLoading,
        rechazarAgendadoLoading,
        isRechazarAgendadoModalVisible,
        isRevertirPagoModalVisible,

        permisoVerIntegraciones,
        obtenerPermisoIntegraciones,
        listaProductosTableIntegracionJedi,
        PreviewJediModalVisible,
        enviarJediModalVisible,

        deshabilitarBotonIntegracion,
        paginaActualBusqueda,
        totalPaginasBusqueda,
    } = useSelector((state) => state.support);
    const dispatch = useDispatch();

    //Hook de mixpanel
    const mixpanel = useMixpanel();

    const urlEstado = (estadoId) => `/api/orders/order-state/${estadoId}`;

    // FUNCIONES AUXILIARES

    const obtenerSiguienteEstado = (idEstadoActual, deliveryType) => {
        let siguienteEstado = {};
        switch (idEstadoActual) {
            case 3: // Pendiente
                siguienteEstado = {
                    id: 4,
                    nombre: 'Preparando',
                };
                break;
            case 4: // Preparando
                if (deliveryType === 1) {
                    siguienteEstado = {
                        id: 5,
                        nombre: 'Delivery',
                    };
                } else {
                    siguienteEstado = {
                        id: 9,
                        nombre: 'Pickup',
                    };
                }
                break;
            case 5: // Delivery
                siguienteEstado = {
                    id: 6,
                    nombre: 'Finalizados',
                };
                break;
            case 9: // Pickup
                siguienteEstado = {
                    id: 6,
                    nombre: 'Finalizados',
                };
                break;
            case 2: // Cancelado
                siguienteEstado = {
                    id: 4,
                    nombre: 'Preparando',
                };
                break;
            default:
                break;
        }
        return siguienteEstado;
    };

    // NOTE: Para cancelar orden, cambiar de estado y descancelar la orden
    const cambiarEstadoOrden = async (
        orderId,
        orderStateId,
        reasonId,
        hideSuccessMessage,
    ) => {
        const data = {
            order_id: orderId,
            order_state_id: orderStateId, //el estado al que se desea cambiar la orden
            reason_id: reasonId, //se manda la razon solo en el caso de cancelacion.
            axiosBaseURL: baseURL,
        };

        try {
            const request = await clienteAxios.post('/orders/state/set', data);
            if (!hideSuccessMessage) {
                message.success(request.data.message);
                mixpanel.track('support_change_state', {
                    orderId: ordenSeleccionada?.id,
                    nextState:
                        siguienteEstado?.id + ' - ' + siguienteEstado?.nombre,
                    stateId:
                        ordenSeleccionada?.order_state_id +
                        ' - ' +
                        ordenSeleccionada?.status,
                    branch: ordenSeleccionada?.branch_id,
                    franchiseId: ordenSeleccionada?.branch?.franchise_id,
                    userId: ordenSeleccionada?.user_id,
                    userName: ordenSeleccionada?.user?.first_name,
                    userLastName: ordenSeleccionada?.user?.last_name,
                });
            }
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                `Hubo un error al cambiar el estado de la orden #${orderId}${
                    error?.response?.data?.message &&
                    ': ' + error.response.data.message
                }`,
            );
            message.error(errorMessage);
            mixpanel.track('support_change_state_error', {
                orderId: ordenSeleccionada?.id,
                nextState:
                    siguienteEstado?.id + ' - ' + siguienteEstado?.nombre,
                stateId:
                    ordenSeleccionada?.order_state_id +
                    ' - ' +
                    ordenSeleccionada?.status,
                branch: ordenSeleccionada?.branch_id,
                franchiseId: ordenSeleccionada?.branch?.franchise_id,
                userId: ordenSeleccionada?.user_id,
                error: errorMessage,
            });
        }
    };

    const verificarPermisoIntegraciones = (idFranquicia, esUsuarioComercio) => {
        const esFranquiciaConIntegracion = Boolean(
            franquiciasIntegracionesSupport.findIndex(
                (item) =>
                    item.idFranquicia === idFranquicia &&
                    item.puedeVerBotonIntegracion,
            ),
        );

        let tienePermisoIntegraciones = true;
        if (esUsuarioComercio && !esFranquiciaConIntegracion) {
            tienePermisoIntegraciones = false;
        }

        dispatch(franquiciaConIntegracion(tienePermisoIntegraciones));
    };

    const obtenerTodasOrdenes = async (userType) => {
        dispatch(loadingOrders(true));

        let userIsMerchant = userType?.esUsuarioComercio;

        try {
            const paramsPendientes = { params: paramsGeneralesPendientes };
            const params = { params: paramsGenerales };
            const pendientePromise = clienteAxios.get(
                `${baseURL}${urlEstado(3)}`,
                paramsPendientes,
            );
            const preparandoPromise = clienteAxios.get(
                `${baseURL}${urlEstado(4)}`,
                params,
            );
            const deliveryPickUpPromise = clienteAxios.get(
                `${baseURL}${urlEstado('5,9')}`,
                params,
            );
            const finalizadoPromise = clienteAxios.get(
                `${baseURL}${urlEstado(6)}`,
                params,
            );
            const canceladoPromise = clienteAxios.get(
                `${baseURL}${urlEstado(2)}`,
                params,
            );
            const agendadoPromise = clienteAxios.get(
                `${baseURL}${urlEstado('12,11,10')}`,
                params,
            );
            const respuesta = await Promise.all([
                pendientePromise,
                preparandoPromise,
                deliveryPickUpPromise,
                finalizadoPromise,
                canceladoPromise,
                agendadoPromise,
            ]);

            // Remover las ordenes de McDonals con 'mcdonals_external_invoice_id' existentes en el estado pendiente
            const ordenesPendientes = respuesta[0].data?.data?.orders;
            const idMcDonalds = franquiciasIntegracionesSupport.find(
                (item) => item.franquicia === 'MC',
            ).idFranquicia;
            const ordenesPendientesFiltradas = ordenesPendientes.filter(
                (orden) => {
                    const isOrderFromMc = orden?.franchise_id === idMcDonalds;
                    const hasBeenSentToIntegration =
                        orden?.mcdonals_external_invoice_id ||
                        orden?.sent_to_integration;
                    const isMcWithIntegration =
                        isOrderFromMc && hasBeenSentToIntegration;
                    return !isMcWithIntegration;
                },
            );

            let listaOrdenes = [
                ...ordenesPendientesFiltradas,
                ...respuesta[1].data?.data?.orders,
                ...respuesta[2].data?.data?.orders,
                ...respuesta[3].data?.data?.orders,
                ...respuesta[4].data?.data?.orders,
                ...respuesta[5].data?.data?.orders,
            ];
            listaOrdenes = Array.from(
                new Set(listaOrdenes.map((a) => a.id)),
            ).map((id) => {
                return listaOrdenes.find((a) => a.id === id);
            });

            // El total de ordenes pendientes siempre se muestra sin filtrar por las ordenes de McDonals
            const totalPendientes = respuesta[0]?.data?.data?.total || 0;
            const totalPreparando = respuesta[1]?.data?.data?.total || 0;
            const totalDeliveryPickup = respuesta[2]?.data?.data?.total || 0;
            const totalFinalizados = respuesta[3]?.data?.data?.total || 0;
            const totalCancelados = respuesta[4]?.data?.data?.total || 0;
            const totalAgendados = respuesta[5]?.data?.data?.total || 0;

            const totalPaginasPendientes =
                Math.ceil(totalPendientes / cantidadPaginasSupportPendientes) ||
                1;
            const totalPaginasPreparando =
                Math.ceil(totalPreparando / cantidadPaginasSupport) || 1;
            const totalPaginasDeliveryPickup =
                Math.ceil(totalDeliveryPickup / cantidadPaginasSupport) || 1;
            const totalPaginasFinalizados =
                Math.ceil(totalFinalizados / cantidadPaginasSupport) || 1;
            const totalPaginasCancelados =
                Math.ceil(totalCancelados / cantidadPaginasSupport) || 1;
            const totalPaginasAgendados =
                Math.ceil(totalAgendados / cantidadPaginasSupport) || 1;

            if (!obtenerPermisoIntegraciones) {
                if (listaOrdenes?.length) {
                    const idFranquicia = listaOrdenes[0]?.branch?.franchise_id;
                    verificarPermisoIntegraciones(idFranquicia, userIsMerchant);
                }
            }

            const listaOrdenesTable = formatoTablaOrdenes(listaOrdenes);

            dispatch(
                updateOrders({
                    pedidos: listaOrdenesTable,

                    totalPendientes,
                    totalPreparando,
                    totalDeliveryPickup,
                    totalFinalizados,
                    totalCancelados,
                    totalAgendados,

                    totalPaginasPendientes,
                    totalPaginasPreparando: totalPaginasPreparando,
                    totalPaginasDeliveryPickup: totalPaginasDeliveryPickup,
                    totalPaginasFinalizados,
                    totalPaginasCancelados,
                    totalPaginasAgendados,

                    paginaActualPendientes: 1,
                    paginaActualPreparando: paginaActualPreparando,
                    paginaActualDeliveryPickup: paginaActualDeliveryPickup,
                    paginaActualFinalizados: 1,
                    paginaActualCancelados: 1,
                    paginaActualAgendados: 1,
                    paginaActualBusqueda: 1,
                }),
            );
        } catch (error) {
            const errorMessage = getErrorMessage(
                `Ocurrió un problema al obtener todas las ordenes: ${error}`,
                'Ocurrió un problema al obtener todas las ordenes',
            );
            message.error(errorMessage);

            mixpanel.track('support_error_in_get_all_orders', {
                error: errorMessage,
            });
        } finally {
            dispatch(loadingOrders(false));
        }
    };

    const cargarMas = useCallback(
        async (stateId, paginaActual, totalPaginas) => {
            dispatch(loadingOrders(true));

            try {
                // Obtener pagina actual y siguiente pagina
                let siguientePagina = 0;
                let paginaActualObj = {};
                let paramsGeneralesCargarMas = { ...paramsGenerales };

                switch (stateId) {
                    case 3: {
                        if (paginaActual <= totalPaginas) {
                            siguientePagina = paginaActual + 1;
                            paginaActualObj = {
                                paginaActualPendientes: siguientePagina,
                            };
                            paramsGeneralesCargarMas = {
                                ...paramsGeneralesPendientes,
                            };
                        }
                        break;
                    }
                    case 4: {
                        if (paginaActual <= totalPaginas) {
                            siguientePagina = paginaActual + 1;
                            paginaActualObj = {
                                paginaActualPreparando: siguientePagina,
                            };
                        }
                        break;
                    }
                    case '5,9': {
                        if (paginaActual <= totalPaginas) {
                            siguientePagina = paginaActual + 1;
                            paginaActualObj = {
                                paginaActualDeliveryPickup: siguientePagina,
                            };
                        }
                        break;
                    }
                    case 6: {
                        if (paginaActual <= totalPaginas) {
                            siguientePagina = paginaActual + 1;
                            paginaActualObj = {
                                paginaActualFinalizados: siguientePagina,
                            };
                        }
                        break;
                    }
                    case 2: {
                        if (paginaActual <= totalPaginas) {
                            siguientePagina = paginaActual + 1;
                            paginaActualObj = {
                                paginaActualCancelados: siguientePagina,
                            };
                        }
                        break;
                    }

                    case '10,11,12': {
                        if (paginaActual <= totalPaginas) {
                            siguientePagina = paginaActual + 1;
                            paginaActualObj = {
                                paginaActualAgendados: siguientePagina,
                            };
                        }
                        break;
                    }

                    default:
                        break;
                }

                // Consultar siguiente pagina a la api rest
                const params = {
                    params: {
                        ...paramsGeneralesCargarMas,
                        page: siguientePagina,
                    },
                };
                const listaOrdenesNuevas = await clienteAxios.get(
                    `${baseURL}${urlEstado(stateId)}`,
                    params,
                );

                let listaOrdenesNuevasTable =
                    listaOrdenesNuevas.data?.data?.orders.map((order) => ({
                        ...order,
                        key: order.id,
                    }));

                // REFACTOR: Migrate use of 'pedidos' to reducer
                // Flatten current order list
                let currentOrdersList = lodash.cloneDeep(pedidos);
                const currentOrdersListKeys = Object.keys(currentOrdersList);
                let currentOrdersListFlat = [];
                currentOrdersListKeys.forEach((stateName) => {
                    currentOrdersList[stateName].forEach((order) => {
                        currentOrdersListFlat.push(lodash.cloneDeep(order));
                    });
                });

                // Merge and remove duplicated orders
                let listaOrdenesTable = [
                    ...currentOrdersListFlat,
                    ...listaOrdenesNuevasTable,
                ];
                listaOrdenesTable = Array.from(
                    new Set(listaOrdenesTable.map((a) => a.key)),
                ).map((key) => {
                    return listaOrdenesTable.find((a) => a.key === key);
                });

                // Format orders for the table
                const ordersList = formatoTablaOrdenes(listaOrdenesTable, true);
                const currentPageStateName = Object.keys(paginaActualObj)[0];
                const currentPageValue = paginaActualObj[currentPageStateName];

                dispatch(
                    updateOrdersLoadMore({
                        pedidos: ordersList,
                        currentPageStateName,
                        currentPageValue,
                    }),
                );
            } catch (error) {
                const errorMessage = getErrorMessage(
                    `Ocurrió un problema al obtener más ordenes: ${error}`,
                    'Ocurrió un problema al obtener más ordenes',
                );
                message.error(errorMessage);

                mixpanel.track('support_error_in_load_more', {
                    error: errorMessage,
                });
            } finally {
                dispatch(loadingOrders(false));
            }
        },
        [dispatch, mixpanel, pedidos],
    );

    const actualizarOrdenesWS = useCallback(
        (orderFromWS) => {
            let newOrder = [];
            if (orderFromWS) {
                newOrder = formatoTablaOrdenes([orderFromWS]);
                // Flatten current order list
                let newOrdersList = lodash.cloneDeep(newOrder);
                const newOrdersListKeys = Object.keys(newOrdersList);
                let newOrdersListFlat = [];
                newOrdersListKeys.forEach((stateName) => {
                    newOrdersList[stateName].forEach((order) => {
                        newOrdersListFlat.push(lodash.cloneDeep(order));
                    });
                });
                newOrder = newOrdersListFlat;
            }

            const listaOrdenesWSArr = newOrder;
            const cantidadOrdenesWS = listaOrdenesWSArr?.length;

            if (cantidadOrdenesWS === 1) {
                dispatch(
                    updateOrdersFromWS({
                        newOrder: newOrder[0],
                    }),
                );
            }
        },
        [dispatch],
    );

    const setHasPermissionsLocked = (permissionsState) => {
        dispatch(permissionsLocked(permissionsState));
    };

    // ORDER MODAL

    const verOrdenModal = (ordenSeleccionada) => {
        dispatch(verOrdenModalRD(ordenSeleccionada));
    };

    const cerrarOrdenModal = () => {
        dispatch(cerrarOrdenModalRD());
    };

    const supportLoading = () => {
        dispatch(toggleSupportLoading());
    };

    const setListaPerfiles = (payload) => {
        dispatch(cargarListaPerfiles(payload));
    };

    const imprimirOrden = async (ordenSeleccionada) => {
        const { id } = ordenSeleccionada;
        const baseURL = urls.REACT_APP_BACKEND_URL_F;

        dispatch(toggleOrdenPrintLoading());

        try {
            const respuesta = await clienteAxios.get(
                `${baseURL}/api/orders/${id}/html`,
            );
            const htmlfile = respuesta?.data?.data?.orderHTML;

            var mywindow = window.open('', 'Print', 'height=600,width=800');
            mywindow.document.write(htmlfile);
            mywindow.document.close();

            mywindow.addEventListener('load', () => {
                mywindow.print();
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Hubo un error al obtener la orden',
            );
            message.error(errorMessage);
            mixpanel.track('support_error_in_print_order', {
                error: errorMessage,
            });
        } finally {
            dispatch(toggleOrdenPrintLoading());
        }
    };

    // PANTALLA DE PERFILES

    const getProfiles = async () => {
        supportLoading();
        try {
            const params = {
                axiosBaseURL: baseURL,
            };
            const { data } = await clienteAxios.get('/profiles', {
                params: params,
            });

            setListaPerfiles(data?.data);
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Hubo un error al obtener los perfiles',
            );
            message.error(errorMessage);
            supportLoading();
            mixpanel.track('support_error_on_get_profiles', {
                error: errorMessage,
            });
        }
    };

    const sendProfiles = async (profile, id) => {
        const body = {
            profiles: profile,
            axiosBaseURL: baseURL,
        };

        supportLoading();

        try {
            const respuesta = await clienteAxios.put(
                `/admin/${id}/profiles`,
                body,
            );

            if (respuesta.data?.success) history.push(`/support/seguimiento`);
        } catch (error) {
            supportLoading();

            const errorMessage = getErrorMessage(
                error,
                'Hubo un error al comprobar perfiles',
            );
            message.error(errorMessage);
            mixpanel.track('support_error_in_send_profiles', {
                error: errorMessage,
            });
        }
    };

    // DRIVERS

    const solicitarDriver = (ordenSeleccionada) => {
        dispatch(requestDriver({ ordenSeleccionada }));
    };

    const cerrarSolicitarDriver = () => {
        dispatch(cerrarModalSolicitarDriver());
    };

    const confirmarSolicitarDriver = async () => {
        dispatch(loadingSolicitarDriver());

        const baseURL = urls.REACT_APP_BACKEND_URL_F;

        const data = {
            order_id: ordenSeleccionada.id,
        };

        try {
            const respuesta = await clienteAxios.post(
                '/driver/request/store',
                data,
                { baseURL },
            );
            message.success(respuesta.data.message);

            dispatch(actualizarOrdenDriverSolicitado());

            mixpanel.track('support_request_driver', {
                orderId: ordenSeleccionada?.id,
                stateId:
                    ordenSeleccionada?.order_state_id +
                    ' - ' +
                    ordenSeleccionada?.status,
                branch: ordenSeleccionada?.branch_id,
                franchiseId: ordenSeleccionada?.branch?.franchise_id,
                userId: ordenSeleccionada?.user_id,
                userName: ordenSeleccionada?.user?.first_name,
                userLastName: ordenSeleccionada?.user?.last_name,
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Ocurrió un problema al solicitar el driver',
            );
            message.error(errorMessage);
            mixpanel.track('support_request_driver_error', {
                error: errorMessage,
                orderId: ordenSeleccionada?.id,
                stateId:
                    ordenSeleccionada?.order_state_id +
                    '-' +
                    ordenSeleccionada?.status,
                branch: ordenSeleccionada?.branch_id,
                franchiseId: ordenSeleccionada?.branch?.franchise_id,
                userId: ordenSeleccionada?.user_id,
            });
        }
        dispatch(loadingSolicitarDriver());
        cerrarSolicitarDriver();
    };

    // CANCELAR ORDEN

    const cerrarCancelarOrden = () => {
        dispatch(cerrarModalCancelarOrder());
    };

    const cancelarOrden = async (ordenSeleccionada, abrirDesdeVerOrden) => {
        dispatch(loadingCancelarOrden({ abrirDesdeVerOrden }));

        let listaMotivosCancelacion = [];
        const params = {
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_C,
        };
        try {
            const respuesta = await clienteAxios.get(
                'reasons_list?type=orden',
                { params: params },
            );
            listaMotivosCancelacion = respuesta?.data?.data?.reasons_list;
            dispatch(
                cancelOrder({ ordenSeleccionada, listaMotivosCancelacion }),
            );
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Hubo un error al obtener motivos de cancelación',
            );
            message.error(errorMessage);

            cerrarCancelarOrden();

            mixpanel.track('support_error_in_cancel_order', {
                error: errorMessage,
            });
        }
    };

    const confirmarCancelarOrden = async (
        ordenSeleccionada,
        idRazonCancelacion,
        resetForm,
    ) => {
        dispatch(loadingCancelarOrden());
        const idEstado = 2; // Estado Cancelado
        const idRazon = idRazonCancelacion;

        await cambiarEstadoOrden(ordenSeleccionada, idEstado, idRazon);

        dispatch(loadingCancelarOrden());
        dispatch(cerrarModalCancelarOrder({ cerrarModales: true }));
        mixpanel.track('support_cancel_order', {
            orderId: ordenSeleccionada?.id,
            stateId:
                ordenSeleccionada?.order_state_id +
                ' - ' +
                ordenSeleccionada?.status,
            branch: ordenSeleccionada?.branch_id,
            franchiseId: ordenSeleccionada?.branch?.franchise_id,
            userId: ordenSeleccionada?.user_id,
            userName: ordenSeleccionada?.user?.first_name,
            userLastName: ordenSeleccionada?.user?.last_name,
            idRazon: idRazon,
        });
        resetForm.resetFields();
    };

    // CAMBIAR ESTADO

    const cerrarCambiarEstado = () => {
        dispatch(cerrarModalCambiarEstado());
    };

    const confirmarCambiarEstado = async () => {
        dispatch(cambiarEstadoLoadingReducer(true));
        const idOrden = ordenSeleccionada?.id || ordenesSeleccionadas;
        if (isNaN(idOrden)) {
            const ordenesSeleccionadasArr = idOrden;
            //array
            message.loading({
                content:
                    'Procesando el cambio de estado de las órdenes seleccionadas',
                key: 'procesandoCambioEstado',
                duration: 0,
            });

            // CASO SELECCIONAR ORDENES
            // Guardamos las ids de las órdenes dentro de un estado, los cuales nos ayudarán a saber
            // cuándo todas las órdenes fueron cambiadas de estado en redux para así poder sacar
            // el modal de cargando.
            ordenesSeleccionadasArr.forEach((orden) => {
                dispatch(actualizarListaEstados(orden.numeroOrden));
            });
            ordenesSeleccionadasArr.forEach(async (orden) => {
                await cambiarEstadoOrden(
                    orden.numeroOrden,
                    orden.siguienteEstado,
                    undefined,
                    true,
                );
            });

            message.destroy('procesandoCambioEstado');

            const cantidadOrdenes =
                ordenesSeleccionadasArr.length.toString() || 0;

            message.success(
                `Se ha procesado el cambio de estado de ${cantidadOrdenes} orden${
                    cantidadOrdenes > 1 ? 'es' : ''
                }`,
            );
        } else {
            const siguienteEstadoId = siguienteEstado?.id;

            if (idOrden && siguienteEstadoId) {
                await cambiarEstadoOrden(idOrden, siguienteEstadoId);
            } else {
                message.error(
                    'La orden seleccionada no tiene un ID o estado actual',
                );
            }
            dispatch(removeOrdenSeleccionada());
        }
    };

    const cambiarEstado = (ordenSeleccionada, abrirDesdeVerOrden) => {
        if (ordenSeleccionada?.order_state_id) {
            const siguienteEstado = obtenerSiguienteEstado(
                ordenSeleccionada.order_state_id,
                ordenSeleccionada.delivery_type,
            );

            dispatch(
                cambiarEstadoReducer({
                    ordenSeleccionada,
                    siguienteEstado,
                    abrirDesdeVerOrden,
                }),
            );
        } else {
            message.error('La orden seleccionada no tiene un estado actual');
        }
    };

    // ENVIAR ORDENES SELECCIONADAS

    const abrirModalCambioEstadoSeleccionados = (ordenesSeleccionadas) => {
        const ordenesSeleccionadasArr = ordenesSeleccionadas.map((orden) => {
            const siguienteEstado = obtenerSiguienteEstado(
                orden.estadoOrden,
                orden.tipoEntrega,
            );
            return {
                numeroOrden: orden.numeroOrden,
                siguienteEstado: siguienteEstado.id,
            };
        });
        const siguienteEstado =
            ordenesSeleccionadasArr[0].siguienteEstado === 6
                ? 'Finalizados'
                : 'Delivery/Pick up';
        dispatch(
            abrirModalOrdenesSeleccionadas({
                ordenesSeleccionadas: ordenesSeleccionadasArr,
                siguienteEstado: {
                    nombre: siguienteEstado,
                },
            }),
        );
    };

    // REVERTIR CANCELACION

    const revertirCancelacion = async (ordenSeleccionada) => {
        dispatch(revertirCancelacionRD({ ordenSeleccionada }));
    };

    const cerrarRevertirCancelacion = () => {
        dispatch(cerrarModalRevertirCancelacionRD());
    };

    const confirmarRevertirCancelacion = async () => {
        dispatch(loadingRevertirCancelacionRD());

        const idOrden = ordenSeleccionada.id;
        const idEstado = 3; // Revertir a estado 'Preparando'

        if (idOrden) {
            await cambiarEstadoOrden(idOrden, idEstado);
        } else {
            message.error('La orden seleccionada no tiene un ID');
        }

        dispatch(loadingRevertirCancelacionRD());
        cerrarRevertirCancelacion();
    };

    // VERIFICAR DIRECCION

    const verificarDireccion = async () => {
        dispatch(verificarDireccionRD());
    };

    const cerrarVerificarDireccion = () => {
        dispatch(cerrarModalVerificarDireccion());
    };

    const confirmarVerificarDireccion = async (data) => {
        dispatch(loadingVerificarDireccion());

        const newData = {
            ...data,
            axiosBaseURL: baseURL,
        };

        try {
            const respuesta = await clienteAxios.post(
                '/api/user/address/update',
                newData,
            );
            message.success(respuesta?.data?.message);
            const datosForm = {
                name: data?.name,
                street1: data?.street1,
                street2: data?.street2,
                number: data?.number,
                reference: data?.reference,
                latitude: data?.latitude,
                longitude: data?.longitude,
                checked: data?.checked,
            };

            // Crear dispatch
            dispatch(
                actualizarVerificarDireccionRD({
                    payload: {
                        datosForm,
                    },
                }),
            );

            mixpanel.track('verify_address', {
                id: data?.id,
                name: data?.name,
                street1: data?.street1,
                street2: data?.street2,
                number: data?.number,
                reference: data?.reference,
                latitude: data?.latitude,
                longitude: data?.longitude,
                checked: data?.checked,
                message: respuesta?.data?.message,
            });
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Hubo un problema al verificar la dirección',
            );
            message.error(errorMessage);

            dispatch(cerrarModalVerificarDireccion());

            mixpanel.track('support_verify_address_error', {
                message: errorMessage,
            });
        }
    };

    // AGENDADOS

    const abrirAceptarAgendado = (ordenSeleccionada) => {
        dispatch(abrirModalAceptarAgendado({ ordenSeleccionada }));
    };

    const cerrarAceptarAgendado = (ordenSeleccionada) => {
        dispatch(cerrarModalAceptarAgendado(ordenSeleccionada));
    };

    const abrirRechazarAgendado = (ordenSeleccionada) => {
        dispatch(abrirModalRechazarAgendado({ ordenSeleccionada }));
    };

    const cerrarRechazarAgendado = (ordenSeleccionada) => {
        dispatch(cerrarModalRechazarAgendado({ ordenSeleccionada }));
    };

    const confirmarAceptarAgendado = async (ordenSeleccionada) => {
        const idOrden = ordenSeleccionada.id;
        dispatch(confirmarAceptarAgendadoLoading());
        await cambiarEstadoOrden(idOrden, 12);
        dispatch(cerrarModalAceptarAgendado(ordenSeleccionada));
    };

    const confirmarRechazarAgendado = async (ordenSeleccionada) => {
        const idOrden = ordenSeleccionada.id;
        dispatch(confirmarRechazarAgendadoLoading());
        await cambiarEstadoOrden(idOrden, 2, 20); //cuando se rechaza el agendado pasar a cancelado (estado 2) y mandar reason_id 20
        dispatch(cerrarModalRechazarAgendado(ordenSeleccionada));
    };

    const setUserType = (tipoUsuario) => {
        dispatch(actualizarTipoUsuario(tipoUsuario?.esUsuarioComercio));
    };

    // TAB DE BÚSQUEDA

    const getSearchUrl = (orderStateId, keyWord, hasBancardCheck, page) => {
        let url;
        const isSearchWithBancardCheck = orderStateId && hasBancardCheck;
        const isSearchWithKeyword = Boolean(keyWord);
        if (isSearchWithBancardCheck) {
            url = `api/bancard/orders/${orderStateId}?page=${page}`;
        }
        if (isSearchWithKeyword) {
            const orderStateIdParam =
                orderStateId === 'todos'
                    ? ''
                    : '?order_state_id=' + orderStateId;
            const pageParam = `${orderStateIdParam ? '&' : '?'}page=${
                page || 1
            }`;
            url = `orders/search/${keyWord}${orderStateIdParam}${pageParam}`;
        }
        return url;
    };

    const obtenerOrdenesBusqueda = async (
        orderStateId,
        keyWord,
        hasBancardCheck,
        page,
    ) => {
        supportLoading();

        try {
            const url = getSearchUrl(
                orderStateId,
                keyWord,
                hasBancardCheck,
                page,
            );

            const response = await clienteAxios.get(`${baseURL}/${url}`);
            const ordersListResponse = response?.data?.data?.orders;
            const totalRows = response?.data?.data?.total;
            const rowsPerPage = response?.data?.data?.items_per_page;

            const formatedOrdersList = formatoTablaOrdenes(ordersListResponse);

            let ordersByState = Object.values(formatedOrdersList);
            let allOrders = [];
            ordersByState.forEach(
                (orders) => (allOrders = allOrders.concat(orders)),
            );

            dispatch(
                obtenerOrdenesBusquedaReducer({
                    ordersList: allOrders,
                    totalRows,
                    rowsPerPage,
                    page,
                }),
            );
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Ocurrió un problema al obtener las ordenes',
            );
            message.error(errorMessage);
            toggleSupportLoading();
            mixpanel.track('support_error_in_get_searched_orders', {
                error: errorMessage,
            });
        }
    };

    const cargarMasBusqueda = async (
        orderStateId,
        keyWord,
        hasBancardCheck,
    ) => {
        try {
            // Get page params
            const currentPage = paginaActualBusqueda;
            const totalPages = totalPaginasBusqueda;

            // Get next page number
            const nextPage = currentPage + 1;
            if (nextPage > totalPages) {
                throw 'El número de página actual es mayor a la cantidad de páginas disponibles';
            }

            // Get next page
            obtenerOrdenesBusqueda(
                orderStateId,
                keyWord,
                hasBancardCheck,
                nextPage,
            );
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'Ocurrió un problema al obtener mas ordenes',
            );
            message.error(errorMessage);
            supportLoading();
            mixpanel.track('support_error_in_loading_more_in_search_section', {
                error: errorMessage,
            });
        }
    };

    const abrirModalEnviarIntegracion = (ordenSeleccionada, esVerDetalle) => {
        mixpanel.track('support_send_to_integration');
        dispatch(
            abrirModalEnviarIntegracionReducer({
                ordenSeleccionada,
                esVerDetalle,
            }),
        );
    };

    const cerrarModalEnviarIntegracion = () => {
        dispatch(cerrarModalEnviarIntegracionReducer());
    };

    const confirmarEnviarIntegracion = async (orderId, idLocal, stateId) => {
        const confirmedOrderStateId = orders_states.find(
            (item) => item.name === 'confirmed',
        ).key;
        let urlIntegracion;

        const integracion = franquiciasIntegracionesSupport.find(
            (item) => item.idFranquicia === idLocal,
        );

        let data = {
            axiosBaseURL: baseURLIntegraciones,
            order_id: orderId,
        };

        switch (integracion.franquicia) {
            case 'MC':
                urlIntegracion = '/synchronize-mc';
                break;
            case 'BK':
                urlIntegracion = '/send-order';
                break;
            case 'PARIS':
                urlIntegracion = '/send_order_to_external';
                data = {
                    axiosBaseURL: baseURLIntegracionesParis,
                    franchiseId: ordenSeleccionada?.franchise_id,
                    orderId: orderId,
                };
                break;
            default:
                break;
        }

        try {
            dispatch(loadingIntegracionesReducer());
            const respuesta = await clienteAxios.post(
                `${urlIntegracion}`,
                data,
            );

            if (!respuesta.data.success) {
                throw new Error(
                    'Ocurrió un problema al enviar la orden a la sucursal',
                );
            }

            const description =
                respuesta?.data?.data?.data ||
                'Orden enviada correctamente a la sucursal';

            // se pasan de estado todos menos Mcdonalds, es automatico. y no se hace el cambio de estado en estado preparando de cualquier local
            if (stateId === confirmedOrderStateId) {
                if (integracion.franquicia !== 'MC') {
                    await cambiarEstadoOrden(orderId, 4);
                }
            }

            dispatch(actualizarOrdenIntegracionReducer());

            message.success(description);

            mixpanel.track('support_send_to_integration ', {
                orderId: orderId,
                idLocal: idLocal,
                description: description,
                responseData: respuesta,
            });
        } catch (error) {
            const checkForMessages =
                typeof error?.data?.data === 'object'
                    ? error?.data?.message
                    : error?.data?.data;

            notification.error({
                description:
                    error?.data?.data?.data ||
                    checkForMessages ||
                    error?.data?.data?.detail ||
                    error?.data?.message ||
                    error?.data ||
                    error?.response?.data?.data ||
                    'Ocurrió un problema al enviar la orden a la sucursal',
                duration: 0,
            });

            mixpanel.track('support_send_to_integration_error ', {
                orderId: orderId,
                idLocal: idLocal,
                error: { error },
            });
        }

        dispatch(cerrarModalEnviarIntegracionReducer({ cerrarVerOrden: true }));
    };

    const previewJedi = async ({ ordenSeleccionada }) => {
        const data = {
            axiosBaseURL: baseURLIntegracionesJedi,
            order_id: ordenSeleccionada?.id,
        };

        try {
            dispatch(loadingIntegracionesReducer());
            const respuesta = await clienteAxios.post(`/preview-order`, data);

            // Verificar si la respuesta es válida
            if (!respuesta || !respuesta.data || !respuesta.data.data) {
                throw new Error(
                    'La respuesta del servidor es inválida o no contiene datos.',
                );
            }

            const productos = respuesta.data.data.json?.detalle;
            let listaProductos = [];
            if (productos && Array.isArray(productos) && productos.length > 0) {
                for (let i = 0; i < productos.length; i++) {
                    // Asegurarse de que cada producto tiene las propiedades necesarias
                    if (
                        typeof productos[i] === 'object' &&
                        productos[i] !== null
                    ) {
                        const products = {
                            productoMonchis: productos[i].monchis_product,
                            productoJedi: productos[i].jedi_product,
                            addonsMonchis: Array.isArray(
                                productos[i].monchis_addons,
                            )
                                ? productos[i].monchis_addons.map(
                                      (item) => item,
                                  )
                                : [],
                            addonsJedi: Array.isArray(productos[i].combos)
                                ? productos[i].combos.map((item) => item)
                                : [],
                        };
                        listaProductos.push(products);
                    } else {
                        console.error(
                            'Se encontró un producto no válido o en un formato inesperado.',
                        );
                    }
                }
                let listaProductosTableIntegracionJedi = listaProductos;
                dispatch(
                    obtenerListaProductosIntegracionJediReducer({
                        listaProductosTableIntegracionJedi,
                    }),
                );
            } else {
                console.error(
                    'Productos no están definidos o la matriz está vacía',
                );
                throw new Error('No se pudieron cargar los productos.');
            }
        } catch (error) {
            console.error(`Error en previewJedi: ${error.message || error}`);
            const errorMessage = getErrorMessage(
                error,
                'Ocurrió un error al obtener datos de JEDI',
            );
            notification.error({
                description: errorMessage,
                duration: 0,
            });
            dispatch(loadingIntegracionesReducer());
            mixpanel.track('support_error_in_preview_jedi');
        }
    };

    const vaciarTablaBusqueda = () => {
        dispatch(vaciarTablaBusquedaReducer({ listaOrdenesTable: [] }));
    };

    const setHasPermitionToChangeState = (hasPermition) => {
        dispatch(
            hasPermitionToChangeState({
                hasPermissionToChangeState: hasPermition,
            }),
        );
    };

    // REVERTIR PAGO

    const revertirPago = (ordenSeleccionada, vieneDelDetalle) => {
        dispatch(
            revertirPagoReducer({
                ordenSeleccionada: ordenSeleccionada,
                vieneDelDetalle: vieneDelDetalle,
            }),
        );
    };

    const cerrarRevertirPago = () => {
        dispatch(cerrarModalRevertirPago());
    };

    const confirmarRevertirPago = async () => {
        dispatch(loadingRevertirPago());

        const cartId = ordenSeleccionada?.cart_id;

        const baseURL = urls.REACT_APP_BACKEND_URL_F;

        try {
            if (cartId) {
                const parametroRespuestaExito = 'Transacción Aprobada';

                const respuesta = await clienteAxios.post(
                    `${baseURL}/bancard/multibuy-rollback/${cartId}`,
                );
                const posibleRespuestaExito = respuesta?.data?.data?.messages
                    ?.length
                    ? respuesta?.data?.data?.messages[0]?.dsc
                    : undefined;

                if (posibleRespuestaExito === parametroRespuestaExito) {
                    const respuestaExito = respuesta?.data?.message;

                    dispatch(actualizarOrdenRevertirPagoReducer());

                    message.success(respuestaExito);
                    mixpanel.track('support_reverse_payment', {
                        cartId: ordenSeleccionada?.cart_id,
                        orderId: ordenSeleccionada?.id,
                        stateId:
                            ordenSeleccionada?.order_state_id +
                            '-' +
                            ordenSeleccionada?.status,
                        branch: ordenSeleccionada?.branch_id,
                        franchiseId: ordenSeleccionada?.branch?.franchise_id,
                        userId: ordenSeleccionada?.user_id,
                    });
                } else {
                    throw respuesta?.data;
                }
            } else {
                throw 'card_id no existe';
            }
        } catch (error) {
            const response = getErrorMessage(
                error,
                'Ocurrió un problema al revertir el pago',
            );
            const mensajeError = `Orden: ${ordenSeleccionada?.id} | Cart ID: ${cartId} | Respuesta: ${response}`;
            notification.info({
                description: mensajeError,
                duration: 0,
            });
            mixpanel.track('support_reverse_payment_error', {
                cartId: ordenSeleccionada?.cart_id,
                orderId: ordenSeleccionada?.id,
                stateId:
                    ordenSeleccionada?.order_state_id +
                    '-' +
                    ordenSeleccionada?.status,
                branch: ordenSeleccionada?.branch_id,
                franchiseId: ordenSeleccionada?.branch?.franchise_id,
                userId: ordenSeleccionada?.user_id,
                error: response,
            });
        }
        dispatch(loadingRevertirPago());
        dispatch(cerrarModalRevertirPago());
    };

    const abrirModalEnviarJedi = (ordenSeleccionada, esVerDetalle) => {
        mixpanel.track('support_send_to_integration');
        dispatch(
            abrirModalEnviarJediReducer({ ordenSeleccionada, esVerDetalle }),
        );
        previewJedi({ ordenSeleccionada });
    };

    const cerrarModalEnviarJedi = () => {
        dispatch(cerrarModalEnviarJediReducer());
    };

    const abrirModalConfirmacionEnviarJedi = (ordenSeleccionada) => {
        dispatch(abrirModalConfirmacionJedi({ ordenSeleccionada }));
    };

    const cerrarModalConfirmacionJedi = (cerrarVerOrden) => {
        dispatch(cerrarModalConfirmacionJediReducer({ cerrarVerOrden }));
    };

    const confirmarEnviarJedi = async (orderId, stateId) => {
        const data = {
            axiosBaseURL: baseURLIntegracionesJedi,
            order_id: orderId,
        };

        const idEstadoPendiente = 3;

        try {
            dispatch(loadingIntegracionesReducer());
            const respuesta = await clienteAxios.post(`/send-order`, data);

            const respuestaEsExitosa = respuesta?.data?.success;
            const description = 'Orden enviada correctamente a JEDI';

            if (respuestaEsExitosa) {
                if (stateId === idEstadoPendiente) {
                    await cambiarEstadoOrden(orderId, 4);
                }

                message.success(description);

                mixpanel.track('support_send_to_jedi', {
                    orderId: orderId,
                    message: respuestaEsExitosa,
                    description: description,
                });
            } else {
                throw respuesta.data;
            }
        } catch (error) {
            const response = getErrorMessage(
                error,
                'Ocurrio un error al enviar la orden a JEDI',
            );

            const mensajeError = `Orden: ${ordenSeleccionada?.id} | Respuesta: ${response}`;

            notification.error({
                description: mensajeError,
                duration: 0,
            });

            mixpanel.track('support_send_to_jedi_error', {
                orderId: orderId,
                messagge: response,
            });
        } finally {
            let cerrarVerOrden = true;
            dispatch(cerrarModalEnviarJediReducer({ cerrarVerOrden }));
        }
    };

    return {
        pedidos,

        ordenSeleccionada,
        isVerOrdenModalVisible,
        isCambiarEstadoModalVisible,
        isVerificarDireccionModalVisible,
        isRevertirPagoModalVisible,

        totalPendientes,
        totalPreparando,
        totalDeliveryPickup,
        totalFinalizados,
        totalCancelados,
        totalAgendados,

        paginaActualPendientes,
        paginaActualPreparando,
        paginaActualDeliveryPickup,
        paginaActualFinalizados,
        paginaActualCancelados,
        paginaActualAgendados,
        paginaActualBusqueda,

        totalPaginasPendientes,
        totalPaginasPreparando,
        totalPaginasDeliveryPickup,
        totalPaginasFinalizados,
        totalPaginasCancelados,
        totalPaginasAgendados,
        totalPaginasBusqueda,

        loading,
        supportLoading,
        imprimirOrdenLoading,
        cancelarOrdenLoading,

        isCancelarOrdenModalVisible,
        isSolicitarDriverModalVisible,
        isRevertirCancelacionModalVisible,
        revertirCancelacionLoading,
        revertirPagoLoading,

        listaPerfiles,
        listaOrdenes,
        listaOrdenesBusqueda,
        listaMotivosCancelacion,
        listaOrdenesCambioEstado,

        getProfiles,
        sendProfiles,

        obtenerTodasOrdenes,
        obtenerSiguienteEstado,
        cargarMas,
        actualizarOrdenesWS,

        setHasPermissionsLocked,
        hasPermissionsLocked,
        verOrdenModal,
        cerrarOrdenModal,
        imprimirOrden,
        abrirModalCambioEstadoSeleccionados,

        siguienteEstado,
        cambiarEstado,
        cerrarCambiarEstado,
        confirmarCambiarEstado,
        cambiarEstadoLoading,
        verificarDireccionLoading,

        solicitarDriver,
        solicitarDriverLoading,
        cerrarSolicitarDriver,
        confirmarSolicitarDriver,

        cancelarOrden,
        cerrarCancelarOrden,
        confirmarCancelarOrden,

        revertirCancelacion,
        cerrarRevertirCancelacion,
        confirmarRevertirCancelacion,

        verificarDireccion,
        cerrarVerificarDireccion,
        confirmarVerificarDireccion,

        isAceptarAgendadoModalVisible,
        isRechazarAgendadoModalVisible,

        aceptarAgendadoLoading,
        rechazarAgendadoLoading,

        abrirAceptarAgendado,
        cerrarAceptarAgendado,
        abrirRechazarAgendado,
        cerrarRechazarAgendado,
        confirmarAceptarAgendado,
        confirmarRechazarAgendado,

        obtenerOrdenesBusqueda,
        cargarMasBusqueda,
        vaciarTablaBusqueda,
        setUserType,
        setHasPermitionToChangeState,

        permisoVerIntegraciones,
        obtenerPermisoIntegraciones,

        revertirPago,
        cerrarRevertirPago,
        confirmarRevertirPago,
        abrirModalEnviarIntegracion,
        cerrarModalEnviarIntegracion,
        confirmarEnviarIntegracion,
        abrirModalEnviarJedi,
        listaProductosTableIntegracionJedi,
        PreviewJediModalVisible,
        cerrarModalEnviarJedi,
        enviarJediModalVisible,
        abrirModalConfirmacionEnviarJedi,
        cerrarModalConfirmacionJedi,
        confirmarEnviarJedi,

        deshabilitarBotonIntegracion,
    };
};

export default useSupport;
