import React, { useContext, useEffect, useState } from 'react';
import { Tabs, Card, Button, notification, Spin, Form } from 'antd';
import SupportContext from '../../../../context/support/SupportContext';
import UtilidadesContext from '../../../../context/utilidades/UtilidadesContext';
import PropTypes from 'prop-types';
import ABMLayout from '../../../../layouts/ABMLayout';

import Pendientes from './Pendientes/Pendientes';
import Preparando from './Preparando/Preparando';
import DeliveryPickUp from './DeliveryPickUp/DeliveryPickUp';
import Finalizados from './Finalizados/Finalizados';
import Cancelados from './Cancelados/Cancelados';
import Busqueda from './Busqueda/Busqueda';
import classes from './seguimiento.module.css';
import './seguimiento.css';
import Agendados from './Agendados/Agendados';
import CancelarOrdenModal from '../../../molecules/Support/Modal/CancelarOrdenModal';
import SolicitarDriverModal from '../../../molecules/Support/Modal/SolicitarDriverModal';
import CambiarEstadoModal from '../../../molecules/Support/Modal/CambiarEstadoModal';
import RevertirPagoModal from '../../../molecules/Support/Modal/RevertirPagoModal';
import RevertirCancelacionModal from '../../../molecules/Support/Modal/RevertirCancelacionModal';
import VerOrdenModal from '../../../molecules/Support/Modal/VerOrdenModal/VerOrdenModal';
import AceptarAgendadoModal from '../../../molecules/Support/Modal/AceptarAgendadoModal';
import RechazarAgendadoModal from '../../../molecules/Support/Modal/RechazarAgendadoModal';
import VerificarDireccionModal from '../../../molecules/Support/Modal/VerificarDireccionModal';
import EnviarIntegracionModal from '../../../molecules/Support/Modal/EnviarIntegracionModal';
import PreviewJediModal from '../../../molecules/Support/Modal/PreviewJediModal';
import EstadoJediModal from '../../../molecules/Support/Modal/EstadoJediModal';
import EnviarJediModal from '../../../molecules/Support/Modal/EnviarJediModal';
import { useSocketSupport } from '../../../hooks/useSocketSupport';
import useSupport from '../../../../hooks/support/useSupport';
import { useVerificarPermiso } from '../../../hooks/useVerificarPermiso';
import PermissionsAlert from '../../../molecules/Support/Alert/PermissionsAlert';
import { useSelector } from 'react-redux';
import Lupa from '../../../../assets/svg/Lupa';
import OrderStatusSelect from 'components/atoms/OrderStatusSelect';
import { Col } from '../../../../../node_modules/antd/lib/index';
import Hotjar from '@hotjar/browser';

const siteId = 3751376;
const hotjarVersion = 6;

const Seguimiento = () => {
    Hotjar.init(siteId, hotjarVersion);
    const name = localStorage.getItem('nombre');
    const lastName = localStorage.getItem('apellido');
    const email = localStorage.getItem('email');

    let userId = email || name + lastName || null;
    window.hj('identify', userId, {
        first_name: name,
        email: email,
    });

    const {
        cerrarModalVerEstadoJedi,
        datosModalVerEstadoJedi,
        listaTableEstadoJedi,
        loading: loadingContext,
        modalEstadoJediVisible,
        ordenesSeleccionadas,
    } = useContext(SupportContext);

    const {
        isAceptarAgendadoModalVisible,
        enviarIntegracionModalVisible,
        loadingIntegraciones,
    } = useSelector((state) => state.support);

    const { getOrdersStates } = useContext(UtilidadesContext);
    const [triggerSearch, setTriggerSearch] = useState(false);
    const { online, conectarSocket } = useSocketSupport();
    const [filter, setFilter] = useState('');

    const { hasPermissionsLocked } = useSelector((state) => state.support);
    const [formFiltros] = Form.useForm();
    const {
        isVerOrdenModalVisible,
        ordenSeleccionada,
        pedidos,

        confirmarCambiarEstado,
        cambiarEstado,
        cerrarCambiarEstado,
        cambiarEstadoLoading,
        siguienteEstado,
        alertSend,

        totalPendientes,
        totalPreparando,
        totalDeliveryPickup,
        totalFinalizados,
        totalCancelados,
        totalAgendados,

        isCancelarOrdenModalVisible,
        isCambiarEstadoModalVisible,
        listaMotivosCancelacion,

        imprimirOrdenLoading,

        solicitarDriverLoading,
        isSolicitarDriverModalVisible,
        cerrarSolicitarDriver,
        confirmarSolicitarDriver,

        cerrarCancelarOrden,
        cancelarOrden,
        cancelarOrdenLoading,
        confirmarCancelarOrden,

        cerrarRevertirCancelacion,
        isRevertirCancelacionModalVisible,
        confirmarRevertirCancelacion,
        revertirCancelacionLoading,

        loading: loadingRedux,

        obtenerTodasOrdenes,
        obtenerSiguienteEstado,
        cerrarOrdenModal,

        verificarDireccion,
        verificarDireccionLoading,
        cerrarVerificarDireccion,
        confirmarVerificarDireccion,
        isVerificarDireccionModalVisible,

        permisoVerIntegraciones,
        revertirPagoLoading,
        cerrarRevertirPago,
        isRevertirPagoModalVisible,
        confirmarRevertirPago,

        setUserType,
        cerrarAceptarAgendado,
        isRechazarAgendadoModalVisible,
        cerrarRechazarAgendado,

        cerrarModalEnviarIntegracion,
        confirmarEnviarIntegracion,
        listaProductosTableIntegracionJedi,
        previewJedi,
        PreviewJediModalVisible,
        cerrarModalEnviarJedi,
        enviarJediModalVisible,
        abrirModalConfirmacionEnviarJedi,
        cerrarModalConfirmacionJedi,
        confirmarEnviarJedi,
    } = useSupport();

    const { tipoUsuario } = useVerificarPermiso();

    const loading = loadingContext || loadingRedux;

    const [notificacion, setNotificacion] = useState(false);

    const [activeKey, setActiveKey] = useState('1');

    const [bancardCheck, setBancardCheck] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const storedRole = JSON.parse(localStorage.getItem('role'));
        const admins = [1, 22];

        const isAdmin = admins.includes(Number(storedRole));
        setIsAdmin(isAdmin);
    }, []);

    const [filterStatus, setFilterStatus] = useState();
    useEffect(() => {
        if (tipoUsuario !== undefined) {
            const userType = { esUsuarioComercio: tipoUsuario };
            setUserType(userType);
            obtenerTodasOrdenes(userType);
            // Se obtiene los estados de las ordenes para
            // poder utilizar a en todas las pantallas del support
            getOrdersStates();
        }
    }, [tipoUsuario]);

    useEffect(() => {
        if (
            !loading &&
            !online &&
            !notificacion &&
            (pedidos?.PENDIENTES?.length !== 0 ||
                pedidos?.PREPARANDO?.length !== 0 ||
                pedidos?.DELIVERYPICKUP?.length !== 0 ||
                pedidos?.FINALIZADOS?.length !== 0)
        ) {
            setNotificacion(true);
            notification.open({
                message: 'Actualizar pedidos de forma manual',
                description:
                    'Tenés pedidos en cola, por favor actualizá el listado para poder visualizarlos.',
                duration: 5,
                placement: 'topRight',
                top: 90,
                className: classes.notificacionRecargar,
                onClose: setNotificacion(false),
            });
        }
    }, [online, loading, notificacion, pedidos]);

    const handleSearch = (value) => {
        setFilter(value);
        setTriggerSearch(true);
    };

    const items = [
        {
            label: (
                <div>
                    Pendientes
                    <Button
                        shape="circle"
                        size="small"
                        className={classes.totalPedidos}>
                        {totalPendientes}
                    </Button>
                </div>
            ),
            key: '1',
            children: (
                <Pendientes
                    pendientes={pedidos['PENDIENTES']}
                    online={online}
                    ordenSeleccionada={ordenSeleccionada}
                    conectarSocket={conectarSocket}
                    loading={loading}
                    alertSend={alertSend}
                />
            ),
        },
        {
            label: (
                <div>
                    Preparando
                    <Button
                        shape="circle"
                        size="small"
                        className={classes.totalPedidos}>
                        {totalPreparando}
                    </Button>
                </div>
            ),
            key: '2',
            children: (
                <Preparando
                    preparando={pedidos['PREPARANDO']}
                    online={online}
                    conectarSocket={conectarSocket}
                    loading={loading}
                />
            ),
        },
        {
            label: (
                <div>
                    Delivery/Pick up
                    <Button
                        shape="circle"
                        size="small"
                        className={classes.totalPedidos}>
                        {totalDeliveryPickup}
                    </Button>
                </div>
            ),
            key: '3',
            children: (
                <DeliveryPickUp
                    deliveryPickUp={pedidos['DELIVERYPICKUP']}
                    online={online}
                    conectarSocket={conectarSocket}
                    loading={loading}
                />
            ),
        },
        {
            label: (
                <div>
                    Agendados
                    <Button
                        shape="circle"
                        size="small"
                        className={classes.totalPedidos}>
                        {totalAgendados}
                    </Button>
                </div>
            ),
            key: '6',
            children: (
                <Agendados
                    agendados={pedidos['AGENDADOS']}
                    online={online}
                    conectarSocket={conectarSocket}
                    loading={loading}
                />
            ),
        },
        {
            label: (
                <div>
                    Cancelados
                    <Button
                        shape="circle"
                        size="small"
                        className={classes.totalPedidos}>
                        {totalCancelados}
                    </Button>
                </div>
            ),
            key: '5',
            children: (
                <Cancelados
                    cancelados={pedidos['CANCELADOS']}
                    online={online}
                    conectarSocket={conectarSocket}
                    loading={loading}
                />
            ),
        },
        {
            label: (
                <div>
                    Historial
                    <Button
                        shape="circle"
                        size="small"
                        className={classes.totalPedidos}>
                        {totalFinalizados}
                    </Button>
                </div>
            ),
            key: '4',
            children: (
                <Finalizados
                    finalizados={pedidos['FINALIZADOS']}
                    online={online}
                    conectarSocket={conectarSocket}
                    loading={loading}
                />
            ),
        },
        {
            key: '7',
            children: (
                <Busqueda
                    filter={filter}
                    triggerSearch={triggerSearch}
                    setTriggerSearch={setTriggerSearch}
                    activeTab={activeKey}
                    filterStatus={filterStatus}
                    bancard={bancardCheck}
                />
            ),
        },
    ];

    return (
        <ABMLayout
            titulo={
                <div className={classes.Titulo}>
                    <strong>Soporte Pedidos Monchis</strong>
                </div>
            }
            busqueda={
                <div className={classes.Buscador}>
                    <Lupa
                        onClick={() => {
                            setTriggerSearch(true);
                            setActiveKey('7');
                        }}
                        style={{ cursor: 'pointer' }}
                    />
                    <input
                        className={classes.InputBuscador}
                        name="filter"
                        value={filter}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            setFilter(newValue);
                        }}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearch(filter);
                                setActiveKey('7');
                            }
                        }}
                        placeholder="Buscar"
                    />

                    <OrderStatusSelect
                        isSupport
                        formInstance={formFiltros}
                        setFilterStatus={setFilterStatus}
                    />
                </div>
            }>
            {isAdmin && (
                <Col span={7} className={classes.ColumnaBancard}>
                    <Form.Item
                        name="bancard"
                        valuePropName="checked"
                        initialValue={bancardCheck}>
                        <div
                            className={`${classes.Checkbox} ${classes.alignItemsCenter}`}>
                            <input
                                type="checkbox"
                                checked={bancardCheck}
                                className={classes.Check}
                                onChange={(e) =>
                                    setBancardCheck(e.target.checked)
                                }></input>
                            <p className={classes.TituloColumna}>
                                Pendientes de cancelación en BANCARD
                            </p>
                        </div>
                    </Form.Item>
                </Col>
            )}
            <PermissionsAlert hasPermissionsLocked={hasPermissionsLocked} />
            <Spin spinning={imprimirOrdenLoading}>
                <Card>
                    <Tabs
                        size="small"
                        defaultActiveKey="1"
                        activeKey={activeKey}
                        animated={false}
                        items={items}
                        onChange={(key) => {
                            setActiveKey(key);
                            setFilter('');
                        }}
                        destroyInactiveTabPane></Tabs>
                </Card>

                {/* Modales */}
                <SolicitarDriverModal
                    cancelarSolicitarDriver={cerrarSolicitarDriver}
                    confirmarSolicitarDriver={confirmarSolicitarDriver}
                    idOrden={ordenSeleccionada?.id}
                    isSolicitarDriverModalVisible={
                        isSolicitarDriverModalVisible
                    }
                    solicitarDriverLoading={solicitarDriverLoading}
                />
                <VerOrdenModal
                    cambiarEstado={cambiarEstado}
                    cancelarOrden={cancelarOrden}
                    cerrarVerOrden={cerrarOrdenModal}
                    isVerOrdenModalVisible={isVerOrdenModalVisible}
                    obtenerSiguienteEstado={obtenerSiguienteEstado}
                    ordenSeleccionada={ordenSeleccionada}
                    permisoVerIntegraciones={permisoVerIntegraciones}
                    verificarDireccion={verificarDireccion}
                />
                <RevertirPagoModal
                    cerrarRevertirPago={cerrarRevertirPago}
                    confirmarRevertirPago={confirmarRevertirPago}
                    isRevertirPagoModalVisible={isRevertirPagoModalVisible}
                    revertirPagoLoading={revertirPagoLoading}
                />
                <CancelarOrdenModal
                    cancelarOrdenLoading={cancelarOrdenLoading}
                    cerrarCancelarOrden={cerrarCancelarOrden}
                    confirmarCancelarOrden={confirmarCancelarOrden}
                    idOrden={ordenSeleccionada?.id}
                    isCancelarOrdenModalVisible={isCancelarOrdenModalVisible}
                    listaMotivosCancelacion={listaMotivosCancelacion}
                />
                <CambiarEstadoModal
                    cambiarEstadoLoading={cambiarEstadoLoading}
                    cerrarCambiarEstado={cerrarCambiarEstado}
                    confirmarCambiarEstado={confirmarCambiarEstado}
                    idOrden={ordenSeleccionada?.id || ordenesSeleccionadas}
                    isCambiarEstadoModalVisible={isCambiarEstadoModalVisible}
                    siguienteEstado={siguienteEstado}
                />
                <RevertirCancelacionModal
                    cerrarRevertirCancelacion={cerrarRevertirCancelacion}
                    confirmarRevertirCancelacion={confirmarRevertirCancelacion}
                    idOrden={ordenSeleccionada?.id}
                    isRevertirCancelacionModalVisible={
                        isRevertirCancelacionModalVisible
                    }
                    revertirCancelacionLoading={revertirCancelacionLoading}
                />
                <AceptarAgendadoModal
                    ordenSeleccionada={ordenSeleccionada}
                    isAceptarAgendadoModalVisible={
                        isAceptarAgendadoModalVisible
                    }
                    cerrarAceptarAgendado={cerrarAceptarAgendado}
                />
                <RechazarAgendadoModal
                    ordenSeleccionada={ordenSeleccionada}
                    isRechazarAgendadoModalVisible={
                        isRechazarAgendadoModalVisible
                    }
                    cerrarRechazarAgendado={cerrarRechazarAgendado}
                />
                <VerificarDireccionModal
                    cerrarVerificarDireccion={cerrarVerificarDireccion}
                    confirmarVerificarDireccion={confirmarVerificarDireccion}
                    isVerificarDireccionModalVisible={
                        isVerificarDireccionModalVisible
                    }
                    ordenSeleccionada={ordenSeleccionada}
                    verificarDireccionLoading={verificarDireccionLoading}
                />
                <EnviarIntegracionModal
                    cerrarModalEnviarIntegracion={cerrarModalEnviarIntegracion}
                    confirmarEnviarIntegracion={confirmarEnviarIntegracion}
                    enviarIntegracionModalVisible={
                        enviarIntegracionModalVisible
                    }
                    loadingIntegraciones={loadingIntegraciones}
                    ordenSeleccionada={ordenSeleccionada}
                />
                <PreviewJediModal
                    abrirModalConfirmacionEnviarJedi={
                        abrirModalConfirmacionEnviarJedi
                    }
                    cerrarModalEnviarJedi={cerrarModalEnviarJedi}
                    listaProductosTableIntegracionJedi={
                        listaProductosTableIntegracionJedi
                    }
                    loadingIntegraciones={loadingIntegraciones}
                    ordenSeleccionada={ordenSeleccionada}
                    previewJedi={previewJedi}
                    PreviewJediModalVisible={PreviewJediModalVisible}
                />
                <EstadoJediModal
                    cerrarModalVerEstadoJedi={cerrarModalVerEstadoJedi}
                    datosModalVerEstadoJedi={datosModalVerEstadoJedi}
                    listaTableEstadoJedi={listaTableEstadoJedi}
                    loadingIntegraciones={loadingIntegraciones}
                    modalEstadoJediVisible={modalEstadoJediVisible}
                />
                <EnviarJediModal
                    cerrarModalConfirmacionJedi={cerrarModalConfirmacionJedi}
                    confirmarEnviarJedi={confirmarEnviarJedi}
                    enviarJediModalVisible={enviarJediModalVisible}
                    loadingIntegraciones={loadingIntegraciones}
                    ordenSeleccionada={ordenSeleccionada}
                />
            </Spin>
        </ABMLayout>
    );
};

export default Seguimiento;

Seguimiento.propTypes = {
    esUsuarioComercio: PropTypes.bool,
};
