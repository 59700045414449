import React, { useState } from 'react';
import { Modal, Radio, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

import ModalContent from '../../../../atoms/ModalContent';

const ChooseDisableBranchesMethodModal = ({
    isVisible,
    onSubmit,
    onCancel,
    title,
}) => {
    const [itsManual, setItsManual] = useState(true);

    const submitChoosenMethodHanler = () => {
        onSubmit(itsManual);
    };

    const cancelButtonHandler = () => {
        setItsManual(true);
        onCancel();
    };

    return (
        <Modal
            centered
            footer={null}
            open={isVisible}
            title={title}
            closable={false}
            destroyOnClose>
            <ModalContent
                isLoading={false}
                okText="Continuar"
                cancelText="Cancelar"
                onCancel={cancelButtonHandler}
                onOk={submitChoosenMethodHanler}>
                <Space direction="vertical">
                    <Typography.Text>
                        Seleccioná el modo para volver a habilitar:
                    </Typography.Text>
                    <Radio.Group
                        onChange={(e) => setItsManual(e.target.value)}
                        defaultValue={itsManual}>
                        <Space direction="vertical">
                            <Radio.Button value={true}>Manual</Radio.Button>
                            <Radio.Button value={false}>
                                Programada
                            </Radio.Button>
                        </Space>
                    </Radio.Group>
                </Space>
            </ModalContent>
        </Modal>
    );
};

export default ChooseDisableBranchesMethodModal;

ChooseDisableBranchesMethodModal.propTypes = {
    isVisible: PropTypes.bool,
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    title: PropTypes.string,
};
