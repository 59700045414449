import {
    OBTENER_LISTADO_RECIBO,
    RECIBO_TOTALES,
    SELECCIONAR_PAGINA_RECIBO,
    RECIBO_GUARDAR_SELECCIONADO,
} from '../../../types';

export const initialState = {
    listadoRecibos: [],
    paginaActualRecibo: 1,
    totalRecibos: '',
    reciboSeleccionado: undefined,
};

const ListaReciboReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_LISTADO_RECIBO:
            return {
                ...state,
                listadoRecibos: action.payload,
            };
        case RECIBO_TOTALES:
            return {
                ...state,
                totalRecibos: action.payload,
            };
        case SELECCIONAR_PAGINA_RECIBO:
            return {
                ...state,
                paginaActualRecibo: action.payload,
            };
        case RECIBO_GUARDAR_SELECCIONADO:
            return {
                ...state,
                reciboSeleccionado: action.payload,
            };

        default:
            return state;
    }
};

export default ListaReciboReducer;
