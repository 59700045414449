import React, { useState } from 'react';
import { Card, Row, Col, Form, Button, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import useFranchiseAndBranchStatus from '../../../hooks/useFranchiseAndBranchStatus';

import ABMLayout from '../../../../layouts/ABMLayout';
import { Divider, Space } from '../../../../../node_modules/antd/lib/index';
import MatchearModal from './MatchearModal';

const MatchearAtributo = () => {
    const brandsHook = useFranchiseAndBranchStatus();
    const [modalMatchear, setmodalMatchear] = useState(false);
    const [form] = Form.useForm();
    const history = useHistory();

    const handleCancelMatchear = () => {
        setmodalMatchear(false);
    };

    const volverListado = () => {
        history.push('/administrar/integraciones/atributos');
    };

    return (
        <ABMLayout
            titulo="Matchear atributo"
            acciones={
                <Space>
                    <Button
                        type="link"
                        style={{ color: '#3F81F7' }}
                        onClick={() => volverListado()}>
                        Volver al listado
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => setmodalMatchear(true)}>
                        Matchear
                    </Button>
                </Space>
            }
            loading={brandsHook.loadingBrands}>
            <Card>
                <strong>
                    <h3>Franquicia:</h3>
                </strong>
                <Divider></Divider>
                <Form form={form} layout="vertical">
                    <Row>
                        <Col
                            span={8}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <span style={{ marginRight: '5px' }}>
                                <strong>Atributo:</strong>
                            </span>
                            <label>Up sizing nuggets</label>
                        </Col>
                        <Col
                            span={8}
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                            <span style={{ marginRight: '5px' }}>
                                <strong>Tipo de atributo: </strong>
                            </span>
                            <label>Agregar</label>
                        </Col>
                    </Row>
                </Form>
                <Col style={{ marginTop: '20px' }}>
                    <span style={{ marginRight: '10px' }}>
                        <strong>Atributo Monchis: </strong>
                    </span>
                    <Select
                        mode="multiple"
                        placeholder="Todas las opciones"
                        style={{ width: '30%' }}>
                        <Select.Option value="Individual">
                            Individual
                        </Select.Option>
                        <Select.Option value="Opción 2">Opción 2</Select.Option>
                        <Select.Option value="Opción 3">Opción 3</Select.Option>
                    </Select>
                </Col>
            </Card>
            <MatchearModal
                modalMatchear={modalMatchear}
                handleCancelMatchear={handleCancelMatchear}
            />
        </ABMLayout>
    );
};

export default MatchearAtributo;
