import { useState } from 'react';
import { message } from 'antd';
import clienteAxios from '../../../../../config/axios';
import { useMixpanel } from 'react-mixpanel-browser';

export const useConsultasCorteTransacccion = () => {
    const [loading, setLoading] = useState(false);
    const mixpanel = useMixpanel();

    const enviarCorreo = async (listaIdsCortesArr) => {
        let respuesta = false;
        setLoading(true);
        const params = {
            arrayId: listaIdsCortesArr,
        };
        try {
            await clienteAxios.post(
                '/send_mail_balance_withStateMachine',
                params,
            );
            respuesta = true;
            mixpanel.track('transactions_cuts_send_email_success');
        } catch (error) {
            message.error('hubo un error al enviar correo');
            mixpanel.track('transactions_cuts_send_email_error', { error });
        }
        setLoading(false);
        return respuesta;
    };

    return { enviarCorreo, loading };
};
