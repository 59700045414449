import React from 'react';
import { Form, Card, Radio, Space } from 'antd';
const PushDireccionesUsuario = () => {
    return (
        <Card title="Dirección del usuario">
            <Form.Item
                name="direccionesUsuario"
                label="Recibir mensaje push en:"
                rules={[{ required: true }]}>
                <Radio.Group>
                    <Space direction="vertical">
                        <Radio value={true}>Dirección principal</Radio>
                        <Radio value={false}>Todas las direcciones</Radio>
                    </Space>
                </Radio.Group>
            </Form.Item>
        </Card>
    );
};

export default PushDireccionesUsuario;
