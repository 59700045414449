import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';
import { formatoGuaranies } from '../../../utils-components/Number';
import './TablaDetalleVerOrden1.css';

const TableDetalleVerOrden = ({ listaProductos }) => {
    const columns = [
        {
            title: 'Cantidad',
            dataIndex: 'cantidad',
            key: 'cantidad',
            className: 'table-columns',
        },
        {
            title: 'Producto',
            dataIndex: 'producto',
            key: 'producto',
            className: 'table-columns',
        },
        {
            title: 'Precio Unitario',
            dataIndex: 'precio',
            key: 'precio',
            className: 'table-columns',
        },
        {
            title: 'Addons',
            dataIndex: 'addons',
            key: 'addons',
            align: 'left',
            className: 'table-columns',
            children: [
                {
                    title: 'Cant.',
                    dataIndex: 'cantidad_addons',
                    key: 'cantidad_addons',
                    className: 'table-columns addon-child',
                    render: (_text, record) => (
                        <>
                            {record?.addons?.map((item) => (
                                <p key={item?.id}>{item?.quantity}</p>
                            ))}
                        </>
                    ),
                },
                {
                    title: 'Detalle',
                    dataIndex: 'detalle_addons',
                    key: 'detalle_addons',
                    className: 'table-columns addon-child',
                    render: (_text, record) => (
                        <>
                            {record?.addons?.map((item) => (
                                <p key={item?.id}>{item?.name}</p>
                            ))}
                        </>
                    ),
                },
                {
                    title: 'Precio',
                    dataIndex: 'precio_addons',
                    key: 'precio_addons',
                    className: 'table-columns addon-child',
                    render: (_text, record) => (
                        <>
                            {record?.addons?.map((item) => (
                                <p key={item?.id}>
                                    {formatoGuaranies(item?.price)}
                                </p>
                            ))}
                        </>
                    ),
                },
            ],
        },
        {
            title: 'Comentario',
            dataIndex: 'comentarios',
            key: 'comentarios',
            className: 'table-columns',
        },
    ];

    const listaProductosFormateado = listaProductos?.map((item) => ({
        key: item?.id,
        cantidad: item?.quantity,
        producto: item?.product?.name,
        codigo_barra: item?.product?.barcode || '-',
        codigo_interno: item?.internal_code || '-',
        precio: formatoGuaranies(item?.total),
        addons: item?.order_product_addons,
        comentarios: item?.comments || '-',
    }));

    return (
        <div className="table-container">
            <Table
                columns={columns}
                dataSource={listaProductosFormateado}
                pagination={false}
            />
        </div>
    );
};

TableDetalleVerOrden.propTypes = {
    listaProductos: PropTypes.array,
};

export default TableDetalleVerOrden;
