import React from 'react';
import { Modal, Typography } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';

const { Title } = Typography;

const RevertirPagoModal = ({
    isRevertirPagoModalVisible,
    revertirPagoLoading,
    cerrarRevertirPago,
    confirmarRevertirPago,
}) => {
    return (
        <Modal
            centered
            open={isRevertirPagoModalVisible}
            footer={null}
            closable={false}
            title={<Title level={2}>¡Atención!</Title>}
            onCancel={cerrarRevertirPago}>
            <ModalContent
                title="¿Está seguro de revertir el pago online?"
                okText="REVERTIR PAGO"
                cancelText="CANCELAR"
                onOk={confirmarRevertirPago}
                onCancel={cerrarRevertirPago}
                isLoading={revertirPagoLoading}
                invertPrimary
                showWarnIcon
                isDelete
            />
        </Modal>
    );
};

RevertirPagoModal.propTypes = {
    isRevertirPagoModalVisible: PropTypes.bool,
    revertirPagoLoading: PropTypes.bool,
    cerrarRevertirPago: PropTypes.func,
    confirmarRevertirPago: PropTypes.func,
};

export default RevertirPagoModal;
