import React, { useReducer } from 'react';
import AdministrarContext from './AdministrarContext';
import PropTypes from 'prop-types';
import { urls } from '../../constants';
import clienteAxios from '../../config/axios';
import AdministrarReducer, { initialState } from './AdministrarReducer';
import {
    PRODUCT_REPORT_LOADING,
    PRODUCT_LIST,
    FRANCHISES_SELECT_LIST,
    FILTERED_FRANCHISE_LIST,
    PRODUCT_ACTIONS_MODAL_VISIBLE,
    CLOSE_PRODUCT_ACTIONS_MODAL,
} from './Types';
import { message } from 'antd';

const franchisesBaseURL = urls.REACT_APP_BACKEND_URL_A;
const uploadBaseURL = urls.REACT_APP_BACKEND_URL_C;

const AdministrarState = ({ children }) => {
    const [state, dispatch] = useReducer(AdministrarReducer, initialState);

    // Get franchise list for filter
    const getFranchiseList = async (datos) => {
        let franchisesArr = [];
        try {
            const { data } = await clienteAxios.get('/franchises_list', {
                params: datos,
                franchisesBaseURL,
            });
            const franchiseFullList = data?.data?.franchises;
            franchiseFullList.forEach((item) =>
                franchisesArr.push({
                    key: item.id,
                    value: item.name,
                    id: item.id,
                }),
            );
            dispatch({
                type: FRANCHISES_SELECT_LIST,
                payload: { franchiseSelectList: franchisesArr },
            });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un error al obtener la lista de franquicias',
            );
        }
    };

    const filteredFranchiseList = (filters) => {
        dispatch({
            type: FILTERED_FRANCHISE_LIST,
            payload: { filters: filters },
        });
    };

    // Get csv list
    const downloadProducts = async (filters) => {
        dispatch({
            type: PRODUCT_REPORT_LOADING,
        });
        message.info('Obteniendo listado...');
        const params = {
            ...filters,
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_SV_RECEPTION,
        };
        try {
            const { data } = await clienteAxios.get('/products_download', {
                params,
            });
            const downloadLink = data?.data?.link_download_products;
            dispatch({
                type: PRODUCT_LIST,
                payload: { productList: downloadLink },
            });
            window.open(downloadLink);
        } catch (error) {
            message.error(
                error?.data?.message || 'Hubo un error al obtener los datos',
            );
            dispatch({
                type: PRODUCT_REPORT_LOADING,
            });
        }
        dispatch({
            type: PRODUCT_REPORT_LOADING,
        });
    };

    // Open modal

    const productActionsModalVisible = () => {
        dispatch({
            type: PRODUCT_ACTIONS_MODAL_VISIBLE,
        });
    };

    // Upload product list
    const uploadProducts = async (file) => {
        dispatch({
            type: PRODUCT_REPORT_LOADING,
        });
        const key = 'cargandoListado';
        message.info({
            content: 'Cargando listado...',
            key,
            duration: 0,
        });

        let productDataArray = [];
        file?.forEach((item) => {
            const formattedFile = {
                id: item.id,
                name: item.productname,
                description: item.productdescription,
                price: Number(item.productprice),
                category_name: item.category,
                franchise_id: Number(item.franchise_id),
                barcode: item.barcode,
                internal_code: item.internalcode,
                enabled: item.enabled === 'SI' ? true : false,
                deleted: item.deleted === 'SI' ? true : false,
            };
            productDataArray.push(formattedFile);
        });

        const body = {
            csv: productDataArray,
        };

        try {
            const respuesta = await clienteAxios.put(
                `${uploadBaseURL}/products_csv`,
                body,
            );

            const successMessage = respuesta?.data?.data?.data;
            message.success(
                successMessage || '¡El archivo se subió exitosamente!',
            );
        } catch (error) {
            const parsedError = `Hubo un problema al intentar cargar el archivo: ${
                error?.response?.data?.message || error?.message
            }`;
            message.error(parsedError);
        } finally {
            message.destroy(key);
            dispatch({
                type: PRODUCT_REPORT_LOADING,
            });
        }
        dispatch({
            type: CLOSE_PRODUCT_ACTIONS_MODAL,
        });
    };

    const providerData = {
        getFranchiseList,
        filteredFranchiseList,
        downloadProducts,
        productActionsModalVisible,
        uploadProducts,

        loadingNewProduct: state.loadingNewProduct,
        productList: state.productList,
        franchiseSelectList: state.franchiseSelectList,
        filters: state.filters,
        loading: state.loading,
    };

    return (
        <AdministrarContext.Provider value={providerData}>
            {children}
        </AdministrarContext.Provider>
    );
};

export default AdministrarState;

AdministrarState.propTypes = {
    children: PropTypes.node,
};
