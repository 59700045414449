import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
    Form,
    Row,
    Col,
    Divider,
    Select as SelectAntd,
    message,
    Modal,
} from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import classes from './corteTransaccion.module.css';

import CustomSVG from '../../../../assets/svg/support/CustomSVG';

import axios from '../../../../config/axios';
import {
    formatoGuaranies,
    formatoNumeros,
} from '../../../utils-components/Number';
import { formatoFechaInput } from '../../../utils-components/Date';

import Input from '../../../atoms/Input';
import Select from '../../../atoms/Select';
import Card from '../../../atoms/Card/Card';
import Button from '../../../atoms/Button';
import DatePicker from '../../../atoms/DatePicker';
import TablaCortes from '../../../molecules/Reportes/Cortes/TablaCortes';
import { SearchOutlined, MailOutlined } from '@ant-design/icons';
import { useConsultasCorteTransacccion } from './consultas/useConsultasCorteTransacccion';
import ModalContent from '../../../atoms/ModalContent';
import ABMLayout from '../../../../layouts/ABMLayout';

const totalRegistrosPorPaginaCorte = 15;

// Formulario -- Configuracion -- configuracion del input fecha inicio fecha fin
const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Por favor seleccione un periodo',
        },
    ],
};

const CorteTransaccion = () => {
    const { enviarCorreo } = useConsultasCorteTransacccion();
    // Datos recibidos del backend
    const [cortes, setCortes] = useState(null);
    const [
        cantidadRegistrosCortesTransaccion,
        setCantidadRegistrosCortesTransaccion,
    ] = useState(null);
    const [paramsCorte, setParamsCorte] = useState(null);
    // Formulario
    const [corteForm] = Form.useForm();
    const [cortesForm, setCortesForm] = useState({});
    const [valoresInicialesForm, setValoresInicialesForm] = useState(null);
    const [obteniendoDatosForm, setObteniendoDatosForm] = useState(false);
    const [isModalEnviarCorreoVisible, setIsModalEnviarCorreoVisible] =
        useState(false);
    const [corteSeleccionado, setCorteSeleccionado] = useState(null);
    const mixpanel = useMixpanel();

    // Formulario -- Configuracion

    //Formulario -- Select  de Franquicias

    const [franquicias, setFranquicias] = useState();

    const obtenerFranquicias = async () => {
        let arrFranquicias = [{ franchise_id: '', name: 'Todas' }];

        try {
            setObteniendoDatosForm(true);

            const respuesta = await axios.get('/franchises');

            respuesta.data.data.franchises.map((item) =>
                arrFranquicias.push(item),
            );
        } catch (error) {
            message.error(error.response.data.message);
        }

        setObteniendoDatosForm(false);

        setFranquicias(arrFranquicias);
    };

    useEffect(() => {
        obtenerFranquicias();

        let tempValoresInicialesForm = {
            // payment_type: tipoPagoOpciones[0].valor,
            date: [
                moment(moment().format(formatoFechaInput), formatoFechaInput),
                moment(moment().format(formatoFechaInput), formatoFechaInput),
            ],
            ruc_or_franchise: '',
        };

        try {
            if (window.history.state?.paramsCorte) {
                // Establecer parametros de busqueda
                const historyState = window.history.state.paramsCorte;
                setParamsCorte({ ...historyState });

                // Establecer valores iniciales del form
                if (historyState?.date_start && historyState?.date_end)
                    tempValoresInicialesForm['date'] = [
                        moment(
                            moment(historyState.date_start, 'YYYY-MM-DD'),
                            formatoFechaInput,
                        ),
                        moment(
                            moment(historyState.date_end, 'YYYY-MM-DD'),
                            formatoFechaInput,
                        ),
                    ];
                if (historyState?.ruc_or_franchise)
                    tempValoresInicialesForm['ruc_or_franchise'] =
                        historyState.ruc_or_franchise;
                if (historyState?.nroTransaccion)
                    tempValoresInicialesForm['nroTransaccion'] =
                        historyState.nroTransaccion;
            }
        } catch (error) {
            // continue regardless of error
        }

        setValoresInicialesForm({ ...tempValoresInicialesForm });
    }, []);

    const onChange = (e) => {
        setCortesForm({
            ...cortesForm,
            [e.target.name]: e.target.value,
        });
    };

    // Tablas de datos
    // Tablas de datos -- Corte Transaccion -- Datos formateados
    const [obteniendoDatos, setObteniendoDatos] = useState(false);
    const [tablaCortesData, setTablaCortesData] = useState();

    // Tarjetas
    // Tarjetas -- datos para las tarjetas en la cabecera
    const [tarjetasCabecera, setTarjetasCabecera] = useState({
        cantidadVentas: 0,
        totalVentas: formatoGuaranies(0),
        totalPagoOnline: formatoGuaranies(0),
        totalEfectivo: formatoGuaranies(0),
    });
    const [tarjetasCabeceraData, setTarjetasCabeceraData] = useState();

    // boton ver resumen
    const verDetallesButtonHandler = () => {
        corteForm.validateFields().then(async (values) => {
            mixpanel.track('click_on_transactions_cuts_view_details_button', {
                values,
            });
            setTablaCortesData(null);
            setCantidadRegistrosCortesTransaccion(null);
            try {
                let paramsCorteTemp = { page: 1 };
                if (values?.ruc_or_franchise)
                    paramsCorteTemp['ruc_or_franchise'] =
                        values.ruc_or_franchise;
                if (values?.nroTransaccion)
                    paramsCorteTemp['nroTransaccion'] = values.nroTransaccion;
                if (values?.date)
                    paramsCorteTemp['date_start'] =
                        values.date[0].format('YYYY-MM-DD');
                if (values?.date)
                    paramsCorteTemp['date_end'] =
                        values.date[1].format('YYYY-MM-DD');
                setParamsCorte(paramsCorteTemp);
            } catch (error) {
                message.error(error.response.data.message);
                mixpanel.track('transactions_cuts_view_details_error', {
                    error,
                });
            }
        });
    };

    useEffect(() => {
        if (paramsCorte) {
            obtenerDatosEndpoint(paramsCorte);
        }
    }, [paramsCorte]);

    // Corte Transaccion - Obtener datos
    const obtenerDatosEndpoint = async (params) => {
        setObteniendoDatos(true);
        try {
            const respuesta = await axios.get('/get_balance_cuts', { params });
            if (!cantidadRegistrosCortesTransaccion)
                setCantidadRegistrosCortesTransaccion(
                    respuesta.data.data.total_rows,
                );

            setCortes(respuesta.data.data.balances);
            setTarjetasCabeceraData({ ...respuesta.data.data, balances: null });
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de cortes',
            );
        }
        setObteniendoDatos(false);
    };

    // Cuando los datos del corte estan guardados en el State, hacer el refresh de la tabla y tarjetas
    useEffect(() => {
        if (
            cortes &&
            tarjetasCabeceraData &&
            cantidadRegistrosCortesTransaccion
        ) {
            refrescarDatosTarjetaCabecera();
            refrescarDatosTabla();
        }
    }, [cortes, tarjetasCabeceraData, cantidadRegistrosCortesTransaccion]);

    useEffect(() => {
        refrescarDatosTabla();
    }, [cortes]);

    // Corte Transaccion - Refrescar datos de la tabla
    const refrescarDatosTabla = () => {
        // crear array para almacenar datos para la tabla
        const cortesArr = cortes?.map((item) => {
            // extrar los datos que se necesitaran
            const nroAcreditacion = item.id;
            let ultimoCorte = item.is_last_cut;
            let corteEspecial = item?.special_closing;
            const fechaCreacion = item.created_at || '2018-01-01T00:00:00.000Z';
            const fechaInicio =
                item.start_closing_date || '2018-01-01T00:00:00.000Z';
            const fechaCierre = item.closing_date || '2018-01-01T00:00:00.000Z';
            const sucursal = item.name;
            const transaccionesOnline = item.amount_online_payment;
            const pagosPendientes = item.previous_balance;
            const pagosRealizados = item.payment_amount;
            const montoRetenido = item.withheld_amount;
            const montoTransferido = item.transfer_amount;
            const comision =
                Number(item.commission_marketplace) +
                Number(item.commission_drivers);
            let saldo = Number(item.balance);
            const franchiseSocietyId = Number(item.franchise_society_id);

            // ajustes para la fecha
            const fechaInicioNormalizada = fechaInicio.substring(
                0,
                fechaInicio.indexOf('.'),
            );
            const fechaCierreNormalizada = fechaCierre.substring(
                0,
                fechaCierre.indexOf('.'),
            );

            // Ajuste para tags
            if (ultimoCorte) {
                ultimoCorte = 'Último Corte';
            }
            if (corteEspecial) {
                corteEspecial = 'Corte Especial';
            }

            const fechaCreacionCorta = fechaCreacion
                .substring(0, fechaCreacion.indexOf('T'))
                .split('-')
                .reverse()
                .join('/')
                .toString();
            const fechaInicioCorta = fechaInicio.substring(
                0,
                fechaInicio.indexOf('T'),
            );
            const fechaFinCorta = fechaCierre.substring(
                0,
                fechaCierre.indexOf('T'),
            );

            return {
                key: nroAcreditacion,
                nroAcreditacion: nroAcreditacion,
                ultimoCorte: ultimoCorte,
                corteEspecial: corteEspecial,
                fechaInicio: moment(fechaInicioNormalizada)
                    .format('dddd DD/MM/YYYY')
                    .replace(/^\w/, (c) => c.toUpperCase()),
                fechaCierre: moment(fechaCierreNormalizada)
                    .format('dddd DD/MM/YYYY')
                    .replace(/^\w/, (c) => c.toUpperCase()),
                sucursal: sucursal,
                transaccionesOnline: formatoGuaranies(transaccionesOnline),
                pagosPendientes: formatoGuaranies(pagosPendientes),
                pagosRealizados: formatoGuaranies(pagosRealizados),
                montoRetenido: formatoGuaranies(montoRetenido),
                montoTransferido: formatoGuaranies(montoTransferido),
                comision: formatoGuaranies(comision),
                saldo: saldo,
                paramsBalance: {
                    date_start: fechaInicioCorta,
                    date_end: fechaFinCorta,
                    ruc_or_franchise: franchiseSocietyId,
                    allow_payments: true,
                    resumenCortePDF: {
                        nroCorte: nroAcreditacion,
                        fechaCorte: fechaCreacionCorta,
                        periodoCorte: `${fechaInicioCorta
                            .split('-')
                            .reverse()
                            .join('/')
                            .toString()} al ${fechaFinCorta
                            .split('-')
                            .reverse()
                            .join('/')
                            .toString()}`,
                        amortizaciones: formatoGuaranies(pagosRealizados),
                    },
                },
                paramsDetalle: {
                    dateStart: fechaInicioCorta,
                    dateEnd: fechaFinCorta,
                    franchiseId: franchiseSocietyId,
                    paymentType: '',
                },
                paramsCorte: { ...paramsCorte },
            };
        });

        setTablaCortesData(cortesArr);
    };

    const cambioDePaginaHandler = (page) => {
        setParamsCorte((state) => ({
            ...state,
            page: page,
        }));
    };

    // Corte Transaccion - Refrescar datos de las tarjetas
    const refrescarDatosTarjetaCabecera = () => {
        let cantidadVentas = tarjetasCabeceraData.total_orders;
        let totalVentas = tarjetasCabeceraData.amount_total_orders;

        let totalPagoOnline = tarjetasCabeceraData.amount_online;
        let totalEfectivo = tarjetasCabeceraData.amount_cash;

        setTarjetasCabecera({
            cantidadVentas: formatoNumeros(cantidadVentas),
            totalVentas: formatoGuaranies(totalVentas),
            totalPagoOnline: formatoGuaranies(totalPagoOnline),
            totalEfectivo: formatoGuaranies(totalEfectivo),
        });
    };

    // Enviar correo
    const enviarCorreoButtonHandler = (idCorte) => {
        setIsModalEnviarCorreoVisible(true);
        const idCorteSeleccionado = Number(idCorte) || null;
        setCorteSeleccionado(idCorteSeleccionado);
        mixpanel.track('click_on_transactions_cuts_send_email_button', {
            idCorteSeleccionado,
        });
    };

    const confirmarEnvioCorreoCorreoButtonHandler = async () => {
        const obtenerListaCompleta = !corteSeleccionado;
        let listaIdCortes = [];
        setObteniendoDatos(true);
        if (obtenerListaCompleta) {
            listaIdCortes = await obtenerListaCompletaIdsCortes();
        } else {
            listaIdCortes = [corteSeleccionado];
        }
        mixpanel.track('click_on_transactions_cuts_send_email_confirm_button', {
            listaIdCortes,
        });

        // Enviar correo
        const respuesta = await enviarCorreo(listaIdCortes);
        if (respuesta) {
            setObteniendoDatos(false);
            setIsModalEnviarCorreoVisible(false);
            message.success(
                `${
                    obtenerListaCompleta
                        ? 'Los correos de corte serán enviados'
                        : 'El correo de corte será enviado'
                }`,
            );
            setCorteSeleccionado(null);
        }
    };

    const obtenerListaCompletaIdsCortes = async () => {
        let listaIdsCortes = [];
        try {
            const params = {
                ...paramsCorte,
                items_per_page: cantidadRegistrosCortesTransaccion,
            };
            const respuesta = await axios.get('/get_balance_cuts', { params });
            const listaCortes = respuesta?.data?.data?.balances;
            listaIdsCortes = listaCortes?.map((corte) => corte?.id);
        } catch (error) {
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al obtener la lista de cortes',
            );
        }
        return listaIdsCortes;
    };

    const cancelarEnvioCorreoButtonHandler = () => {
        setIsModalEnviarCorreoVisible(false);
    };

    const deshabilitarBotonEnviarCorreo = Boolean(!tablaCortesData?.length);

    const corteTransaccionChildren = (
        <div className={classes.corteTransaccionChildren}>
            {/* Formulario y Acciones extras */}
            {valoresInicialesForm && (
                <Form
                    name="basic"
                    form={corteForm}
                    layout="vertical"
                    initialValues={valoresInicialesForm}
                    onFinish={verDetallesButtonHandler}>
                    <Row gutter={24}>
                        <Col span={20}>
                            <Row gutter={24}>
                                <Col span={14}>
                                    <Form.Item
                                        name="ruc_or_franchise"
                                        label="Franquicia">
                                        <Select
                                            showSearch
                                            disabled={obteniendoDatos}
                                            loading={obteniendoDatosForm}
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                option.children
                                                    .toLowerCase()
                                                    .indexOf(
                                                        input.toLowerCase(),
                                                    ) >= 0
                                            }>
                                            {franquicias &&
                                                franquicias.map((item) => (
                                                    <SelectAntd.Option
                                                        key={item.franchise_id}
                                                        value={
                                                            item.franchise_id
                                                        }
                                                        onChange={(evt) =>
                                                            onChange(evt)
                                                        }>
                                                        {item.name}
                                                    </SelectAntd.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={7}>
                                    <Form.Item
                                        name="date"
                                        label="Periodo"
                                        {...rangeConfig}>
                                        <DatePicker
                                            format={formatoFechaInput}
                                            disabled={obteniendoDatos}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col span={7}>
                                    <Form.Item
                                        name="nroTransaccion"
                                        label="Nro. de transacción">
                                        <Input
                                            disabled={obteniendoDatos}
                                            rules={[
                                                {
                                                    type: 'number',
                                                    message:
                                                        'Ingrese un numero válido',
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={4} style={{ marginTop: 'auto' }}>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    block={true}
                                    icon={<MailOutlined />}
                                    onClick={enviarCorreoButtonHandler}
                                    disabled={
                                        deshabilitarBotonEnviarCorreo ||
                                        obteniendoDatos
                                    }>
                                    Enviar correo
                                </Button>
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    block={true}
                                    icon={<SearchOutlined />}
                                    disabled={obteniendoDatos}>
                                    Ver Detalles
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            )}

            <Divider />

            {/* Tarjetas */}
            <Row gutter={24}>
                <Col span={4}>
                    <Card
                        icon={<CustomSVG name="money" />}
                        cargando={obteniendoDatos}
                        backgroundcolor="var(--amarillo)">
                        <h2>{tarjetasCabecera.cantidadVentas}</h2>
                        <small>Ventas</small>
                        <small>
                            <strong>{tarjetasCabecera.totalVentas}</strong>
                        </small>
                    </Card>
                </Col>
                <Col span={4}>
                    <Card
                        icon={<CustomSVG name="money" />}
                        cargando={obteniendoDatos}
                        backgroundcolor="var(--rosa)">
                        <small>Online</small>
                        <small style={{ marginBottom: '10px' }}>
                            <strong>{tarjetasCabecera.totalPagoOnline}</strong>
                        </small>
                        <small>Efectivo</small>
                        <small>
                            <strong>{tarjetasCabecera.totalEfectivo}</strong>
                        </small>
                    </Card>
                </Col>
            </Row>

            <Divider style={{ border: 'none', margin: '1.2rem 0' }} />

            <TablaCortes
                className="tabla-finanzas"
                loading={obteniendoDatos}
                scroll={{ x: 2100 }}
                pagination={{
                    pageSize: totalRegistrosPorPaginaCorte,
                    total: cantidadRegistrosCortesTransaccion,
                    current: paramsCorte?.page ? paramsCorte.page : 1,
                    showSizeChanger: false,
                    onChange: (page) => cambioDePaginaHandler(page),
                }}
                dataSource={tablaCortesData}
                enviarCorreoButtonHandler={enviarCorreoButtonHandler}
            />
        </div>
    );

    return (
        <>
            <ABMLayout titulo="Corte de transacción">
                {corteTransaccionChildren}
            </ABMLayout>
            <Modal
                open={isModalEnviarCorreoVisible}
                footer={null}
                closable={null}>
                <ModalContent
                    title={`Confirmar el envío por correo de ${
                        corteSeleccionado ? 'este corte' : 'todos los cortes'
                    }?`}
                    okText="Confirmar"
                    cancelText="Cancelar"
                    onOk={() => confirmarEnvioCorreoCorreoButtonHandler()}
                    onCancel={() => cancelarEnvioCorreoButtonHandler()}
                    isLoading={obteniendoDatos}
                />
            </Modal>
        </>
    );
};

export default CorteTransaccion;
