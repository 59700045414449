import React from 'react';
import 'antd/dist/antd.css';
import { Col, Button } from 'antd';
import PropTypes from 'prop-types';

import classes from './Acciones1.module.css';

const VerOrdenButton = ({ permitirVerOrden, verOrdenButtonHandler }) => (
    <>
        {permitirVerOrden ? (
            <Col>
                <Button
                    type="button"
                    onClick={verOrdenButtonHandler}
                    className={classes.ButtonVerPedido}>
                    VER PEDIDO
                </Button>
            </Col>
        ) : (
            <div className={classes.emptySpaceAcciones} />
        )}
    </>
);

VerOrdenButton.propTypes = {
    permitirVerOrden: PropTypes.bool,
    verOrdenButtonHandler: PropTypes.func,
};

export default VerOrdenButton;
