import React, { useState, useRef, useCallback, useEffect } from 'react';
import { LoadScript, GoogleMap, Polygon } from '@react-google-maps/api';
import { DeleteOutlined } from '@ant-design/icons';
import { computeOffset } from 'spherical-geometry-js';
import { Button, Tooltip } from 'antd';
import { urls } from '../../constants';
import PropTypes from 'prop-types';

// Ciertas opciones, configuraciones, etc. del mapa se definen fuera del componente
// para evitar establecerlas nuevamente en cada "render" del componente
// Ref.: https://react-google-maps-api-docs.netlify.app/

// Google Map - API Key para el mapa
const googleMapApiKey = urls.REACT_APP_GOOGLE_MAP_API_KEY;

// Google Map - Librerias del mapa
const libreries = ['drawing', 'geometry'];

// Google Map - Asuncion - Paraguay
const center = {
    lat: -25.2922909,
    lng: -57.5588664,
};

// Google Map - Opciones del mapa
// Ref.: https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions.styles
const googleMapOptions = {
    mapTypeId: 'roadmap',
    disableDefaultUI: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false,
    styles: [
        {
            stylers: [{ visibility: 'off' }],
            featureType: 'poi',
        },
    ],
};

// Google Map - Estilo CSS del mapa
const containerStyle = {
    width: '100%',
    height: '300px',
    borderRadius: '8px',
};

// Conjunto de "selects" para Ciudad y Barrio dentro del "collapse" de "Push Zonas"
// * "fields" contienen los datos del "formulario", se utiliza para detectar su contenido
//    "null", lo que significa que se presiono el boton de "restablecer" y ejecutar las
//    instrucciones de limpieza del componente.

const MapaPolygon = ({ puntosIniciales, guardarPuntos }) => {
    // Guardar state de los "paths" del poligono
    const [path, setPath] = useState(puntosIniciales);

    // Define refs for Polygon instance and listeners
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    // Al hacer clic en el mapa, agregar un poligono
    // Ref.: https://github.com/NotWoods/spherical-geometry-js
    const onAdd = (event) => {
        if (path.length === 0) {
            const punto = event.latLng;
            const linea1 = computeOffset(punto, 550, 0);
            const linea2 = computeOffset(punto, 550, 120);
            const linea3 = computeOffset(punto, 550, -120);
            const poligonoInicial = [linea1, linea2, linea3];
            const coordenadas = poligonoInicial.map((item) => ({
                lat: item.latitude,
                lng: item.longitude,
            }));
            setPath(coordenadas);
        }
    };

    // Al remover poligono del mapa
    const onRemove = () => {
        setPath([]);
    };

    // Al editar, llamar a setPath con las coordenadas editadas
    const onEdit = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map((latLng) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPath(nextPath);
        }
    }, [setPath]);

    // Al editar, actualizar Form Input con datos del poligono
    useEffect(() => {
        guardarPuntos(path);
    }, [path]);

    // Conectar refs y listeners al poligono actual
    const onLoad = useCallback(
        (polygon) => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path.addListener('set_at', onEdit),
                path.addListener('insert_at', onEdit),
                path.addListener('remove_at', onEdit),
            );
        },
        [onEdit],
    );

    // Limpiar refs
    const onUnmount = useCallback(() => {
        listenersRef.current.forEach((lis) => lis.remove());
        polygonRef.current = null;
    }, []);

    return (
        <>
            {/*
             * Mapa para seleccion de zona (poligono)
             */}
            <LoadScript
                googleMapsApiKey={googleMapApiKey}
                libraries={libreries}
                language="es"
                region="py">
                <GoogleMap
                    mapContainerStyle={containerStyle}
                    onClick={onAdd}
                    center={center}
                    zoom={12}
                    version="weekly"
                    options={googleMapOptions}
                    clickableIcons={false}>
                    <Tooltip placement="left" title="Remover zona">
                        <Button
                            onClick={onRemove}
                            disabled={path.length === 0}
                            style={{
                                position: 'absolute',
                                bottom: 110,
                                right: 10,
                                width: 40,
                                height: 40,
                                padding: 0,
                            }}>
                            <DeleteOutlined />
                        </Button>
                    </Tooltip>

                    <Polygon
                        // Hacer el poligono editable y movible
                        editable
                        draggable
                        path={path}
                        // Evento para capturar nuevos puntos/etc del poligono
                        onMouseUp={onEdit}
                        // Evento para capturar movimiento del poligono
                        onDragEnd={onEdit}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                    />
                </GoogleMap>
            </LoadScript>
        </>
    );
};

export default MapaPolygon;

MapaPolygon.propTypes = {
    puntosIniciales: PropTypes.any,
    guardarPuntos: PropTypes.func,
};
