import {
    SUPPORT_ABRIR_MODAL_ESTADO_JEDI,
    SUPPORT_CERRAR_MODAL_ESTADO_JEDI,
    SUPPORT_OBTENER_DETALLES_ESTADO_JEDI,
} from './Types.js';

export const initialState = {
    modalEstadoJediVisible: false,
    listaTableEstadoJedi: [],
    datosModalVerEstadoJedi: [],
    loadingIntegraciones: false,
};

const SupportReducer = (state = initialState, action) => {
    switch (action.type) {
        //INTEGRACIONES

        case SUPPORT_OBTENER_DETALLES_ESTADO_JEDI: {
            return {
                ...state,
                listaTableEstadoJedi: action.payload.listaTableEstadoJedi,
                datosModalVerEstadoJedi: action.payload.datosModalVerEstadoJedi,
                loadingIntegraciones: false,
            };
        }
        case SUPPORT_ABRIR_MODAL_ESTADO_JEDI: {
            return {
                ...state,
                modalEstadoJediVisible: true,
            };
        }
        case SUPPORT_CERRAR_MODAL_ESTADO_JEDI: {
            return {
                ...state,
                modalEstadoJediVisible: false,
            };
        }
        default:
            return state;
    }
};

export default SupportReducer;
