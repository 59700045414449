import React, { useEffect, useState, useCallback } from 'react';
import {
    Form,
    Col,
    Row,
    Select,
    message,
    Card,
    Button,
    List,
    Radio,
    Typography,
} from 'antd';
import axios from '../../../config/axios';
import Search from 'antd/lib/input/Search';
import {
    AndroidOutlined,
    AppleOutlined,
    CheckCircleOutlined,
    InfoCircleOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import classes from './PushMolecules.module.css';

const { Text } = Typography;

const PushAudiencia = ({
    pushForm,
    fields,
    setCantidadUsuariosTotal,
    setCantidadUsuariosSeleccionados,
    cantidadUsuariosSeleccionados,
}) => {
    // Administrar las vistas de Audiencia (Lista usuarios | Subir Archivo)
    const [isSubirArchivo, setIsSubirArchivo] = useState(false);

    const onPressSubirArchivo = () => {
        setCantidadUsuariosSeleccionados(undefined);
        setIsSubirArchivo(!isSubirArchivo);
        pushForm.setFieldsValue({
            usuarios: undefined,
        });
    };

    // Lista usuarios
    const [resultadoBusquedaUsuarios, setResultadoBusquedaUsuarios] =
        useState(); // datos de usuarios
    const [busqueda, setBusqueda] = useState(false); // marca que se ha realizado una busqueda
    const [usuariosSeleccionados, setUsuariosSeleccionados] = useState(); // lista de usuarios seleccionados
    const [tipoBusqueda, setTipoBusqueda] = useState('id');
    const [valueBusqueda, setValueBusqueda] = useState();
    const [loading, setLoading] = useState(false);

    const tipoBusquedaHandler = (value) => {
        setTipoBusqueda(value);
    };

    // llamada al endpoint usuarios
    const getUsers = async (tipo, value) => {
        setLoading(true);

        // Obtener el valor a ser buscado
        let valorBusqueda = value;

        // Para tipo "ID" verificar que sea numero
        if (tipo === 'id' && isNaN(valueBusqueda)) {
            valorBusqueda = undefined;
        }

        // Se acepta la opcion de ("" vacio), por lo que se pregunta explicitamente por undefinded
        if (valorBusqueda !== undefined) {
            // Preparar parametros
            let params = {};
            params['user_id'] = tipo === 'id' ? valorBusqueda : undefined;
            params['full_name'] = tipo === 'nombre' ? valorBusqueda : undefined;
            params['email'] = tipo === 'correo' ? valorBusqueda : undefined;

            // Intentar obtener datos del backend
            try {
                // obtener datos
                const response = await axios.get('/users_list', {
                    params: params,
                });

                // extraer lista de usuarios
                const users = response?.data?.data?.users;
                setResultadoBusquedaUsuarios(users);

                // extraer cantidad total de usuarios
                const totalUsers = response?.data?.data?.total_users;
                if (!busqueda) {
                    setCantidadUsuariosTotal(totalUsers);
                }

                // marcar que se ha realizado la busqueda
                setBusqueda(true);
            } catch (error) {
                message.error(
                    error ||
                        'Hubo un problema al obtener los datos de los usuarios',
                );
            }
        } else {
            setResultadoBusquedaUsuarios(undefined);
        }

        setLoading(false);
    };

    const agregarUsuarioHandler = (usuario) => {
        // obtener usuario
        let tempUsuariosSeleccionados = usuariosSeleccionados
            ? usuariosSeleccionados.map((item) => ({ ...item }))
            : [];
        tempUsuariosSeleccionados.push(usuario);

        // guardar en el state nuevo usuario
        setUsuariosSeleccionados(tempUsuariosSeleccionados);
    };

    const removerUsuarioHandler = (usuario) => {
        if (usuariosSeleccionados && usuariosSeleccionados.length > 0) {
            // remover usuario seleccionado
            let tempUsuariosSeleccionados = [];
            usuariosSeleccionados.forEach((item) => {
                if (item.user_id !== usuario.user_id) {
                    tempUsuariosSeleccionados.push({ ...item });
                }
            });

            // guardar nueva lista de usuarios seleccionados
            setUsuariosSeleccionados(tempUsuariosSeleccionados);

            // agregar a lista de resultado busqueda
            if (
                resultadoBusquedaUsuarios &&
                resultadoBusquedaUsuarios.length > 0
            ) {
                let tempUsuariosBusqueda = resultadoBusquedaUsuarios.map(
                    (item) => ({ ...item }),
                );
                tempUsuariosBusqueda.push(usuario);
                setResultadoBusquedaUsuarios(tempUsuariosBusqueda);
            } else {
                setResultadoBusquedaUsuarios([{ ...usuario }]);
            }
        }
    };

    // remover usuarios seleccionados de la lista de resultados
    const removerUsuarioSeleccionadoBusqueda = () => {
        let tempUsuariosBusquedaFiltrados = [];

        resultadoBusquedaUsuarios.forEach((item) => {
            const encontrado = usuariosSeleccionados.find(
                (element) => element.user_id === item.user_id,
            );
            if (encontrado === undefined) {
                tempUsuariosBusquedaFiltrados.push({ ...item });
            }
        });

        setResultadoBusquedaUsuarios(tempUsuariosBusquedaFiltrados);
    };

    // realizar la busqueda de usuarios luego de 1 segundo
    useEffect(() => {
        const delaySearch = setTimeout(() => {
            if (valueBusqueda) {
                getUsers(tipoBusqueda, valueBusqueda);
            } else {
                getUsers('nombre', '');
            }
        }, 1000);
        return () => clearTimeout(delaySearch);
    }, [valueBusqueda, tipoBusqueda]);

    // filtrar cuando se agregan usuarios y actualizar cantidad de usuarios seleccionados
    // actualizar usuarios seleccionados en el formulario
    useEffect(() => {
        if (usuariosSeleccionados && resultadoBusquedaUsuarios) {
            removerUsuarioSeleccionadoBusqueda();
            setCantidadUsuariosSeleccionados(usuariosSeleccionados.length);
        }
        pushForm.setFieldsValue({
            usuarios: usuariosSeleccionados
                ? usuariosSeleccionados.map((item) => item.user_id)
                : undefined,
        });
    }, [usuariosSeleccionados]);

    // filtrar cuando se realizan busquedas
    useEffect(() => {
        if (usuariosSeleccionados && resultadoBusquedaUsuarios && busqueda) {
            removerUsuarioSeleccionadoBusqueda();
        }
    }, [busqueda]);

    // al iniciar el componente, poner la primera lista de usuarios
    useEffect(() => {
        getUsers('nombre', '');
    }, []);

    // al restablecer
    useEffect(() => {
        if (fields === null) {
            // Valores de la busqueda de usuarios
            setResultadoBusquedaUsuarios(undefined);
            setUsuariosSeleccionados(undefined);
            setBusqueda(false);
            setTipoBusqueda('id');
            setValueBusqueda('');

            // Obtener lista de usuarios
            getUsers('nombre', '');

            // Restablecer Alcance
            setCantidadUsuariosSeleccionados(undefined);
        }
    }, [fields]);

    // Subir Archivo
    const [errorArchivo, setErrorArchivo] = useState(null); // Muestra mensaje de error en vista de "subir archivo"

    // Callback para obtener archivos
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            // Archivo en memoria
            const reader = new FileReader();

            reader.onload = () => {
                extraerUsuariosArchivo(reader);
            };
            reader.readAsText(file);
        });
    }, []);

    const { getRootProps, getInputProps } = useDropzone({ onDrop }); // State del componente de DROP FILES

    const extraerUsuariosArchivo = (reader) => {
        // Datos dentro del archivo
        const binaryStr = reader.result;

        // Se separa cada linea del archivo
        const linea = binaryStr.split('\n');

        // Extraer los datos y ponerlos en un objeto
        let arrLineas = [];
        for (let i = 0; i < linea.length; i++) {
            const currentLine = linea[i].split(',');
            const columna1 = currentLine[0].replaceAll(`"`, '');
            arrLineas.push(columna1);
        }

        // Extraer y validar que los datos sean correctos
        let arrIdUsuarios = [];
        let tieneError = false;
        arrLineas.forEach((element) => {
            if (!isNaN(element)) {
                const id = Number(element);
                if (id) {
                    arrIdUsuarios.push(id);
                }
            } else {
                tieneError = true;
            }
        });

        // Remover duplicados
        arrIdUsuarios = [...new Set(arrIdUsuarios)];

        // Extraer datos relevantes de la lista de usuarios detectados
        let cantidadUsuarios = arrIdUsuarios.length;
        let formItemUsuarios = arrIdUsuarios;

        // Puede suceder que hayan lineas con datos incorrectos, si existieron pueden ser ignorados
        tieneError = cantidadUsuarios < 1;

        // Guardar datos en el "input" del formulario
        pushForm.setFieldsValue({
            usuarios: !tieneError ? formItemUsuarios : undefined,
        });

        // Actualizar datos en el state
        setCantidadUsuariosSeleccionados(cantidadUsuarios);
        setErrorArchivo(tieneError);
    };

    const contenedorSubirArchivo = (
        <div {...getRootProps()} style={styles.contenedorSubirArchivo}>
            <input {...getInputProps()} style={styles.inputSubirArchivo} />

            {errorArchivo ? (
                <Text className={classes.fileMessage}>
                    <WarningOutlined style={styles.warningIcon} /> El archivo
                    contiene errores
                </Text>
            ) : (
                <>
                    {cantidadUsuariosSeleccionados ? (
                        <Text className={classes.fileMessage}>
                            <CheckCircleOutlined
                                className={classes.checkIcon}
                            />
                            Encontramos
                            <Text strong>
                                {cantidadUsuariosSeleccionados} posibles
                            </Text>{' '}
                            usuarios
                        </Text>
                    ) : null}
                </>
            )}
            <Text className={classes.infoText}>
                <InfoCircleOutlined className={classes.infoIcon} /> Cambie de
                archivo haciendo clic en &quot;Seleccionar archivos&quot;
            </Text>
        </div>
    );

    const contenedorListaUsuarios = (
        <>
            {/* formulario de busqueda */}
            <Row gutter="24" className={classes.contenedorBuscadorPersonas}>
                <Col span={8}>
                    <Form.Item label="Buscar por">
                        <Select
                            className={classes.fullWidth}
                            onChange={tipoBusquedaHandler}
                            value={tipoBusqueda}>
                            <Select.Option value="id">ID</Select.Option>
                            <Select.Option value="nombre">
                                Nombre y apellido
                            </Select.Option>
                            <Select.Option value="correo">Correo</Select.Option>
                        </Select>
                    </Form.Item>
                </Col>
                <Col span={16}>
                    <Form.Item label="Agregar una persona">
                        <Search
                            name="buscarPersona"
                            placeholder="Buscar a una persona"
                            onChange={(event) =>
                                setValueBusqueda(event.target.value)
                            }
                            value={valueBusqueda}
                            className={classes.fullWidth}
                        />
                    </Form.Item>
                </Col>
            </Row>
            {/* lista de resultados de la búsqueda */}
            <div className={classes.searchResults}>
                <List
                    itemLayout="horizontal"
                    dataSource={resultadoBusquedaUsuarios}
                    size="small"
                    loading={loading}
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    key={item.user_id}
                                    type="link"
                                    onClick={() => agregarUsuarioHandler(item)}>
                                    Agregar
                                </Button>,
                            ]}>
                            <List.Item.Meta
                                title={`${item.user_name} #${item.user_id}`}
                                description={
                                    <>
                                        {item.device_type === 'ANDROID' ? (
                                            <AndroidOutlined
                                                className={classes.listIcons}
                                            />
                                        ) : (
                                            <AppleOutlined
                                                className={classes.listIcons}
                                            />
                                        )}
                                        {item.email}
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </div>
            {/* lista de personas seleccionadas */}
            <div className={classes.usersSelectedText}>
                <Text>Personas agregadas</Text>
            </div>
            <div className={classes.usersSelectedList}>
                <List
                    itemLayout="horizontal"
                    dataSource={usuariosSeleccionados}
                    size="small"
                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <Button
                                    key={item.user_id}
                                    type="link"
                                    className={classes.removeButton}
                                    onClick={() => removerUsuarioHandler(item)}>
                                    Remover
                                </Button>,
                            ]}>
                            <List.Item.Meta
                                title={`${item.user_name} #${item.user_id}`}
                                description={
                                    <span className={classes.listText}>
                                        {item.device_type === 'ANDROID' ? (
                                            <AndroidOutlined
                                                className={classes.listIcons}
                                            />
                                        ) : (
                                            <AppleOutlined
                                                className={classes.listIcons}
                                            />
                                        )}{' '}
                                        {item.email}
                                    </span>
                                }
                            />
                        </List.Item>
                    )}></List>
            </div>
        </>
    );

    return (
        <Card
            title="Audiencia"
            extra={
                <Radio.Group
                    onChange={onPressSubirArchivo}
                    defaultValue="seleccionar">
                    <Radio.Button value="seleccionar">
                        Seleccionar usuarios
                    </Radio.Button>
                    <Radio.Button value="subir">Subir archivo</Radio.Button>
                </Radio.Group>
            }>
            {/* Subir archivo o Seleccionar usuarios manualmente */}
            {isSubirArchivo ? contenedorSubirArchivo : contenedorListaUsuarios}
        </Card>
    );
};

PushAudiencia.propTypes = {
    pushForm: PropTypes.object,
    fields: PropTypes.array,
    setCantidadUsuariosTotal: PropTypes.func,
    setCantidadUsuariosSeleccionados: PropTypes.func,
    cantidadUsuariosSeleccionados: PropTypes.number,
};

export default PushAudiencia;

const styles = {
    contenedorSubirArchivo: {
        border: '1px dashed',
        borderRadius: '8px',
        borderColor: 'var(--dark-3)',
        height: '10rem',
    },
    contenedorBuscadorPersonas: { marginBottom: 12 },
    inputSubirArchivo: {
        display: 'flex',
        width: '100%',
        padding: '20px 20px 10px 20px',
    },
};
