import React, { useContext, useEffect } from 'react';
import { Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { Content } from 'antd/lib/layout/layout';
import { useMixpanel } from 'react-mixpanel-browser';
import ProtectedComponent from '../hoc/ProtectedComponent';
import AuthContext from '../context/autenticacion/authContext';
import { Permisos } from '../constants/Permisos';
import { NavBar } from '../components/molecules/NavBar/NavBar';

import Dashboard from '../components/pages/Dashboard';
import Finanzas from '../components/pages/Finanzas';
import Reportes from '../components/pages/Reportes';

// Push
import Push from '../components/pages/Push/Push';
import ListaPush from '../components/pages/Push/ListaPush';

// Usuarios
import ListaUsuarios from '../components/pages/pagoOnline/ListaUsuarios';
import Usuarios from '../components/pages/Usuarios';

import ProgramaRecompensa from '../components/pages/ProgramaRecompensa';
import Support from '../components/pages/Support';
import Administrar from '../components/pages/Administrar';
import AppLayout from '../layouts/AppLayout';

const DashboardRouter = (props) => {
    const { token, verificarAutenticacion, redirectPath, setRedirectPath } =
        useContext(AuthContext);
    const location = useLocation();
    const mixpanel = useMixpanel();

    useEffect(() => {
        if (!token) {
            verificarAutenticacion();
            // Capturar path al hacer F5 o recargar la pagina, y mantener al usuario en la misma
            if (Object.prototype.hasOwnProperty.call(props, 'location')) {
                let path = props?.location?.pathname;
                setRedirectPath(path);
            }
        }
    }, []);

    //Event log: se trackean las rutas en todo el admin
    useEffect(() => {
        if (location?.pathname) {
            const moduleName = location?.pathname.split('/')[1];
            mixpanel.track('screen', {
                route: location?.pathname,
                moduleName: moduleName,
            });
        }
    }, [location]);

    let rutas = <Redirect to="/login" />;

    if (token) {
        rutas = (
            <Switch>
                <Route
                    path={'/panel'}
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.panel}
                            componente={Dashboard}
                        />
                    )}
                />

                <Route path="/finanzas" component={Finanzas} />
                <Route path="/reportes" component={Reportes} />

                <Route
                    exact
                    path="/push/enviar-push"
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.push.enviarPush}
                            componente={Push}
                        />
                    )}
                />
                <Route
                    exact
                    path="/push/lista-push"
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.push.listaPush}
                            componente={ListaPush}
                        />
                    )}
                />

                <Route
                    path="/programa-recompensa"
                    component={ProgramaRecompensa}
                />

                <Route
                    exact
                    path="/pago-online/tarjetas/lista-usuarios"
                    render={() => (
                        <ProtectedComponent
                            permiso={Permisos.pagoOnline.tarjetas.listaUsuarios}
                            componente={ListaUsuarios}
                        />
                    )}
                />

                <Route path="/usuarios" component={Usuarios} />
                <Route path="/support" component={Support} />
                <Route path="/administrar" component={Administrar} />

                <Redirect to="/panel" />
            </Switch>
        );
    }

    return (
        <AppLayout>
            {token && <NavBar />}
            <Content style={{ margin: '20px' }}>{rutas}</Content>
            {token && redirectPath !== '/' ? (
                <Redirect to={redirectPath} />
            ) : null}
        </AppLayout>
    );
};
export default DashboardRouter;
