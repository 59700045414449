import React, { useState, useEffect } from 'react';
import {
    Button,
    Card,
    Row,
    Col,
    Space,
    Form,
    Input,
    Select,
    message,
    Spin,
    Switch,
    Divider,
} from 'antd';
import PropTypes from 'prop-types';
import ABMLayout from '../../../../../layouts/ABMLayout';
import axios from '../../../../../config/axios';
import {
    money,
    document_types,
    countries,
    payment_method,
    entity_code,
} from '../../../../../constants';
import { useHistory } from 'react-router-dom';
import { InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';

const validateMessages = {
    required: '${label} es un campo requerido.',
    types: {
        email: '${label} is not validate email!',
    },
};

const formFields = [
    'name',
    'document_type',
    'document_number',
    'dv',
    'country',
    'invoice_name',
    'email',
    'phone',
    'address',
    'payment_method',
    'default',
    'money',
    'account_number',
    'entity_code',
    'franchise_society_id',
];

const FranquiciasForm = ({ mode, initialValues }) => {
    const [procesandoForm, setProcesandoForm] = useState(false);
    const [listaFranquicias, setListaFranquicias] = useState(false);
    const [opcionAgregada, setOpcionAgregada] = useState();
    const [moneyOptions, setMoneyOptions] = useState(money);
    const [documentTypesOptions, setDocumentTypesOptions] =
        useState(document_types);
    const [countriesOptions, setCountriesOptions] = useState(countries);
    const [paymentMethodOptions, setPaymentMethodOptions] =
        useState(payment_method);
    const [entityCodeOptions, setEntityCodeOptions] = useState(entity_code);
    const [catastrosForm] = Form.useForm();
    const history = useHistory();

    useEffect(() => {
        obtenerFranquicias();
    }, []);

    useEffect(() => {
        switch (mode) {
            case 'ADD':
                // Continuar sin cambios
                catastrosForm.setFieldsValue({
                    default: true,
                });
                break;
            case 'EDIT':
                catastrosForm.setFieldsValue({
                    name: initialValues?.name,
                    document_type: initialValues?.document_type,
                    document_number: initialValues?.document_number,
                    dv: initialValues?.dv,
                    country: initialValues?.country,
                    invoice_name: initialValues?.invoice_name,
                    email: initialValues?.email,
                    phone: initialValues?.phone,
                    address: initialValues?.address,
                    payment_method: initialValues?.payment_method,
                    default: initialValues?.default,
                    money: initialValues?.money,
                    account_number: initialValues?.account_number,
                    entity_code: initialValues?.entity_code,
                    franchise_society_id: initialValues?.franchise_society_id,
                });
                break;

            default:
                break;
        }
    }, [listaFranquicias, mode, initialValues]);

    const obtenerFranquicias = async () => {
        setProcesandoForm(true);
        let arrFranquicias = [];

        try {
            const respuesta = await axios.get('/franchises_list');

            arrFranquicias = respuesta.data.data.franchises.map((item) => ({
                ...item,
                key: item.id,
            }));
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }

        setListaFranquicias(arrFranquicias);
        setProcesandoForm(false);
    };

    const guardarButtonHandler = () => {
        catastrosForm
            .validateFields(formFields)
            .then((values) => {
                switch (mode) {
                    case 'ADD':
                        nuevaFranquicia(values);

                        break;
                    case 'EDIT':
                        editarCatastro(values);

                        break;

                    default:
                        break;
                }
            })
            .catch(() =>
                message.warning('Por favor complete los campos obligatorios.'),
            );
    };

    const cancelarButtonHandler = () => {
        history.goBack();
    };

    const nuevaFranquicia = async (franchise) => {
        setProcesandoForm(true);

        try {
            const body = {
                ...franchise,
            };

            const respuesta = await axios.post('create_cadastre', body);

            message.success(respuesta.data.data.data);
            history.goBack();
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }

        setProcesandoForm(false);
    };

    const editarCatastro = async (franchise) => {
        setProcesandoForm(true);

        try {
            const body = {
                ...franchise,
                id: initialValues?.id,
            };
            const respuesta = await axios.post('update_cadastre', body);

            message.success(respuesta.data.data.data);
            history.goBack();
        } catch (error) {
            message.error(error?.response?.data?.message || error);
        }

        setProcesandoForm(false);
    };

    const agregarOpcionHandler = (setState) => {
        if (opcionAgregada) {
            setState((state) => [...state, opcionAgregada]);
            setOpcionAgregada(undefined);
        }
    };

    return (
        <ABMLayout
            titulo={mode === 'ADD' ? 'Nuevo catastro' : 'Editar catastro'}
            acciones={
                <Space>
                    <Button onClick={cancelarButtonHandler}>Cancelar</Button>
                    <Button type="primary" onClick={guardarButtonHandler}>
                        Guardar
                    </Button>
                </Space>
            }>
            <Spin spinning={procesandoForm}>
                <Form
                    name="catastroForm"
                    form={catastrosForm}
                    layout="vertical"
                    validateMessages={validateMessages}>
                    <Row gutter={[12, 12]}>
                        <Col span={12}>
                            <Card title="Cuenta">
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Método de pago"
                                            name="payment_method"
                                            rules={[{ required: true }]}>
                                            <Select
                                                placeholder="-- Seleccione o agregue una opción --"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase(),
                                                        ) >= 0
                                                }
                                                dropdownRender={(menu) => (
                                                    <div>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: '4px 0',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap',
                                                                padding: 8,
                                                            }}>
                                                            <Input
                                                                style={{
                                                                    flex: 'auto',
                                                                }}
                                                                placeholder="Agregue una opción nueva"
                                                                value={
                                                                    opcionAgregada
                                                                }
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setOpcionAgregada(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                style={{
                                                                    flex: 'none',
                                                                    marginLeft:
                                                                        '8px',
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    agregarOpcionHandler(
                                                                        setPaymentMethodOptions,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}>
                                                {paymentMethodOptions.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Moneda"
                                            name="money"
                                            rules={[{ required: true }]}>
                                            <Select
                                                placeholder="-- Seleccione o agregue una opción --"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase(),
                                                        ) >= 0
                                                }
                                                dropdownRender={(menu) => (
                                                    <div>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: '4px 0',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap',
                                                                padding: 8,
                                                            }}>
                                                            <Input
                                                                style={{
                                                                    flex: 'auto',
                                                                }}
                                                                placeholder="Agregue una opción nueva"
                                                                value={
                                                                    opcionAgregada
                                                                }
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setOpcionAgregada(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                style={{
                                                                    flex: 'none',
                                                                    marginLeft:
                                                                        '8px',
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    agregarOpcionHandler(
                                                                        setMoneyOptions,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}>
                                                {moneyOptions.map((item) => (
                                                    <Select.Option key={item}>
                                                        {item}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Nro. Cuenta o Tarjeta"
                                            name="account_number"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="País"
                                            name="country"
                                            rules={[{ required: true }]}>
                                            <Select
                                                placeholder="-- Seleccione o agregue una opción --"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase(),
                                                        ) >= 0
                                                }
                                                dropdownRender={(menu) => (
                                                    <div>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: '4px 0',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap',
                                                                padding: 8,
                                                            }}>
                                                            <Input
                                                                style={{
                                                                    flex: 'auto',
                                                                }}
                                                                placeholder="Agregue una opción nueva"
                                                                value={
                                                                    opcionAgregada
                                                                }
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setOpcionAgregada(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                style={{
                                                                    flex: 'none',
                                                                    marginLeft:
                                                                        '8px',
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    agregarOpcionHandler(
                                                                        setCountriesOptions,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}>
                                                {countriesOptions.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col span={16}>
                                        <Form.Item
                                            label="Entidad"
                                            name="entity_code"
                                            rules={[{ required: true }]}>
                                            <Select
                                                placeholder="-- Seleccione o agregue una opción --"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase(),
                                                        ) >= 0
                                                }
                                                dropdownRender={(menu) => (
                                                    <div>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: '4px 0',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap',
                                                                padding: 8,
                                                            }}>
                                                            <Input
                                                                style={{
                                                                    flex: 'auto',
                                                                }}
                                                                placeholder="Agregue una opción nueva"
                                                                value={
                                                                    opcionAgregada
                                                                }
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setOpcionAgregada(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                style={{
                                                                    flex: 'none',
                                                                    marginLeft:
                                                                        '8px',
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    agregarOpcionHandler(
                                                                        setEntityCodeOptions,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}>
                                                {entityCodeOptions.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            name="default"
                                            label="Cuenta principal"
                                            valuePropName="checked"
                                            rules={[{ required: true }]}>
                                            <Switch />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card title="Contacto">
                                <Row gutter={12}>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Nombre"
                                            name="name"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="Teléfono"
                                            name="phone"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={12}>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Tipo de Documento"
                                            name="document_type"
                                            rules={[{ required: true }]}>
                                            <Select
                                                placeholder="-- Seleccione o agregue una opción --"
                                                showSearch
                                                optionFilterProp="children"
                                                filterOption={(input, option) =>
                                                    option.children
                                                        .toLowerCase()
                                                        .indexOf(
                                                            input.toLowerCase(),
                                                        ) >= 0
                                                }
                                                dropdownRender={(menu) => (
                                                    <div>
                                                        {menu}
                                                        <Divider
                                                            style={{
                                                                margin: '4px 0',
                                                            }}
                                                        />
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                flexWrap:
                                                                    'nowrap',
                                                                padding: 8,
                                                            }}>
                                                            <Input
                                                                style={{
                                                                    flex: 'auto',
                                                                }}
                                                                placeholder="Agregue una opción nueva"
                                                                value={
                                                                    opcionAgregada
                                                                }
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setOpcionAgregada(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }
                                                            />
                                                            <Button
                                                                style={{
                                                                    flex: 'none',
                                                                    marginLeft:
                                                                        '8px',
                                                                }}
                                                                type="primary"
                                                                icon={
                                                                    <PlusOutlined />
                                                                }
                                                                onClick={() =>
                                                                    agregarOpcionHandler(
                                                                        setDocumentTypesOptions,
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                )}>
                                                {documentTypesOptions.map(
                                                    (item) => (
                                                        <Select.Option
                                                            key={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ),
                                                )}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="Número de documento"
                                            name="document_number"
                                            rules={[{ required: true }]}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label="DV"
                                            name="dv"
                                            rules={[{ required: true }]}
                                            tooltip={{
                                                title: 'Dígito Verificador',
                                                icon: <InfoCircleOutlined />,
                                            }}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item
                                    label="Dirección de correo"
                                    name="email"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>

                        <Col span={12}>
                            <Card title="Facturación">
                                <Form.Item
                                    label="Franquicia"
                                    name="franchise_society_id"
                                    rules={[
                                        {
                                            required: Boolean(
                                                listaFranquicias?.length > 0,
                                            ),
                                        },
                                    ]}>
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                            0
                                        }
                                        placeholder="-- Seleccione una franquicia --"
                                        disabled={mode === 'EDIT'}>
                                        {listaFranquicias &&
                                            listaFranquicias.map((item) => (
                                                <Select.Option
                                                    key={item.id}
                                                    value={item.id}>
                                                    {item.name}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Nombre en la factura"
                                    name="invoice_name"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item
                                    label="Dirección"
                                    name="address"
                                    rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </ABMLayout>
    );
};

export default FranquiciasForm;

FranquiciasForm.propTypes = {
    mode: PropTypes.string,
    initialValues: PropTypes.object,
};
