import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';

import { urls } from '../../../constants';
import axios from '../../../config/axios';
import getErrorMessage from '../../utils-components/getErrorMessage';

const svUsers = urls.REACT_APP_BACKEND_URL_E;

const useMigrarCredenciales = () => {
    const params = useParams();
    const [redirectURL, setRedirectURL] = useState();
    const [error, setError] = useState();

    const getRedirectURLApi = async (userId) => {
        const data = {
            admin_user_ids: [userId],
        };
        try {
            const apiResponse = await axios.post(
                '/generate_password_secrets',
                data,
                { baseURL: svUsers },
            );

            const credentialsList = apiResponse.data.data.usuarios_credenciales;
            if (!credentialsList?.length) {
                throw 'La lista de credenciales esta vacia';
            }

            const redirectURLRsp = credentialsList[0].link;

            return redirectURLRsp;
        } catch (error) {
            const errorMsg = getErrorMessage(error);
            setError(
                'Hubo un problema al migrar sus credenciales: ' + errorMsg,
            );
            return;
        }
    };

    const getRedirectURL = useCallback(async () => {
        const userId = params?.userId;
        if (!userId) {
            return;
        }

        const redirectURLRsp = await getRedirectURLApi(userId);
        if (redirectURLRsp) {
            let url = redirectURLRsp?.split('/cambiar-credenciales/')[1];
            url = '/cambiar-credenciales/' + url;
            setRedirectURL(url);
        }
    }, [params]);

    return {
        error,
        redirectURL,
        getRedirectURL,
    };
};

export default useMigrarCredenciales;
