import React from 'react';
import { Divider, Modal, Row, Spin, Table, Typography } from 'antd';
import PropTypes from 'prop-types';

const { Title } = Typography;
const { Text } = Typography;

const PreviewJediModal = ({
    PreviewJediModalVisible,
    ordenSeleccionada,
    cerrarModalEnviarJedi,
    listaProductosTableIntegracionJedi,
    abrirModalConfirmacionEnviarJedi,
    loadingIntegraciones,
}) => {
    const ordenId = ordenSeleccionada?.id;
    const columns = [
        {
            title: 'Producto Monchis',
            dataIndex: 'productoMonchis',
            key: 'productoMonchis',
            width: 500,
            render: (_text, record) => (
                <>
                    <Text strong={true}>{record?.productoMonchis}</Text>
                    {record?.addonsMonchis?.map((item) => (
                        <p key={item}>{item}</p>
                    ))}
                </>
            ),
        },
        {
            title: 'Producto Jedi',
            dataIndex: 'productoJedi',
            key: 'productoJedi',
            width: 500,
            render: (_text, record) => (
                <>
                    <Text strong={true}>{record?.productoJedi}</Text>
                    {record?.addonsJedi?.map((item) => (
                        <p key={item}>{item?.jedi_product}</p>
                    ))}
                </>
            ),
        },
    ];

    return (
        <Modal
            width="60%"
            open={PreviewJediModalVisible}
            closable={null}
            onCancel={cerrarModalEnviarJedi}
            okText="Enviar a Jedi"
            onOk={() => abrirModalConfirmacionEnviarJedi(ordenSeleccionada)}>
            <Spin spinning={loadingIntegraciones}>
                <Row justify="space-between">
                    <span style={{ display: 'flex' }}>
                        <Title level={5}>
                            Detalles de la orden a enviar a JEDI.
                        </Title>
                    </span>
                    <Title level={5}>ORDEN #{ordenId}</Title>
                </Row>
                <Divider></Divider>
                <Row>
                    <Table
                        columns={columns}
                        dataSource={listaProductosTableIntegracionJedi || []}
                        pagination={false}
                        bordered={true}
                    />
                </Row>
            </Spin>
        </Modal>
    );
};

PreviewJediModal.propTypes = {
    PreviewJediModalVisible: PropTypes.any,
    ordenSeleccionada: PropTypes.any,
    cerrarModalEnviarJedi: PropTypes.any,
    listaProductosTableIntegracionJedi: PropTypes.any,
    loadingIntegraciones: PropTypes.any,
    abrirModalConfirmacionEnviarJedi: PropTypes.any,
};

export default PreviewJediModal;
