import React from 'react';
import { Modal } from 'antd';
import PropTypes from 'prop-types';
import ModalContent from '../../../atoms/ModalContent';
import useSupport from '../../../../hooks/support/useSupport';

const RechazarAgendadoModal = ({
    ordenSeleccionada,
    isRechazarAgendadoModalVisible,
}) => {
    const {
        cerrarRechazarAgendado,
        confirmarRechazarAgendado,
        rechazarAgendadoLoading,
    } = useSupport();
    let rechazarAgendadoModal;

    rechazarAgendadoModal = (
        <Modal
            open={isRechazarAgendadoModalVisible}
            footer={null}
            closable={null}>
            <ModalContent
                title={`Desea RECHAZAR el agendamiento de la orden #${ordenSeleccionada?.id} ?`}
                okText="Confirmar"
                cancelText="Cancelar"
                onOk={() => confirmarRechazarAgendado(ordenSeleccionada)}
                onCancel={() => cerrarRechazarAgendado()}
                isLoading={rechazarAgendadoLoading}
            />
        </Modal>
    );

    return <>{rechazarAgendadoModal}</>;
};

RechazarAgendadoModal.propTypes = {
    ordenSeleccionada: PropTypes.any,
    isRechazarAgendadoModalVisible: PropTypes.any,
};

export default RechazarAgendadoModal;
