import { Button } from 'antd';
import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import classes from './ultimaActualizacion.module.css';
import PropTypes from 'prop-types';

const UltimaActualizacion = ({ onClick, online }) => {
    const horaUltimaActualizacion =
        !online &&
        `${new Date().toLocaleDateString(
            'es-PY',
        )} - ${new Date().toLocaleTimeString('es-PY', {
            hour12: false,
            timeStyle: 'short',
        })} hs.`;
    return (
        <>
            {!online && (
                <div className={classes.cuadroActualizacion}>
                    <span>Última actualización:</span>
                    <span className={classes.hora}>
                        {horaUltimaActualizacion}
                    </span>

                    <Button
                        className={classes.ButtonActua}
                        type="primary"
                        icon={<ReloadOutlined />}
                        size="small"
                        onClick={onClick}>
                        Actualización manual
                    </Button>
                </div>
            )}
        </>
    );
};

export default React.memo(UltimaActualizacion);

UltimaActualizacion.propTypes = {
    horaUltimaActualizacion: PropTypes.any,
    onClick: PropTypes.func,
    online: PropTypes.bool,
};
