import React, { useContext, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

import PrefContext from '../../../context/preferencias/prefContext';
import UtilidadesContext from '../../../context/utilidades/UtilidadesContext';

import ProtectedComponent from '../../../hoc/ProtectedComponent';
import { useVerificarPermiso } from '../../hooks/useVerificarPermiso';
import SupportState from '../../../context/support/SupportState';
import { Permisos } from '../../../constants/Permisos';

import Perfiles from './Perfiles/index';
import Seguimiento from './Seguimiento/index';

import { Provider } from 'react-redux';
import { store } from '../../../redux/store';

const Support = ({ match }) => {
    // Conexion con el context de Preferencias del usuario
    const { abrirCerrarSidebar } = useContext(PrefContext);
    const { getOrdersStates } = useContext(UtilidadesContext);

    // Consultar permiso para ver Perfiles
    const { esUsuarioComercio } = useVerificarPermiso();
    let componentePerfiles = esUsuarioComercio() ? (
        <Redirect to={`${match.url}/seguimiento`} />
    ) : (
        <Perfiles />
    );

    useEffect(() => {
        abrirCerrarSidebar(false);
        getOrdersStates();
    }, []);

    return (
        <Provider store={store}>
            <SupportState>
                <Switch>
                    <Route
                        path={`${match.url}/perfiles`}
                        render={() => componentePerfiles}
                    />
                    <Route
                        path={`${match.url}/seguimiento`}
                        render={() => (
                            <ProtectedComponent
                                permiso={Permisos.support.verSupport}
                                componente={Seguimiento}
                            />
                        )}
                    />
                </Switch>
            </SupportState>
        </Provider>
    );
};

export default Support;

Support.propTypes = {
    match: PropTypes.object,
};
