import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import NivelUsuarioContext from './NivelUsuarioContext';
import NivelUsuarioReducer, { initialState } from './NivelUsuarioReducer';
import { urls } from '../../../constants';
import axios from '../../../config/axios';
import { message } from 'antd';
import {
    USER_LEVEL_LOADING,
    USER_LEVEL_LIST,
    USER_LEVEL_SHOW_DETAIL,
    USER_LEVEL_HIDE_DETAIL,
    USER_LEVEL_CLOSE_EDIT,
    USER_LEVEL_REWARDS_LOADING,
    USER_LEVEL_REWARDS_LIST,
    USER_LEVEL_SET_SELECTED_USER_LEVEL,
} from './Types';

const svOthers = urls.REACT_APP_BACKEND_URL_C;
const svUsers = urls.REACT_APP_BACKEND_URL_E;

const NivelUsuarioState = ({ children }) => {
    const [state, dispatch] = useReducer(NivelUsuarioReducer, initialState);

    /**
     * It fetches a list of user levels from the server and stores it in the Redux store
     */
    const getUserLevels = () => {
        dispatch({
            type: USER_LEVEL_LOADING,
            payload: {
                loading: true,
            },
        });

        let userLevelList = [];

        axios
            .get('/user_level', { baseURL: svOthers })
            .then((response) => {
                userLevelList = response?.data?.data?.user_levels;
            })
            .catch((error) => {
                const parsedError =
                    error?.response?.data?.message ||
                    error?.message ||
                    'Hubo un problema al obtener la lista de niveles de usuario';
                message.error(parsedError);
            })
            .finally(() => {
                dispatch({
                    type: USER_LEVEL_LIST,
                    payload: {
                        userLevelList: userLevelList,
                    },
                });
            });
    };

    /**
     * It takes a user level object as an argument and dispatches an action to the reducer
     * @param selectedUserLevel - The user level object that was selected.
     */
    const showUserLevelDetail = (selectedUserLevel) => {
        dispatch({
            type: USER_LEVEL_SHOW_DETAIL,
            payload: {
                selectedUserLevel: selectedUserLevel,
            },
        });
    };

    /**
     * It takes a selectedUserLevel object as an argument and dispatches an action to the reducer
     * @param selectedUserLevel - This is the user level that was selected.
     */
    const setSelectedUserLevel = (selectedUserLevel) => {
        dispatch({
            type: USER_LEVEL_SET_SELECTED_USER_LEVEL,
            payload: {
                selectedUserLevel: selectedUserLevel,
            },
        });
    };

    /**
     * It dispatches an action to the reducer to hide the user level detail
     */
    const hideUserLevelDetail = () => {
        dispatch({
            type: USER_LEVEL_HIDE_DETAIL,
        });
    };

    /**
     * It adds a new user level to the database
     * @param userLevel - {
     */
    const newUserLevel = (userLevel, onFormReset) => {
        dispatch({
            type: USER_LEVEL_LOADING,
            payload: {
                loading: true,
            },
        });

        let data = {
            ...userLevel,
            rewards: undefined,
            quantity: undefined,
        };

        const rewardsListHasData = Boolean(userLevel?.rewards?.length);
        const quantityListHasData = Boolean(userLevel?.rewards?.length);
        if (!rewardsListHasData && !quantityListHasData) {
            throw 'No se ha recibido una lista de beneficios';
        }

        axios
            .post('/user_level', data, { baseURL: svOthers })
            .then((response) => {
                let idUserLevel = response?.data?.data?.id;

                if (!idUserLevel)
                    throw 'Hubo un problema al obtener el identificador de este nivel de usuario';

                // Rewards to be added
                const rewardsToBeAdded = userLevel?.rewards.map(
                    (item, index) => ({
                        id: item,
                        quantity: userLevel.quantity[index],
                    }),
                );

                Promise.allSettled(
                    addRewards(rewardsToBeAdded, idUserLevel),
                ).finally(() => {
                    getUserLevels();
                    onFormReset();
                    message.success(
                        response?.data?.message ||
                            'Se guardó el nuevo nivel de usuario exitosamente',
                    );
                });
            })
            .catch((error) => {
                const parsedError =
                    error?.response?.data?.message ||
                    error?.message ||
                    'Hubo un problema al guardar el nuevo nivel de usuario';
                message.error(parsedError);
                dispatch({
                    type: USER_LEVEL_LOADING,
                    payload: {
                        loading: false,
                    },
                });
            });
    };

    /**
     * It updates a user level and its rewards
     * @param userLevel - The user level object to be updated.
     */
    const updateUserLevel = (userLevel) => {
        dispatch({
            type: USER_LEVEL_LOADING,
            payload: {
                loading: true,
            },
        });

        let data = {
            ...userLevel,
            id: undefined,
            rewards: undefined,
            quantity: undefined,
        };
        const idUserLevel = Number(userLevel?.id);

        if (!idUserLevel)
            throw 'No se encontró el identificador del nivel seleccionado';
        axios
            .put(`/user_level/${idUserLevel}`, data, { baseURL: svOthers })
            .then((response) => {
                // Try to add rewards
                const originalRewardsList =
                    state.selectedUserLevel?.rewards || [];
                const newRewardsList =
                    userLevel?.rewards.map((item, index) => ({
                        id: item,
                        quantity: userLevel.quantity[index],
                    })) || [];

                let rewardsToBeRemovedList = originalRewardsList.filter(
                    (original) =>
                        !newRewardsList.includes(
                            (newItem) =>
                                original.id === newItem.id &&
                                original.quantity === newItem.quantity,
                        ),
                );
                let rewardsTobeAddedList = newRewardsList.filter(
                    (newItem) =>
                        !originalRewardsList.includes(
                            (original) =>
                                newItem.id === original.id &&
                                newItem.quantity === original.quantity,
                        ),
                );

                // Fist remove all rewards
                rewardsToBeRemovedList = removeRewards(
                    rewardsToBeRemovedList,
                    idUserLevel,
                );
                Promise.allSettled(rewardsToBeRemovedList).finally(() => {
                    // Then add new rewards (completely new or edited)
                    rewardsTobeAddedList = addRewards(
                        rewardsTobeAddedList,
                        idUserLevel,
                    );
                    Promise.allSettled(rewardsTobeAddedList).finally(() => {
                        getUserLevels();

                        message.success(
                            response?.data?.message ||
                                'Se edito el nivel de usuario exitosamente',
                        );

                        dispatch({
                            type: USER_LEVEL_CLOSE_EDIT,
                        });
                    });
                });
            })
            .catch((error) => {
                const parsedError =
                    error?.response?.data?.message ||
                    error?.message ||
                    'Hubo un problema al editar el nivel de usuario';
                message.error(parsedError);
                dispatch({
                    type: USER_LEVEL_CLOSE_EDIT,
                });
                dispatch({
                    type: USER_LEVEL_LOADING,
                    payload: {
                        loading: true,
                    },
                });
            });
    };

    /**
     * It adds rewards to a user level
     * @param rewardsList - Array of objects with the following structure:
     * @param idUserLevel - The id of the user level that you want to add the rewards to.
     * @returns An array of promises.
     */
    const addRewards = (rewardsList, idUserLevel) => {
        const rewardsListHasData = Boolean(rewardsList?.length);
        if (!parseInt(idUserLevel) && !rewardsListHasData) {
            throw 'Faltan datos para poder asociar los beneficios al nivel de usuario';
        }

        const promisesArr = rewardsList.map((reward) => {
            const rewardData = {
                quantity: reward.quantity,
            };

            const promise = axios
                .post(
                    `/user_level/${idUserLevel}/reward/${reward.id}`,
                    rewardData,
                    { baseURL: svOthers },
                )
                .then(() => {})
                .catch((error) => {
                    const parsedError =
                        error?.response?.data?.message ||
                        error?.message ||
                        'Hubo un problema al agregar el nivel de usuario';
                    throw parsedError;
                });

            return promise;
        });

        return promisesArr;
    };

    /**
     * It removes a list of rewards from a user level
     * @param rewardsList - Array of objects with the following structure:
     * @param idUserLevel - The id of the user level to which the rewards will be associated.
     * @returns An array of promises.
     */
    const removeRewards = (rewardsList, idUserLevel) => {
        const rewardsListHasData = Boolean(rewardsList?.length);
        if (!parseInt(idUserLevel) && !rewardsListHasData) {
            throw 'Faltan datos para poder asociar los beneficios al nivel de usuario';
        }

        const promisesArr = rewardsList.map((reward) => {
            const promise = axios
                .delete(`/user_level/${idUserLevel}/reward/${reward.id}`, {
                    baseURL: svOthers,
                })
                .then(() => {})
                .catch((error) => {
                    const parsedError =
                        error?.response?.data?.message ||
                        error?.message ||
                        'Hubo un problema al remover el nivel de usuario';
                    throw parsedError;
                });

            return promise;
        });

        return promisesArr;
    };

    /**
     * It dispatches an action to the reducer to close the edit user level modal
     */
    const cancelEditUserLevel = () => {
        dispatch({
            type: USER_LEVEL_CLOSE_EDIT,
        });
    };

    /**
     * It gets the rewards list from the server and stores it in the Context store
     */
    const getRewards = () => {
        dispatch({ type: USER_LEVEL_REWARDS_LOADING });

        let rewardsList = [];
        axios
            .get('/reward', { baseURL: svUsers })
            .then((response) => {
                rewardsList = response?.data?.data?.rewards;
            })
            .catch((error) => {
                message.error(error.response.data.message);
            })
            .finally(() => {
                dispatch({
                    type: USER_LEVEL_REWARDS_LIST,
                    payload: {
                        rewardsList: rewardsList,
                    },
                });
            });
    };

    return (
        <NivelUsuarioContext.Provider
            value={{
                userLevelList: state.userLevelList,
                selectedUserLevel: state.selectedUserLevel,
                loading: state.loading,
                isShowUserLevelModalVisible: state.isShowUserLevelModalVisible,
                rewardsList: state.rewardsList,
                isRewardsListLoading: state.isRewardsListLoading,

                getUserLevels,
                showUserLevelDetail,
                hideUserLevelDetail,
                updateUserLevel,
                cancelEditUserLevel,
                newUserLevel,
                setSelectedUserLevel,

                getRewards,
            }}>
            {children}
        </NivelUsuarioContext.Provider>
    );
};

export default NivelUsuarioState;

NivelUsuarioState.propTypes = {
    children: PropTypes.node,
};
