import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ABMLayout from '../../../../../../../layouts/ABMLayout';
import {
    Button,
    Space,
    Card,
    Form,
    Input,
    Col,
    Row,
    Radio,
    DatePicker,
    InputNumber,
    message,
    Spin,
} from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import clienteAxios from '../../../../../../../config/axios';
import { urls } from '../../../../../../../constants';
import FormItemSelectCustomer from '../../../../../../atoms/Form/Select/Customer';
import classes from './facturasForm.module.css';
import DebounceSelect from '../../../../../../atoms/Form/DebounceSelect';

// {/* FIX: numero_factura */}
// {/* FIX: razon_social */}
// {/* FIX: condicion_venta */}
// {/* FIX: total_iva */}

const FacturasForm = () => {
    const dateFormat = 'DD/MM/YYYY';
    const baseURL = urls.REACT_APP_BACKEND_URL_C;
    const [facturaForm] = Form.useForm();
    const history = useHistory();

    const [datosFactura, setDatosFactura] = useState();
    const [invoiceData, setInvoiceData] = useState();
    const [invoiceDescriptionData, setInvoiceDescriptionData] = useState();
    const [loadingSpin, SetIsLoadingSpin] = useState(false);
    const [valueCondicionVenta, setValueCondicionVenta] = useState();

    useEffect(() => {
        getInvoiceNumber();
    }, []);

    useEffect(() => {
        if (datosFactura) {
            sendDataToBackend();
        }
    }, [datosFactura]);

    const getDataForm = () => {
        facturaForm
            .validateFields()
            .then((values) => {
                try {
                    const customersArr = JSON.parse(values.customerId);
                    const invoiceDetails = values?.invoiceDetails.map(
                        (item) => ({
                            id_description: JSON.parse(item.codigo.value).id,
                            quantity: item.cantidad,
                            iva_type: 'iva10',
                            total: item.precio,
                        }),
                    );
                    const data = {
                        type: customersArr.type, //enviar si es franquicia o cliente
                        franchise_society_id:
                            customersArr.type === 'franchise'
                                ? customersArr.id
                                : null, //campo opcional
                        stamped_id: invoiceData.stamped_id, //este dato viene del ednpoint /futureInvoiceInfo
                        customer_id:
                            customersArr.type === 'customer'
                                ? customersArr.id
                                : null, //campo opcional
                        invoice_ruc: customersArr.document_number,
                        reason: customersArr.legal_name,
                        address: customersArr.address,
                        phone_number: customersArr.phone_number,
                        invoice_state_id: 2, //se manda estado facturado por defecto.
                        voucher_type_id: 1, //se manda por defecto 1 que es factura, 2 es recibo pero no se usa aun
                        amount: values.total, //monto total de los items
                        sale_condition: valueCondicionVenta,

                        expedition_point: invoiceData.expedition_point, //este dato viene del ednpoint /futureInvoiceInfo
                        branch: invoiceData.branch, //este dato viene del ednpoint /futureInvoiceInfo

                        date: values.fecha.format('YYYY/MM/DD'), //YYYYMMDD
                        invoice_number: invoiceData.invoice_number, //este dato viene del ednpoint /futureInvoiceInfo
                        invoice_details: [...invoiceDetails],
                    };
                    setDatosFactura(data);
                } catch (error) {
                    message.error(
                        error.response?.data.message ||
                            'Hubo un problema al obtener los datos del RUC',
                    );
                }
            })
            .catch(() => {});
    };

    const getInvoiceNumber = async () => {
        try {
            const request = await clienteAxios.get('/futureInvoiceInfo', {
                baseURL,
            });
            setInvoiceData(request.data.data);
            facturaForm.setFieldsValue({
                numeroFactura: request.data.data.full_invoice_number,
                timbrado: request.data.data.stamped,
            });
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener los datos',
            );
        }
    };

    const getInvoiceDescription = async (code) => {
        try {
            const params = {
                code: code ? code.toUpperCase() : undefined,
            };
            const request = await clienteAxios.get(
                '/get_invoice_descriptions',
                { baseURL, params },
            );
            const options =
                request?.data?.data?.response?.invoice_descriptions.map(
                    (item) => ({
                        label: item.code,
                        value: JSON.stringify(item),
                    }),
                );
            setInvoiceDescriptionData(options);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Hubo un problema al obtener los datos',
            );
        }
    };

    const sendDataToBackend = async () => {
        SetIsLoadingSpin(true);
        const data = datosFactura;
        try {
            const request = await clienteAxios.post(
                '/create_invoice_manual',
                data,
                { baseURL },
            );
            message.success(request.data.message);
        } catch (error) {
            message.error(
                error.response?.data.message ||
                    'Ocurrió un error al crear la factura',
            );
        }
        SetIsLoadingSpin(false);
        facturaForm.resetFields();
        getInvoiceNumber();
    };

    const rucChangeHandler = () => {
        const customerStr = facturaForm.getFieldValue('customerId');
        const customer = JSON.parse(customerStr);
        facturaForm.setFieldsValue({
            razonSocial: customer?.legal_name,
            email: customer?.email,
        });
    };

    const cantidadPrecioChangeHandler = () => {
        const invoiceDetailsArr = facturaForm.getFieldValue('invoiceDetails');
        let totalFactura = 0;
        let itemsArr = [];
        invoiceDetailsArr.forEach((item) => {
            let nuevoItem = { ...item };
            if (item?.cantidad && item?.precio) {
                const totalItem = +item.cantidad * +item.precio;
                totalFactura += totalItem;
                nuevoItem['total'] = totalItem;
            }
            itemsArr.push(nuevoItem);
        });
        const iva10 = Math.round(totalFactura / 11);
        facturaForm.setFieldsValue({
            invoiceDetails: itemsArr,
            iva10: iva10,
            totalIVA: iva10,
            total: totalFactura,
        });
    };

    const codigoOnChangeHandler = (key) => {
        const invoiceDetailsArr = facturaForm.getFieldValue('invoiceDetails');
        let itemsArr = [];
        invoiceDetailsArr.forEach((item, index) => {
            let nuevoItem = { ...item };
            if (key === index) {
                nuevoItem['descripcion'] = JSON.parse(
                    item.codigo.value,
                ).description;
            }
            itemsArr.push(nuevoItem);
        });
        facturaForm.setFieldsValue({ invoiceDetails: itemsArr });
    };

    const onChange = (e) => {
        setValueCondicionVenta(e.target.value);
    };

    return (
        <Spin spinning={loadingSpin}>
            <ABMLayout
                titulo={'Nueva Factura'}
                acciones={
                    <Space>
                        <Button onClick={() => history.goBack()}>
                            Cancelar
                        </Button>
                        <Button onClick={getDataForm} type="primary">
                            Guardar
                        </Button>
                    </Space>
                }>
                <Form form={facturaForm} layout="vertical">
                    <Card title={'Datos generales'} style={{ padding: '1rem' }}>
                        <Row className={classes.flexJustifyBetween}>
                            <Col>
                                <Form.Item label="Número" name="numeroFactura">
                                    {/* FIX: numero_factura */}
                                    <Input />
                                </Form.Item>
                                <Row>
                                    <Form.Item
                                        label="Razón Social"
                                        name="razonSocial">
                                        {/* FIX: razon_social */}
                                        <Input disabled={true} />
                                    </Form.Item>
                                    <FormItemSelectCustomer
                                        onChangeSelect={rucChangeHandler}
                                    />
                                </Row>
                                <Form.Item
                                    label="Dirección de Correo"
                                    name="email">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={10}>
                                <Form.Item label="Timbrado" name="timbrado">
                                    <Input disabled={true} />
                                </Form.Item>
                                <Form.Item
                                    label="Fecha"
                                    name="fecha"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Este campo es obligatorio',
                                        },
                                    ]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        format={dateFormat}></DatePicker>
                                </Form.Item>
                                <Form.Item
                                    name="condicionVenta"
                                    label="Condición de Venta"
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            required: true,
                                            message:
                                                'Este campo es obligatorio',
                                        },
                                    ]}>
                                    {/* FIX: condicion_venta */}
                                    <Radio.Group
                                        optionType="button"
                                        onChange={onChange}>
                                        <Radio value="CREDITO">Crédito</Radio>
                                        <Radio value="CONTADO">Contado</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <Card title={'Factura en blanco'}>
                        <Form.List name="invoiceDetails">
                            {(fields, { add, remove }) => (
                                <>
                                    {fields.map(
                                        ({
                                            key,
                                            name,
                                            fieldKey,
                                            ...restField
                                        }) => (
                                            <Space
                                                key={key}
                                                className={
                                                    classes.itemFacturaEnBlanco
                                                }>
                                                <Row style={{ width: '90vw' }}>
                                                    <Col span={2}>
                                                        <Form.Item
                                                            label="Código"
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'codigo',
                                                            ]}
                                                            fieldKey={[
                                                                fieldKey,
                                                                'codigo',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                },
                                                            ]}>
                                                            <DebounceSelect
                                                                options={
                                                                    invoiceDescriptionData
                                                                }
                                                                fetchOptions={
                                                                    getInvoiceDescription
                                                                }
                                                                onChange={() =>
                                                                    codigoOnChangeHandler(
                                                                        key,
                                                                        name,
                                                                        fieldKey,
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={6}>
                                                        <Form.Item
                                                            label="Descripción"
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'descripcion',
                                                            ]}
                                                            fieldKey={[
                                                                fieldKey,
                                                                'descripcion',
                                                            ]}
                                                            className={
                                                                classes.itemDescripcion
                                                            }>
                                                            <Input
                                                                disabled={true}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Form.Item
                                                            label="Cantidad"
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'cantidad',
                                                            ]}
                                                            fieldKey={[
                                                                fieldKey,
                                                                'cantidad',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                },
                                                            ]}>
                                                            <InputNumber
                                                                onChange={
                                                                    cantidadPrecioChangeHandler
                                                                }
                                                                min={1}
                                                                style={{
                                                                    width: 'inherit',
                                                                }}
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Form.Item
                                                            label="IVA"
                                                            {...restField}
                                                            name={[name, 'iva']}
                                                            fieldKey={[
                                                                fieldKey,
                                                                'iva',
                                                            ]}
                                                            className={
                                                                classes.itemIVA
                                                            }>
                                                            <Input
                                                                disabled={true}
                                                                value="iva10"
                                                                placeholder="10%"
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Form.Item
                                                            label="Precio"
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'precio',
                                                            ]}
                                                            fieldKey={[
                                                                fieldKey,
                                                                'precio',
                                                            ]}
                                                            rules={[
                                                                {
                                                                    required: true,
                                                                    message:
                                                                        'Este campo es obligatorio',
                                                                },
                                                            ]}
                                                            className={
                                                                classes.itemPrecio
                                                            }>
                                                            <InputNumber
                                                                style={{
                                                                    width: 'inherit',
                                                                }}
                                                                onChange={
                                                                    cantidadPrecioChangeHandler
                                                                }
                                                                min={1}
                                                                formatter={(
                                                                    value,
                                                                ) =>
                                                                    `Gs. ${value}`.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        '.',
                                                                    )
                                                                }
                                                                parser={(
                                                                    value,
                                                                ) =>
                                                                    value.replace(
                                                                        /Gs.\s?|([.]*)/g,
                                                                        '',
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={3}>
                                                        <Form.Item
                                                            label="Total"
                                                            {...restField}
                                                            name={[
                                                                name,
                                                                'total',
                                                            ]}
                                                            fieldKey={[
                                                                fieldKey,
                                                                'total',
                                                            ]}
                                                            className={
                                                                classes.itemTotal
                                                            }>
                                                            <InputNumber
                                                                style={{
                                                                    width: 'inherit',
                                                                }}
                                                                disabled={true}
                                                                formatter={(
                                                                    value,
                                                                ) =>
                                                                    `Gs. ${value}`.replace(
                                                                        /\B(?=(\d{3})+(?!\d))/g,
                                                                        '.',
                                                                    )
                                                                }
                                                                parser={(
                                                                    value,
                                                                ) =>
                                                                    value.replace(
                                                                        /Gs.\s?|([.]*)/g,
                                                                        '',
                                                                    )
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col>
                                                        <Form.Item>
                                                            <MinusCircleOutlined
                                                                onClick={() => {
                                                                    remove(
                                                                        name,
                                                                    );
                                                                    cantidadPrecioChangeHandler();
                                                                }}
                                                                className={
                                                                    classes.botonEliminar
                                                                }
                                                            />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Space>
                                        ),
                                    )}
                                    <Form.Item>
                                        <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}>
                                            Nueva Fila
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                        </Form.List>
                    </Card>
                    <Card title={'Liquidación del IVA'}>
                        <Row>
                            <Col span={4}></Col>
                            <Col span={5} className={classes.flexJustifyEnd}>
                                <Form.Item style={{ marginRight: '10px' }}>
                                    5%
                                </Form.Item>
                                <Form.Item name="iva5">
                                    <InputNumber
                                        disabled={true}
                                        placeholder="Gs. 0"
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} className={classes.flexJustifyEnd}>
                                <Form.Item style={{ marginRight: '10px' }}>
                                    10%
                                </Form.Item>
                                <Form.Item name="iva10">
                                    <InputNumber
                                        style={{ width: '120px' }}
                                        disabled={true}
                                        formatter={(value) =>
                                            `Gs. ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                '.',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Gs.\s?|([.]*)/g, '')
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} className={classes.flexJustifyEnd}>
                                <Form.Item style={{ marginRight: '10px' }}>
                                    Total IVA
                                </Form.Item>
                                <Form.Item name="totalIVA">
                                    {/* FIX: total_iva */}
                                    <InputNumber
                                        style={{ width: '120px' }}
                                        disabled={true}
                                        formatter={(value) =>
                                            `Gs. ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                '.',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Gs.\s?|([.]*)/g, '')
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={5} className={classes.flexJustifyEnd}>
                                <Form.Item style={{ marginRight: '10px' }}>
                                    Total
                                </Form.Item>
                                <Form.Item name="total">
                                    <InputNumber
                                        style={{ width: '120px' }}
                                        disabled={true}
                                        formatter={(value) =>
                                            `Gs. ${value}`.replace(
                                                /\B(?=(\d{3})+(?!\d))/g,
                                                '.',
                                            )
                                        }
                                        parser={(value) =>
                                            value.replace(/Gs.\s?|([.]*)/g, '')
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                </Form>
            </ABMLayout>
        </Spin>
    );
};

export default FacturasForm;
