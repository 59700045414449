import React from 'react';
import FranquiciasForm from './FranquiciasForm';
import { useHistory } from 'react-router-dom';

const EditarFranquicias = () => {
    const history = useHistory();
    return (
        <FranquiciasForm
            mode="EDIT"
            initialValues={history.location.state?.initialValues}
        />
    );
};

export default EditarFranquicias;
