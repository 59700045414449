import React from 'react';
import { Modal, Space, Typography } from 'antd';
import PropTypes from 'prop-types';

import ModalContent from '../../../../atoms/ModalContent';

const ConfirmDisableBranchesModal = ({
    isVisible,
    title,
    onCancel,
    onSubmit,
    content,
}) => {
    return (
        <Modal
            centered
            footer={null}
            open={isVisible}
            title={title}
            closable={false}
            destroyOnClose>
            <ModalContent
                showWarnIcon
                isLoading={false}
                okText="Continuar"
                cancelText="Cancelar"
                onCancel={onCancel}
                onOk={onSubmit}>
                <Space direction="vertical">
                    <Typography.Text>
                        Este proceso podría tomar unos minutos
                    </Typography.Text>
                    {content}
                </Space>
            </ModalContent>
        </Modal>
    );
};

export default ConfirmDisableBranchesModal;

ConfirmDisableBranchesModal.propTypes = {
    isVisible: PropTypes.bool,
    title: PropTypes.string,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    content: PropTypes.any,
};
