import { Card, Image, Spin } from 'antd';
import React from 'react';

import monchisLogo from '../../../../assets/images/monchisLogo.png';
import classes from './authCard.module.css';
import PropTypes from 'prop-types';

const AuthCard = ({ loading, children }) => {
    return (
        <div className={classes.authContainer}>
            {/* TODO: Cambiar con este formato cuando se tenga la imagen de fondo para esta pantalla */}
            {/* <div className={classes.authContainer} style={{backgroundImage: `url(${monchisLogo})`}}> */}
            <Card>
                <Spin spinning={loading}>
                    <div className={classes.cardBodyContainer}>
                        <div className={classes.textCenter}>
                            <Image width={100} src={monchisLogo} />
                            <h2 className={classes.textRed}>MONCHIS</h2>
                        </div>
                        {children}
                    </div>
                </Spin>
            </Card>
        </div>
    );
};

export default AuthCard;

AuthCard.propTypes = {
    loading: PropTypes.bool,
    children: PropTypes.node,
};
