import { Button } from 'antd';
import React from 'react';
import classes from './CargarMasButton1.module.css';
import PropTypes from 'prop-types';

const CargarMasButton = ({
    onCargarMas,
    loading,
    paginaActual,
    totalPaginas,
}) => {
    return (
        <div className={classes.buttonContainer}>
            {paginaActual < totalPaginas && (
                <Button
                    type="button"
                    onClick={onCargarMas}
                    loading={loading}
                    className={classes.CargarMAS}>
                    CARGAR MÁS
                </Button>
            )}
        </div>
    );
};

CargarMasButton.propTypes = {
    onCargarMas: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
    paginaActual: PropTypes.number,
    totalPaginas: PropTypes.number,
};

export default React.memo(CargarMasButton);
