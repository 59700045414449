import {
    OBTENER_LISTADO_CLIENTES,
    PAGINADO_CLIENTES,
    CLIENTES_TOTALES,
} from './types';

export const initialState = {
    listaClientes: [],
    paginaActualClientes: 1,
    totalClientes: 0,
};

const ClientesReducer = (state = initialState, action) => {
    switch (action.type) {
        case OBTENER_LISTADO_CLIENTES:
            return {
                ...state,
                listaClientes: action.payload,
            };
        case PAGINADO_CLIENTES:
            return {
                ...state,
                paginaActualClientes: action.payload,
            };
        case CLIENTES_TOTALES:
            return {
                ...state,
                totalClientes: action.payload,
            };
        default:
            return state;
    }
};

export default ClientesReducer;
