import React, { useState, useEffect, useContext } from 'react';
import { Row, Col, Table, Button, Form, message, Tag } from 'antd';
import DocumentosComercioContext from '../../../../../../context/facturacion/documentosComercio/DocumentosComercioContext';
import { formatoGuaranies } from '../../../../../utils-components/Number';
import { obtenerIntervaloInicioFinDocumentosFacturacion } from '../../../../../utils-components/obtenerIntervaloInicioFinDocumentosFacturacion';
import { LoadingOutlined } from '@ant-design/icons';
import FormItemSelectMes from '../../../../../atoms/Form/Select/Mes';
import FormItemSelectAnho from '../../../../../atoms/Form/Select/Anho';
import descargarArchivoRaw from '../../../../../utils-components/descargarArchivoRaw';
import axios from '../../../../../../config/axios';

const itemsPorPagina = 20;

const ListaRecibosComercio = () => {
    const [parametrosBusqueda, setParametrosBusqueda] = useState({
        date_start: '',
        date_end: '',
        state_ids: [1],
        voucher_type_id: 2,
        page: 1,
    });

    const documentosComercioContext = useContext(DocumentosComercioContext);

    const {
        recibosLista,
        recibosTotalPaginas,
        recibosListaLoading,
        recibosObtenerLista,
    } = documentosComercioContext;

    const [formFiltrosRecibos] = Form.useForm();

    const columns = [
        {
            title: 'Nro. Recibo',
            dataIndex: 'nroRecibo',
            key: 'nroRecibo',
            render: (text) => text.replace('000-000-', ''),
        },
        {
            title: 'Nombre',
            dataIndex: 'nombreFranquicia',
            key: 'nombreFranquicia',
        },
        {
            title: 'Razón social',
            dataIndex: 'nombreLegal',
            key: 'nombreLegal',
        },
        {
            title: 'RUC',
            dataIndex: 'ruc',
            key: 'ruc',
        },
        {
            title: 'Estado',
            dataIndex: 'estado',
            key: 'estado',
            render: (text) => (
                <div style={{ textTransform: 'uppercase' }}>
                    <Tag
                        style={{
                            borderRadius: '5px',
                            fontSize: '10px',
                            padding: '7px',
                            alignContent: 'center',
                            border: 'none',
                            marginRight: 0,
                        }}
                        color={text === 'pendiente' ? 'green' : 'volcano'}>
                        {text === 'pendiente' ? 'ACTIVO' : text}
                    </Tag>
                </div>
            ),
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
            render: (text) => formatoGuaranies(+text),
        },
        {
            title: '',
            key: 'acciones',
            render: (record) => (
                <Button
                    type="link"
                    onClick={() =>
                        descargarPDFhandler(record.idFactura, record.secreto)
                    }
                    disabled={!record.idFactura || !record.secreto}>
                    Ver
                </Button>
            ),
        },
    ];

    useEffect(() => {
        // Obtener las fechas para la busqueda inicial

        // Obtener fecha del mes anterior al actual
        const fechaConsultar = new Date();
        fechaConsultar.setMonth(fechaConsultar.getMonth() - 1);

        // Obtener parametros de busqueda
        const { fechasBusqueda, mesStart, anhoStart } =
            obtenerIntervaloInicioFinDocumentosFacturacion(
                fechaConsultar.getMonth(),
                fechaConsultar.getFullYear(),
            );

        // Establecer parametros de busqueda en el form
        formFiltrosRecibos.setFieldsValue({
            mes: mesStart,
            anho: anhoStart,
            franquiciaId: '',
        });

        // Guardar parametros de busqueda en el state local
        setParametrosBusqueda((prevState) => ({
            ...prevState,
            ...fechasBusqueda,
        }));
    }, []);

    useEffect(() => {
        if (parametrosBusqueda.date_start && parametrosBusqueda.date_end) {
            recibosObtenerLista(parametrosBusqueda);
        }
    }, [parametrosBusqueda]);

    const buscarFacturasHandler = (values) => {
        const { fechasBusqueda } =
            obtenerIntervaloInicioFinDocumentosFacturacion(
                +values?.mes - 1,
                +values?.anho,
            );

        setParametrosBusqueda((prevState) => ({
            ...prevState,
            ...fechasBusqueda,
            page: 1,
        }));
    };

    const changePageHandler = (page) => {
        setParametrosBusqueda((prevState) => ({
            ...prevState,
            page,
        }));
    };

    const descargarPDFhandler = async (idFactura, secreto) => {
        message.info({
            content: 'Generando archivo...',
            key: 'exportar',
            duration: 0,
            icon: <LoadingOutlined />,
        });

        const params = {
            invoice_id: idFactura,
            voucher_type_id: 2,
            secret: secreto,
        };
        try {
            const respuesta = await axios.get(`/get_invoice_without_token`, {
                params: params,
            });

            // eslint-disable-next-line no-undef
            const rawFile = Buffer.from(respuesta.data.data.body, 'base64');
            const fileName = 'tu-recibo';
            const fileType = 'pdf';

            descargarArchivoRaw(rawFile, fileName, fileType);
        } catch (error) {
            console.log(error);
            message.error(
                error?.response?.data?.message ||
                    'Hubo un problema al generar su archivo',
            );
        }
        message.destroy('exportar');
    };

    return (
        <>
            <Row gutter={12}>
                <Col span={16}>
                    <Form
                        form={formFiltrosRecibos}
                        onFinish={buscarFacturasHandler}
                        name="filtros"
                        layout="vertical">
                        <Row gutter={12}>
                            <Col span={10}>
                                {/* Form.Item name=mes */}
                                <FormItemSelectMes
                                    rules={[{ required: true }]}
                                    disabled={recibosListaLoading}
                                />
                            </Col>
                            <Col span={10}>
                                {/* Form.Item name=anho */}
                                <FormItemSelectAnho
                                    rules={[{ required: true }]}
                                    disabled={recibosListaLoading}
                                />
                            </Col>
                            <Col span={4}>
                                <Form.Item style={{ marginTop: 26 }}>
                                    <Button
                                        disabled={recibosListaLoading}
                                        type="primary"
                                        htmlType="submit">
                                        Buscar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
            <Row gutter={12}>
                <Col span={24}>
                    <Table
                        size="small"
                        loading={recibosListaLoading}
                        columns={columns}
                        dataSource={recibosLista}
                        pagination={{
                            pageSize: itemsPorPagina,
                            total: recibosTotalPaginas,
                            current: parametrosBusqueda.page,
                            showSizeChanger: false,
                            onChange: (page) => changePageHandler(page),
                        }}
                    />
                </Col>
            </Row>
        </>
    );
};

export default ListaRecibosComercio;
