import React, { useEffect, useMemo, useState } from 'react';
import { Button, Row, Col, Spin } from 'antd';
import ABMLayout from '../../../../layouts/ABMLayout';
import { UserOutlined } from '@ant-design/icons';
import { CardPerfiles } from '../../../atoms/support/CardPerfiles';
import classes from './perfiles.module.css';
import useSupport from '../../../../hooks/support/useSupport';

const SupportPerfiles = () => {
    const { sendProfiles, getProfiles, listaPerfiles, loading } = useSupport();
    const [perfilesSeleccionados, setPerfilesSeleccionados] = useState([]);
    const [idStorage, setIdStorage] = useState(null);

    useEffect(() => {
        getProfiles();

        const idStorage = localStorage.getItem('id');
        setIdStorage(idStorage);
    }, []);

    const handlePerfiles = (perfil, index) => {
        const agregarPerfiles = [...perfilesSeleccionados, perfil];
        setPerfilesSeleccionados(agregarPerfiles);
        removerSeleccionado(perfilesSeleccionados, perfil);
        renderListaPerfiles[index].seleccionado =
            !renderListaPerfiles[index].seleccionado;
    };

    const removerSeleccionado = (array, valor) => {
        //revisar si existe el elemento
        const existe = array.some((item) => item === valor);
        if (existe) {
            const nuevoPerfil = array.filter((item) => item !== valor);
            setPerfilesSeleccionados(nuevoPerfil);
        } else {
            setPerfilesSeleccionados([...array, valor]);
        }
    };

    const enviarPerfiles = (perfiles, idUser) => {
        sendProfiles(perfiles, idUser);
    };

    const renderListaPerfiles = useMemo(() => {
        return listaPerfiles?.map((item) => ({ ...item, seleccionado: false }));
    }, [listaPerfiles]);

    return (
        <Spin spinning={loading}>
            <ABMLayout titulo={'Soporte'}>
                <Row className={classes.areaTrabajo}>
                    <div className={classes.perfilCenter}>
                        <UserOutlined
                            style={{ fontSize: '35px', marginRight: '10px' }}
                        />{' '}
                        {/* solo toma asi los estilos el antd para iconos */}
                    </div>
                    <div>
                        Seleccioná las áreas con las que vas a trabajar hoy:
                    </div>
                </Row>
                <Row gutter={[24, 16]}>
                    {renderListaPerfiles?.map((perfil, index) => (
                        <Col
                            span={8}
                            key={perfil.id}
                            onClick={() => handlePerfiles(perfil.id, index)}>
                            <CardPerfiles
                                perfil={perfil.profile_name}
                                descripcion={perfil.profile_description}
                                seleccionado={perfil.seleccionado}
                            />
                        </Col>
                    ))}
                </Row>
                <Row className={classes.botonContinuar}>
                    <Button
                        type="primary"
                        disabled={perfilesSeleccionados.length <= 0 && true}
                        onClick={() =>
                            enviarPerfiles(perfilesSeleccionados, idStorage)
                        }>
                        Continuar
                    </Button>
                </Row>
            </ABMLayout>
        </Spin>
    );
};

export default SupportPerfiles;
