import React, { useContext } from 'react';
import { Button, Col, Divider, Row } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import classes from './AccionesDetalle1.module.css';
import SupportContext from '../../../../context/support/SupportContext';
import Integraciones from '../Integraciones';
import UtilidadesContext from '../../../../context/utilidades/UtilidadesContext';
import useSupport from '../../../../hooks/support/useSupport';
import ImprimirOrdenButton from '../acciones/ImprimirOrdenButton';
import Cancelar from 'assets/svg/Cancelar';
const idFinalizado = 6;
const idCancelado = 2;

const AccionesDetalleVerOrden = ({
    ordenSeleccionada,
    siguienteEstadoTexto,
    cambiarEstadoOrdenButtonHandler,
    cancelarOrdenButtonHandler,
    hasPermissionToChangeState,
}) => {
    const { cerrarVerOrden } = useContext(SupportContext);
    const {
        abrirAceptarAgendado,
        abrirRechazarAgendado,
        permisoVerIntegraciones,
        imprimirOrden,
    } = useSupport();
    const { ordersStates } = useContext(UtilidadesContext);
    const orderStateId = ordenSeleccionada?.order_state_id;

    const esOrdenFinalizada = Boolean(orderStateId === idFinalizado);
    const esOrdenCancelada = Boolean(orderStateId === idCancelado);

    const orderState = ordersStates.find((state) => state?.id === orderStateId);
    const orderStateName = orderState?.name;

    const esIntegracion = ordenSeleccionada?.actions?.has_integration;

    const botonIntegracion = permisoVerIntegraciones ? (
        <Col span={24}>
            {!esOrdenFinalizada && !esOrdenCancelada && (
                <Integraciones
                    jediId={ordenSeleccionada?.jedi_id}
                    fechaJedi={ordenSeleccionada?.sent_to_jedi_date}
                    idMcDonalds={
                        ordenSeleccionada?.mcdonals_external_invoice_id
                    }
                    ordenSeleccionada={ordenSeleccionada}
                    esIntegracion={esIntegracion}
                    esJedi={ordenSeleccionada?.has_jedi}
                    estadoOrden={orderStateId}
                    idIntegracion={ordenSeleccionada?.external_order_id}
                    envioIntegracion={ordenSeleccionada?.sent_to_integration}
                    esVerDetalle
                />
            )}
        </Col>
    ) : null;

    const aceptarOrdenAgendada = () => {
        abrirAceptarAgendado(ordenSeleccionada);
        cerrarVerOrden();
    };

    const rechazarOrdenAgendada = () => {
        abrirRechazarAgendado(ordenSeleccionada);
        cerrarVerOrden();
    };

    const conditionals = {
        ifTheOrderIsDifferentFromCancel: orderStateName !== 'Cancelada',
        ifTheOrderIsDifferentFromDelivered: orderStateName !== 'Entregada',
        spanQuantity: orderStateName !== 'Entregada' ? 12 : 24,
    };

    const allowToChangeOrderState = !ordenSeleccionada?.actions?.change_state;
    const allowToCancelOrder = !ordenSeleccionada?.actions?.cancel_order;

    return orderStateId === 11 ? (
        <>
            <Row gutter={24}>
                <Col span={12}>
                    <Button
                        icon={<CloseOutlined />}
                        onClick={rechazarOrdenAgendada}
                        block
                        type="default"
                        danger>
                        Rechazar
                    </Button>
                </Col>
                <Col span={12}>
                    <Button
                        icon={<CheckOutlined color="white" />}
                        onClick={aceptarOrdenAgendada}
                        block
                        type="primary"
                        className={classes.botonPrimario}>
                        Aceptar
                    </Button>
                </Col>
            </Row>
        </>
    ) : (
        conditionals.ifTheOrderIsDifferentFromCancel && (
            <>
                <Divider />
                <Row gutter={24} className={classes.Row}>
                    <Col
                        style={{
                            marginLeft: '20px',
                        }}>
                        <Button
                            onClick={cancelarOrdenButtonHandler}
                            type="button"
                            danger
                            disabled={allowToCancelOrder}
                            className={classes.BotonCancelarPedido}>
                            <span style={{ marginRight: '6px' }}>
                                CANCELAR PEDIDO
                            </span>
                            <Cancelar />
                        </Button>
                    </Col>

                    <Col className={classes.IntegracionBotones}>
                        <div>
                            {esIntegracion && <div>{botonIntegracion}</div>}
                        </div>
                        <div className={classes.printOrder}>
                            <ImprimirOrdenButton
                                permitirImprimirOrden={true}
                                imprimirOrdenButtonHandler={() =>
                                    imprimirOrden(ordenSeleccionada)
                                }
                            />
                        </div>
                        {(conditionals.ifTheOrderIsDifferentFromDelivered ||
                            hasPermissionToChangeState) && (
                            <Button
                                onClick={cambiarEstadoOrdenButtonHandler}
                                block
                                type="link"
                                className={classes.BotonEstados}
                                disabled={allowToChangeOrderState}>
                                <span>
                                    PASAR A {siguienteEstadoTexto.toUpperCase()}
                                </span>
                                <span
                                    role="img"
                                    aria-label="right"
                                    className="anticon anticon-right">
                                    <svg
                                        viewBox="64 64 896 896"
                                        focusable="false"
                                        data-icon="right"
                                        width="1em"
                                        height="1em"
                                        fill="currentColor"
                                        aria-hidden="true">
                                        <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                                    </svg>
                                </span>
                            </Button>
                        )}
                    </Col>
                </Row>
            </>
        )
    );
};

AccionesDetalleVerOrden.propTypes = {
    esJedi: PropTypes.bool,
    integracion: PropTypes.string,
    siguienteEstadoTexto: PropTypes.string,
    cambiarEstadoOrdenButtonHandler: PropTypes.func,
    cancelarOrdenButtonHandler: PropTypes.func,
    ordenSeleccionada: PropTypes.any,
    permisoVerIntegraciones: PropTypes.bool,
    hasPermissionToChangeState: PropTypes.bool,
};

export default AccionesDetalleVerOrden;
