import React, { useContext, useEffect, useState } from 'react';
import { useMixpanel } from 'react-mixpanel-browser';

import AuthContext from '../../../context/autenticacion/authContext';
import PrefContext from '../../../context/preferencias/prefContext';

import { Button, Dropdown, Layout, Menu } from 'antd';
import classes from './NavBar.module.css';
import {
    LogoutOutlined,
    UserOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { Header } = Layout;

export const NavBar = () => {
    // extraer los valores del context
    const authContext = useContext(AuthContext);
    //Hook de mixpanel
    const mixpanel = useMixpanel();

    const { cerrarSesion, getFacturasPendientes } = authContext;
    const [usuario, setUsuario] = useState({ nombre: '', apellido: '' });
    // Conexion con el context de Preferencias del usuario
    const prefContext = useContext(PrefContext);
    const { isSidebarAbierto, abrirCerrarSidebar } = prefContext;

    useEffect(() => {
        getFacturasPendientes();
    }, []);

    const sidebarButtonHandler = () => {
        abrirCerrarSidebar();
    };

    useEffect(() => {
        const nombre = localStorage.getItem('nombre');
        const apellido = localStorage.getItem('apellido');
        setUsuario((prev) => ({ ...prev, nombre, apellido }));
    }, []);

    const sidebarButton = React.createElement(
        isSidebarAbierto ? MenuFoldOutlined : MenuUnfoldOutlined,
        {
            className: classes.sidebarButton,
            onClick: sidebarButtonHandler,
        },
    );

    const cerrarSesionHandler = () => {
        cerrarSesion();
        //Event log: Click en cerrar sesión
        mixpanel.track('click_on_logout');
    };

    const menu = (
        <Menu>
            <Menu.Item key="0">
                <UserOutlined style={{ marginRight: '8px' }} />
                <Link to="/support/perfiles"> Ir a seleccionar perfiles </Link>
            </Menu.Item>
            {/* <Menu.Item key="1">
                <LockOutlined style={{marginRight: '8px'}} />
                <Link to={`/cambiar-credenciales/${token}`}>Cambiar contraseña</Link>
            </Menu.Item> */}
            <Menu.Divider />
            <Menu.Item key="3" onClick={cerrarSesionHandler}>
                <LogoutOutlined style={{ marginRight: '8px' }} />
                Cerrar sesión
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className={classes.header}>
            {sidebarButton}
            <Dropdown
                overlay={menu}
                trigger={['click']}
                className={classes.MenuNavBar}>
                <Button
                    type="link"
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}>
                    {`${usuario.nombre} ${usuario.apellido}`}
                </Button>
            </Dropdown>
        </Header>
    );
};
