import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import classes from './Integraciones.module.css';

const { Text } = Typography;

const TiempoPedido = (props) => {
    const { tiempoEstimado } = props;

    const calculateDeliveryTime = (totalMinutes) => {
        let deliveryTime;

        if (totalMinutes >= 1440) {
            const days = Math.floor(totalMinutes / 60 / 24);

            deliveryTime = days > 1 ? `${days} días` : '1 día';
        } else if (totalMinutes >= 60) {
            const hours = Math.floor(totalMinutes / 60);
            const remainingMinutes = totalMinutes % 60;

            deliveryTime = hours > 1 ? `${hours} hrs` : '1 hr';

            if (remainingMinutes > 0) {
                deliveryTime += ` ${remainingMinutes} min`;
            }
        } else {
            deliveryTime = totalMinutes > 1 ? `${totalMinutes} min` : '1 min';
        }

        return { deliveryTime };
    };

    const { deliveryTime } = calculateDeliveryTime(tiempoEstimado);

    return (
        <div>
            <div>
                <span>
                    <Text
                        level={5}
                        strong={false}
                        className={classes.tiempoEstimado}>
                        <span>Entrega estimada: </span>
                    </Text>
                </span>
                <span>
                    <Text className={classes.tiempoEstimado}>
                        {`${deliveryTime}`}
                    </Text>
                </span>
            </div>
        </div>
    );
};

TiempoPedido.propTypes = {
    fechaPedido: PropTypes.any,
    tiempoEstimado: PropTypes.any,
    tiempoTranscurrido: PropTypes.any,
};

export default TiempoPedido;
