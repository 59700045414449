import React from 'react';

export const columnasBalance = [
    {
        title: <strong>RUC</strong>,
        dataIndex: 'ruc',
        key: 'ruc',
        align: 'center',
    },
    {
        title: <strong>Razón social</strong>,
        dataIndex: 'legal_name',
        key: 'legal_name',
        align: 'center',
    },
    {
        title: <strong>Franquicia</strong>,
        dataIndex: 'name',
        key: 'name',
        align: 'center',
    },
    {
        title: <strong>Cantidad de órdenes</strong>,
        dataIndex: 'order_quantity',
        key: 'order_quantity',
        align: 'center',
    },
    {
        title: <strong>Importe orden</strong>,
        dataIndex: 'commissions_total_orders',
        key: 'commissions_total_orders',
        align: 'center',
    },
    {
        title: <strong>Monchis Drivers</strong>,
        dataIndex: 'order_monchis_quantity_delivery_amount',
        key: 'order_monchis_quantity_delivery_amount',
        align: 'center',
    },
    {
        title: <strong>Monchis Marketplace</strong>,
        dataIndex: 'subtotalComision',
        key: 'subtotalcomision',
        align: 'center',
    },
    {
        title: <strong>Comisión a cobrar</strong>,
        dataIndex: 'commissions_total_commission',
        key: 'commissions_total_commission',
        align: 'center',
    },
];

export const columnasFacturacionBalance = [
    ...columnasBalance,
    {
        title: <strong>Periodo Corte Anterior</strong>,
        dataIndex: 'periodo_corte_anterior',
        key: 'periodo_corte_anterior',
        align: 'center',
    },
];

export const columnasResumenMonchisDrivers = [
    {
        title: (
            <span style={{ color: 'var(--rojo-monchis)' }}>
                <strong>Monchis Drivers</strong>
            </span>
        ),
        dataIndex: 'quantity_price',
        key: 'quantity_price',
    },
    {
        title: <strong>Importes</strong>,
        dataIndex: 'total',
        key: 'total',
    },
];

export const columnasResumenMonchisMarketplace = [
    {
        title: (
            <span style={{ color: 'var(--rojo-monchis)' }}>
                <strong>Monchis Marketplace</strong>
            </span>
        ),
        dataIndex: 'comisionesPorcentaje',
        key: 'comisionesporcentaje',
    },
    {
        title: <strong>Importes</strong>,
        dataIndex: 'totalMontoComisiones',
        key: 'totalMontoComisiones',
    },
];

export const columnasResumenImportes = [
    {
        title: (
            <span style={{ color: 'var(--rojo-monchis)' }}>
                <strong>Resumen</strong>
            </span>
        ),
        dataIndex: 'resumenPagos',
        key: 'resumen',
    },
    {
        title: (
            <span style={{ color: 'var(--rojo-monchis)' }}>
                <strong>Importes</strong>
            </span>
        ),
        dataIndex: 'totalResumenPagos',
        key: 'totalResumen',
    },
];

export const requestHistoryColumns = [
    {
        title: 'ID de Franquicia',
        dataIndex: 'franchise_society_id',
        key: 'franchise_society_id',
    },
    {
        title: 'Franquicias',
        dataIndex: 'franchise_name',
        key: 'franchise_name',
    },
    {
        title: 'Razón Social',
        dataIndex: 'legal_name',
        key: 'legal_name',
    },
    {
        title: 'CI / RUC',
        dataIndex: 'ruc',
        key: 'ruc',
    },
    {
        title: 'Importe Online',
        dataIndex: 'amount_online_payment',
        key: 'amount_online_payment',
    },
    {
        title: 'ADMIN COMISION',
        dataIndex: 'total_commission',
        key: 'total_commission',
    },
    {
        title: 'Retención Pendiente CORTE ANTERIOR',
        dataIndex: 'amount_previous_balance',
        key: 'amount_previous_balance',
    },
    {
        title: 'A RETENER FAVOR MONCHIS',
        dataIndex: 'debit_amount',
        key: 'debit_amount',
    },
    {
        title: 'NETO A DEVOLVER A FAVOR COMERCIO',
        dataIndex: 'amount_to_transfer',
        key: 'amount_to_transfer',
    },
    {
        title: 'A RETENER CORTE SIGUIENTE',
        dataIndex: 'balance',
        key: 'balance',
    },
];
