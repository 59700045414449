import { useCallback, useContext, useEffect, useState } from 'react';
import { message } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';

import clienteAxios from '../../config/axios';
import { urls } from '../../constants';
import { formatoFecha } from '../utils-components/Date';
import getErrorMessage from '../utils-components/getErrorMessage';

import UtilidadesContext from '../../context/utilidades/UtilidadesContext';

const useFranchiseAndBranchStatus = () => {
    const mixpanel = useMixpanel();

    const { obtenerFranquicias, obtenerSucursales, getFranchiseStatements } =
        useContext(UtilidadesContext);
    const {
        getBranchStatements: getReasonsToScheduleDisable,
        branchStates: reasonsList,
    } = useContext(UtilidadesContext);

    const [loadingBrands, setLoadingBrands] = useState(false);

    const [franchisesList, setFranchisesList] = useState([]);
    const [selectedFranchises, setSelectedFranchises] = useState([]);

    const [branchesList, setBranchesList] = useState([]);
    const [selectedBranches, setSelectedBranches] = useState([]);

    const [audiencesList, setAudiencesList] = useState([]);
    const [selectedAudience, setSelectedAudience] = useState();

    const [branchesEnabled, setBranchesEnabled] = useState(0);
    const [branchesDisabled, setBranchesDisabled] = useState(0);
    const [branchesWithDrivers, setBranchesWithDrivers] = useState(0);
    const [manuallyEnabledBranches, setManuallyEnabledBranches] = useState(0);

    const [brandsList, setBrandsList] = useState([]);
    const [brandsListPage, setBrandsListPage] = useState(1);
    const [brandsListTotalRows, setBrandsListTotalRows] = useState(0);

    const [reasonsToScheduleDisable, setReasonsToScheduleDisable] = useState(
        [],
    );

    const getFranchisesApi = useCallback(async () => {
        const franchises = await obtenerFranquicias();
        return franchises;
    }, [obtenerFranquicias]);

    const getFranchisesList = useCallback(async () => {
        // get data
        setLoadingBrands(true);
        const franchises = await getFranchisesApi();
        setLoadingBrands(false);
        // store data
        setFranchisesList(franchises);
    }, [getFranchisesApi]);

    const getBranchesApi = useCallback(
        async (franchiseId) => {
            const branches = await obtenerSucursales(franchiseId);
            return branches;
        },
        [obtenerSucursales],
    );

    const getBranchesList = useCallback(
        async (franchisesIds) => {
            if (franchisesIds?.length) {
                // extract new franchise
                const franchisesOnBranches = branchesList?.length
                    ? [
                          ...new Set(
                              branchesList?.map((item) => item?.franchiseId),
                          ),
                      ]
                    : [];
                const newFranchiseIdArray = franchisesIds.filter(
                    (x) => !franchisesOnBranches.includes(x),
                );
                const selectedFranchiseId = newFranchiseIdArray[0];

                if (selectedFranchiseId) {
                    // get data from new franchise
                    setLoadingBrands(true);
                    const branches = await getBranchesApi(selectedFranchiseId);
                    setLoadingBrands(false);
                    const branchesWithFranchise = branches.map((item) => ({
                        ...item,
                        franchiseId: selectedFranchiseId,
                    }));

                    // store data
                    setBranchesList((currBranchesList) => [
                        ...currBranchesList,
                        ...branchesWithFranchise,
                    ]);
                } else {
                    // remove franchise from braches list
                    setBranchesList((currBranchesList) => {
                        const removedFranchiseArray =
                            franchisesOnBranches.filter(
                                (x) => !franchisesIds.includes(x),
                            );
                        const removedFranchiseId = removedFranchiseArray[0];

                        return currBranchesList.filter(
                            (item) => item.franchiseId !== removedFranchiseId,
                        );
                    });
                }
            } else {
                setBranchesList([]);
            }
        },
        [branchesList, getBranchesApi],
    );

    const updateSelectedFranchises = (newState) => {
        setSelectedFranchises(newState);
        updateSelectedAudicences(null);
        getBranchesList(newState);
    };

    const updateSelectedBranches = (newState) => {
        setSelectedBranches(newState.map((item) => JSON.parse(item)));
    };

    const updateSelectedAudicences = (newState) => {
        setSelectedAudience(newState);
    };

    const getAudiencesApi = useCallback(async () => {
        let allAudiences = [];
        let currentPage = 1;
        const itemsPerPage = 35;
        let hasMorePages = true;

        try {
            while (hasMorePages) {
                const params = {
                    type: 'FRANCHISES',
                    page: currentPage,
                    items_per_page: itemsPerPage,
                };

                const response = await clienteAxios({
                    method: 'GET',
                    baseURL: urls.REACT_APP_BACKEND_URL_C,
                    url: 'get_audiencies',
                    params: params,
                });

                if (!response?.data?.success)
                    throw new Error('Failed to fetch data');

                const audiencies = response?.data?.data?.audiencies.map(
                    (item) => ({
                        id: item.id,
                        name: item.name,
                    }),
                );

                allAudiences = [...allAudiences, ...audiencies];

                hasMorePages = audiencies.length === itemsPerPage;
                currentPage++;
            }

            return allAudiences;
        } catch (error) {
            const errorMessage = getErrorMessage(
                error,
                'No hemos podido obtener el listado de audiencias',
            );
            message.error(errorMessage);
            return [];
        }
    }, []);

    const getAudiencesList = useCallback(async () => {
        // get data
        setLoadingBrands(true);
        const audiences = await getAudiencesApi();
        setLoadingBrands(false);
        // store data
        setAudiencesList(audiences);
    }, [getAudiencesApi]);

    const getBrandsStateAPI = async (params) => {
        const response = await getFranchiseStatements(
            params?.franchise_id,
            params?.branch_id,
            params?.page,
            undefined,
            undefined,
            undefined,
            params?.export_to,
            undefined,
            params?.audience_id,
        );
        return response;
    };

    const getBrandsParams = (page) => {
        // prepare params
        const params = {
            page,
        };
        if (selectedFranchises?.length) {
            params.franchise_id = selectedFranchises.toString();
            if (selectedBranches?.length) {
                params.branch_id = selectedBranches
                    .map((item) => item.id)
                    .toString();
            }
        } else if (selectedAudience) {
            params.audience_id = selectedAudience.toString();
        }

        return params;
    };

    const getBrandsList = async (page = 1) => {
        // prepare params
        const params = getBrandsParams(page);
        if (!params) return;

        if (selectedFranchises?.length) {
            params.franchise_id = selectedFranchises.toString();
            if (selectedBranches?.length) {
                params.branch_id = selectedBranches
                    .map((item) => item.id)
                    .toString();
            }
        } else if (selectedAudience) {
            params.audience_id = selectedAudience.toString();
        }

        // call api
        setBrandsList([]);
        let brandsListResponse = [];
        try {
            setLoadingBrands(true);
            brandsListResponse = await getBrandsStateAPI(params);
            setLoadingBrands(false);
        } catch (error) {
            message.error(
                'Hubo un problema al obtener la lista de estado de marcas',
            );
            return;
        }

        // transform data
        let brandsListArray = brandsListResponse?.franchise_states || [];
        const totalRows = brandsListResponse?.total_rows;
        const brachesEnabled = brandsListResponse?.count_branches_enabled;
        const brachesDisabled = brandsListResponse?.count_branches_disabled;
        const brachesWithDrivers = brandsListResponse?.count_branches_drivers;
        const manuallyEnabledBranches = brandsListResponse?.count_manual;

        brandsListArray = brandsListArray?.map((franchise) => ({
            id: franchise?.id,
            key: franchise?.franchise_id,
            franchiseId: franchise?.franchise_id,
            branchId: franchise?.branch_id,
            lastState: franchise?.ultima_actualizacion
                ? formatoFecha(franchise?.ultima_actualizacion)
                      .split(' ')
                      .join(' - ')
                : '-',
            franchise: franchise?.franquicia,
            branch: franchise?.sucursal,
            branchStateId: franchise?.estado_sucursal_id,
            reactivate:
                franchise?.low_type === 'Manual'
                    ? 'Manualmente'
                    : formatoFecha(franchise?.hora_subida)
                          .split(' ')
                          .join(' - '),
            reason: franchise?.motivo ?? '-',
            defaultChecked:
                franchise?.branch_enabled === true ||
                franchise?.branch_enabled === null
                    ? true
                    : false,
            inHigh: franchise?.en_alta,
            lowType: franchise?.low_type,
            branchEnabled: franchise?.branch_enabled,
            dateToSort: franchise?.ultima_actualizacion,
        }));

        // store data
        setBranchesEnabled(brachesEnabled);
        setBranchesDisabled(brachesDisabled);
        setBranchesWithDrivers(brachesWithDrivers);
        setManuallyEnabledBranches(manuallyEnabledBranches);

        setBrandsList(brandsListArray);
        setBrandsListPage(params.page);
        setBrandsListTotalRows(totalRows);
    };

    const getExcelFile = async () => {
        // prepare params
        const params = getBrandsParams();
        if (!params) return;
        params.export_to = 'excel';
        params.page = undefined;

        // get data
        setLoadingBrands(true);
        const excelFile = await getBrandsStateAPI(params);
        setLoadingBrands(false);

        // store
        const excelUrl = excelFile?.fileUrl;
        window.open(excelUrl, 'Download');
    };

    const enableDisableBranchesApi = async (bodyReq) => {
        const params = {
            franchise_ids: bodyReq.franchiseIds,
            branch_ids: bodyReq.branchIds,
            schedule_ids: bodyReq?.scheduleIds,
            enabled: bodyReq.enabled,
            audience_id: bodyReq?.audienceId,
            all_rows: bodyReq.allRows,
        };
        try {
            const response = await clienteAxios.put(
                `${urls.REACT_APP_BACKEND_URL_SV_RECEPTION}/up_down_franchise_branch`,
                params,
            );

            message.success(
                response?.message ||
                    'Proceso realizado con éxito. Si no se visualizan las modificaciones, por favor repetí tu búsqueda.',
            );
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.message ||
                error ||
                'Ha habido un error';

            message.error(errorMessage);

            mixpanel.track('franchise_up_or_down_error', { errorMessage });
        }
    };

    const enableManuallyBranches = async (
        franchiseId,
        branchId,
        scheduleId,
    ) => {
        const allRows = !branchId;
        // prepare params or body
        const bodyReq = {
            franchiseIds: [],
            branchIds: [],
            enabled: true,
            scheduleIds: [],
            audienceId: undefined,
            allRows: allRows,
        };
        if (selectedBranches?.length) {
            bodyReq.franchiseIds = selectedFranchises;
            bodyReq.branchIds = selectedBranches.map((item) => item.id);
            bodyReq.allRows = false;
        } else if (selectedFranchises?.length) {
            bodyReq.franchiseIds = selectedFranchises;
        } else {
            bodyReq.audienceId = +selectedAudience;
        }
        if (!allRows) {
            bodyReq.franchiseIds = [franchiseId];
            bodyReq.branchIds = [branchId];
            if (scheduleId) {
                bodyReq.scheduleIds = [scheduleId.toString()];
            }
        }
        // call api
        setLoadingBrands(true);
        await enableDisableBranchesApi(bodyReq);

        // refresh brand list
        const page = brandsListPage;
        await getBrandsList(page);

        setLoadingBrands(false);
    };

    const enableAllScheduledApi = async (bodyReq) => {
        const { franchiseIds, branchIds, allRows, scheduleIds, audienceId } =
            bodyReq;
        const body = {
            franchise_ids: franchiseIds,
            branch_ids: branchIds,
            schedule_ids: scheduleIds,
            enabled: true,
            all_rows: allRows,
            upAll: true,
            audience_id: audienceId,
        };
        try {
            const response = await clienteAxios.put(
                `${urls.REACT_APP_BACKEND_URL_SV_RECEPTION}/up-all-scheduled`,
                body,
            );

            message.success(
                response?.message ||
                    'Todas las franquicias y sucursales habilitadas. Si no se visualizan las modificaciones, por favor repetí tu búsqueda.',
            );
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.message ||
                error ||
                'Ha habido un error. Intentá de nuevo en unos momentos.';
            message.error(errorMessage);
        }
    };

    const enableScheduledBranches = async (branchId) => {
        const allRows = !branchId;
        const bodyReq = {
            franchiseIds: [],
            branchIds: [],
            enabled: true,
            allRows,
            scheduleIds: [],
            audienceId: parseInt(selectedAudience),
        };
        if (selectedBranches?.length) {
            bodyReq.franchiseIds = selectedFranchises;
            bodyReq.branchIds =
                selectedBranches && selectedBranches.map((item) => item.id);
        } else if (selectedFranchises?.length) {
            bodyReq.franchiseIds = selectedFranchises;
        } else {
            bodyReq.branchIds = [];
            bodyReq.franchiseIds = [];
        }

        setLoadingBrands(true);
        await enableAllScheduledApi(bodyReq);

        // refresh brand list
        const page = brandsListPage;
        await getBrandsList(page);
        setLoadingBrands(false);
    };

    const scheduleDisableBranchesApi = async (scheduleData) => {
        const bodyReq = {
            ids: scheduleData?.ids,
            time: scheduleData?.time,
            time_down: null,
            product_id: scheduleData?.product_id,
            branch_state_id: scheduleData?.branch_state_id,
            reason: scheduleData?.reason,
            audience_id: scheduleData?.audienceId,
            all_rows: scheduleData?.all_rows,
            axiosBaseURL: urls.REACT_APP_BACKEND_URL_SV_RECEPTION,
        };
        try {
            const response = await clienteAxios({
                method: 'POST',
                baseURL: urls.REACT_APP_BACKEND_URL_SV_RECEPTION,
                url: 'up_down_branch_product',
                data: bodyReq,
            });

            message.success(response?.message || 'Proceso realizado con éxito');
        } catch (error) {
            const errorMessage =
                error?.response?.data?.message ||
                error?.message ||
                error ||
                'Lo sentimos, no hemos podido actualizar el estado';
            message.error(errorMessage);
            mixpanel.track('franchise_edit_error', { errorMessage });
        }
    };

    const disableBranchesApiHandler = async (bodyReq, isDisablingManually) => {
        if (isDisablingManually) {
            await enableDisableBranchesApi({
                franchiseIds: bodyReq.franchiseIds,
                branchIds: bodyReq.branchIds,
                enabled: bodyReq.enabled,
                scheduleIds: bodyReq?.scheduleIds,
                audienceId: bodyReq?.audienceId,
                allRows: bodyReq.allRows,
            });
        } else {
            await scheduleDisableBranchesApi(bodyReq);
        }
    };

    const getBrandsEditScheduleBackendDateFormat = (userHours) => {
        const dateObj = new Date();

        if (userHours) {
            dateObj.setHours(dateObj.getHours() + userHours);
        }

        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        const hours = dateObj.getHours();
        const minutes = dateObj.getMinutes();
        const seconds = dateObj.getSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const updateScheduleDisableBranchApi = async (updateData) => {
        const bodyReq = {
            branch_state_id: updateData.branch_state_id,
            reason: updateData.reason,
            time_down: updateData.time_down,
            time_up: updateData.time_up,
        };
        const scheduleId = updateData.scheduleId;

        try {
            const response = await clienteAxios({
                method: 'PUT',
                baseURL: urls.REACT_APP_BACKEND_URL_SV_RECEPTION,
                url: `update_down_franchise_branch/${scheduleId}`,
                data: bodyReq,
            });

            if (!response?.data?.success)
                throw 'Hubo un problema con la actualización de esta programación';

            message.success(
                response?.data?.message || 'Registro actualizado correctamente',
            );
        } catch (error) {
            const checkForMessages =
                typeof error?.data?.data === 'object'
                    ? error?.data?.message
                    : error?.data?.data;

            message.error(
                error?.data?.data?.data ||
                    checkForMessages ||
                    error?.data?.data?.detail ||
                    error?.data?.message ||
                    error?.data ||
                    'Ocurrió un problema al actualizar los datos',
            );
        }
    };

    const disableBranches = async (disableBranchData) => {
        let branchId = null;
        if (disableBranchData?.branchId) {
            branchId = disableBranchData.branchId;
        } else if (selectedBranches?.length) {
            branchId = selectedBranches.map((item) => item.id);
        }
        const scheduleId = disableBranchData?.scheduleId;
        const isDisablingManually = disableBranchData.isManual;
        const allRows = !branchId;

        // call api
        setLoadingBrands(true);

        const bodyReq = {
            audienceId: undefined,
        };
        if (selectedAudience) {
            bodyReq.audienceId = +selectedAudience;
        }

        const userIsEditingDisableStatus = disableBranchData?.isEdit;
        if (userIsEditingDisableStatus) {
            // prepare reqBody
            bodyReq.scheduleId = disableBranchData.scheduleId;
            bodyReq.branch_state_id = disableBranchData.reasonId;
            bodyReq.reason = disableBranchData.customUserReason;
            bodyReq.time_down = getBrandsEditScheduleBackendDateFormat();
            bodyReq.time_up = getBrandsEditScheduleBackendDateFormat(
                disableBranchData.hours,
            );

            // call api
            setLoadingBrands(true);
            await updateScheduleDisableBranchApi(bodyReq);
        } else {
            if (isDisablingManually) {
                // TODO: Brands - Add audience ID to disable branches API
                // prepare body request
                bodyReq.franchiseIds = selectedFranchises;
                bodyReq.branchIds = [];
                bodyReq.enabled = false;
                bodyReq.scheduleIds = [];

                bodyReq.allRows = allRows;

                if (allRows) {
                    bodyReq.branchIds = selectedBranches.map((item) => item.id);
                } else {
                    if (branchId?.length) {
                        bodyReq.branchIds = [...branchId];
                    } else {
                        bodyReq.branchIds = [branchId];
                    }
                    if (scheduleId) {
                        bodyReq.scheduleIds = [scheduleId.toString()];
                    }
                }

                // call api
                await disableBranchesApiHandler(bodyReq, isDisablingManually);
            } else {
                // prepare body request
                bodyReq.all_rows = allRows;
                bodyReq.ids = [];
                const hours = disableBranchData.hours;
                bodyReq.time = `${
                    hours < 10 ? `0${hours}:00:00` : `${hours}:00:00`
                }`;
                bodyReq.branch_state_id = disableBranchData.reasonId;
                bodyReq.reason = disableBranchData.customUserReason;

                const addFranchiseBranchIdsToBodyReq = () => {
                    if (selectedBranches?.length) {
                        bodyReq.ids = selectedBranches.map((branch) => ({
                            franchise_id: branch.franchiseId,
                            branch_id: branch.id,
                        }));
                    } else {
                        bodyReq.ids = selectedFranchises.map((franchiseId) => ({
                            franchise_id: franchiseId,
                        }));
                    }
                };

                if (allRows) {
                    addFranchiseBranchIdsToBodyReq();
                } else {
                    const isSingleBranch =
                        disableBranchData.franchiseId &&
                        disableBranchData.branchId;
                    if (isSingleBranch) {
                        bodyReq.ids = [
                            {
                                franchise_id: disableBranchData.franchiseId,
                                branch_id: disableBranchData.branchId,
                            },
                        ];
                    } else {
                        addFranchiseBranchIdsToBodyReq();
                    }
                    bodyReq.product_id = null;
                }

                // call api
                await disableBranchesApiHandler(bodyReq, isDisablingManually);
            }
        }

        // refresh brand list
        const page = brandsListPage;
        await getBrandsList(page);

        setLoadingBrands(false);
    };

    const updateReasonsToScheduleDisable = useCallback((reasonsList) => {
        // Filter out 'OPEN' and 'Otros' reasons
        const filteredReasons = reasonsList.filter(
            (reason) => reason.id !== 5 && reason.id !== 6,
        );

        // Give format to reasons and sort
        const formattedReasons = filteredReasons
            .map((item) => ({
                id: item.id,
                name: item.name,
                userReason: item.name,
            }))
            .sort((a, b) => {
                return a.id - b.id;
            });

        // Store data
        setReasonsToScheduleDisable(formattedReasons);
    }, []);

    useEffect(() => {
        if (reasonsList?.length && !reasonsToScheduleDisable?.length) {
            updateReasonsToScheduleDisable(reasonsList);
        }
    }, [reasonsList, reasonsToScheduleDisable, updateReasonsToScheduleDisable]);

    return {
        loadingBrands,

        franchisesList,
        getFranchisesList,

        selectedFranchises,
        updateSelectedFranchises,

        branchesList,
        updateSelectedBranches,

        audiencesList,
        getAudiencesList,
        updateSelectedAudicences,

        branchesEnabled,
        branchesDisabled,
        branchesWithDrivers,
        manuallyEnabledBranches,

        getBrandsList,
        brandsList,
        brandsListPage,
        brandsListTotalRows,

        getExcelFile,

        enableManuallyBranches,
        enableScheduledBranches,
        disableBranches,

        getReasonsToScheduleDisable,
        reasonsToScheduleDisable,
    };
};

export default useFranchiseAndBranchStatus;
