import React, { useContext } from 'react';

import {
    CreditCardOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Card, Button, Modal } from 'antd';
import PropTypes from 'prop-types';
import clases from '../pagoOnline.module.css';
import PagoOnlineContext from '../../../../context/pagoOnline/PagoOnlineContext';

const ListarTarjetasUsuario = ({ datoTarjeta }) => {
    // extraer proyectos de state inicial
    const pagoOnlineContext = useContext(PagoOnlineContext);
    const { obtenerTarjetaEliminar, idPersona } = pagoOnlineContext;

    // extraer datos de datotarjeta
    const {
        cardBrand,
        cardMaskedNumber,
        cardType,
        expirationDate,
        aliasToken,
    } = datoTarjeta;

    // se extrae los numeros de la card de a 4 caracteres
    let numeroTarjetaPrimera = cardMaskedNumber.slice(0, 4);
    let numeroTarjetaSegunda = cardMaskedNumber.slice(4, 8);
    let numeroTarjetaTercera = cardMaskedNumber.slice(8, 12);
    let numeroTarjetaCuarta = cardMaskedNumber.slice(12, 16);

    // modal para remover tarjeta
    const confirmar = () => {
        Modal.confirm({
            title: 'Estas seguro/a que deseas eliminar la tarjeta?',
            icon: <ExclamationCircleOutlined />,
            okText: 'confirmar',
            cancelText: 'cancelar',
            onOk: () => obtenerTarjetaEliminar(idPersona, aliasToken),
        });
    };

    return (
        <Card className={clases.cardTarjeta} cover>
            <div className={clases.cabeceraCard}>
                <div className={clases.cabeceraCard_cuadrado}>
                    <div className={clases.iconoTarjeta}>
                        <CreditCardOutlined />
                    </div>
                    <p className={clases.fontSizeTextoTipo}>
                        Tarjeta de {cardType === 'credit' && 'Crédito'}
                        {cardType === 'debit' && 'Débito'}
                    </p>
                </div>
                <div className={clases.fontSizeTextos}>
                    {cardBrand.slice(0, 35)}
                </div>
            </div>
            <div className={clases.numerosTarjeta}>
                <div
                    className={
                        clases.nroTarjeta
                    }>{`${numeroTarjetaPrimera} ${numeroTarjetaSegunda} ${numeroTarjetaTercera} ${numeroTarjetaCuarta}`}</div>
            </div>
            <div className={clases.datosTarjeta}>
                <p>Vencimiento: {expirationDate}</p>
            </div>

            <div className={clases.boton}>
                <Button
                    className={clases.botonRemover}
                    onClick={() => confirmar()}
                    danger>
                    Remover
                </Button>
            </div>
        </Card>
    );
};

export default ListarTarjetasUsuario;

ListarTarjetasUsuario.propTypes = {
    datoTarjeta: PropTypes.object,
};
