import {
    USUARIOS_GUARDAR_USUARIO_SELECCIONADO,
    USUARIOS_LISTA_TARJETAS,
    USUARIOS_LOADING_LISTA_TARJETAS,
    USUARIOS_LOADING_TARJETA,
    OBTENER_DIRECCION_USUARIO,
    USUARIOS_LOADING_LISTA_DIRECCION,
    USUARIOS_LOADING_EDITAR_DIRECCION,
    USUARIOS_LOADING_NUEVA_DIRECCION,
    USUARIOS_LOADING_ELIMINAR_DIRECCION,
    USUARIOS_LOADING_LISTA_PEDIDO,
    OBTENER_LISTADO_PEDIDOS,
    USUARIOS_GUARDAR_INFO,
    USUARIOS_MODAL_CUPON_CONDICIONES,
    USUARIOS_TAB_SELECCIONADA,
    USUARIOS_PATHNAME,
} from './index';

export const initialState = {
    usuarioSeleccionado: undefined,
    listaTarjetas: [],
    ordersTable: undefined,
    loadingListaTarjetas: false,
    loadingTarjetas: false,
    loadingDireccionEditar: false,
    loadingDireccionNuevo: false,
    loadingDireccionEliminar: false,
    loadingListaPedido: false,
    direccionUsuario: [],
    loadingDireccion: false,
    infoUsuario: [],

    modalCondicionesVisible: false,
    tabSeleccionada: '1',

    pathname: '',
};

const UsuariosReducer = (state = initialState, action) => {
    switch (action.type) {
        case USUARIOS_TAB_SELECCIONADA:
            return {
                ...state,
                tabSeleccionada: action.payload.tabSeleccionada,
            };
        case USUARIOS_GUARDAR_USUARIO_SELECCIONADO:
            return {
                ...state,
                usuarioSeleccionado: action.payload.usuarioSeleccionado,
            };
        case USUARIOS_LISTA_TARJETAS:
            return {
                ...state,
                listaTarjetas: action.payload.listaTarjetas,
            };
        case USUARIOS_LOADING_LISTA_TARJETAS:
            return {
                ...state,
                loadingListaTarjetas: !state.loadingListaTarjetas,
            };
        case USUARIOS_LOADING_TARJETA:
            return {
                ...state,
                loadingTarjetas: !state.loadingTarjetas,
            };
        case USUARIOS_LOADING_LISTA_DIRECCION:
            return {
                ...state,
                loadingDireccion: !state.loadingDireccion,
            };
        case USUARIOS_LOADING_NUEVA_DIRECCION:
            return {
                ...state,
                loadingDireccionNuevo: !state.loadingDireccionNuevo,
            };
        case USUARIOS_LOADING_EDITAR_DIRECCION:
            return {
                ...state,
                loadingDireccionEditar: !state.loadingDireccionEditar,
            };
        case USUARIOS_LOADING_ELIMINAR_DIRECCION:
            return {
                ...state,
                loadingDireccionEliminar: !state.loadingDireccionEliminar,
            };
        case OBTENER_DIRECCION_USUARIO:
            return {
                ...state,
                direccionUsuario: action.payload,
            };
        case OBTENER_LISTADO_PEDIDOS:
            return {
                ...state,
                ordersTable: action.payload.table,
            };
        case USUARIOS_LOADING_LISTA_PEDIDO:
            return {
                ...state,
                loadingListaPedido: !state.loadingListaPedido,
            };
        case USUARIOS_GUARDAR_INFO:
            return {
                ...state,
                infoUsuario: action.payload.infoUsuario,
            };
        case USUARIOS_MODAL_CUPON_CONDICIONES:
            return {
                ...state,
                modalCondicionesVisible: !state.modalCondicionesVisible,
            };
        case USUARIOS_PATHNAME:
            return {
                ...state,
                pathname: action.payload.pathname,
            };

        default:
            return state;
    }
};

export default UsuariosReducer;
