import React, { useEffect, useState } from 'react';
import axios from '../../../config/axios';
import { Spin, message } from 'antd';
import { useParams } from 'react-router-dom';
import './ResumenCorte.css';

const ResumenCorte = () => {
    const params = useParams();
    const [obteniendoDatos, setObteniendoDatos] = useState(true);
    const [HTML, setHTML] = useState(null);

    useEffect(() => {
        obtenerTuFactura(params.token);
    }, []);

    const obtenerTuFactura = async (token) => {
        await axios
            .get(`/get_finance_token?token=${token}&getBalanceHTML=true`)
            .then((response) => {
                setHTML(response?.data?.data?.balanceHTML);
                setObteniendoDatos(false);
            })
            .catch((error) => {
                message.error(error.response?.data?.message || error);
            });
    };

    return (
        <>
            {obteniendoDatos ? (
                <div className="spinner-container">
                    <Spin
                        tip="Obteniendo resumen de corte..."
                        spinning={obteniendoDatos}
                    />
                </div>
            ) : (
                <div dangerouslySetInnerHTML={{ __html: HTML }} />
            )}
        </>
    );
};

export default ResumenCorte;
