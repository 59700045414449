import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Form,
    message,
    Row,
    Col,
    Card,
    Input,
    InputNumber,
    Select,
    Space,
    DatePicker,
    Checkbox,
    Typography,
} from 'antd';
import FormItemAsignarUsuarios from '../../../../molecules/ProgramaRecomensa/Cupones/FormItemAsignarUsuarios';
import CuponesContext from '../../../../../context/programaRecompensa/cupones/CuponesContext';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import ABMLayout from '../../../../../layouts/ABMLayout';
import FormItemUsarEn from '../../../../molecules/ProgramaRecomensa/Cupones/FormItemUsarEn';
import FormItemCustomizeNotifications from '../../../../molecules/ProgramaRecomensa/Cupones/FormItemCustomizeNotifications';
import PopUpConfirmCupon from '../../../../molecules/ProgramaRecomensa/Cupones/PopUpConfirmCupon';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Title } = Typography;

const initialValues = {
    limitecupon: 1,
    cantidadveces: 1,
    tipo: 'fixed',
    estado: 'true',
    selectPersona: 'id',
};

const idBancard = '3';

const CuponesForm = ({ mode }) => {
    let history = useHistory();

    const {
        nuevoCupon,
        editarCupon,
        obtenerIdUsuarioCupon,
        restablecerUsuarios,
        guardarUsuariosSelecionados,
        usuariosRemover,
        loadingFormCupones,
        cuponSeleccionado,
        obtenerMotivos,
        obtenerMotivosSelect,
        obtenerFranquicias,
        obtenerCiudades,
        obtenerFamilias,
        obtenerBarrios,
        obtenerSucursales,
        obtenerProductos,
        obtenerTiposPago,
        loadingTiposPago,
        listaTiposPagos,
        obtenerTarjetas,
        listaTarjetas,
        tarjetasLoading,
        verifySMSRules,
    } = useContext(CuponesContext);

    const [cuponForm] = Form.useForm();
    const esEditar = Boolean(mode === 'EDITAR');

    const [selectCuponTipoValue, setSelectCuponTipoValue] = useState('fixed');
    const esMonto = Boolean(selectCuponTipoValue === 'fixed');

    const [esOpcionPagoOnlineBancard, setEsOpcionPagoOnlineBancard] =
        useState(false);
    const [confirmModalVisible, setConfirmModalVisible] = useState(false);

    const habilitarFechasDesdeHoy = (current) => {
        return current && current < moment().startOf('day');
    };

    useEffect(() => {
        if (esEditar && cuponSeleccionado) {
            // Obtener cupon seleccionado
            const cupon = cuponSeleccionado.cuponOriginal;

            // Extrer datos para el form
            let fechaFrom = cupon?.valid_from;
            let fechaTo = cupon?.valid_to;
            let fechaNormalizadaFrom =
                fechaFrom.substring(0, fechaFrom.indexOf('.')) +
                moment().format('Z');
            let fechaNormalizadaTo =
                fechaTo.substring(0, fechaTo.indexOf('.')) +
                moment().format('Z');
            let fechaDesde = moment(fechaNormalizadaFrom).format('YYYY-MM-DD');
            let fechaHasta = moment(fechaNormalizadaTo).format('YYYY-MM-DD');

            // Detalles de la seccion 'Condiciones de uso -> Usar en'
            const detalleCupon = cupon?.discount_code_details;

            const {
                geoZone,
                cityId,
                neighborhoodId,
                franchiseFamilyId,
                franchiseId,
                branchId,
                productId,
                listaUsarEnArr,
            } = obtenerDetalleFormItemUsarEn(detalleCupon);

            // Obtener IDs de tipos de pago
            let listaTiposPagosSeleccionadosArr = detalleCupon?.filter(
                (detalle) => detalle?.payment_type_id,
            );
            if (listaTiposPagosSeleccionadosArr?.length) {
                // Convertir tipos de pago a strings
                listaTiposPagosSeleccionadosArr =
                    listaTiposPagosSeleccionadosArr?.map((detalle) =>
                        detalle?.payment_type_id.toString(),
                    );
                // Remover tipos de pago duplicados
                listaTiposPagosSeleccionadosArr = [
                    ...new Set(listaTiposPagosSeleccionadosArr),
                ];
                // Habilitar select de tarjetas
                const tieneBancard =
                    listaTiposPagosSeleccionadosArr.includes(idBancard);
                setEsOpcionPagoOnlineBancard(tieneBancard);
            }

            // Obtener IDs de tarjetas
            let listaTarjetasSeleccionadasArr = detalleCupon?.filter(
                (detalle) => detalle?.card_id,
            );
            if (listaTarjetasSeleccionadasArr?.length) {
                // Convertir tarjetas a strings
                listaTarjetasSeleccionadasArr =
                    listaTarjetasSeleccionadasArr?.map(
                        (detalle) => detalle?.card_id,
                    );
            }

            // El campo de 'Usuarios asignados' se maneja externamente al formulario
            let listaUsuarios =
                cupon?.users?.map((usuario) => ({
                    ...usuario,
                    nombreCompleto:
                        usuario.first_name + ' ' + usuario.last_name,
                    idUser: usuario.id,
                })) || [];
            guardarUsuariosSelecionados(listaUsuarios);

            // Establecer tipo de cupon
            setSelectCuponTipoValue(cupon?.type);

            // Establecer datos en el form
            cuponForm.setFieldsValue({
                id: cupon?.id,
                codigo: cupon?.code,
                monto: parseInt(cupon?.amount),
                limitecupon: cupon?.usage_limit,
                cantidadveces: cupon?.max_per_user,
                franquicia: cupon?.franchise,
                tipo: cupon?.type,
                estado: cupon?.enabled.toString(),
                validezfecha: [moment(fechaDesde), moment(fechaHasta)],
                motivo: cupon?.reason_id.toString(),
                motivoDescripcion: cupon?.description,
                geo_zone: geoZone ? geoZone : undefined,
                city_id: cityId ? cityId : undefined,
                neighborhood_id: neighborhoodId ? neighborhoodId : undefined,
                franchise_family_id: franchiseFamilyId
                    ? franchiseFamilyId
                    : undefined,
                franchise_id: franchiseId ? franchiseId : undefined,
                branch_id: branchId ? branchId : undefined,
                product_id: productId ? productId : undefined,
                usarEn: listaUsarEnArr,
                usarEnOriginal: detalleCupon,
                free_delivery: Boolean(cupon?.free_delivery),
                self_applicable: Boolean(cupon?.self_applicable),
                payment_type: listaTiposPagosSeleccionadosArr,
                card_id: listaTarjetasSeleccionadasArr,
                minimum_amount: cupon?.minimum_amount || 0,
                maximum_amount: cupon?.maximum_amount || 0,
            });
        } else if (!esEditar) {
            // Establecer datos en el form
            cuponForm.setFieldsValue({
                codigo: generarCodigoCuponAleatorio(),
                minimum_amount: 0,
            });
            restablecerUsuarios();
        }
        obtenerMotivos();
        obtenerTiposPago();
        obtenerTarjetas();
    }, [cuponForm, mode, cuponSeleccionado]);

    const obtenerDetalleFormItemUsarEn = (detalleCupon) => {
        let listaUsarEnArr = [];
        let geoZone = '';
        let cityId = '';
        let neighborhoodId = '';
        let franchiseFamilyId = '';
        let franchiseId = '';
        let branchId = '';
        let productId = '';

        if (detalleCupon?.length) {
            detalleCupon.forEach((item, idx) => {
                let id, item1, item2, item3;
                if (item?.geo_zone) {
                    geoZone += concatenarDetalleFormItemUsarEn(
                        item?.geo_zone,
                        idx,
                        detalleCupon,
                    );
                    franchiseFamilyId += concatenarDetalleFormItemUsarEn(
                        item?.franchise_family_id,
                        idx,
                        detalleCupon,
                    );
                    id = `${item?.geo_zone.toString()}${
                        item?.franchise_family_id
                    }`;
                    item1 = {
                        nombre: 'Zona Geográfica',
                        valor: item?.geo_zone,
                        tipo: 'Zona Geográfica',
                    };
                    item2 = {
                        nombre: '',
                        valor: item?.franchise_family_id,
                        tipo: 'Familia',
                    };
                }
                if (item?.city_id) {
                    cityId += concatenarDetalleFormItemUsarEn(
                        item?.city_id,
                        idx,
                        detalleCupon,
                    );
                    franchiseFamilyId += concatenarDetalleFormItemUsarEn(
                        item?.franchise_family_id,
                        idx,
                        detalleCupon,
                    );
                    id = `${item?.city_id}${item?.franchise_family_id}`;
                    item1 = {
                        nombre: '',
                        valor: item?.city_id,
                        tipo: 'Ciudad',
                    };
                    item2 = {
                        nombre: '',
                        valor: item?.franchise_family_id,
                        tipo: 'Familia',
                    };
                }
                if (item?.neighborhood_id) {
                    neighborhoodId += concatenarDetalleFormItemUsarEn(
                        item?.neighborhood_id,
                        idx,
                        detalleCupon,
                    );
                    franchiseFamilyId += concatenarDetalleFormItemUsarEn(
                        item?.franchise_family_id,
                        idx,
                        detalleCupon,
                    );
                    id = `${item?.neighborhood_id}${item?.franchise_family_id}`;
                    item1 = {
                        nombre: '',
                        valor: item?.neighborhood_id,
                        tipo: 'Barrio',
                    };
                    item2 = {
                        nombre: '',
                        valor: item?.franchise_family_id,
                        tipo: 'Familia',
                    };
                }
                if (item?.franchise_family_id && item?.franchise_id) {
                    franchiseFamilyId += concatenarDetalleFormItemUsarEn(
                        item?.franchise_family_id,
                        idx,
                        detalleCupon,
                    );
                    franchiseId += concatenarDetalleFormItemUsarEn(
                        item?.franchise_id,
                        idx,
                        detalleCupon,
                    );
                    id = `${item?.franchise_family_id}${item?.franchise_id}`;
                    item1 = {
                        nombre: '',
                        valor: item?.franchise_family_id,
                        tipo: 'Familia',
                    };
                    item2 = {
                        nombre: '',
                        valor: item?.franchise_id,
                        tipo: 'Franquicia',
                    };
                }
                if (item?.franchise_id && !item?.franchise_family_id) {
                    franchiseId += concatenarDetalleFormItemUsarEn(
                        item?.franchise_id,
                        idx,
                        detalleCupon,
                    );
                    branchId += concatenarDetalleFormItemUsarEn(
                        item?.branch_id,
                        idx,
                        detalleCupon,
                    );
                    productId += concatenarDetalleFormItemUsarEn(
                        item?.product_id,
                        idx,
                        detalleCupon,
                    );
                    id = `${item?.franchise_id}${item?.branch_id}${item?.product_id}`;
                    item1 = {
                        nombre: '',
                        valor: item?.franchise_id,
                        tipo: 'Franquicia',
                    };
                    item2 = {
                        nombre: '',
                        valor: item?.branch_id,
                        tipo: 'Sucursal',
                    };
                    item3 = {
                        nombre: '',
                        valor: item?.product_id,
                        tipo: 'Producto',
                    };
                }
                listaUsarEnArr.push({ id, item1, item2, item3 });
            });
        }

        // Remove objects with undefined ids
        listaUsarEnArr = listaUsarEnArr.filter((item) => item?.id);

        return {
            geoZone,
            cityId,
            neighborhoodId,
            franchiseFamilyId,
            franchiseId,
            branchId,
            productId,
            listaUsarEnArr,
        };
    };

    const concatenarDetalleFormItemUsarEn = (itemAgregar, idx, arr) => {
        return `${itemAgregar}${idx + 1 < arr?.length ? ',' : ''}`;
    };

    const generarCodigoCuponAleatorio = () => {
        let value = '';
        const charts = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        for (let i = 0; i < 6; i++) {
            value += charts.charAt(Math.floor(Math.random() * charts.length));
        }
        return value;
    };

    const guardarCuponButtonHandler = () => {
        setConfirmModalVisible(true);
    };

    const sendData = () => {
        cuponForm
            .validateFields()
            .then(async (values) => {
                try {
                    setConfirmModalVisible(false);

                    const pushTitle = values?.pushTitle || undefined;
                    const pushMessage = values?.pushMessage || undefined;
                    const smsMessage = values?.smsMessage || undefined;

                    const rulesAreMet = verifySMSRules(smsMessage);

                    if (!rulesAreMet)
                        throw new Error(
                            'La notificación personalizada no debe contener emojis ni acentos',
                        );

                    const esNuevo = !esEditar;

                    const fechaDesde = values.validezfecha[0];
                    const fechaHasta = values.validezfecha[1];

                    let tiposPagoArr = values?.payment_type?.length
                        ? values.payment_type.map((item) => +item)
                        : [];
                    let idTarjetasArr = [];
                    if (esOpcionPagoOnlineBancard) {
                        idTarjetasArr = values?.card_id;

                        const existenTarjetasSeleccionadas = Boolean(
                            idTarjetasArr?.length,
                        );
                        if (existenTarjetasSeleccionadas) {
                            // Asociar la opcion de BANCARD a las tarjetas seleccionadas
                            const tiposPagoSinBancardArr = tiposPagoArr.filter(
                                (item) => item !== +idBancard,
                            );
                            const tiposPagoConBancardArr = idTarjetasArr.map(
                                () => +idBancard,
                            );
                            tiposPagoArr = [
                                ...tiposPagoConBancardArr,
                                ...tiposPagoSinBancardArr,
                            ];
                        } else {
                            idTarjetasArr = [];
                        }
                    }

                    let camposDiferentesNuevoEditarCupon;
                    if (esNuevo) {
                        const idUsuariosArr = obtenerIdUsuarioCupon?.length
                            ? obtenerIdUsuarioCupon
                            : undefined;
                        const geoZoneArr = values?.geo_zone?.length
                            ? values?.geo_zone
                            : undefined;
                        const cityId = values?.city_id || undefined;
                        const neighborhoodId =
                            values?.neighborhood_id || undefined;
                        const franchiseFamilyId =
                            values?.franchise_family_id || undefined;
                        const franchiseId = values?.franchise_id || undefined;

                        camposDiferentesNuevoEditarCupon = {
                            code: values?.codigo || undefined,
                            type: values?.tipo || undefined,
                            amount: values?.monto || undefined,

                            user_id: idUsuariosArr || undefined,
                            geo_zone: geoZoneArr || undefined,
                            city_id: cityId,
                            neighborhood_id: neighborhoodId,
                            franchise_family_id: franchiseFamilyId,
                            franchise_id: franchiseId,
                            branch_id: values?.branch_id || undefined,
                            product_id: values?.product_id || undefined,
                        };

                        const specialCouponsDetails =
                            cityId ||
                            neighborhoodId ||
                            franchiseFamilyId ||
                            franchiseId;
                        const couponHasSpecialDetails = Boolean(
                            specialCouponsDetails,
                        );

                        if (couponHasSpecialDetails) {
                            let specialCouponsDetailsArr;
                            if (Array.isArray(specialCouponsDetails)) {
                                specialCouponsDetailsArr =
                                    specialCouponsDetails;
                            } else {
                                if (!geoZoneArr?.length) {
                                    specialCouponsDetailsArr =
                                        specialCouponsDetails.split(',') || [
                                            ...specialCouponsDetails,
                                        ];
                                }
                            }
                            const nullPositionsArr =
                                specialCouponsDetailsArr?.map(() => null) || [];

                            if (
                                nullPositionsArr?.length ||
                                tiposPagoArr?.length
                            ) {
                                tiposPagoArr = [
                                    ...nullPositionsArr,
                                    ...tiposPagoArr,
                                ];
                            } else {
                                tiposPagoArr = undefined;
                            }

                            if (
                                nullPositionsArr?.length ||
                                idTarjetasArr?.length
                            ) {
                                idTarjetasArr = [
                                    ...nullPositionsArr,
                                    ...idTarjetasArr,
                                ];
                            } else {
                                idTarjetasArr = undefined;
                            }
                        }
                    } else {
                        camposDiferentesNuevoEditarCupon = {
                            id: +values?.id || undefined,
                            enabled: values?.estado || undefined,
                            deleted: values?.deleted || undefined,
                            discount_code_details:
                                values?.usarEnDiscountCodeDetails,
                        };
                    }

                    let valoresFormCupones = {
                        ...camposDiferentesNuevoEditarCupon,

                        valid_from: fechaDesde.format('YYYY-MM-DD'),
                        valid_to: fechaHasta.format('YYYY-MM-DD'),
                        reason_id: +values?.motivo || undefined,
                        description: values?.motivoDescripcion || undefined,

                        usage_limit: values?.limitecupon,
                        max_per_user: values?.cantidadveces,
                        free_delivery: Boolean(values?.free_delivery),
                        self_applicable: Boolean(values?.self_applicable),
                        minimum_amount: +values?.minimum_amount || 0,
                        maximum_amount: +values?.maximum_amount || 0,

                        payment_type_id: tiposPagoArr,
                        card_id: idTarjetasArr,

                        push_title: pushTitle,
                        push_body: pushMessage,
                        sms_text: smsMessage,
                    };

                    if (!esEditar) {
                        await nuevoCupon(valoresFormCupones);
                    } else {
                        await editarCupon(
                            valoresFormCupones,
                            obtenerIdUsuarioCupon,
                            usuariosRemover,
                        );
                    }
                    cancelarButtonHandler();
                } catch (error) {
                    const errorMessage =
                        'Encontramos un problema al guardar el cupon. ' +
                            error?.message || error;
                    message.error(errorMessage);
                }
            })
            .catch((error) => {
                setConfirmModalVisible(false);

                const errorMessage =
                    error.message ||
                    'Por favor complete los campos obligatorios.';
                message.error(errorMessage);
            });
    };

    const cancelarButtonHandler = () => {
        restablecerUsuarios();
        cuponForm.resetFields();
        history.goBack();
    };

    const esCuponConUsuarios = Boolean(obtenerIdUsuarioCupon.length > 0);

    const tiposPagoChangeHandler = (value) => {
        const filtrarBancard = value.find((item) => item === idBancard);
        const tieneBancard = filtrarBancard?.length;
        setEsOpcionPagoOnlineBancard(tieneBancard);
        if (!tieneBancard) {
            cuponForm.setFieldsValue({ card_id: [] });
        }
    };

    const tarjetasChangeHandler = (value) => {
        let nuevoValue = value;
        cuponForm.setFieldsValue({ card_id: nuevoValue });
    };

    return (
        <ABMLayout
            titulo={esEditar ? 'Editar cupón' : 'Nuevo cupón'}
            acciones={
                <Space>
                    <Button onClick={cancelarButtonHandler}>Cancelar</Button>
                    <Button type="primary" onClick={guardarCuponButtonHandler}>
                        Guardar
                    </Button>
                </Space>
            }
            loading={loadingFormCupones}>
            <Form
                name="cuponForm"
                initialValues={initialValues}
                layout="vertical"
                form={cuponForm}
                validateMessages={{
                    required: 'Este campo es obligatorio.',
                }}>
                <Row gutter={24}>
                    <Col span={24}>
                        <Card title="Cupón">
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        label="Código"
                                        name="codigo"
                                        rules={[{ required: true }]}>
                                        <Input disabled={esEditar} />
                                    </Form.Item>
                                    <Form.Item
                                        style={{ display: 'none' }}
                                        name="id">
                                        <Input type="hidden" />
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="tipo"
                                        label="Tipo"
                                        rules={[{ required: true }]}>
                                        <Select
                                            name="tipo"
                                            disabled={esEditar}
                                            onChange={(evt) =>
                                                setSelectCuponTipoValue(evt)
                                            }>
                                            <Option value="percent">
                                                % (Porcentaje)
                                            </Option>
                                            <Option value="fixed">Monto</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="monto"
                                        label={
                                            esMonto
                                                ? 'Monto'
                                                : 'Porcentaje (1 - 100)'
                                        }
                                        rules={[
                                            {
                                                type: 'number',
                                                required: true,
                                                message:
                                                    'Este campo es obligatorio',
                                            },
                                        ]}>
                                        <InputNumber
                                            disabled={esEditar}
                                            formatter={
                                                esMonto
                                                    ? (value) =>
                                                          `${value}`.replace(
                                                              /\B(?=(\d{3})+(?!\d))/g,
                                                              ',',
                                                          )
                                                    : undefined
                                            }
                                            style={{ width: '100%' }}
                                            min={esMonto ? 0 : 1}
                                            max={esMonto ? undefined : 100}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        name="estado"
                                        label="Estado"
                                        rules={[{ required: true }]}>
                                        <Select placeholder="habilitado">
                                            <Option
                                                key="habilitado"
                                                value="true">
                                                Habilitado
                                            </Option>
                                            <Option
                                                key="noHabilitado"
                                                value="false">
                                                No habilitado
                                            </Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="motivo"
                                        label="Motivo"
                                        rules={[{ required: true }]}>
                                        <Select placeholder="Seleccione un motivo">
                                            {obtenerMotivosSelect &&
                                                obtenerMotivosSelect?.map(
                                                    (motivo) => (
                                                        <Option
                                                            key={motivo.id}
                                                            value={motivo.id}>
                                                            {motivo.name}
                                                        </Option>
                                                    ),
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="motivoDescripcion"
                                        label="Descripción del motivo"
                                        rules={[{ required: true }]}>
                                        <Input placeholder="..." />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={24}>
                                <Col span={8}>
                                    <Form.Item
                                        name="free_delivery"
                                        valuePropName="checked">
                                        <Checkbox
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            Delivery gratis
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item
                                        name="self_applicable"
                                        valuePropName="checked">
                                        <Checkbox
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                            }}>
                                            Auto aplicable
                                        </Checkbox>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Card title="Condiciones de uso">
                            <Title type="secondary" level={5}>
                                Uso del cupón
                            </Title>

                            <Row gutter={12}>
                                <Col span={12}>
                                    <Form.Item
                                        name="limitecupon"
                                        label="Límite de uso del cupón"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            min={1}
                                            placeholder="1"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="cantidadveces"
                                        label="Cantidad de veces por persona"
                                        rules={[{ required: true }]}>
                                        <InputNumber
                                            style={{ width: '100%' }}
                                            min={1}
                                            placeholder="1"
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    {/* TODO: Actualizar este Form.Item por el atomo de 'tipo de pago' cuando la tarea (https://monchis.atlassian.net/browse/NA-583) este finalizada */}
                                    <Form.Item
                                        name="payment_type"
                                        label="Opción de pago online"
                                        rules={[
                                            {
                                                type: 'array',
                                            },
                                        ]}>
                                        <Select
                                            mode="multiple"
                                            loading={loadingTiposPago}
                                            onChange={tiposPagoChangeHandler}>
                                            {listaTiposPagos?.length &&
                                                listaTiposPagos?.map(
                                                    (tipoPago) => (
                                                        <Option
                                                            key={tipoPago?.id}
                                                            value={
                                                                tipoPago?.id
                                                            }>
                                                            {tipoPago?.slug}
                                                        </Option>
                                                    ),
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="card_id"
                                        label="Tarjetas"
                                        rules={[{ type: 'array' }]}
                                        disabled={esOpcionPagoOnlineBancard}>
                                        <Select
                                            mode="multiple"
                                            disabled={
                                                !esOpcionPagoOnlineBancard
                                            }
                                            loading={tarjetasLoading}
                                            onChange={tarjetasChangeHandler}>
                                            {listaTarjetas?.length &&
                                                listaTarjetas?.map(
                                                    (tarjeta) => (
                                                        <Option
                                                            key={tarjeta?.id}
                                                            value={tarjeta?.id}>
                                                            {tarjeta?.name}
                                                        </Option>
                                                    ),
                                                )}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="minimum_amount"
                                        label="Monto mínimo de uso"
                                        rules={[{ type: 'number' }]}>
                                        <InputNumber
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ',',
                                                )
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        name="maximum_amount"
                                        label="Monto máximo de descuento"
                                        rules={[{ type: 'number' }]}>
                                        <InputNumber
                                            formatter={(value) =>
                                                `${value}`.replace(
                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                    ',',
                                                )
                                            }
                                            style={{ width: '100%' }}
                                            disabled={esMonto}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Title type="secondary" level={5}>
                                Validez
                            </Title>

                            <Form.Item
                                name="validezfecha"
                                label="Periodo de validez"
                                rules={[{ required: true }]}>
                                <RangePicker
                                    disabledDate={habilitarFechasDesdeHoy}
                                    style={{ width: '100%' }}
                                    placeholder={['Desde', 'Hasta']}
                                />
                            </Form.Item>

                            <FormItemUsarEn
                                esEditar={esEditar}
                                cuponForm={cuponForm}
                                obtenerFranquicias={obtenerFranquicias}
                                obtenerCiudades={obtenerCiudades}
                                obtenerFamilias={obtenerFamilias}
                                obtenerBarrios={obtenerBarrios}
                                obtenerSucursales={obtenerSucursales}
                                obtenerProductos={obtenerProductos}
                            />
                        </Card>
                    </Col>

                    <Col xs={24} sm={24} md={12}>
                        <Col>
                            <FormItemAsignarUsuarios />
                        </Col>
                        <Col>
                            <FormItemCustomizeNotifications
                                formInstance={cuponForm}
                                mode={mode}
                            />
                        </Col>
                    </Col>
                </Row>
            </Form>

            <PopUpConfirmCupon
                mode={mode}
                visible={confirmModalVisible}
                onCancel={() => setConfirmModalVisible(false)}
                onOk={sendData}
                couponHasUsers={esCuponConUsuarios}
            />
        </ABMLayout>
    );
};

export default CuponesForm;

CuponesForm.propTypes = {
    mode: PropTypes.string,
    param: PropTypes.object,
};
