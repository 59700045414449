import React, { useCallback } from 'react';
const { useLocation } = require('react-router-dom');

function useRouterQuery() {
    const { search } = useLocation();

    const query = React.useMemo(() => new URLSearchParams(search), [search]);

    const createQueryParams = useCallback((queryParams) => {
        const params = queryParams;

        // Remove empty or undefined values
        Object.keys(params).forEach((key) => {
            if (!params[key]) {
                delete params[key];
            }
        });

        const parsedParams = new URLSearchParams(params);

        return `?${parsedParams}`;
    }, []);

    return {
        query,
        createQueryParams,
    };
}

export default useRouterQuery;
