import React, { useEffect, useState } from 'react';
import { Card, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';

import useFranchiseAndBranchStatus from '../../../hooks/useFranchiseAndBranchStatus';

import ABMLayout from '../../../../layouts/ABMLayout';
import ProductosTab from './Productos/ProductosTab';
import SucursalesTab from './Sucursales/SucursalesTab';
import AtributosTab from './Atributos/AtributosTab';

const Integraciones = () => {
    const brandsHook = useFranchiseAndBranchStatus();
    const history = useHistory();
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('sucursales'); // Estado inicial de la pestaña seleccionada

    // Obtener la ruta actual y establecerla como la pestaña seleccionada
    useEffect(() => {
        const path = location.pathname;
        switch (path) {
            case '/administrar/integraciones/productos':
                setSelectedTab('productos');
                break;
            case '/administrar/integraciones/sucursales':
                setSelectedTab('sucursales');
                break;
            case '/administrar/integraciones/atributos':
                setSelectedTab('atributos');
                break;
            default:
                setSelectedTab('sucursales'); // Establece un valor predeterminado si la ruta no coincide con ninguna pestaña
                break;
        }
    }, [location.pathname]);

    const handleTabClick = (key) => {
        setSelectedTab(key);
        // Cambia la ruta cuando se hace clic en una pestaña
        switch (key) {
            case 'productos':
                history.push('/administrar/integraciones/productos');
                break;
            case 'sucursales':
                history.push('/administrar/integraciones/sucursales');
                break;
            case 'atributos':
                history.push('/administrar/integraciones/atributos');
                break;
            default:
                break;
        }
    };

    return (
        <ABMLayout titulo="Integraciones" loading={brandsHook.loadingBrands}>
            <Card>
                <Menu
                    mode="horizontal"
                    selectedKeys={[selectedTab]}
                    onClick={({ key }) => handleTabClick(key)}>
                    <Menu.Item key="sucursales">Sucursales</Menu.Item>
                    <Menu.Item key="productos">Productos</Menu.Item>
                    <Menu.Item key="atributos">Atributos</Menu.Item>
                </Menu>
                {selectedTab === 'productos' && <ProductosTab />}
                {selectedTab === 'sucursales' && <SucursalesTab />}
                {selectedTab === 'atributos' && <AtributosTab />}
            </Card>
        </ABMLayout>
    );
};

export default Integraciones;
