import { Avatar, Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { formatoGuaranies } from '../../../utils-components/Number';
import PropTypes from 'prop-types';

const { Text } = Typography;

const UserLevelItem = ({
    name,
    points,
    amount,
    rewardsList,
    color,
    onEdit,
    onViewDetails,
}) => {
    const rewardsListHasData = Boolean(rewardsList?.length);
    return (
        <Row gutter={12} style={styles.userLevelItemContainer}>
            <Col span={20}>
                <Row>
                    <Col span={24} style={styles.userLevelDetailsContainer}>
                        <Avatar
                            style={{
                                marginRight: '0.5rem',
                                backgroundColor: color,
                            }}
                            size="small"
                        />
                        <Text
                            strong
                            style={styles.userLevelDetailsNameContainer}>
                            {name}:
                        </Text>
                        <Text style={styles.userLevelDetailsTextContainer}>
                            {points} punto{points > 1 && 's'} por cada{' '}
                            {formatoGuaranies(amount)}
                        </Text>
                    </Col>
                    <Col span={24} style={styles.userLevelRewardsContainer}>
                        {rewardsListHasData ? (
                            <>
                                {rewardsList.map((item, index) => {
                                    const nextIndex = index + 1;
                                    const nextRewardExists =
                                        nextIndex < rewardsList.length;
                                    return (
                                        <Text key={item?.id} disabled>
                                            {`${item?.name} (${item?.quantity})`}{' '}
                                            {nextRewardExists && ' - '}
                                        </Text>
                                    );
                                })}
                            </>
                        ) : (
                            <Text strong>Nivel sin beneficios</Text>
                        )}
                    </Col>
                </Row>
            </Col>
            <Col span={4} style={styles.userLevelActions}>
                <Button type="link" onClick={onEdit}>
                    Editar
                </Button>
                <Button type="link" onClick={onViewDetails}>
                    Ver detalles
                </Button>
            </Col>
        </Row>
    );
};

export default UserLevelItem;

UserLevelItem.propTypes = {
    name: PropTypes.string,
    points: PropTypes.number,
    amount: PropTypes.number,
    rewardsList: PropTypes.array,
    color: PropTypes.string,
    onEdit: PropTypes.func,
    onViewDetails: PropTypes.func,
};

const styles = {
    userLevelItemContainer: {
        padding: '1rem',
        width: '100%',
    },
    userLevelActions: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    userLevelDetailsContainer: {
        display: 'flex',
    },
    userLevelRewardsContainer: {
        paddingLeft: '2rem',
        paddingTop: '0.5rem',
    },
    userLevelDetailsNameContainer: {
        paddingTop: '0.2rem',
        paddingRight: '0.2rem',
    },
    userLevelDetailsTextContainer: {
        paddingTop: '0.2rem',
    },
};
