import React from 'react';
import { Modal } from 'antd';
import ModalContent from '../../../atoms/ModalContent';
import PropTypes from 'prop-types';

const DesmatchearModal = ({ modalDesmatchear, handleCancelDesmatchear }) => {
    return (
        <div>
            <Modal
                centered
                visible={modalDesmatchear}
                footer={null}
                closable={false}
                onCancel={handleCancelDesmatchear}>
                <ModalContent
                    title="¿Confirmás el desmatcheo?"
                    okText="Sí, confirmar"
                    cancelText="No, cancelar"
                    isLoading={false}
                    onCancel={handleCancelDesmatchear}
                    invertPrimary
                    showWarnIcon
                    isDelete
                />
            </Modal>
        </div>
    );
};

DesmatchearModal.propTypes = {
    modalDesmatchear: PropTypes.bool,
    handleCancelDesmatchear: PropTypes.func.isRequired,
};

export default DesmatchearModal;
