import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { Space, Table } from 'antd';
import { useMixpanel } from 'react-mixpanel-browser';
import UltimaActualizacion from '../../../../atoms/support/UltimaActualizacion/UltimaActualizacion';
import CargarMasButton from '../../../../atoms/support/CargarMasButton/CargarMasButton';
import classes from './Agendados1.module.css';
import SupportContext from '../../../../../context/support/SupportContext';
import PropTypes from 'prop-types';
import TiempoPedido from '../../../../atoms/support/TiempoPedido/TiempoPedido';
import EstadoPedidoAgendado from '../../../../atoms/support/EstadoPedidoAgendado/EstadoPedidoAgendado';
import Local from '../../../../atoms/support/Local';
import DatosCliente from '../../../../atoms/support/DatosCliente';
import Acciones from '../../../../atoms/support/acciones/Acciones';
import AprobacionPedidoAgendado from '../../../../atoms/support/AprobacionPedidoAgendado/AprobacionPedidoAgendado';
import tablas from '../tablas.module.css';
import FinalPrice from '../../../../atoms/support/FinalPrice';
import useSupport from '../../../../../hooks/support/useSupport';

const Agendados = ({ agendados, online, conectarSocket, loading }) => {
    const { revertirPago, cambiarEstado, obtenerSiguienteEstado } =
        useContext(SupportContext);

    const {
        paginaActualAgendados,
        totalPaginasAgendados,

        obtenerTodasOrdenes,
        cargarMas,
        verOrdenModal,
        imprimirOrden,
    } = useSupport();

    const mixpanel = useMixpanel();

    //Se trackea el tiempo en esta pantalla antes de solicitar driver
    useEffect(() => {
        mixpanel.time_event('request_driver');
        mixpanel.track('click_on_scheduled_tab');
    }, [mixpanel]);

    const columns = useMemo(
        () => [
            {
                dataIndex: 'nombreCliente',
                key: 'nombreCliente',
                render: (_text, record) => (
                    <>
                        <Local
                            nombreLocal={record?.nombreLocal}
                            tipoEntrega={record?.tipoEntrega}
                            telefonoLocal={record?.telefonoLocal}
                            tienePermiso={record?.canSeeOnlinePaymentType}
                        />
                        <div>
                            <strong> Orden ID: </strong>
                            {record?.ordenOriginal?.id}
                        </div>
                        <DatosCliente
                            nombre={record?.nombreCliente}
                            id={record?.idCliente}
                            razonSocial={record?.razonSocial}
                            RUC={record?.ruc}
                        />
                    </>
                ),
            },
            {
                dataIndex: 'precioFinal',
                key: 'precioFinal',
                render: (_text, record) => (
                    <>
                        <Local
                            tipoPago={record?.tipoPago}
                            codigoOnline={record?.codigoPagoOnline}
                        />
                        <strong>
                            <FinalPrice ordenOriginal={record?.ordenOriginal} />
                        </strong>
                    </>
                ),
            },
            {
                dataIndex: 'tiempoPedido',
                key: 'tiempoPedido',
                render: (_text, record) => (
                    <TiempoPedido
                        fechaPedido={record?.fechaPedido}
                        ordenOriginal={record?.ordenOriginal}
                        esAgendado={true}
                    />
                ),
            },
            {
                dataIndex: 'estado',
                key: 'estado',
                render: (_text, record) => (
                    <EstadoPedidoAgendado
                        fechaAgendado={record.fechaAgendamiento}
                        estado={record.estadoOrden}
                    />
                ),
            },
            {
                dataIndex: 'aprobacion',
                key: 'aprobacion',
                render: (_text, record) => (
                    <AprobacionPedidoAgendado
                        ordenOriginal={record?.ordenOriginal}
                    />
                ),
            },
            {
                dataIndex: 'acciones',
                key: 'acciones',
                render: (_text, record) => (
                    <Acciones
                        ordenSeleccionada={record?.ordenOriginal}
                        tipoPago={record?.tipoPago}
                        permitirRevertirPagoOnline={
                            record?.permitirRevertirPagoOnline
                        }
                        revertirPago={revertirPago}
                        cambiarEstado={cambiarEstado}
                        permitirImprimirOrden={record?.permitirImprimirOrden}
                        imprimirOrden={imprimirOrden}
                        permitirVerOrden={record?.permitirVerOrden}
                        verOrden={verOrdenModal}
                        obtenerSiguienteEstado={obtenerSiguienteEstado}
                        tipoPagoSoloCupon={record?.tipoPagoSoloCupon}
                    />
                ),
            },
        ],
        [
            revertirPago,
            cambiarEstado,
            imprimirOrden,
            verOrdenModal,
            obtenerSiguienteEstado,
        ],
    );

    const obtenerOrdenesHandler = () => {
        obtenerTodasOrdenes();
        conectarSocket();
    };

    const cargarMasButtonHandler = useCallback(() => {
        cargarMas('10,11,12', paginaActualAgendados, totalPaginasAgendados);
    }, [cargarMas, paginaActualAgendados, totalPaginasAgendados]);

    return (
        <Space direction="vertical" size="small" className={classes.space}>
            <UltimaActualizacion
                onClick={obtenerOrdenesHandler}
                online={online}
            />
            <Table
                className={tablas.bordeNegro}
                columns={columns}
                showHeader={false}
                pagination={false}
                dataSource={agendados}
                loading={loading}
            />
            <CargarMasButton
                onCargarMas={cargarMasButtonHandler}
                loading={loading}
                paginaActual={paginaActualAgendados}
                totalPaginas={totalPaginasAgendados}
            />
        </Space>
    );
};

export default Agendados;

Agendados.propTypes = {
    agendados: PropTypes.any,
    online: PropTypes.bool,
    conectarSocket: PropTypes.func,
    loading: PropTypes.bool,
};
