import {
    Button,
    Card,
    Col,
    DatePicker,
    Form,
    Input,
    Row,
    Select,
    Space,
    TimePicker,
    Typography,
} from 'antd';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import prefContext from 'context/preferencias/prefContext';
import styles from './ProgramPromotions.module.css';
import { PromotionCardsList } from 'components/molecules/ProgramaRecomensa/Promociones/CardsList';
export { getTheDayFromADate } from 'components/utils-components/Date/index';

const ProgramPromotions = ({ formInstance, initialSchedule, disabled }) => {
    const { isSidebarAbierto } = useContext(prefContext);
    const [cardsWrapperName, setCardsWrapperName] = useState(
        styles.cardsPromotionTimeWithSidebar,
    );
    const [programmedCardItems, setProgrammedCardItems] = useState([]);
    const [cardPropsReduced, setCardPropsReduced] = useState([]);

    const { Title } = Typography;

    const dayOptions = [
        { value: 'everyDay', label: 'Todos los días' },
        { value: 'monday', label: 'Lunes' },
        { value: 'tuesday', label: 'Martes' },
        { value: 'wednesday', label: 'Miércoles' },
        { value: 'thursday', label: 'Jueves' },
        { value: 'friday', label: 'Viernes' },
        { value: 'saturday', label: 'Sábado' },
        { value: 'sunday', label: 'Domingo' },
    ];

    useEffect(() => {
        isSidebarAbierto &&
            setCardsWrapperName(styles.cardsPromotionTimeWithSidebar);

        !isSidebarAbierto &&
            setCardsWrapperName(styles.cardsPromotionTimeWithoutSidebar);
    }, [isSidebarAbierto]);

    useEffect(() => {
        if (initialSchedule) {
            setProgrammedCardItems(initialSchedule);
        }
    }, [initialSchedule]);

    useEffect(() => {
        const card = programmedCardItems.reduce((acc, current) => {
            const promotionDay = current['promotionDay'];
            if (!acc[promotionDay]) {
                acc[promotionDay] = [];
            }
            acc[promotionDay].push(current);
            return acc;
        }, {});
        setCardPropsReduced(card);
    }, [programmedCardItems]);

    const onAddToCalendar = () => {
        const values = formInstance.getFieldsValue([
            'promotionDays',
            'promotionHourFrom',
            'promotionHourTo',
        ]);

        const promotionDays =
            values.promotionDays === 'everyDay'
                ? [
                      'monday',
                      'tuesday',
                      'wednesday',
                      'thursday',
                      'friday',
                      'saturday',
                      'sunday',
                  ]
                : [values.promotionDays];

        const newPromotions = promotionDays.map((day) => ({
            promotionDay: day,
            promotionHourFrom: values.promotionHourFrom,
            promotionHourTo: values.promotionHourTo,
            id: new Date().getTime(),
        }));

        const updatedPromotions = [...programmedCardItems, ...newPromotions];
        setProgrammedCardItems(updatedPromotions);
        formInstance.setFieldsValue({ scheduled: updatedPromotions });
    };

    const removeTimeItemHandler = (promotionId) => {
        const filteredList = programmedCardItems.filter(
            (item) => item.id !== promotionId,
        );
        setProgrammedCardItems(filteredList);
        formInstance.setFieldsValue({ scheduled: filteredList });
    };

    return (
        <Card type="inner" title="Programar promoción" className={styles.card}>
            <Space direction="vertical">
                <Title level={5}>Definí la vigencia de tu promo</Title>
                <Space>
                    <Form.Item
                        label="Fecha de inicio"
                        name="promotionDateFrom"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor, ingrese la fecha de inicio.',
                            },
                        ]}>
                        <DatePicker
                            format="DD-MM-YYYY"
                            className={styles.datePicker}
                        />
                    </Form.Item>
                    <Form.Item
                        label="Fecha de finalización"
                        name="promotionDateTo"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Por favor, ingrese la fecha de finalización.',
                            },
                        ]}>
                        <DatePicker
                            format="DD-MM-YYYY"
                            className={styles.datePicker}
                        />
                    </Form.Item>
                </Space>

                <Row>
                    <Title level={5}>
                        Personalizá días y horarios de la promoción
                    </Title>
                </Row>
                <Form.Item name="scheduled" style={{ marginBottom: '-4rem' }}>
                    <Input hidden rules={[{ required: true }]} />
                </Form.Item>

                <Space className={styles.characteristicsRow}>
                    <Col>
                        <Form.Item
                            label="Seleccioná los días de la semana"
                            name="promotionDays"
                            className={styles.hourRange}>
                            <Select
                                disabled={disabled}
                                placeholder="Seleccionar"
                                options={dayOptions}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="promotionHourFrom"
                            label="Hora de inicio"
                            className={styles.hourRange}>
                            <TimePicker
                                disabled={disabled}
                                format="HH:mm"
                                placeholder="Inicio"
                                className={styles.time}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name="promotionHourTo"
                            label="Hora de finalización"
                            className={styles.hourRange}>
                            <TimePicker
                                disabled={disabled}
                                format="HH:mm"
                                placeholder="Final"
                                className={styles.time}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item label=" " className={styles.hourRange}>
                            <Button
                                disabled={disabled}
                                onClick={onAddToCalendar}>
                                Agregar al calendario
                            </Button>
                        </Form.Item>
                    </Col>
                </Space>

                <Row className={cardsWrapperName}>
                    <PromotionCardsList
                        dayOptions={dayOptions}
                        cardPropsReduced={cardPropsReduced}
                        removeHandler={removeTimeItemHandler}
                        disabled={disabled}
                    />
                </Row>
            </Space>
        </Card>
    );
};

export default ProgramPromotions;

ProgramPromotions.propTypes = {
    formInstance: PropTypes.any,
    initialSchedule: PropTypes.array,
    disabled: PropTypes.bool,
};
