export const AUTH_INICIO = 'AUTH_INICIO';
export const AUTH_EXITO = 'AUTH_EXITO';
export const AUTH_FALLO = 'AUTH_FALLO';
export const AUTH_REDIRECT_PATH = 'AUTH_REDIRECT_PATH';

export const CERRAR_SESION = 'CERRAR_SESION';

export const DESCARGA_TARJETA_EXITO = 'DESCARGA_TARJETA_EXITO';
export const SELECCIONAR_ID_TABLA = 'SELECCIONAR_ID_TABLA';
export const TARJETA_ELIMINADA_EXITO = 'TARJETA_ELIMINADA_EXITO';

export const SELECCIONAR_PAGINA_ACTUAL = 'SELECCIONAR_PAGINA_ACTUAL';
export const OBTENER_USUARIOS_ACTUALIZADOS = 'OBTENER_USUARIOS_ACTUALIZADOS';
export const BUSCAR_USUARIO = 'BUSCAR_USUARIO';
export const USUARIOS_TOTALES = 'USUARIOS_TOTALES';

export const OBTENER_CUPONES = 'OBTENER_CUPONES';
export const OBTENER_FRANQUICIAS = 'OBTENER_FRANQUICIAS';
export const BUSCAR_CUPON = 'BUSCAR_CUPON';
export const OBTENER_EDITAR_CUPON = 'OBTENER_EDITAR_CUPON';
export const CUPON_ELIMINADO_EXITO = 'CUPON_ELIMINADO_EXITO';
export const CUPONES_BD = 'CUPONES_BD';
export const OBTENER_USUARIOS = 'OBTENER_USUARIOS';
export const BUSCA_USUARIO_CUPON = 'BUSCA_USUARIO_CUPON';
export const OBTENER_ID_USUARIO_CUPON = 'OBTENER_ID_USUARIO_CUPON';
export const OBTENER_ID_CUPON = 'OBTENER_ID_CUPON';
export const GUARDAR_LISTA_USUARIOS_SELECCIONADOS =
    'GUARDAR_LISTA_USUARIOS_SELECCIONADOS';
export const OBTENER_MOTIVOS = 'OBTENER_MOTIVOS';
export const CUPONES_TOTALES = 'CUPONES_TOTALES';
export const SELECCIONAR_PAGINA_CUPON = 'SELECCIONAR_PAGINA_CUPON';
export const RESTABLECER_ID_USUARIO_CUPON = 'RESTABLECER_ID_USUARIO_CUPON';
export const OBTENER_CIUDADES = 'OBTENER_CIUDADES';
export const OBTENER_FAMILIAS = 'OBTENER_FAMILIAS';

export const GUARDAR_CATEGORY_ID = 'GUARDAR_CATEGORY_ID';

export const USUARIOS_LISTA_ELIMINAR = 'USUARIOS_LISTA_ELIMINAR';

export const OBTENER_LISTADO_TIMBRADOS_TABLA =
    'OBTENER_LISTADO_TIMBRADOS_TABLA';
export const OBTENER_LISTADO_TIMBRADOS = 'OBTENER_LISTADO_TIMBRADOS';

export const SELECCIONAR_PAGINA_TIMBRADO = 'SELECCIONAR_PAGINA_TIMBRADO';
export const TIMBRADOS_TOTALES = 'TIMBRADOS_TOTALES';
export const BUSQUEDA_TIMBRADO = 'BUSQUEDA_TIMBRADO';

export const OBTENER_LISTADO_HISTORIAL_COMISION =
    'OBTENER_LISTADO_HISTORIAL_COMISION';
export const OBTENER_FRANQUICIA_SELECT = 'OBTENER_FRANQUICIA_SELECT';
export const DESCARGAR_HISTORIAL_COMISION = 'DESCARGAR_HISTORIAL_COMISION';
export const SELECCIONAR_PAGINA_HISTORIAL = 'SELECCIONAR_PAGINA_HISTORIAL';
export const HISTORIAL_TOTALES = 'HISTORIAL_TOTALES';

export const OBTENER_LISTADO_RECIBO = 'OBTENER_LISTADO_RECIBO';
export const SELECCIONAR_PAGINA_RECIBO = 'SELECCIONAR_PAGINA_RECIBO';
export const RECIBO_TOTALES = 'RECIBO_TOTALES';
export const RECIBO_GUARDAR_SELECCIONADO = 'RECIBO_GUARDAR_SELECCIONADO';

export const USUARIOS_GUARDAR_REGISTRO = 'USUARIOS_GUARDAR_REGISTRO';
export const USUARIO_ADMIN = 'USUARIO_ADMIN';
